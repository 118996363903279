import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { StickyPanel } from '../../../components/sticky-panel/sticky-panel'
import { PaymentMethod } from './components/payment-method'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import './saved-payment-methods.scss'

export const SavedPaymentMethods = observer(() => {
  const { getSavedCards, savedCards, deleteSavedCard } = useStore().data

  const { t } = useTranslation()

  const { isLoading: isSavedCardsLoading } = useQuery(getSavedCards)
  const [, deleteSavedCardRequest] = useMutation(({ cardId }: { cardId: string }) => deleteSavedCard(cardId))

  const onCardDelete = (cardId: string) => {
    toast.success(t('saved_payment_methods.method_has_been_deleted'))
    deleteSavedCardRequest({ cardId })
  }

  const redirect = useRedirect()

  return (
    <div className="SavedPaymentMethods">
      <Header title={t('saved_payment_methods.payment_methods')} showBack />
      <Separator.Vertical height={35} />
      {isSavedCardsLoading ? (
        <LoadingSpinner />
      ) : savedCards.length > 0 ? (
        <div className="SavedPaymentMethods__container">
          <div className="SavedPaymentMethods__methodsList">
            {savedCards.map((savedCard) => (
              <PaymentMethod
                key={savedCard.id}
                deleteMethod={() => onCardDelete(savedCard.id)}
                cardBrand={savedCard.brand}
                title={`•••• •••• •••• •••• ${savedCard.card_number_last4}`}
              />
            ))}
          </div>
        </div>
      ) : (
        <p className="SavedPaymentMethods__emptyList">{t('saved_payment_methods.no_payment_methods_added')}</p>
      )}
      <StickyPanel style={{ justifyContent: 'center' }}>
        <button
          className="PayButton__button"
          onClick={() => {
            redirect(routes.saveCard, {})
          }}
        >
          {t('saved_payment_methods.add_new_card')}
        </button>
      </StickyPanel>
    </div>
  )
})
