import './day-item.scss'

type Props = {
  id: string
  label: string
  selected: boolean
  toggleSelection: (key: string) => void
}

export const DayItem = ({ id, label, selected, toggleSelection }: Props) => {
  return (
    <div onClick={() => toggleSelection(id)} className={`DayItem ${selected ? 'active' : ''}`}>
      <span>{label}</span>
    </div>
  )
}
