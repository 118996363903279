import locationIcon from '../../../../../components/assets/location-icon.svg'
import editLocationIcon from '../../../assets/edit-grey-icon.svg'
import { Link } from '../../../../../services/router'
import { BusinessLocation } from '../../../../../models/location'
import { routes } from '../../../../../routes'
import { Separator } from '../../../../../components/app-layout'
import './location-item.scss'

interface LocationItemProps {
  location: BusinessLocation
}

const LocationItem = ({ location }: LocationItemProps) => {
  return (
    <Link route={routes.editLocations} params={{ id: String(location.id) }} className="LocationItem">
      <div className="LocationItem__main">
        <div className="LocationItem__nameContainer">
          <img className="LocationItem__locationIcon" src={locationIcon} />
          <h3 className="LocationItem__name">{location.name}</h3>
        </div>
        <img src={editLocationIcon} />
      </div>
      <Separator.Vertical height={6} />
      <div className="LocationItem__address">{location.address}</div>
    </Link>
  )
}

export default LocationItem
