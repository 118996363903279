import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../../../../utils/mst-hooks'
import { Separator } from '../../../../app-layout/separator/separator'
import { Input } from '../../../../form/input/input'
import plusImg from '../assets/plus.svg'
import minusImg from './assets/minus.svg'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './staff-amount.scss'

export const StaffAmount = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const { getProfessionName } = useStore().data

  const quantity = job.quantity.value ?? 0

  const increaseQuantity = () => {
    job.quantity.set(quantity + 1)
  }
  const decreaseQuantity = () => {
    if (quantity <= 1) return
    job.quantity.set(quantity - 1)
  }

  return (
    <div className="StaffAmount">
      <span className="Popup__title">{t('job_draft_details.staff_amount_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.staff_amount_subtitle')}</span>
      <div className="StaffAmount__wrapper">
        <div className="StaffAmount__row">
          <Separator.Spacer />
          <div
            onClick={decreaseQuantity}
            title="decrease quantity"
            className={`StaffAmount__row__button ${job.quantity.hasError ? 'StaffAmount__row__button_error' : ''}`}
            style={{ backgroundImage: `url(${minusImg})` }}
          />
          <Separator.Horizontal width={18} />
          <Input
            label={getProfessionName(job.category.id as number)}
            value={quantity}
            type="number"
            handleChange={value => job.quantity.set(Number(value))}
            className={`StaffAmount__row__input ${job.quantity.hasError ? 'StaffAmount__row__input_error' : ''}`}
          />
          <Separator.Horizontal width={18} />
          <div
            onClick={increaseQuantity}
            className={`StaffAmount__row__button ${job.quantity.hasError ? 'StaffAmount__row__button_error' : ''}`}
            title="increase quantity"
            style={{ backgroundImage: `url(${plusImg})` }}
          ></div>
          <Separator.Spacer />
        </div>
        {job.quantity.hasError && <JobDraftFieldError error={job.quantity.error} />}
      </div>
    </div>
  )
})
