import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { Formik, FormikProps } from 'formik'
import { ApiErrorTypes } from 'ogram-react'
import { observer } from 'mobx-react'
import { RequiredStringSchema } from 'yup/lib/string'

import { CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS } from '../../../constants'
import { useRedirect } from '../../../services/router'
import { t as translate } from '../../../translations'
import { routes } from '../../../routes'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { CompanyDetailsForm, GR_CLIENT_INDUSTRY_RETAIL } from '../../../models/company-details'
import { Separator } from '../../../components/app-layout/separator/separator'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import './complete-registeration.scss'
import { CorporateForm } from './components/corporate-form/corporate-form'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { Header } from '../../../components/app-layout'

export interface CompleteRegistrationForm {
  company_name: string | undefined
  industry: string | undefined
  address: string | undefined
  po_box: string | undefined
  free_zone: string | undefined
  trade_name: string | undefined
  license_number: string | undefined
  is_lpo_required: string | undefined
  region_id: string | undefined
  vat: File | null | undefined
  region: string | undefined
}

const REQUIRED_ERROR = translate('complete_registration.field_required')
const MAX_FILE_SIZE_MB = 10

type CompleteRegistrationProps = {
  navigateToScreenName: 'home' | 'all-jobs' | 'orderDraftSummary'
  orderId?: string
  showAmend?: string
}

const validationSchema: Record<string, RequiredStringSchema<string | undefined>> = {}
getCurrentMarketConfig().registration.requiredCorporateFields.forEach(field => {
  validationSchema[field] = Yup.string().required(REQUIRED_ERROR)
})

export const CompleteRegistration = observer(({ navigateToScreenName, ...params }: CompleteRegistrationProps) => {
  const { t } = useTranslation()
  const {
    getСompanyDetails,
    companyDetails,
    regionsData,
    getRegions,
    getRegionName,
    getRegionId,
    updateСompanyDetails,
    apiHost,
    confirmOrderTermsConditions,
  } = useStore().data
  const [vat, setVat] = useState<File | null>()
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [showVATErrorMesssage, setShowVATErrorMesssage] = useState(false)
  const [initialValues, setInitialValues] = useState<CompleteRegistrationForm>({
    company_name: companyDetails?.company_name ?? '',
    industry: companyDetails?.industry ?? GR_CLIENT_INDUSTRY_RETAIL,
    address: companyDetails?.address ?? '',
    po_box: companyDetails?.po_box ?? '',
    free_zone: companyDetails?.free_zone ?? '',
    trade_name: companyDetails?.trade_name ?? '',
    license_number: companyDetails?.license_number ?? '',
    is_lpo_required: companyDetails?.is_lpo_required ? t('complete_registration.yes') : t('complete_registration.no'),
    region_id: companyDetails?.region_id ? getRegionName(companyDetails.region_id) : undefined,
    region: companyDetails?.region ?? '',
    vat: null,
  })

  const [, toggleTermsAndConditions] = useMutation(confirmOrderTermsConditions, {
    onSuccess: () => {
      setTermsAndConditionsAccepted(true)
    },
    onError: err => alert(err.data.message),
    filterError: [ApiErrorTypes.ClientError],
  })

  const togglePrivacyPolicy = () => {
    setPrivacyPolicyAccepted(state => !state)
  }

  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const redirect = useRedirect()

  const getRegionsQuery = useQuery(getRegions)

  const getCorporateDataQuery = useQuery(getСompanyDetails, {
    filterError: [ApiErrorTypes.ClientError],
  })

  useEffect(() => {
    if (companyDetails && regionsData.length > 0) {
      if (
        companyDetails.is_corporate === 1 &&
        !CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS.includes(companyDetails.status_id)
      ) {
        redirect(routes.onboardingFinish, { navigateToScreenName })
        return
      }
      setInitialValues({
        company_name: companyDetails.company_name ?? '',
        industry: companyDetails.industry ?? GR_CLIENT_INDUSTRY_RETAIL,
        region: companyDetails.region ?? '',
        address: companyDetails.address ?? '',
        po_box: companyDetails.po_box ?? '',
        free_zone: companyDetails.free_zone ?? '',
        trade_name: companyDetails.trade_name ?? '',
        license_number: companyDetails.license_number ?? '',
        is_lpo_required: companyDetails.is_lpo_required
          ? t('complete_registration.yes')
          : t('complete_registration.no'),
        region_id: companyDetails.region_id ? getRegionName(companyDetails.region_id) : undefined,
        vat: null,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails, regionsData.length])

  const [updateCorporateDataQuery, updateCorporateData] = useMutation(
    values => updateСompanyDetails(values as CompanyDetailsForm, { timeout: 40000 }),
    {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: () => {
        if (params.orderId && params.showAmend && navigateToScreenName === 'orderDraftSummary') {
          redirect(routes.orderDraftSummary, params as { orderId: string; showAmend: string })
        } else {
          redirect(routes.onboardingFinish, { navigateToScreenName })
        }
      },
      onError: error => {
        const { errors } = error.data
        if (errors) {
          const errorTextMessages: string[] = []
          for (const property in errors) {
            if (errors.hasOwnProperty(property)) {
              errors[property].forEach(errorText => errorTextMessages.push(errorText))
            }
          }
          setErrorMessages(errorTextMessages)
        } else {
          setErrorMessages([error.data.message])
        }
      },
    },
  )

  const onSelectVAT = (file: File | null) => {
    setVat(file)
    setShowVATErrorMesssage(false)
  }

  const constructDataObject = (values: { [key: string]: unknown }): CompanyDetailsForm => {
    const companyInfo = {
      vat: null,
      ...companyDetails,
      ...values,
      is_lpo_required: values.is_lpo_required === t('complete_registration.yes') ? 1 : 0,
    } as CompanyDetailsForm
    if (!vat && companyDetails?.vat) {
      delete companyInfo.vat
    }
    return companyInfo
  }

  return (
    <div className="CompleteRegisteration">
      {getCorporateDataQuery.isLoading || getRegionsQuery.isLoading || updateCorporateDataQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Header title={t('onboarding.register_corporate')} />
          <Separator.Vertical height={35} />
          <div className="CompleteRegisteration__body">
            <h4 className="CompleteRegisteration__subTitle">{t('complete_registration.legal_information')}</h4>
            <Separator.Vertical height={15} />
            <Formik
              initialValues={initialValues}
              onSubmit={values => {
                updateCorporateData(
                  constructDataObject({
                    ...values,
                    region_id: getRegionId(values.region_id as string),
                  }),
                )
              }}
              validationSchema={Yup.object().shape(validationSchema)}
            >
              {(formikProps: FormikProps<CompleteRegistrationForm>) => (
                <CorporateForm
                  formikProps={formikProps}
                  regionsData={regionsData}
                  maxFileSizeMB={MAX_FILE_SIZE_MB}
                  showVATErrorMessage={showVATErrorMesssage || Boolean(formikProps.errors.vat)}
                  onSelectVAT={onSelectVAT}
                  errorMessages={errorMessages}
                  toggleTermsAndConditions={() => toggleTermsAndConditions({})}
                  termsAndConditionsAccepted={termsAndConditionsAccepted}
                  togglePrivacyPolicy={togglePrivacyPolicy}
                  privacyPolicyAccepted={privacyPolicyAccepted}
                  apiHost={apiHost}
                  vat={companyDetails?.vat}
                />
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  )
})
