import { useState } from 'react'
import './ui/account-statement.scss'
import { observer } from 'mobx-react'
import moment from 'moment'

import { useDelayedQuery, useQuery, useStore } from '../../../utils/mst-hooks'
import { useFileService } from '../../../utils/use-file-service'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { UIProps } from './types'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export function createAccountStatementContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(() => {
      const {
        loadAccountStatement,
        downloadAccountStatementFile,
        statementItems,
        startBalance,
        endBalance,
        dateFrom,
        dateTo,
        setDateFrom,
        setDateTo,
      } = useStore().data

      const redirect = useRedirect()

      const [showCalendar, setShowCalendar] = useState<boolean>(false)
      const [chosenDateRange, setChosenDateRange] = useState<[Date, Date]>([
        new Date(String(dateFrom)),
        new Date(String(dateTo)),
      ])

      const loadAccountStatementQuery = useQuery(() => loadAccountStatement())
      const { enableQuery: loadAccountStatementDelayedQuery, query: loadAccountStatementDelayedResult } =
        useDelayedQuery(() => loadAccountStatement())

      const { download: saveFile } = useFileService()
      const { enableQuery: downloadAccountStatementQuery, query: downloadAccountStatementResult } = useDelayedQuery(
        () => downloadAccountStatementFile(),
        {
          onSuccess: (file) => {
            saveFile(file, `account_statement.csv`)
          },
        },
      )

      const setDateFilters = (value: Date[]) => {
        const dateFromString = moment(value[0]).format('YYYY-MM-DD')
        const dateToString = moment(value[1]).format('YYYY-MM-DD')

        setDateFrom(dateFromString)
        setDateTo(dateToString)
      }

      const onSubmitDates = () => {
        setDateFilters(chosenDateRange)
        setShowCalendar(false)
        loadAccountStatementDelayedQuery()
      }

      return (
        <UIComponent
          isLoading={loadAccountStatementQuery.isLoading || loadAccountStatementDelayedResult.isLoading}
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          dateFrom={dateFrom}
          dateTo={dateTo}
          startBalance={startBalance}
          endBalance={endBalance}
          isDownloadingAccountStatement={downloadAccountStatementResult.isLoading}
          downloadAccountStatement={downloadAccountStatementQuery}
          chosenDateRange={chosenDateRange}
          setChosenDateRange={setChosenDateRange}
          onSubmitDates={onSubmitDates}
          statementItems={statementItems}
          onStatementItemClick={(value) => redirect(routes.invoice, { id: String(value) })}
        />
      )
    }),
  )
}
