import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentMarketConfig } from '../../../../../services/market-service'
import { CherryPickingApplicantSnapshot } from '../../../../../models/cherry-picking-applicants'
import './applicant-info.scss'

interface ApplicantInfoProps {
  applicant: CherryPickingApplicantSnapshot
}

const ApplicantInfo = ({ applicant }: ApplicantInfoProps) => {
  const { t } = useTranslation()

  const fields = useMemo(() => {
    const experience = {
      lbl: t('job_cherry_picking.experience'),
      value: `${applicant.years_experience} ${t('job_draft_details.experience_years')}`,
    }
    const lang = { lbl: t('job_cherry_picking.language'), value: applicant.languages.join(', ') }
    const age = {
      lbl: t('job_cherry_picking.age'),
      value: t('job_cherry_picking.years_old', { count: applicant.age as number }),
    }
    const nationality = { lbl: t('job_cherry_picking.nationality'), value: applicant.nationality }
    const height = {
      lbl: t('job_cherry_picking.height'),
      value: t('job_cherry_picking.height_cm', { count: applicant.height as number }),
    }

    return [
      experience,
      ...(getCurrentMarketConfig().applicantReview.includeNationality ? [nationality] : []),
      lang,
      ...(getCurrentMarketConfig().applicantReview.includeHeight ? [height] : []),
      age,
    ]
  }, [])

  return (
    <div className="ApplicantInfo">
      {fields.map(field => (
        <div className="ApplicantInfo__row" key={field.lbl}>
          <h3 className="ApplicantInfo__label">{field.lbl}</h3>
          <p className="ApplicantInfo__data">{field.value}</p>
        </div>
      ))}
    </div>
  )
}

export default ApplicantInfo
