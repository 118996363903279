export const CloseIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2475 11.9995L16.7418 15.493C16.9009 15.6532 17 15.8733 17 16.1168C17 16.6048 16.6045 17 16.118 17C15.8734 17 15.6534 16.9007 15.4934 16.7415L12 13.2471L8.50664 16.7415C8.34661 16.9007 8.12566 17 7.88198 17C7.39462 17 7 16.6048 7 16.1168C7 15.8733 7.09911 15.6532 7.25823 15.493L10.7525 11.9995L7.25823 8.50604C7.09911 8.34682 7 8.12578 7 7.88227C7 7.39524 7.39462 7 7.88198 7C8.12566 7 8.34661 7.09928 8.50664 7.2585L12 10.752L15.4934 7.2585C15.6534 7.09928 15.8734 7 16.118 7C16.6045 7 17 7.39524 17 7.88227C17 8.12578 16.9009 8.34682 16.7418 8.50604L13.2475 11.9995Z"
        fill={color}
      />
    </svg>
  )
}
