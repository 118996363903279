import { Fragment } from 'react'

import { Stepper } from '../../../../components/stepper/stepper'
import { BasicDataForm } from './components/basic-data-form/basic-data-form'
import { ContactDataForm } from './components/contact-data-form/contact-data-form'
import './register-client.scss'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { RegisterClientUIProps as UIProps } from '../types'

export const RegisterClientUI = (props: UIProps) => {
  return (
    <div className="registerClientForm">
      {props.isSubmitting ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          {props.showBasicDataForm && (
            <BasicDataForm
              innerRef={props.basicDataFormInnerRef}
              firstName={props.firstName}
              lastName={props.lastName}
              companyName={props.companyName}
              industry={props.industry}
              handleFirstNameChange={props.handleFirstNameChange}
              handleLastNameChange={props.handleLastNameChange}
              handleCompanyNameChange={props.handleCompanyNameChange}
              handleIndustryChange={props.handleIndustryChange}
              firstNameErrors={props.firstNameErrors}
              lastNameErrors={props.lastNameErrors}
              companyNameErrors={props.companyNameErrors}
              industryErrors={props.industryErrors}
            />
          )}
          {props.showContactDataForm && (
            <ContactDataForm
              innerRef={props.contactDataFormInnerRef}
              phone={props.phone}
              email={props.email}
              allowMarketingEmails={props.allow_marketing_emails}
              handlePhoneChange={props.handlePhoneChange}
              handleEmailChange={props.handleEmailChange}
              handleToggleEmailAgreement={props.handleToggleEmailAgreement}
              phoneErrors={props.phoneErrors}
              emailErrors={props.emailErrors}
              allowMarketingEmailsErrors={props.allow_marketing_emailsErrors}
            />
          )}
          <Stepper
            stepsCount={props.stepsCount}
            currentStep={props.currentStep}
            nextDisabled={props.nextDisabled}
            backDisabled={props.backDisabled}
            nextButtonLabel={props.nextButtonLabel}
            handleNextPress={props.handleNextPress}
            handleBackPress={props.handleBackPress}
          />
        </Fragment>
      )}
    </div>
  )
}
