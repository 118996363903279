import { onSnapshot } from 'mobx-state-tree'

import { Environment } from '../enviroment'
import { RootStoreModel, RootStore, RootStoreSnapshot } from '../root-store'
import * as storage from '../../services/storage'
import { marketService } from '../../services/market-service'
import { GR_MARKET_FALLBACK, MarketModel, SA_MARKET_FALLBACK, UAE_MARKET_FALLBACK } from '../../models/market'
import { config, isLocalDevelopmentEnvironment } from '../../config/env'

/**
 * The key we'll be saving our state as within async storage.
 */
export const ROOT_STATE_STORAGE_KEY = 'root-storage'

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
function createEnvironment() {
  return new Environment()
}

/**
 * Setup the root state.
 */
export function setupRootStore() {
  const env = createEnvironment()
  let rootStore: RootStore
  try {
    const data = (storage.load(ROOT_STATE_STORAGE_KEY) || { data: {} }) as RootStoreSnapshot
    rootStore = RootStoreModel.create(data, env)
  } catch (e) {
    // if there's any problems loading, then let's at least fallback to an empty state
    // instead of crashing.
    rootStore = RootStoreModel.create({ data: {} }, env)
  }

  rootStore.data.setMarket(
    String(UAE_MARKET_FALLBACK.id),
    MarketModel.create({ ...UAE_MARKET_FALLBACK, api_endpoint: config.API_URL.slice(0, -1) }),
  )
  rootStore.data.setMarket(
    String(GR_MARKET_FALLBACK.id),
    MarketModel.create({ ...GR_MARKET_FALLBACK, api_endpoint: config.API_GR_URL.slice(0, -1) }),
  )
  rootStore.data.setMarket(
    String(SA_MARKET_FALLBACK.id),
    MarketModel.create({ ...SA_MARKET_FALLBACK, api_endpoint: config.API_SA_URL.slice(0, -1) }),
  )

  setEnvBaseUrl(env, rootStore)
  // track changes & save to storage
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  onSnapshot(rootStore, snapshot => {
    storage.save(ROOT_STATE_STORAGE_KEY, snapshot)
    setEnvBaseUrl(env, rootStore)
  })

  return rootStore
}

function setEnvBaseUrl(env: Environment, store: RootStore) {
  const marketCode = marketService.getCurrentMarketCode()
  if (!marketCode) {
    return
  }

  const selectedMarket = store.data.getMarketByCode(marketCode)
  if (!selectedMarket) {
    return
  }

  if (!isLocalDevelopmentEnvironment()) {
    // @ts-ignore
    env.api.api.defaults.baseURL = `${selectedMarket.api_endpoint}/`
  }
}
