import './dropdown-menu.scss'
import { useState } from 'react'

type Props = {
  outerComponent: React.ReactNode
  items: React.ReactNode[]
  hideMenuOnItemClick?: boolean
}

export const DropdownMenu = ({ outerComponent, items, hideMenuOnItemClick = false }: Props) => {
  const [isContentVisible, setIsContentVisible] = useState(false)

  if (items.length === 0) {
    return null
  }

  return (
    <div
      className="DropdownMenu"
      onMouseOver={() => setIsContentVisible(true)}
      onMouseOut={() => setIsContentVisible(false)}
    >
      {outerComponent}
      {isContentVisible && (
        <ul
          className="DropdownMenu__content"
          onClick={() => {
            if (hideMenuOnItemClick) {
              setIsContentVisible(false)
            }
          }}
        >
          {items.map((item, index) => (
            <li key={index} className="DropdownMenu__item">
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
