import { clone, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { FormError } from 'ogram-react'
import { toJS } from 'mobx'

import { distinctTimingFields, JobScheduleModel, sameTimingFields } from './job-fields/schedule'
import { QuantityModel } from './job-fields/quantity'
import { RequirementsModel } from './job-fields/requirements'
import { WorkCategoryModel } from './job-fields/work-category'
import { TransportAccessibilityModel } from './job-fields/transport-accessibility'
import { LocationModel } from './job-fields/location'
import { DescriptionModel } from './job-fields/description'
import { ManagerModel } from './job-fields/manager'
import { ExperienceModel } from './job-fields/experience'
import { DresscodeModel } from './job-fields/dresscode'
import { LanguagesModel } from './job-fields/languages'
import { GenderModel } from './job-fields/gender'
import { DocumentsModel } from './job-fields/documents'
import { AccreditationModel } from './job-fields/accreditation'
import { AccomodationModel } from './job-fields/accomodation'
import { BoardingModel } from './job-fields/boarding'
import { ManualToolsModel } from './job-fields/manual-tools'
import { DraftJobFields, FieldsModel } from './job-fields/fields'
import { addMiddlewares } from './job-fields/middlewares'
import { toCommaSeparatedString } from '../../../utils/array-utils'
import { SingleDateInstance } from './job-fields/schedule/single-date'
import { addBreadcrumb } from '../../../services/sentry'
import { InvitedSPsModel } from './job-fields/invited-sps'
import { BoardingStatus } from '../../../models/job'

const DRAFT_JOB = 'DraftJob'

export const DraftJobModel = types
  .model(DRAFT_JOB, {
    id: types.identifier,
    existing_job_id: types.maybeNull(types.number),
    duplicated_job_id: types.maybeNull(types.number),
    fields: types.optional(FieldsModel, {}),
    category: types.optional(WorkCategoryModel, {}),
    quantity: types.optional(QuantityModel, {}),
    transport: types.optional(TransportAccessibilityModel, {}),
    location: types.optional(LocationModel, {}),
    schedule: types.optional(JobScheduleModel, {}),
    requirements: types.optional(RequirementsModel, {}),
    description: types.optional(DescriptionModel, {}),
    manager: types.optional(ManagerModel, {}),
    experience: types.optional(ExperienceModel, {}),
    dresscode: types.optional(DresscodeModel, {}),
    invitedSPs: types.optional(InvitedSPsModel, {}),
    languages: types.optional(LanguagesModel, {}),
    gender: types.optional(GenderModel, {}),
    documents: types.optional(DocumentsModel, {}),
    manualSelection: types.optional(ManualToolsModel, {}),
    accreditation: types.optional(AccreditationModel, {}),
    accommodation: types.optional(AccomodationModel, {}),
    boarding: types.optional(BoardingModel, {}),
    is_automatic_checkout: 1,
    is_validating_form: types.optional(types.boolean, false),
    error: types.maybeNull(types.string), // general error not applicable to concrete fields
  })
  .actions(self => ({
    setDuplicatedJob(id: number) {
      self.duplicated_job_id = id
    },
    clear() {
      self.quantity.set(null)
      self.schedule.dateBoundaries.start_date = null
      self.schedule.dateBoundaries.end_date = null
      self.schedule.distinctTimeSchedule.removeDates()
      self.schedule.sameTimeSchedule.same_start_time = null
      self.schedule.sameTimeSchedule.same_end_time = null
      self.description.set(null)
      self.requirements.set([])
      self.experience.set(null)
      self.dresscode.set(null)
      self.manager.set(null)
      self.languages.set([])
      self.gender.set(null)
      self.documents.set([])
      self.accreditation.set(false)
      self.accommodation.value = false
      self.boarding.set(BoardingStatus.noBoarding)
      self.fields.reset()
    },
    // @ts-ignore
    fillBasedOnTemplateJob(templateJob) {
      self.quantity.set(templateJob.quantity.value)
      self.description.set(templateJob.description.value)
      self.requirements = clone(templateJob.requirements)
      self.experience.set(templateJob.experience.value)
      self.dresscode.set(templateJob.dresscode.id)
      self.manager.set(templateJob.manager.id)
      self.languages = clone(templateJob.languages)
      self.gender.set(templateJob.gender.value)
      self.documents = clone(templateJob.documents)
      self.accreditation.set(templateJob.accreditation.value)
      self.accommodation.value = templateJob.accommodation.value
      self.boarding.set(templateJob.boarding.value)

      const shouldHavePublicTransportField = self.fields.hasField(DraftJobFields.transport)
      self.fields = clone(templateJob.fields)
      if (shouldHavePublicTransportField) {
        self.fields.addPublicTransportAccessibilityField()
      } else {
        self.fields.removePublicTransportAccessibilityField()
      }
    },
    clearSameTimeFields() {
      self.schedule.sameTimeSchedule.reset()
      self.fields.removeFields(sameTimingFields)
    },
    clearDistinctTimeFields() {
      self.schedule.distinctTimeSchedule.removeDates()
      self.fields.removeFields(distinctTimingFields)
    },
    setAllDaysHaveSameTime(shouldUseSameTiming: boolean) {
      // The field is already applied, no need to update it again
      if (shouldUseSameTiming === self.schedule.sameTimeSchedule.all_days_have_same_time) return
      const fieldsToPaste = shouldUseSameTiming ? sameTimingFields : distinctTimingFields
      if (!self.schedule.sameTimeSchedule.didSelectTimingType) {
        /**
         * It's the first time when time field(s) is inserted, there's nothing to replace yet
         * Thus insert the field(s) right after "shiftTimingsPrompt" field
         */
        self.fields.insertTimeFields(fieldsToPaste)
      } else {
        /**
         * The form already has time field(s).
         * Remove old and paste new fields in place of the old ones.
         */
        const firstFieldToRemove = shouldUseSameTiming ? distinctTimingFields[0] : sameTimingFields[0]
        const numberOfFieldsToRemove = shouldUseSameTiming ? distinctTimingFields.length : sameTimingFields.length
        const currentTimeFieldIndex = self.fields.list.findIndex(field => field === firstFieldToRemove)
        self.fields.list.splice(currentTimeFieldIndex, numberOfFieldsToRemove, ...fieldsToPaste)
      }
      self.schedule.sameTimeSchedule.setIsSameTiming(shouldUseSameTiming)
    },
    validateField(field: DraftJobFields): boolean {
      // do nothing, the action will be intercepted by relevant store's middleware and return boolean
      throw new Error(`Unexpected invokation of validateField method in the ${DRAFT_JOB} store`)
    },
  }))
  .actions(self => ({
    /**
     * Validates the entire form field-by-field
     * Each field puts its own errors and returns a boolean
     * Returns true if all fields return true, false otherwise
     */
    validateForm(): boolean {
      self.is_validating_form = true
      const fieldsToValidate = Object.values(DraftJobFields).filter(
        field => field !== DraftJobFields.preferences && field !== DraftJobFields.transport,
      )
      const hasValidationErrors = fieldsToValidate.map(self.validateField).findIndex(isValid => !isValid) !== -1

      console.log(fieldsToValidate.map(self.validateField))
      if (hasValidationErrors) {
        addBreadcrumb({
          message: 'Job Creation validation errors (frontend)',
          category: 'create job form',
          data: toJS(self),
        })
      }
      self.is_validating_form = false
      return !hasValidationErrors
    },

    assignApiErrorsToJob(errors: FormError) {
      addBreadcrumb({
        message: 'Job Creation validation errors (API)',
        category: 'create job form',
        data: errors,
      })
      Object.entries(errors).forEach(([fieldKey, fieldErrors]) => {
        const formattedError = Array.isArray(fieldErrors) ? toCommaSeparatedString(fieldErrors) : fieldErrors
        switch (fieldKey) {
          case 'quantity':
            self.quantity.error = formattedError
            break
          case 'work_category_id':
            self.category.error = formattedError
            break
          case 'instructions':
            self.description.error = formattedError
            break
          case 'transportation_rate':
            self.transport.error = formattedError
            break
          case 'assignment_type':
            self.manualSelection.manual_selection_error = formattedError
            break
          case 'client_location_id':
            self.location.error = formattedError
            break
          case 'work_requirement_ids':
            self.requirements.error = formattedError
            break
          case 'start_date':
            self.schedule.dateBoundaries.start_date_error = formattedError
            break
          case 'end_date':
            self.schedule.dateBoundaries.end_date_or_open_ended_error = formattedError
            break
          case 'is_accommodation_offered':
            self.accommodation.error = formattedError
            break
          case 'is_interview_required':
            self.manualSelection.interview_error = formattedError
            break
          case 'boarding_type':
            self.boarding.error = formattedError
            break
          case 'intervals':
            if (Array.isArray(fieldErrors)) {
              self.schedule.distinctTimeSchedule.error = formattedError
            } else {
              let anyError = null
              Object.entries(fieldErrors as Record<string, { start_time?: string[]; end_time?: string[] }>).forEach(
                ([date, dateConfig]) => {
                  const dateInstance = self.schedule.distinctTimeSchedule.map.get(date) as SingleDateInstance
                  if (dateConfig.start_time) {
                    dateInstance.start_time_error = dateConfig.start_time.join(', ')
                  }
                  if (dateConfig.end_time) {
                    dateInstance.end_time_error = dateConfig.end_time.join(', ')
                  }
                  anyError = dateInstance.start_time_error ?? dateInstance.end_time_error
                },
              )
              self.schedule.distinctTimeSchedule.error = anyError
            }
            break
          case 'seniority_level':
            self.experience.error = formattedError
            break
          case 'filter':
            const filterErrorsObj = fieldErrors as unknown as Record<string, string[]>
            Object.entries(filterErrorsObj).forEach(([filterKey, filterErrors]) => {
              const formattedFilterError = toCommaSeparatedString(filterErrors)
              switch (filterKey) {
                case 'gender':
                  self.gender.error = formattedFilterError
                  break
                case 'languages':
                  self.languages.error = formattedFilterError
                  break
                case 'certificates':
                  self.documents.error = formattedFilterError
                  break
                default:
                  throw new Error(`Unexpected filter validation error: ${filterKey}`)
              }
            })
            break
          case 'uniform_id':
            self.dresscode.error = formattedError
            break
          case 'order_id':
            self.error = formattedError
            break
          case 'contact_user_id':
            self.manager.error = formattedError
            break
          default:
            self.error = formattedError
            break
        }
      })
    },
  }))

export function createDraftJobInstance(snapshot?: DraftJobSnapshot) {
  const instance = DraftJobModel.create(snapshot)
  addMiddlewares(instance)
  return instance
}

export type DraftJobSnapshot = SnapshotOut<typeof DraftJobModel>
export type DraftJobInstance = Instance<typeof DraftJobModel>
