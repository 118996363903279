import { createRef, HTMLInputTypeAttribute, useState } from 'react'

import { Field, FieldProps } from '../field/field'
import { FormField } from '../form'
import './input.scss'

export type InputProps = {
  label?: string
  multiline?: boolean
  image?: React.ReactNode
  handleChange?: (newVal: string) => void
} & React.InputHTMLAttributes<HTMLInputElement>

function getDirectionByInputType(type: HTMLInputTypeAttribute | undefined) {
  switch (type) {
    case 'email': // for these types direction should always be left-to-right
    case 'number':
    case 'tel':
    case 'url':
      return 'ltr'
    default:
      return 'undefined' // will by set by css instead
  }
}

export function Input(props: InputProps) {
  const { label, multiline, image, handleChange, ...rest } = props

  const ref = createRef<HTMLInputElement>()

  const [focused, setFocused] = useState(false)

  const Tag = (props.multiline ? 'textarea' : 'input') as 'input'

  const hasLabel = label !== undefined
  const hasValue = props.value !== undefined && props.value !== ''

  return (
    <div className={`Input ${rest.className ?? ''}`} dir={getDirectionByInputType(props.type)}>
      {hasLabel && (focused || hasValue) ? (
        <div className="Input__labelContainer">
          <span className="Input__labelContainer__label">{label}</span>
        </div>
      ) : null}
      <Tag
        {...rest}
        placeholder={focused ? '' : label}
        ref={ref}
        className={`Input__input ${focused || hasValue ? 'active' : ''}`}
        type={props.type}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        value={props.value ?? ''}
        onChange={e => handleChange?.(e.target.value)}
        onFocus={e => {
          setFocused(true)
          rest.onFocus?.(e)
        }}
        onBlur={e => {
          setFocused(false)
          rest.onBlur?.(e)
        }}
      />
      {image && <div className="Input__imgContainer">{image}</div>}
    </div>
  )
}

export type InputFieldProps = InputProps & {
  field?: FormField<string>
}

export function InputField(props: InputFieldProps & FieldProps) {
  return (
    <Field description={props.description} required={props.required} errors={props.errors ?? props.field?.errors}>
      <Input
        {...props}
        value={props.value ?? props.field?.value ?? ''}
        handleChange={value => {
          if (props.field) {
            props.field.setData(value)
          } else {
            props.handleChange?.(value)
          }
        }}
      />
    </Field>
  )
}
