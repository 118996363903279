import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { SelectCategoryUIProps as UIProps } from './types'
import { useRedirect } from '../../../services/router'
import { useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'

export function createSelectCategoryContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return observer(() => {
    const redirect = useRedirect()
    const { t } = useTranslation()

    const { setWorkCategoryId } = useStore().data
    const handleSelection = (workCategoryId: number) => {
      setWorkCategoryId(workCategoryId)
      redirect(routes.signUpWithOrderForm, {})
    }

    return (
      <UIComponent
        mainTitle={t('sign_up.create_new_order_title')}
        handleSelection={handleSelection}
        title={t('sign_up.choose_work_category_title')}
        subtitle={t('sign_up.choose_work_category_subtitle')}
      />
    )
  })
}
