import { images } from './assets'
import './count-stepper.scss'

type StepperProps = {
  value: number
  handleValueChange: (newValue: number) => void
}

export const CountStepper = ({ value = 0, handleValueChange }: StepperProps) => (
  <div className="CountStepper">
    <div
      className="CountStepper__button"
      onClick={() => {
        if (value === 0) return
        handleValueChange(value - 1)
      }}
    >
      <img src={images.decreaseImg} className="CountStepper__button__decreaseImg" />
    </div>
    <span className={`CountStepper__value ${value > 0 ? 'active' : ''}`}>{value}</span>
    <div
      className="CountStepper__button"
      onClick={() => {
        handleValueChange(value + 1)
      }}
    >
      <img src={images.increaseImg} className="CountStepper__button__increaseImg" />
    </div>
  </div>
)
