import { useTranslation } from 'react-i18next'

import { OgramModal } from '../../ogram-modal/ogram-modal'
import modalImg from './assets/modal-image.svg'
import { ButtonPrimary } from '../../button/button-primary/button-primary'
import './tc-modal.scss'

type InputProps = {
  showModal: boolean
  setShowModal: (value: boolean) => void
  onContinue: () => void
}

export const TcModal = ({ showModal, setShowModal, onContinue }: InputProps) => {
  const { t } = useTranslation()

  return (
    <OgramModal
      overlayStyle={{ overflow: 'hidden' }}
      contentStyle={{ overflow: 'hidden' }}
      modalVisible={showModal}
      onClose={() => setShowModal(false)}
    >
      <div className="AffiliateTcModal__content">
        <span className="AffiliateTcModal__content__title">{t('common.tc')}</span>
        <img className="AffiliateTcModal__content__image" src={modalImg} />
        <p className="AffiliateTcModal__content__subtitle">{t('common_affiliate.tc_1')}</p>
        <p className="AffiliateTcModal__content__subtitle">{t('common_affiliate.tc_2')}</p>
        <ButtonPrimary
          text={t('common.continue')}
          action={onContinue}
          additionalStyle={{ minWidth: '304px', marginTop: '12px' }}
        />
      </div>
    </OgramModal>
  )
}
