import { observer } from 'mobx-react'

// import { useMutation, useQuery } from '../../../models/utils/mst-hooks'
// import { Loading } from '../../../components/loading/loading'
import './payment-method-form.scss'

export const PaymentMethodForm = observer(() => {
  // const { store, isLoading } = useQuery(st => st.data.getProfile())
  // const [logoutResult, logout] = useMutation(mut => mut.data.logout())
  // if (isLoading) return <Loading />

  return <div>123</div>
})
