import { useRef } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { InvoiceStatusType } from '../../../../../../models/invoice'
import { Link } from '../../../../../../services/router'
import { routes } from '../../../../../../routes'
import { Separator } from '../../../../../../components/app-layout/separator/separator'
import calendarImg from './assets/calendar.svg'
import orderImg from './assets/order.svg'
import './invoice-card.scss'
import { getInvoiceItemStatusLabel } from '../../../../../../utils/invoice'

type Props = {
  id: number
  status: InvoiceStatusType
  date: string | null
  dueDate: string | null
  amount: number
  currency: string
  invoiceNumber: string | null
  orderIds: number[]
}

export const InvoiceCard = ({ id, status, date, dueDate, amount, currency, invoiceNumber, orderIds }: Props) => {
  const { t } = useTranslation()

  const currentDate = useRef(moment(new Date())).current

  const momentDueDate = useRef(moment(dueDate, 'YYYY-MM-DD')).current
  const diff = currentDate.diff(momentDueDate)
  const daysDuration = Math.floor(moment.duration(diff).asDays())
  const isPastDate = daysDuration > 0

  const getDueDate = () => {
    if (daysDuration > 0) {
      return t('invoice_card.due_days_ago', { count: daysDuration })
    }
    if (daysDuration === 0) {
      return t('invoice_card.due_today')
    }
    if (daysDuration === -1) {
      return t('invoice_card.due_tomorrow')
    }
    return t('invoice_card.due_days_ahead', { count: -daysDuration })
  }

  return (
    <Link route={routes.invoice} params={{ id: String(id) }} className="InvoiceCard">
      <div className="InvoiceCard__row">
        <span className="InvoiceCard__numberLabel">{invoiceNumber ?? `INV-${id}`}</span>
        <div className={`InvoiceCard__statusContainer ${status === InvoiceStatusType.Paid ? 'success' : 'warning'}`}>
          <span className={`InvoiceCard__statusLabel ${status === InvoiceStatusType.Paid ? 'success' : 'warning'}`}>
            {getInvoiceItemStatusLabel({ status, due_date: dueDate }, t)}
          </span>
        </div>
      </div>
      {orderIds.length > 0 && (
        <>
          <Separator.Vertical height={13} />
          <div
            className={`InvoiceCard__ordersContainer${
              orderIds.length > 1 ? ' InvoiceCard__ordersContainer--multipleOrders' : ''
            }`}
          >
            <img src={orderImg} />
            <Separator.Horizontal width={9} />
            <div className="InvoiceCard__ordersContainer__orders">
              {orderIds.map(orderId => (
                <>
                  <Link
                    handleClick={e => e?.stopPropagation()}
                    route={routes.orderConfirmation}
                    params={{ orderId: String(orderId) }}
                    className="InvoiceCard__dateLabel"
                  >
                    Order #{orderId}
                  </Link>
                </>
              ))}
            </div>
          </div>
        </>
      )}
      <Separator.Vertical height={13} />
      <div className="InvoiceCard__dateContainer">
        <img src={calendarImg} />
        <Separator.Horizontal width={9} />
        <span className="InvoiceCard__dateLabel">{moment(date, 'YYYY-MM-DD').format('DD MMMM YYYY')}</span>
      </div>
      <Separator.Vertical height={8} />
      <div className="InvoiceCard__row">
        <div className="InvoiceCard__row">
          {status === InvoiceStatusType.Sent && (
            <>
              <Separator.Horizontal width={23} />
              <span className={`InvoiceCard__timeTermLabel ${isPastDate ? 'pastDate' : ''}`}>{getDueDate()}</span>
            </>
          )}
        </div>
        <span className="InvoiceCard__amountLabel">
          {String(amount).replace('.', ',')} {currency}
        </span>
      </div>
    </Link>
  )
}
