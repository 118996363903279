import { ApiError, ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { createBoundary } from '../../utils/create-boundary'
import { handleReactErrorCatch } from '../../services/sentry'

type ErrorMessages = {
  [ApiErrorTypes.ServerError]?: string
  [ApiErrorTypes.PermissionError]?: string
  [ApiErrorTypes.ClientError]?: string
  [ApiErrorTypes.NotFound]?: string
  [ApiErrorTypes.AuthError]?: string
  [ApiErrorTypes.ConnectionError]?: string
  [ApiErrorTypes.TooManyRequestsError]?: string
}

const defaultErrorMessages = {
  [ApiErrorTypes.ServerError]: 'error_boundary.default.server_error',
  [ApiErrorTypes.PermissionError]: 'error_boundary.default.permission_error',
  [ApiErrorTypes.ClientError]: 'error_boundary.default.client_error',
  [ApiErrorTypes.NotFound]: 'error_boundary.default.not_found_error',
  [ApiErrorTypes.AuthError]: 'error_boundary.default.not_authorized_error',
  [ApiErrorTypes.ConnectionError]: 'error_boundary.default.connection_error',
  [ApiErrorTypes.TooManyRequestsError]: 'error_boundary.default.too_many_requests_error',
}

const defaultErrorTypesToCatch = [
  ApiErrorTypes.ServerError,
  ApiErrorTypes.ConnectionError,
  ApiErrorTypes.ClientError,
  ApiErrorTypes.TooManyRequestsError,
]

export function withErrorBoundary<T extends Record<string, unknown>>(
  Component: React.ComponentType<T>,
  apiErrorMessages?: ErrorMessages | null,
  messageToAlwaysShow?: string | null,
  errorTypesToCatch: string[] = defaultErrorTypesToCatch,
  ignoreComponentOnError = false,
) {
  const ErrorBoundary = createBoundary(
    (props) => {
      const { t } = useTranslation()

      const error = props.error
      if (error !== null) {
        if (error instanceof ApiError) {
          if (errorTypesToCatch.includes(error.type)) {
            const errorMessage =
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              messageToAlwaysShow ??
              (apiErrorMessages ? apiErrorMessages[error.type as ApiErrorTypes] : null) ??
              defaultErrorMessages[error.type as ApiErrorTypes] ??
              'error_boundary.default.default_error'
            if (!ignoreComponentOnError) {
              toast.error(t(errorMessage), {
                onClick: props.resetError,
              })
            }

            return null
          }
        }
      }

      return props.children
    },
    {
      didCatch: (error: Error, errorInfo: React.ErrorInfo) => {
        if (error instanceof ApiError) {
          if (!errorTypesToCatch.includes(error.type)) {
            throw error
          }

          return handleReactErrorCatch(error, errorInfo)
        }

        throw error
      },
    },
  )

  return function BoundaryWrapper(props: T) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
