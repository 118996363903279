import { toast } from 'react-toastify'

export const copyToClipboard = (text: string, successText: string) => {
  navigator.clipboard.writeText(text)
  toast.info(successText, {
    position: 'bottom-center',
    autoClose: 1000,
    theme: 'dark',
    hideProgressBar: true,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    closeButton: false,
  })
}
