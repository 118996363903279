import './invite-referral-link.scss'
import { useTranslation } from 'react-i18next'

import clipImg from './assets/clip.svg'
import squaresImg from './assets/squares.svg'
import { copyToClipboard } from '../../../../services/copy-to-clipboard'

export const InviteReferralLink = ({ link }: { link: string }) => {
  const { t } = useTranslation()

  return (
    <div className="InviteReferralLink" onClick={() => copyToClipboard(link, t('common.text_copied'))}>
      <img className="InviteReferralLink__leftImg" src={clipImg} />
      <img className="InviteReferralLink__rightImg" src={squaresImg} />
      <input className="InviteReferralLink__input" type="text" value={link} disabled />
    </div>
  )
}
