import { Separator } from '../app-layout'
import './icon-button.scss'

type Props = {
  title: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  className?: string
  icon: string
}

export const IconButton = ({ title = 'button', type, onClick, className, icon }: Props) => {
  return (
    <button type={type} onClick={onClick} className={`Button ${className ?? ''}`}>
      <img src={icon} className="Button__icon" />
      <Separator.Horizontal width={15} />
      <span className="Button__label">{title}</span>
    </button>
  )
}
