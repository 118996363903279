import { useState, useEffect } from 'react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { observer } from 'mobx-react'

import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { SearchInput } from '../../../../components/search-input/search-input'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { useStore, useDelayedQuery } from '../../../../utils/mst-hooks'
import { CountStepper } from './components/count-stepper/count-stepper'
import { LeadForm } from '../../lead-form'
import './work-categories.scss'

export const WorkCategories = observer(({ values, updateField }: LeadForm) => {
  const [searchValue, setSearchValue] = useState('')
  const [calculatingList, setCalculatingList] = useState(false)
  const [filteredCategories, setFilteredCategories] = useState<typeof professionList>([])

  const { professionList, getProfessions } = useStore().data

  const {
    query: { isLoading: professionsLoading },
    enableQuery: loadProfessions,
  } = useDelayedQuery(getProfessions)

  useEffect(() => {
    if (professionList.length === 0) {
      loadProfessions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (professionList.length > 0) {
      setFilteredCategories(professionList)
    }
  }, [professionList, professionList.length])

  const filterCategories = useDebouncedCallback((value: string) => {
    setFilteredCategories(professionList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())))
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterCategories(value)
  }

  return (
    <div className="WorkCategories">
      <Separator.Vertical height={24} />
      {professionsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput value={searchValue} handleValueChange={handleSearchChange} />
          <Separator.Vertical height={16} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className="WorkCategories__list">
              {filteredCategories.map((profession) => (
                <div className="WorkCategories__list__row" key={profession.id}>
                  <span className="WorkCategories__list__row__title">{profession.name}</span>
                  <CountStepper
                    value={values.categoriesCount[profession.id]}
                    handleValueChange={(count) => {
                      updateField({
                        ...values.categoriesCount,
                        [profession.id]: count,
                      })
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  )
})
