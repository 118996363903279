import { types, SnapshotIn } from 'mobx-state-tree'

import { DataStore } from '../data-store/data-store'
import { OrderJobDetailsModel, orderJobDetailsActions } from './order-job-details'
import { StatisticsModel } from './statistics'
import { BasicOrderModel, basicOrderActions } from './basic-order'
import { orderSummaryActions } from './order-summary'
import { ListItemOrderModel, listItemOrdersActions } from './list-item-orders'

export const OrderStoreModel = types.model('OrderStore', {
  draft: types.map(BasicOrderModel),
  jobDetails: types.maybeNull(types.maybe(OrderJobDetailsModel)),
  statistics: types.maybeNull(StatisticsModel),
  ordersList: types.optional(
    types.model({
      map: types.map(ListItemOrderModel),
      nextPage: types.optional(types.maybeNull(types.number), 1),
    }),
    { map: {}, nextPage: null },
  ),
})

export const orderStoreActions = (self: DataStore) => ({
  ...basicOrderActions(self),
  ...orderJobDetailsActions(self),
  ...orderSummaryActions(self),
  ...listItemOrdersActions(self),
})

export type OrderStore = SnapshotIn<typeof OrderStoreModel>
