import { Carousel } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import hireImg from './assets/hire_1.svg'
import editCompanyImg from './assets/edit-company.svg'
import addPocImg from './assets/add-poc.svg'
import leanImg from './assets/lean.svg'
import scanQRImg from './assets/qr-scan.svg'
import becomeAffiliateImg from './assets/become-affiliate.svg'
import { CarouselSlide } from './carousel-slide'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { UIProps } from '../types'
import './uncompleted-actions-carousel.scss'
import { UIStateContext } from '../../ui-state-management/ui-state-management'

const AUTOPLAY_DURATION = 5000

export const UncompletedActionsCarousel = (props: UIProps) => {
  const { t } = useTranslation()
  const { isRtl } = useContext(UIStateContext)

  if (props.isLoading) {
    return <LoadingSpinner />
  }

  return (
    <Carousel swipe draggable autoplay autoplaySpeed={AUTOPLAY_DURATION} rtl={isRtl}>
      {props.showConnectLeanSlide && (
        <CarouselSlide
          containerClassNames="CarouselSlide--hire"
          icon={leanImg}
          title={t('uncompleted_actions_carousel.connect_your_bank_account')}
          actions={props.connectLeanActions}
          imageStyle={{ width: '60px', marginLeft: '30px', marginRight: '15px' }}
        />
      )}
      <CarouselSlide
        containerClassNames="CarouselSlide--hire"
        icon={hireImg}
        title={t('uncompleted_actions_carousel.looking_for_staff')}
        actions={props.hireActions}
      />
      {props.showEditCompanySlide && (
        <CarouselSlide
          containerClassNames="CarouselSlide--edit-company"
          icon={editCompanyImg}
          title={t('uncompleted_actions_carousel.edit_company')}
          actions={props.editCompanyActions}
        />
      )}
      {props.showAddPocSlide && (
        <CarouselSlide
          containerClassNames="CarouselSlide--add-poc"
          icon={addPocImg}
          title={t('uncompleted_actions_carousel.add_poc')}
          actions={props.addPocActions}
        />
      )}
      {props.showBecomeAffiliateSlide && (
        <CarouselSlide
          containerClassNames="CarouselSlide--become-affiliate"
          icon={becomeAffiliateImg}
          title={t('uncompleted_actions_carousel.become_affiliate')}
          actions={props.becomeAffiliateActions}
        />
      )}
      {props.showScanQRSlide && (
        <CarouselSlide
          containerClassNames="CarouselSlide--scan-qr"
          icon={scanQRImg}
          title={t('qr_scan.carousel_title')}
          actions={props.scanQRActions}
        />
      )}
    </Carousel>
  )
}
