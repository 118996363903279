import { Fragment } from 'react'

import { Page } from '../../../components/page-onboarding/page-onboarding'
import { Separator } from '../../../components/app-layout/separator/separator'
import arrowRight from './assets/arrow-right.svg'
import './knowledge-item.scss'

type KnowledgeItemProps = {
  title: string
  pages: Page[]
  showSeparator: boolean
  selectOnboarding: (index: number) => void
}

export const KnowledgeItem = ({ title, pages, showSeparator, selectOnboarding }: KnowledgeItemProps) => {
  return (
    <div className="KnowledgeCenterItem">
      <ul className="KnowledgeCenterItem__list">
        <div
          onClick={() => {
            selectOnboarding(0)
          }}
          className="KnowledgeCenterItem__list__header"
        >
          <span className="KnowledgeCenterItem__list__header__title">{title}</span>
        </div>
        {pages.map((page, index) => {
          if (page.title === null) return null
          return (
            <Fragment key={page.title}>
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  selectOnboarding(index)
                }}
                className="KnowledgeCenterItem__list__item"
              >
                <span className="KnowledgeCenterItem__list__item__label">{page.title}</span>
                <img src={arrowRight} className="KnowledgeCenterItem__list__item__img" />
              </div>
              <div className="KnowledgeCenterItem__list__separator" />
            </Fragment>
          )
        })}
      </ul>
      {showSeparator && <Separator.Vertical height={10} />}
    </div>
  )
}
