import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const jobRequirementsPage = {
  title: t('order_creation_onboarding.job_requirements_title'),
  content: [
    { image: { src: images.jobRequirementsImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.job_requirements') },
  ],
}
