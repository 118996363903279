import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Popup } from '../popup/popup'
import infoIcon from './assets/info-icon.svg'
import { ButtonPrimary } from '../button/button-primary/button-primary'
import { useMutation, useStore } from '../../utils/mst-hooks'
import './sp-rating-popup.scss'
import { useBottomListListener } from '../../utils/use-bottom-list-listener'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { TrackedEvent, trackingService } from '../../services/tracking-service'
import { SpItem } from './components/sp-item/sp-item'

export const SpRatingPopup = observer(
  ({ jobId, isActive, onClose }: { jobId: number; isActive: boolean; onClose: () => void }) => {
    const [atLeastOneOGRated, setAtLeastOneOGRated] = useState(false)
    const [quantityOfOGsRated, setQuantityOfOGsRated] = useState(0)

    const { t } = useTranslation()

    const { getSPsToRate, spsToRateList, setSPLastJobRating, spsToRateNextPage } = useStore().data

    const [getSPsToRateStatus, getSPsToRateRequest] = useMutation(
      ({ collect, page }: { collect: boolean; page?: number | null }) =>
        getSPsToRate({
          collect,
          jobId,
          page: page ?? spsToRateNextPage ?? 1,
        }),
      {
        onError: err => {
          toast.error(err.data.errors?.note[0])
        },
        filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
      },
    )

    const [, setSPLastJobRatingRequest] = useMutation(
      ({ spId, rating }: { spId: number; rating: number }) => setSPLastJobRating(spId, rating, null, null, jobId),
      {
        onSuccess: result => {
          trackingService.track(TrackedEvent.RatingSetAfterAttendanceReview)
          if (!atLeastOneOGRated) {
            setAtLeastOneOGRated(true)
          }
          setQuantityOfOGsRated((prevState: number) => prevState + 1)
        },
        onError: err => {
          toast.error(err.data.errors?.note[0])
        },
        filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
      },
    )

    useEffect(() => {
      getSPsToRateRequest({ collect: false, page: 1 })
    }, [])

    useEffect(() => {
      if (isActive) {
        trackingService.track(TrackedEvent.RatingPopupAfterAttendanceReviewShown)
      }
    }, [isActive])

    useEffect(() => {
      if (atLeastOneOGRated) {
        trackingService.track(TrackedEvent.RatingSetAfterAttendanceReviewAtLeastOnce)
      }
    }, [atLeastOneOGRated])

    useEffect(() => {
      if (quantityOfOGsRated > 0 && quantityOfOGsRated === spsToRateList.length) {
        trackingService.track(TrackedEvent.RatingSetAfterAttendanceReviewForAllOGs)
      }
    }, [quantityOfOGsRated])

    const handleScroll = useBottomListListener(() => {
      if (spsToRateNextPage) {
        getSPsToRateRequest({
          collect: true,
        })
      }
    })

    return (
      <Popup
        isActive={isActive}
        content={
          <div className="SpRatingPopup">
            <span className="SpRatingPopup__title">{t('rating_popup.title')}</span>
            <div className="SpRatingPopup__alert">
              <img src={infoIcon} />
              <span className="SpRatingPopup__alert__text">
                <span className="SpRatingPopup__alert__text__highlight">{t('rating_popup.subtitle_1')}</span>
                <span className="SpRatingPopup__alert__text__common">{t('rating_popup.subtitle_2')}</span>
              </span>
            </div>
            <span className="SpRatingPopup__sectionTitle">{t('rating_popup.ogs_to_rate')}</span>
            <div className="SpRatingPopup__spList" onScroll={handleScroll}>
              {spsToRateList.map(sp => (
                <SpItem
                  key={sp.id}
                  sp={sp}
                  changeRating={rating => setSPLastJobRatingRequest({ spId: sp.id, rating: rating })}
                />
              ))}
              {getSPsToRateStatus.isLoading && <LoadingSpinner />}
            </div>
            <ButtonPrimary text={t('common.done')} action={onClose} />
          </div>
        }
        close={onClose}
      />
    )
  },
)
