import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const LanguageModel = types.model('Language', {
  id: types.identifierNumber,
  name: types.string,
})

export const languageActions = (self: DataStore) => ({
  getLanguages: flow(function* () {
    const languages = (yield self.request('get', `common/languages`)) as LanguageSnapshot[]
    languages.forEach((language) => {
      self.languages.set(String(language.id), language)
    })
  }),

  updateUserLanguage: flow(function* (lang: string) {
    yield self.request('post', 'common/user/lang', undefined, {
      headers: { 'x-lang-code': lang },
    })
  }),
})

export const languageViews = (self: DataStore) => ({
  get languageList() {
    return Array.from(self.languages.values())
  },

  getLanguagesNames(ids: number[]) {
    return Array.from(self.languages.values())
      .filter((item) => ids.includes(item.id))
      .map((item) => item.name)
  },

  getLanguageIdByTitle(title: string) {
    return Array.from(self.languages.values()).find((language) => language.name === title)?.id
  },
})

export type Language = SnapshotIn<typeof LanguageModel>
export type LanguageSnapshot = SnapshotOut<typeof LanguageModel>
