import clockImg from './clock.svg'
import closeImg from './close.svg'
import dropdownHorizontalImg from './dropdown-horizontal.svg'
import dropdownVerticalImg from './dropdown-vertical.svg'
import notAttendedImg from './not-attended.svg'

export const images = {
  clockImg,
  closeImg,
  dropdownHorizontalImg,
  dropdownVerticalImg,
  notAttendedImg,
}
