import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const DescriptionModel = types
  .model('Description', {
    value: types.maybeNull(types.string),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(description: string | null) {
      self.value = description
      self.error = null
    },

    validate() {
      if (self.value === null || self.value.length < 1) {
        self.error = t('job_draft_details.description_required')
        return false
      } else if (self.value.length > 20000) {
        self.error = t('job_draft_details.description_too_long')
      } else {
        self.error = null
        return true
      }
    },
  }))

export function createDescriptionMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.description) {
      return abort(targetInstance.description.validate())
    }
    return next(call)
  })
}

export type DescriptionInstance = Instance<typeof DescriptionModel>
