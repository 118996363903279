import { useTranslation } from 'react-i18next'

import cameraImg from './assets/camera.svg'
import starImg from './assets/star.svg'
import './question-item.scss'

type QuestionItemProps = {
  description: string
  rating: number | null
  className?: string
  onClick: () => void
}

export function QuestionItem({ description, rating, className, onClick }: QuestionItemProps) {
  const { t } = useTranslation()
  return (
    <div className={`QuestionItem ${className}`}>
      <img src={cameraImg} alt="camera" className="QuestionItem__cameraImg" />
      <div className="QuestionItem__content">
        <div className="QuestionItem__description">{description}</div>
        <div className="QuestionItem__bottom">
          <div className="QuestionItem__linkLabel" onClick={onClick}>
            {t('applicant_details.view_answer')}
          </div>
          <div
            className={`QuestionItem__ratingContainer ${rating === null ? 'QuestionItem__ratingContainer_hidden' : ''}`}
          >
            <img src={starImg} alt="rating" className="QuestionItem__starImg" />
            <div className="QuestionItem__ratingLabel">{rating}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
