import { v4 as uuidv4 } from 'uuid'

export const generateDeviceId = () => {
  let deviceId: string = localStorage.getItem('device_id') as string
  if (deviceId) {
    return deviceId
  }
  deviceId = uuidv4()
  localStorage.setItem('device_id', deviceId)
  return deviceId
}
