import assert from './utils/assert'
import objectToGetParams from './utils/object-to-get-params'
import createShareButton from './hocs/create-share-button'
import icon from './assets/linkedin.svg'

type Options = {
  /** The url-encoded title value that you wish you use. */
  title?: string
  /** The url-encoded description that you wish you use. */
  summary?: string
  /** The url-encoded source of the content (e.g. your website or application name) */
  source?: string
}

function linkedinLink(url: string, { title, summary, source }: Options) {
  assert(url, 'linkedin.url')

  return `https://linkedin.com/shareArticle${objectToGetParams({ url, mini: 'true', title, summary, source })}`
}

const Button = createShareButton<Options>(
  'linkedin',
  linkedinLink,
  ({ title, summary, source }) => ({ title, summary, source }),
  {
    windowWidth: 750,
    windowHeight: 600,
  },
)

const LinkedInShareButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <Button url={url} title={title} summary={title}>
      <img src={icon} style={{ cursor: 'pointer' }} />
    </Button>
  )
}

export default LinkedInShareButton
