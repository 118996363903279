import './section-title.scss'

interface SectionTitleProps {
  image?: string
  title: string
}

const SectionTitle = ({ image, title }: SectionTitleProps) => {
  return (
    <div className="SectionTitle">
      {image && <img src={image} />}
      <h3>{title}</h3>
    </div>
  )
}

export default SectionTitle
