import assert from './utils/assert'
import objectToGetParams from './utils/object-to-get-params'
import createShareButton from './hocs/create-share-button'
import icon from './assets/facebook.svg'

function facebookLink(url: string, { quote, hashtag }: { quote?: string; hashtag?: string }) {
  assert(url, 'facebook.url')

  return `https://www.facebook.com/sharer/sharer.php${objectToGetParams({
    u: url,
    quote,
    hashtag,
  })}`
}

const Button = createShareButton<{ quote?: string; hashtag?: string }>(
  'facebook',
  facebookLink,
  (props) => ({
    quote: props.quote,
    hashtag: props.hashtag,
  }),
  {
    windowWidth: 550,
    windowHeight: 400,
  },
)

const FacebookShareButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <Button url={url} quote={title} title={title}>
      <img src={icon} style={{ cursor: 'pointer' }} />
    </Button>
  )
}

export default FacebookShareButton
