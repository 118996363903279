import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Header, Separator } from '../../../../components/app-layout'
import { Tabs } from '../../../../components/tabs/tabs'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { UIProps } from '../types'
import { PaymentItem } from './components/payment-item'
import { PaymentStatus } from '../../../../models/payment'
import './payment-list.scss'

function getEmptyListText(paymentStatus: PaymentStatus, t: TFunction) {
  if (paymentStatus === PaymentStatus.Pending) {
    return t('payment.empty_pending_list')
  }

  if (paymentStatus === PaymentStatus.Processing) {
    return t('payment.empty_processing_list')
  }

  return t('payment.empty_completed_list')
}

export const PaymentList = ({
  loadMore,
  tabs,
  paymentsList,
  isLoading,
  onItemClick,
  onPayClick,
  paymentStatus,
}: UIProps) => {
  const { t } = useTranslation()

  return (
    <div className="PaymentList" onScroll={loadMore}>
      <Header title={t('payment.payments')} />
      <Separator.Vertical height={35} />
      <div className="PaymentList__container">
        <Tabs tabs={tabs} />
        <Separator.Vertical height={20} />
        {paymentsList.length > 0 ? (
          <Fragment>
            {paymentsList.map((paymentListItem) => (
              <PaymentItem
                key={paymentListItem.id}
                paymentItem={paymentListItem}
                onPayClick={() => onPayClick(paymentListItem)}
                onItemClick={onItemClick}
              />
            ))}
          </Fragment>
        ) : (
          !isLoading && <p className="PaymentList__emptyList">{getEmptyListText(paymentStatus, t)}</p>
        )}
        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  )
}
