const MIN_FLEX = 0
const MAX_FLEX = 1
const MIN_HEIGHT = 0
const MIN_WIDTH = 0

const Vertical = ({ height, className }: { height: number; className?: string }) => (
  <div style={{ minHeight: height }} className={className} />
)

const Horizontal = ({ width, className }: { width: number; className?: string }) => (
  <div style={{ minWidth: width }} className={className} />
)

const Spacer = ({ flex = MAX_FLEX, minHeight, minWidth }: { flex?: number; minHeight?: number; minWidth?: number }) => {
  if (typeof flex === 'number' && (flex < MIN_FLEX || flex > MAX_FLEX)) {
    throw new Error(`Invalid flex value, expected range 0..1, got ${flex} instead`)
  }

  return <div style={{ flex, minHeight: minHeight ?? MIN_HEIGHT, minWidth: minWidth ?? MIN_WIDTH }} />
}

export const Separator = { Vertical, Horizontal, Spacer }
