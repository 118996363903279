import { types } from 'mobx-state-tree'

import { PastSPSnapshot } from '../models/past-workers'

export type ListItemProps = {
  sp: PastSPSnapshot
  setSPNote: (params: { spId: number; note: string }) => void
  setSPLastJobRating: (params: {
    spId: number
    rating: number
    locationId?: number | null
    workCategoryId?: number | null
  }) => void
  toggleIsFavorite: (params: { spId: number }) => void
}

const PastWorkers = types
  .model('PastWorkers', {
    searchFilters: types.model({
      searchString: types.string,
      locationId: types.maybeNull(types.number),
      designationId: types.maybeNull(types.number),
      isFavorite: types.boolean,
    }),
  })
  .actions(self => ({
    setSearchString(searchString: string) {
      self.searchFilters.searchString = searchString
    },
    setLocationId(locationId: number | null) {
      self.searchFilters.locationId = locationId
    },
    setDesignationId(designationId: number | null) {
      self.searchFilters.designationId = designationId
    },
    setIsFavorite(isFavorite: boolean) {
      self.searchFilters.isFavorite = isFavorite
    },
    reset() {
      self.searchFilters.searchString = ''
      self.searchFilters.locationId = null
      self.searchFilters.designationId = null
      self.searchFilters.isFavorite = false
    },
  }))
  .views(self => ({
    get activeFiltersCount() {
      return [self.searchFilters.locationId, self.searchFilters.designationId, self.searchFilters.isFavorite].filter(
        value => Boolean(value),
      ).length
    },
  }))

export const pastWorkersStore = PastWorkers.create({
  searchFilters: {
    searchString: '',
    locationId: null,
    designationId: null,
    isFavorite: false,
  },
})
