import { Fragment } from 'react'
import { Field, FieldInputProps, FieldMetaProps, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import './report-issue-form.scss'

type UIProps = {
  onReportIssueSubmit: ({ message }: { message: string }) => void
  setReportIssueModalVisible: (isVisible: boolean) => void
  errors: string
  isSubmitting: boolean
}

export const ReportIssueForm = ({ onReportIssueSubmit, setReportIssueModalVisible, errors, isSubmitting }: UIProps) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="modalReportIssue__header">
        <p className="modalReportIssue__title">{t('invoice.report_issue_modal_title')}</p>
      </div>
      <div className="modalReportIssue__body">
        <Formik
          initialValues={{ message: '' }}
          validationSchema={Yup.object().shape({
            message: Yup.string().required(t('invoice.report_issue_message_required')),
          })}
          onSubmit={onReportIssueSubmit}
        >
          <Form autoComplete="off" noValidate>
            <Field name="message">
              {({
                field,
                meta,
              }: {
                field: FieldInputProps<string | number | readonly string[] | undefined>
                meta: FieldMetaProps<unknown>
              }) => (
                <Fragment>
                  <textarea
                    {...field}
                    placeholder={t('invoice.report_issue_message_placeholder')}
                    className="modalReportIssue__textArea"
                  />
                  {meta.error && <p className="error-msg">{meta.error}</p>}
                </Fragment>
              )}
            </Field>
            {errors && <p className="error-msg">{errors}</p>}
            <div className="modalReportIssue__actions">
              <button
                disabled={isSubmitting}
                type="submit"
                className="modalReportIssue__actions__button modalReportIssue__actions__button--submit"
              >
                {isSubmitting ? t('invoice.issue_is_submitting') : t('invoice.report_issue_submit')}
              </button>
              <button
                className="modalReportIssue__actions__button modalReportIssue__actions__button--cancel"
                onClick={() => setReportIssueModalVisible(false)}
              >
                {t('invoice.report_issue_cancel')}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </Fragment>
  )
}
