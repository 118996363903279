import { observer } from 'mobx-react'

import { useStore } from '../../../utils/mst-hooks'
import { BecomeAffiliateModal } from '../become-affiliate-modal/become-affiliate-modal'

export const TcAcceptedChecker = observer(() => {
  const { companyDetails, markAffiliateTCAsAccepted } = useStore().data
  const isAffiliateTCAccepted = Boolean(companyDetails?.is_affiliate_tc_accepted)

  if (isAffiliateTCAccepted) {
    return null
  }

  return (
    <BecomeAffiliateModal
      showBecomeAffiliateModal
      setShowBecomeAffiliateModal={(value: boolean) => {}}
      onSuccess={markAffiliateTCAsAccepted}
    />
  )
})
