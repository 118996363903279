import arrowDownImg from './arrow-down.svg'
import plantImg from './plant.svg'
import smileImg from './smile.svg'
import thumbUpImg from './thumb-up.svg'
import homeApplicantReviewImg from './home-applicant-review.png'
import homeAttendanceReview from './home-attendance-review.png'
import homeHireImg from './home-hire.png'
import homeOrdersConfirmationImg from './home-orders-confirmation.png'
import homeRatingRequstsImg from './home-rating-requests.png'

export const images = {
  arrowDownImg,
  plantImg,
  smileImg,
  thumbUpImg,
  homeApplicantReviewImg,
  homeAttendanceReview,
  homeHireImg,
  homeOrdersConfirmationImg,
  homeRatingRequstsImg,
}
