import { TimeInput } from '../../../../../../../components/form/time-input/time-input'
import './time.scss'

export type TimeProps = {
  value: string | null
  label: string
  disabled: boolean
  highlighted?: boolean
  handleChange: (value: string) => void
}

export function Time({ value, label, disabled, highlighted = false, handleChange }: TimeProps) {
  return (
    <div className="ScheduleTime">
      <TimeInput
        disabled={disabled}
        label={label}
        value={value}
        handleChange={handleChange}
        className={`ScheduleTime__timeInput ${disabled ? 'disabled' : ''} ${highlighted ? 'highlighted' : ''}`}
        useDropdownWithInput
      />
    </div>
  )
}
