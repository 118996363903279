import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import { Link } from '../../../services/router'
import { routes } from '../../../routes'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { Separator } from '../../../components/app-layout/separator/separator'
import ShiftCard from '../components/shift-card/shift-card'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import './shifts-list.scss'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

const ShiftsList = withErrorBoundary(
  observer(() => {
    const { fetchAttendanceReportJob, attendanceReportJobView } = useStore().data
    const shifts = attendanceReportJobView()

    const getQueryShifts = useQuery(async () => fetchAttendanceReportJob(), {
      filterError: [ApiErrorTypes.ClientError],
    })

    const { t } = useTranslation()

    return (
      <>
        {getQueryShifts.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="ShiftsList">
            <Header title={t('shifts_attendance_list.title')} />
            <Separator.Vertical height={28} />
            {shifts.length ? (
              <div className="ShiftsList__list">
                {shifts.map((shift) => (
                  <div className="ShiftsList__shiftItem" key={shift.id}>
                    <Link
                      route={routes.attendanceShiftDetails}
                      params={{ id: shift.id }}
                      className="ShiftsList__shiftItem__link"
                    >
                      <ShiftCard shiftData={shift} />
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <p className="ShiftsList__hint">{t('shifts_attendance_list.empty_list')}</p>
            )}
          </div>
        )}
      </>
    )
  }),
)

export default ShiftsList
