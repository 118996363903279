import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { TimeInput } from '../../../../form/time-input/time-input'
import { JobDraftFieldError } from '../error/error'
import './same-time.scss'
import { ActionAlert, Theme } from '../../../../action-alert/action-alert'

export const SameTime = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const hasStartTimeErrors = job.schedule.sameTimeSchedule.startTimeHasErrors
  const hasEndimeErrors = job.schedule.sameTimeSchedule.endTimeHasErrors

  return (
    <div className="SameTime">
      {job.schedule.sameTimeSchedule.is_less_than_24h && (
        <ActionAlert
          theme={Theme.Orange}
          title={t('job_draft_details.less_than_24h_warning')}
          action={() => {}}
          isActionInProgress={false}
          withCloseButton={false}
          style={{ marginTop: '15px' }}
        />
      )}
      <span className="Popup__title">{t('job_draft_details.what_are_job_timings')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.select_same_time')}</span>
      <div className="SameTime__innerContainer">
        <TimeInput
          label={t('job_draft_details.start_time')}
          value={job.schedule.sameTimeSchedule.same_start_time ?? ''}
          handleChange={time => {
            job.schedule.setSameStartTime(time)
          }}
          className={`${job.schedule.sameTimeSchedule.same_start_time ?? 'SameTime__timePicker wide'} ${
            hasStartTimeErrors ? 'error' : ''
          }`}
          useDropdownWithInput
          required
        />
        {hasStartTimeErrors && <JobDraftFieldError error={job.schedule.sameTimeSchedule.same_start_time_error} />}
        <Separator.Vertical height={10} />
        <TimeInput
          label={t('job_draft_details.end_time')}
          value={job.schedule.sameTimeSchedule.same_end_time ?? ''}
          handleChange={time => {
            job.schedule.setSameEndTime(time)
          }}
          className={`${job.schedule.sameTimeSchedule.same_end_time ?? 'SameTime__timePicker wide'} ${
            hasEndimeErrors ? 'error' : ''
          }`}
          pickerStartTime={job.schedule.sameTimeSchedule.same_start_time ?? '00:00'}
          useDropdownWithInput
          showDurationFromPickerStartTime
          required
        />
        {hasEndimeErrors && <JobDraftFieldError error={job.schedule.sameTimeSchedule.same_end_time_error} />}
      </div>
    </div>
  )
})
