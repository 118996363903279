import './field.scss'

type FieldProps = {
  content: string | null | undefined
  placeholder: string
  error?: string | string[] | null
  onPress: () => void
}

export const Field = ({ content, placeholder, error, onPress }: FieldProps) => (
  <div onClick={onPress} className="JobDraftField">
    {content ? (
      <>
        <span className="JobDraftField__placeholder-minimized">{placeholder}</span>
        <span className="JobDraftField__label">{content}</span>
      </>
    ) : (
      <span className="JobDraftField__placeholder">{placeholder}</span>
    )}
    {error?.length && (
      <span className="JobDraftField__errorLabel">{Array.isArray(error) ? error.join(', ') : error}</span>
    )}
  </div>
)
