import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { DaySelector } from '../../../../components/day-selector/day-selector'
import { LeadForm } from '../../lead-form'
import './days.scss'

export const Days = ({ values, updateField }: LeadForm) => {
  const daysSelected = values.days.length

  const { t } = useTranslation()

  return (
    <div className="Days">
      <Separator.Vertical height={67} />
      <DaySelector label={null} selectedDays={values.days} setSelectedDays={updateField} />
      {daysSelected > 0 && (
        <>
          <Separator.Vertical height={18} />
          <span className="Days__selectionLabel">
            {daysSelected > 1
              ? t('lead_form.days_selected', { count: daysSelected })
              : t('lead_form.day_selected', { count: daysSelected })}
          </span>
        </>
      )}
    </div>
  )
}
