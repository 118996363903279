import { flow, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

const SHIFT_EXTENSION_DEFAULT_TIME = 30

const ShiftExtensionTimeOptionModel = types.model('ShiftExtensionTimeOption', {
  label: types.string,
  value: types.number,
  key: types.string,
})

export const ConfigModel = types.model('Config', {
  shift_extension_time_options: types.model({
    default: types.number,
    options: types.array(ShiftExtensionTimeOptionModel),
  }),
})

export const configActions = (self: DataStore) => ({
  getConfig: flow(function* () {
    self.config = yield self.request('get', 'client/config')
  }),
})

export const configViews = (self: DataStore) => ({
  get shiftExtensionTimeOptions() {
    return self.config ? self.config.shift_extension_time_options.options : []
  },

  get shiftExtensionDefaultTime() {
    return self.config ? self.config.shift_extension_time_options.default : SHIFT_EXTENSION_DEFAULT_TIME
  },
})
