import checkOutImg from './check-out.svg'
import heartImg from './heart.svg'
import heartFilledImg from './heart-filled.svg'
import messageImg from './message.svg'
import phoneImg from './phone.svg'
import extendImg from './extend.svg'

export const images = {
  checkOutImg,
  heartImg,
  heartFilledImg,
  messageImg,
  phoneImg,
  extendImg,
}
