import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { isMainScreen, Route, useRedirect } from '../../services/router'
import { useStore } from '../../utils/mst-hooks'
import { routes } from '../../routes'
import { UserRole } from '../../models/account'
import { Navigation } from '../../pages'
import { ProtectedRoute } from '../protected-route/protected-route'
import { Header } from './index'
import './app-layout.scss'
import { ComingSoonPage } from '../../pages/coming-soon-page/coming-soon-page'
import { useLanguageUpdate } from '../../utils/use-language-update'
import { DesktopHeader } from '../../pages/coming-soon-page/components/desktop-header/desktop-header'

export const ComingSoonLayout = observer(() => {
  const { logged } = useStore().data

  const redirect = useRedirect()

  useLanguageUpdate()

  const { t } = useTranslation()

  return (
    <div id="main_layout" className="AppLayout" style={logged && isMainScreen() ? { background: '#fff' } : {}}>
      <div id="modals"></div>
      <main className="AppLayout__main">
        <Route route={routes.index}>
          {logged ? <ComingSoonPage /> : <Navigation.AuthStack.LoginPage onLogin={() => redirect(routes.index, {})} />}
        </Route>
        <Route route={routes.signUp}>{p => <Navigation.AuthStack.SignUpPage redirectTo={p.redirect} />}</Route>
        <Route route={routes.login}>
          <Navigation.AuthStack.LoginPage onLogin={() => redirect(routes.index, {})} />
        </Route>
        <Route route={routes.forgotPassword}>
          <Navigation.AuthStack.ForgotPasswordPage />
        </Route>
        <Route route={routes.resetPassword}>
          <Navigation.AuthStack.ResetPasswordPage />
        </Route>
        <Route route={routes.editProfile}>
          <ProtectedRoute allowedRoles={[UserRole.Admin, UserRole.Owner]}>
            <Header title={t('profile_form.title')} />
            <Navigation.ProfileStack.ProfileFormPage />
          </ProtectedRoute>
        </Route>
        <Route route={routes.completeRegistration}>
          <>
            <DesktopHeader title={t('complete_registration.title')} hideLogo={false} />
            <Navigation.CompleteRegistrationStack.CompleteRegistrationPage navigateToScreenName="home" />
          </>
        </Route>
        <Route route={routes.signSLA}>
          <>
            <DesktopHeader title={t('sign_sla.title')} hideLogo={false} showBack />
            <Navigation.CompleteRegistrationStack.SignSLAPage />
          </>
        </Route>
        <Route route={routes.onboardingFinish}>
          {p => (
            <Navigation.CompleteRegistrationStack.OnboardingFinishedPage
              navigateToScreenName={p.navigateToScreenName}
            />
          )}
        </Route>
      </main>
    </div>
  )
})
