import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import invoiceIcon from '../assets/invoice-icon.svg'
import refundIcon from '../assets/refund-icon.svg'
import { StatementItemSnapshot, StatementItemType } from '../../../../../models/account-statement'
import { getStatementItemPrefix } from '../helpers'
import { getInvoiceItemStatusLabelStyles } from '../../../utils'
import { getInvoiceItemStatusLabelWithDueDays } from '../../../../../utils/invoice'

type InputProps = {
  statementItem: StatementItemSnapshot
  onStatementItemClick: (value: number) => void
}

export const StatementItem = ({ statementItem, onStatementItemClick }: InputProps) => {
  const { t } = useTranslation()

  return (
    <div
      key={statementItem.id}
      className="AccountStatement__invoice"
      onClick={() => onStatementItemClick(statementItem.id)}
    >
      <div className="AccountStatement__invoice__content">
        <div className="AccountStatement__invoice__content__left">
          <img src={statementItem.type === 'invoice' ? invoiceIcon : refundIcon} />
        </div>
        <div className="AccountStatement__invoice__content__right">
          <div className="AccountStatement__invoice__content__titles">
            <span className="AccountStatement__invoice__content__title">
              {getStatementItemPrefix(statementItem)}-{statementItem.id}
            </span>
            <span className="AccountStatement__invoice__content__title">
              {statementItem.currency_code} {statementItem.amount}
            </span>
          </div>
          <div className="AccountStatement__invoice__content__details">
            <span className="AccountStatement__invoice__content__detailsItem">
              {moment(statementItem.due_date).format('MMM DD, YYYY')}
            </span>
            <span className="AccountStatement__invoice__content__detailsItem">.</span>
            <span className="AccountStatement__invoice__content__detailsItem">
              {statementItem.type === StatementItemType.Invoice
                ? t('account_statement.invoice')
                : t('account_statement.credit_note')}
            </span>
            {statementItem.type === StatementItemType.Invoice && (
              <Fragment>
                <span className="AccountStatement__invoice__content__detailsItem">.</span>
                <span
                  className="AccountStatement__invoice__content__detailsItem"
                  style={getInvoiceItemStatusLabelStyles(statementItem)}
                >
                  {getInvoiceItemStatusLabelWithDueDays(statementItem, t)}
                </span>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
