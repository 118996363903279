import moment from 'moment/moment'
import { TFunction } from 'i18next'

import { PaymentItemSnapshot, PaymentMethod, PaymentStatus } from '../../models/payment'
import newCardImg from './payment-methods/ui/assets/new-card.svg'
import wireImg from './payment-methods/ui/assets/wire.svg'
import { InvoiceStatusType, pendingStatuses } from '../../models/invoice'
import { getDaysDuration } from '../../utils/invoice'

export const getPaymentDetailsStatusLabel = (paymentStatus: string) => {
  if (paymentStatus === PaymentStatus.Pending) {
    return 'payment.status_unpaid'
  }

  if (paymentStatus === PaymentStatus.Processing) {
    return 'payment.status_processing'
  }

  if (paymentStatus === PaymentStatus.Completed) {
    return 'payment.status_paid'
  }

  if (paymentStatus === PaymentStatus.Cancelled) {
    return 'payment.status_cancelled'
  }

  return 'payment.status_refunded'
}

export const getPaymentStatusLabelForListItem = (payment: PaymentItemSnapshot, t: TFunction) => {
  if (payment.status === Number(PaymentStatus.Processing)) {
    return t('payment.status_processing')
  }

  if (payment.status === Number(PaymentStatus.Completed)) {
    return t('payment.status_paid')
  }

  if (payment.status === Number(PaymentStatus.Cancelled)) {
    return t('payment.status_cancelled')
  }

  if (payment.status === Number(PaymentStatus.Refunded)) {
    return t('payment.status_refunded')
  }

  if (payment.status === Number(PaymentStatus.Pending)) {
    const { isPastDate, daysDuration } = getDaysDuration(payment)

    if (!isPastDate) {
      return t('payment.status_unpaid')
    }

    if (daysDuration > 0) {
      return t('invoice_card.due_days_ago', { count: daysDuration })
    }
    if (daysDuration === 0) {
      return t('invoice_card.due_today')
    }
    if (daysDuration === -1) {
      return t('invoice_card.due_tomorrow')
    }

    return t('invoice_card.due_days_ahead', { count: -daysDuration })
  }
}

export const getPaymentItemStatusLabelStyles = (payment: PaymentItemSnapshot): object => {
  if (payment.status === Number(PaymentStatus.Processing)) {
    return { color: 'rgba(252, 179, 92, 1)' }
  }

  if (payment.status === Number(PaymentStatus.Completed)) {
    return { color: 'rgba(94, 170, 111, 1)' }
  }

  if (payment.status === Number(PaymentStatus.Pending)) {
    const { isPastDate } = getDaysDuration(payment)

    if (!isPastDate) {
      return { color: 'rgba(252, 179, 92, 1)' }
    }
  }

  return { color: 'rgba(234, 111, 126, 1)' }
}

export const paymentMethodsData = {
  [PaymentMethod.Card]: {
    title: 'payment.payment_method_card_title',
    wideTitle: 'payment.payment_method_card_wide_title',
    description: 'payment.payment_method_card_description',
    image: newCardImg,
  },
  [PaymentMethod.Lean]: {
    title: 'payment.payment_method_lean_description',
    wideTitle: 'payment.payment_method_lean_description',
    description: 'payment.payment_method_lean_description',
    image: newCardImg,
  },
  [PaymentMethod.Wire]: {
    title: 'payment.payment_method_wire_transfer_title',
    wideTitle: 'payment.payment_method_wire_transfer_title',
    description: 'payment.payment_method_wire_transfer_description',
    image: wireImg,
  },
}

export function getInvoiceItemStatusLabelStyles({
  status,
  due_date,
}: {
  status: number
  due_date: string | null
}): object {
  if (null === due_date) {
    return { color: 'rgba(234, 111, 126, 1)' }
  }

  if (status === InvoiceStatusType.Paid) {
    return { color: 'rgba(94, 170, 111, 1)' }
  }

  if (pendingStatuses.includes(status)) {
    const { isPastDate } = getDaysDuration({ due_date })

    if (!isPastDate) {
      return { color: 'rgba(252, 179, 92, 1)' }
    }

    return { color: 'rgba(234, 111, 126, 1)' }
  }

  return {}
}

export function getFormattedDate(date: string | null) {
  if (null === date) {
    return null
  }

  return moment(date).format('MMM DD, YYYY')
}
