import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { DateInput } from '../../../../date-input/date-input'
import { Toggle } from '../../../../toggle/toggle'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './dates.scss'

const DatesComponent = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const startDate = job.schedule.dateBoundaries.start_date ?? ''
  const endDate = job.schedule.dateBoundaries.end_date ?? ''

  const toggleReccuringStatus = () => {
    job.schedule.setIfOpenEnded(!job.schedule.dateBoundaries.open_ended)
  }

  const today = new Date()
  const minDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today
    .getDate()
    .toString()
    .padStart(2, '0')}`

  return (
    <div className="JobDraftDates">
      <span className="Popup__title">{t('job_draft_details.dates_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.dates_subtitle')}</span>
      <div className="JobDraftDates__innerContainer">
        <div className="JobDraftDates__itemWrapper">
          <DateInput
            label={t('job_draft_details.start_date')}
            value={startDate}
            handleChange={job.schedule.setStartDate}
            min={minDate}
            max={endDate ? endDate : undefined}
            className={`JobDraftDates__dateInput ${job.schedule.dateBoundaries.startDateHasError ? 'error' : ''}`}
          />
          {job.schedule.dateBoundaries.startDateHasError && (
            <JobDraftFieldError error={job.schedule.dateBoundaries.start_date_error} />
          )}
        </div>
        <Separator.Vertical height={15} />
        <div className="JobDraftDates__innerContainer__jobToggleRow">
          <span className="JobDraftDates__innerContainer__jobToggleRow__label">
            {t('job_draft_details.dates_open_ended')}
          </span>
          <Toggle active={job.schedule.dateBoundaries.open_ended} onToggle={toggleReccuringStatus} />
        </div>
        {!job.schedule.dateBoundaries.open_ended && (
          <>
            <Separator.Vertical height={15} />
            <DateInput
              label={t('job_draft_details.end_date')}
              value={endDate}
              handleChange={job.schedule.setEndDate}
              min={startDate ? startDate : minDate}
              className={`JobDraftDates__dateInput ${
                job.schedule.dateBoundaries.endDateOrOpenEndedHasError ? 'error' : ''
              }`}
            />
            {job.schedule.dateBoundaries.endDateOrOpenEndedHasError && (
              <JobDraftFieldError error={job.schedule.dateBoundaries.end_date_or_open_ended_error} />
            )}
          </>
        )}
      </div>
    </div>
  )
})
// @ts-ignore
DatesComponent.displayName = 'DatesComponent'

export const Dates = DatesComponent
