import { Separator } from '../../../../../components/app-layout/separator/separator'
import './work-exp-card.scss'

export type WorkExpProps = {
  title: string
  companyName: string
  location: string
  startYear: number
  endYear: number
  description: string
  imageURL: string
}

export const WorkExperienceCard = ({
  title,
  companyName,
  location,
  startYear,
  endYear,
  description,
  imageURL,
}: WorkExpProps) => (
  <div className="WorkExperienceCard">
    <div className="WorkExperienceCard__imgContainer">
      <img src={imageURL} alt="" />
    </div>
    <Separator.Horizontal width={13} />
    <div className="WorkExperienceCard__info">
      <span className="WorkExperienceCard__info__title">{title}</span>
      <span className="WorkExperienceCard__info__company">{companyName}</span>
      <span className="WorkExperienceCard__info__location">{location}</span>
      <span className="WorkExperienceCard__info__time">
        {startYear} - {endYear}
      </span>
      <p className="WorkExperienceCard__info__description">{description}</p>
    </div>
  </div>
)
