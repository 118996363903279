import { default as OrderSummaryPage } from './order-summary/order-summary'
import { OrdersListPage } from './orders-list/orders-list'
import { JobDraftCategory } from './job-draft/job-draft-category/job-draft-category'
import { JobDraftDetailPage } from './job-draft/job-draft-details/job-draft-details'
import { OrderDraft as OrderDraftPage } from './order-draft/order-draft'
import { OrderConfirmedPage } from './order-confirmed/order-confirmed'
import { OrderDraftSummary as OrderDraftSummaryPage } from './order-draft-summary/order-draft-summary'
import { OrderRequestedPage } from './order-requested/order-requested'
import { OrderDuplicate as OrderDuplicatePage } from './order-duplicate/order-duplicate/order-duplicate'
import { ChooseJobs as OrderDuplicateChooseJobsPage } from './order-duplicate/choose-jobs/choose-jobs'
import {JobDraftLocation} from "./job-draft/job-draft-location/job-draft-location";

export const OrderStack = {
  OrderSummaryPage,
  OrdersListPage,
  JobDraftCategoryPage: JobDraftCategory,
  JobDraftLocationPage: JobDraftLocation,
  JobDraftDetailPage,
  OrderDraftPage,
  OrderConfirmedPage,
  OrderDraftSummaryPage,
  OrderRequestedPage,
  OrderDuplicatePage,
  OrderDuplicateChooseJobsPage,
}
