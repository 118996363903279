import { useTranslation } from 'react-i18next'

import penIcon from '../../../../../components/assets/pen.svg'

export const EditJobButton = ({ onClick, containerStyle }: { onClick: () => void; containerStyle?: object }) => {
  const { t } = useTranslation()

  return (
    <div className="JobCard__header__editJob" onClick={onClick} style={containerStyle}>
      <span className="JobCard__header__editJob__title">{t('order_draft.edit_job')}</span>
      <img src={penIcon} />
    </div>
  )
}
