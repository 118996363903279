import Modal from 'react-modal'
import { observer } from 'mobx-react'

import crossImg from './assets/cross.svg'
import './popup.scss'

type PopupProps = {
  isActive: boolean
  content: React.ReactNode
  close: () => void
}

export const Popup = observer(({ isActive, content, close }: PopupProps) => {
  return (
    <Modal isOpen={isActive} onRequestClose={close} closeTimeoutMS={400} className="Popup">
      <div className="Popup__header">
        <div className="Popup__header__spacer" />
        <div className="Popup__header__topBar" />
        <div onClick={close} className="Popup__header__closeContainer">
          <img src={crossImg} className="Popup__header__closeContainer__img" alt="close" />
        </div>
      </div>
      {content}
    </Modal>
  )
})
