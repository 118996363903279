import { Field, FormikProps } from 'formik'
import './text-input.scss'
import { HTMLInputTypeAttribute } from 'react'

export interface TextInputProps<T> {
  label: string
  getValue?: (value: string) => void
  formikKey: keyof T
  type?: string
  disabled?: boolean
  formikProps: FormikProps<T>
  className?: string
}

function getDirectionByInputType(type: HTMLInputTypeAttribute | undefined) {
  switch (type) {
    case 'email': // for these types direction should always be left-to-right
    case 'number':
    case 'tel':
    case 'url':
      return 'ltr'
    default:
      return 'undefined' // will by set by css instead
  }
}

export function TextInput<T>({
  label,
  getValue,
  formikKey,
  formikProps,
  type,
  disabled,
  className,
}: TextInputProps<T>) {
  const handleKeyUp = (event: Event) => {
    if (getValue) {
      getValue((event.target as HTMLTextAreaElement).value)
    }
  }
  return (
    <div className={`TextInput ${disabled ? 'disabled' : ''}`} dir={getDirectionByInputType(type)}>
      <Field
        placeholder=""
        disabled={disabled}
        name={formikKey}
        autoComplete="off"
        className={`TextInput__input ${className ?? ''}`}
        onKeyUp={handleKeyUp}
        type={type ?? 'text'}
        required
      />
      <label className={`input-label ${Boolean(formikProps.getFieldProps(formikKey as string).value) && 'active'}`}>
        {label}
      </label>
      <p className="error-msg">{formikProps.touched[formikKey] && formikProps.errors[formikKey]}</p>
    </div>
  )
}

export default TextInput
