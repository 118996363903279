import { useEffect, Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import fileIcon from '../assets/file-icon.svg'
import inviteIcon from '../assets/invite-user-icon.svg'
import SectionTitle from '../components/section-title/section-title'
import OrderDraftSummaryCard from '../components/order-summary-card/order-summary-card'
import OrderDraftSummaryJobsCard from '../components/order-summary-jobs-card/order-summary-jobs-card'
import { AccountType } from '../../../models/account'
import { routes } from '../../../routes'
import { useRedirect } from '../../../services/router'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { Button } from '../../../components/button/button'
import { Header } from '../../../components/app-layout/header/header'
import { Separator } from '../../../components/app-layout/separator/separator'
import { trackingService, TrackedEvent } from '../../../services/tracking-service'
import './order-draft-summary.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { StepIndicator } from '../../../components/step-indicator/step-indicator'
import { HeaderJobsCounter } from '../../../components/header-jobs-counter/header-jobs-counter'
import { OrderSummarySnapshot } from '../../../models/order/order-summary'

type OrderDraftSummaryProps = {
  orderId: string
  showAmend: boolean
}

const stepIndicatorConfig = {
  stepsCount: 4,
  currentStepNumber: 4,
}

export const OrderDraftSummary = withErrorBoundary(
  observer(({ orderId, showAmend }: OrderDraftSummaryProps) => {
    const { account, getOrderSummary, getOrderIdSummary, submitOrder, getInvitedPastSPsByIds, invitedPastSPsList } =
      useStore().data

    const [, getInvitedPastSPsByIdsQuery] = useMutation(({ ids }: { ids: number[] }) => getInvitedPastSPsByIds(ids), {
      onError: err => {
        toast.error(err.data.message)
      },
    })

    const orderSummary = getOrderIdSummary(orderId)
    const orderSummaryQuery = useQuery(() => getOrderSummary({ orderId }), {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: (res: OrderSummarySnapshot) => {
        const invitedSPIds: number[] = []

        res.jobs.forEach(job => {
          job.invited_sp_ids.forEach(invitedSPId => {
            if (!invitedSPIds.includes(invitedSPId)) {
              invitedSPIds.push(invitedSPId)
            }
          })
        })

        getInvitedPastSPsByIdsQuery({ ids: invitedSPIds })
      },
      onError: err => {
        toast.error(err.data.message)
      },
    })

    const [submitOrderQuery, requestSubmitOrder] = useMutation(submitOrder, {
      onSuccess: () => {
        redirect(routes.orderRequestSuccess, { orderId })
      },
      onError: err => alert(err.data.message),
      filterError: [ApiErrorTypes.ClientError],
    })

    useEffect(() => {
      trackingService.track(TrackedEvent.ViewedNotSubmittedOrderSummary)
    }, [])

    const redirect = useRedirect()

    const { t } = useTranslation()

    return (
      <>
        {orderSummaryQuery.isLoading || submitOrderQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="OrderDraftSummary">
            <Header
              showBack
              title={t('order.draft_order_title')}
              rightComponent={
                <HeaderJobsCounter jobsCount={orderSummary?.jobs.length as number} orderId={Number(orderId)} />
              }
            />
            <div className="OrderDraftSummary__container">
              <StepIndicator
                stepsCount={stepIndicatorConfig.stepsCount}
                currentStepNumber={stepIndicatorConfig.currentStepNumber}
              />
              <Separator.Vertical height={14} />
              <SectionTitle image={fileIcon} title={t('order.order_summary')} />
              <Separator.Vertical height={14} />
              <OrderDraftSummaryCard
                location={orderSummary?.locations[0].name}
                paymentTerms={orderSummary?.payment_terms_days}
                startDate={orderSummary?.start_date ? moment(orderSummary.start_date) : null}
                endDate={orderSummary?.start_date ? moment(orderSummary.end_date) : null}
                showCompleteDetails={
                  account?.account_type === AccountType.CORPORATE && account.is_corporate_details_complete === false
                }
                orderId={orderId}
                showAmend={showAmend}
              />
              <Separator.Vertical height={24} />
              {orderSummary && orderSummary.jobs.length > 0 && (
                <>
                  <SectionTitle image={inviteIcon} title={t('order.jobs')} />
                  <Separator.Vertical height={14} />
                </>
              )}
              {orderSummary?.jobs.map((job, i) => {
                const invitedSPs = invitedPastSPsList.filter(sp => job.invited_sp_ids.includes(sp.id))

                return (
                  <Fragment key={job.id}>
                    <OrderDraftSummaryJobsCard
                      work_category_name={job.work_category_name}
                      start_date={moment(job.start_date)}
                      end_date={moment(job.end_date)}
                      hours={job.hours}
                      currency={job.currency}
                      shifts={job.shifts}
                      hourly_rate={job.hourly_rate}
                      quantity={job.quantity}
                      transportation_costs={job.transportation_costs}
                      underReview={Boolean(orderSummary.is_pending_rates_review)}
                      showRate={false}
                      showTransportations={false}
                      invitedSPs={invitedSPs}
                    />
                    {i !== orderSummary.jobs.length - 1 && <Separator.Vertical height={14} />}
                  </Fragment>
                )
              })}
              <Separator.Vertical height={31} />
              <Button onClick={() => requestSubmitOrder(orderId)} className="OrderDraftSummary__sendForReviewButton">
                {t('order.send_for_review')}
              </Button>
              {showAmend && (
                <>
                  <Separator.Vertical height={24} />
                  <Button
                    onClick={() => {
                      redirect(routes.orderDraft, { orderId })
                    }}
                    className="OrderDraftSummary__amendButton"
                  >
                    {t('order.amend_order')}
                  </Button>
                </>
              )}
              <Separator.Vertical height={30} />
            </div>
          </div>
        )}
      </>
    )
  }),
)
