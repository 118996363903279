import { useTranslation } from 'react-i18next'

import './order-summary-welcome.scss'
import { VERIFICATION_STATUS_MISSING, VERIFICATION_STATUS_INREVIEW } from '../../../../models/company-details'
import { Link } from '../../../../services/router'
import { routes } from '../../../../routes'
import { getCurrentMarketConfig } from '../../../../services/market-service'

interface OrderSummaryWelcomeProps {
  pendingConfirmation: boolean
  pendingRates: boolean
  corporateDetailsVerificationStatus: number
  openOrderExtensionPopup: () => void
}

const getWelcomeText = (
  pendingConfirmation: boolean,
  pendingRates: boolean,
  corporateDetailsVerificationStatus: number,
  corporateDetailsRequired: boolean,
) => {
  if (corporateDetailsRequired && corporateDetailsVerificationStatus === VERIFICATION_STATUS_MISSING) {
    return 'order.need_to_review_corporate_details'
  }

  if (corporateDetailsRequired && corporateDetailsVerificationStatus === VERIFICATION_STATUS_INREVIEW) {
    return 'order.corporate_details_are_being_reviewed'
  }

  if (pendingRates) {
    return 'order.pending_rates_review'
  }

  if (pendingConfirmation) {
    return 'order.confirm_order'
  }

  return 'order.suggest_extension'
}

const OrderSummaryWelcome = ({
  pendingConfirmation,
  pendingRates,
  corporateDetailsVerificationStatus,
  openOrderExtensionPopup,
}: OrderSummaryWelcomeProps) => {
  const { t } = useTranslation()

  const isCorporateDetailsRequiredAndNotFilled =
    getCurrentMarketConfig().registration.allowOnlyCorporate &&
    corporateDetailsVerificationStatus === VERIFICATION_STATUS_MISSING

  const showExtensionOption = !pendingConfirmation && !pendingRates

  return (
    <div className="OrderSummaryWelcome orange">
      {showExtensionOption && <h1>{t('order.extend_your_order')}</h1>}
      <p>
        {t(
          getWelcomeText(
            pendingConfirmation,
            pendingRates,
            corporateDetailsVerificationStatus,
            getCurrentMarketConfig().registration.allowOnlyCorporate,
          ),
        )}
      </p>
      {isCorporateDetailsRequiredAndNotFilled && (
        <Link route={routes.completeRegistration} params={{}} className="OrderSummaryWelcome__completeRegistrationLink">
          {t('order.complete_company_details_link')}
        </Link>
      )}
      {showExtensionOption && (
        <span onClick={openOrderExtensionPopup} className="OrderSummaryWelcome__completeRegistrationLink">
          {t('order_extension.extend_order')}
        </span>
      )}
    </div>
  )
}

export default OrderSummaryWelcome
