export const Rating = ({ rating }: { rating: number }) => {
  const ratingRounded = Math.round(rating)

  return (
    <div className="WorkCategorySkills__skillRating">
      {Array.from(Array(ratingRounded)).map((_, i) => (
        <div key={i} className="WorkCategorySkills__skillRatingBox"></div>
      ))}
      {Array.from(Array(5 - ratingRounded)).map((_, i) => (
        <div key={i} className="WorkCategorySkills__skillRatingBox WorkCategorySkills__skillRatingBox--empty"></div>
      ))}
    </div>
  )
}
