import { useTranslation } from 'react-i18next'

import cameraIcon from '../../assets/camera.svg'
import cameraDisabledIcon from '../../assets/camera_disabled.svg'

export const CameraUnavailable = ({
  hasCamera,
  isCameraAccessDenied,
}: {
  hasCamera: boolean
  isCameraAccessDenied: boolean
}) => {
  const { t } = useTranslation()

  return (
    <div className="QrScanner__cameraPermission">
      <div
        className={`QrScanner__cameraPermission__iconContainer${
          !hasCamera ? 'QrScanner__cameraPermission__iconContainer--disabled' : ''
        }`}
      >
        <img src={!hasCamera ? cameraDisabledIcon : cameraIcon} />
      </div>
      {hasCamera && !isCameraAccessDenied && (
        <>
          <span className="QrScanner__cameraPermission__title">{t('qr_scan.camera_permission_required')}</span>
          <span className="QrScanner__cameraPermission__subtitle">{t('qr_scan.enable_permission')}</span>
        </>
      )}
      {hasCamera && isCameraAccessDenied && (
        <>
          <span className="QrScanner__cameraPermission__title QrScanner__cameraPermission__title--disabled">
            {t('qr_scan.access_denied')}
          </span>
          <span className="QrScanner__cameraPermission__subtitle">{t('qr_scan.enable_permission')}</span>
        </>
      )}
      {!hasCamera && (
        <>
          <span className="QrScanner__cameraPermission__title QrScanner__cameraPermission__title--disabled">
            {t('qr_scan.camera_missing_title')}
          </span>
          <span className="QrScanner__cameraPermission__subtitle">{t('qr_scan.camera_missing_description')}</span>
        </>
      )}
    </div>
  )
}
