import { useEffect } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { TimeInput } from '../../../../form/time-input/time-input'
import './days-select.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'

type TimeRowProps = {
  job: DraftJobInstance
  isActive: boolean
  isWeeklyType: boolean
  day: string
  startTime: string | null
  startTimeError: string | null
  endTime: string | null
  endTimeError: string | null
  lastStartTimeInput: string
  lastEndTimeInput: string
  updateLastStartTimeInput: (value: string) => void
  updateLastEndTimeInput: (value: string) => void
}

function TimeRowComponent({
  job,
  isActive,
  isWeeklyType,
  day,
  startTime,
  startTimeError,
  endTime,
  endTimeError,
  lastStartTimeInput,
  lastEndTimeInput,
  updateLastStartTimeInput,
  updateLastEndTimeInput,
}: TimeRowProps) {
  const { t } = useTranslation()

  useEffect(() => {
    if (startTime && startTime.length > 0) {
      updateLastStartTimeInput(startTime)
    }
    if (startTime && isActive === false && endTime) {
      job.schedule.distinctTimeSchedule.selectDate({ date: day })
    }
    if (endTime && endTime.length > 0) {
      updateLastEndTimeInput(endTime)
    }
    if (endTime && isActive === false && startTime) {
      job.schedule.distinctTimeSchedule.selectDate({ date: day })
    }
  }, [startTime, endTime, isActive])

  return (
    <div className="JobDraftTime__dates__row">
      <div
        onClick={() => {
          if (isActive) {
            job.schedule.distinctTimeSchedule.clearDate(day)
            return
          }
          if (job.schedule.sameTimeSchedule.allDaysHaveSameTime) {
            job.schedule.distinctTimeSchedule.setDateStartTime({
              date: day,
              start_time: job.schedule.sameTimeSchedule.same_start_time,
            })
            job.schedule.distinctTimeSchedule.setDateEndTime({
              date: day,
              end_time: job.schedule.sameTimeSchedule.same_end_time,
            })
          }
          const shouldPasteDefaultStartValue = !startTime && lastStartTimeInput
          const shouldPasteDefaultEndValue = !endTime && lastEndTimeInput
          if (shouldPasteDefaultStartValue) {
            job.schedule.distinctTimeSchedule.setDateStartTime({ date: day, start_time: lastStartTimeInput })
          }
          if (shouldPasteDefaultEndValue) {
            job.schedule.distinctTimeSchedule.setDateEndTime({ date: day, end_time: lastEndTimeInput })
          }
          job.schedule.distinctTimeSchedule.selectDate({ date: day })
        }}
        className={`JobDraftTime__dates__row__day ${isActive ? 'active' : ''}`}
      >
        <span className={`JobDraftTime__dates__row__day__label ${isActive ? 'active' : ''}`}>
          {isWeeklyType ? t(`time.${day.toLowerCase()}`) : moment(day, 'YYYY-MM-DD').format('MMM DD')}
        </span>
      </div>
      {!job.schedule.sameTimeSchedule.allDaysHaveSameTime && (
        <>
          <div className="JobDraftTime__inputWrapper">
            <TimeInput
              label={t('job_draft_details.start_time')}
              value={startTime ?? ''}
              handleChange={newStartTime => {
                job.schedule.distinctTimeSchedule.setDateStartTime({ date: day, start_time: newStartTime })
                if (endTime) {
                  job.schedule.distinctTimeSchedule.selectDate({ date: day })
                }
              }}
              className="JobDraftTime__dates__row__input"
              onFocus={() => {
                const shouldPasteDefaultStartValue = !startTime && lastStartTimeInput
                if (shouldPasteDefaultStartValue) {
                  job.schedule.distinctTimeSchedule.setDateStartTime({ date: day, start_time: lastStartTimeInput })
                }
              }}
              useDropdownWithInput
              required
            />
            <JobDraftFieldError error={startTimeError} />
          </div>
          <div className="JobDraftTime__inputWrapper">
            <TimeInput
              label={t('job_draft_details.end_time')}
              value={endTime ?? ''}
              handleChange={newEndTime => {
                job.schedule.distinctTimeSchedule.setDateEndTime({ date: day, end_time: newEndTime })
                if (startTime) {
                  job.schedule.distinctTimeSchedule.selectDate({ date: day })
                }
              }}
              className="JobDraftTime__dates__row__input"
              onFocus={() => {
                const shouldPasteDefaultEndValue = !endTime && lastEndTimeInput
                if (shouldPasteDefaultEndValue) {
                  job.schedule.distinctTimeSchedule.setDateEndTime({ date: day, end_time: lastEndTimeInput })
                }
              }}
              pickerStartTime={startTime ?? '00:00'}
              useDropdownWithInput
              showDurationFromPickerStartTime
              required
            />
            <JobDraftFieldError error={endTimeError} />
          </div>
        </>
      )}
    </div>
  )
}

export const TimeRow = observer(TimeRowComponent)
