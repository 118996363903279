import './button-secondary.scss'

export const ButtonSecondary = ({
  text,
  action,
  additionalStyle,
}: {
  text: string
  action: () => void
  additionalStyle?: object
}) => {
  return (
    <button className="Button__secondary" onClick={action} style={additionalStyle}>
      {text}
    </button>
  )
}
