import { useTranslation } from 'react-i18next'

import './job-column.scss'
import threeDotsIcon from '../../../../../../../components/assets/3-dots.svg'
import { MenuItem } from '../../../../../../../components/dropdown-menu/components/menu-item/menu-item'
import calendarNextIcon from '../../../../../../../components/assets/calendar-next.svg'
import { DropdownMenu } from '../../../../../../../components/dropdown-menu/dropdown-menu'
import dropdownDownIcon from '../../../../../../../components/assets/arrow-dropdown-down.svg'
import dropdownRightIcon from '../../../../../../../components/assets/arrow-dropdown-right.svg'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'

export type JobColumnProps = {
  workCategory: string
  quantity: number
  location: string
  orderId: number
  setCollapsed: () => void
  collapsed: boolean
  openOrderExtensionPopup: () => void
}

export function JobColumn({
  workCategory,
  quantity,
  location,
  orderId,
  setCollapsed,
  collapsed,
  openOrderExtensionPopup,
}: JobColumnProps) {
  const { t } = useTranslation()

  const title = `${workCategory} (${quantity})`
  return (
    <div className="JobColumn">
      <div className="JobColumn__container">
        <div
          onClick={() => {
            setCollapsed()

            trackingService.track(TrackedEvent.TimesheetRowExpanded)
          }}
          className="JobColumn__content"
        >
          <img src={collapsed ? dropdownRightIcon : dropdownDownIcon} className="JobColumn__img" />
          <div className="JobColumn__textContainer">
            <span
              className={`JobColumn__textContainer__subtitle JobColumn__textContainer__subtitle--top ${
                location.length > 25 ? 'JobColumn__textContainer__subtitle_size_small' : ''
              }`}
            >
              {t('order.order_number', { id: orderId })}
            </span>
            <span
              className={`JobColumn__textContainer__title ${
                title.length > 25 ? 'JobColumn__textContainer__title_size_small' : ''
              }`}
            >
              {title}
            </span>
            <span
              className={`JobColumn__textContainer__subtitle ${
                location.length > 25 ? 'JobColumn__textContainer__subtitle_size_small' : ''
              }`}
            >
              {location}
            </span>
          </div>
        </div>
        <DropdownMenu
          outerComponent={
            <div>
              <img
                src={threeDotsIcon}
                onMouseOver={() => trackingService.track(TrackedEvent.TimesheetOrder3DotsClicked)}
              />
            </div>
          }
          items={[
            <MenuItem
              key="extend"
              img={<img src={calendarNextIcon} />}
              title={t('order_extension.extend_order')}
              onClick={openOrderExtensionPopup}
            />,
          ]}
        />
      </div>
    </div>
  )
}
