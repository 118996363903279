import { useTranslation } from 'react-i18next'

import { Separator } from '../../app-layout'
import { InviteReferralLink } from '../../../pages/affiliate/components/invite-referral-link/invite-referral-link'
import { Popup } from '../../popup/popup'
import LinkedinShareButton from '../../social-share/linkedin-share-button'
import FacebookShareButton from '../../social-share/facebook-share-button'
import TwitterShareButton from '../../social-share/twitter-share-button'
import TelegramShareButton from '../../social-share/telegram-share-button'
import WhatsappShareButton from '../../social-share/whatsapp-share-button'
import './invite-referral-popup.scss'

export const InviteReferralPopup = ({
  showPopup,
  setShowPopup,
  invitationLink,
}: {
  showPopup: boolean
  setShowPopup: (value: boolean) => void
  invitationLink: string
}) => {
  const { t } = useTranslation()

  return (
    <Popup
      isActive={showPopup}
      content={
        <div className="InviteReferralPopup">
          <span className="InviteReferralPopup__title">{t('common_affiliate.send_invitation')}</span>
          <span className="InviteReferralPopup__subtitle">{t('common_affiliate.share_link')}</span>
          <div className="InviteReferralPopup__socialShare">
            <FacebookShareButton url={invitationLink} title={t('common_affiliate.join_ogram')} />
            <LinkedinShareButton url={invitationLink} title={t('common_affiliate.join_ogram')} />
            <TwitterShareButton url={invitationLink} title={t('common_affiliate.join_ogram')} />
            <TelegramShareButton url={invitationLink} title={t('common_affiliate.join_ogram')} />
            <WhatsappShareButton url={invitationLink} title={t('common_affiliate.join_ogram')} />
          </div>
          <span className="InviteReferralPopup__subtitle2">{t('common_affiliate.copy_and_send_link')}</span>
          <Separator.Vertical height={10} />
          <InviteReferralLink link={invitationLink} />
        </div>
      }
      close={() => setShowPopup(false)}
    />
  )
}
