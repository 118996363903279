import { useState, useMemo, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { onboardingsForKnowledgeCenter } from '../../components/onboardings'
import { PageOnboarding } from '../../components/page-onboarding/page-onboarding'
import { Header, Separator } from '../../components/app-layout'
import { KnowledgeItem } from './components/knowledge-item'
import './knowledge-center.scss'
import { AppVersion } from '../../components/app-version/app-version'

export const KnowledgeCenter = () => {
  const [visibleOnboarding, setVisibleOnboarding] = useState<string | null>(null)
  const [initialIndex, setInitialIndex] = useState<number>(0)

  const { t } = useTranslation()

  const onboardingKnowledgeInfo = useMemo(() => {
    if (!visibleOnboarding) return null
    return onboardingsForKnowledgeCenter.find(item => item.paths.includes(visibleOnboarding))
  }, [visibleOnboarding])

  return (
    <div className="KnowledgeCenter">
      <Header title={t('onboarding.knowledge_center')} />
      <Separator.Vertical height={30} />
      {onboardingsForKnowledgeCenter.map((onboarding, index) => (
        <Fragment key={onboarding.paths[0]}>
          <KnowledgeItem
            pages={onboarding.pages}
            title={onboarding.title}
            showSeparator={index !== onboardingsForKnowledgeCenter.length - 1}
            selectOnboarding={(i: number) => {
              setInitialIndex(i)
              setVisibleOnboarding(onboarding.paths[0])
            }}
          />
          <Separator.Vertical height={25} />
        </Fragment>
      ))}
      {onboardingKnowledgeInfo && (
        <PageOnboarding
          visible={visibleOnboarding !== null}
          initialIndex={initialIndex}
          onClose={() => setVisibleOnboarding(null)}
          content={onboardingKnowledgeInfo}
        />
      )}
      <Separator.Vertical height={50} />
      <AppVersion />
      <Separator.Vertical height={50} />
    </div>
  )
}
