import { types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../../translations'
import { DraftJobFields } from '../fields'
import { DraftJobInstance } from '../../draft-job'
import { ScheduleType } from './types'

export const ScheduleTypeModel = types
  .model('JobDatesScheduleType', {
    value: types.maybeNull(types.enumeration<ScheduleType>('ScheduleType', Object.values(ScheduleType))),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(type: ScheduleType) {
      checkValueIsInEnum(type)
      self.value = type
      self.error = null
    },

    validate() {
      if (self.value === null) {
        self.error = t('job_draft_details.schedule_type_required')
        return false
      }
      checkValueIsInEnum(self.value)
      return true
    },
  }))

function checkValueIsInEnum(value: ScheduleType) {
  if (!Object.values(ScheduleType).includes(value)) {
    throw new Error(`Unexpected id ${value} for schedule type`)
  }
}

export function createScheduleTypeMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.scheduleType) {
      return abort(targetInstance.schedule.scheduleType.validate())
    }
    return next(call)
  })
}
