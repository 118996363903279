import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'

import leftArrowSvg from '../../../../../../components/assets/arrow-left.svg'
import Calendar from '../../../../../../components/calendar/calendar'
import { useOutsideClickHandler } from '../../../../../../utils/use-outside-click-handler'
import { getFirstDayOfWeek } from '../../../../../../utils/datetime-utils'
import { TrackedEvent, trackingService } from '../../../../../../services/tracking-service'

type InputProps = {
  onPreviousWeekPress: () => void
  onNextWeekPress: () => void
  additionalContainerClassName?: string
  selectedDate: Date
  setSelectedDate: (value: Date) => void
  setCurrentWeek: () => void
}
export const WeekToggle = ({
  onPreviousWeekPress,
  onNextWeekPress,
  additionalContainerClassName,
  selectedDate,
  setSelectedDate,
  setCurrentWeek,
}: InputProps) => {
  const { t } = useTranslation()
  const [showCalendar, setShowCalendar] = useState(false)
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null)

  const calendarRef = useRef(null)

  useOutsideClickHandler(calendarRef, () => setShowCalendar(false))

  return (
    <div className={`CalendarHeader__weekToggle ${additionalContainerClassName}`}>
      {showCalendar && (
        <Calendar
          value={selectedDate}
          setValue={(value: Date | null) => {
            if (value) {
              setSelectedDate(getFirstDayOfWeek(value))
            }
            setShowCalendar(false)

            trackingService.track(TrackedEvent.TimesheetWeekSwitchedViaCalendar)
          }}
          containerClassName="DateInput__Calendar CalendarHeader__weekToggle__calendar"
          hoveredDate={hoveredDate}
          setHoveredDate={setHoveredDate}
          ref={calendarRef}
          highlightWeek
        />
      )}
      <button
        onClick={() => {
          setCurrentWeek()
          trackingService.track(TrackedEvent.TimesheetCurrentWeekClicked)
        }}
        className="CalendarHeader__right__currentWeekButton"
      >
        {t('all_jobs.current_week')}
      </button>
      <span
        className="CalendarHeader__label"
        onClick={() => {
          setShowCalendar(true)
          trackingService.track(TrackedEvent.TimesheetCalendarSwitcherOpened)
        }}
      >
        {t('all_jobs.week')}
      </span>
      <div className="CalendarHeader__right__toggle">
        <div
          onClick={() => {
            trackingService.track(TrackedEvent.TimesheetWeekBackClicked)
            onPreviousWeekPress()
          }}
          className="CalendarHeader__right__toggle__arrowContainer"
        >
          <img src={leftArrowSvg} className="CalendarHeader__right__toggle__arrowContainer__arrow" />
        </div>
        <div className="CalendarHeader__right__toggle__separator" />
        <div
          onClick={() => {
            trackingService.track(TrackedEvent.TimesheetWeekForwardClicked)
            onNextWeekPress()
          }}
          className="CalendarHeader__right__toggle__arrowContainer"
        >
          <img src={leftArrowSvg} className="CalendarHeader__right__toggle__arrowContainer__arrow flipped" />
        </div>
      </div>
    </div>
  )
}
