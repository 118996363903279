import { createDateBoundariesMiddleware } from './schedule/date-boundaries'
import { createSameTimeScheduleMiddleware } from './schedule/same-time-schedule'
import { createScheduleTypeMiddleware } from './schedule/schedule-type'
import { createDistinchTimeScheduleMiddleware } from './schedule/distinct-time-schedule'
import { createQuantityMiddleware } from './quantity'
import { createRequirementsMiddleware } from './requirements'
import { createCategoryMiddleware } from './work-category'
import { createTransportMiddleware } from './transport-accessibility'
import { createLocationMiddleware } from './location'
import { createDescriptionMiddleware } from './description'
import { createManagerMiddleware } from './manager'
import { createExperienceMiddleware } from './experience'
import { createDresscodeMiddleware } from './dresscode'
import { createLanguagesMiddleware } from './languages'
import { createGenderMiddleware } from './gender'
import { createDocumentsMiddleware } from './documents'
import { createManualToolsMiddleware } from './manual-tools'
import { createAccreditationMiddleware } from './accreditation'
import { createAccomodationMiddleware } from './accomodation'
import { createBoardingMiddleware } from './boarding'
import { createFieldsMiddleware } from './fields'
import { DraftJobInstance } from '../draft-job'
import { createInvitedSPsMiddleware } from './invited-sps'

export function addMiddlewares(toInstance: DraftJobInstance) {
  createRequirementsMiddleware(toInstance)
  createQuantityMiddleware(toInstance)
  createCategoryMiddleware(toInstance)
  createTransportMiddleware(toInstance)
  createLocationMiddleware(toInstance)
  createDateBoundariesMiddleware(toInstance)
  createSameTimeScheduleMiddleware(toInstance)
  createScheduleTypeMiddleware(toInstance)
  createDistinchTimeScheduleMiddleware(toInstance)
  createDescriptionMiddleware(toInstance)
  createInvitedSPsMiddleware(toInstance)
  createManagerMiddleware(toInstance)
  createExperienceMiddleware(toInstance)
  createDresscodeMiddleware(toInstance)
  createLanguagesMiddleware(toInstance)
  createGenderMiddleware(toInstance)
  createDocumentsMiddleware(toInstance)
  createManualToolsMiddleware(toInstance)
  createAccreditationMiddleware(toInstance)
  createAccomodationMiddleware(toInstance)
  createBoardingMiddleware(toInstance)
  createFieldsMiddleware(toInstance)
}
