import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useRedirect } from '../../../services/router'
import { TextInput } from '../../../components/text-input/text-input'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { FieldError } from '../../../components/form/field-error/field-error'
import { Separator } from '../../../components/app-layout/separator/separator'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import appLogoImg from '../../../components/assets/app-logo.svg'
import './change-password.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const ChangePassword = withErrorBoundary(
  observer(() => {
    const store = useStore()
    const redirect = useRedirect()
    const [changeResult, change] = useMutation(store.data.changePassword, {
      onSuccess: () => {
        toast.success(t('change_password.password_changed_successfully'))
        redirect(routes.index, {})
      },
      onError: (err) => {
        if (err.data.message) {
          alert(err.data.message)
        }
      },
      filterError: [ApiErrorTypes.ClientError],
    })

    const errors = changeResult.error?.data.errors

    const { t } = useTranslation()

    return (
      <div className="ResetPassword">
        <img src={appLogoImg} className="ResetPassword__logo" />
        <Separator.Vertical height={45} />
        <h1 className="ResetPassword__title">{t('change_password.title')}</h1>
        <Separator.Vertical height={5} />
        <h2 className="ResetPassword__subtitle">{t('change_password.description')}</h2>
        <Separator.Vertical height={53} />
        {changeResult.isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={{ old_password: '', password: '', confirm_password: '' }}
            validationSchema={Yup.object().shape({
              old_password: Yup.string().required(t('change_password.please_enter_old_password')),
              password: Yup.string().required(t('change_password.please_enter_password')),
              confirm_password: Yup.string().required(t('change_password.please_confirm_password')),
            })}
            onSubmit={({ old_password, password, confirm_password }) => {
              if (password === confirm_password) {
                change({
                  password,
                  old_password,
                })
              }
            }}
          >
            {(formikProps) => (
              <Form noValidate>
                <FieldError errors={errors?.old_password}>
                  <TextInput
                    type="password"
                    label={t('change_password.old_password')}
                    formikProps={formikProps}
                    formikKey="old_password"
                  />
                </FieldError>
                <Separator.Vertical height={15} />
                <FieldError errors={errors?.password}>
                  <TextInput
                    type="password"
                    label={t('change_password.new_password')}
                    formikProps={formikProps}
                    formikKey="password"
                  />
                </FieldError>
                <Separator.Vertical height={15} />
                <TextInput
                  type="password"
                  label={t('change_password.confirm_new_password')}
                  formikProps={formikProps}
                  formikKey="confirm_password"
                />
                {formikProps.touched.password &&
                  formikProps.values.confirm_password &&
                  (formikProps.values.password === formikProps.values.confirm_password ? (
                    <p className="ResetPassword__matchedText">{t('change_password.password_matched')}</p>
                  ) : (
                    <p className="ResetPassword__notMatchedText">{t('change_password.password_not_matched')}</p>
                  ))}
                <Separator.Vertical height={25} />
                <button type="submit" className="ResetPassword__button">
                  {t('change_password.submit')}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    )
  }),
)
