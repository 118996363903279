import { AuthStack } from './auth'
import { CompleteRegistrationStack } from './complete-registeration'
import { HomeStack } from './home'
import { OrderStack } from './order'
import { ProfileStack } from './profile'
import { ShiftsAttendanceStack } from './shifts-attendance'
import { ApplicantsReviewStack } from './applicants-review'
import { JobsStack } from './jobs'
import { LocationsStack } from './locations'
import { UsersManagmentStack } from './users-managment'
import { RatingRequestsStack } from './rating-requests'
import { LeadGenerationStack } from './lead-form'
import { KnowledgeCenter } from './knowledge-center/knowledge-center'
import { SignUpWithOrderStack } from './sign-up-with-order'
import { ConfirmClientInvitationStack } from './confirm-client-invitation'
import { PaymentStack } from './payment'
import { AffiliateStack } from './affiliate'
import { PastWorkers } from './past-workers/past-workers'

export const Navigation = {
  AuthStack,
  CompleteRegistrationStack,
  HomeStack,
  OrderStack,
  ProfileStack,
  ShiftsAttendanceStack,
  ApplicantsReviewStack,
  JobsStack,
  LocationsStack,
  UsersManagmentStack,
  RatingRequestsStack,
  LeadGenerationStack,
  KnowledgeCenter,
  SignUpWithOrderStack,
  ConfirmClientInvitationStack,
  PaymentStack,
  AffiliateStack,
  PastWorkers,
}
