import { flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

type LeadForm = {
  company_name: string
  contact_email: string
  contact_name: string
  contact_phone: string
  days: string[]
  description: string
  end_date: string
  end_time: string
  start_date: string
  start_time: string
  experience: number
  gender: number | null
  language_ids: number[]
  location: {
    address: string
    lat: number
    lng: number
  }
  categories: Record<number, number>
}

export const leadFormActions = (self: DataStore) => ({
  submitLeadForm: flow(function* (params: LeadForm) {
    const { categories, days, language_ids, location, gender, ...form } = params
    for (const [work_category_id, sp_max_quantity] of Object.entries(categories)) {
      const formData = new FormData()
      const submitObject = { ...form, work_category_id, sp_max_quantity }
      Object.entries(submitObject).forEach(([key, value]) => {
        formData.append(key, String(value))
      })
      days.forEach((day) => {
        formData.append('days[]', day)
      })
      language_ids.forEach((lang) => {
        formData.append('language_ids[]', String(lang))
      })
      formData.append('location[address]', location.address)
      formData.append('location[lat]', String(location.lat))
      formData.append('location[lng]', String(location.lng))
      if (gender !== null) {
        formData.append('gender', String(gender))
      }

      yield self.request('post', 'common/lead/create', formData)
    }
  }),
})
