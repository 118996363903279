import './step-indicator.scss'
import { useTranslation } from 'react-i18next'

export const StepIndicator = ({ stepsCount, currentStepNumber }: { stepsCount: number; currentStepNumber: number }) => {
  const { t } = useTranslation()

  return (
    <div className="StepIndicator__container">
      <div className="StepIndicator__stepNumber">
        <span className="StepIndicator__stepNumber__content">
          {t('step_indicator.step_of', { currentStepNumber, stepsCount })}
        </span>
      </div>
      <div className="StepIndicator__steps">
        {new Array(stepsCount).fill(undefined).map((_, index) => {
          const stepNumber = index + 1
          const isCurrentStep = stepNumber === currentStepNumber

          return (
            <div key={index} className="StepIndicator__stepContainer">
              <div className={`StepIndicator__step${isCurrentStep ? ' StepIndicator__step--current' : ''}`}>
                <span className="StepIndicator__step__content">{stepNumber}</span>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
