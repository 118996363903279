import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const InvitationFilterModel = types.model('InvitationFilter', {
  id: types.maybeNull(types.number),
  JobId: types.maybeNull(types.number),
  favorite_staff: types.maybeNull(types.number),
  nationalilities: types.maybeNull(types.array(types.number)),
  certificates: types.maybeNull(types.array(types.number)),
  languages: types.maybeNull(types.array(types.number)),
  gender: types.maybeNull(types.number),
  male_count: types.maybeNull(types.number),
  female_count: types.maybeNull(types.number),
  min_age: types.maybeNull(types.number),
  max_age: types.maybeNull(types.number),
  min_height: types.maybeNull(types.number),
  max_height: types.maybeNull(types.number),
  min_experience: types.maybeNull(types.number),
  max_experience: types.maybeNull(types.number),
  min_hour_rate: types.maybeNull(types.number),
  max_hour_rate: types.maybeNull(types.number),
  min_rate: types.maybeNull(types.number),
  max_rate: types.maybeNull(types.number),
  fix_rate: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string),
  deleted_at: types.maybeNull(types.string),
})

export const InvitationSPModel = types.model('InvitationSP', {
  SPId: types.number,
  first_name: types.string,
  last_name: types.string,
  image_url: types.maybeNull(types.string),
  gender: types.number,
  nationality: types.maybeNull(types.string),
  currency: types.string,
  rating: types.string,
  rate: types.maybeNull(types.number),
  avalilable_shifts_ids: types.string,
  shifts_count: types.number,
  availability_count: types.number,
})

export const InvitationSPListModel = types.model('InvitationSPList', {
  list: types.array(InvitationSPModel),
  paginator: types.model({
    current_page: types.number,
    next_page: types.maybeNull(types.number),
    per_page: types.number,
  }),
})

export const InvitationModel = types.model('Invitation', {
  job_id: types.number,
  RateType: types.number,
  work_category_name: types.string,
  work_category_id: types.number,
  quantity: types.number,
  min_invited: types.number,
  max_invited: types.number,
  invited: types.number,
  shifts: types.array(types.number),
  filter: types.maybeNull(InvitationFilterModel),
  sps: types.maybe(InvitationSPListModel),
  invited_sps: types.maybeNull(types.array(InvitationSPModel)),
  invitedIds: types.optional(types.array(types.number), []),
})

export const invitationActions = (self: DataStore) => ({
  getInvitation: flow(function* ({
    orderId,
    jobId,
    filter,
  }: {
    orderId: number
    jobId: number
    filter?: InvitationFilterObject
  }) {
    const SPsForJobs = (yield self.request('post', 'client/order/invitation/sps_list', {
      order_id: orderId,
      job_id: jobId,
      filter,
    })) as InvitationSnapshot[]
    SPsForJobs.forEach((invitation) => {
      if (invitation.job_id !== jobId) return

      const storeInvitation = self.invitations.get(String(invitation.job_id))
      const previouslyInvitedIds: number[] = storeInvitation?.invitedIds ?? []
      const apiInvitatedSPs = invitation.invited_sps ?? []
      const invitedSPIds = apiInvitatedSPs.filter((sp) => !previouslyInvitedIds.includes(sp.SPId)).map((sp) => sp.SPId)

      self.invitations.set(String(invitation.job_id), {
        ...invitation,
        sps: invitation.sps ?? storeInvitation?.sps,
        invitedIds: [...invitedSPIds, ...previouslyInvitedIds],
      })
    })
  }),

  sendInvitations: flow(function* (
    invitations: {
      sps: { id: number; avalilable_shifts_ids: number[] }[]
      job_id: number
    }[],
  ) {
    yield self.request('post', 'client/order/invitation/send', { invitations })
  }),

  addInvitation({ jobId, SPId }: { jobId: number; SPId: number }) {
    const invitation = self.invitations.get(String(jobId)) as InvitationSnapshot
    invitation.invitedIds = [...invitation.invitedIds, SPId]
  },
})

export const invitationViews = (self: DataStore) => ({
  getFiltersCountForJob(jobId: string) {
    const filter = self.invitations.get(String(jobId))?.filter

    if (!filter) return 0

    const uselessFilterProperties = ['JobId', 'id', 'created_at', 'deleted_at', 'updated_at', 'min_rate', 'max_rate']
    const switchFilterProperties = ['favorite_staff', 'fix_rate']

    let filtersAmount = 0
    Object.keys(filter).forEach((filterProperty) => {
      if (uselessFilterProperties.includes(filterProperty)) return

      if (switchFilterProperties.includes(filterProperty)) {
        filtersAmount += filter[filterProperty as keyof InvitationFilterSnapshot]
        return
      }

      if (filter[filterProperty as keyof InvitationFilterSnapshot] !== null) {
        filtersAmount++
      }
    })

    return filtersAmount
  },
})

export interface InvitationFilterObject {
  favorite_staff?: number | null
  nationalilities?: number[] | null
  languages?: number[] | null
  certificates?: number[] | null
  gender?: number | null
  male_count?: number | null
  female_count?: number | null
  min_age?: number | null
  max_age?: number | null
  min_height?: number | null
  max_height?: number | null
  min_experience?: number | null
  max_experience?: number | null
  min_hour_rate?: number | null
  max_hour_rate?: number | null
  fix_rate?: number | null
}

export type Invitation = typeof InvitationModel.Type
export type InvitationSnapshot = typeof InvitationModel.SnapshotType

export type InvitationSPList = typeof InvitationSPListModel.Type
export type InvitationSPListSnapshot = typeof InvitationSPListModel.SnapshotType

export type InvitationSP = typeof InvitationSPModel.Type
export type InvitationSPSnapshot = typeof InvitationSPModel.SnapshotType

export type InvitationFilter = typeof InvitationFilterModel.Type
export type InvitationFilterSnapshot = typeof InvitationFilterModel.SnapshotType
