import { useState } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../services/router'
import LoadingSpinner from '../../components/loading-spinner/loading-spinner'
import { useStore, useQuery, useMutation } from '../../utils/mst-hooks'
import { routes } from '../../routes'
import { Separator } from '../../components/app-layout/separator/separator'
import {
  Header,
  Stepper,
  WorkCategories,
  Location,
  Dates,
  Days,
  Time,
  Description,
  ExperienceLevel,
  Languages,
  Gender,
} from './components'
import { titles } from './titles'
import './lead-form.scss'

const screens = [
  { field: 'categoriesCount', Component: WorkCategories },
  { field: 'location', Component: Location },
  { field: 'dates', Component: Dates },
  { field: 'days', Component: Days },
  { field: 'time', Component: Time },
  { field: 'description', Component: Description },
  { field: 'experienceLevel', Component: ExperienceLevel },
  { field: 'languages', Component: Languages },
  { field: 'gender', Component: Gender },
]

export type LeadState = {
  categoriesCount: Record<number, number>
  location: { address: string; lat: number; lng: number } | null
  dates: { startDate: string | null; endDate: string | null }
  time: { startTime: string | null; endTime: string | null }
  days: string[]
  description: string | null
  experienceLevel: number | null
  languages: number[]
  gender: number | null
}

export type LeadForm = {
  values: LeadState
  updateField: (args: unknown) => void
}

export const LeadFormPage = observer(() => {
  const { profile, companyDetails, getProfile, getСompanyDetails, submitLeadForm } = useStore().data

  const { isLoading: profileLoading } = useQuery(getProfile)
  const { isLoading: companyDetailsLoading } = useQuery(getСompanyDetails)

  const [submitResult, submit] = useMutation(submitLeadForm, {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: () => {
      redirect(routes.orderCreateSuccess, {})
    },
    onError: (err) => {
      if (err.data.errors) {
        setErrors(Object.values(err.data.errors).flat())
      }
    },
  })

  const [errors, setErrors] = useState<string[]>([])
  const [step, setStep] = useState(0)
  const [formState, setFormState] = useState<LeadState>({
    categoriesCount: {},
    location: null,
    dates: {
      startDate: null,
      endDate: null,
    },
    days: [],
    time: { startTime: null, endTime: null },
    description: null,
    experienceLevel: null,
    languages: [],
    gender: null,
  })

  const redirect = useRedirect()

  const { t } = useTranslation()

  const getNextStatus = () => {
    switch (step) {
      case 0:
        return !Boolean(Object.values(formState.categoriesCount).find((item) => item !== 0))
      case 1:
        return formState.location === null
      case 2:
        return !formState.dates.startDate || !formState.dates.endDate
      case 3:
        return formState.days.length === 0
      case 4:
        return !formState.time.startTime || !formState.time.endTime
      case 5:
        return formState.description === null || formState.description.length === 0
      case 6:
        return formState.experienceLevel === null
      default:
        return false
    }
  }

  const updateField = (field: string) => (fieldState: unknown) => {
    setFormState((prevState) => ({ ...prevState, [field]: fieldState }))
  }

  const isNextDisabled = getNextStatus()

  const { Component } = screens[step]

  const handleSubmit = () => {
    submit({
      company_name: companyDetails?.company_name as string,
      contact_email: profile?.email as string,
      contact_name: `${profile?.first_name} ${profile?.last_name}`,
      contact_phone: profile?.phone as string,
      days: formState.days,
      description: formState.description as string,
      end_date: formState.dates.endDate as string,
      end_time: formState.time.endTime as string,
      start_date: formState.dates.startDate as string,
      start_time: formState.time.startTime as string,
      experience: formState.experienceLevel as number,
      gender: formState.gender,
      language_ids: formState.languages,
      location: {
        address: formState.location?.address as string,
        lat: formState.location?.lat as number,
        lng: formState.location?.lng as number,
      },
      categories: formState.categoriesCount,
    })
  }

  if (profileLoading || companyDetailsLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className="LeadForm">
      <Separator.Vertical height={40} />
      <Header title={titles[step].title} subtitle={titles[step].subtitle} />
      <Component values={formState} updateField={updateField(screens[step].field)} />
      <Separator.Spacer minHeight={27} />
      {errors.length > 0 && <span className="LeadForm__errors">{errors.join(', ')}</span>}
      <Stepper
        stepsCount={screens.length}
        currentStep={step}
        handleNextPress={() => {
          if (step === screens.length - 1) {
            handleSubmit()
          } else {
            setStep((prevStep) => prevStep + 1)
          }
        }}
        handleBackPress={() => {
          setStep((prevStep) => prevStep - 1)
        }}
        nextDisabled={isNextDisabled || submitResult.isLoading}
        backDisabled={step === 0}
        nextButtonLabel={step === screens.length - 1 ? t('lead_form.submit') : t('lead_form.next')}
      />
      <Separator.Vertical height={40} />
    </div>
  )
})
