import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const DAY_OFF_REASON_ID = 13

export const ShiftCancelReasonModel = types.model('ShiftCancelReason', {
  id: types.number,
  name: types.string,
})

export const shiftCancelReasonActions = (self: DataStore) => ({
  fetchShiftCancelReasons: flow(function* () {
    const reasons = (yield self.request('get', 'common/shift-cancel-reasons')) as ShiftCancelReasonSnapshot[]
    reasons.forEach((reason: ShiftCancelReason) => {
      self.shiftCancelReasons.set(String(reason.id), reason)
    })
  }),
})

export const shiftCancelReasonViews = (self: DataStore) => ({
  get cancelReasons() {
    return Array.from(self.shiftCancelReasons.values())
  },

  getReasonIdByTitle(title: string) {
    return Array.from(self.shiftCancelReasons.values()).find((reason) => reason.name === title)?.id
  },
})

export type ShiftCancelReason = typeof ShiftCancelReasonModel.Type
export type ShiftCancelReasonSnapshot = typeof ShiftCancelReasonModel.SnapshotType
