import jobAllShiftsToolsImg from './job-all-shifts-tools.png'
import jobSingleShiftTools from './job-single-shift-tools.png'
import allJobsListImg from './all-jobs-list.png'
import changeScheduleImg from './change-schedule.png'
import cancelShiftImg from './cancel-shift.png'

export const images = {
  jobAllShiftsToolsImg,
  jobSingleShiftTools,
  allJobsListImg,
  changeScheduleImg,
  cancelShiftImg,
}
