import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../../components/app-layout'
import { TimeInput } from '../../../../../components/form/time-input/time-input'
import { ButtonSecondary } from '../../../../../components/button/button-secondary/button-secondary'
import { ButtonPrimary } from '../../../../../components/button/button-primary/button-primary'
import { Popup } from '../../../../../components/popup/popup'
import { IntervalModelSnapshot } from '../../../../../models/order/order-job-details'

type InputProps = {
  showPopup: boolean
  title: string
  isScheduledWeekly: boolean
  daysCount: number
  intervals: [string, IntervalModelSnapshot][]
  openEditSchedulePage: () => void
  closePopup: () => void
}

export const SchedulePopup = ({
  showPopup,
  title,
  isScheduledWeekly,
  daysCount,
  intervals,
  openEditSchedulePage,
  closePopup,
}: InputProps) => {
  const { t } = useTranslation()

  return (
    <Popup
      isActive={showPopup}
      content={
        <div className="JobDraftTime">
          <span className="Popup__title">{title}</span>
          <span className="Popup__subtitle">{t('order_extension.schedule_description')}</span>
          <>
            <span className="JobDraftTime__selectionCount">
              {isScheduledWeekly
                ? t('job_draft_details.time_days_selected', { count: daysCount })
                : t('job_draft_details.time_dates_selected', { count: daysCount })}
            </span>
            <Separator.Vertical height={16} />
            <div className="JobDraftTime__dates">
              {intervals.map(([day, time]) => {
                return (
                  <div className="JobDraftTime__dates__row" key={day}>
                    <div className={`JobDraftTime__dates__row__day`}>
                      <span className={`JobDraftTime__dates__row__day__label`}>
                        {isScheduledWeekly
                          ? t(`time.${day.toLowerCase()}`)
                          : moment(day, 'YYYY-MM-DD').format('MMM DD')}
                      </span>
                    </div>
                    <TimeInput
                      label={t('job_draft_details.start_time')}
                      value={time.start_time}
                      handleChange={() => {}}
                      className="JobDraftTime__dates__row__input"
                      required
                      disabled
                    />
                    <TimeInput
                      label={t('job_draft_details.end_time')}
                      value={time.end_time}
                      handleChange={() => {}}
                      className="JobDraftTime__dates__row__input"
                      required
                      disabled
                    />
                  </div>
                )
              })}
            </div>
            <Separator.Vertical height={40} />
          </>
          <div className="JobCard__schedule">
            <ButtonSecondary text={t('order_extension.edit')} action={openEditSchedulePage} />
            <ButtonPrimary text={t('order_extension.done')} action={closePopup} />
          </div>
        </div>
      }
      close={() => closePopup}
    />
  )
}
