import { useMemo } from 'react'

import playVideoIcon from './assets/play-video.svg'
import './applicant-interview.scss'

type ApplicantInterviewProps = {
  rating: number | null
  className?: string
  onClick: () => void
}

export function ApplicantInterview({ rating, className, onClick }: ApplicantInterviewProps) {
  const modificator = useMemo(() => {
    if (rating === null) return ''
    else if (rating >= 4) return '_top'
    else if (rating >= 3) return '_middle'
    else return '_low'
  }, [rating])

  const getFormattedRating = () => {
    if (rating === null) {
      return 'Not rated'
    }
    return rating % 2 === 0 ? rating : Math.round(rating * 10) / 10
  }

  return (
    <div className={`ApplicantInterview ApplicantInterview${modificator} ${className}`} onClick={onClick}>
      <div className="ApplicantInterview__side">
        <span className="ApplicantInterview__title">Interview:</span>
        <span className={`ApplicantInterview__rating ApplicantInterview__rating${modificator}`}>
          {getFormattedRating()}
        </span>
      </div>
      <div className="ApplicantInterview__side">
        <img src={playVideoIcon} className="ApplicantInterview__playImg" />
        <span className="ApplicantInterview__playLabel">Play Answers</span>
      </div>
    </div>
  )
}
