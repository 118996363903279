import { forwardRef } from 'react'
import { Field, FormikProps } from 'formik'
import './location-auto-complete.scss'
import { usePlacesWidget } from 'react-google-autocomplete'

import { config } from '../../config/env'
import { getCurrentMarketConfig } from '../../services/market-service'

export interface TextInputProps<T> {
  label: string
  getValue?: (value: string) => void
  formikKey: keyof T
  type?: string
  autocompleteType?: string
  disabled?: boolean
  formikProps: FormikProps<T>
  onPlaceSelected: (p: {
    geometry: { location: { lat: () => number; lng: () => number } }
    name: string
    formatted_address: string
    address_components: {
      long_name: string
      types: string[]
    }[]
  }) => void
}

const market = getCurrentMarketConfig().iso2Code

function LocationAutoComplete<T>({
  label,
  getValue,
  formikKey,
  formikProps,
  disabled,
  onPlaceSelected,
  autocompleteType = 'establishment',
}: TextInputProps<T>) {
  const { ref } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      componentRestrictions: { country: market },
      types: [autocompleteType],
      fields: ['name', 'address_components', 'geometry.location', 'place_id', 'formatted_address'],
    },
  })
  const handleKeyUp = (event: Event) => {
    if (getValue) {
      getValue((event.target as HTMLTextAreaElement).value)
    }
  }
  return (
    <div className="LocationAutoComplete">
      <Field
        placeholder=""
        disabled={disabled}
        name={formikKey}
        autoComplete="off"
        className="LocationAutoComplete__input"
        onKeyUp={handleKeyUp}
        type={'text'}
        required
        innerRef={ref}
      />
      <label className={`input-label ${Boolean(formikProps.getFieldProps(formikKey as string).value) && 'active'}`}>
        {label}
      </label>
      <p className="error-msg">{formikProps.touched[formikKey] && formikProps.errors[formikKey]}</p>
    </div>
  )
}

// @ts-nocheck
/* eslint-disable */
export default forwardRef<any, TextInputProps<any>>(LocationAutoComplete)
