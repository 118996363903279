import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../services/router'
import { AccountType } from '../../../models/account'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { TextInput } from '../../../components/text-input/text-input'
import { FieldError, FormFieldError } from '../../../components/form/field-error/field-error'
import { Separator } from '../../../components/app-layout/separator/separator'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { generateDeviceId } from '../../../utils/device-id-generator'
import { useFirebaseToken } from '../../../utils/use-firebase-token'
import { ResendOnboardingEmail } from './resend-onboarding-email/resend-onboarding-email'
import appLogoImg from '../../../components/assets/app-logo.svg'
import './create-password.scss'

type CreatePasswordProps = {
  navigateToScreenName: 'home' | 'all-jobs'
}

export const CreatePassword = observer(({ navigateToScreenName }: CreatePasswordProps) => {
  const firebaseToken = useFirebaseToken()

  const [resendModalTitle, setResendModalTitle] = useState('')
  const [isEmailSent, setEmailIsSent] = useState(false)
  const [resendErrors, setResendErrors] = useState<FormFieldError>()

  const { account, resetPassword, resendOnboardingEmail, logged } = useStore().data
  const redirect = useRedirect()
  const urlParams = new URLSearchParams(window.location.search)
  const changePasswordToken = String(urlParams.get('changePasswordToken'))

  const navigateToNextScreen = () => {
    if (account?.account_type === AccountType.NOT_CORPORATE || account?.is_corporate_details_complete) {
      redirect(routes.onboardingFinish, { navigateToScreenName })
    } else {
      redirect(routes.completeRegistration, { navigateToScreenName })
    }
  }

  const [setPasswordResult, setPassword] = useMutation(resetPassword, {
    onSuccess: navigateToNextScreen,
    onError: (err) => {
      if (err.data.code === 'token_invalid') {
        setResendModalTitle(err.data.message)
      } else if (err.data.message) {
        alert(err.data.message)
      }
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const [resendEmailResult, requestResendEmail] = useMutation(resendOnboardingEmail, {
    onSuccess: () => setEmailIsSent(true),
    onError: (err) => {
      if (err.data.errors) {
        setResendErrors(err.data.errors.email)
      } else {
        alert(err.data.message)
      }
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  useEffect(() => {
    if (logged) {
      redirect(routes.index, {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errors = setPasswordResult.error?.data.errors

  const { t } = useTranslation()

  return (
    <div className="CreatePassword">
      <img src={appLogoImg} className="CreatePassword__logo" />
      <Separator.Vertical height={45} />
      <h1 className="CreatePassword__title">{t('create_password.title')}</h1>
      <Separator.Vertical height={5} />
      <h2 className="CreatePassword__subtitle">{t('create_password.description')}</h2>
      <Separator.Vertical height={53} />
      {setPasswordResult.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          initialValues={{ password: '', confirm_password: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(t('create_password.please_enter_password')),
            confirm_password: Yup.string().required(t('create_password.please_confirm_password')),
          })}
          onSubmit={({ password }) => {
            setPassword({
              password,
              token: changePasswordToken,
              device_id: generateDeviceId(),
              firebase_token: firebaseToken,
            })
          }}
        >
          {(formikProps) => (
            <Form noValidate>
              <FieldError errors={errors?.password}>
                <TextInput
                  type="password"
                  label={t('create_password.password')}
                  formikProps={formikProps}
                  formikKey="password"
                />
              </FieldError>
              <Separator.Vertical height={15} />
              <TextInput
                type="password"
                label={t('create_password.confirm_password')}
                formikProps={formikProps}
                formikKey="confirm_password"
              />
              {formikProps.touched.password &&
                formikProps.values.confirm_password &&
                (formikProps.values.password === formikProps.values.confirm_password ? (
                  <p className="CreatePassword__matchedText">{t('create_password.password_matched')}</p>
                ) : (
                  <p className="CreatePassword__notMatchedText">{t('create_password.password_not_matched')}</p>
                ))}
              <Separator.Vertical height={25} />
              <button
                type="submit"
                className="CreatePassword__button"
                disabled={
                  formikProps.values.password !== formikProps.values.confirm_password || !formikProps.values.password
                }
              >
                {t('create_password.submit')}
              </button>
            </Form>
          )}
        </Formik>
      )}
      <ResendOnboardingEmail
        visible={Boolean(resendModalTitle)}
        loading={resendEmailResult.isLoading}
        title={resendModalTitle}
        close={() => setResendModalTitle('')}
        emailError={resendErrors}
        handleResendPress={requestResendEmail}
        showSuccessLabel={isEmailSent}
      />
    </div>
  )
})
