export const OGRAM_SALES_EMAIL = 'sales@ogram.ae'
export const OGRAM_PHONE = '800-OGRAM'
export const DEFAULT_CURRENCY = 'AED'
export const TERMS_CONDITIONS_LINK = 'http://help.ogram.co/en/articles/4287235-terms-conditions'
export enum ACCOUNT_TYPE {
  attendanceManager = 'A',
}
export const PRIVACY_POLICY = 'https://help.ogram.co/en/articles/4287328-privacy-policy'
export const PRIVACY_POLICY_CORPORATE_GR_EN = 'https://www.ogram.co/gr/en/privacy-policy-companies/'

export const CLIENT_STATUS_NOT_SUBMITTED = 2
export const CLIENT_STATUS_ACTIVE = 5
export const CLIENT_STATUS_REJECT = 6

export const CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS = [CLIENT_STATUS_NOT_SUBMITTED, CLIENT_STATUS_REJECT]

export const TERMS_AND_CONDITIONS_TYPE_PRIVACY_POLICY_CLIENT = 9
export const TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT = 10

export const API_VERSION_URL = 'common/version/check'

export const INITIAL_VERSION = '1.0.0'
export const VERSION_CACHE_NAME = 'version_cache'
export const VERSION_CACHE_KEY = 'app_version'

export const HELP_BUTTON_STYLE_FOR_ORDER_FIELD = {
  bottom: '80px',
  right: '3%',
  background: 'rgba(127, 114, 217, .7)',
}
