import { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { Link } from '../../../../services/router'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { routes } from '../../../../routes'
import './order-summary-card.scss'

interface OrderSummaryCardProps {
  location: string | undefined
  paymentTerms: number | undefined
  startDate: Moment | null
  endDate: Moment | null
  showCompleteDetails: boolean
  orderId: string
  showAmend: boolean
  isExtension?: boolean
}

const OrderSummaryCard = ({
  orderId,
  showAmend,
  location,
  startDate,
  endDate,
  paymentTerms,
  showCompleteDetails,
  isExtension = false,
}: OrderSummaryCardProps) => {
  const { t } = useTranslation()

  return (
    <div className="OrderSummaryCard">
      <div className="OrderSummaryCard__column">
        <div className={`OrderSummaryCard__row ${showCompleteDetails ? 'withWidget' : ''}`}>
          <p className="OrderSummaryCard__label">{t('order.payment_terms')} </p>
          <Separator.Spacer minWidth={20} />
          <p className="OrderSummaryCard__data">{paymentTerms ? `${paymentTerms} Day` : 'Upfront'} </p>
        </div>
        {showCompleteDetails && (
          <div className="OrderSummaryCard__column__completeCompanyDetails">
            <p className="OrderSummaryCard__column__completeCompanyDetails__description">
              {t('order.complete_company_details')}
            </p>
            <Link
              route={routes.completeCompanyDetailsForPaymentTerms}
              params={{ orderId, showAmend: String(showAmend) }}
              className="OrderSummaryCard__column__completeCompanyDetails__link"
            >
              {t('order.change_terms')}
            </Link>
          </div>
        )}
      </div>
      {location !== undefined && (
        <div className="OrderSummaryCard__row">
          <p className="OrderSummaryCard__label">{t('order.location')} </p>
          <Separator.Spacer minWidth={20} />
          <p className="OrderSummaryCard__data">{location}</p>
        </div>
      )}
      {startDate && (
        <div className="OrderSummaryCard__row">
          <p className="OrderSummaryCard__label">{t('order.start_date')} </p>
          <Separator.Spacer minWidth={20} />
          <p className="OrderSummaryCard__data">{startDate.format('DD MMM YYYY')}</p>
        </div>
      )}
      {endDate && (
        <div className="OrderSummaryCard__row">
          <p className="OrderSummaryCard__label">
            {t('order.end_date')}{' '}
            {isExtension && <span className="OrderSummaryCard__data__label">[{t('order_draft.updated')}]</span>}
          </p>
          <Separator.Spacer minWidth={20} />
          <p className="OrderSummaryCard__data">{endDate.format('DD MMM YYYY')}</p>
        </div>
      )}
    </div>
  )
}

export default OrderSummaryCard
