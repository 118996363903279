import * as Yup from 'yup'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { PastSPSnapshot } from '../../../../models/past-workers'

export const NoteInput = ({
  setSPNote,
  sp,
  className,
  placeholder,
}: {
  setSPNote: ({ spId, note }: { spId: number; note: string }) => void
  sp: PastSPSnapshot
  className: string
  placeholder: string
}) => {
  const { t } = useTranslation()

  const validationSchema = Yup.object({
    note: Yup.string().max(499, t('staff.note_validation_length')),
  })

  const formik = useFormik({
    initialValues: {
      note: String(sp.note),
    },
    validationSchema,
    onSubmit: values => {
      setSPNote({ spId: sp.id, note: values.note })
    },
  })

  const handleChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target

    // Validate the new value
    try {
      await validationSchema.validateAt(name, { [name]: value })
      // If valid, update the value in Formik state
      formik.setFieldValue(name, value, true) // Pass true to trigger validation
      formik.setFieldTouched(name, true) // Mark field as touched
      formik.submitForm() // Automatically submit form on change
    } catch (error) {
      // If invalid, show toast notification
      if (error instanceof Yup.ValidationError) {
        toast.error(error.message)
      }
    }
  }

  const handleChangeWrapper = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e).catch(error => {
      console.error('Error handling change:', error)
    })
  }

  useEffect(() => {
    if (formik.errors.note && formik.touched.note) {
      toast.error(formik.errors.note)
    }
  }, [formik.errors, formik.touched])

  return (
    <textarea
      className={className}
      placeholder={placeholder}
      name="note"
      id="note"
      value={formik.values.note}
      onChange={handleChangeWrapper}
    ></textarea>
  )
}
