import { addMiddleware, cast, Instance, types } from 'mobx-state-tree'

import { DraftJobInstance } from '../draft-job'
import { DraftJobFields } from './fields'

export const InvitedSPsModel = types
  .model('InvitedSPs', {
    list: types.maybeNull(types.array(types.number)),
    error: types.maybeNull(types.string),
  })
  .actions(self => ({
    add(id: number) {
      if (self.list) {
        self.list.push(id)
      } else {
        self.list = cast([id])
      }
    },

    remove(id: number) {
      self.list = cast(self.list?.filter(item => item !== id))
    },

    set(invitedSPs: number[]) {
      self.list = cast(invitedSPs)
    },

    validate() {
      self.error = null
      return true
    },
  }))
  .views(self => ({
    has(id: number) {
      return Boolean(self.list?.includes(id))
    },
  }))

export function createInvitedSPsMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.invitedSPs) {
      return abort(targetInstance.invitedSPs.validate())
    }
    return next(call)
  })
}

export type InvitedSPsInstance = Instance<typeof InvitedSPsModel>
