import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

const InterviewModel = types.model('Interview', {
  id: types.identifierNumber,
  job_id: types.number,
  questions: types.array(types.number),
})

const QuestionModel = types.model('Question', {
  id: types.identifierNumber,
  description: types.string,
  answers: types.array(types.number),
})

const AnswerModel = types.model('Answer', {
  id: types.identifierNumber,
  sp_id: types.number,
  answer_time: types.number,
  file_url: types.string,
  rating: types.maybeNull(types.number),
})

export const InterviewStoreModel = types.model('InterviewStore', {
  interviews: types.map(InterviewModel),
  questions: types.map(QuestionModel),
  answers: types.map(AnswerModel),
  sps: types.map(types.map(types.map(types.reference(AnswerModel)))), // Record<spId, Record<interviewId, Record<question_id, reference to AnswerModel>>>
  questionListStore: types.model('InterviewQuestions', {
    list: types.array(types.number),
    paginator: types.model({
      current_page: types.number,
      next_page: types.maybeNull(types.number),
      per_page: types.number,
      last_page: types.number,
      total: types.number,
    }),
  }),
})

export const interviewActions = (self: DataStore) => ({
  getQuestions: flow(function* ({ page }: { page: number }) {
    const { list: questions, paginator } = (yield self.request('get', 'interviews/v1/client/questions', undefined, {
      params: { page },
    })) as { list: QuestionSnapshot[]; paginator: Paginator }
    if (page === 1) {
      self.interviewStore.questionListStore.list.clear()
    }
    questions.forEach(q => {
      self.interviewStore.questions.set(String(q.id), q)
    })
    self.interviewStore.questionListStore.list.push(...questions.map(q => q.id))
    self.interviewStore.questionListStore.paginator = paginator
    return questions
  }),

  addQuestion: flow(function* ({ description }: { description: string }) {
    const createdQuestion = (yield self.request('post', 'interviews/v1/client/questions', {
      description,
    })) as QuestionSnapshot
    self.interviewStore.questionListStore.list.push(createdQuestion.id)
    self.interviewStore.questions.set(String(createdQuestion.id), createdQuestion)
    return createdQuestion
  }),

  getInterview: flow(function* ({ jobId }: { jobId: number }) {
    const interview = (yield self.request('get', 'interviews/v1/client/interviews', undefined, {
      params: { job_id: jobId },
    })) as {
      id: number
      questions: {
        id: number
        description: string
        answers: {
          id: number
          sp_id: number
          answer_time: number
          file_url: string
          rating: number | null
        }[]
      }[]
    }
    self.interviewStore.interviews.set(String(interview.id), {
      id: interview.id,
      job_id: jobId,
      questions: interview.questions.map(q => {
        self.interviewStore.questions.set(String(q.id), {
          id: q.id,
          description: q.description,
          answers: q.answers.map(a => {
            self.interviewStore.answers.set(String(a.id), a)
            const existingSp = self.interviewStore.sps.get(String(a.sp_id))
            if (existingSp) {
              const existingInterview = existingSp.get(String(interview.id))
              if (existingInterview) {
                existingInterview.set(String(q.id), a.id)
              } else {
                existingSp.set(String(interview.id), { [q.id]: a.id })
              }
            } else {
              self.interviewStore.sps.set(String(a.sp_id), {
                [interview.id]: { [q.id]: a.id },
              })
            }
            return a.id
          }),
        })
        return q.id
      }),
    })
    return interview
  }),

  createInterview: flow(function* ({
    jobId,
    workCategoryId,
    questions,
  }: {
    jobId: number
    workCategoryId: number
    questions: number[]
  }) {
    yield self.request('post', 'interviews/v1/client/interviews', {
      job_id: jobId,
      work_category_id: workCategoryId,
      questions,
    })
  }),

  updateInterview: flow(function* ({
    interviewId,
    jobId,
    workCategoryId,
    questions,
  }: {
    interviewId: number
    jobId: number
    workCategoryId: number
    questions: number[]
  }) {
    yield self.request('put', `interviews/v1/client/interviews/${interviewId}`, {
      work_category_id: workCategoryId,
      job_id: jobId,
      questions,
    })
  }),

  deleteInterview: flow(function* ({ interviewId }: { interviewId: number }) {
    yield self.request('delete', `interviews/v1/client/interviews/${interviewId}`)
    self.interviewStore.interviews.delete(String(interviewId))
  }),

  rateAnswer: flow(function* ({ answerId, rating }: { answerId: number; rating: number }) {
    yield self.request('post', `interviews/v1/client/answers/${answerId}`, { rating })
    if (self.interviewStore.answers.has(String(answerId))) {
      ;(self.interviewStore.answers.get(String(answerId)) as Answer).rating = rating
    }
  }),
})

export const interviewViews = (self: DataStore) => ({
  get questionList() {
    return Array.from(self.interviewStore.questionListStore.list.values())
  },

  get nextQuestionPage() {
    return self.interviewStore.questionListStore.paginator.next_page
  },

  getSpRating({ interviewId, spId }: { interviewId: number; spId: number }) {
    const sp = self.interviewStore.sps.get(String(spId))
    const questions = sp?.get(String(interviewId))
    if (!questions) {
      return null
    }
    let answers = 0
    let sum = 0
    Array.from(questions.values()).forEach(item => {
      if (item.rating !== null) {
        answers++
        sum += item.rating
      }
    })
    if (sum === 0 || answers === 0) return null
    return sum / answers
  },

  getSpAnswer({ spId, interviewId, questionId }: { spId: number; interviewId: number; questionId: number }) {
    const sp = self.interviewStore.sps.get(String(spId))
    const interview = sp?.get(String(interviewId))
    return interview?.get(String(questionId)) ?? null
  },

  spHasAnswers({ spId, interviewId }: { spId: number; interviewId: number }) {
    const sp = self.interviewStore.sps.get(String(spId))
    return sp?.has(String(interviewId)) ?? false
  },

  getQuestionNames(ids: number[]) {
    return new Array(ids.length)
      .fill('')
      .map((_, index) => self.interviewStore.questions.get(String(ids[index]))?.description ?? '')
  },

  getQuestionById(id: number) {
    return self.interviewStore.questions.get(String(id))
  },

  getInterviewQuestions(interviewId: number) {
    return self.interviewStore.interviews.get(String(interviewId))?.questions ?? ([] as number[])
  },

  getInterviewById(id: number) {
    return self.interviewStore.interviews.get(String(id))
  },

  getInterviewByJobId(id: number) {
    return Array.from(self.interviewStore.interviews.values()).find(i => i.job_id === id)
  },
})

export const defaultInterviewStore = types.optional(InterviewStoreModel, {
  interviews: {},
  questions: {},
  answers: {},
  sps: {},
  questionListStore: {
    list: [],
    paginator: {
      current_page: 1,
      next_page: null,
      per_page: 20,
      last_page: 1,
      total: 0,
    },
  },
})

export type Interview = Instance<typeof InterviewModel>

export type Question = Instance<typeof QuestionModel>
export type QuestionSnapshot = SnapshotOut<typeof QuestionModel>

export type Answer = Instance<typeof AnswerModel>
export type AnswerSnapshot = SnapshotOut<typeof AnswerModel>
