import './card.scss'

type Props = {
  amount: number
  title: string
  backgroundColor: string
  color: string
  image: string
  onPress: () => void
}

export const Card = ({ amount, title, backgroundColor, color, image, onPress }: Props) => {
  return (
    <div onClick={onPress} className="Card" style={{ backgroundColor }}>
      <img src={image} alt="" className="img" />
      <div className="Card__right-container">
        <span className="amount" style={{ color }}>
          {amount}
        </span>
        <span className="title" style={{ color }}>
          {title}
        </span>
      </div>
    </div>
  )
}
