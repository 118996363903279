import { memo } from 'react'
import { Map, Marker, GoogleApiWrapper, IMapProps } from '@peacechen/google-maps-react'

import { config } from '../../config/env'
import markerIcon from '../../components/assets/location-icon.svg'
import { mapStyle } from './custom-map-styles'
import { getCurrentMarketConfig } from '../../services/market-service'

export interface GoogleMapsProps extends IMapProps {
  markerLat: number | null
  markerLng: number | null
  onMarkerChange: (location: { lat: number; lng: number }) => void
}

const comparison = (prevProps: GoogleMapsProps, nextProps: GoogleMapsProps) => {
  return prevProps.markerLat === nextProps.markerLat && prevProps.markerLng === nextProps.markerLng
}

const containerStyle = { position: 'relative' }

const GoogleMaps = (props: GoogleMapsProps) => {
  const { centerCoordinatesOnMap } = getCurrentMarketConfig()

  const onMapLoaded = (mapProps: IMapProps | undefined, map: typeof props.google) => {
    map.setOptions({
      styles: mapStyle,
      disableDefaultUI: true,
    })
  }
  const onMarkerDrag = (_: unknown, marker: { position: { lat: () => number; lng: () => number } }) => {
    props.onMarkerChange({ lat: marker.position.lat(), lng: marker.position.lng() })
  }
  return (
    <Map
      containerStyle={containerStyle}
      initialCenter={{
        lat: props.markerLat ?? centerCoordinatesOnMap.lat,
        lng: props.markerLng ?? centerCoordinatesOnMap.lng,
      }}
      center={{
        lat: props.markerLat ?? centerCoordinatesOnMap.lat,
        lng: props.markerLng ?? centerCoordinatesOnMap.lng,
      }}
      google={props.google}
      zoom={props.markerLat ? 15 : 7}
      onReady={(mapProps, map) => onMapLoaded(mapProps, map)}
    >
      {props.markerLat && (
        <Marker
          // @ts-ignore
          icon={{
            url: markerIcon,
            scaledSize: new props.google.maps.Size(25, 30),
          }}
          draggable={true}
          onDragend={onMarkerDrag}
          position={{ lat: props.markerLat, lng: props.markerLng }}
        ></Marker>
      )}
    </Map>
  )
}

const GoogleMapsMemoized = memo(GoogleMaps, comparison)

export default GoogleApiWrapper({
  apiKey: config.GOOGLE_API_KEY,
})(GoogleMapsMemoized)
