import './referral-list-item.scss'
import { useTranslation } from 'react-i18next'
import { Image } from 'antd'

import { getStatusSettings } from '../../helper'
import { AffiliateReferralSnapshot, ReferralStatus } from '../../../../models/affiliate'
import { ShiftsLabel } from './components/shifts-label/shifts-label'
import { Table } from './components/table/table'

export const ReferralListItem = ({ affiliateReferral }: { affiliateReferral: AffiliateReferralSnapshot }) => {
  const { t } = useTranslation()

  const status = affiliateReferral.status as ReferralStatus

  return (
    <div className="AffiliateReferralListItem">
      <div className="AffiliateReferralListItem__top">
        <div className="AffiliateReferralListItem__top__left">
          {affiliateReferral.image && (
            <Image className="AffiliateReferralListItem__top__left__image" src={affiliateReferral.image} />
          )}
          <div className="AffiliateReferralListItem__top__left__userInfo">
            <span className="AffiliateReferralListItem__top__left__userInfo__name">{affiliateReferral.fullName}</span>
            <ShiftsLabel affiliateReferral={affiliateReferral} />
          </div>
        </div>
        <div className="AffiliateReferralListItem__top__right">
          <div
            className="AffiliateReferralListItem__top__right__statusLabel"
            style={{
              background: `rgba(${getStatusSettings(status).mainColorRGB.join(', ')}, 0.2)`,
              color: `rgb(${getStatusSettings(status).mainColorRGB.join(', ')})`,
            }}
          >
            {t(getStatusSettings(status).title)}
          </div>
        </div>
      </div>
      <div className="AffiliateReferralListItem__bottom">
        <Table affiliateReferral={affiliateReferral} />
      </div>
    </div>
  )
}
