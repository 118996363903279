import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const PointsOfContactsModel = types.model('PointsOfContacts', {
  id: types.identifierNumber,
  first_name: types.string,
  last_name: types.string,
  phone: types.string,
  email: types.string,
  image: types.maybeNull(types.string),
  roles: types.array(types.number),
  client_locations: types.maybeNull(types.array(types.number)),
})

export const pointsOfContactActions = (self: DataStore) => ({
  getPointsOfContact: flow(function* ({ locationId }: { locationId?: number }) {
    self.pointsOfContact = yield self.request(
      'get',
      `client/pocs${locationId !== undefined ? `?location_id=${locationId}` : ''}`,
    )
    return self.pointsOfContact
  }),

  addNewContact: flow(function* (form: Record<string, unknown>) {
    yield self.request('post', 'client/pocs', form, { headers: { 'Content-Type': 'multipart/form-data' } })
  }),

  updateContact: flow(function* (form: Record<string, unknown>) {
    yield self.request('post', `client/pocs/${form.id}`, form, { headers: { 'Content-Type': 'multipart/form-data' } })
  }),

  inviteUser: flow(function* (form: Record<string, unknown>) {
    yield self.request('post', 'client/pocs/invite', form, { headers: { 'Content-Type': 'multipart/form-data' } })
  }),

  deleteContact: flow(function* (id: number) {
    const indexOfContact = self.pointsOfContact?.findIndex((contact) => contact.id === id)
    if (indexOfContact) {
      self.pointsOfContact?.splice(indexOfContact, 1)
    }
    yield self.request('delete', `client/pocs/${id}`)
  }),

  markClientCreatedPOCs: flow(function* () {
    if (self.companyDetails) {
      self.companyDetails.is_pocs_created = 1
    }

    yield self.request('post', 'client/pocs/mark-client-created-pocs')
  }),

  confirmClientInvitation: flow(function* (invitedClientId: string) {
    yield self.request('post', `client/pocs/confirm-client-invitation/${invitedClientId}`)
  }),
})

export const pointsOfContactViews = (self: DataStore) => ({
  getContactById(id: number) {
    return self.pointsOfContact?.find((contact) => contact.id === id)
  },

  getContactIdByTitle(fullName: string) {
    const [firstName, lastName] = fullName.split(' ')
    return self.pointsOfContact?.find((contact) => contact.first_name === firstName && contact.last_name === lastName)
      ?.id
  },

  getContactFullName(id: number) {
    const poc = self.pointsOfContact?.find((contact) => contact.id === id)
    if (poc) {
      return `${poc.first_name} ${poc.last_name}`
    }
  },
})

export type PointsOfContacts = Instance<typeof PointsOfContactsModel>
export type PointsOfContactsSnapshot = SnapshotOut<typeof PointsOfContactsModel>
