import { AxiosResponse } from 'axios'

import { addBreadcrumb } from '../sentry'

export const breadcrumbApiResponse = (response: AxiosResponse) => {
  addBreadcrumb({
    message: 'API request/response',
    category: 'network',
    data: {
      status: response.status,
      serverResponse: response.data,
      requestData: {
        data: response.config.data,
        baseURL: response.config.baseURL,
        headers: response.config.headers,
        method: response.config.method,
      },
    },
  })

  return response
}
