import { PublicTransportAccessibility } from '../../../models-ui/order/store/job-fields/transport-accessibility'

export const getPublicTransportAccessibilityName = (accessibility: number) => {
  switch (accessibility) {
    case Number(PublicTransportAccessibility.accessible):
      return 'order_draft.public_transport_accessibility_accessible'
    case Number(PublicTransportAccessibility.notAccessible):
      return 'order_draft.public_transport_accessibility_not_accessible'
    default:
      return 'order_draft.public_transport_accessibility_accessible'
  }
}
