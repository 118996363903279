export const images = {
  allJobsImg: require('./all-jobs.svg'),
  applicantsReviewImg: require('./applicants-review.svg'),
  attendanceReviewImg: require('./attendance-review.svg'),
  homeImg: require('./home.svg'),
  manageLocationsImg: require('./manage-locations.svg'),
  registerCorporateImg: require('./register-corporate.svg'),
  usersImg: require('./users.svg'),
  ordersImg: require('./orders.svg'),
  activeOrdersImg: require('./active.svg'),
  allUsersImg: require('./all.svg'),
  closeSideBarImg: require('./close-sidebar.svg'),
  draftOrdersImg: require('./drafts.svg'),
  favouriteUsersImg: require('./drafts.svg'),
  myStaffUsersImg: require('./my-staff.svg'),
  pastOrdersImg: require('./past.svg'),
  starImg: require('./star.svg'),
  invoiceImg: require('./invoice.svg'),
  paymentImg: require('./payment.svg'),
}
