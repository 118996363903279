import { useTranslation, Trans } from 'react-i18next'

import Spacer from '../../../../order/components/spacer/spacer'
import { OgramModal } from '../../../../../components/ogram-modal/ogram-modal'
import './cherry-picking-confirm-modal.scss'

interface CherryPickingConfirmModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  onConfirm: () => void
  applicantName: string
  type: 'accept' | 'reject'
}

const CherryPickingConfirmModal = ({
  modalIsOpen,
  closeModal,
  onConfirm,
  applicantName,
  type,
}: CherryPickingConfirmModalProps) => {
  const { t } = useTranslation()

  const getDescription = () => {
    if (type === 'accept') {
      return (
        <Trans i18nKey="job_cherry_picking.you_are_about_to_accept" name={applicantName}>
          You are about to accept{' '}
          <span className="CherryPickingConfirmModal__modalBoldText">{{ name: applicantName }}</span> for the job
        </Trans>
      )
    }
    return (
      <Trans i18nKey="job_cherry_picking.you_are_about_to_reject" name={applicantName}>
        You are about to reject{' '}
        <span className="CherryPickingConfirmModal__modalBoldText">{{ name: applicantName }}</span> They will be removed
        from the list
      </Trans>
    )
  }

  return (
    <OgramModal modalVisible={modalIsOpen} className="CherryPickingConfirmModal" onClose={closeModal}>
      <h2 className="CherryPickingConfirmModal__modalTitle">{t('job_cherry_picking.confirmation')}</h2>
      <Spacer space={29} />
      <p className="CherryPickingConfirmModal__modalText">{getDescription()}</p>
      <Spacer space={35} />
      <div className="CherryPickingConfirmModal__actionButtonsContainer">
        <button className="CherryPickingConfirmModal__confirm" onClick={onConfirm}>
          {t('job_cherry_picking.confirm')}
        </button>
        <button className="CherryPickingConfirmModal__cancel" onClick={closeModal}>
          {t('job_cherry_picking.cancel')}
        </button>
      </div>
    </OgramModal>
  )
}

export default CherryPickingConfirmModal
