import assert from './utils/assert'
import objectToGetParams from './utils/object-to-get-params'
import createShareButton from './hocs/create-share-button'
import icon from './assets/whatsapp.svg'

function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
}

function whatsappLink(url: string, { title, separator }: { title?: string; separator?: string }) {
  assert(url, 'whatsapp.url')
  return `https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${objectToGetParams({
    text: title ? title + separator + url : url,
  })}`
}

const Button = createShareButton<{ title?: string; separator?: string }>(
  'whatsapp',
  whatsappLink,
  (props) => ({
    title: props.title,
    separator: props.separator ?? ' ',
  }),
  {
    windowWidth: 550,
    windowHeight: 400,
  },
)

const WhatsappShareButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <Button url={url} title={title}>
      <img src={icon} style={{ cursor: 'pointer' }} />
    </Button>
  )
}

export default WhatsappShareButton
