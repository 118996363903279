import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import AddLocation from '../../../locations/add-location/add-location'
import { useStore, useDelayedQuery } from '../../../../utils/mst-hooks'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import { Button } from '../../../../components/button/button'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { Select } from '../../../../components/form/select/select'
import { LeadForm } from '../../lead-form'
import crossImg from '../../../../components/assets/cross-icon-black.svg'
import locationImg from './assets/location.svg'
import './location.scss'

export const Location = observer(({ values, updateField }: LeadForm) => {
  const { locationsDataView, getLocations, getLocationByName } = useStore().data

  const [regionsModalVisible, setRegionsModalVisible] = useState(false)

  const {
    query: { isLoading: locationsLoading },
    enableQuery: loadLocations,
  } = useDelayedQuery(getLocations)

  const locationOptions =
    locationsDataView.length > 0
      ? locationsDataView.map((location) => ({
          key: String(location.id),
          label: location.name,
          value: location.name,
        }))
      : null

  useEffect(() => {
    if (locationsDataView.length === 0) {
      loadLocations()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!values.location && locationsDataView.length > 0) {
      updateField({
        address: locationsDataView[0].address,
        lat: locationsDataView[0].lat,
        lng: locationsDataView[0].lng,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsDataView, locationsDataView.length])

  const { t } = useTranslation()

  return (
    <div className="Location">
      <Separator.Vertical height={63} />
      {locationsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {locationOptions !== null && (
            <Select
              label={t('lead_form.location')}
              value={values.location?.address}
              onChange={(value) => {
                const location = getLocationByName(value)
                updateField({
                  address: location?.address,
                  lat: location?.lat,
                  lng: location?.lng,
                })
              }}
              options={locationOptions}
              className="Location__locationSelect"
            />
          )}
        </>
      )}
      <Separator.Vertical height={35} />
      <Button
        onClick={() => {
          setRegionsModalVisible(true)
        }}
        className="Location__addLocationButton"
      >
        <img src={locationImg} className="Location__addLocationButton__img" />
        <span className="Location__addLocationButton__title">{t('lead_form.add_new_location')}</span>
      </Button>
      <OgramModal
        modalVisible={regionsModalVisible}
        onClose={() => {
          setRegionsModalVisible(false)
        }}
        className="Location__modal"
      >
        <img
          src={crossImg}
          onClick={() => {
            setRegionsModalVisible(false)
          }}
          className="Location__modal__modalCloseImg"
        />
        <p className="Location__modal__modalTitle">{t('lead_form.add_location')}</p>
        <AddLocation
          onSubmit={() => {
            setRegionsModalVisible(false)
          }}
        />
      </OgramModal>
    </div>
  )
})
