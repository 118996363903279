import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import './experience.scss'
import { useQuery, useStore } from '../../../../../utils/mst-hooks'
import { ExperienceButton } from './experience-button'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { getCurrentMarketConfig } from '../../../../../services/market-service'
import { Experience } from '../../../../../models/seniority-level'
import { JobDraftFieldError } from '../error/error'
import LoadingSpinner from '../../../../loading-spinner/loading-spinner'

export const JobDraftExperience = observer(({ job }: { job: DraftJobInstance }) => {
  const workCategory = job.category.id as number

  const { getSeniorityLevels, getSeniorityLevelById } = useStore().data
  const { isLoading: seniorityLevelsLoading } = useQuery(() => getSeniorityLevels(workCategory))

  const seniorityLevelJunior = getSeniorityLevelById(workCategory, Number(Experience.junior))
  const seniorityLevelMiddle = getSeniorityLevelById(workCategory, Number(Experience.middle))
  const seniorityLevelSenior = getSeniorityLevelById(workCategory, Number(Experience.senior))
  const seniorityLevelStudent = getSeniorityLevelById(workCategory, Number(Experience.beginnerOrStudent))

  const { t } = useTranslation()

  const experience = job.experience.value

  if (seniorityLevelsLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className="JobDraftExperience">
      <span className="Popup__title">{t('job_draft_details.experience_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.experience_subtitle')}</span>
      <div className="JobDraftExperience__innerContainer">
        {seniorityLevelStudent && (
          <>
            <ExperienceButton
              onClick={() => {
                job.experience.set(Experience.beginnerOrStudent)
              }}
              isActive={experience === Experience.beginnerOrStudent}
              title={t(getCurrentMarketConfig().order.nonExperiencedTitle)}
              seniorityLevel={seniorityLevelStudent}
              isError={job.experience.hasError}
            />
            <Separator.Vertical height={10} />
          </>
        )}
        {seniorityLevelJunior && (
          <>
            <ExperienceButton
              onClick={() => {
                job.experience.set(Experience.junior)
              }}
              isActive={experience === Experience.junior}
              title={t('job_draft_details.experience_junior')}
              seniorityLevel={seniorityLevelJunior}
              isError={job.experience.hasError}
            />
            <Separator.Vertical height={10} />
          </>
        )}
        {seniorityLevelMiddle && (
          <>
            <ExperienceButton
              onClick={() => {
                job.experience.set(Experience.middle)
              }}
              isActive={experience === Experience.middle}
              title={t('job_draft_details.experience_middle')}
              seniorityLevel={seniorityLevelMiddle}
              isError={job.experience.hasError}
            />
            <Separator.Vertical height={10} />
          </>
        )}
        {seniorityLevelSenior && (
          <>
            <ExperienceButton
              onClick={() => {
                job.experience.set(Experience.senior)
              }}
              isActive={experience === Experience.senior}
              title={t('job_draft_details.experience_senior')}
              seniorityLevel={seniorityLevelSenior}
              isError={job.experience.hasError}
            />
            <Separator.Vertical height={15} />
          </>
        )}
        <span className="JobDraftExperience__innerContainer__hint">{t('job_draft_details.experience_hint')}</span>
        <JobDraftFieldError error={job.experience.error} />
      </div>
    </div>
  )
})
