import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const RatingCriteriaModel = types.model('RatingCriteria', {
  id: types.identifierNumber,
  name: types.string,
  type: types.string,
})

export const ratingCriteriaActions = (self: DataStore) => ({
  getRatingCriteries: flow(function* () {
    const criterias = (yield self.request('get', 'common/rating_criteria')) as RatingCriteriaSnapshot[]
    criterias.forEach((criteria) => {
      self.ratingCriteries.set(String(criteria.id), criteria)
    })
  }),
})

export const ratingCriteriaViews = (self: DataStore) => ({
  get criteriesArray() {
    return Array.from(self.ratingCriteries.values())
  },

  get negativeCriteries() {
    return Array.from(self.ratingCriteries.values()).filter((criteria) => criteria.type === 'negative')
  },

  get positiveCriteries() {
    return Array.from(self.ratingCriteries.values()).filter((criteria) => criteria.type === 'positive')
  },
})

export type RatingCriteria = typeof RatingCriteriaModel.Type
export type RatingCriteriaSnapshot = typeof RatingCriteriaModel.SnapshotType
