import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import englishTranslation from './en.json'
import greekTranslation from './el.json'
import arabicTranslation from './ar.json'
import { save } from '../services/storage'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: englishTranslation,
      },
      el: {
        translation: greekTranslation,
      },
      ar: {
        translation: arabicTranslation,
      },
    },
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'en',
    supportedLngs: ['en', 'el', 'ar'],
    load: 'languageOnly',
  })

save('userLanguage', i18next.language)

export const { t } = i18next
