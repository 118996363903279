import { useContext } from 'react'

import { CarouselSlideAction } from '../types'
import { UIStateContext } from '../../ui-state-management/ui-state-management'

type Props = {
  containerClassNames: string
  icon: string
  title: string
  actions: CarouselSlideAction[]
  imageStyle?: object
}

export const CarouselSlide = ({ containerClassNames, icon, title, actions, imageStyle }: Props) => {
  const { isRtl } = useContext(UIStateContext)
  return (
    <div className={`CarouselSlide ${containerClassNames}`} dir={isRtl ? 'rtl' : 'ltr'}>
      <img src={icon} className="CarouselSlide__img" style={imageStyle} />
      <div className="CarouselSlide__rightContainer">
        <span className="CarouselSlide__rightContainer__title">{title}</span>
        <div className="CarouselSlide__rightContainer__actions">
          {actions.map((action: CarouselSlideAction, key) => (
            <button
              key={key}
              onClick={action.onClick}
              className={`CarouselSlide__rightContainer__button ${action.classNames}`}
            >
              <span>{action.title}</span>
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
