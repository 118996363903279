import { observer } from 'mobx-react'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Question } from '../../../../models/interview'
import { useStore } from '../../../../utils/mst-hooks'
import { Popup } from '../../../../components/popup/popup'
import { ToggleFavourite } from '../toggle-favourite/toggle-favourite'
import { AnswerForm } from './components/answer-form'
import { ResultForm } from './components/result-form'
import { FormStepper } from './components/form-stepper'
import './interview.scss'

export type InterviewProps = {
  applicantsType: 'single' | 'multiple'
  jobId: number
  showInitialQuestion: { qId: number; qIndex: number } | null
  onAcceptPress: () => void
}

function InterviewComponent({ applicantsType, jobId, showInitialQuestion, onAcceptPress }: InterviewProps) {
  const { t } = useTranslation()
  const {
    currentApplicant,
    notAcceptedApplicantsList,
    getInterviewByJobId,
    getInterviewQuestions,
    getQuestionById,
    setCurrentApplicant,
    spHasAnswers,
    getSpAnswer,
  } = useStore().data
  const [currentQuestion, setCurrentQuestion] = useState<{ qId: number; qIndex: number } | null>(null)
  const [showResultRating, setShowResult] = useState(false)
  useEffect(() => {
    if (showInitialQuestion?.qId !== currentQuestion?.qId) {
      setCurrentQuestion(showInitialQuestion)
    }
  }, [showInitialQuestion])
  const applicant = currentApplicant

  const interview = getInterviewByJobId(jobId)

  if (!applicant || !interview) return null

  const questions = getInterviewQuestions(interview.id)
  const questionsWithAnswers = questions.filter(questionId => {
    return Boolean(getSpAnswer({ spId: applicant.id, interviewId: interview.id, questionId }))
  })

  const onClose = () => {
    setCurrentQuestion(null)
    setShowResult(false)
  }

  const findApplicantIndex = (checkCustomCondition: (i: number) => boolean) => {
    return notAcceptedApplicantsList.findIndex((applicantItem, index) => {
      const applicantHasAnswers = spHasAnswers({ spId: applicantItem.id, interviewId: interview.id })
      return checkCustomCondition(index) && applicantItem.is_accepted === 0 && applicantHasAnswers
    })
  }

  const onNextApplicant = () => {
    const currentApplicantIndex = notAcceptedApplicantsList.findIndex(
      applicantItem => applicantItem.id === applicant.id,
    )
    // find the next not accepted applicant standing after the current one
    let nextApplicantIndex = findApplicantIndex(index => index > currentApplicantIndex)
    if (nextApplicantIndex === -1) {
      // find the first not accepted applicant standing before the current one
      nextApplicantIndex = findApplicantIndex(index => index < currentApplicantIndex)
      if (nextApplicantIndex === -1) {
        // if not-accepted applicants are not found, let user know about it and close
        toast.info(t('interview.applicants_finished'))
        onClose()
        return
      }
    }
    const nextApplicantId = notAcceptedApplicantsList[nextApplicantIndex].id
    setCurrentApplicant(nextApplicantId)

    const nextApplicantQuestions = questions.filter(questionId =>
      Boolean(getSpAnswer({ spId: nextApplicantId, interviewId: interview.id, questionId })),
    )
    setCurrentQuestion({ qIndex: 0, qId: nextApplicantQuestions[0] })
    setShowResult(false)
  }

  return (
    <Popup
      isActive={currentQuestion !== null || showResultRating}
      close={onClose}
      content={
        currentQuestion ? (
          <div className="Interview">
            <div>
              <div className="Interview__header">
                <div className="Interview__headerLeft">
                  {typeof applicant.image_url === 'string' && (
                    <img src={applicant.image_url} alt="" className="Interview__avatar" />
                  )}
                  <div>
                    <div className="Interview__title">{applicant.name}</div>
                    <div className="Interview__subtitle">{t('interview.applicant_id', { id: applicant.id })}</div>
                  </div>
                </div>
                <ToggleFavourite />
              </div>
              <div className="Interview__bottomSeparator" />
              {questionsWithAnswers.map((questionId, index) => {
                const question = getQuestionById(questionId) as Question
                return (
                  <AnswerForm
                    key={question.id}
                    visible={!showResultRating && currentQuestion.qId === questionId}
                    interviewId={interview.id}
                    questionId={question.id}
                    questionIndex={index}
                  />
                )
              })}
              <ResultForm isVisible={showResultRating} interviewId={interview.id} />
            </div>
            <FormStepper
              stepIndex={currentQuestion.qIndex}
              stepsLength={questionsWithAnswers.length}
              showAccept={showResultRating && currentApplicant.is_accepted === 0}
              showAccepted={showResultRating && currentApplicant.is_accepted === 1}
              showNext={!showResultRating}
              onBack={() => {
                if (showResultRating) {
                  setShowResult(false)
                } else {
                  setCurrentQuestion(prevState => {
                    const prevIndex = (prevState?.qIndex ?? 1) - 1
                    return {
                      qIndex: prevIndex,
                      qId: questionsWithAnswers[prevIndex],
                    }
                  })
                }
              }}
              onNext={() => {
                setCurrentQuestion(prevState => {
                  const nextIndex = (prevState?.qIndex ?? 0) + 1
                  return { qIndex: nextIndex, qId: questionsWithAnswers[nextIndex] }
                })
              }}
              onShowResult={() => {
                setShowResult(true)
              }}
              onAccept={() => {
                onAcceptPress()
                onClose()
              }}
              onNextApplicant={onNextApplicant}
              showNextApplicantButton={showResultRating && applicantsType === 'multiple'}
            />
          </div>
        ) : null
      }
    />
  )
}

export const Interview = observer(InterviewComponent)
