import { nonVoid } from 'assertio'
import { AxiosRequestConfig } from 'axios'
import { ErrorData } from 'ogram-react'
import { Api } from 'ogram-react'
import { ApiConfig } from 'ogram-react'

import { AttendanceManagerSnapshot } from '../../models/attendance-manager'
import { CompanyDetails } from '../../models/company-details'
import { BusinessLocation } from '../../models/location'
import { ProfileSnapshot } from '../../models/profile'
import { InvoiceStatusType } from '../../models/invoice'
import { DEFAULT_API_CONFIG } from './api-config'
import { mockData } from './mock-data/mock-data'

/**
 * Manages all requests to the API.
 */
export class ApiMock extends Api {
  isLogged = false
  id = 10

  constructor(/** Configurable options.*/ public config: ApiConfig = DEFAULT_API_CONFIG) {
    super()
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  response<T>(config: AxiosRequestConfig, status: number, data: unknown) {
    return this.sleep(300).then(() => {
      const res = {
        status: status,
        data: JSON.parse(JSON.stringify(data)) as unknown,
        headers: {},
      }
      return res
    })
  }

  error<T>(config: AxiosRequestConfig, status: number, data: ErrorData) {
    return this.response(config, status, data)
  }

  /**
   * Send an API call
   * @param config
   */
  protected async request(config: AxiosRequestConfig) {
    if (!config.url?.match(/client\/auth/) && config.url !== 'client/forgot_password' && !this.isLogged) {
      return this.error(config, 401, {
        code: 'token_invalid',
        message: 'Invalid token',
      })
    }
    switch (nonVoid(config.url)) {
      case 'client/auth/login':
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (config.data.email !== 'client1@gmail.com' || config.data.password !== 'password') {
          return this.error(config, 400, {
            code: 'invalid_login',
            message: 'Please enter correct email and password',
          })
        }
        this.isLogged = true
        return this.response(config, 200, mockData.logedIn)

      case 'client/auth/logout':
        this.isLogged = false
        return this.response(config, 200, mockData.ok)

      case 'client/profile/summary':
        return this.response(config, 200, mockData.profile)

      case 'client/profile/basic/update':
        mockData.profile = config.data as ProfileSnapshot
        return this.response(config, 200, mockData.profile)

      case 'client/attendance_managers':
        if (config.method === 'post') {
          const man = config.data as AttendanceManagerSnapshot
          const newData: AttendanceManagerSnapshot = {
            ...man,
            id: this.id++,
          }
          mockData.managers.push(newData)
          return this.response(config, 200, mockData.ok)
        }
        return this.response(config, 200, mockData.managers)
      case 'client/attendance_managers/1':
        if (config.method === 'put') {
          const man = config.data as AttendanceManagerSnapshot
          mockData.managers[0] = man
        }
        return this.response(config, 200, mockData.managers[0])
      case 'client/attendance_managers/2':
        return this.response(config, 200, mockData.managers[1])

      case 'client/locations':
        if (config.method === 'post') {
          mockData.locations.push(config.data)
          return this.response(config, 200, {})
        }
        return this.response(config, 200, mockData.locations)
      case 'client/locations/1':
        if (config.method === 'put') {
          mockData.locations[0] = config.data as BusinessLocation
        }
        return this.response(config, 200, mockData.locations[0])
      case 'client/locations/2':
        if (config.method === 'delete') {
          mockData.locations.pop()
          return this.response(config, 200, {})
        }
        if (config.method === 'put') {
          mockData.locations[1] = config.data as BusinessLocation
        }
        return this.response(config, 200, mockData.locations[1])
      case 'client/profile/company_details':
        return this.response(config, 200, mockData.companyDetails)
      case 'client/profile/company_details/update':
        mockData.companyDetails = config.data as CompanyDetails
        return this.response(config, 200, {})
      case 'client/rating/request_list':
        return this.response(config, 200, mockData.ratingRequests)
      case 'client/rating/submit':
        removeById(mockData.ratingRequests.list, (config.data as { id: string }).id)
        return this.response(config, 200, {})
      case 'client/rating/skip':
        removeById(mockData.ratingRequests.list, (config.data as { id: string }).id)
        return this.response(config, 200, {})
      case 'common/uniform':
        return this.response(config, 200, mockData.uniforms)
      case 'common/professions':
        return this.response(config, 200, mockData.professions)
      case 'client/order/basic/post':
        return this.response(config, 200, { order_id: nextId++ })
      case 'client/order/jobs/get':
        return this.response(config, 200, mockData.jobs)
      case 'client/order/jobs/post':
        add(mockData.jobs, config.data)
        return this.response(config, 200, {})
      case 'client/order/jobs/update':
        updateById(mockData.jobs, config.data)
        return this.response(config, 200, {})
      case 'client/order/jobs/delete':
        removeById(mockData.jobs, config.data.id)
        return this.response(config, 200, {})
      case 'client/auth/signup':
        return this.response(config, 200, {})
      case 'client/forgot_password':
        return this.response(config, 200, {})
      case 'client/auth/resend_email_verification':
        return this.response(config, 200, {})
      case 'client/auth/social_signup':
        this.isLogged = true
        return this.response(config, 200, {})
      case 'client/auth/verifyemail/foobar':
        this.isLogged = true
        return this.response(config, 200, {})
      case 'client/invoices':
        const responseData =
          config.data.statusId === InvoiceStatusType.Sent ? mockData.unpaidInvoices : mockData.paidInvoices
        return this.response(config, 200, responseData)
      case 'client/invoices/display':
        return this.response(config, 200, { ...mockData.invoiceWithDetails, id: config.data.id })
      case 'client/sp/search':
        return this.response(config, 200, mockData.sps)
      case 'client/sp/saved':
        return this.response(config, 200, mockData.sps)
    }
    throw new Error(`Mock response is not implemented: ${config.url}`)
  }
}

function removeById(list: { id: number | string }[], id: number | string) {
  // eslint-disable-next-line eqeqeq
  const pos = list.findIndex(it => it.id == id)
  if (pos > -1) {
    list.splice(pos, 1)
  }
}
function updateById<T extends { id: number | string }>(list: T[], item: T) {
  // eslint-disable-next-line eqeqeq
  const pos = list.findIndex(it => it.id == item.id)
  if (pos > -1) {
    list[pos] = item
  }
}

let nextId = 100
function add<T extends { id: number | string }>(list: T[], item: T) {
  item.id = nextId++
  list.push(item)
}
