import './toggle.scss'
import { useTranslation } from 'react-i18next'

type ToggleProps = {
  active: boolean
  onToggle: () => void
}

export const Toggle = ({ active, onToggle }: ToggleProps) => {
  const { t } = useTranslation()

  return (
    <div onClick={onToggle} className={`Toggle ${active ? 'active' : ''}`}>
      <div className="Toggle__background" />
      <div className="Toggle__switch">
        <span className="Toggle__switch__label">{active ? t('toggle.yes_short') : t('toggle.no_short')}</span>
      </div>
    </div>
  )
}
