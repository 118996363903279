import './dashboard.scss'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../../components/app-layout'
import { SectionTitle } from '../../../components/section-title/section-title'
import staffImg from '../../../../../components/assets/staff.svg'
import notifyImg from './assets/notify.svg'
import arrowRightImg from '../../../../../components/assets/arrow-right.svg'
import { AffiliateReferralSnapshot, AffiliateStatsSnapshot } from '../../../../../models/affiliate'
import { ReferralListItem } from '../../../components/referral-list-item/referral-list-item'
import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { useQuery, useStore } from '../../../../../utils/mst-hooks'

type InputProps = {
  setShowInvitePopup: (value: boolean) => void
  affiliateStats?: AffiliateStatsSnapshot
}

export const Dashboard = observer(({ setShowInvitePopup, affiliateStats }: InputProps) => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  const { getAffiliateReferrals, affiliateReferralsList } = useStore().data
  useQuery(getAffiliateReferrals)

  return (
    <div className="AffiliateDashboardContainer">
      {/*<div className="AffiliateDashboardContainer__card">*/}
      {/*    <div onClick={() => redirect(routes.affiliateEarnings, {})} className="AffiliateDashboardContainer__stats">*/}
      {/*        {affiliateStats?.marginSplit && <span className="AffiliateDashboardContainer__stats__margin">{t('common_affiliate.margin_split')}: <span className="AffiliateDashboardContainer__stats__margin__percents">{affiliateStats?.marginSplit}%</span></span>}*/}
      {/*        <span className="AffiliateDashboardContainer__stats__sum">{affiliateStats?.currency} {affiliateStats?.currentMonthIncome}</span>*/}
      {/*        <span className="AffiliateDashboardContainer__stats__period">{t('common_affiliate.this_month')}</span>*/}
      {/*    </div>*/}
      {/*</div>*/}
      {/*<Separator.Vertical height={30} />*/}
      <SectionTitle
        icon={staffImg}
        text="Staff List"
        statText={`${affiliateStats?.referralsCount} ${t('common.ogs')}`}
      />
      <Separator.Vertical height={20} />
      <div className="AffiliateDashboardContainer__card">
        <div className="AffiliateDashboardContainer__referralList">
          {affiliateReferralsList.map((affiliateReferral: AffiliateReferralSnapshot) => (
            <ReferralListItem key={affiliateReferral.id} affiliateReferral={affiliateReferral} />
          ))}
        </div>
        <div
          onClick={() => redirect(routes.affiliateReferralsList, {})}
          className="AffiliateDashboardContainer__showMore"
        >
          {t('common.show_more')}
        </div>
      </div>
      <Separator.Vertical height={20} />
      <div className="AffiliateDashboardContainer__card">
        <div onClick={() => setShowInvitePopup(true)} className="AffiliateDashboardContainer__inviteMore">
          <SectionTitle icon={notifyImg} text={t('common_affiliate.invite_more_staff')} />
          <img src={arrowRightImg} />
        </div>
      </div>
      <Separator.Vertical height={50} />
    </div>
  )
})
