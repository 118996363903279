import { types, flow } from 'mobx-state-tree'
import { AxiosRequestConfig } from 'axios'

import { AccountType } from './account'
import { DataStore } from './data-store/data-store'

export const GR_CLIENT_INDUSTRY_RETAIL = '1'
export const GR_CLIENT_INDUSTRY_HOTELS = '2'
export const GR_CLIENT_INDUSTRY_BRC = '3'

export const VERIFICATION_STATUS_MISSING = 0
export const VERIFICATION_STATUS_INREVIEW = 1
export const VERIFICATION_STATUS_APPROVED = 2

export const CompanyDetailsModel = types.model('CompanyDetails', {
  company_name: types.maybeNull(types.string),
  industry: types.maybeNull(types.string),
  region: types.maybeNull(types.string),
  trade_name: types.maybeNull(types.string),
  region_id: types.maybeNull(types.number),
  is_corporate: types.maybeNull(types.number),
  is_document_complete: types.maybeNull(types.number),
  is_custom_sla: types.maybeNull(types.number),
  is_pocs_created: types.maybeNull(types.number),
  is_lpo_required: types.maybeNull(types.number),
  is_affiliate: types.maybeNull(types.number),
  is_affiliate_tc_accepted: types.boolean,
  address: types.maybeNull(types.string),
  po_box: types.maybeNull(types.string),
  status_id: types.number,
  free_zone: types.maybeNull(types.string),
  license_number: types.maybeNull(types.string),
  vat: types.maybeNull(types.string),
  trade: types.maybeNull(types.string),
  is_sla_accepted: types.maybeNull(types.number),
  finance_first_name: types.maybeNull(types.string),
  finance_last_name: types.maybeNull(types.string),
  finance_extension: types.maybeNull(types.string),
  finance_phone: types.maybeNull(types.string),
  finance_email: types.maybeNull(types.string),
})

export const companyDetailsActions = (self: DataStore) => ({
  updateСompanyDetails: flow(function* (data: CompanyDetailsForm, optConfig?: AxiosRequestConfig) {
    const dataWithoutNullValues = Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null))
    yield self.request('post', 'client/corporates', dataWithoutNullValues, {
      headers: { 'Content-Type': 'multipart/form-data' },
      ...optConfig,
    })
    if (self.account) {
      self.account.is_corporate_details_complete = true
      self.account.account_type = AccountType.CORPORATE
    }
  }),

  getСompanyDetails: flow(function* () {
    self.companyDetails = (yield self.request('post', 'client/profile/company_details')) as CompanyDetails
  }),

  markAffiliateTCAsAccepted: function () {
    if (self.companyDetails) {
      self.companyDetails.is_affiliate_tc_accepted = true
    }
  },
})

export type CompanyDetails = typeof CompanyDetailsModel.Type
export type CompanyDetailsSnapshot = typeof CompanyDetailsModel.SnapshotType

export type CompanyDetailsForm = Omit<CompanyDetails, 'vat' | 'trade'> & {
  vat_url?: string | null
  vat?: File | null | undefined
  trade_url?: string | null
  trade?: File | null | undefined
}
