import { useContext } from 'react'

import { formContext } from '../form'
import './submit.scss'

export function Submit(props: { children: React.ReactNode }) {
  const form = useContext(formContext)
  return (
    <div>
      <button disabled={form.isLoading}>{props.children}</button>
    </div>
  )
}
