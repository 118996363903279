import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PaymentStatus } from '../../../models/payment'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { useBottomListListener } from '../../../utils/use-bottom-list-listener'
import { UIProps } from './types'
import { useOnPayHandler } from '../../../services/payment-service'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { UnseenUpdateType } from '../../../models/unseen-updates'

type InputProps = {
  paymentStatus: PaymentStatus
}

const getUpdateTypeByStatus = (status: PaymentStatus) => {
  switch (status) {
    case PaymentStatus.Pending:
      return UnseenUpdateType.NewPendingPayment
    case PaymentStatus.Processing:
      return UnseenUpdateType.NewProcessingPayment
    default:
      return UnseenUpdateType.NewPaidPayment
  }
}

export function createPaymentListContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ paymentStatus }: InputProps) => {
      const { paymentsList, getPaymentsList, getUnseenUpdates, isUpdateSeen, markUpdateAsSeen } = useStore().data

      useQuery(getUnseenUpdates)

      const [getPaymentsListMutation, getPaymentsListApiCall] = useMutation(
        ({ status, page }: { status: PaymentStatus; page?: number }) => getPaymentsList(status, page),
      )

      const [, markUpdateAsSeenApiCall] = useMutation(({ updateType }: { updateType: number }) =>
        markUpdateAsSeen(updateType),
      )

      const redirect = useRedirect()
      const { t } = useTranslation()
      const onPayClick = useOnPayHandler()

      useEffect(() => {
        getPaymentsListApiCall({
          status: paymentStatus,
          page: 1,
        })

        markUpdateAsSeenApiCall({
          updateType: getUpdateTypeByStatus(paymentStatus),
        })
      }, [paymentStatus])

      const loadMore = useBottomListListener(() => getPaymentsListApiCall({ status: paymentStatus }))

      const tabs = [
        {
          title: t('payment.pending'),
          onClick: () => redirect(routes.paymentList, { paymentStatus: PaymentStatus.Pending }),
          isActive: PaymentStatus.Pending === paymentStatus,
          showUpdatesIndicator: !isUpdateSeen(UnseenUpdateType.NewPendingPayment),
        },
        {
          title: t('payment.processing'),
          onClick: () => redirect(routes.paymentList, { paymentStatus: PaymentStatus.Processing }),
          isActive: PaymentStatus.Processing === paymentStatus,
          showUpdatesIndicator: !isUpdateSeen(UnseenUpdateType.NewProcessingPayment),
        },
        {
          title: t('payment.completed'),
          onClick: () => redirect(routes.paymentList, { paymentStatus: PaymentStatus.Completed }),
          isActive: PaymentStatus.Completed === paymentStatus,
          showUpdatesIndicator: !isUpdateSeen(UnseenUpdateType.NewPaidPayment),
        },
      ]

      return (
        <UIComponent
          loadMore={loadMore}
          tabs={tabs}
          paymentsList={paymentsList}
          isLoading={getPaymentsListMutation.isLoading}
          onPayClick={onPayClick}
          onItemClick={(paymentId: string) => redirect(routes.paymentDetails, { paymentId })}
          paymentStatus={paymentStatus}
        />
      )
    }),
  )
}
