import { useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import location from '../../../../components/assets/location-icon-grey.svg'
import calender from '../../../../components/assets/calender-icon-grey.svg'
import info from '../../assets/info-icon-orange.svg'
import AutoConfirmationModal from '../auto-confirmation-modal/auto-confirmation-modal'
import { Job } from '../attendance-card/attendance-card'
import { Separator } from '../../../../components/app-layout/separator/separator'
import './shift-card.scss'

interface ShiftCardProps {
  shiftData: Job
  showLimitedTimeModal?: boolean
}

const ShiftCard = ({ shiftData, showLimitedTimeModal }: ShiftCardProps) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()

  function openModal() {
    if (showLimitedTimeModal) {
      setIsOpen(true)
    }
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <div className="ShiftCard">
        {shiftData.time_to_auto_confirm && (
          <h6 onClick={openModal} className="ShiftCard__leftTime">
            {Math.floor(shiftData.time_to_auto_confirm / 3600)} :
            {t('shift_attendance_common.hours_left', {
              count: Math.floor((shiftData.time_to_auto_confirm % 3600) / 60),
            })}
            <img src={info} />
          </h6>
        )}
        <h5 className="ShiftCard__name">
          <span>{shiftData.work_category_name}</span>
          <Separator.Horizontal width={5} />
          <span className="ShiftCard__name__amount">({shiftData.pending_shifts})</span>
        </h5>
        <div className="ShiftCard__details">
          <img src={location} />
          {shiftData.client_location_name}
        </div>
        <div className="ShiftCard__details">
          <img src={calender} />
          {moment(shiftData.start_time).format('HH:mm')} - {moment(shiftData.end_time).format('HH:mm')},{' '}
          {moment(shiftData.start_time).format('DD MMM')}
        </div>
      </div>
      <AutoConfirmationModal time={shiftData.time_to_auto_confirm} closeModal={closeModal} modalIsOpen={modalIsOpen} />
    </>
  )
}

export default ShiftCard
