var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { observable, makeObservable, action } from 'mobx';
import * as React from 'react';
import { ApiError } from '../api/api-types';
export class RequestResult {
    constructor() {
        this.status = 'pending';
        this.isLoading = false;
        this.promise = undefined;
        this.error = undefined;
        makeObservable(this);
    }
}
__decorate([
    observable
], RequestResult.prototype, "isLoading", void 0);
__decorate([
    observable
], RequestResult.prototype, "error", void 0);
export function createMSTHooks(context) {
    function useStore() {
        return React.useContext(context);
    }
    function useQuery(cb, config) {
        if (config) {
            if ((config === null || config === void 0 ? void 0 : config.hasFallback) === undefined) {
                config.hasFallback = true;
            }
        }
        else {
            // eslint-disable-next-line no-param-reassign
            config = {
                hasFallback: true,
            };
        }
        const [result, fn] = create(cb, config);
        if (result.status === 'pending') {
            fn();
        }
        return result;
    }
    function useDelayedQuery(cb, config) {
        const [query, fn] = create(cb, config);
        const enableQuery = () => {
            fn();
        };
        return { query, enableQuery };
    }
    function useSuspenseQuery(cb, config) {
        const [result, fn] = create(cb, config);
        if (result.status === 'pending') {
            throw fn();
        }
        return result;
    }
    function useMutation(cb, config) {
        return create(cb, config);
    }
    function create(cb, config) {
        var _a;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const result = React.useRef(new RequestResult()).current;
        const fn = action((params) => {
            setDefaultState();
            result.reload = () => fn(params);
            const promise = cb(params);
            result.promise = promise;
            return promise
                .then(action(data => {
                var _a;
                result.status = 'data';
                (_a = config === null || config === void 0 ? void 0 : config.onSuccess) === null || _a === void 0 ? void 0 : _a.call(config, data);
                result.isLoading = false;
            }))
                .catch(action((err) => {
                var _a, _b;
                result.status = 'error';
                result.error = err;
                if ((_a = config === null || config === void 0 ? void 0 : config.filterError) === null || _a === void 0 ? void 0 : _a.find(error => error === err.type)) {
                    (_b = config === null || config === void 0 ? void 0 : config.onError) === null || _b === void 0 ? void 0 : _b.call(config, err);
                }
                result.isLoading = false;
            }));
            function setDefaultState() {
                result.status = 'loading';
                result.isLoading = true;
                result.error = undefined;
            }
        });
        if (result.error !== undefined) {
            if (result.error instanceof ApiError && (config === null || config === void 0 ? void 0 : config.hasFallback)) {
                result.error.hasFallback = true;
            }
            if (!(result.error instanceof ApiError) || !((_a = config === null || config === void 0 ? void 0 : config.filterError) === null || _a === void 0 ? void 0 : _a.includes(result.error.type))) {
                throw result.error;
            }
        }
        return [result, fn];
    }
    return {
        MSTProvider: context.Provider,
        useQuery: useQuery,
        useDelayedQuery: useDelayedQuery,
        useStore: useStore,
        useMutation: useMutation,
        useSuspenseQuery: useSuspenseQuery,
        RequestResult: RequestResult,
    };
}
