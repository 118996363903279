import { types, cast, addMiddleware, clone } from 'mobx-state-tree'

import { getCurrentMarketConfig } from '../../../../services/market-service'
import { orderArrayBasedOnArray } from '../../../../utils/array-utils'
import { DraftJobInstance } from '../draft-job'

/**
 * All the possible values job form may have.
 * Some of them are optional depending on user actions and market.
 */
export enum DraftJobFields {
  requirements = 'requirements',
  category = 'category',
  quantity = 'quantity',
  location = 'location',
  transport = 'transport',
  dateBoundaries = 'dateBoundaries',
  scheduleType = 'scheduleType',
  sameOrDynamicTimingPrompt = 'sameOrDynamicTimingPrompt',
  sameTimeSchedule = 'sameTimeSchedule',
  daysSelect = 'daysSelect', // applicable to both same/distinct time types
  description = 'description',
  contact = 'contact',
  experience = 'experience',
  dresscode = 'dresscode',
  invitedSPs = 'invitedSPs',
  preferences = 'preferences',
  languages = 'languages',
  gender = 'gender',
  documents = 'documents',
  videoInterviews = 'videoInterviews',
  accreditation = 'accreditation',
  accommodation = 'accommodation',
  boarding = 'boarding',
}
/**
 * These are the screens available from the beginning in the order they should appear.
 * They may be extended (i.g. with preference screens or day/time selections)
 * depending on user choices.
 * Should be used for UAE market only.
 */
export const initialOrderedFieldsDefault = [
  DraftJobFields.requirements,
  DraftJobFields.quantity,
  DraftJobFields.transport,
  DraftJobFields.dateBoundaries,
  DraftJobFields.scheduleType,
  DraftJobFields.sameOrDynamicTimingPrompt,
  DraftJobFields.description,
  DraftJobFields.experience,
  DraftJobFields.dresscode,
  DraftJobFields.contact,
  DraftJobFields.invitedSPs,
  DraftJobFields.preferences,
]
/**
 * These are the screens available as additional preference screens, not visible by default.
 * Should be used for UAE market only.
 */
export const orderedPreferenceFieldsDefault = [
  DraftJobFields.languages,
  DraftJobFields.gender,
  DraftJobFields.documents,
  DraftJobFields.videoInterviews,
  DraftJobFields.accreditation,
  DraftJobFields.boarding,
]

export const orderedPreferenceFields = orderedPreferenceFieldsDefault.filter(field => {
  return getCurrentMarketConfig().order.includeGenderField || field !== DraftJobFields.gender
})
export const initialOrderedFields = [
  ...initialOrderedFieldsDefault.filter(field => {
    return getCurrentMarketConfig().order.includePublicTransportationField || field !== DraftJobFields.transport
  }),
]

export const FieldsModel = types
  .model('Fields', {
    list: types.optional(
      types.array(types.enumeration<DraftJobFields>('DraftJobFields', Object.values(DraftJobFields))),
      initialOrderedFields,
    ),
  })
  .views(self => ({
    hasField(field: DraftJobFields) {
      return self.list.includes(field)
    },

    get preferenceFieldIndex() {
      return self.list.indexOf(DraftJobFields.preferences)
    },
  }))
  .views(self => ({
    get preferenceFields() {
      return self.list.slice(self.preferenceFieldIndex + 1)
    },
    get defaultFields() {
      return self.list.slice(0, self.preferenceFieldIndex + 1)
    },
  }))
  .actions(self => ({
    reset() {
      self.list.clear()
      self.list = clone(FieldsModel.create().list)
    },
    insertTimeFields(fields: DraftJobFields[]) {
      const insertToIndex = self.list.findIndex(field => field === DraftJobFields.sameOrDynamicTimingPrompt) + 1
      self.list.splice(insertToIndex, 0, ...fields)
    },
    replaceFields(firstFieldToRemove: DraftJobFields, fieldsCount: number, pasteFields: DraftJobFields[]) {
      const currentTimeFieldIndex = self.list.findIndex(field => field === firstFieldToRemove)
      const numberOfFieldsToRemove = fieldsCount
      self.list.splice(currentTimeFieldIndex, numberOfFieldsToRemove, ...pasteFields)
    },
    removeFields(fields: DraftJobFields[]) {
      // @ts-ignore
      self.list = cast(self.list.filter(field => !fields.includes(field)))
    },
    orderPreferenceFields() {
      // sort them to be in correct order
      orderArrayBasedOnArray(self.preferenceFields, orderedPreferenceFields)
      // and paste sorted preference fields in place of the old ones
      self.list.splice(self.preferenceFieldIndex + 1, self.preferenceFields.length, ...self.preferenceFields)
    },
    orderDefaultFields() {
      orderArrayBasedOnArray(self.defaultFields, initialOrderedFields)
      self.list.splice(0, self.defaultFields.length, ...self.defaultFields)
    },
    orderFields() {
      this.orderDefaultFields()
      this.orderPreferenceFields()
    },
  }))
  .actions(self => ({
    addAccreditationField() {
      if (!self.hasField(DraftJobFields.accreditation)) {
        self.preferenceFields.push(DraftJobFields.accreditation)
        self.orderPreferenceFields()
      }
    },
    removeAccreditationField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.accreditation))
    },
    addLanguagesField() {
      if (!self.hasField(DraftJobFields.languages)) {
        self.preferenceFields.push(DraftJobFields.languages)
        self.orderPreferenceFields()
      }
    },
    removeLanguagesField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.languages))
    },
    addGenderField() {
      if (!self.hasField(DraftJobFields.gender)) {
        self.preferenceFields.push(DraftJobFields.gender)
        self.orderPreferenceFields()
      }
    },
    removeGenderField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.gender))
    },
    addPublicTransportAccessibilityField() {
      if (!self.hasField(DraftJobFields.transport)) {
        self.defaultFields.push(DraftJobFields.transport)
        self.orderDefaultFields()
      }
    },
    removePublicTransportAccessibilityField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.transport))
    },
    addInvitedSPsField() {
      if (!self.hasField(DraftJobFields.invitedSPs)) {
        self.defaultFields.push(DraftJobFields.invitedSPs)
        self.orderDefaultFields()
      }
    },
    removeInvitedSPsField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.invitedSPs))
    },
    addRequirementsField() {
      if (!self.hasField(DraftJobFields.requirements)) {
        self.defaultFields.push(DraftJobFields.requirements)
        self.orderDefaultFields()
      }
    },
    removeRequirementsField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.requirements))
    },
    addDocumentsField() {
      if (!self.hasField(DraftJobFields.documents)) {
        self.preferenceFields.push(DraftJobFields.documents)
        self.orderPreferenceFields()
      }
    },
    removeDocumentsField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.documents))
    },
    addInterviewField() {
      if (!self.hasField(DraftJobFields.videoInterviews)) {
        self.preferenceFields.push(DraftJobFields.videoInterviews)
        self.orderPreferenceFields()
      }
    },
    removeInterviewField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.videoInterviews))
    },
    addBoardingField() {
      if (!self.hasField(DraftJobFields.boarding)) {
        self.preferenceFields.push(DraftJobFields.boarding)
        self.orderPreferenceFields()
      }
    },
    removeBoardingField() {
      self.list = cast(self.list.filter(item => item !== DraftJobFields.boarding))
    },
  }))

export function createFieldsMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    switch (call.name) {
      case 'useAccreditation':
        if (call.args[0]) {
          targetInstance.fields.addAccreditationField()
        } else {
          targetInstance.fields.removeAccreditationField()
        }
        break
      case 'useLanguages':
        if (call.args[0]) {
          targetInstance.fields.addLanguagesField()
        } else {
          targetInstance.fields.removeLanguagesField()
        }
        break
      case 'useGender':
        if (call.args[0]) {
          targetInstance.fields.addGenderField()
        } else {
          targetInstance.fields.removeGenderField()
        }
        break
      case 'useDocuments':
        if (call.args[0]) {
          targetInstance.fields.addDocumentsField()
        } else {
          targetInstance.fields.removeDocumentsField()
        }
        break
      case 'useInterview':
        if (call.args[0]) {
          targetInstance.fields.addInterviewField()
        } else {
          targetInstance.fields.removeInterviewField()
        }
        break
      case 'useBoarding':
        if (call.args[0]) {
          targetInstance.fields.addBoardingField()
        } else {
          targetInstance.fields.removeBoardingField()
        }
        break
      default:
        break
    }
    return next(call)
  })
}
