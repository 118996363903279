import { observer } from 'mobx-react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import securityImg from '../../checkout/stripe/pay-with-card/components/pay-with-card-form/ui/assets/security.svg'
import { StickyPanel } from '../../../../components/sticky-panel/sticky-panel'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'

export const SaveCardForm = observer(() => {
  const [isPaymentFormLoaded, setIsPaymentFormLoaded] = useState<boolean>(false)
  const [isSavingProcessing, setIsSavingProcessing] = useState<boolean>(false)

  const redirect = useRedirect()
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      return
    }

    setIsSavingProcessing(true)

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (result.error) {
      toast.error(result.error.message)
    } else {
      redirect(routes.savedPaymentMethods, {})
      toast.success(t('saved_payment_methods.method_has_been_added'))
    }

    setIsSavingProcessing(false)
  }

  return (
    <div className="SaveCardForm">
      <div className="SaveCardForm__form">
        <PaymentElement
          options={{
            wallets: {
              applePay: 'never',
              googlePay: 'never',
            },
          }}
          onReady={(event) => {
            setIsPaymentFormLoaded(true)
          }}
        />
        {isPaymentFormLoaded && (
          <div className="StripeForm__securityMessage">
            <img src={securityImg} />
            <span className="StripeForm__securityMessage__text">{t('payment.all_transactions_are_secured')}</span>
          </div>
        )}
      </div>
      {isPaymentFormLoaded && (
        <StickyPanel style={{ justifyContent: 'center' }}>
          <button
            className="PayButton__button"
            onClick={() => {
              handleSubmit()
            }}
            disabled={isSavingProcessing}
          >
            {!isSavingProcessing ? t('saved_payment_methods.save_card') : t('saved_payment_methods.saving_progress')}
          </button>
        </StickyPanel>
      )}
      {(!isPaymentFormLoaded || !elements || !stripe) && <LoadingSpinner />}
    </div>
  )
})
