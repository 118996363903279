import './sp-column.scss'
import { useState } from 'react'

import { Popup } from '../../../../../../../components/popup/popup'
import ApplicantDetails from '../../../../../../applicants-review/applicant-details/applicant-details'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'

export type SpColumnProps = {
  name: string
  spId: number
  jobId: number
  imageUrl: string
}

export function SpColumn({ imageUrl, name, spId, jobId }: SpColumnProps) {
  const [isProfileShown, setIsProfileShown] = useState(false)

  return (
    <div className="SpColumn">
      <img
        onClick={() => {
          setIsProfileShown(true)

          trackingService.track(TrackedEvent.TimesheetSPProfileClicked)
        }}
        src={imageUrl}
        title={name}
        className="SpColumn__img"
      />
      <div
        onClick={() => {
          setIsProfileShown(true)

          trackingService.track(TrackedEvent.TimesheetSPProfileClicked)
        }}
        className="SpColumn__textContainer"
      >
        <span className="SpColumn__name">{name}</span>
        <span className="SpColumn__id">ID#{spId}</span>
      </div>
      <Popup
        isActive={isProfileShown}
        content={
          <ApplicantDetails
            jobId={String(jobId)}
            applicantId={String(spId)}
            applicantName={name}
            showHeader={false}
            showControls={false}
          />
        }
        close={() => setIsProfileShown(false)}
      />
    </div>
  )
}
