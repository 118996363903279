export const Heart = ({ isActive, onClick }: { isActive: boolean; onClick: () => void }) => {
  if (isActive) {
    return (
      <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.82828 4.62275C7.98819 2.79242 5.00646 2.79242 3.16637 4.62275C2.22624 5.55789 1.75542 6.93192 1.71757 8.25105C1.67976 9.56899 2.0715 10.9674 3.0316 11.9224C4.28536 13.1695 5.91449 14.588 7.22045 15.6874C7.8754 16.2388 8.45286 16.7132 8.86672 17.0498C9.07371 17.2181 9.2399 17.3521 9.35455 17.4442L9.48657 17.5499L9.52109 17.5775L9.53004 17.5846L9.53239 17.5865L9.53302 17.587L9.5332 17.5871C9.53325 17.5872 9.53329 17.5872 9.99987 17L9.53329 17.5872C9.81018 17.8072 10.2031 17.8039 10.4763 17.5792L9.99987 17C10.4763 17.5792 10.4763 17.5792 10.4764 17.5792L10.4765 17.579L10.4771 17.5785L10.4794 17.5767L10.488 17.5696L10.5213 17.5421L10.6488 17.4365C10.7596 17.3446 10.9203 17.2107 11.1208 17.0425C11.5217 16.7061 12.0818 16.2321 12.7193 15.6811C13.9911 14.5819 15.5832 13.1658 16.8333 11.9224C17.7833 10.9774 18.215 9.59141 18.2151 8.27257C18.2151 6.95372 17.7834 5.5677 16.8334 4.62275C14.9933 2.79242 12.0116 2.79242 10.1715 4.62275L9.99987 4.79343L9.82828 4.62275Z"
          fill="#F88C99"
        />
      </svg>
    )
  }

  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.16637 4.62275C5.00646 2.79242 7.98819 2.79242 9.82828 4.62275L9.99987 4.79343L10.1715 4.62275C12.0116 2.79242 14.9933 2.79242 16.8334 4.62275C17.7834 5.5677 18.2151 6.95372 18.2151 8.27257C18.215 9.59141 17.7833 10.9774 16.8333 11.9224C15.5832 13.1658 13.9911 14.5819 12.7193 15.6811C12.0818 16.2321 11.5217 16.7061 11.1208 17.0425C10.9203 17.2107 10.7596 17.3446 10.6488 17.4365L10.5213 17.5421L10.488 17.5696L10.4794 17.5767L10.4771 17.5785L10.4765 17.579L10.4764 17.5792C10.4763 17.5792 10.4763 17.5792 9.99987 17C9.53329 17.5872 9.53325 17.5872 9.5332 17.5871L9.53302 17.587L9.53239 17.5865L9.53004 17.5846L9.52109 17.5775L9.48657 17.5499L9.35455 17.4442C9.2399 17.3521 9.07371 17.2181 8.86672 17.0498C8.45286 16.7132 7.8754 16.2388 7.22045 15.6874C5.91449 14.588 4.28536 13.1695 3.0316 11.9224C2.0715 10.9674 1.67976 9.56899 1.71757 8.25105C1.75542 6.93192 2.22624 5.55789 3.16637 4.62275ZM9.99987 17L9.53329 17.5872C9.81018 17.8072 10.2031 17.8039 10.4763 17.5792L9.99987 17ZM9.99206 16.0313C10.0433 15.9884 10.0983 15.9424 10.1566 15.8934C10.5532 15.5607 11.1076 15.0915 11.7384 14.5463C13.0033 13.4531 14.5635 12.0645 15.7755 10.8589C16.373 10.2645 16.7151 9.30211 16.7151 8.27255C16.7151 7.24298 16.3731 6.28058 15.7755 5.68622C14.5206 4.43793 12.4843 4.43793 11.2293 5.68622L10.5288 6.38302C10.2362 6.67404 9.76352 6.67404 9.47095 6.38302L8.77044 5.68622C7.51549 4.43793 5.47916 4.43793 4.22421 5.68622C3.61682 6.2904 3.24649 7.26478 3.21696 8.29407C3.18739 9.32454 3.50202 10.2746 4.08944 10.8589C5.29777 12.0608 6.88832 13.447 8.18651 14.54C8.83363 15.0848 9.40438 15.5536 9.81319 15.8861C9.87686 15.9379 9.93659 15.9863 9.99206 16.0313Z"
        fill="#BABED6"
      />
    </svg>
  )
}
