import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { DateInput } from '../../../date-input/date-input'
import { orderExtensionStore } from '../../../../models-ui/order/store'
import { Separator } from '../../../app-layout'

export const SameDateSelector = observer(() => {
  const { t } = useTranslation()

  return (
    <>
      <span className="Popup__title">{t('order_extension.for_how_long_extend')}</span>
      <span className="Popup__subtitle">{t('order_extension.you_can_extend_order')}</span>
      <div className="OrderExtension__popup__innerContainer">
        <DateInput
          label={t('order_draft.start_date')}
          value={orderExtensionStore.extensionStartDateForAllJobs ?? ''}
          handleChange={orderExtensionStore.setExtensionStartDateForAllJobs}
          min={orderExtensionStore.minStartDateForAllJobs ?? undefined}
          className="Dates__dateInput"
        />
      </div>
      <Separator.Vertical height={10} />
      <div className="OrderExtension__popup__innerContainer">
        <DateInput
          label={t('order_draft.end_date')}
          value={orderExtensionStore.extensionDateForAllJobs ?? ''}
          handleChange={orderExtensionStore.setExtensionDateForAllJobs}
          min={orderExtensionStore.extensionStartDateForAllJobs ?? undefined}
          className="Dates__dateInput"
        />
      </div>
    </>
  )
})
