import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import bestPriceGuaranteeIcon from '../../assets/best-price-guarantee.svg'
import { useMutation, useStore } from '../../../../utils/mst-hooks'
import './best-price-guarantee.scss'

export const BestPriceGuarantee = observer(({ orderId }: { orderId: string }) => {
  const { requestCallback } = useStore().data
  const { t } = useTranslation()

  const [, requestCallbackRequest] = useMutation(() => requestCallback({ orderId }), {
    onSuccess: () => {
      toast.success(t('order_draft.callback_request_sent'))
    },
  })

  return (
    <div className="BestPriceGuarantee">
      <div className="BestPriceGuarantee__top">
        <img src={bestPriceGuaranteeIcon} />
        <div className="BestPriceGuarantee__top__description">
          <span className="BestPriceGuarantee__top__description__title">{t('order_draft.pricing.best_price_1')}</span>
          <span className="BestPriceGuarantee__top__description__text">{t('order_draft.pricing.best_price_2')}</span>
        </div>
      </div>
      <div className="BestPriceGuarantee__bottom">
        <span className="BestPriceGuarantee__bottom__description">{t('order_draft.pricing.best_price_3')}</span>
        <span onClick={requestCallbackRequest} className="BestPriceGuarantee__bottom__action">
          {t('order_draft.pricing.best_price_4')}
        </span>
      </div>
    </div>
  )
})
