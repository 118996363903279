import './header-jobs-counter.scss'
import userIcon from '../assets/user-icon.svg'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'

const MINIMUM_JOBS_COUNT_TO_SHOW_COUNTER = 2

export const HeaderJobsCounter = ({
  jobsCount,
  orderId,
}: {
  jobsCount: number
  orderId: number | null | undefined
}) => {
  const redirect = useRedirect()

  if (jobsCount < MINIMUM_JOBS_COUNT_TO_SHOW_COUNTER) {
    return null
  }

  const disabled = !orderId

  const clickHandler = () => {
    if (disabled) {
      return
    }

    redirect(routes.orderDraft, { orderId: String(orderId) })
  }

  return (
    <div onClick={clickHandler} className={`HeaderJobsCounter__container${disabled ? ' disabled' : ''}`}>
      <img src={userIcon} />
      <div className="HeaderJobsCounter__circle">{jobsCount}</div>
    </div>
  )
}
