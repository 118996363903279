import './top-quality.scss'

interface TopQualityProps {
  name: string
  containerStyleClass?: string
}

const TopQuality = ({ name, containerStyleClass }: TopQualityProps) => {
  return (
    <div className={`TopQuality ${containerStyleClass}`}>
      <p className="TopQuality__name">{name}</p>
    </div>
  )
}

export default TopQuality
