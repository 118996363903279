import { Config, isLocalDevelopmentEnvironment } from './env'

export const config: Config = {
  API_URL: isLocalDevelopmentEnvironment() ? 'http://localhost:8000/api/' : 'https://dev.ogram.co/api/',
  API_GR_URL: isLocalDevelopmentEnvironment() ? 'http://localhost:8000/api/' : 'https://dev-gr.ogram.co/api/',
  API_SA_URL: isLocalDevelopmentEnvironment() ? 'http://localhost:8000/api/' : 'https://dev-sa.ogram.co/api/',
  USE_REAL_API: true,
  GOOGLE_API_KEY: 'AIzaSyBTTfo5ohIS-E7DxdT5i9S-NjByrLV5XBY',
  GOOGLE_AUTH_CLIENT_ID: '171301787568-i2djddid25but5oigoid80si3s743bdf.apps.googleusercontent.com',
  IOS_CLIENT_ID: '1004213687272-gcqsfhnek4iiq3j6fqdu603igb6m3dl8.apps.googleusercontent.com',
  FB_AUTH_CLIENT_ID: '640269856536676',
  SHOW_MESSAGES_TAB: true,
  STRIPE_PUBLISHABLE_KEY_UAE:
    'pk_test_51KyBhxHzf8fVSAwXC6jdo69lJsORa7f52woJ8iFFGsYu4kcKKCbG0JMxYruVt1ZbOcBxNnhLWEZANvjpVhNgzYk900te4dZsrb',
  STRIPE_PUBLISHABLE_KEY_GR:
    'pk_test_51M2r2QJz9c5KxSLgXUHfpH3dXDLrx3vnEs5TS5VPZ1KLoXyYH9zzjxH5kGmnn4zbjKUDkX8IJWMd2XrQh29JPkk200L6lGjJUt',
  LEAN_APP_TOKEN: '220d863e-a1ae-4efe-a1c1-4b0a761ff910',
  LEAN_PAYMENT_DESTINATION_ID: '27d79ff2-702b-481b-944d-2779b2869858',
  FIREBASE_WEB_CONFIG: {
    apiKey: 'AIzaSyB8WI2K1_g06BTxD711J_LE6omGEGrFtDY',
    vapidKey: 'BIqhz1dCHsyj-iMNuhZs-6txzhyU4sLrv1uTPMn4kY2JwXUxuly5o7-eVMMmuUk_wREx72qZkI8anMhtTKrQ1z0',
    authDomain: 'ogram-client.firebaseapp.com',
    projectId: 'ogram-client',
    storageBucket: 'ogram-client.appspot.com',
    messagingSenderId: '188726379320',
    appId: '1:188726379320:web:32bc95d9cd08ef528537cd',
    measurementId: 'G-Y7VPTMJDYR',
  },
}
