import { types, flow, Instance, SnapshotIn } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const UniformModel = types.model('Uniform', {
  id: types.identifierNumber,
  name: types.string,
  description: types.string,
  image_url: types.string,
})

export const uniformActions = (self: DataStore) => ({
  getUniforms: flow(function* () {
    const uniforms = (yield self.request('get', `common/uniforms`)) as Uniform[]
    uniforms.forEach((uniform) => {
      self.uniforms.set(String(uniform.id), uniform)
    })
  }),
})

export const uniformViews = (self: DataStore) => ({
  getUniformIdByTitle(title: string) {
    return Array.from(self.uniforms.values()).find((uniform) => uniform.name === title)?.id
  },

  getUniformName(id: number) {
    return self.uniforms.get(String(id))?.name
  },

  getUniformById(id: number) {
    return self.uniforms.get(String(id))
  },

  get uniformList() {
    return Array.from(self.uniforms.values())
  },
})

export type Uniform = SnapshotIn<typeof UniformModel>
export type UniformSnapshot = SnapshotIn<typeof UniformModel>
export type UniformInstance = Instance<typeof UniformModel>
