import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

const OrderModel = types.model({
  id: types.number,
  name: types.string,
  eventTypeName: types.string,
  locationId: types.number,
})

const LocationModel = types.model({
  id: types.number,
  name: types.string,
  imageUrl: types.maybeNull(types.string),
})

const WorkCategory = types.model({
  id: types.number,
  name: types.string,
  imageUrl: types.maybeNull(types.string),
})

export const ContactFiltersModel = types.model('ContactFilters', {
  orders: types.array(OrderModel),
  locations: types.array(LocationModel),
  workCategories: types.array(WorkCategory),
})

export const contactFiltersActions = (self: DataStore) => ({
  getContactFilters: flow(function* () {
    self.contactFilters = yield self.request('get', 'common/chat/contacts/filters')
  }),
})

export type ContactFilters = typeof ContactFiltersModel.Type
export type ContactFiltersSnapshot = typeof ContactFiltersModel.SnapshotType
