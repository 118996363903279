import { observer } from 'mobx-react'

import { Select } from '../select/select'
import { useQuery, useStore } from '../../../utils/mst-hooks'

type InputProps = {
  label: string
  value: number | undefined
  onChange: (val: number) => void
  className: string
}

export const POCSelect = observer(({ label, value, onChange, className }: InputProps) => {
  const { pointsOfContact, getPointsOfContact } = useStore().data

  const { isLoading: pointsOfContactLoading } = useQuery(() => getPointsOfContact({}))

  const pointsOfContactOptions =
    Number(pointsOfContact?.length) > 0
      ? (pointsOfContact ?? []).map(pointOfContact => ({
          key: String(pointOfContact.id),
          label: `${pointOfContact.first_name} ${pointOfContact.last_name}`,
          value: pointOfContact.id,
        }))
      : []

  if (pointsOfContactLoading) {
    return null
  }

  return (
    <Select label={label} value={value} onChange={onChange} options={pointsOfContactOptions} className={className} />
  )
})
