import selectDesignationImg from './select-designation.png'
import jobDetailsImg from './job-details.png'
import jobDurationImg from './job-duration.png'
import schedulingOptionsImg from './scheduling-options.png'
import jobRequirementsImg from './job-requirements.png'
import shiftsBreakdownImg from './shifts-breakdown.png'
import duplicateJobImg from './duplicate-job.png'
import sendForReviewImg from './send-for-review.png'

export const images = {
  selectDesignationImg,
  jobDetailsImg,
  jobDurationImg,
  schedulingOptionsImg,
  jobRequirementsImg,
  shiftsBreakdownImg,
  duplicateJobImg,
  sendForReviewImg,
}
