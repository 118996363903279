import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useStore, useMutation } from '../../../../utils/mst-hooks'
import favouriteIcon from './assets/favourite.svg'
import notFavouriteIcon from './assets/not-favourite.svg'
import './toggle-favourite.scss'

type ToggleFavouriteProps = {
  className?: string
}

function ToggleFavouriteComponent({ className = '' }: ToggleFavouriteProps) {
  const { currentApplicant, toggleCurrentApplicantAsFavouriteApiCall } = useStore().data

  const { t } = useTranslation()

  const [toggleMutation, triggerToggleMutation] = useMutation(toggleCurrentApplicantAsFavouriteApiCall)

  const markApplicantAsFavourite = () => {
    if (!currentApplicant?.is_favorite) {
      toast.success(t('applicant_details.added_as_favourite', { spName: currentApplicant?.name }))
    }
    triggerToggleMutation(undefined)
  }

  return (
    <div
      className={`ToggleFavourite ${className}`}
      onClick={() => {
        if (toggleMutation.isLoading) return
        markApplicantAsFavourite()
      }}
    >
      <img src={currentApplicant?.is_favorite ? favouriteIcon : notFavouriteIcon} />
    </div>
  )
}

export const ToggleFavourite = observer(ToggleFavouriteComponent)
