import { observer } from 'mobx-react'

import { useStore } from '../../utils/mst-hooks'
import { FallbackUI } from './fallback-ui'

type ProtectedRouteRoutes = {
  allowedRoles: number[]
  children: React.ReactNode
  showDefaultFallbackUI?: boolean
  FallbackComponent?: React.ComponentType
}

export const ProtectedRoute = observer(
  ({ allowedRoles, children, showDefaultFallbackUI = true, FallbackComponent }: ProtectedRouteRoutes) => {
    const { account } = useStore().data

    const userRoles = account?.roles ?? []
    const rolesIntersectionArray = userRoles.filter((userRole) => allowedRoles.includes(userRole))
    const routeIsAllowed = rolesIntersectionArray.length > 0

    if (userRoles.length === 0 || routeIsAllowed) {
      return <>{children}</>
    }

    if (FallbackComponent) {
      return <FallbackComponent />
    }

    if (showDefaultFallbackUI) {
      return <FallbackUI />
    }

    return null
  },
)
