import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/locations'
import '../onboarding.scss'

export const locationsOnboarding: OnboardingContent = {
  title: t('locations_onboarding.title'),
  paths: [routes.locations.path],
  pages: [
    {
      title: t('locations_onboarding.location_list_title'),
      content: [
        { image: { src: images.locationsListImg, className: 'Onboarding__bigImg' } },
        { description: t('locations_onboarding.location_list') },
      ],
    },
    {
      title: t('locations_onboarding.location_details_title'),
      content: [
        { image: { src: images.locationsDetailsImg, className: 'Onboarding__bigImg' } },
        { description: t('locations_onboarding.location_details') },
      ],
    },
  ],
}
