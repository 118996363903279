import { getMessaging, getToken, isSupported } from 'firebase/messaging'

import { config } from '../config/env'

const getFirebaseToken = async () => {
  if (await isSupported()) {
    try {
      const token = await getToken(getMessaging(), { vapidKey: config.FIREBASE_WEB_CONFIG.vapidKey })
      return token
    } catch {
      return '0'
    }
  } else {
    return '0'
  }
}

export { getFirebaseToken }
