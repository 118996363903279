import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export type RatingRequestDetails = {
  id: number
  title: string
  client_address: string
  start_date: string
  end_date: string
  sp_max_quantity: number
  rating: number | null
  shifts: { start_time: string; end_time: string }[]
  sps: { id: number; full_name: string; image_url: string }[]
}

export const RatingRequestModel = types.model('RatingRequest', {
  id: types.identifierNumber,
  order_id: types.number,
  title: types.string,
  client_address: types.string,
  start_date: types.string,
  end_date: types.string,
  sp_max_quantity: types.number,
})

export const ratingActions = (self: DataStore) => ({
  getRatingRequests: flow(function* (includeActiveJobs: boolean) {
    const ratingRequestArray = (yield self.request(
      'get',
      `client/rating/pending?includeActiveJobs=${Number(includeActiveJobs)}`,
    )) as RatingRequestSnapshot[]
    if (self.ratingRequests.size > 0) {
      self.ratingRequests.clear()
    }
    ratingRequestArray.forEach(ratingRequest => {
      self.ratingRequests.set(String(ratingRequest.id), ratingRequest)
    })
  }),

  getRatingRequestDetails: flow(function* (id: number) {
    return (yield self.request('get', `client/rating/${id}`)) as RatingRequestDetails
  }),

  submitRating: flow(function* (params: RatingForm) {
    const formData = new FormData()
    formData.append('job_id', String(params.job_id))
    formData.append('job_rating', String(params.job_rating))
    params.ogrammerRates.forEach((ogRate, index) => {
      formData.append(`sps[${index}][sp_id]`, String(ogRate.sp_id))
      formData.append(`sps[${index}][rating]`, String(ogRate.rating))
      formData.append(`sps[${index}][comment]`, ogRate.comment ?? '')
      let criteriaIndex = 0
      ogRate.criterias.forEach(criteria => {
        formData.append(`sps[${index}][criterias][${criteriaIndex}]`, String(criteria))
        criteriaIndex++
      })
    })
    yield self.request('post', 'client/rating', formData)
  }),
})

export const ratingViews = (self: DataStore) => ({
  get ratingRequestList() {
    return Array.from(self.ratingRequests.values())
  },

  get ratingRequestsCount() {
    return self.ratingRequests.size
  },
})

export type RatingForm = {
  job_id: number
  job_rating: number
  ogrammerRates: {
    sp_id: number
    rating: number
    criterias: Set<number>
    comment: string | null
  }[]
}

export type RatingRequest = SnapshotIn<typeof RatingRequestModel>
export type RatingRequestSnapshot = SnapshotOut<typeof RatingRequestModel>
