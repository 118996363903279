import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const ManagerModel = types
  .model('Manager', {
    id: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(id: number | null) {
      self.id = id
      self.error = null
    },

    validate() {
      if (self.id === null) {
        self.error = t('job_draft_details.poc_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createManagerMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.contact) {
      return abort(targetInstance.manager.validate())
    }
    return next(call)
  })
}

export type ManagerInstance = Instance<typeof ManagerModel>
