import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useStore, useQuery, useMutation } from '../../../../../utils/mst-hooks'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import './invited-sps.scss'
import crossIcon from '../../../../assets/cross-icon-grey.svg'
import { InvitedSP } from '../../../../invited-sp/invited-sp'
import { useBottomListListener } from '../../../../../utils/use-bottom-list-listener'
import { TrackedEvent, trackingService } from '../../../../../services/tracking-service'
import { ActionAlert, Theme } from '../../../../action-alert/action-alert'
import { Label } from '../../../../label/label'

export const InvitedSPs = observer(({ job }: { job: DraftJobInstance }) => {
  const {
    getPastSPs,
    pastSPsList,
    getInvitedPastSPsByIds,
    invitedPastSPsList,
    pastSPsNextPage,
    getProfessionName,
    getLocationNameById,
  } = useStore().data
  const [searchValue, setSearchValue] = useState('')

  const { isLoading: spsLoading } = useQuery(
    () =>
      getPastSPs({
        availableFrom: job.schedule.dateBoundaries.start_date,
        availableTo: job.schedule.dateBoundaries.end_date,
        preferredLocationId: job.location.value?.id,
        preferredDesignationId: job.category.id,
        collect: false,
      }),
    {
      onError: err => {
        toast.error(err.data.message)
      },
    },
  )
  const [searchSPsResult, searchSPsRequest] = useMutation(
    ({
      searchString,
      availableFrom,
      availableTo,
      page,
      collect,
    }: {
      searchString: string
      availableFrom: string | null
      availableTo: string | null
      page?: number
      collect?: boolean
    }) =>
      getPastSPs({
        searchString,
        availableFrom,
        availableTo,
        page,
        collect,
        preferredLocationId: job.location.value?.id,
        preferredDesignationId: job.category.id,
      }),
    {
      onError: err => {
        toast.error(err.data.message)
      },
    },
  )

  const [, getInvitedSPsQuery] = useMutation(({ ids }: { ids: number[] }) => getInvitedPastSPsByIds(ids), {
    onError: err => {
      toast.error(err.data.message)
    },
  })

  const { t } = useTranslation()

  const addOrRemoveSP = (id: number) => {
    const selected = job.invitedSPs.has(id)
    if (selected) {
      job.invitedSPs.remove(id)
      trackingService.track(TrackedEvent.PreferredWorkerRemovedFromOrder)
    } else {
      if (Number(job.invitedSPs.list?.length) >= Number(job.quantity.value) * 3) {
        toast.warning(t('invited_sps.quantity_must_not_be_greater'))
        trackingService.track(TrackedEvent.PreferredWorkerTriedToAddMoreThanPossible)

        return
      }
      job.invitedSPs.add(id)
      trackingService.track(TrackedEvent.PreferredWorkerAddedToOrder)
    }

    if (job.invitedSPs.list?.length) {
      getInvitedSPsQuery({ ids: job.invitedSPs.list })
    }
  }

  const handleSearchChange = (value: string) => {
    searchSPsRequest({
      searchString: value,
      availableFrom: job.schedule.dateBoundaries.start_date,
      availableTo: job.schedule.dateBoundaries.end_date,
      collect: false,
    })
    setSearchValue(value)

    trackingService.track(TrackedEvent.PreferredWorkerSearchStarted)
  }

  const handleScroll = useBottomListListener(() => {
    if (pastSPsNextPage) {
      searchSPsRequest({
        page: pastSPsNextPage,
        searchString: searchValue,
        availableFrom: job.schedule.dateBoundaries.start_date,
        availableTo: job.schedule.dateBoundaries.end_date,
        collect: true,
      })

      trackingService.track(TrackedEvent.PreferredWorkerScrolled)
    }
  })

  const cantInvite = !job.quantity.value || !job.schedule.scheduleType.value || !job.location.value || !job.category.id

  return (
    <div className="JobDraftInvitedSPs">
      {Number(job.invitedSPs.list?.length) > Number(job.quantity.value) && (
        <ActionAlert
          theme={Theme.Orange}
          title={t('job_draft_details.invited_quantity_more_than_required_quantity')}
          action={() => {}}
          isActionInProgress={false}
          withCloseButton={false}
          style={{ marginTop: '15px' }}
        />
      )}
      <span className="Popup__title">
        <Label title={t('common.optional')} />
        {t('invited_sps.form_title')}
      </span>
      <span className="Popup__subtitle">{t('invited_sps.form_subtitle')}</span>
      {cantInvite ? (
        <div>
          <span className="JobDraftInvitedSPs__noDataProvided">{t('invited_sps.select_required_data')}</span>
        </div>
      ) : (
        <>
          {spsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <SearchInput
                value={searchValue}
                handleValueChange={handleSearchChange}
                className="JobDraftInvitedSPs__searchContainer"
              />
              <Separator.Vertical height={18} />
              {Number(job.invitedSPs.list?.length) > 0 && (
                <>
                  <div className="JobDraftInvitedSPs__addedList" onScroll={handleScroll}>
                    {invitedPastSPsList
                      .filter(sp => job.invitedSPs.has(sp.id))
                      .map(sp => (
                        <div
                          onClick={() => addOrRemoveSP(sp.id)}
                          className="JobDraftInvitedSPs__addedList__item"
                          key={sp.id}
                        >
                          <span>{sp.name}</span>
                          <img src={crossIcon} />
                        </div>
                      ))}
                  </div>
                </>
              )}
              <div className="JobDraftInvitedSPs__list" onScroll={handleScroll}>
                {pastSPsList.map(sp => {
                  const selected = job.invitedSPs.has(sp.id)
                  return (
                    <InvitedSP
                      key={sp.id}
                      disabled={!sp.is_available}
                      withCheckbox={Boolean(sp.is_available)}
                      onClick={() => {
                        if (sp.is_available) {
                          addOrRemoveSP(sp.id)
                        }
                      }}
                      sp={sp}
                      selected={selected}
                      jobDesignation={getProfessionName(Number(job.category.id))}
                      jobLocation={getLocationNameById(String(job.location.value?.id))}
                      showDesignation
                    />
                  )
                })}
                {searchSPsResult.isLoading && <LoadingSpinner />}
              </div>
            </>
          )}
          <Separator.Vertical height={24} />
        </>
      )}
    </div>
  )
})
// @ts-ignore
InvitedSPs.displayName = 'InvitedSPsComponent'
