import { useState } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { OgramModal } from '../../../../../../../../../../../../components/ogram-modal/ogram-modal'
import { Separator } from '../../../../../../../../../../../../components/app-layout/separator/separator'
import { TimeInput } from '../../../../../../../../../../../../components/form/time-input/time-input'
import { Button } from '../../../../../../../../../../../../components/button/button'
import { convertTime12to24, is12HourFormat } from '../../../../../../../../../../../../utils/datetime-utils'
import './check-in-out-modal.scss'

type Props = {
  label: string
  modalVisible: boolean
  onConfirm: (time: string) => void
  onClose: () => void
}

const getFormattedCurrentTime = () => {
  return moment().format('HH:mm')
}

export const CheckInOutModal = observer(({ label, modalVisible, onConfirm, onClose }: Props) => {
  const [selectedTime, setSelectedTime] = useState(getFormattedCurrentTime())
  const { t } = useTranslation()

  if (!modalVisible) return null
  return (
    <OgramModal modalVisible={modalVisible} onClose={onClose} className="CheckInOutModal">
      <Separator.Vertical height={40} />
      <span className="CheckInOutModal__title">{label}</span>
      <Separator.Vertical height={40} />
      <TimeInput
        label={t('job_cherry_picking.time')}
        value={selectedTime}
        handleChange={setSelectedTime}
        className="CheckInOutModal__timeInput"
      />
      <Separator.Vertical height={44} />
      <Button
        disabled={selectedTime.length === 0}
        title={t('job_cherry_picking.confirm')}
        onClick={() => {
          const time24Format = is12HourFormat ? convertTime12to24(selectedTime) : selectedTime
          onConfirm(time24Format)
        }}
        className="CheckInOutModal__confirmButton"
      />
      <Separator.Vertical height={16} />
      <Button title={t('job_cherry_picking.cancel')} onClick={onClose} />
      <Separator.Vertical height={81} />
    </OgramModal>
  )
})
