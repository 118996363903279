import { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Link } from '../../../services/router'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { useStore, useQuery } from '../../../utils/mst-hooks'
import { Separator } from '../../../components/app-layout/separator/separator'
import { routes } from '../../../routes'
import { RatingCard } from './components/rating-card/rating-card'
import './rating-requests-list.scss'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const RatingRequestsListPage = withErrorBoundary(
  observer(() => {
    const { ratingRequestList, getRatingRequests } = useStore().data

    const { isLoading } = useQuery(() => getRatingRequests(true))

    const { t } = useTranslation()

    return (
      <div className="RatingRequestsList">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header title={t('rating_request_list.title')} />
            <Separator.Vertical height={33} />
            {ratingRequestList.length === 0 && (
              <>
                <Separator.Vertical height={67} />
                <p className="RatingRequestsList__emptyList">{t('rating_request_list.empty_list')}</p>
              </>
            )}
            {ratingRequestList.map(ratingRequest => (
              <Fragment key={ratingRequest.id}>
                <Link
                  route={routes.jobRatingDetails}
                  params={{ id: ratingRequest.id }}
                  className="RatingRequestsList__cardContainer"
                >
                  <RatingCard
                    key={ratingRequest.id}
                    title={ratingRequest.title}
                    amount={ratingRequest.sp_max_quantity}
                    location={ratingRequest.client_address}
                    startDateString={ratingRequest.start_date}
                    endDateString={ratingRequest.end_date}
                  />
                </Link>
                <Separator.Vertical height={16} />
              </Fragment>
            ))}
          </>
        )}
      </div>
    )
  }),
)
