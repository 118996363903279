import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { toast } from 'react-toastify'

import { UserRole } from '../../../models/account'
import { useQuery, useStore, useMutation } from '../../../utils/mst-hooks'
import { Separator } from '../../../components/app-layout/separator/separator'
import { FieldError } from '../../../components/form/field-error/field-error'
import TextInput from '../../../components/text-input/text-input'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { Select } from '../../../components/form/select/select'
import { Button } from '../../../components/button/button'
import './invite-user.scss'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

const roleOptions = [
  { key: String(UserRole.Admin), label: 'Admin', value: 'Admin' },
  { key: String(UserRole.Manager), label: 'Manager', value: 'Manager' },
  { key: String(UserRole.Finance), label: 'Finance', value: 'Finance' },
]

export const InviteUser = withErrorBoundary(
  observer(({ email }: { email: string | undefined }) => {
    const { locationsDataView, getLocations, inviteUser } = useStore().data
    const { isLoading: locationsLoading } = useQuery(getLocations)

    const redirect = useRedirect()

    const locationOptions =
      locationsDataView.length > 0
        ? locationsDataView.map((location) => ({
            key: String(location.id),
            label: location.name,
            value: location.name,
          }))
        : null

    const [requestInviteUserResult, requestInvite] = useMutation(inviteUser, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: () => {
        toast.success(t('user_management.invitation_alert_text'))
        redirect(routes.unconfirmedUsers, {})
      },
    })

    const { t } = useTranslation()

    const errors = requestInviteUserResult.error?.data.errors

    return (
      <div className="InviteUser">
        {locationsLoading || requestInviteUserResult.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header title={t('user_management.invite_user')} showBack />
            <Separator.Vertical height={15} />
            <div className="InviteUser__container">
              <Formik
                initialValues={{
                  email: email ?? '',
                  role: roleOptions[0].value,
                  location: null as string | null,
                }}
                onSubmit={(values) => {
                  const requestPayload: Record<string, unknown> = {
                    email: values.email,
                    'roles[0]': Number(roleOptions.find((role) => role.value === values.role)?.key),
                  }
                  if (values.location) {
                    requestPayload['client_locations[0]'] = Number(
                      locationOptions?.find((loc) => loc.value === values.location)?.key,
                    )
                  }
                  requestInvite(requestPayload)
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email(t('validation_messages.email_invalid'))
                    .required(t('user_management.enter_email')),
                  role: Yup.string().required(t('user_management.choose_role')),
                  location: Yup.string()
                    .nullable(true)
                    .when('role', {
                      is: UserRole.Manager,
                      then: Yup.string()
                        .required(t('user_management.choose_location'))
                        .typeError(t('user_management.choose_location')),
                    }),
                })}
              >
                {(formikProps) => (
                  <Form noValidate className="InviteUser__container__form">
                    <FieldError errors={errors?.email}>
                      <TextInput formikProps={formikProps} label={t('user_management.email')} formikKey="email" />
                    </FieldError>
                    <Separator.Vertical height={20} />
                    <Select
                      label={t('user_management.job_role')}
                      value={formikProps.getFieldProps('role').value}
                      onChange={(value) => {
                        if (value === 'Admin') {
                          formikProps.setFieldValue('location', null)
                        } else {
                          formikProps.setFieldValue('location', locationOptions?.[0].value)
                        }
                        formikProps.setFieldValue('role', value)
                      }}
                      options={roleOptions}
                    />
                    <Separator.Vertical height={20} />
                    {formikProps.values.role === 'Manager' && (
                      <>
                        <FieldError errors={formikProps.errors.location ?? errors?.client_locations}>
                          <Select
                            label={t('user_management.location')}
                            value={formikProps.getFieldProps('location').value}
                            onChange={(value) => {
                              formikProps.setFieldValue('location', value)
                            }}
                            options={locationOptions ?? []}
                          />
                        </FieldError>
                        <Separator.Vertical height={34} />
                      </>
                    )}
                    <div className="InviteUser__container__form__buttons">
                      <Button
                        type="submit"
                        title={t('user_management.save')}
                        className="InviteUser__container__form__buttons__button submit"
                      />
                      <Separator.Horizontal width={10} />
                      <Button
                        title={t('user_management.cancel')}
                        className="InviteUser__container__form__buttons__button"
                        type="button"
                        onClick={() => window.history.back()}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </>
        )}
      </div>
    )
  }),
)
