import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const RegionModel = types.model('Region', {
  id: types.identifierNumber,
  name: types.string,
})

export const regionActions = (self: DataStore) => ({
  getRegions: flow(function* () {
    const regions = (yield self.request('get', 'common/regions?with_hidden=1')) as RegionModelSnapshot[]
    regions.forEach((region) => {
      self.regions.set(String(region.id), region)
    })
  }),
})

export const regionViews = (self: DataStore) => ({
  getRegionId(name: string) {
    return Array.from(self.regions.values()).find((region) => region.name === name)?.id
  },

  getRegionName(id: number) {
    return Array.from(self.regions.values()).find((region) => region.id === id)?.name
  },

  get regionsData() {
    return Array.from(self.regions.values())
  },
})

export type Region = typeof RegionModel.Type
export type RegionModelSnapshot = typeof RegionModel.SnapshotType
