import { types, flow, SnapshotIn, SnapshotOut, Instance } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from '../data-store/data-store'

export enum OrderState {
  Unconfirmed = 'unconfirmed',
  Active = 'active',
  Completed = 'completed',
}

export const ListItemOrderModel = types.model('ListItemOrder', {
  id: types.identifierNumber,
  start_date: types.maybeNull(types.string),
  end_date: types.maybeNull(types.string),
  is_draft: types.boolean,
  is_pending_rates_review: types.maybeNull(types.number),
  is_pending_client_approval: types.maybeNull(types.number),
  days_till_end_count: types.number,
  jobs: types.array(
    types.model({
      id: types.number,
      work_category_name: types.string,
      quantity: types.number,
      location_name: types.string,
      start_date: types.string,
      end_date: types.string,
      completeness: types.number,
      days_till_end_count: types.number,
      min_extension_date: types.string,
    }),
  ),
})

export const listItemOrdersActions = (self: DataStore) => ({
  getOrders: flow(function* (
    ordersState: string,
    locationId?: number | null,
    designationId?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    page?: number,
  ) {
    if (page === 1) {
      self.order.ordersList.map.clear()
      self.order.ordersList.nextPage = 1
    }
    const nextPage = self.order.ordersList.nextPage
    if (nextPage) {
      let url = `client/order/find-by-state/${ordersState}?page=${nextPage}`
      if (locationId) {
        url += `&location_id=${locationId}`
      }
      if (designationId) {
        url += `&designation_id=${designationId}`
      }
      if (startDate) {
        url += `&start_date=${startDate}`
      }
      if (endDate) {
        url += `&end_date=${endDate}`
      }

      const { list, paginator } = (yield self.request('get', url)) as {
        list: ListItemOrderSnapshot[]
        paginator?: Paginator
      }
      list.forEach(item => {
        self.order.ordersList.map.set(String(item.id), item)
      })
      self.order.ordersList.nextPage = paginator?.next_page ?? null
    }
  }),
})

export const listItemOrdersViews = (self: DataStore) => ({
  get orders() {
    return Array.from(self.order.ordersList.map.values())
  },
})

export type ListItemOrder = SnapshotIn<typeof ListItemOrderModel>
export type ListItemOrderSnapshot = SnapshotOut<typeof ListItemOrderModel>
export type ListItemOrderInstance = Instance<typeof ListItemOrderModel>
