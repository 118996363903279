import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

export const CherryPickingJobModel = types.model('CherryPickingJob', {
  id: types.identifierNumber,
  work_category_name: types.string,
  location_name: types.string,
  required_quantity: types.maybeNull(types.number),
  quantity: types.maybeNull(types.number),
  start_date: types.string,
  end_date: types.string,
  shifts_count: types.number,
  shifts_fulfilled_count: types.number,
  applicants_count: types.maybeNull(types.number),
})

export const CherryPickingJobList = types.model('CherryPickingJobList', {
  map: types.map(CherryPickingJobModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const cherryPickingJobsActions = (self: DataStore) => ({
  getCherryPickingJob: flow(function* (jobId) {
    const job = yield self.request('get', `client/cherry-picking/job-details?job_id=${jobId}`)
    self.cherryPickingJobs.map.set(String(job.id), job)
  }),
  getUnFullfilledJobList: flow(function* (page?: number) {
    if (page === 1) {
      self.cherryPickingJobs.map.clear()
      self.cherryPickingJobs.nextPage = 1
    }
    const nextPage = self.cherryPickingJobs.nextPage
    if (nextPage) {
      const { list, paginator } = (yield self.request(
        'get',
        `client/cherry-picking/unfulfilled-jobs?page=${nextPage}`,
      )) as {
        list: CherryPickingJobSnapshot[]
        paginator?: Paginator
      }

      list.forEach((item) => {
        self.cherryPickingJobs.map.set(String(item.id), item)
      })
      self.cherryPickingJobs.nextPage = paginator?.next_page ?? null
    }
  }),
})

export const cherryPickingJobsViews = (self: DataStore) => ({
  getCherryPickingByIdView: (jobId: string): CherryPickingJob => {
    return self.cherryPickingJobs.map.get(jobId) as CherryPickingJob
  },
  get cherryPickingListView() {
    return Array.from(self.cherryPickingJobs.map.values())
  },
})

export type CherryPickingJob = typeof CherryPickingJobModel.Type
export type CherryPickingJobSnapshot = SnapshotOut<typeof CherryPickingJobModel>
