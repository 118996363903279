import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { DateInput } from '../../../../components/date-input/date-input'
import { LeadForm } from '../../lead-form'
import './dates.scss'

const currentDateFormatted = moment(new Date()).format('YYYY-MM-DD')

export const Dates = ({ values, updateField }: LeadForm) => {
  const { t } = useTranslation()

  return (
    <div className="Dates">
      <Separator.Vertical height={67} />
      <DateInput
        label={t('lead_form.start_date')}
        value={values.dates.startDate ?? ''}
        handleChange={(startDate) => {
          updateField({ ...values.dates, startDate })
        }}
        min={currentDateFormatted}
        max={values.dates.endDate ?? undefined}
        className="Dates__dateInput"
      />
      <Separator.Vertical height={22} />
      <DateInput
        label={t('lead_form.end_date')}
        value={values.dates.endDate ?? ''}
        handleChange={(endDate) => {
          updateField({ ...values.dates, endDate })
        }}
        min={values.dates.startDate ?? currentDateFormatted}
        className="Dates__dateInput"
      />
    </div>
  )
}
