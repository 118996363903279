import { config, isProductionEnvironment } from '../config/env'

class LeanService {
  connect(
    customerId: string,
    endUserId: string,
    onConnected: (responseObject: { status: string | null; secondary_status: string | null }) => void,
  ) {
    // @ts-ignore
    window.Lean.connect({
      app_token: config.LEAN_APP_TOKEN,
      permissions: ['identity', 'transactions', 'balance', 'accounts', 'payments'],
      customer_id: customerId,
      end_user_id: endUserId,
      payment_destination_id: config.LEAN_PAYMENT_DESTINATION_ID,
      sandbox: !isProductionEnvironment(),
      callback: onConnected,
    })
  }

  pay(
    paymentIntentId: string,
    endUserId: string,
    onPaid: (responseObject: { status: string | null; secondary_status: string | null }) => void,
  ) {
    // @ts-ignore
    window.Lean.pay({
      app_token: config.LEAN_APP_TOKEN,
      payment_intent_id: paymentIntentId,
      end_user_id: endUserId,
      sandbox: !isProductionEnvironment(),
      callback: onPaid,
    })
  }

  authorize(
    paymentIntentId: string,
    customerId: string,
    endUserId: string,
    onAuthorized: (responseObject: { status: string | null; secondary_status: string | null }) => void,
  ) {
    // @ts-ignore
    window.Lean.authorize({
      app_token: config.LEAN_APP_TOKEN,
      payment_intent_ids: [paymentIntentId],
      customer_id: customerId,
      end_user_id: endUserId,
      sandbox: !isProductionEnvironment(),
      callback: onAuthorized,
    })
  }
}

export const leanService = new LeanService()
