import { observer } from 'mobx-react'

import { Select } from '../select/select'
import { useQuery, useStore } from '../../../utils/mst-hooks'

type InputProps = {
  label: string
  value: number | undefined
  onChange: (val: number) => void
  className: string
}

export const DesignationSelect = observer(({ label, value, onChange, className }: InputProps) => {
  const { professionList, getProfessions } = useStore().data

  const { isLoading: professionsLoading } = useQuery(getProfessions)

  const professionOptions =
    professionList.length > 0
      ? professionList.map(designation => ({
          key: String(designation.id),
          label: designation.name,
          value: designation.id,
        }))
      : []

  if (professionsLoading) {
    return null
  }

  return <Select label={label} value={value} onChange={onChange} options={professionOptions} className={className} />
})
