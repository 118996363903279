import { transformArrayToObj } from '../../../utils/common'
import { epochToDate, formatDateToIso8601, getWeekDates, utcOffsetSecs } from '../../../utils/datetime-utils'
import { ApiJobSchedule, ScheduleDatesSnapshot, ScheduleSnapshot } from '../types'

export function convertToSchedule(input: ApiJobSchedule[], date: Date): ScheduleSnapshot[] {
  return input.map(item => {
    const { shifts, assigned_sps, ...fieldsToCopy } = item
    // convert shifts to origin time
    const shiftsInOriginTime = shifts.map(shiftItem => ({
      ...shiftItem,
      start_time: shiftItem.start_time + shiftItem.timezone_offset + utcOffsetSecs,
      end_time: shiftItem.end_time + shiftItem.timezone_offset + utcOffsetSecs,
    }))

    const transformedAssignedSps = transformArrayToObj(
      'id',
      assigned_sps.map(spItem => {
        // convert active shifts to origin time
        const activeShiftsInOriginTime = spItem.active_shifts.map(activeShiftItem => ({
          ...activeShiftItem,
          start_time: activeShiftItem.start_time
            ? activeShiftItem.start_time + activeShiftItem.timezone_offset + utcOffsetSecs
            : null,
          end_time: activeShiftItem.end_time
            ? activeShiftItem.end_time + activeShiftItem.timezone_offset + utcOffsetSecs
            : null,
          client_checkin_time: activeShiftItem.client_checkin_time
            ? activeShiftItem.client_checkin_time + activeShiftItem.timezone_offset + utcOffsetSecs
            : null,
          client_checkout_time: activeShiftItem.client_checkout_time
            ? activeShiftItem.client_checkout_time + activeShiftItem.timezone_offset + utcOffsetSecs
            : null,
        }))
        return { ...spItem, active_shifts: transformArrayToObj('shift_id', activeShiftsInOriginTime) }
      }),
    )
    const transformedShifts = transformArrayToObj('id', shiftsInOriginTime)
    const dates: ScheduleDatesSnapshot = getWeekDates(date).reduce((map, dateItem) => {
      return {
        ...map,
        [formatDateToIso8601(dateItem)]: {
          shift_id: shifts.find(shift => epochToDate(shift.start_time).getDate() === dateItem.getDate())?.id,
          sps: assigned_sps.reduce((acc, sp) => ({ ...acc, [sp.id]: sp.id }), {}),
        },
      }
    }, {})

    return {
      ...fieldsToCopy,
      shifts: transformedShifts,
      assigned_sps: transformedAssignedSps,
      dates,
      modifications: { shifts: {}, assigned_sps: {} },
    }
  })
}
