import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../app-layout/separator/separator'
import { PageStepper } from './page-stepper'
import closeImg from './assets/close.svg'
import './page-onboarding.scss'

export type PageContent = {
  image?: { src: string; className: string }
  description?: string
}
export type Page = { title: string | null; content: PageContent[] }
export type OnboardingContent = { title: string; paths: string[]; pages: Page[] }

export type PageOnboardingProps = {
  visible: boolean
  initialIndex: number
  content: OnboardingContent
  onClose: () => void
}

export const PageOnboarding = ({ visible, initialIndex, content, onClose }: PageOnboardingProps) => {
  const { t } = useTranslation()

  const [pageIndex, setPageIndex] = useState(initialIndex)

  const pageContent = content.pages[pageIndex].content

  const handleClose = () => {
    onClose()
    setPageIndex(0)
  }

  return (
    <div className={`PageOnboarding ${visible ? '' : 'hidden'}`}>
      <div className="PageOnboarding__inner-container">
        <Separator.Vertical height={30} />
        <div onClick={handleClose} className="PageOnboarding__inner-container__closeContainer">
          <img src={closeImg} className="PageOnboarding__inner-container__closeContainer__img" />
          {pageIndex === 0 && (
            <>
              <Separator.Vertical height={7.5} />
              <span className="PageOnboarding__inner-container__closeContainer__closeLabel">
                {t('onboarding.click_here_to_close')}
              </span>
            </>
          )}
        </div>
        {pageContent.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.image && <img src={item.image.src} className={item.image.className} />}
              {item.description && (
                <span className="PageOnboarding__inner-container__description">{item.description}</span>
              )}
              {index !== pageContent.length - 1 && <Separator.Vertical height={10} />}
            </Fragment>
          )
        })}
        <Separator.Vertical height={10} />
        <PageStepper
          step={pageIndex}
          stepsCount={content.pages.length}
          toNextStep={() => setPageIndex((prevPage) => prevPage + 1)}
          toPreviousStep={() => setPageIndex((prevPage) => prevPage - 1)}
          close={handleClose}
        />
      </div>
    </div>
  )
}
