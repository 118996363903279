import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { JobDocumentsSnapshot } from '../../../../../models/job-documents'
import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { Checkbox } from '../../../../checkbox/checkbox'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './documents.scss'

export const JobDraftDocuments = observer(({ job }: { job: DraftJobInstance }) => {
  const { jobDocumentsList, getJobDocuments } = useStore().data
  const [filteredDocuments, setFilteredDocuments] = useState<JobDocumentsSnapshot[]>([])
  const [calculatingList, setCalculatingList] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const { isLoading: jobDocumentsLoading } = useQuery(getJobDocuments)

  const filterDocuments = useDebouncedCallback((value: string) => {
    setFilteredDocuments(jobDocumentsList.filter(item => item.name.toLowerCase().includes(value.toLowerCase())))
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  useEffect(() => {
    if (jobDocumentsList.length > 0) {
      setFilteredDocuments(jobDocumentsList)
    }
  }, [jobDocumentsList, jobDocumentsList.length])

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterDocuments(value)
  }

  return (
    <div className="JobDraftDocuments">
      <span className="Popup__title">{t('job_draft_details.documents_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.documents_subtitle')}</span>
      {jobDocumentsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            placeholder={t('job_draft_details.select_document')}
            className="JobDraftDocuments__searchContainer"
          />
          <Separator.Vertical height={18} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className={`JobDraftDocuments__list ${job.documents.hasError ? 'error' : ''}`}>
              {filteredDocuments.map(doc => {
                const selected = job.documents.has(doc.id)
                return (
                  <div
                    onClick={() => {
                      if (selected) {
                        job.documents.remove(doc.id)
                      } else {
                        job.documents.add(doc.id)
                      }
                    }}
                    key={doc.id}
                    className="JobDraftDocuments__list__item"
                  >
                    <span className="JobDraftDocuments__list__item__label">{doc.name}</span>
                    <Checkbox isActive={selected} type="square" />
                  </div>
                )
              })}
            </div>
          )}
          <JobDraftFieldError error={job.documents.error} />
          <Separator.Vertical height={20} />
        </>
      )}
    </div>
  )
})
