import moment from 'moment'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import location from '../../../../../components/assets/location-icon-grey.svg'
import calender from '../../../../../components/assets/calender-icon-grey.svg'
import { CherryPickingJob } from '../../../../../models/cherry-picking-jobs'
import { dateFormat } from '../../../../../utils/datetime-utils'

import './applicant-review-job-card.scss'

interface ApplicantReviewJobCardProps {
  onClick: () => void
  jobDetails: CherryPickingJob
}

const MINIMUM_RATIO = 50 // minimum ration to turn fullfilment into green badge
const getRatio = (current: number, total: number) => {
  return Math.round((current / total) * 100)
}

const ApplicantReviewJobCard = ({ onClick, jobDetails }: ApplicantReviewJobCardProps) => {
  const fullFilmentRatio = useMemo(() => {
    return getRatio(jobDetails.shifts_fulfilled_count, jobDetails.shifts_count)
  }, [jobDetails])

  const { t } = useTranslation()

  return (
    <div onClick={onClick} className="ApplicantReviewJobCard">
      <h6
        className={`ApplicantReviewJobCard__fullfillmentPercentage ${
          fullFilmentRatio <= MINIMUM_RATIO ? '' : 'ApplicantReviewJobCard__fullfillmentPercentage--green'
        }`}
      >
        {t('applicants_review.percent_filled', { percent: fullFilmentRatio })}
      </h6>
      <h5 className="ApplicantReviewJobCard__name">
        <span>{jobDetails.work_category_name}</span>
        <span>({jobDetails.quantity ?? jobDetails.required_quantity})</span>
      </h5>
      <div className="ApplicantReviewJobCard__details">
        <img src={location} />
        {jobDetails.location_name}
      </div>
      <div className="ApplicantReviewJobCard__details">
        <img src={calender} />
        {moment(jobDetails.start_date).format(dateFormat)} - {moment(jobDetails.end_date).format(dateFormat)}
      </div>
    </div>
  )
}

export default ApplicantReviewJobCard
