import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Question } from '../../../../models/interview'
import { QuestionList } from '../question-list/question-list'
import { QuestionItem } from '../question-item/question-item'
import { useStore } from '../../../../utils/mst-hooks'
import { Interview } from '../../components/interview/interview'
import chatHollowImg from './assets/chat-hollow.svg'
import './interview-section.scss'

type InterviewSectionProps = {
  jobId: number
  spId: number
  isLoading: boolean
  onAcceptPress: () => void
}

function InterviewSectionComponent({ jobId, spId, isLoading, onAcceptPress }: InterviewSectionProps) {
  const { t } = useTranslation()
  const { getInterviewByJobId, getQuestionById, spHasAnswers, getSpAnswer } = useStore().data
  const [showInterviewQuestion, setShowInterviewQuestion] = useState<{ qId: number; qIndex: number } | null>(null)

  const interview = getInterviewByJobId(jobId)

  if (isLoading || !interview) return null

  const hasAnswers = spHasAnswers({ interviewId: interview.id, spId })

  if (!hasAnswers) return null

  const questionsWithAnswers = interview.questions.filter(questionId => {
    const answer = getSpAnswer({ interviewId: interview.id, questionId, spId })
    return Boolean(answer)
  })

  return (
    <div className="InterviewSection">
      <div className="InterviewSection__header">
        <img src={chatHollowImg} alt="" className="InterviewSection__chatImg" />
        <div className="InterviewSection__title">{t('applicant_details.interview')}</div>
      </div>
      <div className="InterviewSection__listContainer">
        <QuestionList>
          {questionsWithAnswers.map((qId, qIndex) => {
            const question = getQuestionById(qId) as Question
            return (
              <QuestionItem
                key={qId}
                description={question.description}
                rating={null}
                className="InterviewSection__listItem"
                onClick={() => {
                  setShowInterviewQuestion({ qId, qIndex })
                }}
              />
            )
          })}
        </QuestionList>
      </div>
      <Interview
        applicantsType="single"
        jobId={jobId}
        showInitialQuestion={showInterviewQuestion}
        onAcceptPress={onAcceptPress}
      />
    </div>
  )
}

export const InterviewSection = observer(InterviewSectionComponent)
