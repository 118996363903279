import './dropdown-select.scss'
import { useRef, useState } from 'react'

import dropdownArrowDownIcon from '../assets/dropdown-arrow-down.svg'
import { useOutsideClickHandler } from '../../utils/use-outside-click-handler'

type Props = {
  label: string
  items: {
    element: React.ReactNode
    closeOnClick: boolean
  }[]
  value: string | null
}

export const DropdownSelect = ({ label, items, value }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const selectRef = useRef(null)
  useOutsideClickHandler(selectRef, () => setIsOpen(false))

  if (items.length === 0) {
    return null
  }

  return (
    <div className="DropdownSelect" ref={selectRef}>
      <div className="DropdownSelect__selector" onClick={toggleIsOpen}>
        <div className="DropdownSelect__selector__content">
          <span
            className={
              value ? 'DropdownSelect__selector__content__title' : 'DropdownSelect__selector__content__title--no-value'
            }
          >
            {label}
          </span>
          {value && <span className="OrderExtension__job__selector__content__value">{value}</span>}
        </div>
        <img src={dropdownArrowDownIcon} />
      </div>
      {isOpen && (
        <ul className="DropdownSelect__content">
          {items.map((item, index) => (
            <li
              key={index}
              className="DropdownSelect__item"
              onClick={() => {
                if (item.closeOnClick) {
                  setIsOpen(false)
                }
              }}
            >
              {item.element}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
