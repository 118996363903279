import { useState } from 'react'
import { Trans } from 'react-i18next'

import infoIcon from '../../../../../components/assets/info-italic.svg'
import crossIcon from '../../../../../components/assets/cross-icon-blue.svg'
import './chief-card.scss'

export const ChiefCard = () => {
  const [visible, setVisible] = useState(true)

  if (!visible) return null

  return (
    <div className="CheifCard">
      <img src={infoIcon} alt="" className="CheifCard__infoIcon" />
      <div className="CheifCard__text">
        <Trans i18nKey="applicant_details.chief">
          <span>Chief OG's </span>
          <span className="CheifCard__text__highlight">
            are experienced team leaders, hand picked to better coordinate Ogram teams on the ground.
          </span>
        </Trans>
      </div>
      <img
        src={crossIcon}
        alt=""
        className="CheifCard__crossIcon"
        onClick={() => {
          setVisible(false)
        }}
      />
    </div>
  )
}
