import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { LanguageSnapshot } from '../../../../../models/languages'
import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { Checkbox } from '../../../../checkbox/checkbox'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { JobDraftFieldError } from '../error/error'
import './languages.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'

export const JobDraftLanguages = observer(({ job }: { job: DraftJobInstance }) => {
  const { languageList, getLanguages } = useStore().data
  const [filteredLanguages, setFilteredLanguages] = useState<LanguageSnapshot[]>([])
  const [calculatingList, setCalculatingList] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const { isLoading: languagesLoading } = useQuery(getLanguages)

  const filterLanguages = useDebouncedCallback((value: string) => {
    setFilteredLanguages(languageList.filter(item => item.name.toLowerCase().includes(value.toLowerCase())))
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  useEffect(() => {
    if (languageList.length > 0) {
      setFilteredLanguages(languageList)
    }
  }, [languageList, languageList.length])

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterLanguages(value)
  }

  return (
    <div className="JobDraftLanguages">
      <span className="Popup__title">{t('job_draft_details.languages_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.languages_subtitle')}</span>
      {languagesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            className="JobDraftLanguages__searchContainer"
          />
          <Separator.Vertical height={18} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className={`JobDraftLanguages__list ${job.languages.hasError ? 'error' : ''}`}>
              {filteredLanguages.map(lang => {
                const selected = job.languages.has(lang.id)
                return (
                  <div
                    onClick={() => {
                      if (selected) {
                        job.languages.remove(lang.id)
                      } else {
                        job.languages.add(lang.id)
                      }
                    }}
                    key={lang.id}
                    className="JobDraftLanguages__list__item"
                  >
                    <span className="JobDraftLanguages__list__item__label">{lang.name}</span>
                    <Checkbox isActive={selected} type="square" />
                  </div>
                )
              })}
            </div>
          )}
          <JobDraftFieldError error={job.languages.error} />
          <Separator.Vertical height={20} />
        </>
      )}
    </div>
  )
})
