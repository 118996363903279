import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/users'
import '../onboarding.scss'

export const usersOnboarding: OnboardingContent = {
  title: t('users_onboarding.title'),
  paths: [routes.users.path],
  pages: [
    {
      title: t('users_onboarding.user_list_title'),
      content: [
        { image: { src: images.userListImg, className: 'Onboarding__bigImg' } },
        { description: t('users_onboarding.user_list') },
      ],
    },
    {
      title: t('users_onboarding.user_details_title'),
      content: [
        { image: { src: images.userDetailsImg, className: 'Onboarding__bigImg' } },
        { description: t('users_onboarding.user_details') },
      ],
    },
  ],
}
