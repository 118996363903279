// TODO: fix ts-issues
// @ts-nocheck
import { AttendanceManagerSnapshot } from '../../../models/attendance-manager'
import { CompanyDetailsSnapshot } from '../../../models/company-details'
import { JobSnapshot } from '../../../models/job'
import { BusinessLocationSnapshot } from '../../../models/location'
import { Profession } from '../../../models/profession'
import { ProfileSnapshot } from '../../../models/profile'
import { RatingRequestSnapshot } from '../../../models/rating-request'
import { Uniform } from '../../../models/uniform'
import { InvoiceSnapshot } from '../../../models/invoice'
import { SPDetailsSnapshot } from '../../../models/sp'

const managers: AttendanceManagerSnapshot[] = [
  {
    email: 'bar@foo',
    id: 1,
    locations: [],
    first_name: 'Donald',
    last_name: 'Trump',
    job_role: 'manager',
    status: 'active',
    phone: '2423424',
  },
  {
    email: 'foo@bar',
    id: 2,
    locations: [],
    first_name: 'John',
    last_name: 'Fride',
    phone: '1231412',
    status: 'active',
    job_role: 'manager',
  },
]

const loginData = {
  id: '439392e4-f32c-4ea1-8c5d-f739877cl222',
  token: '23a803fa627c006eb0ddca6d660c7684',
  name: 'Artur',
  account_type: 'C',
  is_basic_info_complete: true,
  is_corporate_details_complete: false,
}

const profile: ProfileSnapshot = {
  id: 123,
  first_name: 'Artur',
  last_name: 'Mukminov',
  email: 'client1@gmail.com',
  phone: '01000700386',
  image: 'https://www.drupal.org/files/user-pictures/picture-54136-1489672154.jpg',
  total_jobs_count: 10,
  inprogress_jobs_count: 5,
  unpaid_invoices_count: 3,
  rating_request_count: 2,
}

const ok = { status: 'ok', message: 'ok' }

const location1: BusinessLocationSnapshot = {
  id: 1,
  address: 'Indianapolis, IN 46225',
  address_details: '1903 Winifred Way ',
  image: null,
  delete_image: 0,
  lat: 37.4,
  lng: -122,
  name: 'Home',
  region: 'USA',
  is_georestriction_large: false,
  is_georestriction_disabled: false,
}
const location2: BusinessLocationSnapshot = {
  id: 2,
  address: '581 Oxford Court',
  address_details: 'Amory, MS 38821',
  image: null,
  delete_image: 0,
  lat: 0,
  lng: 0,
  name: 'Work',
  region: 'USA',
  is_georestriction_large: false,
  is_georestriction_disabled: false,
}

const locations = [location1, location2]

const companyDetails: CompanyDetailsSnapshot = {
  company_name: 'Google',
  address: '1600 Amphitheatre Parkway',
  region_id: 1,
  is_corporate: 0,
  is_document_complete: 0,
  is_pocs_created: 0,
  po_box: '01000700386',
  finance_first_name: 'Ivan',
  finance_last_name: 'Volovikov',
  trade_name: '',
  free_zone: '',
  license_number: '',
  is_lpo_required: 0,
  is_sla_accepted: 0,
  finance_extension: null,
  finance_email: 'inbox@google.com',
  status_id: 1,
  trade: null,
  vat: 'http://134.209.26.2:88/dashboard/images/avatar_client.png',
  finance_phone: '+1 (123) 345-23-23',
  is_custom_sla: 0,
}

const ratingRequests: { list: RatingRequestSnapshot[] } = {
  list: [
    {
      id: 1,
      title: 'Mock Admin Assistant job',
      client_address: 'Abu Dhabi',
      start_date: '2021-03-03',
      end_date: '2021-03-05',
      sp_max_quantity: 3,
    },
    {
      id: 2,
      title: 'Mock Waiter job',
      client_address: 'Dubai',
      start_date: '2022-02-02',
      end_date: '2022-02-04',
      sp_max_quantity: 5,
    },
  ],
}

const uniforms: Uniform[] = [
  {
    id: 1,
    name: 'We will provide uniform for service providers.',
    description: '',
    image_url:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQMd5xgHHeTRsvHE7IPrNqKMfZkRtsC2ySQWG2jLIf85hTHBht_&usqp=CAU',
  },
  {
    id: 2,
    name: 'Formal',
    description: 'black trousers/ Black shirt/ Black shoes',
    image_url: 'https://5.imimg.com/data5/OY/RK/QJ/SELLER-43660254/mens-formal-uniform-250x250.jpg',
  },
  {
    id: 3,
    name: 'Formal white',
    description: 'black trousers/ white shirt',
    image_url: 'https://4.imimg.com/data4/JO/RK/MY-20259882/mens-college-uniforms-250x250.jpg',
  },
  {
    id: 3,
    name: 'Casual',
    description: 'Jeans/ white shirt',
    image_url: 'https://i.pinimg.com/474x/13/d2/dc/13d2dc9225a59b99af73c4fed295df08--restaurant-uniforms-casual.jpg',
  },
]

const professions: Profession[] = [
  {
    'id': 1,
    'name': 'Accountant',
    'image': 'http://134.209.26.2:88/professions/Accountant-1.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
  {
    'id': 2,
    'name': 'Actor',
    'image': 'http://134.209.26.2:88/professions/actor-1.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
  {
    'id': 3,
    'name': 'Admin Assistant',
    'image': 'http://134.209.26.2:88/professions/Admin-Assistant-1.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
  {
    'id': 4,
    'name': 'Barback',
    'image': 'http://134.209.26.2:88/professions/barber.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
  {
    'id': 5,
    'name': 'Barista',
    'image': 'http://134.209.26.2:88/professions/barista-1.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
  {
    'id': 6,
    'name': 'Bartender',
    'image': 'http://134.209.26.2:88/professions/bouncer-1.png',
    'description': 'Description',
    'job_description': 'Job description',
  },
]

const jobs: JobSnapshot[] = []

const unpaidInvoices: { list: Omit<InvoiceSnapshot, 'paid' | 'items' | symbol>[] } = {
  list: [
    {
      id: 1,
      date: '2020-02-25',
      due_date: '2020-02-25',
      total_amount: 200,
      currency_code: 'USD',
      invoice_number: '33',
      status: 2,
    },
  ],
}

const paidInvoices: { list: Omit<InvoiceSnapshot, 'paid' | 'items' | symbol>[] } = {
  list: [
    {
      id: 1,
      date: '2020-02-25',
      due_date: '2020-02-25',
      total_amount: 200,
      currency_code: 'USD',
      invoice_number: '34',
      status: 3,
    },
  ],
}

const invoiceWithDetails: InvoiceSnapshot = {
  id: 3,
  invoice_number: '33',
  currency_code: 'AED',
  status: 2,
  date: '2020-05-05',
  due_date: '2020-05-12',
  total_amount: 450,
}

const sps: { list: Omit<SPDetailsSnapshot, 'full_name' | 'gender'>[] } = {
  list: [],
}

export const mockData = {
  managers: managers,
  locations: locations,
  logedIn: loginData,
  profile: profile,
  companyDetails: companyDetails,
  ratingRequests: ratingRequests,
  uniforms: uniforms,
  professions: professions,
  jobs: jobs,
  ok: ok,
  unpaidInvoices,
  paidInvoices,
  invoiceWithDetails,
  sps,
}
