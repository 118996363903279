import { flow, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const SeniorityLevelModel = types.model('SeniorityLevels', {
  levelId: types.integer,
  minYears: types.maybeNull(types.integer),
  maxYears: types.maybeNull(types.integer),
})

export const seniorityLevelsActions = (self: DataStore) => ({
  getSeniorityLevels: flow(function* (workCategoryId: number) {
    const seniorityLevels: SeniorityLevelSnapshot[] = yield self.request(
      'get',
      `common/work-categories/${workCategoryId}/seniority-levels`,
    )
    self.workCategorySeniorityLevels.set(String(workCategoryId), seniorityLevels)
  }),
})

export const seniorityLevelsViews = (self: DataStore) => ({
  getSeniorityLevelById(workCategoryId: number, levelId: number) {
    return (
      self.workCategorySeniorityLevels.get(String(workCategoryId))?.filter((item) => item.levelId === levelId)[0] ??
      null
    )
  },
})

export type SeniorityLevel = SnapshotIn<typeof SeniorityLevelModel>
export type SeniorityLevelSnapshot = SnapshotOut<typeof SeniorityLevelModel>

export enum Experience {
  junior = '1',
  middle = '2',
  senior = '3',
  beginnerOrStudent = '4',
}
