import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { OgramModal } from '../../../../../../../../../../components/ogram-modal/ogram-modal'
import { Separator } from '../../../../../../../../../../components/app-layout/separator/separator'
import { Select } from '../../../../../../../../../../components/form/select/select'
import { Button } from '../../../../../../../../../../components/button/button'
import TextInput from '../../../../../../../../../../components/text-input/text-input'
import './cancel-job-modal.scss'

type Props = {
  modalVisible: boolean
  onClose: () => void
}

export const CancelJobModal = ({ modalVisible, onClose }: Props) => {
  const { t } = useTranslation()

  const capacityLabel = t('job_shifts.i_have_capacity')
  const otherLabel = t('job_shifts.other')

  if (!modalVisible) return null

  return (
    <OgramModal modalVisible={modalVisible} onClose={onClose} className="CancelJobModal">
      <Separator.Vertical height={29} />
      <span className="CancelJobModal__title">{t('job_shifts.cancel_shift')}</span>
      <Separator.Vertical height={27} />
      <span className="CancelJobModal__subtitle">{t('job_shifts.confirmation_question')}</span>
      <Separator.Vertical height={27} />
      <Formik
        initialValues={{
          reason: capacityLabel,
          comment: '',
        }}
        onSubmit={values => {
          // TODO: implement functionality
          console.log('values', values)
        }}
        validationSchema={Yup.object().shape({
          reason: Yup.string().required(),
        })}
      >
        {formikProps => (
          <Form noValidate className="CancelJobModal__form">
            <Select
              label={t('job_shifts.select_reason')}
              value={formikProps.getFieldProps('reason').value}
              onChange={value => {
                formikProps.setFieldValue('reason', value)
              }}
              options={[
                { key: capacityLabel, value: capacityLabel, label: capacityLabel },
                { key: otherLabel, value: otherLabel, label: otherLabel },
              ]}
            />
            <Separator.Vertical height={20} />
            <div className="CancelJobModal__textarea-container">
              <TextInput
                formikProps={formikProps}
                type="textarea"
                label={t('job_shifts.comment_here')}
                formikKey="comment"
              />
            </div>
            <Separator.Vertical height={50} />
            <Button type="submit" title={t('job_shifts.cancel_job')} className="CancelJobModal__confirm-button" />
            <Separator.Vertical height={16} />
            <Button title={t('job_shifts.cancel')} onClick={onClose} />
            <Separator.Vertical height={30} />
          </Form>
        )}
      </Formik>
    </OgramModal>
  )
}
