import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

export type InvoiceJobShifts = {
  sp: {
    id: number
    full_name: string
  }
  invoice: {
    title: string
    location: string
    hours: number
    price: number
    currency_code: string
  }
  shifts: {
    date: string
    hours: number
    approver: {
      id: number | null
      full_name: string
    }
  }[]
}

const InvoicePaymentModel = types.model('InvoicePayment', {
  id: types.identifierNumber,
  payment_number: types.string,
  type_id: types.maybeNull(types.number),
  due_date: types.string,
  amount: types.number,
  status: types.number,
  currency_code: types.string,
})

export const InvoiceDetailsModel = types.model('InvoiceDetailsModel', {
  id: types.identifierNumber,
  invoice_number: types.maybeNull(types.string),
  status: types.number,
  date: types.maybeNull(types.string),
  due_date: types.maybeNull(types.string),
  currency_code: types.string,
  total_amount: types.number,
  sub_total_amount: types.number,
  tax_percentage: types.maybeNull(types.number),
  total_tax_amount: types.number,
  total_transportation_amount: types.number,
  payment: types.maybeNull(InvoicePaymentModel),
  items: types.array(
    types.model({
      id: types.number,
      order_id: types.maybeNull(types.number),
      title: types.maybeNull(types.string),
      location: types.string,
      total_shifts: types.number,
      total_hours: types.number,
      total_sps: types.number,
      sps: types.array(
        types.model({
          id: types.number,
          full_name: types.string,
          image_url: types.string,
          shifts_count: types.maybeNull(types.number),
          hours: types.maybeNull(types.number),
          earnings: types.maybeNull(types.number),
        }),
      ),
    }),
  ),
})

export type InvoiceDetails = SnapshotIn<typeof InvoiceDetailsModel>
export type InvoiceDetailsSnapshot = SnapshotOut<typeof InvoiceDetailsModel>

export enum InvoiceStatusType {
  Draft = 1,
  Sent = 2,
  Paid = 3,
  Delayed = 4,
  Blocked = 5,
  Processed = 6,
  Voided = 7,
}

export enum InvoiceType {
  BillingInvoice = 1,
  SalesInvoice = 2,
  CreditNote = 3,
  BillingCreditNote = 4,
}

export const pendingStatuses = [InvoiceStatusType.Sent, InvoiceStatusType.Blocked, InvoiceStatusType.Delayed]

export const InvoiceModel = types.model('InvoiceModel', {
  id: types.identifierNumber,
  invoice_number: types.maybeNull(types.string),
  status: types.number,
  date: types.maybeNull(types.string),
  due_date: types.maybeNull(types.string),
  total_amount: types.number,
  currency_code: types.string,
  order_ids: types.array(types.number),
})

export const InvoiceStoreModel = types.model('InvoiceStoreModel', {
  map: types.map(InvoiceModel),
  detailsMap: types.map(InvoiceDetailsModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const invoiceActions = (self: DataStore) => ({
  getInvoices: flow(function* (
    pointOfContactId?: number | null,
    locationId?: number | null,
    designationId?: number | null,
    dateFrom?: string | null,
    dateTo?: string | null,
    orderId?: number | null,
    isOverdue?: boolean,
    page?: number,
  ) {
    if (page === 1) {
      self.invoices.map.clear()
      self.invoices.nextPage = 1
    }
    const nextPage = self.invoices.nextPage
    if (nextPage) {
      let url = `client/invoices?page=${nextPage}`
      if (pointOfContactId) {
        url += `&poc_id=${pointOfContactId}`
      }
      if (locationId) {
        url += `&location_id=${locationId}`
      }
      if (designationId) {
        url += `&designation_id=${designationId}`
      }
      if (dateFrom) {
        url += `&date_from=${dateFrom}`
      }
      if (dateTo) {
        url += `&date_to=${dateTo}`
      }
      if (orderId) {
        url += `&order_id=${orderId}`
      }
      if (isOverdue) {
        url += `&is_overdue=${Number(isOverdue)}`
      }

      const { list: invoicesList, paginator } = (yield self.request('get', url)) as {
        list: InvoiceSnapshot[]
        paginator: Paginator
      }

      invoicesList.forEach((invoice: InvoiceSnapshot) => {
        self.invoices.map.set(String(invoice.id), invoice)
      })
      self.invoices.nextPage = paginator.next_page
    }
  }),

  getInvoice: flow(function* (id: string) {
    const invoiceWithDetails = (yield self.request('get', `client/invoices/${id}`)) as InvoiceDetailsSnapshot
    self.invoices.detailsMap.set(String(id), invoiceWithDetails)
  }),

  getInvoiceJobShfits: flow(function* (params: { invoiceItemId: string; ogrammerId: string }) {
    return (yield self.request(
      'get',
      `client/invoice-items/${params.invoiceItemId}/shifts/${params.ogrammerId}`,
    )) as InvoiceJobShifts
  }),

  downloadInvoice: flow(function* (id: string) {
    return (yield self.request('get', `client/invoices/${id}/download`, undefined, {
      responseType: 'blob',
    })) as BlobPart
  }),

  reportIssue: flow(function* (params: { id: string; message: string }) {
    yield self.request('post', `client/invoices/${params.id}/issue`, {
      message: params.message,
    })
  }),
})

export const invoiceViews = (self: DataStore) => ({
  get invoiceList() {
    return Array.from(self.invoices.map.values())
  },

  getInvoiceDetails(id: string) {
    return self.invoices.detailsMap.get(id)
  },

  getInvoicesByStatus(statusId: InvoiceStatusType) {
    return Array.from(self.invoices.map.values()).filter(item => item.status === statusId)
  },
})

export type Invoice = SnapshotIn<typeof InvoiceModel>
export type InvoiceSnapshot = SnapshotOut<typeof InvoiceModel>
export type InvoicePaymentSnapshot = SnapshotOut<typeof InvoicePaymentModel>
