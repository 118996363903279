import { Separator } from '../app-layout'
import { OgramModal } from '../ogram-modal/ogram-modal'
import './error-modal.scss'

interface ErrorModalProps {
  isModalVisible: boolean
  onClose: () => void
  onConfirm?: () => void
  errorMessage: string
}

const ErrorModal = ({ isModalVisible, onClose, onConfirm, errorMessage }: ErrorModalProps) => {
  return (
    <OgramModal className="ErrorModal" modalVisible={isModalVisible} onClose={onClose}>
      <h3 className="ErrorModal__title">Error</h3>
      <p className="ErrorModal__message">{errorMessage}</p>
      <Separator.Vertical height={30} />
      <button onClick={onConfirm} className="ErrorModal__ok">
        Okay
      </button>
    </OgramModal>
  )
}

export default ErrorModal
