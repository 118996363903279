import { FormikProps } from 'formik'

import { DraftJobInstance } from '../../models-ui/order/store/draft-job'
import { StaffAmount } from './ui/components/staff-amount/staff-amount'
import { Dates } from './ui/components/dates/dates'
import { Location } from './ui/components/location/location'
import { ScheduleType } from './ui/components/schedule-type/schedule-type'
import { WorkRequirements } from './ui/components/requirements/requirements'
import { DaysSelect } from './ui/components/time/days-select'
import { Description } from './ui/components/description/description'
import { JobDraftExperience } from './ui/components/experience/experience'
import { JobDraftUniform } from './ui/components/uniform/uniform'
import { JobDraftPreferences } from './ui/components/preferences/preferences'
import { JobDraftLanguages } from './ui/components/languages/languages'
import { JobDraftGender } from './ui/components/gender/gender'
import { JobDraftDocuments } from './ui/components/documents/documents'
import { JobDraftInterview } from './ui/components/interview/interview'
import { JobDraftAccreditation } from './ui/components/accreditation/accreditation'
import { JobDraftPOC } from './ui/components/manager-contact/manager-contact'
import { JobDraftBoarding } from './ui/components/boarding/boarding'
import { JobDraftCategory } from '../../pages/order/job-draft/job-draft-category/job-draft-category'
import { BusinessLocationForm } from '../../models/location'
import { JobDraftPublicTransportAccessibility } from './ui/components/public-transport-accessibility/public-transport-accessibility'
import { DraftJobFields } from '../../models-ui/order/store/job-fields/fields'
import { ShiftTimingsPrompt } from './ui/components/shift-timings-prompt/shift-timings-prompt'
import { SameTime } from './ui/components/same-time/same-time'
import { InvitedSPs } from './ui/components/invited-sps/invited-sps'

export type FormComponentsPossibleParams = {
  orderId?: string
  locationFormRef?: React.RefObject<FormikProps<BusinessLocationForm>> | null
  onLocationsLoaded?: (hasLocations: boolean) => void
  onLocationFormSubmit?: () => void
  job: DraftJobInstance
}

export const formComponents: Record<DraftJobFields, (params: FormComponentsPossibleParams) => React.ReactNode> = {
  [DraftJobFields.category]: params => <JobDraftCategory orderId={params.orderId} />,
  [DraftJobFields.quantity]: params => <StaffAmount job={params.job} />,
  [DraftJobFields.location]: params => (
    <Location
      locationFormRef={params.locationFormRef}
      onLocationsLoaded={params.onLocationsLoaded}
      onLocationFormSubmit={params.onLocationFormSubmit}
      job={params.job}
    />
  ),
  [DraftJobFields.transport]: params => <JobDraftPublicTransportAccessibility job={params.job} />,
  [DraftJobFields.dateBoundaries]: params => <Dates job={params.job} />,
  [DraftJobFields.scheduleType]: params => <ScheduleType job={params.job} />,
  [DraftJobFields.sameOrDynamicTimingPrompt]: params => <ShiftTimingsPrompt job={params.job} />,
  [DraftJobFields.sameTimeSchedule]: params => <SameTime job={params.job} />,
  [DraftJobFields.daysSelect]: params => <DaysSelect job={params.job} />,
  [DraftJobFields.requirements]: params => <WorkRequirements job={params.job} />,
  [DraftJobFields.description]: params => <Description job={params.job} />,
  [DraftJobFields.contact]: params => <JobDraftPOC job={params.job} />,
  [DraftJobFields.experience]: params => <JobDraftExperience job={params.job} />,
  [DraftJobFields.dresscode]: params => <JobDraftUniform job={params.job} />,
  [DraftJobFields.invitedSPs]: params => <InvitedSPs job={params.job} />,
  [DraftJobFields.preferences]: params => <JobDraftPreferences job={params.job} />,
  [DraftJobFields.languages]: params => <JobDraftLanguages job={params.job} />,
  [DraftJobFields.gender]: params => <JobDraftGender job={params.job} />,
  [DraftJobFields.documents]: params => <JobDraftDocuments job={params.job} />,
  [DraftJobFields.videoInterviews]: params => <JobDraftInterview job={params.job} />,
  [DraftJobFields.accreditation]: params => <JobDraftAccreditation job={params.job} />,
  [DraftJobFields.boarding]: params => <JobDraftBoarding job={params.job} />,
  [DraftJobFields.accommodation]: () => null,
}

export const renderFormComponent = (key: DraftJobFields, params: FormComponentsPossibleParams) => {
  return formComponents[key](params)
}
