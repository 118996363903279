import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'

import calendarIcon from '../assets/calendar-icon.svg'
import downloadIcon from '../assets/download-icon.svg'

type InputProps = {
  isLoading: boolean
  setShowCalendar: (value: boolean) => void
  dateFrom: string | null
  dateTo: string | null
  startBalance: number | null
  endBalance: number | null
  isDownloadingAccountStatement: boolean
  downloadAccountStatement: () => void
}

export const HeaderBottomComponent = ({
  isLoading,
  setShowCalendar,
  dateFrom,
  dateTo,
  startBalance,
  endBalance,
  isDownloadingAccountStatement,
  downloadAccountStatement,
}: InputProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return null
  }

  return (
    <div className="AccountStatement__HeaderBottom">
      <div className="AccountStatement__HeaderBottom__top" onClick={() => setShowCalendar(true)}>
        <span className="AccountStatement__HeaderBottom__datesTitle">
          {t('account_statement.from')} <strong>{moment(dateFrom).format('MMM DD')}</strong> {t('account_statement.to')}{' '}
          <strong>{moment(dateTo).format('MMM DD')}</strong>
        </span>
        <img src={calendarIcon} />
      </div>
      <div className="AccountStatement__HeaderBottom__bottom">
        <span className="AccountStatement__HeaderBottom__amountsTitle">
          {t('account_statement.starts_from')}{' '}
          <strong>
            {t('account_statement.currency')} {startBalance}
          </strong>{' '}
          {t('account_statement.to')}{' '}
          <strong>
            {t('account_statement.currency')} {endBalance}
          </strong>
        </span>
        {isDownloadingAccountStatement ? (
          <span className="AccountStatement__HeaderBottom__download__title">{t('account_statement.downloading')}</span>
        ) : (
          <div className="AccountStatement__HeaderBottom__download" onClick={downloadAccountStatement}>
            <img src={downloadIcon} />
            <span className="AccountStatement__HeaderBottom__download__title">{t('account_statement.download')}</span>
          </div>
        )}
      </div>
    </div>
  )
}
