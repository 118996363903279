import './calendar-header.scss'
import { CalendarLocationFilter } from '../calendar-location-filter/calendar-location-filter'
import { WeekToggle } from '../week-toggle/week-toggle'
import { CalendarDesignationFilter } from '../calendar-designation-filter/calendar-designation-filter'
import { TrackedEvent, trackingService } from '../../../../../../services/tracking-service'

type CalendarHeader = {
  month: string
  year: string
  locationIds: number[]
  setLocationIds: (values: number[]) => void
  designationIds: number[]
  setDesignationIds: (values: number[]) => void
  onPreviousWeekPress: () => void
  onNextWeekPress: () => void
  selectedDate: Date
  setSelectedDate: (value: Date) => void
  setCurrentWeek: () => void
}

export function CalendarHeader({
  month,
  year,
  onPreviousWeekPress,
  onNextWeekPress,
  locationIds,
  setLocationIds,
  designationIds,
  setDesignationIds,
  selectedDate,
  setSelectedDate,
  setCurrentWeek,
}: CalendarHeader) {
  return (
    <div className="CalendarHeader__container">
      <div className="CalendarHeader__top mobile-only">
        <span
          className="CalendarHeader__label"
          onClick={() => trackingService.track(TrackedEvent.TimesheetMonthTitleClicked)}
        >
          {month} <span className="CalendarHeader__label--dimmed">{year}</span>
        </span>
        <WeekToggle
          onPreviousWeekPress={onPreviousWeekPress}
          onNextWeekPress={onNextWeekPress}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCurrentWeek={setCurrentWeek}
        />
      </div>
      <div className="CalendarHeader">
        <div className="CalendarHeader__left">
          <span
            className="CalendarHeader__label desktop-only"
            onClick={() => trackingService.track(TrackedEvent.TimesheetMonthTitleClicked)}
          >
            {month} <span className="CalendarHeader__label--dimmed">{year}</span>
          </span>
          <WeekToggle
            onPreviousWeekPress={onPreviousWeekPress}
            onNextWeekPress={onNextWeekPress}
            additionalContainerClassName="desktop-only"
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            setCurrentWeek={setCurrentWeek}
          />
        </div>
        <div className="CalendarHeader__right">
          <CalendarLocationFilter locationIds={locationIds} setLocationIds={setLocationIds} />
          <CalendarDesignationFilter designationIds={designationIds} setDesignationIds={setDesignationIds} />
        </div>
      </div>
    </div>
  )
}
