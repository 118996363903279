import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const WorkCategoryModel = types
  .model('WorkCategory', {
    id: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get workCategoryId() {
      return self.id
    },
  }))
  .actions(self => ({
    set(categoryId: number) {
      self.id = categoryId
    },

    validate() {
      if (self.id === null) {
        self.error = t('job_draft_details.work_category_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createCategoryMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.category) {
      return abort(targetInstance.category.validate())
    }
    return next(call)
  })
}

export type WorkCategoryInstance = Instance<typeof WorkCategoryModel>
