import { Trans, useTranslation } from 'react-i18next'

import infoModalImg from './assets/info-modal-img.svg'
import { Button } from '../../../../../components/button/button'
import { OgramModal } from '../../../../../components/ogram-modal/ogram-modal'

type InputProps = {
  isVisible: boolean
  setIsVisible: (value: boolean) => void
}

export const CheckInRestrictionsModal = ({ isVisible, setIsVisible }: InputProps) => {
  const { t } = useTranslation()

  return (
    <OgramModal
      modalVisible={isVisible}
      onClose={() => setIsVisible(false)}
      contentStyle={{ overflow: 'hidden', maxWidth: '530px', padding: '54px 12px 33px 12px' }}
    >
      <div className="LocationForm__infoModal">
        <img src={infoModalImg} />
        <span className="LocationForm__infoModal__title">{t('location_list.check_in_restrictions_title')}</span>
        <p className="LocationForm__infoModal__content">
          <Trans i18nKey="location_list.restrictions_info_modal_text" components={{ strong: <strong />, br: <br /> }} />
        </p>
        <Button type="button" className="LocationForm__infoModal__button" onClick={() => setIsVisible(false)}>
          Okay
        </Button>
      </div>
    </OgramModal>
  )
}
