import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useQuery, useStore } from '../../../../utils/mst-hooks'

export const Welcome = observer(() => {
  const { profile, getProfile } = useStore().data
  const { isLoading: profileLoading } = useQuery(getProfile)
  const { t } = useTranslation()

  return profileLoading ? null : (
    <h1 className="Home__title">
      {t('home.hello')}, <span className="highlighted">{profile?.first_name}</span>
      👋
    </h1>
  )
})
