import { useTranslation } from 'react-i18next'
import './skill-match.scss'

const MIN_SKILL_MATCH_TO_RENDER_COMPONENT = 50

export const SkillMatch = ({
  skillMatch,
  skillIcons,
  className,
}: {
  skillMatch: number
  skillIcons: string[]
  className?: string
}) => {
  const { t } = useTranslation()

  if (skillMatch <= MIN_SKILL_MATCH_TO_RENDER_COMPONENT || skillMatch === 100) {
    return null
  }

  return (
    <div className={`SkillMatch ${className ?? ''}`}>
      <span className="SkillMatch__title">
        {t('job_cherry_picking.skill_match')}: <span className="SkillMatch__percent">{skillMatch}%</span>
      </span>
      <div className="SkillMatch__images">
        {skillIcons.map((icon, key) => (
          <img key={key} src={icon} />
        ))}
      </div>
    </div>
  )
}
