import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import Rating from '../../../rating/rating'
import './sp-item.scss'
import { Separator } from '../../../app-layout'

export const SpItem = observer(
  ({
    sp,
    changeRating,
  }: {
    sp: {
      id: number
      image_url: string | null
      name: string
    }
    changeRating: (rating: number) => void
  }) => {
    const { t } = useTranslation()
    const [rating, setRating] = useState<number | null>(null)

    return (
      <div key={sp.id} className="SPItem">
        <div className="SPItem__body">
          <img className="SPItem__body__image" src={String(sp.image_url)} />
          <div className="SPItem__content">
            <div className="SPItem__titleContainer">
              <span className="SPItem__title">{sp.name}</span>
            </div>
            <Separator.Vertical height={10} />
            <Rating
              rating={Number(rating)}
              size={25}
              editable
              onRatingChange={(value: number) => {
                setRating(value)
                changeRating(value)
              }}
            />
            {rating !== null && (
              <span
                className={`SPItem__ratingNote${
                  rating > 3 ? ' SPItem__ratingNote--positive' : ' SPItem__ratingNote--negative'
                }`}
              >
                {rating > 3 ? t('rating_popup.will_be_prioritized') : t('rating_popup.will_not_be_assigned')}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  },
)
