import { Fragment, FunctionComponent, useState } from 'react'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useDelayedQuery, useStore } from '../../../../../../../utils/mst-hooks'
import { PaymentIntent } from '../../../../../../../models/payment'
import { confirmNewCardPayment } from '../../../../../../../services/stripe'
import { UIProps } from './types'
import { routes } from '../../../../../../../routes'
import { useRedirect } from '../../../../../../../services/router'
import { withErrorBoundary } from '../../../../../../../components/error/with-error-boundary'

export function createPayWithCardFormContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(
      ({
        paymentId,
        paymentIntent,
        paymentOptions,
      }: {
        paymentId: number
        paymentIntent: PaymentIntent
        paymentOptions?: string
      }) => {
        const [isPaymentFormLoaded, setIsPaymentFormLoaded] = useState<boolean>(false)
        const [isPaymentFormComplete, setIsPaymentFormComplete] = useState<boolean>(false)
        const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false)
        const [shouldSaveCard, setShouldSaveCard] = useState<boolean>(true)

        const { t } = useTranslation()
        const stripe = useStripe()
        const elements = useElements()
        const redirect = useRedirect()

        const { processPayment, resetPaymentStatus, resetPayment } = useStore().data
        const { enableQuery: processPaymentQuery } = useDelayedQuery(() => processPayment(paymentId), {
          onSuccess: (result) => {
            tryToConfirmPayment(true)
          },
        })
        const { enableQuery: resetPaymentStatusQuery } = useDelayedQuery(() => resetPaymentStatus(paymentId))

        const { enableQuery: resetPaymentQuery } = useDelayedQuery(() => resetPayment(paymentId), {
          onSuccess: (result) => {
            toast.success(t('payment.card_payment_has_been_canceled'))
            redirect(routes.paymentMethodsList, { paymentId: String(paymentId) })
          },
        })

        const tryToConfirmPayment = async (resetPaymentOnFailure: boolean) => {
          if (!elements) {
            return
          }

          const confirmPaymentErrorHandler = (errorMessage: string) => {
            if (resetPaymentOnFailure) {
              resetPaymentStatusQuery()
            }
            toast.error(errorMessage)
          }

          await confirmNewCardPayment(
            stripe,
            elements,
            paymentId,
            shouldSaveCard,
            setIsPaymentProcessing,
            confirmPaymentErrorHandler,
            paymentOptions,
          )
        }

        const pay = () => {
          setIsPaymentProcessing(true)
          processPaymentQuery()
        }

        const getFormHandler = () => {
          if (isPaymentProcessing) {
            return () => {}
          }

          return isPaymentFormComplete ? pay : () => tryToConfirmPayment(false)
        }

        const getPayButtonText = () => {
          if (!isPaymentProcessing) {
            return (
              <Fragment>
                Pay{' '}
                <strong>
                  {paymentIntent.currency_code} {paymentIntent.amount}
                </strong>
              </Fragment>
            )
          }

          return t('payment.processing_running')
        }

        return (
          <UIComponent
            setIsPaymentFormComplete={setIsPaymentFormComplete}
            setIsPaymentFormLoaded={setIsPaymentFormLoaded}
            setShouldSaveCard={setShouldSaveCard}
            shouldSaveCard={shouldSaveCard}
            isPaymentFormLoaded={isPaymentFormLoaded}
            formHandler={getFormHandler()}
            payButtonText={getPayButtonText()}
            resetPayment={resetPaymentQuery}
          />
        )
      },
    ),
  )
}
