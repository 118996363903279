import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const jobDurationPage = {
  title: t('order_creation_onboarding.job_duration_title'),
  content: [
    { image: { src: images.jobDurationImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.job_duration') },
  ],
}
