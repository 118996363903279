import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { images } from '../../assets'
import { Separator } from '../../../../../../components/app-layout'
import { InvoicePaymentSnapshot } from '../../../../../../models/invoice'
import { getFormattedDate, getPaymentDetailsStatusLabel } from '../../../../../payment/utils'
import { useOnPayHandler } from '../../../../../../services/payment-service'
import { PaymentStatus } from '../../../../../../models/payment'

export const Payment = ({ payment }: { payment: InvoicePaymentSnapshot }) => {
  const { t } = useTranslation()
  const onPayClick = useOnPayHandler()

  return (
    <Fragment>
      <div className="Invoice__sectionHeader">
        <img src={images.detailsImg} className="Invoice__sectionHeader__detailsImg " />
        <Separator.Horizontal width={8} />
        <span className="Invoice__sectionHeader__title">{t('payment.payment')}</span>
      </div>
      <Separator.Vertical height={14} />
      <div className="Invoice__cardContainer">
        <div className="Invoice__cardContainer__row">
          <span className="Invoice__cardContainer__row__label">{t('payment.payment_number')}</span>
          <span className="Invoice__cardContainer__row__value">{payment.payment_number}</span>
        </div>
        <div className="Invoice__cardContainer__row">
          <span className="Invoice__cardContainer__row__label">{t('payment.amount')}</span>
          <span className="Invoice__cardContainer__row__value">
            {payment.amount} {payment.currency_code}
          </span>
        </div>
        <div className="Invoice__cardContainer__row">
          <span className="Invoice__cardContainer__row__label">{t('payment.status')}</span>
          <span className="Invoice__cardContainer__row__value">
            {t(getPaymentDetailsStatusLabel(String(payment.status)))}
          </span>
        </div>
        <div className="Invoice__cardContainer__row last">
          <span className="Invoice__cardContainer__row__label">{t('payment.due_date')}</span>
          <span className="Invoice__cardContainer__row__value">{getFormattedDate(String(payment.due_date))}</span>
        </div>
        {payment.status === Number(PaymentStatus.Pending) && (
          // TODO(A): fix wrong payment type
          // @ts-ignore
          <button onClick={() => onPayClick(payment)} className="Invoice__Payment__payButton">
            {t('invoice.pay_now')}
          </button>
        )}
      </div>
      <Separator.Vertical height={14} />
    </Fragment>
  )
}
