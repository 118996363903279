import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import titleIcon from '../../../../payment-details/ui/assets/title-icon.svg'
import { copyToClipboard } from '../../../../../../services/copy-to-clipboard'
import copyIcon from '../../../../../../components/assets/copy.svg'
import './bank-account-details.scss'
import { usePaymentIntent } from '../../../../../../services/stripe'
import { PaymentMethod } from '../../../../../../models/payment'
import LoadingSpinner from '../../../../../../components/loading-spinner/loading-spinner'
import { withErrorBoundary } from '../../../../../../components/error/with-error-boundary'

export const BankAccountDetails = withErrorBoundary(
  observer(({ paymentId }: { paymentId: number }) => {
    const { paymentIntent } = usePaymentIntent(paymentId, PaymentMethod.Wire)
    const { t } = useTranslation()

    if (!paymentIntent) {
      return <LoadingSpinner />
    }

    return (
      <>
        <div className="BankAccountDetails__title-container">
          <img src={titleIcon} />
          <span>{t('payment.wire_transfer_details')}</span>
        </div>
        <div className="BankAccountDetails__values">
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">{t('payment.wire_transfer_amount')}</span>
            <span className="BankAccountDetails__values__valueContainer__value">
              <strong>
                {paymentIntent.currency_code} {paymentIntent.amount}
              </strong>
            </span>
          </div>
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">
              {t('payment.wire_transfer_bank_account_name')}
            </span>
            <div className="BankAccountDetails__values__valueContainer__valueBox">
              <span className="BankAccountDetails__values__valueContainer__value">
                {paymentIntent.meta.bank_account_name}
              </span>
              <img
                onClick={() => copyToClipboard(String(paymentIntent.meta.bank_account_name), t('payment.text_copied'))}
                className="BankAccountDetails__values__valueContainer__value__icon"
                src={copyIcon}
              />
            </div>
          </div>
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">
              {t('payment.wire_transfer_bank_account_bank_name')}
            </span>
            <div className="BankAccountDetails__values__valueContainer__valueBox">
              <span className="BankAccountDetails__values__valueContainer__value">
                {paymentIntent.meta.bank_account_bank_name}
              </span>
              <img
                onClick={() =>
                  copyToClipboard(String(paymentIntent.meta.bank_account_bank_name), t('payment.text_copied'))
                }
                className="BankAccountDetails__values__valueContainer__value__icon"
                src={copyIcon}
              />
            </div>
          </div>
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">
              {t('payment.wire_transfer_bank_account_number')}
            </span>
            <div className="BankAccountDetails__values__valueContainer__valueBox">
              <span className="BankAccountDetails__values__valueContainer__value">
                {paymentIntent.meta.bank_account_number}
              </span>
              <img
                onClick={() =>
                  copyToClipboard(String(paymentIntent.meta.bank_account_number), t('payment.text_copied'))
                }
                className="BankAccountDetails__values__valueContainer__value__icon"
                src={copyIcon}
              />
            </div>
          </div>
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">
              {t('payment.wire_transfer_bank_account_iban')}
            </span>
            <div className="BankAccountDetails__values__valueContainer__valueBox">
              <span className="BankAccountDetails__values__valueContainer__value">
                {paymentIntent.meta.bank_account_iban}
              </span>
              <img
                onClick={() => copyToClipboard(String(paymentIntent.meta.bank_account_iban), t('payment.text_copied'))}
                className="BankAccountDetails__values__valueContainer__value__icon"
                src={copyIcon}
              />
            </div>
          </div>
          <div className="BankAccountDetails__values__valueContainer">
            <span className="BankAccountDetails__values__valueContainer__key">
              {t('payment.wire_transfer_bank_account_bank_address')}
            </span>
            <div className="BankAccountDetails__values__valueContainer__valueBox">
              <span className="BankAccountDetails__values__valueContainer__value">
                {paymentIntent.meta.bank_account_bank_address}
              </span>
              <img
                onClick={() =>
                  copyToClipboard(String(paymentIntent.meta.bank_account_bank_address), t('payment.text_copied'))
                }
                className="BankAccountDetails__values__valueContainer__value__icon"
                src={copyIcon}
              />
            </div>
          </div>
        </div>
      </>
    )
  }),
)
