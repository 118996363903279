import { useState, createRef } from 'react'
import { assertType } from 'assertio'
import { observer } from 'mobx-react'

import docIcon from './assets/doc.svg'
import uploadIcon from './assets/upload.svg'
import photoIcon from './assets/photo.svg'
import './upload-file.scss'

export type InputFile = {
  url: string
  data: File
} | null

export const UploadFile = observer(
  (props: {
    placeholder: React.ReactNode
    value: string | null | undefined
    onChange?: (newValue: File) => void
    accept?: string
    required?: boolean
  }) => {
    const [file, setFile] = useState(props.value)
    const inputRef = createRef<HTMLInputElement>()
    return (
      <div className="UploadFile">
        {file ? (
          <div className="UploadFile__image-container">
            <img src={file} onError={() => setFile(docIcon)} className="UploadFile__image" />
            <img src={photoIcon} className="UploadFile__photo-icon" />
          </div>
        ) : (
          <img src={uploadIcon} className="UploadFile__placeholderImage" />
        )}
        {!file && props.placeholder}
        <input
          className="UploadFile__input"
          type="file"
          accept={props.accept ?? '.jpg,.jpeg,.png'}
          ref={inputRef}
          onChange={(f) => {
            const node = f.target
            assertType<{ files: FileList }>(node)
            if (node.files.length > 0) {
              setFile(URL.createObjectURL(node.files[0]))
              props.onChange?.(node.files[0])
            }
          }}
        />
      </div>
    )
  },
)
