import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics'

import { marketService } from './market-service'

export enum TrackedEvent {
  LeanPaymentMethodChosen = 'Lean Payment Method Chosen',
  LeanPaymentPaid = 'Lean Payment Paid',
  LeanPaymentAuthorizationInitiated = 'Lean Payment Authorization Initiated',
  ViewedPaymentCompleted = 'Viewed Payment Completed',
  ViewedNotSubmittedOrderSummary = 'Viewed Not Submitted Order Summary',
  ViewedSubmittedOrderSummary = 'Viewed Submitted Order Summary',
  ViewedConfirmOrderSummary = 'Viewed Confirm Order Summary',
  ViewedInstallProgressiveAppBanner = 'Viewed Install Progressive App Banner',
  AppInstalledAsProgressive = 'App Installed As Progressive',
  ChiefCongratulationsPopupShown = 'Chief Congratulations Popup Shown',

  RatingSet = 'RatingSet',
  FavoriteAdded = 'FavoriteAdded',
  FavoriteRemoved = 'FavoriteRemoved',

  PastWorkersPageOpened = 'PastWorkersPageOpened',
  PastWorkersListFiltersApplied = 'PastWorkersListFiltersApplied',
  PastWorkersListPaginationUsed = 'PastWorkersListPaginationUsed',
  PastWorkersListSortUsed = 'PastWorkersListSortUsed',
  PastWorkersProfileOpened = 'PastWorkersProfileOpened',

  PreferredWorkerAddedToOrder = 'PreferredWorkerAddedToOrder',
  PreferredWorkerRemovedFromOrder = 'PreferredWorkerRemovedFromOrder',
  PreferredWorkerSearchStarted = 'PreferredWorkerSearchStarted',
  PreferredWorkerScrolled = 'PreferredWorkerScrolled',
  PreferredWorkerTriedToAddMoreThanPossible = 'PreferredWorkerTriedToAddMoreThanPossible',

  ExtendedOrderFromOrdersList = 'ExtendedOrderFromOrdersList',
  ExtendedOrderFromOrderDetails = 'ExtendedOrderFromOrderDetails',
  ExtendedOrderFromAllJobs = 'ExtendedOrderFromAllJobs',

  RatingPopupAfterAttendanceReviewShown = 'RatingPopupAfterAttendanceReviewShown',
  RatingSetAfterAttendanceReview = 'RatingSetAfterAttendanceReview',
  RatingSetAfterAttendanceReviewAtLeastOnce = 'RatingSetAfterAttendanceReviewAtLeastOnce',
  RatingSetAfterAttendanceReviewForAllOGs = 'RatingSetAfterAttendanceReviewForAllOGs',

  HiringJobWasPrefilled = 'HiringJobWasPrefilled',
  HiringPrefilledJobWasCleared = 'HiringPrefilledJobWasCleared',

  TimesheetWeekBackClicked = 'TimesheetWeekBackClicked',
  TimesheetWeekForwardClicked = 'TimesheetWeekForwardClicked',
  TimesheetLocationFilterUsed = 'TimesheetLocationFilterUsed',
  TimesheetDesignationFilterUsed = 'TimesheetDesignationFilterUsed',
  TimesheetStartTimeBulkChanged = 'TimesheetStartTimeBulkChanged',
  TimesheetEndTimeBulkChanged = 'TimesheetEndTimeBulkChanged',
  TimesheetSPStartTimeChanged = 'TimesheetSPStartTimeChanged',
  TimesheetSPEndTimeChanged = 'TimesheetSPEndTimeChanged',
  TimesheetSlotOptionsViewed = 'TimesheetSlotOptionsViewed',
  TimesheetCancelClicked = 'TimesheetCancelClicked',
  TimesheetSaveClicked = 'TimesheetSaveClicked',
  TimesheetSlotOptionCheckInClicked = 'TimesheetSlotOptionCheckInClicked',
  TimesheetSlotOptionCheckOutClicked = 'TimesheetSlotOptionCheckOutClicked',
  TimesheetSlotOptionExtendShiftClicked = 'TimesheetSlotOptionExtendShiftClicked',
  TimesheetSlotOptionCancelClicked = 'TimesheetSlotOptionCancelClicked',
  TimesheetSlotOptionPhoneClicked = 'TimesheetSlotOptionPhoneClicked',
  TimesheetSlotOptionWhatsappClicked = 'TimesheetSlotOptionWhatsappClicked',
  TimesheetSlotEmptySlotClicked = 'TimesheetSlotEmptySlotClicked',
  TimesheetSlotOptionSPSelectClicked = 'TimesheetSlotOptionSPSelectClicked',
  TimesheetCancellationAllShiftsSelected = 'TimesheetCancellationAllShiftsSelected',
  TimesheetCancellationConfirmed = 'TimesheetCancellationConfirmed',
  TimesheetCancellationCancelled = 'TimesheetCancellationCancelled',
  TimesheetStatusLabelClicked = 'TimesheetStatusLabelClicked',
  TimesheetMonthTitleClicked = 'TimesheetMonthTitleClicked',
  TimesheetCurrentWeekClicked = 'TimesheetCurrentWeekClicked',
  TimesheetCalendarSwitcherOpened = 'TimesheetCalendarSwitcherOpened',
  TimesheetWeekSwitchedViaCalendar = 'TimesheetWeekSwitchedViaCalendar',
  TimesheetDateTitleClicked = 'TimesheetDateTitleClicked',
  TimesheetRowExpanded = 'TimesheetRowExpanded',
  TimesheetOrder3DotsClicked = 'TimesheetOrder3DotsClicked',
  TimesheetSPProfileClicked = 'TimesheetSPProfileClicked',

  HomeCarouselHireNowClicked = 'HomeCarouselHireNowClicked',
  HomeCarouselRegisterCorporateClicked = 'HomeCarouselRegisterCorporateClicked',
  HomeCarouselInviteStaffStartClicked = 'HomeCarouselInviteStaffStartClicked',
  HomeCarouselInviteStaffDismissClicked = 'HomeCarouselInviteStaffDismissClicked',

  HomeStatsPendingPaymentsClicked = 'HomeStatsPendingPaymentsClicked',
  HomeStatsShiftsAttendanceClicked = 'HomeStatsShiftsAttendanceClicked',
  HomeStatsOrdersToConfirmClicked = 'HomeStatsOrdersToConfirmClicked',
  HomeStatsRatingsClicked = 'HomeStatsRatingsClicked',
  HomeStatsApplicantsClicked = 'HomeStatsApplicantsClicked',

  StickyPanelPendingPaymentsShown = 'StickyPanelPendingPaymentsShown',
  StickyPanelPendingPaymentsClicked = 'StickyPanelPendingPaymentsClicked',
  StickyPanelPendingOrdersShown = 'StickyPanelPendingOrdersShown',
  StickyPanelPendingOrdersClicked = 'StickyPanelPendingOrdersClicked',
  StickyPanelPendingAttendanceShown = 'StickyPanelPendingAttendanceShown',
  StickyPanelPendingAttendanceClicked = 'StickyPanelPendingAttendanceClicked',
  StickyPanelPendingRatingsShown = 'StickyPanelPendingRatingsShown',
  StickyPanelPendingRatingsClicked = 'StickyPanelPendingRatingsClicked',
}

export enum Conversion {
  SignInClicked = 'AW-764793267/JjZKCNGOwcwYELOj1-wC',
  SignUpClicked = 'AW-764793267/Axi5CN2hgM4YELOj1-wC',
}

type GTagFn = (type: string, subtype: string, payload?: Record<string, unknown>) => void
type MetaConversionFn = (type: string, eventName: string, payload?: Record<string, unknown>) => void

class TrackingService {
  email: string | null = null

  identifyUser = (userId: number) => {
    setUserId(getAnalytics(), String(userId))
  }

  assignUserProperties = (clientId: number, roles: number[], email: string) => {
    setUserProperties(getAnalytics(), {
      clientId: String(clientId),
      roles: roles.join(','),
      mainRole: String(roles[0]),
      market: String(marketService.getCurrentMarketCode()),
    })

    this.email = email
  }

  resetUser = () => {
    setUserId(getAnalytics(), '')
  }

  track = (event: string, properties?: Record<string, unknown>) => {
    if (this.email?.endsWith('@ogram.co')) {
      return
    }

    logEvent(getAnalytics(), event, properties)
  }

  conversion = (event: Conversion) => {
    if ('gtag' in window) {
      ;(window.gtag as GTagFn)('event', 'conversion', { 'send_to': event, 'event_callback': () => {} })
    }
  }

  metaConversion = (type: 'track' | 'trackCustom', event: string, payload?: Record<string, unknown>) => {
    if ('fbq' in window) {
      ;(window.fbq as MetaConversionFn)(type, event, payload)
    }
  }
}

export const trackingService = new TrackingService()
