import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import mastercardLogo from '../assets/mastercard.svg'
import visaLogo from '../assets/visa.svg'
import newCardLogo from '../assets/new-card.svg'
import wireLogo from '../assets/wire.svg'
import arrowRight from '../assets/arrow-right.svg'
import leanLogo from '../assets/lean_2.svg'
import lean from '../assets/lean.svg'
import leanTransparent from '../assets/lean_transparent.svg'
import { CardBrand, LeanSetupStatus, PaymentMethod as PaymentMethodType } from '../../../../../models/payment'
import { OgramModal } from '../../../../../components/ogram-modal/ogram-modal'
import { isLeanSetupInitiated, useIsLeanPaymentsEnabled } from '../../../../../services/payment-service'
import { Separator } from '../../../../../components/app-layout'
import { getFormattedDate } from '../../../utils'

type InputProps = {
  paymentMethod: PaymentMethodType
  isSavedCard: boolean
  chooseMethod: () => void
  title: string
  isMethodChosen: boolean
  cardBrand?: string
  connectLean?: () => void
  paymentMethodProps: {
    leanSetupCompleted: boolean
    leanSetupStatus: number | null
    leanStatusUpdatedAt: string | null
  }
}

export const PaymentMethod = ({
  paymentMethod,
  isSavedCard,
  cardBrand,
  chooseMethod,
  title,
  isMethodChosen,
  connectLean,
  paymentMethodProps,
}: InputProps) => {
  const [leanInfoModalVisible, setLeanInfoModalVisible] = useState<boolean>(false)
  const isLeanPaymentsEnabled = useIsLeanPaymentsEnabled()

  const { t } = useTranslation()

  const getLogo = () => {
    if (!isSavedCard) {
      if (paymentMethod === PaymentMethodType.Wire) {
        return wireLogo
      }

      if (paymentMethod === PaymentMethodType.Lean) {
        return leanLogo
      }

      return newCardLogo
    }

    if (cardBrand === String(CardBrand.Mastercard)) {
      return mastercardLogo
    }

    if (cardBrand === String(CardBrand.Visa)) {
      return visaLogo
    }

    return newCardLogo
  }

  const getLeanStatusSettings = () => {
    switch (paymentMethodProps.leanSetupStatus) {
      case Number(LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE):
        return {
          title: t('payment.lean.setup_status_active'),
          color: '#5EAA6F',
        }
      case Number(LeanSetupStatus.LEAN_SETUP_STATUS_AWAITING_BENEFICIARY_COOL_OFF):
        return {
          title: t('payment.lean.setup_status_pending'),
          color: '#FCB35C',
        }
      default:
        return {
          title: t('payment.lean.setup_status_not_connected'),
          color: '#9CA3AF',
        }
    }
  }

  const leanStatusSettings = getLeanStatusSettings()

  if (paymentMethod === String(PaymentMethodType.Lean)) {
    if (!isLeanPaymentsEnabled()) {
      return null
    }

    return (
      <div className="PaymentMethods__method PaymentMethods__leanMethod">
        <div className="PaymentMethods__method__container">
          <div className="PaymentMethods__method__body" onClick={chooseMethod}>
            <div className="PaymentMethods__method__mainInfo">
              <div className="PaymentMethods__method__content">
                <div className="PaymentMethods__method__logoContainer">
                  <img src={getLogo()} />
                </div>
                <div className="PaymentMethods__method__descriptionContainer">
                  <span className="PaymentMethods__method__title">{title}</span>
                  {Boolean(paymentMethodProps.leanSetupStatus) && (
                    <div className="PaymentMethods__method__leanStatusContainer">
                      <span className="PaymentMethods__method__leanStatus__date">
                        {getFormattedDate(paymentMethodProps.leanStatusUpdatedAt)}
                      </span>
                      <div className="HorizontalDivider"></div>
                      <span
                        className="PaymentMethods__method__leanStatus__status"
                        style={{ color: leanStatusSettings.color }}
                      >
                        {leanStatusSettings.title}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="PaymentMethods__methodRadio">
                <div className={`PaymentMethods__methodRadio__filler${isMethodChosen ? '' : ' hidden'}`}></div>
              </div>
            </div>
            {isLeanSetupInitiated(paymentMethodProps.leanSetupStatus) && (
              <div className="PaymentMethods__method__actions">
                <button className="PaymentMethods__method__actions__button--added">One Account Added</button>
                <button onClick={connectLean} className="PaymentMethods__method__actions__button">
                  {t('payment.add_account')}
                </button>
              </div>
            )}
          </div>
          <div className="PaymentMethods__method__bottom" onClick={() => setLeanInfoModalVisible(true)}>
            <div className="PaymentMethods__method__bottom__left">
              <span className="PaymentMethods__method__leanInfo">
                {t('payment.learn_more_about')}{' '}
                <span className="PaymentMethods__method__leanInfo__link">{t('payment.lean_payment')}</span>
              </span>
              <img src={arrowRight} />
            </div>
            <div className="PaymentMethods__method__bottom__right">
              <img src={lean} />
            </div>
          </div>
        </div>
        <OgramModal
          modalVisible={leanInfoModalVisible}
          onClose={() => setLeanInfoModalVisible(false)}
          className="LeanInfoModal"
        >
          <div className="LeanInfoModal__container">
            <div className="LeanInfoModal__body">
              <span className="LeanInfoModal__body__title-1">{t('payment.lean_modal_title_1')}</span>
              <span className="LeanInfoModal__body__title-2">{t('payment.lean_modal_title_2')}</span>
              <span className="LeanInfoModal__body__title-3">{t('payment.lean_modal_title_3')}</span>
              <Separator.Vertical height={15} />
              <span className="LeanInfoModal__body__title-3">{t('payment.lean_modal_title_4')}</span>
            </div>
            <div className="LeanInfoModal__bottom">
              <img src={leanTransparent} />
            </div>
          </div>
        </OgramModal>
      </div>
    )
  }

  return (
    <div className="PaymentMethods__method" onClick={chooseMethod}>
      <div className="PaymentMethods__method__content">
        <div className="PaymentMethods__method__logoContainer">
          <img src={getLogo()} />
        </div>
        <span className="PaymentMethods__method__title">{title}</span>
      </div>
      <div className="PaymentMethods__methodRadio">
        <div className={`PaymentMethods__methodRadio__filler${isMethodChosen ? '' : ' hidden'}`}></div>
      </div>
    </div>
  )
}
