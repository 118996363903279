import moment from 'moment'
import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { ScheduleType } from '../../../../../models-ui/order/store/job-fields/schedule/types'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { TimeRow } from './time-row'
import { JobDraftFieldError } from '../error/error'
import './days-select.scss'
import { ActionAlert, Theme } from '../../../../action-alert/action-alert'

export const DaysSelect = observer(({ job }: { job: DraftJobInstance }) => {
  const [lastStartTimeInput, setLastStartTimeInput] = useState(
    job.schedule.scheduleType.value
      ? job.schedule.distinctTimeSchedule.jobDatesValues.find(date => date.start_time)?.start_time ?? ''
      : '',
  )
  const [lastEndTimeInput, setLastEndTimeInput] = useState(
    job.schedule.scheduleType.value
      ? job.schedule.distinctTimeSchedule.jobDatesValues.find(date => date.end_time)?.end_time ?? ''
      : '',
  )

  const { t } = useTranslation()

  const isWeeklyType = job.schedule.scheduleType.value === ScheduleType.weekly
  const noDatesSet =
    !job.schedule.dateBoundaries.start_date ||
    (!job.schedule.dateBoundaries.end_date && !job.schedule.dateBoundaries.open_ended)

  return (
    <div className="JobDraftTime">
      {job.schedule.distinctTimeSchedule.isLessThan24Hours && (
        <ActionAlert
          theme={Theme.Orange}
          title={t('job_draft_details.less_than_24h_warning')}
          action={() => {}}
          isActionInProgress={false}
          withCloseButton={false}
          style={{ marginTop: '15px' }}
        />
      )}
      <span className="Popup__title">{t('job_draft_details.time_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.time_subtitle')}</span>
      {job.schedule.scheduleType.value === null || noDatesSet ? (
        <span className="JobDraftTime__selectScheduleLabel">{t('job_draft_details.time_select_dates_schedule')}</span>
      ) : (
        <>
          <span className="JobDraftTime__obligatoryDates">
            {t('job_draft_details.start_and_end_date_must_be_selected', {
              startDate: job.schedule.dateBoundaries.start_date
                ? moment(job.schedule.dateBoundaries.start_date).format('MMM DD (dddd)')
                : '',
              endDate: job.schedule.dateBoundaries.end_date
                ? moment(job.schedule.dateBoundaries.end_date).format('MMM DD (dddd)')
                : '',
            })}
          </span>
          <Separator.Vertical height={16} />
          <div
            className={`JobDraftTime__dates${
              job.schedule.sameTimeSchedule.allDaysHaveSameTime ? ' JobDraftTime__dates--horizontally' : ''
            }`}
          >
            {job.schedule.distinctTimeSchedule.jobDates.map(([day, time]) => {
              return (
                <TimeRow
                  key={day}
                  day={day}
                  startTime={time.start_time}
                  startTimeError={time.start_time_error}
                  endTime={time.end_time}
                  endTimeError={time.end_time_error}
                  isActive={time.selected}
                  isWeeklyType={isWeeklyType}
                  job={job}
                  lastStartTimeInput={lastStartTimeInput}
                  lastEndTimeInput={lastEndTimeInput}
                  updateLastStartTimeInput={setLastStartTimeInput}
                  updateLastEndTimeInput={setLastEndTimeInput}
                />
              )
            })}
          </div>
          <JobDraftFieldError error={job.schedule.distinctTimeSchedule.error} />
        </>
      )}
    </div>
  )
})
