import { useTranslation } from 'react-i18next'

import confirmed from '../../../components/assets/confirmed.svg'
import { Separator } from '../../../components/app-layout/separator/separator'
import { ButtonLink } from '../../../components/button-link/button-link'
import { routes } from '../../../routes'
import './onboarding-finished.scss'

type OnboardingFinishedProps = {
  navigateToScreenName: string
}

export const OnboardingFinished = ({ navigateToScreenName }: OnboardingFinishedProps) => {
  const buttonLinkRoute = navigateToScreenName === 'home' ? routes.index : routes.allJobs

  const { t } = useTranslation()

  return (
    <div className="OnboardingFinished">
      <Separator.Spacer flex={0.06} />
      <div className="OnboardingFinished__container">
        <img src={confirmed} />
        <h2 className="OnboardingFinished__modalTitle">{t('onboarding_complete.title')}</h2>
        <p>{t('onboarding_complete.description')}</p>
        <ButtonLink
          url={buttonLinkRoute}
          title={t('onboarding_complete.done')}
          params={{}}
          className="OnboardingFinished__closeButton"
        />
      </div>
      <Separator.Spacer flex={0.04} />
    </div>
  )
}
