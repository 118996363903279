import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const JobDocumentsModel = types.model('JobDocuments', {
  id: types.identifierNumber,
  name: types.string,
})

export const jobDocumentsActions = (self: DataStore) => ({
  getJobDocuments: flow(function* () {
    const documents = (yield self.request('get', 'client/order/certificates/all')) as JobDocumentsSnapshot[]
    documents.forEach((docItem) => {
      self.job_documents.set(String(docItem.id), docItem)
    })
  }),
})

export const jobDocumentsViews = (self: DataStore) => ({
  get jobDocumentsList() {
    return Array.from(self.job_documents.values())
  },

  getJobDocumentsNames(ids: number[]) {
    return Array.from(self.job_documents.values())
      .filter((item) => ids.includes(item.id))
      .map((item) => item.name)
  },
})

export type JobDocuments = SnapshotIn<typeof JobDocumentsModel>
export type JobDocumentsSnapshot = SnapshotOut<typeof JobDocumentsModel>
