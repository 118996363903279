import { useMemo } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { CherryPickingJob } from '../../../../../models/cherry-picking-jobs'
import { dateFormat } from '../../../../../utils/datetime-utils'
import './job-details-card.scss'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

type JobDetailsCardProps = {
  jobDetails: CherryPickingJob | null
}

const getRatio = (current: number, total: number) => {
  return Math.round((current / total) * 100)
}

const JobDetailsCard = withErrorBoundary(
  observer(({ jobDetails }: JobDetailsCardProps) => {
    const formatedStartDate = moment(jobDetails?.start_date).format(dateFormat)
    const formatedEndDate = moment(jobDetails?.end_date).format(dateFormat)

    const fullFilmentRatio = useMemo(() => {
      if (jobDetails) {
        return getRatio(jobDetails.shifts_fulfilled_count, jobDetails.shifts_count)
      }
      return 0
    }, [jobDetails])

    const { t } = useTranslation()

    return (
      <div className="JobDetailsCard">
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.jobID')}</h3>
          <p className="JobDetailsCard__data">{jobDetails?.id}</p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.designation')}</h3>
          <p className="JobDetailsCard__data">{jobDetails?.work_category_name}</p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.location')}</h3>
          <p className="JobDetailsCard__data">{jobDetails?.location_name}</p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.required_quantity')}</h3>
          <p className="JobDetailsCard__data">{jobDetails?.required_quantity}</p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.fulfillment')}</h3>
          <p className="JobDetailsCard__data">
            {t('job_cherry_picking.fulfillmentRatio', { percent: fullFilmentRatio })}
          </p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.start_date')}</h3>
          <p className="JobDetailsCard__data">{formatedStartDate}</p>
        </div>
        <div className="JobDetailsCard__row">
          <h3 className="JobDetailsCard__label">{t('job_cherry_picking.end_date')}</h3>
          <p className="JobDetailsCard__data">{formatedEndDate}</p>
        </div>
      </div>
    )
  }),
)

export default JobDetailsCard
