import { createRef } from 'react'

import { Field, FieldProps } from '../field/field'
import { FormField } from '../form'
import './checkbox.scss'

type CheckboxProps = {
  required?: boolean
  value?: boolean
  onChange?: (val: boolean) => void
  field?: FormField<boolean>
}

export function Checkbox(props: CheckboxProps) {
  const handleChange = (value: boolean) => {
    props.field?.setData(value)
    props.onChange?.(value)
  }
  const ref = createRef<HTMLInputElement>()
  return (
    <input
      ref={ref}
      type="checkbox"
      checked={props.value ?? props.field?.value}
      className="Checkbox"
      onChange={(e) => handleChange(e.target.checked)}
      required={props.required}
    />
  )
}

export function CheckboxField(props: CheckboxProps & FieldProps) {
  return (
    <Field
      errors={props.errors ?? props.field?.errors}
      rightPos
      label={props.label}
      description={props.description}
      required={props.required}
    >
      <Checkbox {...props} />
    </Field>
  )
}
