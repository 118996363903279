import { useTranslation } from 'react-i18next'

import './seniority-level.scss'
import { Experience } from '../../../../../models/seniority-level'
import { getCurrentMarketConfig } from '../../../../../services/market-service'

interface SeniorityLevelProps {
  seniority: {
    name: string
    id: number
  }
  containerStyleClass?: string
}

const getSeniorityName = (seniorityId: number) => {
  switch (seniorityId) {
    case Number(Experience.beginnerOrStudent):
      return getCurrentMarketConfig().order.nonExperiencedTitle
    case Number(Experience.junior):
      return 'job_draft_details.experience_junior'
    case Number(Experience.middle):
      return 'job_draft_details.experience_middle'
    default:
      return 'job_draft_details.experience_senior'
  }
}

const SeniorityLevel = ({ seniority, containerStyleClass }: SeniorityLevelProps) => {
  const { t } = useTranslation()

  return (
    <div className={`SeniorityLevel ${containerStyleClass}`}>
      <div className="SeniorityLevel__meterContainer">
        <span className="SeniorityLevel__title">{t('job_cherry_picking.seniority_level')}</span>
        <div className="SeniorityLevel__meter">
          {seniority.id === Number(Experience.beginnerOrStudent) ? (
            <>
              <div className="SeniorityLevel__meterStep SeniorityLevel__meterStep--active"></div>
              <div className="SeniorityLevel__meterStep"></div>
              <div className="SeniorityLevel__meterStep"></div>
            </>
          ) : (
            Array.from(Array(3)).map((_, i) => (
              <div
                key={i}
                className={`SeniorityLevel__meterStep ${
                  i + 1 <= seniority.id ? 'SeniorityLevel__meterStep--active' : ''
                }`}
              ></div>
            ))
          )}
        </div>
      </div>
      <p className="SeniorityLevel__levelName">{t(getSeniorityName(seniority.id))}</p>
    </div>
  )
}

export default SeniorityLevel
