import { JobCategorySelector } from '../../../../components/job-category-selector/job-category-selector'
import { SelectCategoryUIProps as UIProps } from '../types'
import { Header } from '../../components/header/header'

export const SelectCategoryUI = ({ handleSelection, mainTitle, title, subtitle }: UIProps) => {
  return (
    <>
      <JobCategorySelector
        handleSelection={handleSelection}
        title={title}
        subtitle={subtitle}
        header={<Header title={mainTitle} />}
      />
      {/*<StickyButton*/}
      {/*  onClick={() => redirect(routes.login, {})}*/}
      {/*  buttonTitle={t('sign_up.sign_in')}*/}
      {/*/>*/}
    </>
  )
}
