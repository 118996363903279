import { AxiosResponse } from 'axios'

import { DEFAULT_API_CONFIG } from '../../services/api/api-config'
import { AccountSnapshot } from '../../models/account'
import { store } from '../../app'

export const updateAccountOnRefresh = (response: AxiosResponse) => {
  if (response.config.url === DEFAULT_API_CONFIG.refreshURL && response.status === 200) {
    // remove if check when backend adds payload to refresh response
    if (response.data.data) {
      store.data.setAccount(response.data.data as AccountSnapshot)
    }
  }
  return response
}
