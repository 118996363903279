import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../../components/app-layout/separator/separator'
import bookImg from './assets/book.svg'
import './education-card.scss'

type Props = {
  school: string
  degree: string
  field: string
}

export const EducationCard = ({ school, degree, field }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="EducationCard">
      <div className="EducationCard__imgContainer">
        <img src={bookImg} alt="" />
      </div>
      <Separator.Horizontal width={13} />
      <div className="EducationCard__info">
        <span className="EducationCard__info__title">{school}</span>
        <div className="EducationCard__info__degreeContainer">
          <span className="EducationCard__info__degreeContainer__degree">{degree}</span>{' '}
          <span className="EducationCard__info__degreeContainer__text">{t('applicant_details.in')}</span>{' '}
          <span className="EducationCard__info__degreeContainer__field">{field}</span>
        </div>
      </div>
    </div>
  )
}
