import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'

import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import TextInput from '../../../components/text-input/text-input'
import { Button } from '../../../components/button/button'
import { ButtonLink } from '../../../components/button-link/button-link'
import { Separator } from '../../../components/app-layout/separator/separator'
import { ProfileFormType } from '../../../models/profile'
import { FieldError } from '../../../components/form/field-error/field-error'
import { UploadFile } from '../../../components/form/upload-file/upload-file'
import { useMutation, useStore, useQuery } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import './profile-form.scss'

export const ProfileForm = withErrorBoundary(
  observer(() => {
    const { profile, companyDetails, getProfile, getСompanyDetails, updateProfile } = useStore().data

    const { isLoading: companyDetailsLoading } = useQuery(getСompanyDetails)
    const { isLoading: profileLoading } = useQuery(getProfile)
    const [saveResult, save] = useMutation(updateProfile, {
      onSuccess: () => window.history.back(),
      filterError: [ApiErrorTypes.ClientError],
    })

    const { t } = useTranslation()

    const errors = saveResult.error?.data.errors

    return (
      <div className="ProfileForm">
        {companyDetailsLoading || profileLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Separator.Vertical height={24.5} />
            <Formik
              initialValues={{
                image: profile?.image as File | string | undefined,
                first_name: profile?.first_name,
                last_name: profile?.last_name ?? '',
                phone: profile?.phone,
                email: profile?.email,
                allow_marketing_emails: Boolean(profile?.allow_marketing_emails),
              }}
              onSubmit={(values) => {
                const submitObject = {
                  ...values,
                  allow_marketing_emails: Number(values.allow_marketing_emails),
                }
                if (values.image instanceof File) {
                  submitObject.image = values.image
                } else {
                  delete submitObject.image
                }
                save(submitObject as ProfileFormType)
              }}
              validationSchema={Yup.object().shape({
                first_name: Yup.string().required(t('profile_form.first_name_required')),
                last_name: Yup.string().required(t('profile_form.last_name_required')),
                phone: Yup.string().required(t('profile_form.phone_required')),
                email: Yup.string()
                  .email(t('validation_messages.email_invalid'))
                  .required(t('profile_form.email_required')),
              })}
            >
              {(formikProps) => (
                <Form noValidate>
                  <div className="ProfileForm__top-container">
                    <UploadFile
                      placeholder={t('profile_form.upload_photo')}
                      value={formikProps.getFieldProps('image').value}
                      onChange={(value) => {
                        formikProps.setFieldValue('image', value)
                      }}
                    />
                    <Separator.Vertical height={25} />
                    <h2 className="ProfileForm__name">
                      {profile?.first_name} {profile?.last_name}
                    </h2>
                    <span className="ProfileForm__status">
                      {companyDetails?.is_corporate === 0 ? t('profile_form.private') : t('profile_form.corporate')}
                    </span>
                    {companyDetails?.is_corporate === 0 && (
                      <>
                        <Separator.Vertical height={31} />
                        <ButtonLink title={t('profile_form.register_corporate')} url={routes.completeRegistration} />
                      </>
                    )}
                  </div>
                  <Separator.Vertical height={24} />
                  <div className="ProfileForm__basic-container">
                    <FieldError errors={errors?.first_name}>
                      <TextInput
                        label={t('profile_form.first_name')}
                        formikProps={formikProps}
                        formikKey="first_name"
                      />
                    </FieldError>
                    <Separator.Vertical height={15} />
                    <FieldError errors={errors?.last_name}>
                      <TextInput label={t('profile_form.last_name')} formikProps={formikProps} formikKey="last_name" />
                    </FieldError>
                    <Separator.Vertical height={15} />
                    <FieldError errors={errors?.phone}>
                      <TextInput label={t('profile_form.phone')} formikProps={formikProps} formikKey="phone" />
                    </FieldError>
                    <Separator.Vertical height={15} />
                    <FieldError errors={errors?.email}>
                      <TextInput disabled label={t('profile_form.email')} formikProps={formikProps} formikKey="email" />
                    </FieldError>
                    <Separator.Vertical height={15} />
                    <div className="ProfileForm__emailAgreementContainer">
                      <FieldError errors={[]} className="ProfileForm__emailAgreementContainer__row">
                        <input
                          type="checkbox"
                          onClick={() => {
                            formikProps.setFieldValue(
                              'allow_marketing_emails',
                              !formikProps.values.allow_marketing_emails,
                            )
                          }}
                          checked={formikProps.values.allow_marketing_emails}
                          className="ProfileForm__emailAgreementContainer__checkboxContainer__checkbox"
                        />
                        <Separator.Horizontal width={10} />
                        <div className="ProfileForm__emailAgreementContainer__checkboxContainer__labelContainer">
                          <span className="ProfileForm__emailAgreementContainer__checkboxContainer__labelContainer__label">
                            {t('profile_form.agree_to_emails')}
                          </span>
                        </div>
                      </FieldError>
                    </div>
                    <Separator.Vertical height={41} />
                    <div className="ProfileForm__buttons_container">
                      <Button type="submit" title={t('profile_form.save')} className="ProfileForm__save_button" />
                      <Separator.Horizontal width={10} />
                      <Button type="button" title={t('profile_form.cancel')} onClick={() => window.history.back()} />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <Separator.Vertical height={37} />
          </>
        )}
      </div>
    )
  }),
)
