import { useState } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import { CompanyDetailsForm, CompanyDetailsSnapshot } from '../../../models/company-details'
import { Form } from '../../../components/form/form'
import { InputField } from '../../../components/form/input/input'
import { Submit } from '../../../components/form/submit/submit'
import { UploadFile } from '../../../components/form/upload-file/upload-file'
import { Loading } from '../../../components/loading/loading'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import './company-form.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const CompanyForm = withErrorBoundary(
  observer(() => {
    const store = useStore()
    const [data, setData] = useState<CompanyDetailsForm>({
      ...(store.data.companyDetails as CompanyDetailsSnapshot),
      trade: undefined,
      vat: undefined,
      trade_url: store.data.companyDetails?.trade,
      vat_url: store.data.companyDetails?.vat,
    })
    const [saveResult, save] = useMutation(() => store.data.updateСompanyDetails(data), {
      filterError: [ApiErrorTypes.ClientError],
    })
    const { isLoading } = useQuery(() => store.data.getСompanyDetails())

    const { t } = useTranslation()

    if (isLoading) return <Loading />

    return (
      <div className="CompanyForm">
        <h1>{t('company_form.title')}</h1>
        {saveResult.status === 'data' && <div className="CompanyForm__saved">{t('company_form.saved')}</div>}

        <Form
          data={data}
          setData={setData}
          error={saveResult.error?.data}
          onSubmit={save}
          isLoading={saveResult.isLoading}
        >
          {(fields) => (
            <>
              <InputField
                label={t('company_form.company_name')}
                field={fields.company_name}
                description={t('company_form.company_name_as_in_license')}
                required
              />
              <InputField label={t('company_form.address')} field={fields.address} required />
              <InputField label={t('company_form.po_box')} field={fields.po_box} required />
              <div className="CompanyForm__files">
                <UploadFile
                  placeholder={t('company_form.vat_certificate')}
                  value={fields.vat_url?.value}
                  onChange={fields.vat?.setData}
                  required
                  accept=".jpg,.jpeg,.png,.pdf"
                />
                <UploadFile
                  placeholder={t('company_form.trade_license')}
                  value={fields.trade_url?.value}
                  onChange={fields.trade?.setData}
                  accept=".jpg,.jpeg,.png,.pdf"
                />
              </div>
              <InputField label={t('company_form.finance_first_name')} field={fields.finance_first_name} />
              <InputField label={t('company_form.finance_last_name')} field={fields.finance_last_name} />
              <InputField label={t('company_form.finance_phone')} field={fields.finance_phone} />
              <InputField label={t('company_form.finance_email')} field={fields.finance_email} />
              <InputField label={t('company_form.extension')} field={fields.finance_extension} />
              <Submit>{t('company_form.save')}</Submit>
            </>
          )}
        </Form>
      </div>
    )
  }),
)
