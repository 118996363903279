/*  don't use ?? on "value" prop because "value" can be an empty string
    which is false as boolean but not nullish */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing  */
import moment from 'moment'
import TimeField from 'react-simple-timefield'
import Dropdown from 'rc-dropdown'
import { useState, useRef, useEffect, BaseSyntheticEvent, useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

import 'rc-dropdown/assets/index.css'
import { Field, FieldProps } from '../field/field'
import arrowDownSvg from '../../assets/down-arrow-grey.svg'
import './time-input.scss'
import { generateDailyTimeSlots } from '../../../utils/datetime-utils'

type TimeInputProps = {
  required?: boolean
  value: string | null
  step?: number
  label?: string
  disabled?: boolean
  className: string
  handleChange: (val: string) => void
  onBlur?: () => void
  onFocus?: () => void
  useDropdownWithInput?: boolean
  pickerStartTime?: string | null
  pickerTimeGap?: number
  showDurationFromPickerStartTime?: boolean
}

const shouldUseDesktopInput = window.innerWidth >= 1200

const HALF_DAY_HOURS = '12'
const HALF_HOUR_MINUTES = '30'
const MINUTES_IN_HOUR = 60
const MIN_HOUR_DIFF_BETWEEN_START_END_TIME = 3
const DEFAULT_PICKER_START_TIME = '00:00'

/**
 * Renders a component responsible for time in format HH:mm
 * Depending on desktop/mobile renders different elements
 * Desktop: typeable input field
 * Mobile: picker of hours/minutes
 */
export function TimeInput({
  value,
  label,
  disabled = false,
  className,
  onFocus,
  onBlur,
  handleChange,
  useDropdownWithInput,
  pickerStartTime = null,
  pickerTimeGap = 15,
  showDurationFromPickerStartTime = false,
}: TimeInputProps) {
  const timeSlots = useMemo(
    () => generateDailyTimeSlots(pickerStartTime ?? DEFAULT_PICKER_START_TIME, pickerTimeGap),
    [pickerStartTime, pickerTimeGap],
  )

  const defaultHours = shouldUseDesktopInput && !useDropdownWithInput ? '--' : HALF_DAY_HOURS
  const defaultMinutes = shouldUseDesktopInput && !useDropdownWithInput ? '--' : HALF_HOUR_MINUTES

  const [isVisible, setVisible] = useState(false)
  const [timeInputElement, setTimeInputElement] = useState<HTMLInputElement | null>(null)

  const pickerContainerId = useRef(`a${uuidv4()}`).current
  const inputId = useRef(`a${uuidv4()}`).current

  const pickerContainerRef = useRef<HTMLDivElement>(null)

  const pickerValue =
    pickerStartTime !== null
      ? timeSlots[0 + (MIN_HOUR_DIFF_BETWEEN_START_END_TIME * MINUTES_IN_HOUR) / pickerTimeGap]
      : null
  const currentValue = value || pickerValue || `${defaultHours}:${defaultMinutes}`

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement
      const parentIsModal = target.closest(`#${pickerContainerId}`)
      const parentIsInput = target.closest(`#${inputId}`)
      if (!parentIsModal && !parentIsInput) {
        setVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      const scrollTimeout = setTimeout(scrollToPickerSlot, 10)

      return () => clearTimeout(scrollTimeout)
    }
  }, [isVisible])

  const scrollToPickerSlot = () => {
    if (pickerContainerRef.current && currentValue) {
      const children = Array.from(pickerContainerRef.current.children)
      const targetItem =
        children.find(el => el.getAttribute('data-value') === currentValue) ??
        children.find(el => el.getAttribute('data-value')?.startsWith(currentValue.slice(0, 2)))

      if (targetItem) {
        targetItem.scrollIntoView({ block: 'start' })
      }
    }
  }

  const getPickerSlotContent = (slotTime: string) => {
    if (!showDurationFromPickerStartTime) {
      return slotTime
    }

    const startTime = moment(pickerStartTime ?? DEFAULT_PICKER_START_TIME, 'HH:mm')
    const endTime = moment(slotTime, 'HH:mm')

    if (endTime.isSame(startTime)) {
      return slotTime
    }

    const diff = endTime.isAfter(startTime) ? endTime.diff(startTime) : endTime.clone().add(1, 'day').diff(startTime)
    const duration = moment.duration(diff)

    const hoursString = duration.hours() > 0 ? `${duration.hours()}h` : ''
    const minutesString = duration.minutes() > 0 ? `${duration.minutes()}m` : ''

    if (!hoursString && minutesString) {
      return `${slotTime} (${minutesString})`
    }

    if (hoursString && !minutesString) {
      return `${slotTime} (${hoursString})`
    }

    return `${slotTime} (${hoursString} ${minutesString})`
  }

  const timeFieldComponent = (
    <TimeField
      // @ts-ignore incorrect prop typing
      value={currentValue}
      // @ts-ignore incorrect function signature
      inputRef={(inputEl: HTMLInputElement) => {
        setTimeInputElement(inputEl)
      }}
      onChange={(_, updatedValue) => {
        handleChange(updatedValue)
        if (isVisible) {
          scrollToPickerSlot()
        }
      }}
      // @ts-ignore the library lacks ts-types for default input props
      disabled={disabled}
      onClick={(ev: BaseSyntheticEvent) => {
        // when input is clicked, we want cursor to be focused on the symbol it was clicked on
        // that's why we don't want parent's onClick() to be fired
        ev.stopPropagation()
      }}
      onFocus={() => {
        setVisible(true)
        onFocus?.()
      }}
      onBlur={() => {
        onBlur?.()
      }}
      className={`TimeInput__timeInput TimeInput__value ${!value && !isVisible ? 'TimeInput__timeInput_hidden' : ''}`}
    />
  )

  if (shouldUseDesktopInput && !useDropdownWithInput) {
    return (
      <div
        className={className}
        onClick={() => {
          setVisible(true)
          // once clicked on the wrapper, focus on the input
          // and place cursor on the first symbol
          timeInputElement?.focus()
          if (timeInputElement) {
            timeInputElement.selectionStart = 0
            timeInputElement.selectionEnd = 0
          }
        }}
      >
        <TimeContainer inputId={inputId} label={label}>
          {timeFieldComponent}
        </TimeContainer>
      </div>
    )
  }

  const innerComponent = (
    <div className="TimeInput__list" id={pickerContainerId} ref={pickerContainerRef}>
      {timeSlots.map(timeSlot => {
        return (
          <span
            onClick={() => {
              handleChange(timeSlot)
              setVisible(false)
            }}
            data-value={timeSlot}
            key={timeSlot}
            className={`TimeInput__list__item${currentValue === timeSlot ? ' TimeInput__list__item--selected' : ''}`}
          >
            {getPickerSlotContent(timeSlot)}
          </span>
        )
      })}
    </div>
  )

  return (
    <Dropdown
      trigger={disabled ? [] : ['click']}
      overlay={innerComponent}
      visible={isVisible}
      onVisibleChange={newIsVisible => {
        if (newIsVisible) {
          setVisible(true)
          onFocus?.()
        } else {
          onBlur?.()
        }
      }}
    >
      <div className={className}>
        <TimeContainer inputId={inputId} label={label} className={className}>
          {useDropdownWithInput ? timeFieldComponent : <span className="TimeInput__value">{value}</span>}
        </TimeContainer>
      </div>
    </Dropdown>
  )
}

function TimeContainer(
  props: React.PropsWithChildren & {
    className?: string
    inputId: string
    label?: string
  },
) {
  return (
    <div className={`TimeInput ${props.className}`} id={props.inputId}>
      <div className="TimeInput__container">
        <div>
          <span className="TimeInput__label">{props.label}</span>
          {props.children}
        </div>
        <img src={arrowDownSvg} className="TimeInput__toggleIcon" />
      </div>
    </div>
  )
}

export function TimeInputField(props: TimeInputProps & FieldProps) {
  return (
    <Field required={props.required} errors={props.errors}>
      <TimeInput {...props} />
    </Field>
  )
}
