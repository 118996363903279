import { Fragment } from 'react'

import './sticky-call-to-action.scss'
import { StickyPanel } from '../sticky-panel/sticky-panel'

type InputProps = {
  actionTitle: string
  actionSubtitle: string
  action: () => void
  buttonTitle: string
  buttonStyle?: object
}

export const StickyCallToAction = ({ actionTitle, actionSubtitle, action, buttonTitle, buttonStyle }: InputProps) => {
  return (
    <StickyPanel contentStyle={{ justifyContent: 'space-between' }}>
      <Fragment>
        <div className="StickPayButton__description">
          <span className="StickPayButton__description__title">{actionTitle}</span>
          <span className="StickPayButton__description__amount">{actionSubtitle}</span>
        </div>
        <button className="StickPayButton__button" onClick={action} style={buttonStyle}>
          {buttonTitle}
        </button>
      </Fragment>
    </StickyPanel>
  )
}
