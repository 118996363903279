import { useTranslation } from 'react-i18next'

import './order-summary-cost-card.scss'

interface OrderSummaryCostCardProps {
  sub_total: number
  tax: number
  tax_percent: number
  transportation_total: number
  total: number
  currency: string
}

const OrderSummaryCostCard = ({
  sub_total,
  tax,
  tax_percent,
  transportation_total,
  total,
  currency,
}: OrderSummaryCostCardProps) => {
  const { t } = useTranslation()

  return (
    <div className="OrderSummaryCostCard">
      <div className="OrderSummaryCostCard__row">
        <p className="OrderSummaryCostCard__label">{t('order.subtotal')}</p>
        <p className="OrderSummaryCostCard__data">
          {sub_total} {currency}
        </p>
      </div>
      <div className="OrderSummaryCostCard__row">
        <p className="OrderSummaryCostCard__label">{t('order.transportations')}</p>
        <p className="OrderSummaryCostCard__data">
          {transportation_total} {currency}
        </p>
      </div>
      <div className="OrderSummaryCostCard__row">
        <p className="OrderSummaryCostCard__label">{t('order.vat_percent', { count: tax_percent })}</p>
        <p className="OrderSummaryCostCard__data">
          {tax} {currency}
        </p>
      </div>
      <div className="OrderSummaryCostCard__row">
        <p className="OrderSummaryCostCard__label bold">{t('order.estimated_total')}</p>
        <p className="OrderSummaryCostCard__data bold">
          {total} {currency}
        </p>
      </div>
      <div className="OrderSummaryCostCard__hint">
        <span className="OrderSummaryCostCard__hint__label">{t('order.final_amount_will_be_based')}</span>
      </div>
    </div>
  )
}

export default OrderSummaryCostCard
