import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export enum UnseenUpdateType {
  NewPendingPayment = 1,
  NewProcessingPayment = 2,
  NewPaidPayment = 3,
}

export const UnseenUpdateModel = types.model('UnseenUpdateModel', {
  type: types.identifierNumber,
  seen: types.boolean,
})

export const unseenUpdatesActions = (self: DataStore) => ({
  getUnseenUpdates: flow(function* () {
    const unseenUpdates = (yield self.request('get', 'client/unseen-updates')) as UnseenUpdateSnapshot[]
    unseenUpdates.forEach((unseenUpdate) => {
      self.unseenUpdates.set(String(unseenUpdate.type), unseenUpdate)
    })
  }),

  markUpdateAsSeen: flow(function* (updateType: UnseenUpdateType) {
    yield self.request('post', `client/unseen-updates/mark-as-seen/${updateType}`)
    const update = self.unseenUpdates.get(String(updateType))
    if (update) {
      update.seen = true
    }
  }),
})

export const unseenUpdatesViews = (self: DataStore) => ({
  isUpdateSeen(updateType: UnseenUpdateType) {
    return self.unseenUpdates.get(String(updateType))?.seen ?? true
  },
})

export type UnseenUpdate = Instance<typeof UnseenUpdateModel>
export type UnseenUpdateSnapshot = SnapshotOut<typeof UnseenUpdateModel>
