import { ChangeEvent, useState } from 'react'
import './upload-document.scss'
import { useTranslation } from 'react-i18next'

export interface UploadDocumentProps {
  onSelectFile: (file: File | null) => void
  hasFile: boolean
  showErrorRequiredMessage: boolean
  placeholder: string
  name: string
  maxFileSize: number
}

const UploadDocument = ({
  onSelectFile,
  hasFile,
  placeholder,
  name,
  maxFileSize,
  showErrorRequiredMessage,
}: UploadDocumentProps) => {
  const [isFileSelected, setIsFileSelected] = useState(false)
  const [showErrorMesssage, setShowErrorMesssage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('This Field is Required')

  const { t } = useTranslation()

  const handleFileSelected = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onSelectFile(null)
    const files = target.files
    if (files) {
      const fileSizeInMB = files[0].size / 1000000
      if (fileSizeInMB <= maxFileSize) {
        onSelectFile(files[0])
        setShowErrorMesssage(false)
        setIsFileSelected(true)
      } else {
        setShowErrorMesssage(true)
        setErrorMessage(`File size canot exceed ${maxFileSize}mb`)
      }
    }
  }
  return (
    <>
      <div className="UploadDocument">
        <input
          accept=".doc,.docx,.pdf,image/*"
          onChange={handleFileSelected}
          value={''}
          id={name}
          type="file"
          className="UploadDocument__uploadInput"
        />
        <label htmlFor={name} className="UploadDocument__upload">
          {isFileSelected ? (
            <p className="UploadDocument__uploadText">{t('complete_registration.document_selected')}</p>
          ) : hasFile ? (
            <p className="UploadDocument__uploadText">{t('complete_registration.document_submitted')}</p>
          ) : (
            <p className="UploadDocument__placeholder">{placeholder}</p>
          )}
        </label>
      </div>
      {showErrorMesssage || showErrorRequiredMessage ? (
        <p className="UploadDocument__errorMessage">{errorMessage}</p>
      ) : null}
    </>
  )
}

export default UploadDocument
