import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { orderExtensionStore } from '../../../../models-ui/order/store'
import { getFormattedDate } from '../../../../pages/order/orders-list/utils'
import { DropdownSelect } from '../../../dropdown-select/dropdown-select'
import { DateInput } from '../../../date-input/date-input'
import { MenuItem } from '../../../dropdown-select/components/menu-item/menu-item'

export const DifferentDatesSelector = observer(() => {
  const { t } = useTranslation()

  return (
    <>
      <span className="Popup__title">{t('order_extension.for_how_long_extend')}</span>
      <span className="Popup__subtitle">{t('order_extension.you_can_extend_order')}</span>
      <div className="OrderExtension__popup__innerContainer">
        {orderExtensionStore.jobsList.map((job, key) => (
          <div className="OrderExtension__job" key={key}>
            <div className="OrderExtension__job__info">
              <span className="OrderExtension__job__location">{job.location}</span>
              <span className="OrderExtension__job__designation">
                {job.designation} <span className="OrderExtension__job__quantity">({job.staffAmount})</span>
              </span>
              <span className="OrderExtension__job__extensionDate">End Date: {getFormattedDate(job.endTime)}</span>
            </div>
            <DropdownSelect
              label={job.shouldBeExtended ? t('order_draft.start_date') : t('order_extension.do_not_extend')}
              value={job.startDate}
              items={[
                {
                  element: (
                    <DateInput
                      calendarContainerStyle={{ left: '-155px', top: '-15px' }}
                      hideBorder
                      hideValue
                      label={t('order_extension.select_start_date')}
                      value={job.startDate ?? ''}
                      handleChange={val => orderExtensionStore.setJobExtensionStartDate(job.id, val)}
                      min={job.minStartDate}
                      className="Dates__dateInput"
                    />
                  ),
                  closeOnClick: false,
                },
                {
                  element: (
                    <MenuItem
                      img={null}
                      title={t('order_extension.do_not_extend')}
                      onClick={() => {
                        orderExtensionStore.setJobExtensionStartDate(job.id, null)
                      }}
                    />
                  ),
                  closeOnClick: true,
                },
              ]}
            />
            <DropdownSelect
              label={job.shouldBeExtended ? t('order_draft.end_date') : t('order_extension.do_not_extend')}
              value={job.extensionDate}
              items={[
                {
                  element: (
                    <DateInput
                      calendarContainerStyle={{ left: '-155px', top: '-15px' }}
                      hideBorder
                      hideValue
                      label={t('order_extension.select_end_date')}
                      value={job.extensionDate ?? ''}
                      handleChange={val => orderExtensionStore.setJobExtensionDate(job.id, val)}
                      min={job.startDate ?? undefined}
                      className="Dates__dateInput"
                    />
                  ),
                  closeOnClick: false,
                },
                {
                  element: (
                    <MenuItem
                      img={null}
                      title={t('order_extension.do_not_extend')}
                      onClick={() => {
                        orderExtensionStore.setJobExtensionDate(job.id, null)
                      }}
                    />
                  ),
                  closeOnClick: true,
                },
              ]}
            />
          </div>
        ))}
      </div>
    </>
  )
})
