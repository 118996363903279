import { Separator } from '../../../../../../../../../../components/app-layout/separator/separator'
import './menu-item.scss'

type Props = {
  img: string
  title: string
  disabled: boolean
  onClick: () => void
}

export const MenuItem = ({ img, title, disabled, onClick }: Props) => {
  return (
    <div className={`MenuItem ${disabled ? 'disabled' : ''}`} onClick={disabled ? () => {} : onClick}>
      <img src={img} alt="" />
      <Separator.Horizontal width={15} />
      <span>{title}</span>
    </div>
  )
}
