import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import './sticky-payment-button.scss'

import { StickyPanel } from '../../sticky-panel/sticky-panel'

type InputProps = {
  amount: string
  action: () => void
  actionTitle: string
}

export const StickyPaymentButton = ({ amount, action, actionTitle }: InputProps) => {
  const { t } = useTranslation()

  return (
    <StickyPanel contentStyle={{ justifyContent: 'space-between' }}>
      <Fragment>
        <div className="StickPayButton__description">
          <span className="StickPayButton__description__title">{t('payment.overdue_balance')}</span>
          <span className="StickPayButton__description__amount">{amount}</span>
        </div>
        <button className="StickPayButton__button" onClick={action}>
          {actionTitle}
        </button>
      </Fragment>
    </StickyPanel>
  )
}
