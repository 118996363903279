import { useTranslation } from 'react-i18next'
import './filters-button.scss'

import filterIcon from '../../assets/filter.svg'

export const FiltersButton = ({
  setShowFilters,
  activeFiltersCount,
  className,
}: {
  setShowFilters: (value: boolean) => void
  activeFiltersCount: number
  className?: string | null
}) => {
  const { t } = useTranslation()

  return (
    <div onClick={() => setShowFilters(true)} className={`filterButton ${className}`}>
      <img src={filterIcon} className="filterButton__icon" />
      <span className="filterButton__title">{t('common.filter')}</span>
      {activeFiltersCount > 0 ? <div className="filterButton__indicator">{activeFiltersCount}</div> : null}
    </div>
  )
}
