import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/applicants-review'
import '../onboarding.scss'

export const applicantsReviewOnboarding: OnboardingContent = {
  title: t('applicants_review_onboarding.title'),
  paths: [routes.applicantsReview.path],
  pages: [
    {
      title: t('applicants_review_onboarding.title'),
      content: [
        { image: { src: images.jobsPendingApplicantsReviewImg, className: 'Onboarding__bigImg' } },
        { description: t('applicants_review_onboarding.pending_applicant_review') },
      ],
    },
    {
      title: t('applicants_review_onboarding.applicant_list_title'),
      content: [
        { image: { src: images.jobsApplicantsListImg, className: 'Onboarding__bigImg' } },
        { description: t('applicants_review_onboarding.applicant_list') },
      ],
    },
    {
      title: t('applicants_review_onboarding.applicant_details_title'),
      content: [
        { image: { src: images.applicantDetailsImg, className: 'Onboarding__bigImg' } },
        { description: t('applicants_review_onboarding.applicant_details') },
      ],
    },
  ],
}
