import { Config } from './env'

export const config: Config = {
  API_URL: 'https://api.ogram.co/api/',
  API_GR_URL: 'https://api-gr.ogram.co/api/',
  API_SA_URL: 'https://api-sa.ogram.co/api/',
  USE_REAL_API: true,
  GOOGLE_API_KEY: 'AIzaSyCcsLOyCRKa_PIH_DwVDEgs-s1EUWhOg1A',
  GOOGLE_AUTH_CLIENT_ID: '171301787568-i2djddid25but5oigoid80si3s743bdf.apps.googleusercontent.com',
  IOS_CLIENT_ID: '1004213687272-gcqsfhnek4iiq3j6fqdu603igb6m3dl8.apps.googleusercontent.com',
  FB_AUTH_CLIENT_ID: '640269856536676',
  SHOW_MESSAGES_TAB: false,
  STRIPE_PUBLISHABLE_KEY_UAE:
    'pk_live_51KyBhxHzf8fVSAwXr7mz3VkTBUl6eECw05CGrXQQkVkppXoSUwqQ4cnztXTjCu4fpNkM4LbXBnh1KIO0YOGwdC9H00c6pa96MB',
  STRIPE_PUBLISHABLE_KEY_GR:
    'pk_live_51M2r2QJz9c5KxSLgC0WAyoOUjhjSYiGSVL4ssqgJTXDv8xQmRdOJbQYF0sgql5RfvzBHCVqL2mCDfoDCPGGmw20i00m9nvaPSr',
  LEAN_APP_TOKEN: '8ce69dc8-627c-4e40-abbd-1042a40b61c9',
  LEAN_PAYMENT_DESTINATION_ID: '9a5b2b0b-390d-43a1-9a2f-8c699e669c06',
  FIREBASE_WEB_CONFIG: {
    apiKey: 'AIzaSyB8WI2K1_g06BTxD711J_LE6omGEGrFtDY',
    vapidKey: 'BDIoSfsOUXOCCMOgRHHEvfib0GiReHY4AXOl7JRgy9pkiMthllvevpuEjJgcAWbNdmZXVikKb22RLf9CKL3R5Q0',
    authDomain: 'ogram-client.firebaseapp.com',
    projectId: 'ogram-client',
    storageBucket: 'ogram-client.appspot.com',
    messagingSenderId: '188726379320',
    appId: '1:188726379320:web:32bc95d9cd08ef528537cd',
    measurementId: 'G-Y7VPTMJDYR',
  },
}
