import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Answer } from '../../../../../models/interview'
import { CherryPickingApplicant } from '../../../../../models/cherry-picking-applicants'
import { useStore } from '../../../../../utils/mst-hooks'
import Rating from '../../../../../components/rating/rating'
import ratingStarImg from '../../../../../components/assets/rating-star.svg'
import './result-form.scss'

type ResultFormProps = {
  interviewId: number
  isVisible: boolean
}

function ResultFormComponent({ interviewId, isVisible }: ResultFormProps) {
  const { t } = useTranslation()
  const { currentApplicant, getInterviewQuestions, getSpAnswer, getSpRating } = useStore().data
  const questions = getInterviewQuestions(interviewId)
  const applicant = currentApplicant as CherryPickingApplicant
  const spId = applicant.id
  const overalRating = getSpRating({ interviewId, spId }) ?? 0
  const isRecommended = overalRating >= 4
  const questionsWithAnswers = questions.filter(id => Boolean(getSpAnswer({ spId, interviewId, questionId: id })))

  return (
    <div hidden={!isVisible} className="ResultForm">
      <div className="ResultForm__header">
        <div hidden={!isRecommended} className="ResultForm__title">
          {t('interview.recommended_to_hire')}
        </div>
        <span className="ResultForm__subtitle">{t('interview.overall_rating')}</span>
        <div className="ResultForm__ratingContainer">
          <img src={ratingStarImg} alt="" className="ResultForm__overallStarImg" />
          <div className="ResultForm__overallRatingLabel">
            {overalRating % 2 === 0 ? overalRating : Math.round(overalRating * 10) / 10}
          </div>
        </div>
      </div>
      {questionsWithAnswers.map((questionId, index) => {
        const answer = getSpAnswer({ spId: applicant.id, interviewId, questionId }) as Answer
        return (
          <div key={questionId} className="ResultForm__questionContainer">
            <div className="ResultForm__questionRatingLabel">
              {t('interview.rating_question_short', { index: index + 1 })}
            </div>
            <Rating editable={false} rating={answer.rating ?? 0} size={14} />
          </div>
        )
      })}
    </div>
  )
}

export const ResultForm = observer(ResultFormComponent)
