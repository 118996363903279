import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useRedirect } from '../../../../../services/router'
import { generateDeviceId } from '../../../../../utils/device-id-generator'
import { useStore, useMutation } from '../../../../../utils/mst-hooks'
import { remove } from '../../../../../services/storage'
import { routes } from '../../../../../routes'
import { trackingService } from '../../../../../services/tracking-service'
import { DropdownMenu } from '../../../../dropdown-menu/dropdown-menu'
import { ROOT_STATE_STORAGE_KEY } from '../../../../../models/utils/setup-root-store'
import { DRAFT_JOBS_STORE_KEY } from '../../../../../models-ui/order/store'
import { withErrorBoundary } from '../../../../error/with-error-boundary'
import './dropdown-avatar.scss'

export const DropdownAvatar = withErrorBoundary(
  observer(({ imageURL }: { imageURL: string }) => {
    const store = useStore()
    const redirect = useRedirect()
    const { t } = useTranslation()

    const [, logout] = useMutation(() => store.data.logout({ device_id: generateDeviceId() }), {
      onSuccess: () => {
        redirect(routes.login, {})
        remove(ROOT_STATE_STORAGE_KEY)
        remove(DRAFT_JOBS_STORE_KEY)
        trackingService.resetUser()
      },
    })
    return (
      <DropdownMenu
        outerComponent={<img src={imageURL} className="Dropdown__main-avatar" />}
        items={[
          <p onClick={() => redirect(routes.editProfile, {})} key="edit-profile" className="Dropdown__link">
            {t('profile_form.title')}
          </p>,
          <p onClick={() => redirect(routes.changePassword, {})} key="change-password" className="Dropdown__link">
            {t('change_password.title')}
          </p>,
          <p onClick={logout} key="sign-out" className="Dropdown__link">
            {t('profile_form.sign_out')}
          </p>,
        ]}
      />
    )
  }),
)
