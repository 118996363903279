import assert from './utils/assert'
import objectToGetParams from './utils/object-to-get-params'
import createShareButton from './hocs/create-share-button'
import icon from './assets/telegram.svg'

function telegramLink(url: string, { title }: { title?: string }) {
  assert(url, 'telegram.url')

  return `https://telegram.me/share/url${objectToGetParams({
    url,
    text: title,
  })}`
}

const Button = createShareButton<{ title?: string }>(
  'telegram',
  telegramLink,
  (props) => ({
    title: props.title,
  }),
  {
    windowWidth: 550,
    windowHeight: 400,
  },
)

const TelegramShareButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <Button url={url} title={title}>
      <img src={icon} style={{ cursor: 'pointer' }} />
    </Button>
  )
}

export default TelegramShareButton
