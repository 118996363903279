import assert from './utils/assert'
import objectToGetParams from './utils/object-to-get-params'
import createShareButton from './hocs/create-share-button'
import icon from './assets/twitter.svg'

function twitterLink(
  url: string,
  {
    title,
    via,
    hashtags = [],
    related = [],
  }: { title?: string; via?: string; hashtags?: string[]; related?: string[] },
) {
  assert(url, 'twitter.url')
  assert(Array.isArray(hashtags), 'twitter.hashtags is not an array')
  assert(Array.isArray(related), 'twitter.related is not an array')

  return `https://twitter.com/share${objectToGetParams({
    url,
    text: title,
    via,
    hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
    related: related.length > 0 ? related.join(',') : undefined,
  })}`
}

const Button = createShareButton<{
  title?: string
  via?: string
  hashtags?: string[]
  related?: string[]
}>(
  'twitter',
  twitterLink,
  (props) => ({
    hashtags: props.hashtags,
    title: props.title,
    via: props.via,
    related: props.related,
  }),
  {
    windowWidth: 550,
    windowHeight: 400,
  },
)

const TwitterShareButton = ({ url, title }: { url: string; title: string }) => {
  return (
    <Button url={url} title={title}>
      <img src={icon} style={{ cursor: 'pointer' }} />
    </Button>
  )
}

export default TwitterShareButton
