import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { PublicTransportAccessibility } from '../../../../../models-ui/order/store/job-fields/transport-accessibility'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './public-transport-accessibility.scss'

export const JobDraftPublicTransportAccessibility = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()
  const accessibility = job.transport.value

  return (
    <div className="JobDraftPublicTransportAccessibility">
      <span className="Popup__title">{t('order_draft.is_location_accessible_by_transport')}</span>
      <span className="Popup__subtitle">{t('order_draft.tell_if_location_is_reachable')}</span>
      <div className="JobDraftPublicTransportAccessibility__innerContainer">
        <Button
          title={t('order_draft.public_transport_accessibility_accessible')}
          onClick={() => {
            job.transport.set(Number(PublicTransportAccessibility.accessible))
          }}
          className={`JobDraftPublicTransportAccessibility__innerContainer__button ${
            accessibility === Number(PublicTransportAccessibility.accessible) ? 'active' : ''
          } ${job.transport.hasError ? 'error' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('order_draft.public_transport_accessibility_not_accessible')}
          onClick={() => {
            job.transport.set(Number(PublicTransportAccessibility.notAccessible))
          }}
          className={`JobDraftPublicTransportAccessibility__innerContainer__button ${
            accessibility === Number(PublicTransportAccessibility.notAccessible) ? 'active' : ''
          } ${job.transport.hasError ? 'error' : ''}`}
        />
        {job.transport.hasError && <JobDraftFieldError error={job.transport.error} />}
      </div>
    </div>
  )
})
