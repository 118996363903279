import { useTranslation } from 'react-i18next'

import { ScheduleShiftSnapshot, ScheduleActiveShift } from '../../../../../../../models/schedule/types'
import { Status, getShiftStatus } from './status'
import './sp-canceled.scss'
import { ConfirmationShiftTools } from './confirmation-shift-tools'

type SpCanceledProps = {
  shift: ScheduleShiftSnapshot
  spShift: ScheduleActiveShift
}

export function SpCanceled({ shift, spShift }: SpCanceledProps) {
  const { t } = useTranslation()
  return (
    <div className="SpCanceled">
      <div className="SpCanceled__top">
        <div className="SpCanceled__top__timeContainer">
          <span className="SpCanceled__top__timeContainer__label">{t('all_jobs.start')}</span>
        </div>
        <div className="SpCanceled__top__timeContainer">
          <span className="SpCanceled__top__timeContainer__label">{t('all_jobs.end')}</span>
        </div>
      </div>
      <div className="SpCanceled__bottom">
        <Status status={getShiftStatus(spShift.status.id)} title={spShift.status.title} />
        <ConfirmationShiftTools shiftId={shift.id} />
      </div>
    </div>
  )
}
