import './earnings.scss'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { TcAcceptedChecker } from '../../../components/affiliate/tc-accepted-checker/tc-accepted-checker'

export const Earnings = observer(() => {
  const { t } = useTranslation()

  const { getAffiliateEarnings, affiliateEarnings } = useStore().data
  useQuery(getAffiliateEarnings)

  return (
    <div className="AffiliateEarningsPage">
      <TcAcceptedChecker />
      <Header title={t('common_affiliate.earning_history')} showBack />
      <Separator.Vertical height={28} />
      <div className="AffiliateEarningsPage__container">
        <div className="AffiliateEarningsPage__card">
          <div className="AffiliateEarningsPage__stats">
            <span className="AffiliateEarningsPage__stats__sum">
              {affiliateEarnings?.currency} {affiliateEarnings?.totalAmount}
            </span>
            <span className="AffiliateEarningsPage__stats__period">{t('common_affiliate.total_earnings')}</span>
          </div>
        </div>
        <Separator.Vertical height={20} />
        <div className="AffiliateEarningsPage__card">
          <div className="AffiliateEarningsPage__months">
            {(affiliateEarnings?.periods ?? []).map((period) => (
              <div key={period.title} className="AffiliateEarningsPage__months__item">
                <div className="AffiliateEarningsPage__months__item__left">{period.title}</div>
                <div className="AffiliateEarningsPage__months__item__right">
                  {affiliateEarnings?.currency} {period.amount}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})
