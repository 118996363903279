import moment from 'moment'

import { getCurrentMarketConfig } from '../services/market-service'

export const timeFormat = 'hh:mm a'
export const dateFormat = 'D MMM YYYY'

export const getDaysInRange = (start?: Date, end?: Date) => {
  if (!start || !end) return []
  const startDate = new Date(start.valueOf())
  startDate.setMilliseconds(0)
  const endDate = new Date(end.valueOf())
  endDate.setMilliseconds(0)
  const arr: string[] = []
  while (startDate <= endDate) {
    arr.push(moment(startDate).format('YYYY-MM-DD'))
    startDate.setDate(startDate.getDate() + 1)
  }
  return arr
}

export const utcOffsetSecs = new Date().getTimezoneOffset() * 60

export const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']

/**
 * Converts 'YYYY-MM-DD' to 'ddd' format
 * i.g.  formatYyyyMmDdToDdd('2024-07-10') returns 'wed'
 */
export const formatYyyyMmDdToDdd = (yyyyMmDd: string) => {
  return daysOfWeek[new Date(yyyyMmDd).getUTCDay()]
}

export const formatDateToDdd = (date: Date) => {
  return daysOfWeek[date.getUTCDay()]
}

export const isYyyyMmDdValid = (yyyyMmDd: string) => {
  return !isNaN(new Date(yyyyMmDd).getTime())
}

export const isDddValid = (ddd: string) => {
  return daysOfWeek.includes(ddd)
}

export const isYyyyMmDdBeforeToday = (yyyyMmDd: string) => {
  const currentDate = new Date()
  const compareDate = new Date(yyyyMmDd)
  compareDate.setHours(0, 0, 0, 0)

  return compareDate < new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())
}

export const getRangeOfDates = (startDate: Date, endDate: Date) => {
  const range: Date[] = []
  const currentDate = new Date(startDate)
  while (currentDate <= endDate) {
    range.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return range
}

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ')

  const timeComponents = time.split(':')
  let hours = timeComponents[0]
  const minutes = timeComponents[1]

  if (hours === '12') {
    hours = '00'
  }

  if (modifier === 'PM') {
    hours = String(Number(hours) + 12)
  }

  return `${hours}:${minutes}`
}

export const convertTime24To12 = (time24h: string) => {
  const hours24 = Number(time24h.slice(0, 2))
  const minutes = time24h.slice(-2)
  const hours12 = hours24 % 12 || 12
  const hours12WithPrefix = hours12 >= 10 ? hours12 : `0${hours12}`
  const modifier = hours24 < 12 || hours24 === 24 ? 'AM' : 'PM'
  return `${hours12WithPrefix}:${minutes} ${modifier}`
}

export const getRelativeDay = (date: Date, addOrSubtractDays = 1): Date => {
  const currentDay = date.getDate()
  const nextDay = new Date(date.getTime())
  nextDay.setDate(currentDay + addOrSubtractDays)
  return nextDay
}

/**
 *
 * @param date - any date
 * @returns array of dates that belong to the week of the `date`
 */
export const getWeekDates = (date: Date) => {
  const firstDayOfWeek = getFirstDayOfWeek(date)
  return new Array(7).fill(0).map((_, index) => {
    return getRelativeDay(firstDayOfWeek, index)
  })
}

export const getFirstDayOfWeek = (currentDate: Date): Date => {
  const isSundayFirstDayOfWeek = getCurrentMarketConfig().firstDayOfWeek === 'Sun'
  const currentDayOfWeekIndex = currentDate.getDay()

  const firstDayDate = new Date(currentDate)
  if (isSundayFirstDayOfWeek) {
    firstDayDate.setDate(currentDate.getDate() - currentDayOfWeekIndex)
  } else {
    const diff = currentDayOfWeekIndex === 0 ? 6 : currentDayOfWeekIndex - 1
    firstDayDate.setDate(currentDate.getDate() - diff)
  }

  return firstDayDate
}

export const formatDateToIso8601 = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export const getCurrentDateIso8601 = () => {
  return formatDateToIso8601(new Date())
}

export const formatDateToHhMm = (date: Date): string => {
  const hours = `0${date.getHours()}`.slice(-2)
  const minutes = `0${date.getMinutes()}`.slice(-2)

  return `${hours}:${minutes}`
}

export function generateDailyTimeSlots(startTimeString = '00:00', gapInMinutes = 15) {
  const times = []

  let currentHour = 0
  let currentMinute = 0

  while (currentHour < 24) {
    times.push(`${String(currentHour).padStart(2, '0')}:${String(currentMinute).padStart(2, '0')}`)
    currentMinute += gapInMinutes
    if (currentMinute >= 60) {
      currentHour++
      currentMinute -= 60
    }
  }

  const indexOfStartTime = times.indexOf(startTimeString)
  const firstPartOfArray = times.slice(indexOfStartTime)
  const secondPartOfArray = times.slice(0, indexOfStartTime)
  return [...firstPartOfArray, ...secondPartOfArray]
}

// dateString has format ‘YYYY-MM-DD’
/**
 * Converts ‘YYYY-MM-DD’ to 'DD MMM YYYY'
 * example: convertYyyyMmDdToDdMmmYyyy('2024-07-10') returns 10 Jul 2024
 */
export const convertYyyyMmDdToLocaleDdMmmYyyy = (dateString: string): string => {
  return moment(dateString, 'YYYY-MM-DD').format('DD MMM YYYY')
}

export const epochToDate = (epoch: number): Date => {
  return new Date(epoch * 1000)
}

/**
 * @param time - HH:mm
 */
export const isTimeLessThan24HoursFromNow = (time: string) => {
  const momentTimeTomorrow = moment(time, 'HH:mm').add(1, 'days')
  const timeNow = moment()
  const duration = moment.duration(momentTimeTomorrow.diff(timeNow))
  // if actual hours difference is i.g. 26 hours moment.js will treat it as 1 day, 2 hours
  // so if omit duration.days() === 0 it can return true for tomorrow
  // same for duration.months() === 0 it can return true for the date exactly one month ahead
  const isLessThan24 = duration.months() === 0 && duration.days() === 0 && duration.hours() < 24
  return isLessThan24
}

export const getNextDaySameTime = (date: Date): Date => {
  const nextDay = new Date(date)
  nextDay.setDate(nextDay.getDate() + 1)
  return nextDay
}

const localeDateString = new Date().toLocaleString()
export const is12HourFormat = localeDateString.includes('PM') || localeDateString.includes('AM')
