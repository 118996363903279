const MIN_SPACE_TO_BOTTOM = 100

export const useBottomListListener = (callback: () => void) => {
  return (e: React.UIEvent<HTMLElement>) => {
    const closeToBottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < MIN_SPACE_TO_BOTTOM
    if (closeToBottom) {
      callback()
    }
  }
}
