import { ApiErrorTypes } from 'ogram-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { OgramModal } from '../../ogram-modal/ogram-modal'
import modalImg from './assets/modal-image.svg'
import { ButtonPrimary } from '../../button/button-primary/button-primary'
import { AcceptTerms } from '../accept-terms/accept-terms'
import { Separator } from '../../app-layout'
import './become-affiiate-modal.scss'

type InputProps = {
  showBecomeAffiliateModal: boolean
  setShowBecomeAffiliateModal: (value: boolean) => void
  onSuccess?: () => void
}

export const BecomeAffiliateModal = observer(
  ({ showBecomeAffiliateModal, setShowBecomeAffiliateModal, onSuccess }: InputProps) => {
    const { t } = useTranslation()
    const redirect = useRedirect()

    const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false)

    const { markClientAsAffiliate, confirmAffiliateTermsAndConditions, apiHost } = useStore().data
    const [, markClientAsAffiliateRequest] = useMutation(markClientAsAffiliate, {
      onSuccess: (result) => {
        if (onSuccess) {
          onSuccess()
        } else {
          redirect(routes.affiliateDashboard, {})
        }
      },
    })

    const [, requestConfirmTerms] = useMutation(confirmAffiliateTermsAndConditions, {
      onSuccess: () => {
        setIsTermsAndConditionsAccepted(true)
      },
      onError: (err) => alert(err.data.message),
      filterError: [ApiErrorTypes.ClientError],
    })

    return (
      <OgramModal
        overlayStyle={{ overflow: 'hidden' }}
        contentStyle={{ overflow: 'hidden' }}
        modalVisible={showBecomeAffiliateModal}
        onClose={() => setShowBecomeAffiliateModal(false)}
      >
        <div className="BecomeAffiliateModal__content">
          <span className="BecomeAffiliateModal__content__title">{t('become_affiliate.modal_title')}</span>
          <span className="BecomeAffiliateModal__content__subtitle">{t('become_affiliate.modal_subtitle')}</span>
          <img className="BecomeAffiliateModal__content__image" src={modalImg} />
          <AcceptTerms
            isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
            requestConfirmTerms={requestConfirmTerms}
            apiHost={apiHost}
          />
          <Separator.Vertical height={15} />
          <ButtonPrimary
            text={t('common.continue')}
            action={() => markClientAsAffiliateRequest({})}
            additionalStyle={{ minWidth: '304px' }}
            disabled={!isTermsAndConditionsAccepted}
          />
          <Separator.Vertical height={15} />
        </div>
      </OgramModal>
    )
  },
)
