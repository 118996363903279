import { types, flow, SnapshotIn } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const IndustryModel = types.model('Industry', {
  value: types.identifierNumber,
  label: types.string,
  key: types.string,
})

export const industryActions = (self: DataStore) => ({
  getIndustries: flow(function* () {
    const industries = (yield self.request('get', `common/industries`)) as { value: number; label: string }[]
    industries.forEach(industry => {
      self.industries.set(String(industry.value), {
        ...industry,
        key: String(industry.value),
      })
    })
  }),
})

export const industryViews = (self: DataStore) => ({
  get industriesList() {
    return Array.from(self.industries.values())
  },
})

export type Industry = SnapshotIn<typeof IndustryModel>
