import { Separator } from '../../../../../../components/app-layout/separator/separator'
import './menu-item.scss'

type Props = {
  img: React.ReactNode
  title: string
  onClick: () => void
}

export const MenuItem = ({ img, title, onClick }: Props) => {
  return (
    <div onClick={onClick} className="MenuItem">
      {img}
      <Separator.Horizontal width={16} />
      <span className="MenuItem__title">{title}</span>
    </div>
  )
}
