import { useTranslation } from 'react-i18next'

import limitedTime from '../../assets/limited-time.svg'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import './auto-confirmation-modal.scss'

interface AutoConfirmationModalProps {
  time: number
  modalIsOpen: boolean
  closeModal: () => void
}

const AutoConfirmationModal = ({ time, closeModal, modalIsOpen }: AutoConfirmationModalProps) => {
  const { t } = useTranslation()

  const hoursToShift = Math.floor(time / 3600)
  const label =
    hoursToShift < 1
      ? t('shift_attendance_common.n_minutes', { count: hoursToShift * 60 })
      : t('shift_attendance_common.n_hours', { hours: hoursToShift })
  return (
    <OgramModal modalVisible={modalIsOpen} className="AutoConfirmationModal" onClose={closeModal}>
      <img src={limitedTime} />
      <h2 className="AutoConfirmationModal__modalTitle">{t('shift_attendance_common.limited_time_to_confirm')}</h2>
      <p>{t('shift_attendance_common.you_have_n_to_review', { time: label })}</p>
      <button onClick={closeModal} className="AutoConfirmationModal__closeButton">
        {t('shift_attendance_common.i_understand')}
      </button>
    </OgramModal>
  )
}

export default AutoConfirmationModal
