import { useTranslation } from 'react-i18next'

import accessDeniedErrorImage from '../assets/not-authorized-error.svg'
import './permission-error.scss'

const PermissionError = ({ resetError }: { resetError: () => void }) => {
  const { t } = useTranslation()

  const handleBack = () => {
    window.history.back()
    resetError()
  }

  return (
    <div className="PermissionError">
      <div className="PermissionError__container">
        <img src={accessDeniedErrorImage} />
        <h2 className="PermissionError__modalTitle">{t('permission_error.title')}</h2>
        <button onClick={handleBack} className="PermissionError__backButton">
          {t('permission_error.back')}
        </button>
      </div>
    </div>
  )
}

export default PermissionError
