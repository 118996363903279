import { types } from 'mobx-state-tree'
import moment from 'moment'

import { OrderExtension } from '../../../models/order/order-summary'

export enum ExtensionStep {
  ExtensionTypeSelection = 0,
  DateSelection = 1,
}

export const SHOWN_STEPS_COUNT = 5

const JobExtensionModel = types.model('JobExtensionModel', {
  id: types.identifierNumber,
  location: types.string,
  designation: types.string,
  staffAmount: types.number,
  startTime: types.string,
  endTime: types.string,
  minExtensionDate: types.string,
  minStartDate: types.string,
  startDate: types.maybeNull(types.string),
  extensionDate: types.maybeNull(types.string),
  shouldBeExtended: types.boolean,
})

type OrderModel = {
  id: number
  end_date: string | null
  jobs: {
    id: number
    location_name: string
    work_category_name: string
    quantity: number
    start_date: string
    end_date: string
    min_extension_date: string
  }[]
}

export const OrderExtensionModel = types
  .model('OrderExtensionModel', {
    id: types.maybeNull(types.number),
    minStartDateForAllJobs: types.maybeNull(types.string),
    useSameExtensionDateForAllJobs: types.boolean,
    extensionStartDateForAllJobs: types.maybeNull(types.string),
    extensionDateForAllJobs: types.maybeNull(types.string),
    extensionStep: types.maybeNull(types.number),
    jobs: types.map(JobExtensionModel),
  })
  .actions(self => ({
    createFromOrderModel(orderModel: OrderModel) {
      self.id = orderModel.id
      self.minStartDateForAllJobs = moment(orderModel.end_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')

      orderModel.jobs.forEach(job => {
        self.jobs.set(
          String(job.id),
          JobExtensionModel.create({
            id: job.id,
            location: job.location_name,
            designation: job.work_category_name,
            staffAmount: job.quantity,
            startTime: job.start_date,
            endTime: job.end_date,
            minExtensionDate: job.min_extension_date,
            shouldBeExtended: true,
            startDate: null,
            extensionDate: null,
            minStartDate: moment(job.end_date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'),
          }),
        )
      })
    },
    setUseSameExtensionDateForAllJobs(value: boolean) {
      self.useSameExtensionDateForAllJobs = value
    },
    setExtensionStartDateForAllJobs(value: string | null) {
      self.extensionStartDateForAllJobs = value
    },
    setExtensionDateForAllJobs(value: string | null) {
      self.extensionDateForAllJobs = value
    },
    setExtensionStep(value: number) {
      self.extensionStep = value
    },
    goToNextStep() {
      if (self.extensionStep !== null) {
        self.extensionStep++
      }
    },
    goToPreviousStep() {
      if (self.extensionStep !== null) {
        self.extensionStep--
      }
    },
    setJobExtensionDate(jobId: number, extensionDate: string | null) {
      const job = self.jobs.get(String(jobId))
      if (job) {
        job.extensionDate = extensionDate

        const shouldBeExtended = Boolean(extensionDate)
        job.shouldBeExtended = shouldBeExtended
        if (!shouldBeExtended) {
          job.startDate = null
        }
      }
    },
    setJobExtensionStartDate(jobId: number, startDate: string | null) {
      const job = self.jobs.get(String(jobId))
      if (job) {
        job.startDate = startDate

        const shouldBeExtended = Boolean(startDate)
        job.shouldBeExtended = Boolean(startDate)
        if (!shouldBeExtended) {
          job.extensionDate = null
        }
      }
    },
    clear() {
      self.id = null
      self.minStartDateForAllJobs = null
      self.extensionDateForAllJobs = null
      self.extensionStartDateForAllJobs = null
      self.extensionStep = 0
      self.jobs.clear()
    },
  }))
  .views(self => ({
    get jobsList() {
      return Array.from(self.jobs.values())
    },
    get dataForApiCall(): OrderExtension {
      return {
        id: Number(self.id),
        jobsToExtend: Array.from(self.jobs.values())
          .filter(job => self.useSameExtensionDateForAllJobs || job.extensionDate !== null)
          .map(job => {
            return {
              id: job.id,
              startDate: self.useSameExtensionDateForAllJobs
                ? String(self.extensionStartDateForAllJobs)
                : String(job.startDate),
              extensionDate: self.useSameExtensionDateForAllJobs
                ? String(self.extensionDateForAllJobs)
                : String(job.extensionDate),
            }
          }),
      }
    },
    get jobsForExtensionExist() {
      const jobs = Array.from(self.jobs.values())

      if (self.useSameExtensionDateForAllJobs) {
        return self.extensionStartDateForAllJobs !== null && self.extensionDateForAllJobs !== null
      }

      return jobs.filter(job => job.startDate !== null && job.extensionDate !== null).length > 0
    },
    get minOrderExtensionDate() {
      const maxTimestamp = Math.max(
        ...Array.from(self.jobs.values()).map(job => moment(job.minExtensionDate).valueOf()),
      )

      return moment(maxTimestamp).format('YYYY-MM-DD')
    },
  }))
