import { useTranslation } from 'react-i18next'

import notFoundImage from '../assets/404.svg'
import './page-not-found.scss'

interface PageNotFoundProps {
  resetError: () => void
}

const PageNotFound = ({ resetError }: PageNotFoundProps) => {
  const { t } = useTranslation()

  return (
    <div className="PageNotFound">
      <div className="PageNotFound__container">
        <img src={notFoundImage} />
        <h2 className="PageNotFound__modalTitle">{t('page_not_found.title')}</h2>
        <p>{t('page_not_found.description')}</p>
        <button
          onClick={() => {
            resetError()
            window.history.back()
          }}
          className="PageNotFound__backButton"
        >
          {t('page_not_found.go_back')}
        </button>
      </div>
    </div>
  )
}

export default PageNotFound
