import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { ShiftCancelReasonSnapshot } from '../../../../../../../../../../../../models/shift-cancel-reason'
import { OgramModal } from '../../../../../../../../../../../../components/ogram-modal/ogram-modal'
import { Separator } from '../../../../../../../../../../../../components/app-layout/separator/separator'
import { Select } from '../../../../../../../../../../../../components/form/select/select'
import { Button } from '../../../../../../../../../../../../components/button/button'
import { Checkbox } from '../../../../../../../../../../../../components/form/checkbox/checkbox'
import TextInput from '../../../../../../../../../../../../components/text-input/text-input'
import './cancel-shift-modal.scss'
import { TrackedEvent, trackingService } from '../../../../../../../../../../../../services/tracking-service'

type Props = {
  cancelReasons: ShiftCancelReasonSnapshot[]
  shiftId: number
  ogrammerName: string
  modalVisible: boolean
  onClose: () => void
  onSubmit: (params: {
    id: number
    reasonId: number
    reasonDetails: string
    cancelFollowing: boolean
    blockOgrammer: boolean
  }) => void
}

export const CancelShiftModal = observer(
  ({ cancelReasons, shiftId, ogrammerName, modalVisible, onClose, onSubmit }: Props) => {
    const { t } = useTranslation()

    if (!modalVisible) return null

    return (
      <OgramModal modalVisible={modalVisible} onClose={onClose} className="CancelShiftModal">
        <Separator.Vertical height={29} />
        <span className="CancelShiftModal__title">{t('job_shifts.cancel_shift')}</span>
        <Separator.Vertical height={27} />
        <span className="CancelShiftModal__subtitle">
          You are about to cancel <span className="CancelShiftModal__subtitle-highlighted">{ogrammerName}</span>{' '}
          {t('job_shifts.for_the_job')}
        </span>
        <Separator.Vertical height={27} />
        <Formik
          initialValues={{
            reason: cancelReasons[0].name,
            comment: '',
            blockOgrammer: false,
            cancelFollowingShifts: false,
          }}
          onSubmit={values => {
            onSubmit({
              id: shiftId,
              reasonId: (cancelReasons.find(reason => reason.name === values.reason) as ShiftCancelReasonSnapshot).id,
              reasonDetails: values.comment,
              cancelFollowing: values.cancelFollowingShifts,
              blockOgrammer: values.blockOgrammer,
            })

            trackingService.track(TrackedEvent.TimesheetCancellationConfirmed)
          }}
          validationSchema={Yup.object().shape({
            reason: Yup.string().required(),
          })}
        >
          {formikProps => (
            <Form noValidate className="CancelShiftModal__form">
              <Select
                label="Select A Reason"
                value={formikProps.getFieldProps('reason').value}
                onChange={value => {
                  formikProps.setFieldValue('reason', value)
                }}
                options={cancelReasons.map(reason => ({
                  key: String(reason.id),
                  value: reason.name,
                  label: reason.name,
                }))}
              />
              <Separator.Vertical height={20} />
              <div className="CancelShiftModal__textarea-container">
                <TextInput
                  formikProps={formikProps}
                  type="textarea"
                  label="Comment here (Optional)"
                  formikKey="comment"
                />
              </div>
              <Separator.Vertical height={22} />
              <div className="CancelShiftModal__row">
                <Checkbox
                  value={formikProps.getFieldProps('blockOgrammer').value}
                  onChange={value => {
                    formikProps.setFieldValue('blockOgrammer', value)
                  }}
                />
                <span className="CancelShiftModal__checkbox-description">{t('job_shifts.block_ogrammer')}</span>
              </div>
              <Separator.Vertical height={15} />
              <div className="CancelShiftModal__row">
                <Checkbox
                  value={formikProps.getFieldProps('cancelFollowingShifts').value}
                  onChange={value => {
                    if (value) {
                      trackingService.track(TrackedEvent.TimesheetCancellationAllShiftsSelected)
                    }
                    formikProps.setFieldValue('cancelFollowingShifts', value)
                  }}
                />
                <span className="CancelShiftModal__checkbox-description">
                  {t('job_shifts.cancel_all_following_shifts')}
                </span>
              </div>
              <Separator.Vertical height={50} />
              <Button type="submit" title={t('job_shifts.cancel_shift')} className="CancelShiftModal__confirm-button" />
              <Separator.Vertical height={16} />
              <Button
                title={t('job_shifts.cancel')}
                onClick={() => {
                  onClose()

                  trackingService.track(TrackedEvent.TimesheetCancellationCancelled)
                }}
              />
              <Separator.Vertical height={30} />
            </Form>
          )}
        </Formik>
      </OgramModal>
    )
  },
)
