import { Experience } from '../../../models/seniority-level'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { t } from '../../../translations'

export const getExperienceName = (experience: Experience) => {
  switch (experience) {
    case Experience.junior:
      return t('job_draft_details.junior')
    case Experience.middle:
      return t('job_draft_details.midSenior')
    case Experience.senior:
      return t('job_draft_details.senior')
    case Experience.beginnerOrStudent:
      return t(getCurrentMarketConfig().order.nonExperiencedTitle)
  }
}
