import './pagination.scss'
import { useTranslation } from 'react-i18next'

import prevImg from './assets/prev.svg'
import nextImg from './assets/next.svg'

type InputProps = {
  totalPages: number
  currentPage: number
  handleNext: () => void
  handlePrev: () => void
}

export const Pagination = ({ totalPages, currentPage, handleNext, handlePrev }: InputProps) => {
  const { t } = useTranslation()

  const isPrevDisabled = currentPage === 1
  const isNextDisabled = currentPage === totalPages

  const onPrev = () => {
    if (!isPrevDisabled) {
      handlePrev()
    }
  }

  const onNext = () => {
    if (!isNextDisabled) {
      handleNext()
    }
  }

  return (
    <div className="Pagination">
      <div onClick={onPrev} className="Pagination__left" style={isPrevDisabled ? { cursor: 'not-allowed' } : {}}>
        <img src={prevImg} />
        <span>{t('page_stepper.previous')}</span>
      </div>
      <div className="Pagination__middle">
        <span className="Pagination__middle__pages">
          <span className="Pagination__middle__pages__current">{currentPage}</span> {t('page_stepper.of')} {totalPages}
        </span>
      </div>
      <div onClick={onNext} className="Pagination__right" style={isNextDisabled ? { cursor: 'not-allowed' } : {}}>
        <span>{t('page_stepper.next')}</span>
        <img src={nextImg} />
      </div>
    </div>
  )
}
