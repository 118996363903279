import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { ApiError } from 'ogram-react'

import { createBoundary } from '../../utils/create-boundary'
import { TooManyAttempts } from '../too-many-attempts/too-many-attempts'
import { handleReactErrorCatch } from '../../services/sentry'
import PageNotFound from '../page-not-found/page-not-found'
import NotAuthorizedError from '../not-authorized-error/not-authorized-error'
import PermissionError from '../permission-error/permission-error'
import './error.scss'

export const ErrorBoundary = createBoundary(
  (props) => {
    const tryAgain = () => {
      props.resetError()
    }

    const { t } = useTranslation()

    const error = props.error
    if (error !== null) {
      if (error instanceof ApiError) {
        if (error.type === ApiErrorTypes.ServerError) {
          return <ErrorView tryAgain={tryAgain}>{t('error_boundary.default.server_error')}</ErrorView>
        }
        if (error.type === ApiErrorTypes.ConnectionError) {
          return <ErrorView tryAgain={tryAgain}>{t('error_boundary.default.connection_error')}</ErrorView>
        }
        if (error.type === ApiErrorTypes.TooManyRequestsError) {
          return <TooManyAttempts resetError={props.resetError} />
        }
        if (error.type === ApiErrorTypes.NotFound) {
          return <PageNotFound resetError={props.resetError} />
        }
        if (error.type === ApiErrorTypes.AuthError) {
          return <NotAuthorizedError resetError={props.resetError} />
        }
        if (error.type === ApiErrorTypes.PermissionError) {
          return <PermissionError resetError={props.resetError} />
        }
      }
      return <ErrorView>{t('error_boundary.default.default_error')}</ErrorView>
    }
    return <>{props.children}</>
  },
  { didCatch: handleReactErrorCatch },
)

function ErrorView(props: { children: React.ReactNode; tryAgain?: () => void }) {
  const { t } = useTranslation()

  return (
    <div>
      {props.children}
      {props.tryAgain && (
        <div>
          <button onClick={props.tryAgain}>{t('error_boundary.default.try_again')}</button>
        </div>
      )}
    </div>
  )
}
