import { flow, SnapshotOut, types } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

export const SORT_BY_JOB_START_DATE = 'last_job_start_date'
export const SORT_BY_CLIENT_RATING_FOR_LAST_JOB = 'client_rating_for_last_job'

export type AllowedSortBy = 'last_job_start_date' | 'client_rating_for_last_job'
export type AllowedOrderBy = 'ASC' | 'DESC'

export type PastWorkersListAPIParams = {
  page: number
  sortBy: AllowedSortBy
  orderBy: AllowedOrderBy
  collect: boolean
  locationId: number | null
  designationId: number | null
  isFavorite: boolean | null
  searchString: string
}

const PastWorkerModel = types.model('PastWorkerModel', {
  id: types.identifierNumber,
  name: types.string,
  image_url: types.maybeNull(types.string),
  is_favorite: types.boolean,
  designations: types.array(types.string),
  locations: types.array(types.string),
  hours_amount_worked_for_client: types.number,
  shifts_amount_worked_for_client: types.integer,
  client_rating_for_last_job: types.maybeNull(types.number),
  last_job_id: types.maybeNull(types.number),
  last_job_start_date: types.maybeNull(types.string),
  last_job_end_date: types.maybeNull(types.string),
  is_available: types.maybeNull(types.boolean),
  note: types.maybeNull(types.string),
})

export const PastWorkersModel = types.model('PastWorkersModel', {
  list: types.map(PastWorkerModel),
  invitedSPs: types.map(PastWorkerModel),
  currentPage: types.number,
  totalPages: types.number,
  nextPage: types.maybeNull(types.number),
  total: types.number,
})

export const pastWorkersActions = (self: DataStore) => ({
  getInvitedPastSPsByIds: flow(function* (ids: number[] = []) {
    let url = `client/sp/past?`
    ids.forEach(id => {
      url += `&sp_ids[]=${id}`
    })

    const { list } = (yield self.request('get', url)) as {
      list: PastSPSnapshot[]
    }

    list.forEach(item => {
      self.pastWorkers.invitedSPs.set(item.id, item)
    })
  }),
  getPastSPs: flow(function* ({
    page = 1,
    ids = [],
    searchString = '',
    collect = true,
    availableFrom = null,
    availableTo = null,
    sortBy = SORT_BY_JOB_START_DATE,
    orderBy = 'DESC',
    locationId = null,
    designationId = null,
    isFavorite = null,
    preferredLocationId = null,
    preferredDesignationId = null,
    jobId = null,
  }: {
    page?: number
    ids?: number[]
    searchString?: string
    collect?: boolean
    availableFrom?: string | null
    availableTo?: string | null
    sortBy?: AllowedSortBy | null
    orderBy?: AllowedOrderBy | null
    locationId?: number | null
    designationId?: number | null
    isFavorite?: boolean | null
    preferredLocationId?: number | null
    preferredDesignationId?: number | null
    jobId?: number | null
  }) {
    let url = `client/sp/past?page=${page}&sort_by=${sortBy}&order_by=${orderBy}`
    if (locationId) {
      url += `&location_id=${locationId}`
    }
    if (designationId) {
      url += `&designation_id=${designationId}`
    }
    if (isFavorite) {
      url += `&is_favorite=${1}`
    }
    if (searchString !== '') {
      url += `&search_string=${searchString}`
    }
    if (availableFrom) {
      url += `&available_from=${availableFrom}`
    }
    if (availableTo) {
      url += `&available_to=${availableTo}`
    }
    if (preferredLocationId) {
      url += `&preferred_location_id=${preferredLocationId}`
    }
    if (preferredDesignationId) {
      url += `&preferred_designation_id=${preferredDesignationId}`
    }
    if (jobId) {
      url += `&job_id=${jobId}`
    }
    ids.forEach(id => {
      url += `&sp_ids[]=${id}`
    })

    const { list, paginator } = (yield self.request('get', url)) as {
      list: PastSPSnapshot[]
      paginator?: Paginator
    }

    if (!collect) {
      self.pastWorkers.list.clear()
    }
    list.forEach(item => {
      self.pastWorkers.list.set(item.id, item)
    })

    self.pastWorkers.totalPages = Number(paginator?.last_page)
    self.pastWorkers.currentPage = page
    self.pastWorkers.total = Number(paginator?.total)
    self.pastWorkers.nextPage = paginator?.next_page ?? null
  }),
  setSPNote: flow(function* (spId: number, note: string) {
    const sp = self.pastWorkers.list.get(spId)
    // eslint-disable-next-line
    if (!!sp) {
      sp.note = note
    }

    yield self.request('post', `client/sp/${spId}/note`, { note })
  }),
  setSPLastJobRating: flow(function* (
    spId: number,
    rating: number,
    locationId?: number | null,
    workCategoryId?: number | null,
    jobId?: number | null,
  ) {
    const sp = self.pastWorkers.list.get(spId)
    // eslint-disable-next-line
    if (!!sp) {
      sp.client_rating_for_last_job = rating
    }

    yield self.request('post', `client/sp/${spId}/last-job-rating`, { rating, locationId, workCategoryId, jobId })
  }),
  toggleIsFavorite: flow(function* (spId: number) {
    const sp = self.pastWorkers.list.get(spId)
    // eslint-disable-next-line
    if (!!sp) {
      const isFavorite = sp.is_favorite
      if (isFavorite) {
        // eslint-disable-next-line
        sp.is_favorite = false
        yield self.request('post', `client/sp/un-save`, { sp_id: spId })
      } else {
        // eslint-disable-next-line
        sp.is_favorite = true
        yield self.request('post', `client/sp/save`, { sp_id: spId })
      }
    }
  }),
})

export const pastWorkersViews = (self: DataStore) => ({
  get pastSPsList() {
    return Array.from(self.pastWorkers.list.values())
  },
  get invitedPastSPsList() {
    return Array.from(self.pastWorkers.invitedSPs.values())
  },
  get pastSPsPagesCount() {
    return self.pastWorkers.totalPages
  },
  get pastSPsTotalCount() {
    return self.pastWorkers.total
  },
  get pastSPsNextPage() {
    return self.pastWorkers.nextPage
  },
})

export type PastSPSnapshot = SnapshotOut<typeof PastWorkerModel>
