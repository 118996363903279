import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { routes } from '../../../../routes'
import { useRedirect } from '../../../../services/router'
import { useStore, useQuery, useMutation } from '../../../../utils/mst-hooks'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { Select } from '../../../../components/form/select/select'
import { Button } from '../../../../components/button/button'
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner'
import './amend-modal.scss'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'

type Props = {
  modalVisible: boolean
  orderId: string
  onClose: () => void
}

export const AmendModal = withErrorBoundary(
  observer(({ modalVisible, orderId, onClose }: Props) => {
    const [reason, setReason] = useState<string>('')

    const { amendReasonList, getAmendReasons, setOrderAmendReason, getAmendReasonIdByName } = useStore().data

    const { isLoading: loadingReasons } = useQuery(getAmendReasons)

    useEffect(() => {
      if (amendReasonList.length > 0) {
        setReason(amendReasonList[0].name)
      }
    }, [amendReasonList, amendReasonList.length])

    const redirect = useRedirect()

    const [{ isLoading: submittingAmendReason }, sendAmendReason] = useMutation(setOrderAmendReason, {
      onSuccess: () => {
        redirect(routes.orderDraft, { orderId })
      },
    })

    const { t } = useTranslation()

    const options = amendReasonList.map((reasonItem) => ({
      key: String(reasonItem.id),
      label: reasonItem.name,
      value: reasonItem.name,
    }))

    if (!modalVisible) return null

    return (
      <OgramModal modalVisible={modalVisible} onClose={onClose} className="AmendModal">
        {loadingReasons ? (
          <LoadingSpinner />
        ) : (
          <>
            <Separator.Vertical height={29} />
            <span className="AmendModal__title">{t('order.select_amend_reason')}</span>
            <Separator.Vertical height={28} />
            <Select label={t('order.select_reason')} value={reason} onChange={setReason} options={options} />
            <Separator.Vertical height={31} />
            <Button
              onClick={() => {
                sendAmendReason({
                  orderId,
                  reasonId: getAmendReasonIdByName(reason) as number,
                })
              }}
              disabled={!reason || submittingAmendReason}
              title={submittingAmendReason ? t('order.submitting') : t('order.continue')}
              className="AmendModal__confirmButton"
            />
            <Separator.Vertical height={16} />
            <Button title={t('order.cancel')} onClick={onClose} className="AmendModal__cancelButton" />
            <Separator.Vertical height={30} />
          </>
        )}
      </OgramModal>
    )
  }),
)
