import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import './shift-timings-prompt.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'

export const ShiftTimingsPrompt = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const hasErrors = job.schedule.sameTimeSchedule.sameTimePromptHasErrors

  return (
    <div className="ShiftTimingsPrompt">
      <span className="Popup__title">{t('job_draft_details.what_are_the_shifts_timings')}</span>
      <div className="ShiftTimingsPrompt__innerContainer">
        <Button
          onClick={() => {
            job.setAllDaysHaveSameTime(true)
          }}
          className={`ShiftTimingsPrompt__innerContainer__button ${
            job.schedule.sameTimeSchedule.allDaysHaveSameTime === true ? 'active' : undefined
          } ${hasErrors ? 'error' : ''}`}
        >
          {t('job_draft_details.start_and_ends_are_same')}
        </Button>
        <Separator.Vertical height={10} />
        <Button
          onClick={() => {
            job.setAllDaysHaveSameTime(false)
          }}
          className={`ShiftTimingsPrompt__innerContainer__button ${
            job.schedule.sameTimeSchedule.allDaysHaveSameTime === false ? 'active' : undefined
          } ${hasErrors ? 'error' : ''}`}
        >
          {t('job_draft_details.time_vary')}
        </Button>
        {hasErrors && <JobDraftFieldError error={job.schedule.sameTimeSchedule.all_days_have_same_time_error} />}
      </div>
    </div>
  )
})
