import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { InvoiceJobShifts } from '../../../models/invoice'
import { useStore, useQuery } from '../../../utils/mst-hooks'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { Separator } from '../../../components/app-layout/separator/separator'
import ogDetailsImg from './assets/og-details.svg'
import shiftDetailsImg from './assets/shift-details.svg'
import './invoice-ogrammer.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

type Props = {
  invoiceItemId: string
  ogrammerId: string
  ogrammerName: string
}

export const InvoiceOgrammer = withErrorBoundary(
  observer(({ invoiceItemId, ogrammerId }: Props) => {
    const [invoiceJobShifts, setInvoiceJobShifts] = useState<InvoiceJobShifts>()

    const { t } = useTranslation()

    const { getInvoiceJobShfits } = useStore().data

    const { isLoading } = useQuery(() => getInvoiceJobShfits({ invoiceItemId, ogrammerId }), {
      onSuccess: setInvoiceJobShifts,
    })

    return (
      <div className="InvoiceOgrammer">
        <Separator.Vertical height={30} />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="InvoiceOgrammer__sectionHeader">
              <img src={ogDetailsImg} className="InvoiceOgrammer__sectionHeader__img" />
              <Separator.Horizontal width={10} />
              <span className="InvoiceOgrammer__sectionHeader__title">{t('invoice_ogrammer.og_details')}</span>
            </div>
            <Separator.Vertical height={16} />
            <div className="InvoiceOgrammer__section">
              <div className="InvoiceOgrammer__section__row">
                <span className="InvoiceOgrammer__section__row__label">{t('invoice_ogrammer.job')}</span>
                <span className="InvoiceOgrammer__section__row__value">{invoiceJobShifts?.invoice.title}</span>
              </div>
              <div className="InvoiceOgrammer__section__row">
                <span className="InvoiceOgrammer__section__row__label">{t('invoice_ogrammer.location')}</span>
                <span className="InvoiceOgrammer__section__row__value">{invoiceJobShifts?.invoice.location}</span>
              </div>
              <div className="InvoiceOgrammer__section__row">
                <span className="InvoiceOgrammer__section__row__label">{t('invoice_ogrammer.shifts')}</span>
                <span className="InvoiceOgrammer__section__row__value">{invoiceJobShifts?.shifts.length}</span>
              </div>
              <div className="InvoiceOgrammer__section__row">
                <span className="InvoiceOgrammer__section__row__label">{t('invoice_ogrammer.hours')}</span>
                <span className="InvoiceOgrammer__section__row__value">{invoiceJobShifts?.invoice.hours}</span>
              </div>
              <div className="InvoiceOgrammer__section__row">
                <span className="InvoiceOgrammer__section__row__label">{t('invoice_ogrammer.amount_due')}</span>
                <span className="InvoiceOgrammer__section__row__value">
                  {invoiceJobShifts?.invoice.price} {invoiceJobShifts?.invoice.currency_code}
                </span>
              </div>
            </div>
            <Separator.Vertical height={30} />
            <div className="InvoiceOgrammer__sectionHeader">
              <img src={shiftDetailsImg} className="InvoiceOgrammer__sectionHeader__img" />
              <Separator.Horizontal width={10} />
              <span className="InvoiceOgrammer__sectionHeader__title">{t('invoice_ogrammer.shift_details')}</span>
            </div>
            <Separator.Vertical height={16} />
            <div className="InvoiceOgrammer__shiftsSection">
              <div className="InvoiceOgrammer__shiftsSection__shiftRow">
                <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                  <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__label">
                    {t('invoice_ogrammer.day')}
                  </span>
                </div>
                <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                  <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__label">
                    {t('invoice_ogrammer.hours')}
                  </span>
                </div>
                <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                  <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__label">
                    {t('invoice_ogrammer.approved_by')}
                  </span>
                </div>
              </div>
              {invoiceJobShifts?.shifts.map((item, index) => (
                <div
                  key={index}
                  className={`InvoiceOgrammer__shiftsSection__shiftRow ${index % 2 === 0 ? 'even' : ''}`}
                >
                  <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                    <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__value">{item.date}</span>
                  </div>
                  <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                    <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__value">{item.hours}</span>
                  </div>
                  <div className="InvoiceOgrammer__shiftsSection__shiftRow__column">
                    <span className="InvoiceOgrammer__shiftsSection__shiftRow__column__value">
                      {item.approver.full_name}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <Separator.Vertical height={30} />
          </>
        )}
      </div>
    )
  }),
)
