import checkmarkImg from './assets/checkmark.svg'
import disabledImg from './assets/disabled.svg'
import './checkbox.scss'

type Checkbox = {
  isActive: boolean
  onChange?: () => void
  type?: 'square' | 'circle'
  disabled?: boolean
}

export const Checkbox = ({ isActive, onChange, type = 'square', disabled }: Checkbox) => (
  <div
    onClick={() => {
      onChange?.()
    }}
    className={`Checkbox ${type === 'square' ? 'square' : 'circle'} ${isActive ? 'active' : ''}`}
  >
    {!disabled ? (
      <>
        {isActive &&
          (type === 'square' ? (
            <img src={checkmarkImg} className="Checkbox__image" />
          ) : (
            <div className="Checkbox__centerCircle" />
          ))}
      </>
    ) : (
      <>
        <img src={disabledImg} />
      </>
    )}
  </div>
)
