import './app-version.scss'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getCurrentAppVersion } from '../../utils/get-current-app-version'

export const AppVersion = () => {
  const [version, setVersion] = useState<string | null>(null)
  const { t } = useTranslation()

  useEffect(() => {
    ;(async () => {
      setVersion(await getCurrentAppVersion())
    })()
  }, [])

  if (version === null) {
    return null
  }

  return (
    <div className="AppVersion">
      <span className="AppVersion__title">{t('common.app_version', { version })}</span>
    </div>
  )
}
