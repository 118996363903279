import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/orders-confirmation'
import '../onboarding.scss'

export const ordersConfirmationOnboarding: OnboardingContent = {
  title: t('order_confirmation_onboarding.title'),
  paths: [routes.orders.path],
  pages: [
    {
      title: t('order_confirmation_onboarding.pending_confirmation_title'),
      content: [
        { image: { src: images.ordersPendingConfirmationImg, className: 'Onboarding__bigImg' } },
        { description: t('order_confirmation_onboarding.pending_confirmation') },
      ],
    },
    {
      title: t('order_confirmation_onboarding.order_confirmation_title'),
      content: [
        { image: { src: images.orderConfirmationImg, className: 'Onboarding__bigImg' } },
        { description: t('order_confirmation_onboarding.order_confirmation') },
      ],
    },
    {
      title: t('order_confirmation_onboarding.order_amend_title'),
      content: [
        { image: { src: images.orderAmendImg, className: 'Onboarding__bigImg' } },
        { description: t('order_confirmation_onboarding.order_amend') },
      ],
    },
  ],
}
