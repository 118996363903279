import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Heart } from '../heart'
import ApplicantDetails from '../../../applicants-review/applicant-details/applicant-details'
import { Popup } from '../../../../components/popup/popup'
import { ListItemProps, pastWorkersStore } from '../../../../models-ui/past-workers'
import { Rating } from '../rating/rating'
import { NoteInput } from '../note-input/note-input'
import { TrackedEvent, trackingService } from '../../../../services/tracking-service'

export const TableItem = observer(({ sp, setSPNote, setSPLastJobRating, toggleIsFavorite }: ListItemProps) => {
  const { t } = useTranslation()

  const [isEditRatingMode, setIsEditRatingMode] = useState(sp.client_rating_for_last_job === null)
  const [isProfileShown, setIsProfileShown] = useState(false)

  const changeProfileShown = (value: boolean) => {
    if (value) {
      trackingService.track(TrackedEvent.PastWorkersProfileOpened)
    }

    setIsProfileShown(value)
  }

  const handleSetRating = (value: number) => {
    setSPLastJobRating({
      spId: sp.id,
      rating: value,
      locationId: pastWorkersStore.searchFilters.locationId,
      workCategoryId: pastWorkersStore.searchFilters.designationId,
    })
    setIsEditRatingMode(false)
  }

  return (
    <tr className="WorkersList__table__item">
      <td className="WorkersList__table__item__main" style={{ width: '20%' }}>
        <div className="WorkersList__table__item__expanded">
          <div className="WorkersList__table__item__left">
            <img
              className="WorkersList__table__item__info__image"
              onClick={() => changeProfileShown(true)}
              src={sp.image_url ?? undefined}
            />
            <div className="WorkersList__table__item__info" onClick={() => changeProfileShown(true)}>
              <span className="WorkersList__table__item__info__primary">{sp.name}</span>
              <span className="WorkersList__table__item__info__secondary">{t('common.id_n', { id: sp.id })}</span>
            </div>
          </div>
          <div className="WorkersList__table__item__info__icon">
            <Heart isActive={sp.is_favorite} onClick={() => toggleIsFavorite({ spId: sp.id })} />
          </div>
        </div>
      </td>
      <td style={{ width: '13%' }}>
        <div className="WorkersList__table__item__info">
          <span className="WorkersList__table__item__info__primary">{sp.designations[0]}</span>
        </div>
      </td>
      <td style={{ width: '8%' }}>
        <div className="WorkersList__table__item__info">
          <span className="WorkersList__table__item__info__primary">
            {t('staff.n_shifts', { shiftsAmount: sp.shifts_amount_worked_for_client })}
          </span>
        </div>
      </td>
      <td style={{ width: '8%' }}>
        <div className="WorkersList__table__item__info">
          <span className="WorkersList__table__item__info__primary">{sp.last_job_end_date}</span>
        </div>
      </td>
      <td style={{ width: '13%' }}>
        <div className="WorkersList__table__item__info">
          <span className="WorkersList__table__item__info__primary">
            {sp.locations[0].length > 25 ? `${sp.locations[0].slice(0, 25)}...` : sp.locations[0]}
          </span>
        </div>
      </td>
      <td style={{ width: '13%' }}>
        <div className="WorkersList__table__item__expanded">
          <Rating
            isEditRatingMode={isEditRatingMode}
            setIsEditRatingMode={setIsEditRatingMode}
            rating={Number(sp.client_rating_for_last_job)}
            handleSetRating={handleSetRating}
          />
        </div>
      </td>
      <td style={{ width: '25%' }}>
        <div className="WorkersList__table__item__info">
          <NoteInput
            setSPNote={setSPNote}
            sp={sp}
            className="WorkersList__table__item__info__textarea"
            placeholder=""
          />
        </div>
      </td>
      <Popup
        isActive={isProfileShown}
        content={
          <ApplicantDetails
            jobId={String(sp.last_job_id)}
            applicantId={String(sp.id)}
            applicantName={sp.name}
            showHeader={false}
            showControls={false}
          />
        }
        close={() => changeProfileShown(false)}
      />
    </tr>
  )
})
