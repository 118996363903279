import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'

import { QuestionSnapshot } from '../../../../../models/interview'
import { useStore, useMutation } from '../../../../../utils/mst-hooks'
import { Checkbox } from '../../../../checkbox/checkbox'
import { Button } from '../../../../button/button'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { SearchInput } from '../../../../search-input/search-input'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { useBottomListListener } from '../../../../../utils/use-bottom-list-listener'
import { QuestionCreate } from './components/question-create'
import plusImg from '../assets/plus.svg'
import { JobDraftFieldError } from '../error/error'
import './interview.scss'

export const JobDraftInterview = observer(({ job }: { job: DraftJobInstance }) => {
  const { nextQuestionPage, getQuestionById, getQuestions } = useStore().data
  const [filteredQuestions, setFilteredQuestions] = useState<number[]>([])
  const [calculatingList, setCalculatingList] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [questionModalVisible, setQuestionModalVisible] = useState(false)
  const [localQuestions, setLocalQuestions] = useState<number[]>([])

  const [{ isLoading: questionsLoading }, loadQuestions] = useMutation(getQuestions, {
    onSuccess: (questions: QuestionSnapshot[]) => {
      setLocalQuestions(oldQuestions => [...oldQuestions, ...questions.map(q => q.id)])
    },
  })

  const filterQuestions = useDebouncedCallback((value: string) => {
    setFilteredQuestions(
      localQuestions.filter(id => {
        const question = getQuestionById(id)
        return question?.description.toLowerCase().includes(value.toLowerCase())
      }),
    )
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  useEffect(() => {
    loadQuestions({ page: 1 })
  }, [])

  useEffect(() => {
    if (localQuestions.length > 0) {
      setFilteredQuestions(localQuestions)
    }
  }, [localQuestions, localQuestions.length])

  const handleScroll = useBottomListListener(() => {
    if (nextQuestionPage !== null) {
      loadQuestions({ page: nextQuestionPage })
    }
  })

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterQuestions(value)
  }

  return (
    <div className="JobDraftInterview">
      <span className="Popup__title">{t('job_draft_details.interview_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.interview_subtitle')}</span>
      {questionsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            className="JobDraftInterview__searchContainer"
          />
          <Separator.Vertical height={18} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div
              className={`JobDraftInterview__list ${job.manualSelection.hasInterviewError ? 'error' : ''}`}
              onScroll={handleScroll}
            >
              {filteredQuestions.map(id => {
                const selected = job.manualSelection.hasQuestion(id)
                return (
                  <div
                    onClick={() => {
                      if (selected) {
                        job.manualSelection.removeQuestion(id)
                      } else {
                        job.manualSelection.addQuestion(id)
                      }
                    }}
                    key={String(id)}
                    className="JobDraftInterview__list__item"
                  >
                    <span className="JobDraftInterview__list__item__label">{getQuestionById(id)?.description}</span>
                    <Checkbox isActive={selected} type="square" />
                  </div>
                )
              })}
            </div>
          )}
          <JobDraftFieldError error={job.manualSelection.interview_error} />
          <Separator.Vertical height={16} />
          <Button
            className="JobDraftInterview__addQuestionContainer"
            onClick={() => {
              setQuestionModalVisible(true)
            }}
          >
            <img src={plusImg} className="" />
            <Separator.Horizontal width={12} />
            {t('job_draft_details.interview_new_question')}
          </Button>
          <Separator.Vertical height={20} />
        </>
      )}
      <QuestionCreate
        visible={questionModalVisible}
        onCreate={newQuestion => {
          setQuestionModalVisible(false)
          setLocalQuestions(questions => [...questions, newQuestion.id])
          job.manualSelection.addQuestion(newQuestion.id)
        }}
        onClose={() => {
          setQuestionModalVisible(false)
        }}
      />
    </div>
  )
})
