import React from 'react'
import './total.scss'

export const Total = ({ count, icon }: { count: number; icon: React.ReactNode }) => {
  return (
    <div className="total">
      {icon}
      <span className="total__title">{count}</span>
    </div>
  )
}
