import './button-primary.scss'

export const ButtonPrimary = ({
  text,
  action,
  additionalStyle,
  disabled = false,
}: {
  text: string
  action: () => void
  additionalStyle?: object
  disabled?: boolean
}) => {
  return (
    <button
      className={`Button__primary${disabled ? ' Button__primary--disabled' : ''}`}
      onClick={action}
      style={additionalStyle}
      disabled={disabled}
    >
      {text}
    </button>
  )
}
