import { Fragment } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import calendarIcon from '../../../../components/assets/calender-icon-grey.svg'
import './order-card.scss'

type Props = {
  id: number
  startDateString: string | null
  endDateString: string | null
  status: 'draft' | 'underReview' | 'pendingConfirmation' | 'started' | 'upcoming'
  jobs: {
    id: number
    work_category_name: string
    quantity: number
    location_name: string
  }[]
  showStatusLabel: boolean
}

const getFormattedDate = (dateString: string) => {
  return moment(dateString, 'YYYY-MM-DD').format('DD MMM')
}

export const OrderCard = ({ id, startDateString, endDateString, jobs, status, showStatusLabel }: Props) => {
  const { t } = useTranslation()

  const getLabelByStatus = () => {
    switch (status) {
      case 'draft':
        return 'order_status.draft'
      case 'pendingConfirmation':
        return 'order_status.pending_confirmation'
      case 'underReview':
        return 'order_status.under_review'
      case 'started':
        return 'order_status.started'
      case 'upcoming':
        return 'order_status.upcoming'
    }
  }

  return (
    <div className="OrderCard">
      <div className="OrderCard__header">
        <span className="OrderCard__header__title">{t('order.order_number', { id })}</span>
        {Boolean(startDateString && endDateString) && (
          <div className="OrderCard__header__dateContainer">
            <img src={calendarIcon} alt="" className="OrderCard__header__dateContainer__calendarImg" />
            <Separator.Horizontal width={9} />
            <span className="OrderCard__header__dateContainer__dateLabel">
              {getFormattedDate(startDateString as string)} - {getFormattedDate(endDateString as string)}
            </span>
          </div>
        )}
      </div>
      <Separator.Vertical height={16} />
      {jobs.map((job, index) => (
        <Fragment key={job.id}>
          <div className="OrderCard__occupationRow">
            <span className="OrderCard__occupationRow__label">
              {job.work_category_name} ({job.quantity})
            </span>
            {showStatusLabel && index === jobs.length - 1 && (
              <div className={`OrderCard__occupationRow__status ${status}`}>
                <span className="OrderCard__occupationRow__status__label">{t(getLabelByStatus())}</span>
              </div>
            )}
          </div>
          {index !== jobs.length - 1 && <Separator.Vertical height={12} />}
        </Fragment>
      ))}
    </div>
  )
}
