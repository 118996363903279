import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Time } from './time'
import { Status, getShiftStatus } from './status'
import {
  Schedule,
  ScheduleActiveShift,
  ShiftStatus,
  ScheduleShiftSnapshot,
  SpShiftStatus,
} from '../../../../../../../models/schedule/types'
import { ShiftTools } from './shift-tools/components'
import './sp-time.scss'
import { ConfirmationShiftTools } from './confirmation-shift-tools'
import { formatDateToHhMm } from '../../../../../../../utils/datetime-utils'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'

export type SpTimeProps = {
  jobSchedule: Schedule
  spId: number
  spShift: ScheduleActiveShift
  shift: ScheduleShiftSnapshot
}

function SpTimeComponent({ spId, spShift, shift, jobSchedule }: SpTimeProps) {
  const { t } = useTranslation()
  const { startTime, endTime } = jobSchedule.getSpTime({ spId, shiftId: shift.id })
  const timeModificationAllowed = shift.status === ShiftStatus.Upcoming
  const startTimeModificationAllowed = timeModificationAllowed && spShift.client_checkin_time === null
  const endTimeModificationAllowed = timeModificationAllowed && spShift.client_checkout_time === null
  const highlightAllowed = shift.status === ShiftStatus.Started || shift.status === ShiftStatus.Completed
  const startTimeDate = new Date((startTime as number) * 1000)
  const endTimeDate = new Date((endTime as number) * 1000)

  const getUpdatedTime = (value: string, date: Date) => {
    const [hours, minutes] = value.split(':')
    const newStartTime = new Date(date)
    newStartTime.setHours(parseInt(hours))
    newStartTime.setMinutes(parseInt(minutes))
    return Math.floor(newStartTime.getTime() / 1000)
  }

  return (
    <div className="SpTime">
      <div className="SpTime__top">
        <Time
          value={startTime ? formatDateToHhMm(startTimeDate) : null}
          disabled={!startTimeModificationAllowed}
          highlighted={highlightAllowed && Boolean(startTime)}
          label={t('all_jobs.start')}
          handleChange={value => {
            trackingService.track(TrackedEvent.TimesheetSPStartTimeChanged)
            jobSchedule.modifySpTime({
              spId,
              shiftId: shift.id,
              startTime: getUpdatedTime(value, startTimeDate),
            })
          }}
        />
        <Time
          value={endTime ? formatDateToHhMm(endTimeDate) : null}
          disabled={!endTimeModificationAllowed}
          highlighted={highlightAllowed && Boolean(endTime)}
          label={t('all_jobs.end')}
          handleChange={value => {
            trackingService.track(TrackedEvent.TimesheetSPEndTimeChanged)
            jobSchedule.modifySpTime({
              spId,
              shiftId: shift.id,
              endTime: getUpdatedTime(value, endTimeDate),
            })
          }}
        />
      </div>
      <div className="SpTime__bottom">
        <Status status={getShiftStatus(spShift.status.id)} title={spShift.status.title} />
        {spShift.status.id === SpShiftStatus.pendingConfirmation && spShift.is_available_for_confirmation ? (
          <ConfirmationShiftTools shiftId={shift.id} />
        ) : shift.status === ShiftStatus.Started || shift.status === ShiftStatus.Upcoming ? (
          <ShiftTools jobSchedule={jobSchedule} shift={spShift} spId={spId} spShift={spShift} />
        ) : null}
      </div>
    </div>
  )
}

export const SpTime = observer(SpTimeComponent)
