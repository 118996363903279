import { useState } from 'react'

import ImagePreview from '../image-preview/image-preview'
import UploadDocument, { UploadDocumentProps } from '../upload-document/upload-document'

interface ImageUploaderProps extends UploadDocumentProps {
  imageUrl: File | null | undefined | string
  onDeleteImage: () => void
}

const ImageUploader = (props: ImageUploaderProps) => {
  const [isFileSelected, setIsFileSelected] = useState(props.hasFile)
  const [imageUrl, setImageUrl] = useState(props.imageUrl)
  const handleSelectFile = (file: File | null) => {
    if (file) {
      setIsFileSelected(true)
      setImageUrl(URL.createObjectURL(file))
    } else {
      setIsFileSelected(false)
    }
  }

  const handleDeleteSelectedImage = () => {
    setIsFileSelected(false)
    setImageUrl(undefined)
    props.onDeleteImage()
  }

  return (
    <div className="ImageUploader">
      {isFileSelected ? (
        <ImagePreview onDeletedSelectedImage={handleDeleteSelectedImage} imageUrl={imageUrl} />
      ) : (
        <UploadDocument
          {...props}
          hasFile={isFileSelected}
          onSelectFile={(file) => {
            props.onSelectFile(file)
            handleSelectFile(file)
          }}
        />
      )}
    </div>
  )
}

export default ImageUploader
