import './sticky-panel.scss'

type InputProps = {
  children: React.ReactNode
  style?: object
  contentStyle?: object
  onClick?: () => void
}

export const StickyPanel = ({ children, style, contentStyle, onClick }: InputProps) => {
  return (
    <section className="StickyPanel" style={style} onClick={onClick}>
      <div className="StickyPanel__content" style={contentStyle}>
        {children}
      </div>
    </section>
  )
}
