import { useState } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'

import { useStore, useMutation } from '../../../../../../../../../../../../utils/mst-hooks'
import { OgramModal } from '../../../../../../../../../../../../components/ogram-modal/ogram-modal'
import { Separator } from '../../../../../../../../../../../../components/app-layout/separator/separator'
import { Select } from '../../../../../../../../../../../../components/form/select/select'
import { Button } from '../../../../../../../../../../../../components/button/button'
import './extend-shift-modal.scss'

type Props = {
  shiftId: number
  label: string
  modalVisible: boolean
  onClose: () => void
  shiftExtensionDefaultTime: number
}

export const ExtendShiftModal = observer(
  ({ shiftId, label, modalVisible, onClose, shiftExtensionDefaultTime }: Props) => {
    const [selectedExtension, setSelectedExtension] = useState(shiftExtensionDefaultTime)
    const { extendShift, shiftExtensionTimeOptions } = useStore().data
    const { t } = useTranslation()

    const [, requestShiftExtension] = useMutation(extendShift, {
      onSuccess: () => {
        alert('Shift has been extended')
        onClose()
      },
      onError: (err) => {
        alert(err.data.message)
      },
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    })

    if (!modalVisible) return null
    return (
      <OgramModal modalVisible={modalVisible} onClose={onClose} className="ExtendShiftModal">
        <Separator.Vertical height={40} />
        <span className="ExtendShiftModal__title">{label}</span>
        <Separator.Vertical height={40} />
        <Select
          label={label}
          value={selectedExtension}
          onChange={setSelectedExtension}
          options={shiftExtensionTimeOptions}
        />
        <Separator.Vertical height={44} />
        <Button
          title={t('job_cherry_picking.confirm')}
          onClick={() => {
            requestShiftExtension({ id: shiftId, time: selectedExtension })
          }}
          className="ExtendShiftModal__confirm-button"
        />
        <Separator.Vertical height={16} />
        <Button title={t('job_cherry_picking.cancel')} onClick={onClose} />
        <Separator.Vertical height={81} />
      </OgramModal>
    )
  },
)
