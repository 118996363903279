import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import infoIcon from '../../../../../components/assets/info.svg'
import { Toggle } from '../../../../../components/toggle/toggle'
import { Separator } from '../../../../../components/app-layout'
import { CheckInRestrictionsModal } from '../check-in-restrictions-modal/check-in-restrictions-modal'

type InputProps = {
  isVisible: boolean
  isGeorestrictionLarge: boolean
  isGeorestrictionDisabled: boolean
  setIsGeorestrictionLarge: (value: boolean) => void
  setIsGeorestrictionDisabled: (value: boolean) => void
}

export const CheckInRestrictions = ({
  isVisible,
  isGeorestrictionLarge,
  isGeorestrictionDisabled,
  setIsGeorestrictionLarge,
  setIsGeorestrictionDisabled,
}: InputProps) => {
  const [infoModalVisible, setInfoModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()

  if (!isVisible) {
    return null
  }

  return (
    <Fragment>
      <div className="LocationForm__additionalBlock">
        <div className="LocationForm__additionalBlock__header">
          <span className="LocationForm__additionalBlock__title">{t('location_list.check_in_restrictions_title')}</span>
          <img
            src={infoIcon}
            className="LocationForm__additionalBlock__icon"
            onClick={() => setInfoModalVisible(true)}
          />
        </div>
        <div className="LocationForm__toggle">
          <span className="LocationForm__toggle__label">{t('location_list.large_area')}</span>
          <Toggle active={isGeorestrictionLarge} onToggle={() => setIsGeorestrictionLarge(!isGeorestrictionLarge)} />
        </div>
        <div className="LocationForm__toggle">
          <span className="LocationForm__toggle__label">{t('location_list.no_restrictions')}</span>
          <Toggle
            active={isGeorestrictionDisabled}
            onToggle={() => setIsGeorestrictionDisabled(!isGeorestrictionDisabled)}
          />
        </div>
      </div>
      <Separator.Vertical height={22} />
      <CheckInRestrictionsModal isVisible={infoModalVisible} setIsVisible={setInfoModalVisible} />
    </Fragment>
  )
}
