import { Fragment, FunctionComponent } from 'react'

import { BottomPanel } from './bottom-panel'

export function withBottomPanel<T extends Record<string, unknown>>(Component: FunctionComponent<T>) {
  return function BottomPanelWrapper(props: T) {
    return (
      <Fragment>
        <Component {...props} />
        <BottomPanel />
      </Fragment>
    )
  }
}
