import './account-statement.scss'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../components/app-layout'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { RangeCalendar } from '../../../../components/range-calendar/range-calendar'
import { UIProps } from '../types'
import { HeaderBottomComponent } from './components/header-bottom-component'
import { StatementItem } from './components/statement-item'

export const AccountStatement = observer(
  ({
    isLoading,
    showCalendar,
    setShowCalendar,
    dateFrom,
    dateTo,
    startBalance,
    endBalance,
    isDownloadingAccountStatement,
    downloadAccountStatement,
    chosenDateRange,
    setChosenDateRange,
    onSubmitDates,
    statementItems,
    onStatementItemClick,
  }: UIProps) => {
    const { t } = useTranslation()

    if (isLoading) {
      return <LoadingSpinner />
    }

    return (
      <div className="AccountStatement">
        <RangeCalendar
          showCalendar={showCalendar}
          setShowCalendar={setShowCalendar}
          chosenDateRange={chosenDateRange}
          setChosenDateRange={setChosenDateRange}
          onSubmit={onSubmitDates}
        />
        <Header
          title={t('account_statement.account_statement')}
          bottomComponent={
            <HeaderBottomComponent
              isLoading={isLoading}
              setShowCalendar={setShowCalendar}
              dateFrom={dateFrom}
              dateTo={dateTo}
              startBalance={startBalance}
              endBalance={endBalance}
              isDownloadingAccountStatement={isDownloadingAccountStatement}
              downloadAccountStatement={downloadAccountStatement}
            />
          }
          desktopContainerStyle={{ height: '130px' }}
          mobileContainerStyle={{ height: '130px' }}
        />
        <Separator.Vertical height={100} />
        <div className="AccountStatement__container">
          <div className="AccountStatement__activitiesTitle">{t('account_statement.recent_activities')}</div>
          <div className="AccountStatement__activitiesContainer">
            {statementItems.length > 0 ? (
              statementItems.map((item) => (
                <StatementItem key={item.id} statementItem={item} onStatementItemClick={onStatementItemClick} />
              ))
            ) : (
              <p className="AccountStatement__emptyList">{t('account_statement.empty_list')}</p>
            )}
          </div>
        </div>
      </div>
    )
  },
)
