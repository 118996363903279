import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik/dist/types'

import TextInput from '../../../../../components/text-input/text-input'
import { Separator } from '../../../../../components/app-layout'
import ImageUploader from '../../../../../components/image-uploader/image-uploader'
import { BusinessLocationForm } from '../../../../../models/location'
import { Toggle } from '../../../../../components/toggle/toggle'
import { PublicTransportAccessibility } from '../../../../../models-ui/order/store/job-fields/transport-accessibility'

type InputProps = {
  showAddressDetailsField: boolean
  showImageField: boolean
  onDeleteImage: () => void
  imageUrl: File | null | undefined | string
  onSelectImage: (file: File | null) => void
  formikProps: FormikProps<BusinessLocationForm>
}

export const AdditionalDetails = ({
  showAddressDetailsField,
  showImageField,
  onDeleteImage,
  imageUrl,
  onSelectImage,
  formikProps,
}: InputProps) => {
  const { t } = useTranslation()

  if (!(showAddressDetailsField || showImageField)) {
    return null
  }

  return (
    <div className="LocationForm__additionalBlock">
      <div className="LocationForm__additionalBlock__header">
        <span className="LocationForm__additionalBlock__title">{t('location_list.additional_details')}</span>
      </div>
      {showAddressDetailsField && (
        <Fragment>
          <TextInput label={t('location_list.address_details')} formikProps={formikProps} formikKey="address_details" />
          <Separator.Vertical height={16} />
        </Fragment>
      )}
      <div className="LocationForm__toggle">
        <span className="LocationForm__toggle__label">{t('order_draft.is_location_accessible_by_transport')}</span>
        <Toggle
          active={formikProps.values.public_transport_accessibility === Number(PublicTransportAccessibility.accessible)}
          onToggle={() => {
            const newValue =
              formikProps.values.public_transport_accessibility === Number(PublicTransportAccessibility.accessible)
                ? Number(PublicTransportAccessibility.notAccessible)
                : Number(PublicTransportAccessibility.accessible)
            formikProps.setFieldValue('public_transport_accessibility', newValue)
          }}
        />
      </div>
      {showImageField && (
        <Fragment>
          <ImageUploader
            onDeleteImage={onDeleteImage}
            placeholder={t('location_list.upload_location_image')}
            name="location_image"
            imageUrl={imageUrl}
            showErrorRequiredMessage={false}
            hasFile={Boolean(imageUrl)}
            maxFileSize={10}
            onSelectFile={onSelectImage}
          />
          <Separator.Vertical height={30} />
        </Fragment>
      )}
    </div>
  )
}
