import { useTranslation } from 'react-i18next'

import { CardBrand } from '../../../../models/payment'
import newCardLogo from '../assets/new-card.svg'
import mastercardLogo from '../assets/mastercard.svg'
import visaLogo from '../assets/visa.svg'
import { DropdownMenu } from '../../../../components/dropdown-menu/dropdown-menu'
import dots from '../assets/dots.svg'
import remove from '../assets/remove.svg'

export const PaymentMethod = ({
  cardBrand,
  title,
  deleteMethod,
}: {
  cardBrand: string
  title: string
  deleteMethod: () => void
}) => {
  const { t } = useTranslation()

  const getLogo = () => {
    if (cardBrand === String(CardBrand.Mastercard)) {
      return mastercardLogo
    }

    if (cardBrand === String(CardBrand.Visa)) {
      return visaLogo
    }

    return newCardLogo
  }

  return (
    <div className="SavedPaymentMethods__method">
      <div className="SavedPaymentMethods__method__content">
        <div className="SavedPaymentMethods__method__logoContainer">
          <img src={getLogo()} />
        </div>
        <span className="SavedPaymentMethods__method__title">{title}</span>
      </div>
      <DropdownMenu
        outerComponent={<img src={dots} />}
        items={[
          <div key="remove" className="SavedPaymentMethods__method__remove" onClick={deleteMethod}>
            <img src={remove} />
            <span className="SavedPaymentMethods__method__remove-title">
              {t('saved_payment_methods.remove_payment_method')}
            </span>
          </div>,
        ]}
      />
    </div>
  )
}
