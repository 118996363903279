import { observer } from 'mobx-react'
import { Elements } from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { createStripe } from '../../../services/stripe'
import { SaveCardForm } from './components/save-card-form'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { Header, Separator } from '../../../components/app-layout'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import './save-card.scss'

export const SaveCard = observer(() => {
  const { initSetupIntent } = useStore().data
  const [clientSecret, setClientSecret] = useState<string | null>(null)

  const { t } = useTranslation()

  useQuery(initSetupIntent, {
    onSuccess: (result) => {
      setClientSecret(result.client_secret)
    },
  })

  if (!clientSecret) {
    return <LoadingSpinner />
  }

  return (
    <div className="SaveCardPage">
      <Header title={t('saved_payment_methods.save_card')} showBack />
      <Separator.Vertical height={35} />
      <div className="SaveCardPage__container">
        <Elements stripe={createStripe()} options={{ clientSecret }}>
          <SaveCardForm />
        </Elements>
      </div>
    </div>
  )
})
