import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

type Action = Generator<Promise<unknown>, void>

export const ProfessionModel = types.model('Profession', {
  id: types.identifierNumber,
  name: types.string,
  image: types.string,
  description: types.string,
  job_description: types.string,
  is_students_allowed: types.number,
})

export const professionActions = (self: DataStore) => ({
  getProfessions: flow(function* (): Action {
    const professions = (yield self.request('get', `common/professions`)) as ProfessionSnapshot[]
    professions.forEach((profession) => {
      self.professions.set(String(profession.id), profession)
    })
  }),
})

export const professionViews = (self: DataStore) => ({
  getProfessionIdByTitle(title: string) {
    return Array.from(self.professions.values()).find((profession) => profession.name === title)?.id
  },

  getProfessionName(id: number) {
    return Array.from(self.professions.values()).find((role) => role.id === id)?.name
  },

  getProfession(id: number) {
    return Array.from(self.professions.values()).find((role) => role.id === id)
  },

  get professionList() {
    return Array.from(self.professions.values())
  },
})

export type Profession = typeof ProfessionModel.Type
export type ProfessionSnapshot = typeof ProfessionModel.SnapshotType
