import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { Button } from '../../../../components/button/button'
import { LeadForm } from '../../lead-form'
import './gender.scss'

export const Gender = ({ values, updateField }: LeadForm) => {
  const { t } = useTranslation()

  return (
    <div className="Gender">
      <Separator.Vertical height={54} />
      <div className="Gender__innerContainer">
        <Button
          title={t('lead_form.male')}
          onClick={() => {
            updateField(1)
          }}
          className={`Gender__innerContainer__button ${values.gender === 1 ? 'active' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('lead_form.female')}
          onClick={() => {
            updateField(2)
          }}
          className={`Gender__innerContainer__button ${values.gender === 2 ? 'active' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('lead_form.no_preference')}
          onClick={() => {
            updateField(null)
          }}
          className={`Gender__innerContainer__button ${values.gender === null ? 'active' : ''}`}
        />
      </div>
    </div>
  )
}
