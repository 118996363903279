import detectorActiveIcon from '../../assets/detector_active.svg'
import detectorIcon from '../../assets/detector.svg'

export const ViewFinder = ({ isDetectorActive }: { isDetectorActive: boolean }) => {
  return (
    <div
      style={{
        width: '128px',
        height: '128px',
        border: '2px solid transparent',
        borderRadius: '20px',
        background: 'rgba(255, 255, 255, 0.2)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: '999999',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={isDetectorActive ? detectorActiveIcon : detectorIcon} />
    </div>
  )
}
