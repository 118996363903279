import * as Yup from 'yup'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { FieldError } from '../../../../components/form/field-error/field-error'
import { Input } from '../../../../components/form/input/input'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { Button } from '../../../../components/button/button'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import './resend-onboarding-email.scss'

type ResendOnboardingEmailProps = {
  visible: boolean
  loading: boolean
  title: string
  emailError: string | string[] | null | undefined
  showSuccessLabel: boolean
  close: () => void
  handleResendPress: (email: string) => void
}

export const ResendOnboardingEmail = ({
  visible,
  loading,
  title,
  emailError,
  showSuccessLabel,
  close,
  handleResendPress,
}: ResendOnboardingEmailProps) => {
  const { t } = useTranslation()

  return (
    <OgramModal modalVisible={visible} onClose={close}>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('validation_messages.email_invalid'))
            .required(t('create_password.email_required')),
        })}
        onSubmit={(values) => {
          handleResendPress(values.email)
        }}
        validateOnChange={false}
      >
        {(formikProps) => (
          <div className="ResendOnboardingEmail">
            <span className="ResendOnboardingEmail__title">{title}</span>
            <Separator.Vertical height={20} />
            <FieldError errors={formikProps.errors.email ?? emailError}>
              <Input
                label={t('create_password.email')}
                value={formikProps.values.email}
                handleChange={(val) => {
                  formikProps.setFieldValue('email', val)
                }}
              />
            </FieldError>
            <Separator.Vertical height={20} />
            {showSuccessLabel ? (
              <span className="ResendOnboardingEmail__successLabel">{t('create_password.email_is_sent')}</span>
            ) : (
              <Button
                title={loading ? t('create_password.sending') : t('create_password.resend_modal_button')}
                type="submit"
                disabled={!formikProps.values.email}
                onClick={formikProps.handleSubmit}
              />
            )}
          </div>
        )}
      </Formik>
    </OgramModal>
  )
}
