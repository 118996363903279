import { useState } from 'react'

import './action-alert.scss'
import { InfoIcon } from './assets/info-icon'
import { CloseIcon } from './assets/close-icon'

export enum Theme {
  Blue = 'blue',
  Orange = 'orange',
}

const themes = {
  [Theme.Blue]: {
    primaryColor: '#7094F7',
    secondaryColor: '#F4FAFF',
  },
  [Theme.Orange]: {
    primaryColor: '#FCB35C',
    secondaryColor: '#FFFFFF',
  },
}

type InputProps = {
  theme: Theme
  title: string
  actionText?: string
  action?: () => void
  isActionInProgress?: boolean
  actionInProgressText?: string
  withCloseButton?: boolean
  style?: object
  textStyle?: object
}

export const ActionAlert = ({
  theme,
  title,
  actionText,
  action = () => {},
  isActionInProgress = false,
  actionInProgressText,
  withCloseButton = true,
  style,
  textStyle,
}: InputProps) => {
  const [isShown, setIsShown] = useState<boolean>(true)

  if (!isShown) {
    return null
  }

  const containerStyle = {
    border: `1px solid ${themes[theme].primaryColor}`,
    background: themes[theme].secondaryColor,
    ...style,
  }

  return isActionInProgress ? (
    <div className="ActionAlert" style={containerStyle}>
      <span className="ActionAlert__actionText">{actionInProgressText}</span>
    </div>
  ) : (
    <div onClick={action} className="ActionAlert" style={containerStyle}>
      <div className="ActionAlert__left">
        <div className="ActionAlert__icon">
          <InfoIcon color={themes[theme].primaryColor} />
        </div>
        <span className="ActionAlert__title" style={textStyle}>
          {title}
        </span>
      </div>
      <div className="ActionAlert__right">
        <span className="ActionAlert__actionText">{actionText}</span>
      </div>
      {withCloseButton && (
        <div className="ActionAlert__close" onClick={() => setIsShown(false)}>
          <CloseIcon color={themes[theme].primaryColor} />
        </div>
      )}
    </div>
  )
}
