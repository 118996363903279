import './empty-list.scss'
import { useTranslation } from 'react-i18next'

import { Separator } from '../app-layout'

export const EmptyList = () => {
  const { t } = useTranslation()

  return (
    <div className="EmptyList">
      <Separator.Vertical height={50} />
      <span>{t('common.no_data_found')}</span>
    </div>
  )
}
