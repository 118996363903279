import { useState, useEffect } from 'react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { SearchInput } from '../../../../components/search-input/search-input'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { Checkbox } from '../../../../components/checkbox/checkbox'
import { useStore, useDelayedQuery } from '../../../../utils/mst-hooks'
import { LeadForm } from '../../lead-form'
import './language.scss'

export const Languages = observer(({ values, updateField }: LeadForm) => {
  const [searchValue, setSearchValue] = useState('')
  const [calculatingList, setCalculatingList] = useState(false)
  const [filteredLanguages, setFilteredLanguages] = useState<typeof languageList>([])

  const { languageList, getLanguages } = useStore().data

  const {
    query: { isLoading: languagesLoading },
    enableQuery: loadProfessions,
  } = useDelayedQuery(getLanguages)

  useEffect(() => {
    if (languageList.length === 0) {
      loadProfessions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (languageList.length > 0) {
      setFilteredLanguages(languageList)
    }
  }, [languageList, languageList.length])

  const filterLanguages = useDebouncedCallback((value: string) => {
    setFilteredLanguages(languageList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())))
    setCalculatingList(false)
  }, 500)

  const [calculatingListDebounced] = useDebounce(calculatingList, 300)

  const { t } = useTranslation()

  const handleSearchChange = (value: string) => {
    setCalculatingList(true)
    setSearchValue(value)
    filterLanguages(value)
  }

  return (
    <div className="Language">
      <Separator.Vertical height={24} />
      {languagesLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchInput
            value={searchValue}
            handleValueChange={handleSearchChange}
            placeholder={t('lead_form.select_languages')}
          />
          <Separator.Vertical height={16} />
          {calculatingListDebounced ? (
            <LoadingSpinner />
          ) : (
            <div className="Language__list">
              {filteredLanguages.map((language) => {
                const isActive = values.languages.includes(language.id)
                const toggle = () => {
                  updateField(
                    isActive ? values.languages.filter((id) => id !== language.id) : [...values.languages, language.id],
                  )
                }
                return (
                  <div className="Language__list__row" key={language.id} onClick={toggle}>
                    <span className="Language__list__row__title">{language.name}</span>
                    <Checkbox isActive={isActive} onChange={toggle} />
                  </div>
                )
              })}
            </div>
          )}
        </>
      )}
    </div>
  )
})
