import { useState, useEffect, Fragment } from 'react'
import { useDebounce, useDebouncedCallback } from 'use-debounce'
import { observer } from 'mobx-react'

import { LoadingSpinner } from '../loading-spinner/loading-spinner'
import { SearchInput } from '../search-input/search-input'
import { Separator } from '../app-layout/separator/separator'
import { useStore, useDelayedQuery } from '../../utils/mst-hooks'
import arrowImg from './assets/arrow.svg'
import './job-category-selector.scss'
import { StepIndicator } from '../step-indicator/step-indicator'

type InputProps = {
  handleSelection: (id: number) => void
  title: string
  subtitle: string
  header: React.ReactNode
  showStepIndicator?: boolean
  stepNumber?: number
}

const stepIndicatorConfig = {
  stepsCount: 4,
  currentStepNumber: 1,
}

export const JobCategorySelector = observer(
  ({ handleSelection, title, subtitle, header, showStepIndicator = false, stepNumber }: InputProps) => {
    const [searchValue, setSearchValue] = useState('')
    const [calculatingList, setCalculatingList] = useState(false)
    const [filteredCategories, setFilteredCategories] = useState<typeof professionList>([])

    const { professionList, getProfessions } = useStore().data

    const {
      query: { isLoading: professionsLoading },
      enableQuery: loadProfessions,
    } = useDelayedQuery(getProfessions)

    useEffect(() => {
      loadProfessions()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (professionList.length > 0) {
        setFilteredCategories(professionList)
      }
    }, [professionList, professionList.length])

    const filterCategories = useDebouncedCallback((value: string) => {
      setFilteredCategories(professionList.filter(item => item.name.toLowerCase().includes(value.toLowerCase())))
      setCalculatingList(false)
    }, 500)

    const [calculatingListDebounced] = useDebounce(calculatingList, 300)

    const handleSearchChange = (value: string) => {
      setCalculatingList(true)
      setSearchValue(value)
      filterCategories(value)
    }

    return (
      <Fragment>
        {header}
        <div className="JobDraftCategory">
          <Separator.Vertical height={24} />
          {showStepIndicator && (
            <StepIndicator
              stepsCount={stepIndicatorConfig.stepsCount}
              currentStepNumber={stepNumber ?? stepIndicatorConfig.currentStepNumber}
            />
          )}
          <span className="JobDraftCategory__title">{title}</span>
          <span className="JobDraftCategory__subtitle">{subtitle}</span>
          <Separator.Vertical height={24} />
          {professionsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <SearchInput value={searchValue} handleValueChange={handleSearchChange} />
              <Separator.Vertical height={16} />
              {calculatingListDebounced ? (
                <LoadingSpinner />
              ) : (
                <div className="JobDraftCategory__list">
                  {filteredCategories.map(profession => (
                    <div
                      onClick={() => {
                        handleSelection(profession.id)
                      }}
                      className="JobDraftCategory__list__row"
                      key={profession.id}
                    >
                      <div className="JobDraftCategory__list__row__left">
                        <img src={profession.image} className="JobDraftCategory__list__row__image" />
                        <span className="JobDraftCategory__list__row__title">{profession.name}</span>
                      </div>
                      <img src={arrowImg} className="JobDraftCategory__list__row__img rtl-x-flipped" />
                    </div>
                  ))}
                </div>
              )}
              <Separator.Vertical height={24} />
            </>
          )}
        </div>
      </Fragment>
    )
  },
)
