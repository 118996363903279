import { types, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export enum AccountType {
  CORPORATE = 'B',
  NOT_CORPORATE = 'C',
}

export enum UserRole {
  Owner = 1,
  Admin = 2,
  Manager = 3,
  Finance = 4,
}

export enum Tag {
  PaymentsEnabled = '_payments_enabled',
  LeanPaymentsEnabled = '_lean_payments_enabled',
}

const AccountProfileModel = types.model('AccountProfile', {
  id: types.identifierNumber,
  company_name: types.maybeNull(types.string),
})

export const AccountModel = types.model('Account', {
  id: types.identifierNumber,
  user_id: types.maybeNull(types.number),
  name: types.string,
  account_type: types.string,
  is_basic_info_complete: types.boolean,
  is_corporate_details_complete: types.boolean,
  roles: types.array(types.number),
  profiles: types.array(AccountProfileModel),
  tags: types.array(types.string),
  email: types.string,
})

export const accountActions = (self: DataStore) => ({
  setAccount(account: AccountSnapshot) {
    self.account = AccountModel.create(account)
  },
})

export type AccountProfile = SnapshotIn<typeof AccountProfileModel>
export type AccountProfileSnapshot = SnapshotOut<typeof AccountProfileModel>

export type Account = SnapshotIn<typeof AccountModel>
export type AccountSnapshot = SnapshotOut<typeof AccountModel>
