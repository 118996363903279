import { Link, Route } from '../../services/router'
import { Separator } from '../app-layout'
import './icon-button-link.scss'

type Props = {
  url: Route<object>
  title: string
  params?: Record<string, unknown>
  className?: string
  icon: string
}

export const IconButtonLink = ({ url, title, params, className, icon }: Props) => {
  return (
    <Link route={url} params={params ?? {}} className="IconButtonLink">
      <img src={icon} alt="" className="IconButtonLink__icon" />
      <Separator.Horizontal width={15} />
      <span className="IconButtonLink__label">{title}</span>
    </Link>
  )
}
