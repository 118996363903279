import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './description.scss'

export const Description = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  return (
    <div className="JobDraftDescription">
      <span className="Popup__title">{t('job_draft_details.description_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.description_subtitle')}</span>
      <textarea
        value={job.description.value ?? ''}
        onChange={ev => job.description.set(ev.target.value)}
        placeholder={t('job_draft_details.description_placeholder')}
        className={`JobDraftDescription__textarea ${job.description.hasError ? 'error' : ''}`}
      />
      <JobDraftFieldError error={job.description.error} />
    </div>
  )
})
