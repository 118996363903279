import { useTranslation } from 'react-i18next'

import { Button } from '../button/button'
import { images } from './assets'
import './stepper.scss'

type StepperProps = {
  stepsCount: number
  currentStep: number
  nextDisabled: boolean
  backDisabled: boolean
  nextButtonLabel: string
  handleNextPress: () => void
  handleBackPress: () => void
  shouldNextButtonSubmitForm?: boolean
  objectNameLabel?: string
  className?: string
}

export const Stepper = ({
  stepsCount,
  currentStep,
  nextDisabled,
  backDisabled,
  nextButtonLabel,
  handleNextPress,
  handleBackPress,
  shouldNextButtonSubmitForm,
  objectNameLabel,
  className,
}: StepperProps) => {
  const { t } = useTranslation()

  return (
    <div className={`Stepper ${className}`}>
      <div className="Stepper__leftContainer">
        <span className="Stepper__leftContainer__label">
          {t('job_draft_details.step_n_of_m_steps', {
            objectNameLabel: objectNameLabel ?? 'Step',
            index: currentStep + 1,
            count: stepsCount,
          })}
        </span>
        <div className="Stepper__leftContainer__progressBar">
          {new Array(currentStep + 1).fill(undefined).map((_, index) => {
            const isLastActiveItem = index === currentStep
            return (
              <div
                key={index}
                className={`Stepper__leftContainer__progressBar__step rtl-x-flipped ${index === 0 ? 'first' : ''} ${
                  isLastActiveItem ? 'last' : ''
                }`}
                style={{ width: `${(1 / stepsCount) * 100}%` }}
              />
            )
          })}
        </div>
      </div>
      <div className="Stepper__rightContainer">
        <Button
          onClick={handleBackPress}
          className={`Stepper__rightContainer__backButton ${backDisabled ? 'disabled' : ''}`}
          disabled={backDisabled}
        >
          <img src={images.backArrowImg} className="Stepper__rightContainer__backButton__img rtl-x-flipped" />
        </Button>
        <Button
          onClick={handleNextPress}
          className={`Stepper__rightContainer__nextButton ${nextDisabled ? 'disabled' : ''}`}
          disabled={nextDisabled}
          type={shouldNextButtonSubmitForm ? 'submit' : 'button'}
        >
          {nextButtonLabel === 'Next' ? ( // replace with i18n
            <>
              <span className="Stepper__rightContainer__nextButton__label">{nextButtonLabel}</span>
              <img src={images.nextArrowImg} className="Stepper__rightContainer__nextButton__img rtl-x-flipped" />
            </>
          ) : (
            <span className="Stepper__rightContainer__nextButton__label">{nextButtonLabel}</span>
          )}
        </Button>
      </div>
    </div>
  )
}
