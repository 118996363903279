import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const LocationModel = types
  .model('Location', {
    value: types.maybeNull(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    error: types.maybeNull(types.string),
  })
  .actions(self => ({
    clearError() {
      self.error = null
    },

    set(location: { id: number; name: string }) {
      self.value = location
      self.error = null
    },

    validate() {
      if (self.value === null) {
        self.error = t('job_draft_details.location_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createLocationMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.location) {
      return abort(targetInstance.location.validate())
    }
    return next(call)
  })
}

export type LocationInstance = Instance<typeof LocationModel>
