import { useState, useRef, useEffect, Fragment } from 'react'

import { Checkbox } from '../checkbox/checkbox'
import { Separator } from '../app-layout/separator/separator'
import './multiselect.scss'

type Option = { key: number; name: string }

type MultiselectProps = {
  placeholder: string
  options: Option[]
  selectedOptions: Option[]
  onSelect: (options: Option[]) => void
  onDeselect: (options: Option[]) => void
}

export const Multiselect = ({ placeholder, options, selectedOptions, onSelect, onDeselect }: MultiselectProps) => {
  const [showOptions, setShowOptions] = useState(false)

  const selectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (selectRef.current && !selectRef.current.contains(event.target as Element)) {
        setShowOptions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectRef])

  const handleSelectedOptionPressed = (option: Option) => {
    const newOptions = selectedOptions.filter((item) => item.key !== option.key)
    onDeselect(newOptions)
  }

  return (
    <div ref={selectRef} className="Multiselect">
      <div
        onClick={() => {
          setShowOptions((prevState) => !prevState)
        }}
        className="Multiselect__inputPlug"
      >
        <span className="Multiselect__inputPlug__label">{placeholder}</span>
      </div>
      <div className={`Multiselect__options ${showOptions ? '' : 'hidden'}`}>
        {options.map((option) => {
          const selected = Boolean(selectedOptions.find((item) => item.key === option.key))
          return (
            <div
              onClick={() => {
                if (selected) {
                  onSelect(selectedOptions.filter((item) => item.key !== option.key))
                } else {
                  onSelect([...selectedOptions, option])
                }
              }}
              className="Multiselect__options__item"
              key={option.key}
            >
              <Checkbox isActive={selected} />
              <span className="Multiselect__options__item__label">{option.name}</span>
            </div>
          )
        })}
      </div>
      <div className="Multiselect__selectedOptions">
        {selectedOptions.map((option, index) => (
          <Fragment key={option.key}>
            <div
              onClick={() => {
                handleSelectedOptionPressed(option)
              }}
              className="Multiselect__selectedOptions__option"
            >
              <span className="Multiselect__selectedOptions__option__name">{option.name}</span>
            </div>
            {index !== selectedOptions.length - 1 && <Separator.Horizontal width={10} />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
