import { useTranslation } from 'react-i18next'

import { Rating } from './components/rating/rating'
import { Skill as SkillType } from '../../../../types'

const ratingTitle: { [key: number]: string } = {
  1: 'sp_rating.novice',
  2: 'sp_rating.advanced_beginner',
  3: 'sp_rating.competent',
  4: 'sp_rating.proficient',
  5: 'sp_rating.expert',
}

export const Skill = ({ name, rating, icon }: SkillType) => {
  const { t } = useTranslation()

  const ratingRounded = Math.round(rating)

  return (
    <div className="WorkCategorySkills__skill">
      <div className="WorkCategorySkills__skillLeft">
        <img src={icon} />
        <Rating rating={rating} />
      </div>
      <div className="WorkCategorySkills__skillRight">
        <span className="WorkCategorySkills__skillLevel">{t(ratingTitle[ratingRounded])}</span>
        <span className="WorkCategorySkills__skillName">{name}</span>
      </div>
    </div>
  )
}
