/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment'
import { toJS } from 'mobx'
import { extendMoment } from 'moment-range'
import { v4 as uuidv4 } from 'uuid'

import { IntervalModelSnapshot, OrderJobDetailsInstance } from '../../../../models/order/order-job-details'
import { DraftJobInstance, createDraftJobInstance, DraftJobSnapshot } from '../../../../models-ui/order/store/draft-job'
import { Genders } from '../../../../models-ui/order/store/job-fields/gender'
import { ScheduleType } from '../../../../models-ui/order/store/job-fields/schedule/types'
import { sameTimingFields, distinctTimingFields } from '../../../../models-ui/order/store/job-fields/schedule'
import { BoardingStatus } from '../../../../models/job'
import { Experience } from '../../../../models/seniority-level'
import { DraftJobFields, initialOrderedFieldsDefault } from '../../../../models-ui/order/store/job-fields/fields'
import { getCurrentMarketConfig } from '../../../../services/market-service'
import { AssignmentType } from '../../../../models-ui/order/store/job-fields/manual-tools'

export const getGenderById = (id: number | null) => {
  switch (id) {
    case 1:
      return Genders.male
    case 2:
      return Genders.female
    default:
      return null
  }
}

export const createDraftJobDataFromDetailsInstance = (job: OrderJobDetailsInstance): Omit<DraftJobSnapshot, 'id'> => {
  const weekdays = moment.weekdaysShort()
  // @ts-ignore
  const momentRange = extendMoment(moment)
  const getAllDaysHaveSameTime = (intervals: IntervalModelSnapshot[], firstInterval: IntervalModelSnapshot | null) => {
    if (!firstInterval) {
      return null
    }

    return !intervals.find(
      item => item.start_time !== firstInterval.start_time || item.end_time !== firstInterval.end_time,
    )
  }

  const intervalsArray = Array.from(job.intervals?.values() ?? [])
  const firstInterval = intervalsArray[0] ?? null

  const allDaysHaveSameTime = getAllDaysHaveSameTime(intervalsArray, firstInterval)
  const sameStartTime = allDaysHaveSameTime ? firstInterval.start_time.slice(0, 5) : null
  const sameEndTime = allDaysHaveSameTime ? firstInterval.end_time.slice(0, 5) : null

  const fields = [
    ...initialOrderedFieldsDefault.filter(field => {
      return getCurrentMarketConfig().order.includePublicTransportationField || field !== DraftJobFields.transport
    }),
  ]
  const fieldsToPaste = allDaysHaveSameTime ? sameTimingFields : distinctTimingFields
  const insertToIndex = fields.findIndex(field => field === DraftJobFields.sameOrDynamicTimingPrompt) + 1
  fields.splice(insertToIndex, 0, ...fieldsToPaste)
  if (job.filter.languages) {
    fields.push(DraftJobFields.languages)
  }
  if (job.filter.gender) {
    fields.push(DraftJobFields.gender)
  }
  if (job.filter.certificates) {
    fields.push(DraftJobFields.documents)
  }
  if (job.is_accreditation_required === 1) {
    fields.push(DraftJobFields.accreditation)
  }
  if (job.boarding_type !== 0) {
    fields.push(DraftJobFields.boarding)
  }

  const isWeeklyType = weekdays.includes(job.intervals?.keys().next().value)

  const getScheduleType = () => {
    if (isWeeklyType) {
      return ScheduleType.weekly
    }
    return ScheduleType.daily
  }

  const getDates = () => {
    const dates: Record<
      string,
      {
        start_time: string | null
        end_time: string | null
        selected: boolean
        start_time_error: string | null
        end_time_error: string | null
        is_less_than_24h: boolean | null
      }
    > = {}

    // handle weekly type ('Sun', 'Mon', ...)
    if (isWeeklyType) {
      weekdays.forEach(weekday => {
        const weekdayIncluded = job.intervals?.has(weekday)
        dates[weekday] = {
          start_time: weekdayIncluded ? job.intervals?.get(weekday)!.start_time.slice(0, 5) ?? null : null,
          end_time: weekdayIncluded ? job.intervals?.get(weekday)!.end_time.slice(0, 5) ?? null : null,
          selected: weekdayIncluded ?? false,
          start_time_error: null,
          end_time_error: null,
          is_less_than_24h: false,
        }
      })
      return dates
    }

    // handle different type
    const jobMomentStartDate = moment(job.start_date, 'YYYY-MM-DD')
    const jobMomentEndDate = moment(job.end_date, 'YYYY-MM-DD')
    const dateRanges = momentRange.range(jobMomentStartDate, jobMomentEndDate)
    for (const date of dateRanges.by('days')) {
      const formatted = date.format('YYYY-MM-DD')
      const hasDate = job.intervals?.has(formatted)
      dates[formatted] = {
        start_time: hasDate ? job.intervals?.get(formatted)!.start_time.slice(0, 5) ?? null : null,
        end_time: hasDate ? job.intervals?.get(formatted)!.end_time.slice(0, 5) ?? null : null,
        selected: hasDate ?? false,
        start_time_error: null,
        end_time_error: null,
        is_less_than_24h: false,
      }
    }
    return dates
  }

  return {
    existing_job_id: job.id,
    duplicated_job_id: null,
    fields: { list: fields },
    category: {
      id: job.work_category_id,
      error: null,
    },
    quantity: { value: job.quantity, error: null },
    transport: { value: job.public_transport_accessibility, error: null },
    location: {
      value: {
        id: job.client_location_id,
        name: job.client_location_name,
      },
      error: null,
    },
    schedule: {
      dateBoundaries: {
        start_date: job.start_date,
        start_date_error: null,
        end_date: job.end_date,
        open_ended: Boolean(job.is_recurring),
        end_date_or_open_ended_error: null,
      },
      scheduleType: {
        value: getScheduleType(),
        error: null,
      },
      sameTimeSchedule: {
        all_days_have_same_time: allDaysHaveSameTime,
        all_days_have_same_time_error: null,
        same_end_time: sameEndTime,
        same_start_time_error: null,
        same_start_time: sameStartTime,
        same_end_time_error: null,
        is_less_than_24h: false,
      },
      distinctTimeSchedule: { map: getDates(), error: null },
    },
    requirements: { list: toJS(job.work_requirement_ids), error: null },
    description: { value: job.instructions, error: null },
    manager: { id: job.contact_user_id, error: null },
    experience: {
      value:
        job.seniority_level === 1
          ? Experience.junior
          : job.seniority_level === 2
            ? Experience.middle
            : job.seniority_level === 3
              ? Experience.senior
              : Experience.beginnerOrStudent,
      error: null,
    },
    dresscode: { id: job.uniform_id, error: null },
    languages: {
      list: toJS(job.filter.languages),
      use_languages: Boolean(job.filter.languages),
      error: null,
    },
    gender: {
      value: getGenderById(job.filter.gender),
      use_gender: Boolean(job.filter.gender),
      error: null,
    },
    documents: {
      list: toJS(job.filter.certificates),
      use_documents: Boolean(job.filter.certificates),
      error: null,
    },
    manualSelection: {
      use_manual_selection: true,
      manual_selection: job.assignment_type.toString() as AssignmentType,
      use_interview: Boolean(job.is_interview_required),
      interview_questions: null,
      interview_error: null,
      manual_selection_error: null,
    },
    accreditation: {
      value: Boolean(job.is_accreditation_required),
      use_accreditation: Boolean(job.is_accreditation_required),
      error: null,
    },
    accommodation: {
      value: Boolean(job.is_accommodation_offered),
      use_accommodation: Boolean(job.is_accommodation_offered),
      error: null,
    },
    boarding: {
      value: job.boarding_type,
      use_boarding: job.boarding_type !== BoardingStatus.noBoarding,
      error: null,
    },
    is_validating_form: false,
    is_automatic_checkout: 1,
    error: null,
    invitedSPs: {
      list: toJS(job.invited_sp_ids),
      error: null,
    },
  }
}

export const createDraftJobFromDetailsInstance = (job: OrderJobDetailsInstance): DraftJobInstance => {
  const draftJobData = createDraftJobDataFromDetailsInstance(job)

  const jobDraftId = uuidv4()
  return createDraftJobInstance({ ...draftJobData, id: jobDraftId })
}
