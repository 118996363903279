import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { orderExtensionStore } from '../../models-ui/order/store'
import { Separator } from '../app-layout'
import { Stepper } from '../stepper/stepper'
import { Popup } from '../popup/popup'
import { useMutation, useStore } from '../../utils/mst-hooks'
import { OrderExtension } from '../../models/order/order-summary'
import { routes } from '../../routes'
import { withErrorBoundary } from '../error/with-error-boundary'
import { useRedirect } from '../../services/router'
import { ExtensionType } from './components/extension-type/extension-type'
import { SameDateSelector } from './components/same-date-selector/same-date-selector'
import { DifferentDatesSelector } from './components/different-dates-selector/different-dates-selector'
import { ExtensionStep, SHOWN_STEPS_COUNT } from '../../models-ui/order/store/order-extension'
import './extend-order-popup.scss'

export const ExtendOrderPopup = withErrorBoundary(
  observer(
    ({
      isOpen,
      close,
      clearOnClose,
      onSuccess = () => {},
    }: {
      isOpen: boolean
      close: () => void
      clearOnClose: boolean
      onSuccess?: () => void
    }) => {
      const { createExtendedOrder } = useStore().data

      const redirect = useRedirect()
      const { t } = useTranslation()

      const [, createExtendedOrderApiCall] = useMutation(
        ({ orderExtension }: { orderExtension: OrderExtension }) => createExtendedOrder(orderExtension),
        {
          onSuccess: orderId => {
            orderExtensionStore.clear()
            onSuccess()
            redirect(routes.extendedOrderDraft, { orderId })
          },
        },
      )

      const getNextDisabled = () => {
        if (orderExtensionStore.extensionStep === Number(ExtensionStep.DateSelection)) {
          return !orderExtensionStore.jobsForExtensionExist
        }

        return false
      }

      const handleNextPress = () => {
        if (orderExtensionStore.extensionStep === Number(ExtensionStep.DateSelection)) {
          createExtendedOrderApiCall({ orderExtension: orderExtensionStore.dataForApiCall })
          return
        }
        orderExtensionStore.goToNextStep()
      }

      return (
        <Popup
          isActive={isOpen}
          content={
            <>
              <div className="OrderExtension__popup">
                {orderExtensionStore.extensionStep === Number(ExtensionStep.ExtensionTypeSelection) && (
                  <ExtensionType />
                )}
                {orderExtensionStore.extensionStep === Number(ExtensionStep.DateSelection) &&
                  orderExtensionStore.useSameExtensionDateForAllJobs && <SameDateSelector />}
                {orderExtensionStore.extensionStep === Number(ExtensionStep.DateSelection) &&
                  !orderExtensionStore.useSameExtensionDateForAllJobs && <DifferentDatesSelector />}
              </div>
              <Separator.Spacer />
              <Stepper
                currentStep={orderExtensionStore.extensionStep ?? Number(ExtensionStep.ExtensionTypeSelection)}
                stepsCount={SHOWN_STEPS_COUNT}
                backDisabled={orderExtensionStore.extensionStep === Number(ExtensionStep.ExtensionTypeSelection)}
                nextDisabled={getNextDisabled()}
                handleNextPress={handleNextPress}
                handleBackPress={orderExtensionStore.goToPreviousStep}
                nextButtonLabel={t('page_stepper.next')}
              />
            </>
          }
          close={() => {
            close()
            if (clearOnClose) {
              orderExtensionStore.clear()
            }
          }}
        />
      )
    },
  ),
)
