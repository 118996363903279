import starIcon from '../assets/rating-star.svg'
import emptyStarIcon from '../assets/rating-empty-star.svg'
import './rating.scss'

interface RatingProps {
  rating: number
  size?: number
  editable?: boolean
  filledIcon?: string
  emptyIcon?: string
  onRatingChange?: (newRating: number) => void
}

const Rating = ({ rating, editable = false, size, filledIcon, emptyIcon, onRatingChange }: RatingProps) => {
  const ratingRounded = Math.round(rating)

  const onChange = (newRating: number) => {
    if (editable) {
      onRatingChange?.(newRating)
    }
  }
  return (
    <div className={`Rating ${editable ? 'Rating_editable' : ''}`}>
      {Array.from(Array(ratingRounded)).map((_, i) => (
        <img style={{ width: size }} onClick={() => onChange(i + 1)} key={i} src={filledIcon ?? starIcon} />
      ))}
      {Array.from(Array(5 - ratingRounded)).map((_, i) => (
        <img
          style={{ width: size }}
          onClick={() => onChange(i + 1 + rating)}
          key={i}
          src={emptyIcon ?? emptyStarIcon}
        />
      ))}
    </div>
  )
}

export default Rating
