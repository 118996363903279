import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { Button } from '../../../../components/button/button'
import { LeadForm } from '../../lead-form'
import './experience-level.scss'

export const ExperienceLevel = ({ values, updateField }: LeadForm) => {
  const { t } = useTranslation()
  return (
    <div className="ExperienceLevel">
      <Separator.Vertical height={54} />
      <div className="ExperienceLevel__innerContainer">
        <Button
          title={t('job_draft_details.experience_junior')}
          onClick={() => {
            updateField(1)
          }}
          className={`ExperienceLevel__innerContainer__button ${values.experienceLevel === 1 ? 'active' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.experience_middle')}
          onClick={() => {
            updateField(2)
          }}
          className={`ExperienceLevel__innerContainer__button ${values.experienceLevel === 2 ? 'active' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.experience_senior')}
          onClick={() => {
            updateField(3)
          }}
          className={`ExperienceLevel__innerContainer__button ${values.experienceLevel === 3 ? 'active' : ''}`}
        />
        <Separator.Vertical height={15} />
        <span className="ExperienceLevel__innerContainer__description">{t('lead_form.experience_label_tips')}</span>
      </div>
    </div>
  )
}
