import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { Select } from '../../../../form/select/select'
import { Separator } from '../../../../app-layout/separator/separator'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './uniform.scss'

export const JobDraftUniform = observer(({ job }: { job: DraftJobInstance }) => {
  const { uniformList, getUniforms, getUniformById, getUniformIdByTitle } = useStore().data

  const { isLoading: uniformsLoading } = useQuery(getUniforms)

  useEffect(() => {
    if (!job.dresscode.id && uniformList.length > 0) {
      job.dresscode.set(uniformList[0].id)
    }
  }, [uniformList, uniformList.length])

  const { t } = useTranslation()

  const uniformOptions =
    uniformList.length > 0
      ? uniformList.map(uniform => ({
          key: uniform.name,
          label: uniform.name,
          value: uniform.name,
        }))
      : null

  const uniform = job.dresscode.id ? getUniformById(job.dresscode.id) : null

  return (
    <div className="JobDraftUniform">
      <span className="Popup__title">{t('job_draft_details.uniform_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.uniform_subtitle')}</span>
      {uniformsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Select
            label={t('job_draft_details.uniform_dresscode')}
            value={uniform?.name ?? ''}
            onChange={name => {
              const id = getUniformIdByTitle(name) as number
              job.dresscode.set(id)
            }}
            options={uniformOptions ?? []}
            className={`JobDraftUniform__uniformSelect ${job.dresscode.hasError ? 'error' : ''}`}
          />
          <Separator.Vertical height={12} />
          {uniform?.description && uniform.description.length > 0 && (
            <span
              className="JobDraftUniform__uniformDescription"
              dangerouslySetInnerHTML={{ __html: uniform.description }}
            ></span>
          )}
          <JobDraftFieldError error={job.dresscode.error} />
        </>
      )}
    </div>
  )
})
