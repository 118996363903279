import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../../../../../../utils/mst-hooks'
import { DropdownMenu } from '../../../../../../../components/dropdown-menu/dropdown-menu'
import { MenuItem } from './shift-tools/components/menu-item/menu-item'
import dropdownHorizontalImg from '../../../../../../../components/assets/context.svg'
import clockImg from './shift-tools/assets/clock.svg'
import { routes } from '../../../../../../../routes'
import { useRedirect } from '../../../../../../../services/router'
import './assign-shift-tools.scss'
import { TrackedEvent, trackingService } from '../../../../../../../services/tracking-service'

function AssignShiftToolsComponent({ jobId, shiftId }: { jobId: number; shiftId: number }) {
  const { t } = useTranslation()

  const { scheduleModificationsAmount } = useStore().data

  const redirect = useRedirect()

  return (
    <>
      <div className="AssignShiftTools">
        <DropdownMenu
          outerComponent={
            <div className="AssignShiftTools__dropdownContainer">
              <img src={dropdownHorizontalImg} alt="ogrammer tools" />
            </div>
          }
          items={[
            <MenuItem
              key="select-og"
              img={clockImg}
              title={t('all_jobs.select_OG')}
              onClick={() => {
                trackingService.track(TrackedEvent.TimesheetSlotOptionSPSelectClicked)
                if (scheduleModificationsAmount === 0 || confirm(t('all_jobs.unsaved_changes'))) {
                  redirect(routes.jobOGCherryPicker, { jobId: String(jobId), shiftId: String(shiftId) })
                }
              }}
              disabled={false}
            />,
          ]}
        />
      </div>
    </>
  )
}

export const AssignShiftTools = observer(AssignShiftToolsComponent)
