import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Route } from '../../../../../services/router'
import { UserRole } from '../../../../../models/account'
import { Separator } from '../../../separator/separator'
import { ProtectedRoute } from '../../../../protected-route/protected-route'
import { Link } from '../link/link'
import dropdownArrowOutlined from './assets/dropdown-arrow-outlined.svg'
import './submenu.scss'

type Props = {
  sectionTitle?: string
  items: {
    title: string
    route: { [key: string]: unknown }
    imgSource: string
    params?: Record<string, string>
    allowedRoles: UserRole[]
  }[]
}

export const Submenu = ({ items, sectionTitle }: Props) => {
  const [active, setActive] = useState(true)
  const { t } = useTranslation()

  const toggleState = () => {
    setActive((value) => !value)
  }

  return (
    <div className="Submenu">
      {Boolean(sectionTitle) && (
        <div onClick={toggleState}>
          <div className="Submenu__horizontal-separator" />
          <Separator.Vertical height={25} />
          <div className="Submenu__row Submenu__list-header">
            <span>{t(sectionTitle as string)}</span>
            <img src={dropdownArrowOutlined} alt="" />
          </div>
          <Separator.Vertical height={15} />
        </div>
      )}
      <ul className={`Submenu__list ${active ? 'active' : ''}`}>
        {items.map((item, i) => (
          <ProtectedRoute allowedRoles={item.allowedRoles} showDefaultFallbackUI={false} key={i}>
            <Link
              title={t(item.title)}
              route={item.route as Route<Record<string, unknown>>}
              imageSource={item.imgSource}
              params={item.params ?? {}}
            />
          </ProtectedRoute>
        ))}
        <Separator.Vertical height={16} />
      </ul>
    </div>
  )
}
