import { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { useStore } from '../../../../../utils/mst-hooks'
import { UserRole } from '../../../../../models/account'
import { Separator } from '../../../../../components/app-layout/separator/separator'
import editUserImg from '../../../../../components/assets/edit-pencil.svg'
import avatarPlaceholderImg from './assets/avatar-placeholder.svg'
import './user-card.scss'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

type Props = {
  id: number
  name: string
  imageURL: string | null
  roles: number[]
  locations?: number[]
}

const getRoleByNumber = (role: UserRole) => {
  switch (role) {
    case UserRole.Admin:
      return 'user_management.role_admin'
    case UserRole.Owner:
      return 'user_management.role_owner'
    case UserRole.Manager:
      return 'user_management.role_manager'
    case UserRole.Finance:
      return 'user_management.role_finance'
  }
}

export const UserCard = withErrorBoundary(
  observer(({ id, name, imageURL, roles, locations }: Props) => {
    const { getLocationNameById } = useStore().data
    const editingAlowed = !roles.includes(UserRole.Owner)

    const redirect = useRedirect()
    const { t } = useTranslation()

    return (
      <div
        onClick={() => {
          if (!editingAlowed) return
          redirect(routes.createUser, { id: String(id) })
        }}
        className={`UserCard ${locations ? 'increased' : ''} ${editingAlowed ? 'clickable' : ''}`}
      >
        <div className="UserCard__leftContainer">
          <img
            src={imageURL ?? avatarPlaceholderImg}
            alt=""
            className={`UserCard__leftContainer__userPhoto ${imageURL ? '' : 'placeholder'}`}
          />
          <Separator.Horizontal width={8} />
          <div className="UserCard__leftContainer__userInfo">
            <span className="UserCard__leftContainer__userInfo__name">{name}</span>
            <div className="UserCard__leftContainer__userInfo__locations">
              {locations?.map((locationId) => (
                <Fragment key={locationId}>
                  <span className="UserCard__leftContainer__userInfo__locations__location">
                    {getLocationNameById(String(locationId))}
                  </span>
                  <Separator.Vertical height={3} />
                </Fragment>
              ))}
            </div>
          </div>
          <Separator.Horizontal width={8} />
          {roles.map((role, index) => {
            const roleName = t(getRoleByNumber(role))
            return (
              <Fragment key={role}>
                <div className={`UserCard__leftContainer__roleContainer ${roleName}`}>
                  <span>{roleName}</span>
                </div>
                {index !== roles.length - 1 && <Separator.Horizontal width={8} />}
              </Fragment>
            )
          })}
        </div>
        {editingAlowed && <img src={editUserImg} alt="edit user" className="UserCard__editImg" />}
      </div>
    )
  }),
)
