import './client-exp-card.scss'
import { useTranslation } from 'react-i18next'

export type WorkExpProps = {
  title: string
  location: string
  jobId: number
  startDate: string
  endDate?: string | null
}

export const ClientExpCard = ({ title, location, jobId, startDate, endDate }: WorkExpProps) => {
  const { t } = useTranslation()

  return (
    <div className="WorkExperienceCard">
      <div className="WorkExperienceCard__info">
        <span className="WorkExperienceCard__info__title">{title}</span>
        <span className="WorkExperienceCard__info__company">{location}</span>
        <span className="WorkExperienceCard__info__location">{t('common.job_n', { id: jobId })}</span>
        <span className="WorkExperienceCard__info__time">
          {startDate} - {endDate ?? t('common.now')}
        </span>
      </div>
    </div>
  )
}
