import { TFunction } from 'i18next'
import moment from 'moment'

import { InvoiceStatusType, InvoiceType, pendingStatuses } from '../models/invoice'

export function getDaysDuration({ due_date }: { due_date: string }) {
  const currentDate = moment(new Date())

  const momentDueDate = moment(due_date, 'YYYY-MM-DD')
  const diff = currentDate.diff(momentDueDate)
  const daysDuration = Math.floor(moment.duration(diff).asDays())
  const isPastDate = daysDuration > 0

  return { daysDuration, isPastDate }
}

export function getInvoiceItemStatusLabelWithDueDays(
  { status, due_date, type }: { status: number; due_date: string | null; type: number | string },
  t: TFunction,
): string | null {
  if (null === due_date || Number(type) === InvoiceType.CreditNote) {
    return null
  }

  if (status === InvoiceStatusType.Paid) {
    return t('payment.status_paid')
  }

  if (status === InvoiceStatusType.Draft) {
    return t('payment_item.status_draft')
  }

  if (status === InvoiceStatusType.Processed) {
    return t('payment_item.status_processed')
  }

  if (pendingStatuses.includes(status)) {
    const { isPastDate, daysDuration } = getDaysDuration({ due_date })

    if (!isPastDate) {
      return t('payment_item.status_unpaid')
    }

    if (daysDuration > 0) {
      return t('invoice_card.due_days_ago', { count: daysDuration })
    }
    if (daysDuration === 0) {
      return t('invoice_card.due_today')
    }
    if (daysDuration === -1) {
      return t('invoice_card.due_tomorrow')
    }
    return t('invoice_card.due_days_ahead', { count: -daysDuration })
  }

  return t('payment_item.status_voided')
}

export function getInvoiceItemStatusLabel(
  { status, due_date }: { status: number; due_date: string | null },
  t: TFunction,
): string {
  if (status === InvoiceStatusType.Paid) {
    return t('payment.status_paid')
  }

  if (status === InvoiceStatusType.Draft) {
    return t('payment_item.status_draft')
  }

  if (status === InvoiceStatusType.Processed) {
    return t('payment_item.status_processed')
  }

  if (pendingStatuses.includes(status)) {
    return t('payment_item.status_unpaid')
  }

  return t('payment_item.status_voided')
}
