import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { DraftJobContext } from '../job-draft-details/job-draft-details'
import { Header, Separator } from '../../../../components/app-layout'
import { useDelayedQuery, useStore } from '../../../../utils/mst-hooks'
import { StepIndicator } from '../../../../components/step-indicator/step-indicator'
import AddLocation from '../../../locations/add-location/add-location'
import { JobDraftFieldError } from '../../../../components/job-draft-details-form/ui/components/error/error'
import { Select } from '../../../../components/form/select/select'
import { BusinessLocationForm, BusinessLocationSnapshot } from '../../../../models/location'
import { Button } from '../../../../components/button/button'
import locationImg from '../../../../components/job-draft-details-form/ui/components/location/assets/location.svg'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import crossImg from '../../../../components/assets/cross-icon-black.svg'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { ButtonPrimary } from '../../../../components/button/button-primary/button-primary'
import { useDraftJobsStore } from '../../../../models-ui/order/store'
import { routes } from '../../../../routes'
import { useRedirect } from '../../../../services/router'

type InputProps = {
  orderId?: string
  draftJobId?: string
  stepNumber?: number
  showStepIndicator?: boolean
}

const stepIndicatorConfig = {
  stepsCount: 4,
  currentStepNumber: 2,
}

export const JobDraftLocation = observer(({ orderId, draftJobId, stepNumber, showStepIndicator }: InputProps) => {
  const { t } = useTranslation()
  const { getProfessionName, locationsDataView, getLocations, getLocationByName } = useStore().data
  const [regionsModalVisible, setRegionsModalVisible] = useState(false)

  const { enableQuery: getLocationsQuery, query: locationsQuery } = useDelayedQuery(getLocations)

  useEffect(() => {
    getLocationsQuery()
  }, [])

  const { createDraftJob, getDraftJob } = useDraftJobsStore()
  const job = draftJobId ? getDraftJob(draftJobId) : createDraftJob()

  const redirect = useRedirect()

  useEffect(() => {
    if (!job.location.value && locationsDataView.length > 0) {
      const { id, name, public_transport_accessibility } = locationsDataView[0]
      job.location.set({ id, name })
      job.manager.set(null)

      if (public_transport_accessibility !== null) {
        job.fields.removePublicTransportAccessibilityField()
      } else {
        job.fields.addPublicTransportAccessibilityField()
      }
    }
  }, [locationsDataView, locationsDataView.length])

  const locationFormRef = useRef<FormikProps<BusinessLocationForm>>(null)

  const onLocationFormSubmit = (location: BusinessLocationSnapshot) => {
    if (Boolean(locationFormRef.current?.errors)) {
      return
    } else {
      job.location.clearError()

      job.location.set({ id: location.id, name: location.name })
      job.manager.set(null)

      redirect(routes.jobDraftDetails, { orderId, draftJobId: String(draftJobId), stepNumber: '3' })
    }
  }

  const locationOptions =
    locationsDataView.length > 0
      ? locationsDataView.map(location => ({
          key: String(location.id),
          label: location.name,
          value: location.name,
        }))
      : null

  const onSubmit = async () => {
    if (job.location.value) {
      redirect(routes.jobDraftDetails, { orderId, draftJobId: String(draftJobId), stepNumber: '3' })
    } else {
      await locationFormRef.current?.submitForm()
    }
  }

  return (
    <DraftJobContext.Provider value={job}>
      <Header title={getProfessionName(Number(job.category.id))} />
      <div className="JobDraftLocation">
        <Separator.Vertical height={24} />
        {showStepIndicator && (
          <StepIndicator
            stepsCount={stepIndicatorConfig.stepsCount}
            currentStepNumber={stepNumber ?? stepIndicatorConfig.currentStepNumber}
          />
        )}
        <span className="JobDraftCategory__title">{t('job_draft_details.location_title')}</span>
        <span className="JobDraftCategory__subtitle">{t('job_draft_details.location_subtitle')}</span>
        <Separator.Vertical height={24} />
        {locationsQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {locationOptions === null ? (
              <AddLocation
                formRef={locationFormRef}
                onSubmit={onLocationFormSubmit}
                showSubmit={false}
                showLocationNameField={false}
                showAddressDetailsField={false}
                showImageField={false}
                showCheckInRestrictionsBlock={false}
                onLocationSelected={job.location.clearError}
              >
                {!locationFormRef.current?.errors && job.location.error && (
                  <JobDraftFieldError error={job.location.error} style={{ position: 'absolute' }} />
                )}
              </AddLocation>
            ) : (
              <>
                <Select
                  label={t('job_draft_details.location')}
                  value={job.location.value?.name}
                  onChange={value => {
                    const { id, name, public_transport_accessibility } = getLocationByName(
                      value,
                    ) as BusinessLocationSnapshot
                    job.location.set({ id, name })
                    job.manager.set(null)

                    if (public_transport_accessibility !== null) {
                      job.fields.removePublicTransportAccessibilityField()
                    } else {
                      job.fields.addPublicTransportAccessibilityField()
                    }
                  }}
                  options={locationOptions}
                  className="JobDraftLocation__locationSelect"
                />
                {job.location.error && <JobDraftFieldError error={job.location.error} />}
                <Separator.Vertical height={35} />
                <Button
                  onClick={() => {
                    setRegionsModalVisible(true)
                  }}
                  className="JobDraftLocation__addLocationButton"
                  style={{ width: '100%' }}
                >
                  <img src={locationImg} className="JobDraftLocation__addLocationButton__img" />
                  <span className="JobDraftLocation__addLocationButton__title">
                    {t('job_draft_details.location_add_new')}
                  </span>
                </Button>
                <OgramModal
                  modalVisible={regionsModalVisible}
                  onClose={() => {
                    setRegionsModalVisible(false)
                  }}
                >
                  <div className="JobDraftLocation__modalHeader">
                    <div />
                    <p className="JobDraftLocation__modalHeader__modalTitle">{t('job_draft_details.location_add')}</p>
                    <img
                      src={crossImg}
                      onClick={() => {
                        setRegionsModalVisible(false)
                      }}
                      className="JobDraftLocation__modalHeader__modalCloseImg"
                    />
                  </div>
                  <AddLocation
                    onSubmit={() => {
                      setRegionsModalVisible(false)
                    }}
                    showSubmit
                    showLocationNameField
                    showAddressDetailsField
                    showImageField
                    showCheckInRestrictionsBlock
                  />
                </OgramModal>
              </>
            )}
          </>
        )}
        <Separator.Vertical height={12} />
        <ButtonPrimary
          text={t('sign_up.next')}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          action={onSubmit}
        />
      </div>
    </DraftJobContext.Provider>
  )
})
