import { useTranslation } from 'react-i18next'

import { PaymentAlert } from './payment-alert'

export const LeanPaymentAwaitingAuthorizationAlert = () => {
  const { t } = useTranslation()

  return (
    <PaymentAlert
      firstTitle={t('payment.payment_awaiting_authorization')}
      secondTitle={t('payment.will_be_paid_after_authorize')}
    />
  )
}
