import checkMark from '../../assets/check-mark.svg'

type JobCardProps = {
  jobId: number
  isJobChosen: boolean
  removeDuplicatedJobId: () => void
  addDuplicatedJobId: () => void
  categoryName: string
  quantity: number
  clientLocationName: string
}

export const JobCard = ({
  jobId,
  isJobChosen,
  removeDuplicatedJobId,
  addDuplicatedJobId,
  categoryName,
  quantity,
  clientLocationName,
}: JobCardProps) => {
  return (
    <div
      key={jobId}
      className={`ChooseJobs__jobContainer${isJobChosen ? ' ChooseJobs__jobContainer--selected' : ''}`}
      onClick={() => {
        isJobChosen ? removeDuplicatedJobId() : addDuplicatedJobId()
      }}
    >
      <div className="ChooseJobs__jobContainer__actions">
        <span className="ChooseJobs__jobContainer__jobTitle">{categoryName}</span>
        <div className="ChooseJobs__jobContainer__actionSelect">
          <img src={checkMark} className="ChooseJobs__jobContainer__actionSelect__checkMark"></img>
        </div>
      </div>
      <div className="ChooseJobs__jobContainer__property">
        <span className="ChooseJobs__jobContainer__property__key">Quantity</span>
        <span className="ChooseJobs__jobContainer__property__value">{quantity}</span>
      </div>
      <div className="ChooseJobs__jobContainer__property">
        <span className="ChooseJobs__jobContainer__property__key">Location</span>
        <span className="ChooseJobs__jobContainer__property__value">{clientLocationName}</span>
      </div>
    </div>
  )
}
