import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const jobDetailsPage = {
  title: t('order_creation_onboarding.job_details_title'),
  content: [
    { image: { src: images.jobDetailsImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.job_details') },
  ],
}
