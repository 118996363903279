import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import { IconButtonLink } from '../../../components/icon-button-link/icon-button-link'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import LocationItem from './components/location-item/location-item'
import addLocationIcon from '../assets/add-location-icon.svg'
import './location-list.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const LocationList = withErrorBoundary(
  observer(() => {
    const { locationsDataView, getLocations, getRegions } = useStore().data

    const { isLoading: locationsLoading } = useQuery(getLocations)
    const { isLoading: regionsLoading } = useQuery(getRegions)

    const { t } = useTranslation()

    return (
      <div className="LocationList">
        <Header
          subTitle={
            locationsDataView.length
              ? t('location_list.you_have_n_locations', { locationsCount: locationsDataView.length })
              : ''
          }
          title={t('location_list.title')}
        />
        <Separator.Vertical height={54} />
        {locationsLoading || regionsLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="LocationList__list">
              {locationsDataView.map((location) => (
                <div key={location.id}>
                  <LocationItem location={location} />
                  <Separator.Vertical height={16} />
                </div>
              ))}
            </div>
            <Separator.Vertical height={31} />
            <IconButtonLink url={routes.addLocations} title={t('location_list.add_location')} icon={addLocationIcon} />
          </>
        )}
      </div>
    )
  }),
)
