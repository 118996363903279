import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import { InviteReferrals } from './components/invite-referrals/invite-referrals'
import { Dashboard as DashboardComponent } from './components/dashboard/dashboard'
import { InviteReferralPopup } from '../../../components/affiliate/invite-referral-popup/invite-referral-popup'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { TcAcceptedChecker } from '../../../components/affiliate/tc-accepted-checker/tc-accepted-checker'

export const Dashboard = observer(() => {
  const { t } = useTranslation()
  const [showInvitePopup, setShowInvitePopup] = useState(false)

  const { getAffiliateStats, affiliateStats } = useStore().data
  useQuery(getAffiliateStats)

  return (
    <div className="AffiliateDashboardPage">
      <TcAcceptedChecker />
      <Header title={t('common_affiliate.my_referrals')} />
      <Separator.Vertical height={28} />
      <div className="AffiliateDashboardPage__container">
        {affiliateStats && affiliateStats.referralsCount > 0 ? (
          <DashboardComponent affiliateStats={affiliateStats} setShowInvitePopup={setShowInvitePopup} />
        ) : (
          // TODO(A): Properly handle absence of link invitationLink={affiliateStats?.invitationLink ?? ''}
          <InviteReferrals
            setShowInvitePopup={setShowInvitePopup}
            invitationLink={affiliateStats?.invitationLink ?? ''}
          />
        )}
      </div>
      {affiliateStats && (
        <InviteReferralPopup
          showPopup={showInvitePopup}
          setShowPopup={setShowInvitePopup}
          invitationLink={affiliateStats.invitationLink}
        />
      )}
    </div>
  )
})
