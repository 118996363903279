import { InputProps } from './types'
import { CODE_GR, marketService } from '../../../../../services/market-service'
import { CorporateFormDefault } from './corporate-form-default'
import { CorporateFormGr } from './corporate-form-gr'

export const CorporateForm = (props: InputProps) => {
  const marketCode = marketService.getCurrentMarketCode()

  if (marketCode === CODE_GR) {
    return <CorporateFormGr {...props} />
  }

  return <CorporateFormDefault {...props} />
}
