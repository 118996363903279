import Modal from 'react-modal'

import './ogram-modal.scss'

type Props = {
  modalVisible: boolean
  onClose: () => void
  children: React.ReactNode
  className?: string
  overlayStyle?: Record<string, unknown>
  contentStyle?: Record<string, unknown>
}

export const OgramModal = ({
  modalVisible,
  onClose,
  children,
  className,
  overlayStyle = {},
  contentStyle = {},
}: Props) => {
  if (!modalVisible) return null
  return (
    <Modal
      isOpen={modalVisible}
      onRequestClose={onClose}
      style={{
        overlay: {
          zIndex: 350,
          backgroundColor: 'rgba(50,50,70,.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          ...overlayStyle,
        },
        content: {
          maxHeight: '90vh',
          left: 0,
          right: 0,
          marginLeft: 'auto',
          marginRight: 'auto',
          top: 'unset',
          bottom: 'unset',
          borderRadius: 20,
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          ...contentStyle,
        },
      }}
      className={`OgramModal ${className ?? ''}`}
    >
      {children}
    </Modal>
  )
}
