import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const AccreditationModel = types
  .model('Accreditation', {
    use_accreditation: types.optional(types.boolean, false),
    value: types.maybeNull(types.boolean),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(requireAccreditation: boolean) {
      self.value = requireAccreditation
      self.error = null
    },

    useAccreditation(requireAccreditation: boolean) {
      self.use_accreditation = requireAccreditation
      if (!requireAccreditation) {
        self.value = null
      }
      self.error = null
    },

    validate() {
      if (self.use_accreditation && self.value === null) {
        self.error = t('job_draft_details.accreditation_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createAccreditationMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.accreditation) {
      return abort(targetInstance.accreditation.validate())
    }
    return next(call)
  })
}

export type AccreditationInstance = Instance<typeof AccreditationModel>
