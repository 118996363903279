import { InfoIcon } from '../../../../../components/assets/components/info-icon'

type InputProps = {
  firstTitle: string
  secondTitle: string
}

export const PaymentAlert = ({ firstTitle, secondTitle }: InputProps) => {
  return (
    <div className="PaymentDetails__alert PaymentDetails__alert--primary">
      <div className="PaymentDetails__alert__content">
        <div className="PaymentDetails__alert__content__icon" style={{ marginTop: '2px' }}>
          <InfoIcon color="#4E5D78" />
        </div>
        <div className="PaymentDetails__alert__content__texts">
          <span className="PaymentDetails__alert__content__title-primary">{firstTitle}</span>
          <span className="PaymentDetails__alert__content__subtitle-primary">{secondTitle}</span>
        </div>
      </div>
    </div>
  )
}
