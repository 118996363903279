import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { observer } from 'mobx-react'

import { useRedirect } from '../../../services/router'
import signature from '../assets/signature.svg'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { Separator } from '../../../components/app-layout/separator/separator'
import { routes } from '../../../routes'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import './sign-sla.scss'

export const SignSLA = observer(() => {
  const { acceptSLA, apiHost } = useStore().data
  const redirect = useRedirect()
  const [acceptSLAQuery, submitSLA] = useMutation(acceptSLA, {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: () => {
      redirect(routes.onboardingFinish, { navigateToScreenName: 'home' })
    },
  })

  const { t } = useTranslation()

  return (
    <div className="SignSLA">
      {acceptSLAQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Separator.Vertical height={20} />
          <div className="SignSLA__body">
            <div className="SignSLA__terms">
              <iframe src={`${apiHost}client/profile/sla`} frameBorder="0" height="100%" width="100%"></iframe>
            </div>
            <button onClick={submitSLA} className="SignSLA__agreeButton">
              <img src={signature} /> <span>{t('sign_sla.i_agree')}</span>
            </button>
          </div>
        </>
      )}
    </div>
  )
})
