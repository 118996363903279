import { Fragment } from 'react'
import moment from 'moment'

import { Separator } from '../app-layout/separator/separator'
import { DayItem } from '../day-item/day-item'
import './day-selector.scss'

type ShortWeekdays = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun'

function getDayLabel(dayLabel: ShortWeekdays) {
  switch (dayLabel) {
    case 'Mon':
      return 'Mo'
    case 'Tue':
      return 'Tu'
    case 'Wed':
      return 'We'
    case 'Thu':
      return 'Th'
    case 'Fri':
      return 'Fr'
    case 'Sat':
      return 'Sa'
    case 'Sun':
      return 'Su'
  }
}

const localeWeekdays = moment.weekdaysShort()

export const DaySelector = ({
  label,
  selectedDays,
  setSelectedDays,
}: {
  label: string | null
  selectedDays: string[]
  setSelectedDays: (days: string[]) => void
}) => {
  const toggleDaySelection = (key: string) => {
    if (selectedDays.includes(key)) {
      setSelectedDays(selectedDays.filter((day) => day !== key))
    } else {
      setSelectedDays([...selectedDays, key])
    }
  }

  return (
    <div className="DaySelector">
      {label !== null && (
        <>
          <span className="DaySelector__label">{label}</span>
          <Separator.Vertical height={16} />
        </>
      )}
      <div className="DaySelector__days-container">
        {localeWeekdays.map((item, index) => (
          <Fragment key={item}>
            <DayItem
              id={item}
              label={getDayLabel(item as ShortWeekdays)}
              selected={selectedDays.includes(item)}
              toggleSelection={toggleDaySelection}
            />
            {index !== localeWeekdays.length - 1 && <Separator.Spacer flex={0.1} />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}
