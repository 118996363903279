import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Link, useRedirect } from '../../../services/router'
import { FieldError } from '../../../components/form/field-error/field-error'
import TextInput from '../../../components/text-input/text-input'
import { Separator } from '../../../components/app-layout/separator/separator'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import appLogoImg from '../../../components/assets/app-logo.svg'
import { routes } from '../../../routes'
import './forgot-password.scss'

export const ForgotPassword = observer(() => {
  const store = useStore()
  const redirect = useRedirect()
  const { t } = useTranslation()

  const [restorePasswordResult, restorePassword] = useMutation(store.data.forgotPassword, {
    onSuccess: () => {
      toast.success(t('forgot_password.email_sent'))
      redirect(routes.login, {})
    },
    onError: (err) => {
      if (err.data.message) {
        alert(err.data.message)
      }
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const errors = restorePasswordResult.error?.data.errors

  return (
    <div className="ForgotPassword">
      <img src={appLogoImg} className="ForgotPassword__logo" />
      <Separator.Vertical height={45} />
      <h1 className="ForgotPassword__title">{t('forgot_password.title')}</h1>
      <Separator.Vertical height={5} />
      <h2 className="ForgotPassword__subtitle">{t('forgot_password.description')}</h2>
      <Separator.Vertical height={53} />
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('validation_messages.email_invalid'))
            .required(t('forgot_password.please_enter_email')),
        })}
        onSubmit={restorePassword}
      >
        {(formikProps) => (
          <Form noValidate>
            <FieldError errors={errors?.email}>
              <TextInput type="email" label={t('forgot_password.email')} formikProps={formikProps} formikKey="email" />
            </FieldError>
            <Separator.Vertical height={25} />
            <button type="submit" className="ForgotPassword__button">
              {t('forgot_password.submit')}
            </button>
            <Separator.Vertical height={25} />
            <div className="ForgotPassword__backContainer">
              <Link route={routes.login} params={{}} className="ForgotPassword__backContainer__backLink">
                {t('forgot_password.back_to_sign_in')}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
})
