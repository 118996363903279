import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../services/router'
import { Header, Separator } from '../../../components/app-layout'
import { routes } from '../../../routes'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { CherryPickingJob } from '../../../models/cherry-picking-jobs'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import ApplicantReviewJobCard from './components/applicant-review-job-card/applicant-review-job-card'
import './applicants-review.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { useBottomListListener } from '../../../utils/use-bottom-list-listener'

const ApplicantsReviewPage = withErrorBoundary(
  observer(() => {
    const { getUnFullfilledJobList, cherryPickingListView } = useStore().data
    const [getUnFullfilledJobsMutation, getUnFullfilledJobsApiCall] = useMutation(({ page }: { page?: number }) =>
      getUnFullfilledJobList(page),
    )

    useEffect(() => {
      getUnFullfilledJobsApiCall({
        page: 1,
      })
    }, [])

    const handleScroll = useBottomListListener(() => getUnFullfilledJobsApiCall({}))
    const navigate = useRedirect()

    const { t } = useTranslation()

    return (
      <div className="ApplicantsReviewPage" onScroll={handleScroll}>
        <Header title={t('applicants_review.title')} />
        <Separator.Vertical height={28} />
        <div className="ApplicantsReviewPage__container">
          {Boolean(cherryPickingListView.length) &&
            cherryPickingListView.map((job: CherryPickingJob, i) => (
              <div key={i}>
                <ApplicantReviewJobCard
                  jobDetails={job}
                  onClick={() => navigate(routes.jobOGCherryPicker, { jobId: String(job.id) })}
                />
                <Separator.Vertical height={16} />
              </div>
            ))}
          {!getUnFullfilledJobsMutation.isLoading && !cherryPickingListView.length ? (
            <p className="ApplicantsReviewPage__noJobs">{t('applicants_review.no_applicants_to_reivew')}</p>
          ) : null}
          {getUnFullfilledJobsMutation.isLoading && <LoadingSpinner />}
        </div>
      </div>
    )
  }),
)

export default ApplicantsReviewPage
