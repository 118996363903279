import { types, flow } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

const NotificationDataModel = types.model('data', {
  shift_id: types.maybe(types.number),
  sp_id: types.maybe(types.number),
  sp_ids: types.optional(types.array(types.number), []),
  job_id: types.maybe(types.number),
  order_id: types.maybe(types.number),
  document_type_id: types.maybe(types.number),
  status: types.maybe(types.string),
  channelId: types.maybe(types.string),
})

export type NotificationData = typeof NotificationDataModel.Type
export type NotificationDataSnapshot = typeof NotificationDataModel.SnapshotType

const NotificationModel = types.model('Notification', {
  id: types.identifier,
  message: types.string,
  data: NotificationDataModel,
  is_read: types.optional(types.boolean, false),
  popup: types.optional(types.number, 0),
  popup_image: types.optional(types.string, ''),
  subject: types.string,
  url: types.maybeNull(types.string),
  date: types.number,
  entity_id: types.number,
})

export const NotificationListModel = types.model('NotificationList', {
  list: types.map(NotificationModel),
  unreadCount: types.optional(types.number, 0),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const notificationActions = (self: DataStore) => {
  const actions = {
    fetchNotifications: flow(function* ({ page }: { page?: number }) {
      if (page === 1) {
        self.notification.list.clear()
        self.notification.nextPage = 1
      }
      if (self.notification.nextPage) {
        const { list: notifications = [], paginator } = (yield self.request(
          'post',
          `client/notifications/list?page=${self.notification.nextPage}`,
        )) as { list: NotificationSnapshot[]; paginator?: Paginator }

        notifications.forEach((notification: NotificationSnapshot) => {
          self.notification.list.set(String(notification.id), notification)
        })
        if (paginator) {
          self.notification.nextPage = paginator.next_page
        }
      }
    }),
    readNotification: flow(function* (id: string) {
      const notification = self.notification.list.get(id)
      yield self.request('post', 'client/notifications/mark-as-read', { id })
      self.notification.list.set(id, { ...notification, is_read: true } as NotificationSnapshot)
      return notification
    }),
    readPushedNotification: flow(function* (id) {
      yield self.request('post', 'client/notifications/mark-as-read', { id })
      actions.decrementUnreadNotifications()
    }),
    getUnreadNotificationsCount: flow(function* () {
      self.notification.unreadCount = (yield self.request('post', 'client/notifications/new')).count
    }),
    incrementUnreadNotifications: function () {
      self.notification.unreadCount++
    },
    decrementUnreadNotifications: function () {
      self.notification.unreadCount--
    },
    resetUnreadNotificationsCount: function (count = 0) {
      self.notification.unreadCount = count
    },
  }
  return actions
}

export const notificationViews = (self: DataStore) => ({
  get notifications() {
    return Array.from(self.notification.list.values())
  },

  get isMoreNotifications() {
    return Boolean(self.notification.nextPage)
  },
})

export type Notification = typeof NotificationModel.Type
export type NotificationSnapshot = typeof NotificationModel.SnapshotType
