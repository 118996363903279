import { StripePaymentStack } from './checkout/stripe'
import { PaymentList as PaymentListPage } from './payment-list/payment-list'
import { PaymentDetails as PaymentDetailsPage } from './payment-details/payment-details'
import { PaymentMethods as PaymentMethodsPage } from './payment-methods/payment-methods'
import { AccountStatement as AccountStatementPage } from './account-statement/account-statement'
import { WireTransferStack } from './checkout/wire-transfer'
import { SavedPaymentMethods as SavedPaymentMethodsPage } from './saved-payment-methods/saved-payment-methods'
import { SaveCard as SaveCardPage } from './save-card/save-card'

export const PaymentStack = {
  PaymentListPage,
  StripePaymentStack,
  PaymentDetailsPage,
  PaymentMethodsPage,
  AccountStatementPage,
  WireTransferStack,
  SavedPaymentMethodsPage,
  SaveCardPage,
}
