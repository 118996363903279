import { withNonAuthenticated } from '../../components/with-non-authenticated/with-non-authenticated'
import { ForgotPassword as ForgotPasswordPage } from './forgot-password/forgot-password'
import { LoginPage } from './login/page'
import { SignUpPage } from './sign-up/page'
import { VerifyEmail as VerifyEmailPage } from './verify-email/verify-email'
import { ResetPassword as ResetPasswordPage } from './reset-password/reset-password'
import { CreatePassword as CreatePasswordPage } from './create-password/create-password'
import { ChangePassword as ChangePasswordPage } from './change-password/change-password'

export const AuthStack = {
  ForgotPasswordPage: withNonAuthenticated(ForgotPasswordPage),
  LoginPage: withNonAuthenticated(LoginPage),
  SignUpPage: withNonAuthenticated(SignUpPage),
  VerifyEmailPage,
  ResetPasswordPage,
  CreatePasswordPage,
  ChangePasswordPage,
}
