import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const NationalityModel = types.model('Nationality', {
  id: types.identifierNumber,
  name: types.string,
})

export const nationalityActions = (self: DataStore) => ({
  getNationalities: flow(function* () {
    const nationalities = (yield self.request('get', 'common/nationalities')) as NationalitySnapshot[]
    nationalities.forEach((nationality) => {
      self.nationalities.set(String(nationality.id), nationality)
    })
  }),
})

export const nationalityViews = (self: DataStore) => ({
  get nationalitiesList() {
    return Array.from(self.languages.values())
  },

  getNationalitiesNames(ids: number[]) {
    return Array.from(self.languages.values())
      .filter((item) => ids.includes(item.id))
      .map((item) => item.name)
  },
})

export type Nationality = SnapshotIn<typeof NationalityModel>
export type NationalitySnapshot = SnapshotOut<typeof NationalityModel>
