import { useState } from 'react'

import { isMainScreen } from '../../../../../services/router'
import { Sidebar } from '../../../side-bar/sidebar'
import { NotificationsBar } from '../../../notifications-bar/notifications-bar'
import { Separator } from '../../../separator/separator'
import { DropdownAvatar } from '../dropdown-avatar/dropdown-avatar'
import logoSmallImg from '../../../../assets/logo-small.png'
import backImg from '../../../../assets/back.svg'
import './mobile-header.scss'
import { ScanQrButton } from '../../../../scan-qr-button/scan-qr-button'

type Props = {
  profileImgURL?: string
  title?: string
  subTitle?: string
  showBack?: boolean
  handleBackClick?: () => void
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  containerStyle?: object
  logged: boolean
}

export const MobileHeader = ({
  profileImgURL,
  title,
  subTitle,
  showBack,
  handleBackClick,
  rightComponent,
  bottomComponent,
  containerStyle,
  logged,
}: Props) => {
  const [sidebarActive, setSidebarActive] = useState(false)

  const navigateBack = () => window.history.back()
  const isCurrentScreenMain = isMainScreen()

  return (
    <div className="MobileHeaderBox">
      <div className="MobileHeaderBox__container" style={containerStyle}>
        <div className="MobileHeader">
          <div
            className="MobileHeader__container"
            style={{
              flexDirection: isCurrentScreenMain ? 'row' : 'column',
              justifyContent: isCurrentScreenMain ? 'space-between' : 'center',
            }}
          >
            {isCurrentScreenMain ? (
              <>
                <div className="MobileHeader__main-left">
                  {logged && <Sidebar active={sidebarActive} setActive={setSidebarActive} />}
                  <Separator.Horizontal width={11} />
                  <img src={logoSmallImg} className="MobileHeader__logo" />
                </div>
                <div className="MobileHeader__main-right">
                  <ScanQrButton style={{ marginRight: '50px' }} />
                  <NotificationsBar />
                  <Separator.Horizontal width={23} />
                  <DropdownAvatar imageURL={profileImgURL ?? ''} />
                </div>
              </>
            ) : (
              <>
                <div className="MobileHeader__top">
                  {showBack ? (
                    <img
                      className="MobileHeader__back rtl-x-flipped"
                      src={backImg}
                      alt="go back"
                      onClick={handleBackClick ?? navigateBack}
                    />
                  ) : (
                    logged && <Sidebar active={sidebarActive} setActive={setSidebarActive} />
                  )}
                  {Boolean(title) && (
                    <div className="MobileHeader__titleContainer">
                      <h1 className="MobileHeader__title">{title}</h1>
                      {Boolean(subTitle) && <p className="MobileHeader__subTitle">{subTitle}</p>}
                    </div>
                  )}
                  {rightComponent ? (
                    <div className="MobileHeader__main-right">{rightComponent}</div>
                  ) : (
                    <div className="MobileHeader__main__separator" />
                  )}
                </div>
                {bottomComponent && <div className="MobileHeader__bottom">{bottomComponent}</div>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
