import { observer } from 'mobx-react'
import { useState, useEffect } from 'react'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Answer } from '../../../../../models/interview'
import { CherryPickingApplicant } from '../../../../../models/cherry-picking-applicants'
import { useStore, useMutation } from '../../../../../utils/mst-hooks'
import Rating from '../../../../../components/rating/rating'
import './answer-form.scss'

type AnswerFormProps = {
  visible: boolean
  interviewId: number
  questionIndex: number
  questionId: number
}

function AnswerFormComponent({ visible, interviewId, questionId, questionIndex }: AnswerFormProps) {
  const { t } = useTranslation()
  const [mounted, setMounted] = useState(false)
  const { currentApplicant, getQuestionById, getSpAnswer, rateAnswer } = useStore().data
  const [rateMutation, submitRate] = useMutation(rateAnswer, {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: () => {
      toast.success(t('interview.rating_applied'))
    },
    onError: err => {
      toast.error(err.data.errors?.rating?.[0] ?? err.data.message)
    },
  })
  useEffect(() => {
    if (visible && !mounted) {
      setMounted(true)
    }
  }, [visible])
  const applicant = currentApplicant as CherryPickingApplicant
  const question = getQuestionById(questionId)
  const answer = getSpAnswer({ spId: applicant.id, interviewId, questionId })

  if (!mounted) return null

  return (
    <div className="AnswerForm" hidden={!visible}>
      <div className="AnswerForm__question">
        <span className="AnswerForm__questionIndex">{t('interview.question_short', { index: questionIndex + 1 })}</span>{' '}
        <span className="AnswerForm__questionText">{question?.description}</span>
      </div>
      {answer !== null && (
        <video key={answer.file_url} controls className="AnswerForm__videoPlayer" width="100%">
          <source src={answer.file_url} type="video/mp4" />
          {t('interview.video_player_not_supported')}
        </video>
      )}
      <div className="AnswerForm__ratingContainer">
        <div className="AnswerForm__ratingLabel">
          {t('interview.rating_question_short', { index: questionIndex + 1 })}
        </div>
        <Rating
          rating={answer?.rating ?? 0}
          editable={!rateMutation.isLoading}
          size={14}
          onRatingChange={newRating => {
            submitRate({ answerId: (answer as Answer).id, rating: newRating })
          }}
        />
      </div>
      {rateMutation.isLoading && <div className="AnswerForm__ratingLoading">{t('interview.submitting_rating')}</div>}
    </div>
  )
}

export const AnswerForm = observer(AnswerFormComponent)
