import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

export enum PaymentStatus {
  Pending = '1',
  Processing = '2',
  Completed = '3',
  Cancelled = '4',
  Refunded = '5',
}

export enum LeanStatus {
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum LeanSetupStatus {
  LEAN_SETUP_STATUS_AWAITING_BENEFICIARY_COOL_OFF = 1,
  LEAN_SETUP_STATUS_ACTIVE = 2,
}

export enum LeanSetupExternalStatus {
  ACTIVE = 'OK',
  PENDING_BENEFICIARY_COOL_OFF = 'PENDING_BENEFICIARY_COOL_OFF',
}

export enum LeanSecondaryStatus {
  AWAITING_AUTHORIZATION = 'AWAITING_AUTHORIZATION',
  ACCEPTED_BY_BANK = 'ACCEPTED_BY_BANK',
}

export enum PaymentExternalStatus {
  AWAITING_AUTHORIZATION = 2,
}

export enum CardBrand {
  Mastercard = 'mastercard',
  Visa = 'visa',
}

export enum PaymentMethod {
  Card = '1',
  Lean = '2',
  Wire = '3',
}

export const ENABLED_PAYMENT_METHODS = [PaymentMethod.Card, PaymentMethod.Lean, PaymentMethod.Wire]

type PaymentIntentMeta = {
  bank_account_bank_address?: string
  bank_account_bank_name?: string
  bank_account_iban?: string
  bank_account_name?: string
  bank_account_number?: string
}

export type PaymentIntent = {
  id: string
  client_secret: string
  amount: number
  currency_code: string
  meta: PaymentIntentMeta
}

export const InvoiceModel = types.model('Invoice', {
  id: types.identifierNumber,
  invoice_number: types.maybeNull(types.string),
  status: types.number,
  date: types.maybeNull(types.string),
  due_date: types.maybeNull(types.string),
  amount: types.number,
  currency_code: types.string,
  type: types.number,
})

export const PaymentItem = types.model('PaymentItem', {
  id: types.identifierNumber,
  payment_number: types.string,
  status: types.number,
  date: types.string,
  due_date: types.string,
  amount: types.number,
  currency_code: types.string,
  invoices_count: types.optional(types.number, 0),
  invoices: types.array(InvoiceModel),
  type_id: types.maybeNull(types.number),
  fulfilled_at: types.maybeNull(types.string),
  client: types.maybeNull(
    types.model('Client', {
      email: types.string,
    }),
  ),
  file: types.maybeNull(types.string),
  external_status: types.maybeNull(types.number),
  external_id: types.maybeNull(types.string),
})

export const SavedCard = types.model('SavedCard', {
  id: types.identifier,
  card_number_last4: types.string,
  brand: types.string,
  fingerprint: types.string,
})

const OverdueModel = types.model('OverdueModel', {
  amount: types.number,
  currency_code: types.string,
  payment_id: types.maybeNull(types.number),
  pending_payments_count: types.number,
})

export const PaymentStoreModel = types.model('PaymentStore', {
  paymentMethods: types.maybeNull(
    types.model('PaymentMethods', {
      methods: types.array(types.number),
      cards: types.array(SavedCard),
      config: types.maybeNull(
        types.model({
          lean_setup_completed: types.maybeNull(types.boolean),
          lean_setup_status: types.maybeNull(types.number),
          lean_status_updated_at: types.maybeNull(types.string),
        }),
      ),
    }),
  ),
  paymentsList: types.optional(
    types.model({
      map: types.map(PaymentItem),
      nextPage: types.optional(types.maybeNull(types.number), 1),
    }),
    { map: {}, nextPage: null },
  ),
  currentPayment: types.maybeNull(PaymentItem),
  overdue: types.maybeNull(OverdueModel),
  savedCards: types.map(SavedCard),
})

export const paymentActions = (self: DataStore) => ({
  getPaymentsList: flow(function* (paymentStatus: PaymentStatus, page?: number) {
    if (page === 1) {
      self.payments.paymentsList.map.clear()
      self.payments.paymentsList.nextPage = 1
    }

    const nextPage = self.payments.paymentsList.nextPage
    if (nextPage) {
      const { list, paginator } = (yield self.request(
        'get',
        `client/payments?status=${paymentStatus}&page=${nextPage}`,
      )) as {
        list: PaymentItemSnapshot[]
        paginator?: Paginator
      }

      list.forEach((item) => {
        self.payments.paymentsList.map.set(String(item.id), item)
      })

      self.payments.paymentsList.nextPage = paginator?.next_page ?? null
    }
  }),

  getPayment: flow(function* (paymentId: number) {
    const payment = (yield self.request('get', `client/payments/${paymentId}`)) as PaymentItemSnapshot

    self.payments.currentPayment = PaymentItem.create({
      ...payment,
      invoices: payment.invoices.map((invoice) => invoice),
    })
  }),

  initPaymentIntent: flow(function* (paymentId: number, paymentMethod: PaymentMethod) {
    return (yield self.request('post', `client/payments/${paymentId}/init-intent`, {
      payment_method: paymentMethod as string,
    })) as PaymentIntent
  }),

  initSetupIntent: flow(function* () {
    return (yield self.request('post', 'client/payments/create-setup-intent')) as {
      client_secret: string
    }
  }),

  initLeanPaymentIntent: flow(function* (paymentId: number, endUserId: string) {
    const paymentIntent = (yield self.request('post', `client/payments/${paymentId}/init-intent`, {
      payment_method: PaymentMethod.Lean as string,
    })) as PaymentIntent

    return {
      ...paymentIntent,
      endUserId,
      paymentId,
    }
  }),

  processPayment: flow(function* (paymentId: number, slipFile?: File) {
    yield self.request(
      'post',
      `client/payments/${paymentId}/process`,
      slipFile ? { slip: slipFile } : {},
      slipFile ? { headers: { 'Content-Type': 'multipart/form-data' } } : {},
    )

    return { paymentId } as { paymentId: number }
  }),

  resetPayment: flow(function* (paymentId: number) {
    yield self.request('post', `client/payments/${paymentId}/reset`)
  }),

  resetPaymentStatus: flow(function* (paymentId: number) {
    yield self.request('post', `client/payments/${paymentId}/reset-status`)
  }),

  getPaymentMethods: flow(function* (paymentId: number) {
    self.payments.paymentMethods = yield self.request('get', `client/payments/${paymentId}/payment-methods`)
    return self.payments.paymentMethods
  }),

  getSavedCards: flow(function* () {
    const response = yield self.request('get', 'client/payments/saved-cards')
    const savedCards = response['cards'] as SavedCardType[]
    savedCards.forEach((savedCard) => {
      self.payments.savedCards.set(String(savedCard.id), savedCard)
    })
  }),

  deleteSavedCard: flow(function* (cardId: string) {
    self.payments.savedCards.delete(cardId)
    yield self.request('post', `client/payments/delete-card/${cardId}`)
  }),

  getOverdue: flow(function* () {
    self.payments.overdue = yield self.request('get', 'client/payments/overdue')
  }),

  initLeanClient: flow(function* () {
    const data = yield self.request('post', 'client/payments/init-client', {
      payment_method: Number(PaymentMethod.Lean),
    })

    return data as {
      client_lean_id: string | null
    }
  }),

  initLeanClientForPayment: flow(function* (paymentId: number, externalId: string | null) {
    const data = yield self.request('post', 'client/payments/init-client', {
      payment_method: Number(PaymentMethod.Lean),
    })

    return {
      ...data,
      paymentId,
      externalId,
    } as {
      client_lean_id: string | null
      user_lean_id: string | null
      paymentId: number
      externalId: string | null
    }
  }),

  setPaymentExternalStatus: function (paymentId: number, externalStatus: number | null) {
    if (self.payments.currentPayment) {
      self.payments.currentPayment.external_status = externalStatus
    }

    const paymentItem = self.payments.paymentsList.map.get(String(paymentId))
    if (paymentItem) {
      paymentItem.external_status = externalStatus
    }
  },

  setPaymentExternalId: function (paymentId: number, externalId: string) {
    if (self.payments.currentPayment) {
      self.payments.currentPayment.external_id = externalId
    }

    const paymentItem = self.payments.paymentsList.map.get(String(paymentId))
    if (paymentItem) {
      paymentItem.external_id = externalId
    }
  },

  setPaymentStatus: function (paymentId: number, status: number) {
    if (self.payments.currentPayment) {
      self.payments.currentPayment.status = status
    }

    const paymentItem = self.payments.paymentsList.map.get(String(paymentId))
    if (paymentItem) {
      paymentItem.status = status
    }
  },

  setLeanSetupStatus: function (status: number) {
    if (self.payments.paymentMethods?.config) {
      self.payments.paymentMethods.config.lean_setup_status = status
    }
  },

  setLeanSetupStatusUpdatedAt: function (updatedAt: string) {
    if (self.payments.paymentMethods?.config) {
      self.payments.paymentMethods.config.lean_status_updated_at = updatedAt
    }
  },
})

export const paymentViews = (self: DataStore) => ({
  get paymentsList() {
    return Array.from(self.payments.paymentsList.map.values())
  },

  get currentPayment() {
    return self.payments.currentPayment
  },

  get paymentMethods() {
    return self.payments.paymentMethods
  },

  get cards() {
    const addedCards = new Set()
    return (
      self.payments.paymentMethods?.cards.filter((card) => {
        if (addedCards.has(card.fingerprint)) {
          return false
        }

        addedCards.add(card.fingerprint)
        return true
      }) ?? []
    )
  },

  get overdue() {
    return self.payments.overdue
  },

  get savedCards() {
    return Array.from(self.payments.savedCards.values())
  },
})

export type PaymentStore = typeof PaymentStoreModel.Type
export type PaymentItemSnapshot = SnapshotOut<typeof PaymentItem>
export type SavedCardSnapshot = SnapshotOut<typeof SavedCard>
export type InvoiceSnapshot = SnapshotOut<typeof InvoiceModel>
export type OverdueSnapshot = SnapshotOut<typeof OverdueModel>
export type SavedCardType = Instance<typeof SavedCard>
