import './past-workers.scss'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { ApiErrorTypes } from 'ogram-react'

import { Header, Separator } from '../../components/app-layout'
import { SearchInput } from '../../components/search-input/search-input'
import { Sort } from './components/sort'
import { TableItem } from './components/table-item/table-item'
import { CardItem } from './components/card-item/card-item'
import { useMutation, useStore } from '../../utils/mst-hooks'
import LoadingSpinner from '../../components/loading-spinner/loading-spinner'
import {
  AllowedOrderBy,
  AllowedSortBy,
  PastWorkersListAPIParams,
  SORT_BY_CLIENT_RATING_FOR_LAST_JOB,
  SORT_BY_JOB_START_DATE,
} from '../../models/past-workers'
import { pastWorkersStore } from '../../models-ui/past-workers'
import { useBottomListListener } from '../../utils/use-bottom-list-listener'
import { Pagination } from './components/pagination/pagination'
import { Total } from '../../components/filters/total/total'
import usersIcon from './assets/users.svg'
import { FiltersButton } from '../../components/filters/filters-button/filters-button'
import { FiltersPopup } from '../../components/filters/filters-popup/filters-popup'
import { TrackedEvent, trackingService } from '../../services/tracking-service'

export const PastWorkers = observer(() => {
  const { t } = useTranslation()

  const [showFilters, setShowFilters] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortByFilter, setSortByFilter] = useState<AllowedSortBy>(SORT_BY_JOB_START_DATE)
  const [orderByFilter, setOrderByFilter] = useState<AllowedOrderBy>('DESC')

  const {
    getPastSPs,
    pastSPsList,
    pastSPsPagesCount,
    pastSPsTotalCount,
    pastSPsNextPage,
    setSPNote,
    setSPLastJobRating,
    toggleIsFavorite,
  } = useStore().data

  const [getPastSPsResult, getPastSPsRequest] = useMutation(
    ({
      page,
      sortBy,
      orderBy,
      collect,
      locationId,
      designationId,
      isFavorite,
      searchString,
    }: PastWorkersListAPIParams) =>
      getPastSPs({
        page,
        searchString,
        collect,
        sortBy,
        orderBy,
        locationId,
        designationId,
        isFavorite,
      }),
  )

  const fetchPastWorkers = (page?: number, sortBy?: AllowedSortBy, orderBy?: AllowedOrderBy, collect?: boolean) => {
    const appliedFilters = []
    if (pastWorkersStore.searchFilters.locationId !== null) {
      appliedFilters.push('locationId')
    }
    if (pastWorkersStore.searchFilters.designationId !== null) {
      appliedFilters.push('designationId')
    }
    if (pastWorkersStore.searchFilters.isFavorite === true) {
      appliedFilters.push('isFavorite')
    }
    if (pastWorkersStore.searchFilters.searchString !== '') {
      appliedFilters.push('searchString')
    }

    if (appliedFilters.length > 0) {
      trackingService.track(TrackedEvent.PastWorkersListFiltersApplied, {
        filters: appliedFilters.join(','),
      })
    }

    if (page !== undefined && page > 1) {
      trackingService.track(TrackedEvent.PastWorkersListPaginationUsed)
    }

    getPastSPsRequest({
      page: page ?? currentPage,
      sortBy: sortBy ?? sortByFilter,
      orderBy: orderBy ?? orderByFilter,
      collect: collect ?? false,
      locationId: pastWorkersStore.searchFilters.locationId,
      designationId: pastWorkersStore.searchFilters.designationId,
      isFavorite: pastWorkersStore.searchFilters.isFavorite,
      searchString: pastWorkersStore.searchFilters.searchString,
    })
  }

  const [, setSPNoteRequest] = useMutation(({ spId, note }: { spId: number; note: string }) => setSPNote(spId, note), {
    onError: err => {
      toast.error(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
  })

  const [, toggleIsFavoriteRequest] = useMutation(({ spId }: { spId: number }) => toggleIsFavorite(spId), {
    onError: err => {
      toast.error(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
  })

  const [, setSPLastJobRatingRequest] = useMutation(
    ({
      spId,
      rating,
      locationId,
      workCategoryId,
    }: {
      spId: number
      rating: number
      locationId?: number | null
      workCategoryId?: number | null
    }) => setSPLastJobRating(spId, rating, locationId, workCategoryId),
    {
      onSuccess: result => {
        toast.success(t('staff.rating_submitted'))
      },
      onError: err => {
        toast.error(err.data.errors?.note[0])
      },
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    },
  )

  const setRating = (params: {
    spId: number
    rating: number
    locationId?: number | null
    workCategoryId?: number | null
  }) => {
    trackingService.track(TrackedEvent.RatingSet, {
      source: 'PastWorkersPage',
      isChange: pastSPsList.find(item => item.id === params.spId)?.client_rating_for_last_job !== null,
    })
    setSPLastJobRatingRequest(params)
  }

  const changeIsFavorite = (params: { spId: number }) => {
    const isRemoving = pastSPsList.find(item => item.id === params.spId)?.is_favorite ?? false
    if (isRemoving) {
      trackingService.track(TrackedEvent.FavoriteRemoved)
    } else {
      trackingService.track(TrackedEvent.FavoriteAdded)
    }
    toggleIsFavoriteRequest(params)
  }

  const handleScroll = useBottomListListener(() => {
    if (pastSPsNextPage) {
      fetchPastWorkers(pastSPsNextPage, sortByFilter, orderByFilter, true)
    }
  })

  useEffect(() => {
    trackingService.track(TrackedEvent.PastWorkersPageOpened)
    fetchPastWorkers()
  }, [])

  return (
    <div className="WorkersList" onScroll={handleScroll}>
      <Header title={t('staff.past_workers')} />
      <Separator.Vertical height={35} />
      <div className="WorkersList__container">
        <div className="WorkersList__content">
          <div className="WorkersList__filters">
            <Total count={pastSPsTotalCount} icon={<img src={usersIcon} />} />
            <SearchInput
              value={String(pastWorkersStore.searchFilters.searchString)}
              handleValueChange={value => {
                pastWorkersStore.setSearchString(value)
                fetchPastWorkers(1)
              }}
              className="WorkersList__filters__searchInput desktop-only"
              placeholder={t('staff.search_worker_by_id_or_name')}
            />
            <FiltersButton setShowFilters={setShowFilters} activeFiltersCount={pastWorkersStore.activeFiltersCount} />
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              fetchPastWorkers={(page: number) => fetchPastWorkers(page)}
              pastSPsPagesCount={pastSPsPagesCount}
              isFetching={getPastSPsResult.isLoading}
            />
          </div>
          <div className="WorkersList__filters mobile-only">
            <SearchInput
              value={String(pastWorkersStore.searchFilters.searchString)}
              handleValueChange={value => {
                pastWorkersStore.setSearchString(value)
                fetchPastWorkers(1)
              }}
              className="WorkersList__filters__searchInput"
              placeholder={t('staff.search_worker_by_id_or_name')}
            />
          </div>
          <div className="WorkersList__listContainer">
            <>
              {pastSPsList.length > 0 ? (
                <table className="WorkersList__table desktop-only">
                  <tr className="WorkersList__table__head">
                    <td>
                      <div>
                        <span>{t('staff.worker_name')}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{t('staff.last_designation')}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{t('staff.shifts_worked')}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() => {
                          const newSortBy: AllowedSortBy = SORT_BY_JOB_START_DATE
                          let newOrderBy: AllowedOrderBy = 'DESC'

                          if (sortByFilter === SORT_BY_JOB_START_DATE) {
                            newOrderBy = orderByFilter === 'ASC' ? 'DESC' : 'ASC'
                          }

                          setSortByFilter(newSortBy)
                          setOrderByFilter(newOrderBy)

                          trackingService.track(TrackedEvent.PastWorkersListSortUsed, {
                            sort: `${newSortBy} ${newOrderBy}`,
                          })

                          fetchPastWorkers(currentPage, newSortBy, newOrderBy)
                        }}
                        className="WorkersList__table__head__column cursor-pointer"
                      >
                        <span>{t('staff.last_job')}</span>
                        <Sort sortDirection={sortByFilter === SORT_BY_JOB_START_DATE ? orderByFilter : null} />
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{t('staff.last_location')}</span>
                      </div>
                    </td>
                    <td>
                      <div
                        onClick={() => {
                          const newSortBy: AllowedSortBy = SORT_BY_CLIENT_RATING_FOR_LAST_JOB
                          let newOrderBy: AllowedOrderBy = 'DESC'

                          if (sortByFilter === SORT_BY_CLIENT_RATING_FOR_LAST_JOB) {
                            newOrderBy = orderByFilter === 'ASC' ? 'DESC' : 'ASC'
                          }

                          setSortByFilter(newSortBy)
                          setOrderByFilter(newOrderBy)

                          trackingService.track(TrackedEvent.PastWorkersListSortUsed, {
                            sort: `${newSortBy} ${newOrderBy}`,
                          })

                          fetchPastWorkers(currentPage, newSortBy, newOrderBy)
                        }}
                        className="WorkersList__table__head__column cursor-pointer"
                      >
                        <span>{t('staff.your_rating')}</span>
                        <Sort
                          sortDirection={sortByFilter === SORT_BY_CLIENT_RATING_FOR_LAST_JOB ? orderByFilter : null}
                        />
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{t('staff.notes')}</span>
                      </div>
                    </td>
                  </tr>
                  {pastSPsList.map(sp => (
                    <TableItem
                      key={sp.id}
                      sp={sp}
                      setSPNote={setSPNoteRequest}
                      setSPLastJobRating={setRating}
                      toggleIsFavorite={changeIsFavorite}
                    />
                  ))}
                </table>
              ) : (
                <>
                  {!getPastSPsResult.isLoading && (
                    <div className="WorkersList__emptyList desktop-only">
                      <span>{t('staff.no_workers_found')}</span>
                    </div>
                  )}
                </>
              )}
              <div className="WorkersList__items mobile-only">
                {pastSPsList.length > 0 ? (
                  <>
                    {pastSPsList.map(sp => {
                      return (
                        <CardItem
                          key={sp.id}
                          sp={sp}
                          setSPNote={setSPNoteRequest}
                          setSPLastJobRating={setRating}
                          toggleIsFavorite={changeIsFavorite}
                        />
                      )
                    })}
                  </>
                ) : (
                  <>
                    {!getPastSPsResult.isLoading && (
                      <div className="WorkersList__emptyList mobile-only">
                        <span>{t('staff.no_workers_found')}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
            {getPastSPsResult.isLoading && <LoadingSpinner />}
          </div>
        </div>
      </div>
      <FiltersPopup
        fields={['locationId', 'designationId', 'isFavorite']}
        isOpen={showFilters}
        close={() => setShowFilters(false)}
        locationId={pastWorkersStore.searchFilters.locationId ?? undefined}
        setLocationId={value => pastWorkersStore.setLocationId(value ?? null)}
        designationId={pastWorkersStore.searchFilters.designationId ?? undefined}
        setDesignationId={value => pastWorkersStore.setDesignationId(value ?? null)}
        isFavorite={pastWorkersStore.searchFilters.isFavorite}
        setIsFavorite={value => pastWorkersStore.setIsFavorite(value)}
        apply={() => {
          setShowFilters(false)
          fetchPastWorkers(1)
        }}
        reset={() => {
          pastWorkersStore.reset()
          setShowFilters(false)
          fetchPastWorkers(1)
        }}
      />
    </div>
  )
})
