import { useTranslation } from 'react-i18next'

import { routes } from '../../../../../routes'
import arrowLeftIcon from '../../assets/arrow-left.svg'
import checkMarkIcon from '../../assets/checkmark.svg'
import scannerIcon from '../../../../../components/assets/scanner-white.svg'
import { OgramModal } from '../../../../../components/ogram-modal/ogram-modal'
import { useRedirect } from '../../../../../services/router'
import { formatDateToIso8601 } from '../../../../../utils/datetime-utils'

type InputProps = {
  isSuccessModalVisible: boolean
  isCheckin: boolean
  setShowQRScanner: (value: boolean) => void
}

export const SuccessModal = ({ isSuccessModalVisible, isCheckin, setShowQRScanner }: InputProps) => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <OgramModal
      overlayStyle={{ overflow: 'hidden' }}
      contentStyle={{ overflow: 'hidden' }}
      modalVisible={isSuccessModalVisible}
      onClose={() => {}}
    >
      <div className="QrAttendance__success">
        <div onClick={() => redirect(routes.index, {})} className="QrAttendance__success__back">
          <img src={arrowLeftIcon} />
          <span>{t('sidebar.home')}</span>
        </div>
        <img className="QrAttendance__success__checkmark" src={checkMarkIcon} />
        <span className="QrAttendance__success__text">
          {t('qr_scan.success_modal_text', {
            processTitle: isCheckin ? t('shift_attendance_common.check_in') : t('shift_attendance_common.check_out'),
          })}
        </span>
        <button onClick={() => setShowQRScanner(true)} className="QrAttendance__success__primaryAction">
          <img src={scannerIcon} />
          <span>{t('qr_scan.scan_next_qr')}</span>
        </button>
        <button
          onClick={() => redirect(routes.allJobs, { date: formatDateToIso8601(new Date()) })}
          className="QrAttendance__success__secondaryAction"
        >
          {t('sidebar.all_jobs')}
        </button>
      </div>
    </OgramModal>
  )
}
