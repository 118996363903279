import { flow, getEnv, applySnapshot } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'
import { Enviroment } from './environment/environment'

export const authActions = (self: DataStore) => {
  const actions = {
    login: flow(function* (params: SignInDataType) {
      self.account = yield self.request('post', 'client/auth/login', params)
      self.logged = true
      return self.account
    }),

    register: flow(function* (params: SignUpDataType) {
      self.account = yield self.request('post', 'client/auth/signup', params)
      self.logged = true
      return self.account
    }),

    verifyEmail: flow(function* (params: { hash: string }) {
      yield self.request('get', `client/auth/verifyemail/${params.hash}`)
      self.logged = true
    }),

    logInByToken: flow(function* (params: { token: string }) {
      self.account = yield self.request('post', 'client/token', params)
      self.logged = true
    }),

    socialRegister: flow(function* (params: SocialLogInType) {
      yield self.request('post', 'client/auth/social_signup', params)
      self.logged = true
    }),

    forgotPassword: flow(function* (params: { email: string }) {
      yield self.request('post', 'client/auth/forgot_password', params)
    }),

    resendEmailVerification: flow(function* (params: { email: string }) {
      yield self.request('post', 'client/auth/resend_email_verification', params)
    }),

    verifyEmailByCode: flow(function* (params: { token: string; device_id: string; firebase_token: string }) {
      yield self.request('post', 'client/auth/verify_email', params)
      self.logged = true
    }),

    resetPassword: flow(function* (params: {
      password: string
      token: string
      device_id: string
      firebase_token: string
    }) {
      yield self.request('post', 'client/auth/reset_password', params)
      self.logged = true
    }),

    resendOnboardingEmail: flow(function* (email: string) {
      yield self.request('post', 'client/auth/resend_email_verification', { email })
    }),

    logout: flow(function* (params: { device_id: string }) {
      yield self.request('post', 'client/auth/logout', params)
      actions.logoutLocal()
    }),

    logoutLocal: function () {
      getEnv<Enviroment>(self).api.setAuthKey(null)
      applySnapshot(self, {})
    },

    refreshToken: flow(function* () {
      self.account = yield self.request('post', 'client/auth/refresh')
      self.logged = true
    }),
  }

  return actions
}

export type SignUpDataType = {
  first_name: string
  last_name: string
  phone: string
  email: string
  password?: string
  allow_marketing_emails: number
  is_corporate: number
  device_id: string
  firebase_token: string
  company_name?: string
  industry?: number | null
}

export type SignInDataType = { email: string; password: string; device_id: string; firebase_token: string }
export type SocialLogInType = {
  provider: 'facebook' | 'google'
  access_token: string
  phone: string
  is_corporate: number
  device_id: string
  firebase_token: string
}
