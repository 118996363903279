import './skills-list.scss'

export const SkillsList = ({ skills }: { skills: { name: string; rating: number }[] }) => {
  const skillsFiltered = skills.filter((skill) => skill.rating > 1)

  return skillsFiltered.length > 0 ? (
    <div className="SkillsList">
      {skillsFiltered.map((skill, key) => (
        <div
          key={key}
          className={`SkillsList__skill ${skill.rating >= 3 ? 'SkillsList__skill--high' : 'SkillsList__skill--medium'}`}
        >
          <span className="SkillsList__skill__name">{skill.name}</span>
          <span className="SkillsList__skill__rating">{skill.rating}</span>
        </div>
      ))}
    </div>
  ) : null
}
