import axios from 'axios'
import { ApiRest } from 'ogram-react'
import { Api } from 'ogram-react'

import { config } from '../config/env'
import { DEFAULT_API_CONFIG } from '../services/api/api-config'
import { ApiMock } from '../services/api/api-mock'
import { secretTokenInterceptor } from '../services/api/secret-token-interceptor'
import { updateAccountOnRefresh } from '../services/api/update-account-on-refresh'
import { breadcrumbApiResponse } from '../services/api/breadcrumb-api-response'

axios.defaults.withCredentials = true

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  /**
   * Our api.
   */
  api: Api =
    process.env.NODE_ENV === 'production' || config.USE_REAL_API
      ? new ApiRest({
          ...DEFAULT_API_CONFIG,
          requestInterceptors: [secretTokenInterceptor],
          responseInterceptors: [updateAccountOnRefresh, breadcrumbApiResponse],
          lang: navigator.language,
          withCredentials: true,
        })
      : new ApiMock()

  rawApi = axios.create({})
}
