import { useTranslation } from 'react-i18next'

import invoiceIcon from '../assets/invoice-icon.svg'
import { InvoiceSnapshot } from '../../../../../models/payment'
import { getFormattedDate, getInvoiceItemStatusLabelStyles } from '../../../utils'
import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { getInvoiceItemStatusLabelWithDueDays } from '../../../../../utils/invoice'

export const Invoice = ({ invoice }: { invoice: InvoiceSnapshot }) => {
  const { t } = useTranslation()
  const redirect = useRedirect()

  return (
    <div className="PaymentDetails__invoice" onClick={() => redirect(routes.invoice, { id: String(invoice.id) })}>
      <div className="PaymentDetails__invoice__content">
        <div className="PaymentDetails__invoice__content__left">
          <img src={invoiceIcon} />
        </div>
        <div className="PaymentDetails__invoice__content__right">
          <div className="PaymentDetails__invoice__content__titles">
            <span className="PaymentDetails__invoice__content__title">
              {invoice.invoice_number ?? `INV-${invoice.id}`}
            </span>
            <span className="PaymentDetails__invoice__content__title">
              {invoice.currency_code} {invoice.amount}
            </span>
          </div>
          <div className="PaymentDetails__invoice__content__details">
            <span className="PaymentDetails__invoice__content__detailsItem">{getFormattedDate(invoice.due_date)}</span>
            <span className="PaymentDetails__invoice__content__detailsItem">.</span>
            <span
              className="PaymentDetails__invoice__content__detailsItem"
              style={getInvoiceItemStatusLabelStyles(invoice)}
            >
              {getInvoiceItemStatusLabelWithDueDays(invoice, t)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
