import moment from 'moment'
import { Fragment } from 'react'

import { Separator } from '../../../separator/separator'
import bellImg from './assets/bell.svg'
import './notification-item.scss'

type Props = {
  title: string
  description: string
  url: string | null
  secondsSinceNotification: number
  onClick: () => void
}

const getTimeFormatted = (seconds: number) => {
  const notificationDate = new Date(seconds * 1000)
  const notificationMomentDate = moment(notificationDate)
  const currentMomentDate = moment(new Date())
  const isNotificationDateToday = notificationMomentDate.isSame(currentMomentDate, 'day')

  if (isNotificationDateToday) {
    return moment(notificationDate).format('hh:mm a')
  }
  return moment(notificationDate).format('YYYY-MM-DD hh:mm a')
}

export const NotificationItem = ({ title, description, url, secondsSinceNotification, onClick }: Props) => {
  const notificationContent = (
    <Fragment>
      <div className="NotificationItem__left">
        <img src={bellImg} />
      </div>
      <Separator.Horizontal width={15} />
      <div className="NotificationItem__center">
        <span className="NotificationItem__center__title">{title}</span>
        <span className="NotificationItem__center__subtitle" dangerouslySetInnerHTML={{ __html: description }}></span>
      </div>
      <Separator.Horizontal width={15} />
      <div className="NotificationItem__right">
        <span className="NotificationItem__center__subtitle">{getTimeFormatted(secondsSinceNotification)}</span>
      </div>
    </Fragment>
  )

  if (url === null) {
    return <div className="NotificationItem">{notificationContent}</div>
  }

  return (
    <a href={url} onClick={onClick} className="NotificationItem">
      {notificationContent}
    </a>
  )
}
