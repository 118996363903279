import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const shiftsBreakdownPage = {
  title: t('order_creation_onboarding.shifts_breakdown_title'),
  content: [
    { image: { src: images.shiftsBreakdownImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.shifts_breakdown') },
  ],
}
