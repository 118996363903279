import { Skill } from './components/skill/skill'
import './work-category-skills.scss'
import { SkillSnapshot } from '../../../../../models/cherry-picking-applicants'

export const WorkCategorySkills = ({ categoryTitle, skills }: { categoryTitle: string; skills: SkillSnapshot[] }) => {
  return (
    <div className="WorkCategorySkills">
      <span className="WorkCategorySkills__title">{categoryTitle}</span>
      <div className="WorkCategorySkills__skillsList">
        {skills.map((skill, key) => (
          <Skill key={key} name={skill.name} rating={skill.rating} icon={skill.icon ?? ''} />
        ))}
      </div>
    </div>
  )
}
