import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useContext, useState } from 'react'

import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { useMutation, useQuery, useStore } from '../../utils/mst-hooks'
import { UIProps } from './types'
import { CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS } from '../../constants'
import { withErrorBoundary } from '../error/with-error-boundary'
import { isLeanSetupInitiated, useConnectLean, useIsLeanPaymentsEnabled } from '../../services/payment-service'
import { LeanStatus } from '../../models/payment'
import { load, save } from '../../services/storage'
import { UIStateContext } from '../ui-state-management/ui-state-management'
import { TrackedEvent, trackingService } from '../../services/tracking-service'

export function createUncompletedActionsCarouselContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ openBecomeAffiliateModal }: { openBecomeAffiliateModal: () => void }) => {
      const { companyDetails, getСompanyDetails, markClientCreatedPOCs, profile, setLeanSetupCompleted } =
        useStore().data
      const [hideBecomeAffiliateSlide, setHideBecomeAffiliateSlide] = useState(
        Boolean(load('hideBecomeAffiliateSlide')),
      )
      const [hideScanQRSlide, setHideScanQRSlide] = useState(Boolean(load('hideScanQRSlide')))

      const { isLoading: isCompanyDetailsLoading } = useQuery(getСompanyDetails)

      const [, markClientCreatedPOCsSubmit] = useMutation(markClientCreatedPOCs)

      const isLeanPaymentsEnabled = useIsLeanPaymentsEnabled()

      const { t } = useTranslation()
      const redirect = useRedirect()
      const { connectLean } = useConnectLean(responseObject => {
        if (responseObject.status === String(LeanStatus.SUCCESS)) {
          setLeanSetupCompleted(1)
          toast.success(t('payment.successfully_connected_bank'))
        }
      })

      const { setShowQRScanner } = useContext(UIStateContext)

      const hireActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--hire',
          onClick: () => {
            trackingService.track(TrackedEvent.HomeCarouselHireNowClicked)
            redirect(routes.setJobCategory, {})
          },
          title: t('uncompleted_actions_carousel.hire_now'),
        },
      ]

      const editCompanyActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--edit-company',
          onClick: () => {
            trackingService.track(TrackedEvent.HomeCarouselRegisterCorporateClicked)
            redirect(routes.completeRegistration, {})
          },
          title: t('uncompleted_actions_carousel.start_edit_company'),
        },
      ]

      const addPocActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--add-poc',
          onClick: () => {
            trackingService.track(TrackedEvent.HomeCarouselInviteStaffStartClicked)
            redirect(routes.users, {})
          },
          title: t('uncompleted_actions_carousel.start_add_poc'),
        },
        {
          classNames: 'CarouselSlide__rightContainer__button--dismiss-add-poc',
          onClick: () => {
            trackingService.track(TrackedEvent.HomeCarouselInviteStaffDismissClicked)
            markClientCreatedPOCsSubmit({})
          },
          title: t('uncompleted_actions_carousel.dismiss_add_poc'),
        },
      ]

      const connectLeanActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--hire',
          onClick: () => {
            connectLean()
          },
          title: t('uncompleted_actions_carousel.connect'),
        },
      ]

      const becomeAffiliateActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--add-poc',
          onClick: openBecomeAffiliateModal,
          title: t('common.start_now'),
        },
        {
          classNames: 'CarouselSlide__rightContainer__button--dismiss-add-poc',
          onClick: () => {
            save('hideBecomeAffiliateSlide', true)
            setHideBecomeAffiliateSlide(true)
          },
          title: t('common.dismiss'),
        },
      ]

      const scanQRActions = [
        {
          classNames: 'CarouselSlide__rightContainer__button--scan-qr',
          onClick: () => setShowQRScanner(true),
          title: t('qr_scan.scan_qr'),
        },
        {
          classNames: 'CarouselSlide__rightContainer__button--dismiss-scan-qr',
          onClick: () => {
            save('hideScanQRSlide', true)
            setHideScanQRSlide(true)
          },
          title: t('common.dismiss'),
        },
      ]

      const isCorporate = Boolean(companyDetails?.is_corporate)
      const isPocsCreated = Boolean(companyDetails?.is_pocs_created)

      const showEditCompanySlide =
        isCorporate && CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS.includes(Number(companyDetails?.status_id))
      const showAddPocSlide = !isPocsCreated
      const showConnectLeanSlide = Boolean(isLeanPaymentsEnabled() && !isLeanSetupInitiated(profile?.lean_setup_status))

      return (
        <UIComponent
          isLoading={isCompanyDetailsLoading}
          showEditCompanySlide={showEditCompanySlide}
          showAddPocSlide={showAddPocSlide}
          showConnectLeanSlide={showConnectLeanSlide}
          showBecomeAffiliateSlide={!hideBecomeAffiliateSlide}
          showScanQRSlide={!hideScanQRSlide}
          editCompanyActions={editCompanyActions}
          hireActions={hireActions}
          addPocActions={addPocActions}
          connectLeanActions={connectLeanActions}
          becomeAffiliateActions={becomeAffiliateActions}
          scanQRActions={scanQRActions}
        />
      )
    }),
    { [ApiErrorTypes.ServerError]: 'uncompleted_actions_carousel.error_failed_to_load' },
  )
}
