import { useEffect, useState, Fragment } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { extendMoment } from 'moment-range'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { OrderJobDetailsInstance, ShiftRepeatType } from '../../../models/order/order-job-details'
import { routes } from '../../../routes'
import { useRedirect } from '../../../services/router'
import { useDelayedQuery, useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { Popup } from '../../../components/popup/popup'
import { Separator } from '../../../components/app-layout/separator/separator'
import { Header } from '../../../components/app-layout/header/header'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { useDraftJobsStore } from '../../../models-ui/order/store'
import { createDraftJobDataFromDetailsInstance } from './utils/create-draft-job-data-from-details-instance'
import { JobCard } from './components/job-card/job-card'
import { DuplicateJob } from './components/duplicate-job/duplicate-job'
import jobsIcon from '../assets/jobs-icon.svg'
import './order-draft.scss'
import { OrderState } from '../../../models/order/list-item-orders'
import { StepIndicator } from '../../../components/step-indicator/step-indicator'
import SectionTitle from '../components/section-title/section-title'
import fileIcon from '../assets/file-icon.svg'
import OrderDraftSummaryCard from '../components/order-summary-card/order-summary-card'
import { AccountType, UserRole } from '../../../models/account'
import { StickyPanel } from '../../../components/sticky-panel/sticky-panel'
import { ButtonPrimary } from '../../../components/button/button-primary/button-primary'
import { ButtonSecondary } from '../../../components/button/button-secondary/button-secondary'
import { OgramModal } from '../../../components/ogram-modal/ogram-modal'
import { HeaderJobsCounter } from '../../../components/header-jobs-counter/header-jobs-counter'
import { AcceptTerms } from '../components/accept-terms/accept-terms'
import OrderSummaryCostCard from '../components/order-summary-cost-card/order-summary-cost-card'
import costIcon from '../assets/cost-icon.svg'
import { BestPriceGuarantee } from '../components/best-price-guarantee/best-price-guarantee'
import { DraftJobFields } from '../../../models-ui/order/store/job-fields/fields'
import { ProtectedRoute } from '../../../components/protected-route/protected-route'

type OrderDraftProps = {
  orderId: string
  title?: string
  isExtension: boolean
}

const stepIndicatorConfig = {
  stepsCount: 4,
  currentStepNumber: 3,
}

// @ts-ignore
const momentRange = extendMoment(moment)

export const OrderDraft = observer(({ orderId, title, isExtension }: OrderDraftProps) => {
  const [jobIdToDuplicate, setJobIdToDuplicate] = useState<number | null>(null)
  const [newJobModalVisible, setNewJobModalVisible] = useState<boolean>(false)
  const [duplicateJobPopupVisible, setDuplicateJobPopupVisible] = useState<boolean>(false)
  const [allowSelectJobOnDuplicate, setAllowSelectJobOnDuplicate] = useState<boolean>(false)
  const [jobsCount, setJobsCount] = useState<number>(0)
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false)

  const {
    getOrderJobs,
    getOrderIdJobs,
    getJobIntervals,
    cancelJob,
    account,
    getOrderSummary,
    getOrderIdSummary,
    submitOrder,
    getUpfrontPaymentId,
    confirmOrder,
    confirmOrderTermsConditions,
    apiHost,
    getPastSPs,
    pastSPsList,
  } = useStore().data

  useQuery(() => getPastSPs({}))

  const orderSummary = getOrderIdSummary(orderId)
  const { isLoading: orderSummaryLoading } = useQuery(() => getOrderSummary({ orderId }), {
    filterError: [ApiErrorTypes.ClientError],
    onError: err => {
      alert(err.data.message)
    },
  })

  useEffect(() => {
    setJobsCount(Number(orderSummary?.jobs.length))
  }, [orderSummary])

  const { isLoading: jobsLoading } = useQuery(() => getOrderJobs({ order_id: Number(orderId) }))

  const [{ isLoading: deletingJob }, requestCancelJob] = useMutation(cancelJob, {
    onSuccess: () => {
      const updatedJobs = getOrderIdJobs(Number(orderId))
      setJobsCount(updatedJobs.length)
      if (updatedJobs.length === 0) {
        redirect(routes.orders, { ordersState: OrderState.Unconfirmed })
      }
    },
  })

  const [submitOrderQuery, requestSubmitOrder] = useMutation(submitOrder, {
    onSuccess: () => {
      redirect(routes.orderRequestSuccess, { orderId })
    },
    onError: err => {
      // Makes Sentry to have it as a separate error with all logs/breadcrumbs
      console.error('Submit Order API Error')
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const { createDraftJob } = useDraftJobsStore()

  const redirect = useRedirect()

  const { t } = useTranslation()

  const jobs = getOrderIdJobs(Number(orderId))
  const loading = jobsLoading || deletingJob || orderSummaryLoading || submitOrderQuery.isLoading

  const handleNewJob = (job: OrderJobDetailsInstance, popupField: DraftJobFields | null) => {
    const draftJob = createDraftJob(createDraftJobDataFromDetailsInstance(job))
    const route = popupField !== null ? routes.jobDraftDetailsPopupField : routes.jobDraftDetails
    const routeParams = {
      orderId,
      draftJobId: draftJob.id,
      stepNumber: String(stepIndicatorConfig.currentStepNumber),
      isExtension: String(isExtension),
      ...(popupField !== null ? { popupField } : {}),
    }
    redirect(route, routeParams)
  }

  const {
    query: { isLoading: isPayLoading },
    enableQuery: pay,
  } = useDelayedQuery(() => getUpfrontPaymentId(orderId), {
    onSuccess: paymentId => {
      redirect(routes.paymentMethodsList, {
        paymentId: String(paymentId),
        paymentOptions: JSON.stringify({
          redirectToRouteAfterSuccess: 'orderRequestSuccess',
          redirectToRouteAfterSuccessParams: {
            orderId: String(orderId),
          },
          redirectToRouteAfterSuccessTitle: t('payment.back_to_order'),
        }),
      })
    },
    onError: err => {
      // Makes Sentry to have it as a separate error with all logs/breadcrumbs
      console.error('Pay For Order API Error')
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const [confirmOrderQuery, requestConfirmOrder] = useMutation(confirmOrder, {
    onSuccess: () => {
      redirect(routes.orderCreateSuccess, { orderId })
    },
    onError: err => {
      // Makes Sentry to have it as a separate error with all logs/breadcrumbs
      console.error('Confirm Draft Order API Error')
      alert(err.data.message)
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const [, requestConfirmTerms] = useMutation(confirmOrderTermsConditions, {
    onSuccess: () => {
      setIsTermsAndConditionsAccepted(true)
    },
    onError: err => alert(err.data.message),
    filterError: [ApiErrorTypes.ClientError],
  })

  const isAutoPricingEnabled = (!isExtension && orderSummary?.is_dynamic_priced) ?? false

  return (
    <>
      <Header
        title={Boolean(title) ? title : t('order_draft.title')}
        rightComponent={<HeaderJobsCounter jobsCount={jobsCount} orderId={Number(orderId)} />}
      />
      <div className="OrderDraft">
        <Separator.Vertical height={87} />
        <StepIndicator
          stepsCount={stepIndicatorConfig.stepsCount}
          currentStepNumber={stepIndicatorConfig.currentStepNumber}
        />
        <Separator.Vertical height={14} />
        <SectionTitle image={fileIcon} title={t('order.order_summary')} />
        <Separator.Vertical height={14} />
        {!loading ? (
          <OrderDraftSummaryCard
            location={orderSummary?.locations[0].name}
            paymentTerms={orderSummary?.payment_terms_days}
            startDate={orderSummary?.start_date ? moment(orderSummary.start_date) : null}
            endDate={orderSummary?.start_date ? moment(orderSummary.end_date) : null}
            showCompleteDetails={
              account?.account_type === AccountType.CORPORATE && account.is_corporate_details_complete === false
            }
            orderId={orderId}
            isExtension={isExtension}
            showAmend
          />
        ) : null}
        <Separator.Vertical height={28} />
        {jobs.length > 0 && (
          <>
            <div className="OrderDraft__row">
              <img src={jobsIcon} className="OrderDraft__row__icon" />
              <span className="OrderDraft__row__label">{t('order_draft.jobs')}</span>
            </div>
            <Separator.Vertical height={14} />
          </>
        )}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {jobs.map((job, index) => {
              const startDate = moment(job.start_date, 'YYYY-MM-DD')
              const formattedStartDate = startDate.format('MMM DD')
              const endDate = moment(job.end_date, 'YYYY-MM-DD')
              const formattedEndDate = endDate.format('MMM DD')

              const shiftsCount = Number(endDate.diff(startDate, 'days'))

              const invitedSPs = pastSPsList.filter(sp => job.invited_sp_ids.includes(sp.id))

              return (
                <Fragment key={job.id}>
                  <JobCard
                    title={job.category_name}
                    quantity={job.quantity}
                    locationName={job.client_location_name}
                    daysCount={job.intervals?.size ?? 0}
                    startDate={formattedStartDate}
                    endDate={formattedEndDate}
                    onScheduleEdit={() => handleNewJob(job, DraftJobFields.daysSelect)}
                    onJobPress={() => handleNewJob(job, null)}
                    onDelete={() => {
                      const confirmed = confirm(t('order_draft.confirm_delete_job', { name: job.category_name }))
                      if (confirmed) {
                        requestCancelJob({ orderId, jobId: job.id })
                      }
                    }}
                    onDuplicate={() => {
                      setJobIdToDuplicate(job.id)
                      setDuplicateJobPopupVisible(true)
                    }}
                    isAutoPricingEnabled={isAutoPricingEnabled}
                    pricing={job.pricing}
                    currency={String(orderSummary?.currency)}
                    isExtension={isExtension}
                    intervals={getJobIntervals(orderId, String(job.id))}
                    repeatType={job.repeat_type as ShiftRepeatType}
                    shiftsCount={shiftsCount}
                    invitedSPs={invitedSPs}
                  />
                  {index !== jobs.length - 1 && <Separator.Vertical height={20} />}
                </Fragment>
              )
            })}
            <Separator.Vertical height={36} />
            {isAutoPricingEnabled && (
              <ProtectedRoute
                allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]}
                showDefaultFallbackUI={false}
              >
                <SectionTitle image={costIcon} title={t('order.cost')} />
                <Separator.Vertical height={14} />
                <OrderSummaryCostCard
                  sub_total={orderSummary?.sub_total as number}
                  tax={orderSummary?.tax as number}
                  tax_percent={orderSummary?.tax_percentage as number}
                  transportation_total={orderSummary?.transportation_total as number}
                  total={orderSummary?.total as number}
                  currency={orderSummary?.currency as string}
                />
                <Separator.Vertical height={36} />
              </ProtectedRoute>
            )}
            {isAutoPricingEnabled ? (
              <ProtectedRoute
                allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]}
                showDefaultFallbackUI={false}
              >
                <BestPriceGuarantee orderId={orderId} />
                <Separator.Vertical height={36} />
              </ProtectedRoute>
            ) : (
              <Separator.Vertical height={36} />
            )}
            {jobs.length > 0 && (
              <StickyPanel contentStyle={{ justifyContent: 'center' }}>
                <div>
                  {isAutoPricingEnabled && !orderSummary?.is_upfront && (
                    <ProtectedRoute
                      allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]}
                      showDefaultFallbackUI={false}
                    >
                      <div style={{ marginBottom: '16px' }}>
                        <AcceptTerms
                          isTermsAndConditionsAccepted={isTermsAndConditionsAccepted}
                          requestConfirmTerms={requestConfirmTerms}
                          apiHost={apiHost}
                        />
                      </div>
                    </ProtectedRoute>
                  )}
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {!isExtension && (
                      <ButtonSecondary
                        text={t('order_draft.add_another_job_with_plus')}
                        action={() => setNewJobModalVisible(true)}
                      />
                    )}
                    {isAutoPricingEnabled && !orderSummary?.is_upfront && (
                      <ProtectedRoute
                        allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]}
                        showDefaultFallbackUI={false}
                      >
                        <ButtonPrimary
                          text={t('order.confirm')}
                          disabled={!isTermsAndConditionsAccepted && confirmOrderQuery.isLoading}
                          action={() => requestConfirmOrder({ orderId })}
                          additionalStyle={{ marginInlineStart: '12px' }}
                        />
                      </ProtectedRoute>
                    )}
                    {isAutoPricingEnabled && orderSummary?.is_upfront && (
                      <ProtectedRoute
                        allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]}
                        showDefaultFallbackUI={false}
                      >
                        <ButtonPrimary
                          text={`${t('payment.pay')} ${orderSummary.currency} ${orderSummary.total}`}
                          disabled={isPayLoading}
                          action={pay}
                          additionalStyle={{ marginInlineStart: '12px' }}
                        />
                      </ProtectedRoute>
                    )}
                    {!isAutoPricingEnabled && (
                      <ButtonPrimary
                        text={t('order.send_for_review')}
                        action={() => requestSubmitOrder(orderId)}
                        additionalStyle={{ marginInlineStart: '12px' }}
                      />
                    )}
                  </div>
                </div>
              </StickyPanel>
            )}
            <Separator.Vertical height={23} />
          </>
        )}
        <OgramModal
          contentStyle={{
            overflow: 'hidden',
            maxWidth: '400px',
          }}
          modalVisible={newJobModalVisible}
          onClose={() => setNewJobModalVisible(false)}
        >
          <div className="OrderDraft__NewJobModal__container">
            <span className="OrderDraft__NewJobModal__title">{t('order_draft.add_another_job')}</span>
            <ButtonSecondary
              text={t('order_draft.blank_job')}
              action={() => {
                redirect(routes.setJobCategory, { orderId, stepNumber: String(1) })
              }}
              additionalStyle={{ width: '80%' }}
            />
            {jobs.length > 0 && (
              <ButtonPrimary
                text={t('order_draft.duplicate_job')}
                action={() => {
                  setJobIdToDuplicate(jobs[0]?.id)
                  setAllowSelectJobOnDuplicate(true)
                  setDuplicateJobPopupVisible(true)
                  setNewJobModalVisible(false)
                }}
                additionalStyle={{ width: '80%' }}
              />
            )}
          </div>
        </OgramModal>
        <Popup
          close={() => {
            setJobIdToDuplicate(null)
            setAllowSelectJobOnDuplicate(false)
            setDuplicateJobPopupVisible(false)
          }}
          content={
            <DuplicateJob
              showSelectJob={allowSelectJobOnDuplicate}
              setJobId={value => setJobIdToDuplicate(Number(value))}
              jobsList={jobs.map(jobItem => {
                return {
                  id: jobItem.id,
                  category_name: jobItem.category_name,
                  quantity: jobItem.quantity,
                }
              })}
              orderId={orderId}
              jobId={String(jobIdToDuplicate)}
            />
          }
          isActive={duplicateJobPopupVisible}
        />
        <Separator.Vertical height={80} />
      </div>
    </>
  )
})
