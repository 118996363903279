import './section-title.scss'

export const SectionTitle = ({ icon, text, statText }: { icon: string; text: string; statText?: string }) => {
  return (
    <div className="SectionTitle">
      <img className="SectionTitle__icon" src={icon} />
      <span className="SectionTitle__text">{text}</span>
      <span className="SectionTitle__statText">{statText}</span>
    </div>
  )
}
