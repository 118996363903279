import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const selectDesignationPage = {
  title: t('order_creation_onboarding.select_designation_title'),
  content: [
    { image: { src: images.selectDesignationImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.select_designation') },
  ],
}
