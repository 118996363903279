export const ArrowRight = ({ isEnabled, onClick }: { isEnabled: boolean; onClick: () => void }) => {
  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 16L14 12L10 8"
        stroke={isEnabled ? '#7767E4' : '#BABED6'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
