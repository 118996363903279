import { Fragment } from 'react'

import { MarketSelector } from '../../../../components/market-selector/market-selector'
import appLogoSmallImg from '../../../../components/assets/app-logo-small.svg'
import { Separator } from '../../../../components/app-layout'
import './header.scss'

export const Header = ({ title }: { title: string }) => {
  return (
    <Fragment>
      <Separator.Vertical height={24} />
      <div className="SelectCategory__headerContainer">
        <img src={appLogoSmallImg} className="SelectCategory__logo" />
        <h1 className="SelectCategory__title">{title}</h1>
        <MarketSelector redirectPath="start" />
      </div>
    </Fragment>
  )
}
