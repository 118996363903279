import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../../components/app-layout'
import { ActionAlert, Theme } from '../../../../../components/action-alert/action-alert'
import { StickyButton } from '../../../../../components/sticky-button/sticky-button'
import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { BankAccountDetails } from '../components/bank-account-details/bank-account-details'
import './wire-transfer-details.scss'

export const WireTransferDetails = ({ paymentId }: { paymentId: number }) => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <div className="WireTransferDetails">
      <Header title={t('payment.checkout')} showBack />
      <Separator.Vertical height={35} />
      <div className="WireTransferDetails__container">
        <BankAccountDetails paymentId={paymentId} />
        <ActionAlert
          theme={Theme.Blue}
          withCloseButton
          title={t('payment.wire_transfer_upload_alert_text')}
          action={() => {}}
          isActionInProgress={false}
        />
        <StickyButton
          onClick={() => {
            redirect(routes.wireTransferUploadSlip, { paymentId: String(paymentId) })
          }}
          buttonTitle={t('payment.continue')}
        />
      </div>
    </div>
  )
}
