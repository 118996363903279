import { Separator } from '../../../separator/separator'
import { DropdownAvatar } from '../dropdown-avatar/dropdown-avatar'
import { NotificationsBar } from '../../../notifications-bar/notifications-bar'
import logoSmallImg from '../../../../assets/logo-small.png'
import backImg from '../../../../assets/back.svg'
import './desktop-header.scss'
import { isMainScreen } from '../../../../../services/router'
import { ScanQrButton } from '../../../../scan-qr-button/scan-qr-button'

type Props = {
  profileImgURL?: string
  title?: string
  subTitle?: string
  showBack?: boolean
  handleBackClick?: () => void
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  containerStyle?: object
  hideLogo: boolean
}

export const DesktopHeader = ({
  profileImgURL,
  title,
  showBack,
  handleBackClick,
  subTitle,
  rightComponent,
  bottomComponent,
  containerStyle,
  hideLogo,
}: Props) => {
  const navigateBack = () => window.history.back()

  return (
    <div className="DesktopHeaderBox">
      <div className="DesktopHeaderBox__container" style={containerStyle}>
        <div className="DesktopHeader">
          <Separator.Vertical height={24} />
          <div className="DesktopHeader__container">
            {isMainScreen() ? (
              <div className="DesktopHeader__main-controls">
                <div className="DesktopHeader__main-left">
                  <Separator.Horizontal width={11} />
                  <img src={logoSmallImg} className={`DesktopHeader__logo ${hideLogo ? 'hidden' : ''}`} />
                </div>
                <div className="DesktopHeader__main-right">
                  <ScanQrButton style={{ marginInlineEnd: '50px' }} />
                  <NotificationsBar />
                  <Separator.Horizontal width={23} />
                  <DropdownAvatar imageURL={profileImgURL ?? ''} />
                </div>
              </div>
            ) : (
              <>
                <div className="DesktopHeader__top">
                  {showBack ? (
                    <img
                      className="DesktopHeader__back rtl-x-flipped"
                      src={backImg}
                      alt="go back"
                      onClick={handleBackClick ?? navigateBack}
                    />
                  ) : (
                    <div className="DesktopHeader__main__separator" />
                  )}
                  {Boolean(title) && (
                    <div>
                      <h1 className="DesktopHeader__title">{title}</h1>
                      {Boolean(subTitle) && <p className="MobileHeader__subTitle">{subTitle}</p>}
                    </div>
                  )}
                  {rightComponent ? (
                    <div className="DesktopHeader__main-right">{rightComponent}</div>
                  ) : (
                    <div className="DesktopHeader__main__separator" />
                  )}
                </div>
                {bottomComponent && <div className="DesktopHeader__bottom">{bottomComponent}</div>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
