import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import calendarIcon from '../../../../components/assets/calender-icon-grey.svg'
import './order-card-with-progress.scss'
import { DropdownMenu } from '../../../../components/dropdown-menu/dropdown-menu'
import threeDotsIcon from '../../../../components/assets/3-dots.svg'
import calendarNextIcon from '../../../../components/assets/calendar-next.svg'
import copyIcon from '../../../../components/assets/copy.svg'
import { MenuItem } from '../../../../components/dropdown-menu/components/menu-item/menu-item'
import { getFormattedDate } from '../utils'

type Props = {
  id: number
  startDateString: string | null
  endDateString: string | null
  status: 'draft' | 'underReview' | 'pendingConfirmation' | 'started' | 'upcoming'
  daysTillEndCount: number
  jobs: {
    id: number
    work_category_name: string
    quantity: number
    location_name: string
    completeness: number
    days_till_end_count: number
  }[]
  showStatusLabel: boolean
  openOrderExtensionPopup: () => void
  openOrderDuplicationPage: () => void
}

const getProgressFillerColorByCompleteness = (completeness: number) => {
  if (completeness >= 85) {
    return 'rgba(248, 140, 153, 1)'
  }

  if (completeness >= 50) {
    return 'rgba(252, 179, 92, 1)'
  }

  return 'rgba(112, 148, 247, 1)'
}

export const OrderCardWithProgress = ({
  id,
  startDateString,
  endDateString,
  jobs,
  status,
  daysTillEndCount,
  showStatusLabel,
  openOrderExtensionPopup,
  openOrderDuplicationPage,
}: Props) => {
  const { t } = useTranslation()

  const getLabelByStatus = () => {
    switch (status) {
      case 'draft':
        return 'order_status.draft'
      case 'pendingConfirmation':
        return 'order_status.pending_confirmation'
      case 'underReview':
        return 'order_status.under_review'
      case 'started':
        return 'order_status.started'
      case 'upcoming':
        return 'order_status.upcoming'
    }
  }

  const showProgressBar = status === 'started'

  return (
    <div className="OrderCard">
      <div className="OrderCard__header">
        <span className="OrderCard__header__title">{t('order.order_number', { id })}</span>
        <DropdownMenu
          outerComponent={<img src={threeDotsIcon} />}
          items={[
            <MenuItem
              key="duplicate"
              img={<img src={copyIcon} />}
              title={t('order.duplicate_order')}
              onClick={event => {
                event.stopPropagation()
                openOrderDuplicationPage()
              }}
            />,
            <MenuItem
              key="extend"
              img={<img src={calendarNextIcon} />}
              title={t('order_extension.extend_order')}
              onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                event.stopPropagation()
                openOrderExtensionPopup()
              }}
            />,
          ]}
        />
      </div>
      <Separator.Vertical height={16} />
      <div className="OrderCard__jobsList">
        {jobs.map((job, index) => (
          <Fragment key={job.id}>
            <div className="OrderCard__occupationRow">
              <span className="OrderCard__occupationRow__label">
                {job.work_category_name} ({job.quantity})
              </span>
              {showStatusLabel && (
                <div className={`OrderCard__occupationRow__status ${status}`}>
                  <span className="OrderCard__occupationRow__status__label">{t(getLabelByStatus())}</span>
                </div>
              )}
              {showProgressBar && (
                <div className="OrderCard__occupationRow__progressBar">
                  <span className="OrderCard__occupationRow__progressBar__title">
                    <span className="OrderCard__occupationRow__progressBar__titleDays">
                      {job.days_till_end_count} {t('order.days')}
                    </span>{' '}
                    {t('order.to_end')}
                  </span>
                  <div className="OrderCard__occupationRow__progressBar__filledArea">
                    <div
                      className="OrderCard__occupationRow__progressBar__filler"
                      style={{
                        width: `${job.completeness}%`,
                        background: getProgressFillerColorByCompleteness(job.completeness),
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
            {index !== jobs.length - 1 && <Separator.Vertical height={12} />}
          </Fragment>
        ))}
      </div>
      {Boolean(startDateString && endDateString) && (
        <div className="OrderCard__dateContainer">
          <img src={calendarIcon} alt="" className="OrderCard__header__dateContainer__calendarImg" />
          <Separator.Horizontal width={9} />
          <span className="OrderCard__dateContainer__dateLabel">
            {getFormattedDate(startDateString as string)} - {getFormattedDate(endDateString as string)}
          </span>
        </div>
      )}
    </div>
  )
}
