import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'

import { useQuery, useStore } from '../../../utils/mst-hooks'
import { Header, Separator } from '../../../components/app-layout'
import ApplicantsSection from './components/applicants-section/applicants-section'
import SectionTitle from '../../order/components/section-title/section-title'
import JobDetailsCard from './components/job-details-card/job-details-card'
import Spacer from '../../order/components/spacer/spacer'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import fileIcon from './assets/file-icon.svg'
import './job-cherry-picking.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

type JobCherryPickingProps = {
  jobId: string
  shiftId?: string
}

const JobCherryPicking = withErrorBoundary(
  observer(({ jobId, shiftId }: JobCherryPickingProps) => {
    const parsedShiftId = shiftId ? parseInt(shiftId) : undefined
    const { getCherryPickingByIdView, getCherryPickingJob, getCherryPickingApplicantsApiCall, getInterview } =
      useStore().data
    const parsedJobId = parseInt(jobId)
    const cherryPickingJob = getCherryPickingByIdView(jobId)
    const getCherryPickingJobQuery = useQuery(() => getCherryPickingJob(jobId))
    const getCherryPickingApplicantsQuery = useQuery(() =>
      getCherryPickingApplicantsApiCall({ jobId: parsedJobId, sortBy: 'rating', shiftId: parsedShiftId }),
    )
    useQuery(() => getInterview({ jobId: parsedJobId }), {
      filterError: [ApiErrorTypes.NotFound],
    })

    const { t } = useTranslation()

    return (
      <div className="JobCherryPicking">
        {!getCherryPickingJobQuery.isLoading && !getCherryPickingApplicantsQuery.isLoading ? (
          <div className="JobCherryPicking__container">
            <Header showBack={true} title={cherryPickingJob.work_category_name} />
            <Separator.Vertical height={38} />
            <SectionTitle image={fileIcon} title={t('job_cherry_picking.title')} />
            <Spacer space={14} />
            <JobDetailsCard jobDetails={cherryPickingJob} />
            <Spacer space={22} />
            <ApplicantsSection jobId={jobId} shiftId={parsedShiftId} />
          </div>
        ) : (
          <div className="spinner">
            <LoadingSpinner />
          </div>
        )}
      </div>
    )
  }),
)

export default JobCherryPicking
