import { useTranslation } from 'react-i18next'

import { Button } from '../button/button'
import { Separator } from '../app-layout/separator/separator'
import './fallback-ui.scss'

export const FallbackUI = () => {
  const { t } = useTranslation()

  const handleButtonClick = () => {
    window.history.back()
  }

  return (
    <div className="FallbackUI">
      <span className="FallbackUI__title">{t('permission_error.title')}</span>
      <Separator.Spacer flex={0.1} />
      <Button title={t('page_not_found.go_back')} onClick={handleButtonClick} />
    </div>
  )
}
