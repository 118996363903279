import { Carousel } from 'antd'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { Separator } from '../../components/app-layout'
import { useQuery, useStore } from '../../utils/mst-hooks'
import LoadingSpinner from '../../components/loading-spinner/loading-spinner'
import { DesktopHeader } from './components/desktop-header/desktop-header'
import collaborationIcon from './assets/collaboration.svg'
import puzzleIcon from './assets/puzzle.svg'
import mobileIcon from './assets/mobile.svg'
import savingsIcon from './assets/savings.svg'
import leftArrowIcon from './assets/arrow-left.svg'
import rightArrowIcon from './assets/arrow-right.svg'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import './coming-soon-page.scss'

const CAROUSEL_AUTOPLAY_DURATION = 10000

export const ComingSoonPage = observer(() => {
  const { getProfile, profile, companyDetails, getСompanyDetails } = useStore().data
  const { isLoading: profileLoading } = useQuery(getProfile)
  const { isLoading: isCompanyDetailsLoading } = useQuery(getСompanyDetails)

  const isCorporate = Boolean(companyDetails?.is_corporate)

  const redirect = useRedirect()
  const { t } = useTranslation()

  const carouselSlides = [
    {
      icon: collaborationIcon,
      iconWidth: '327px',
      title: t('home.coming_soon.we_are_coming_soon'),
      subtitle: t('home.coming_soon.prepare_for_launch'),
    },
    {
      icon: puzzleIcon,
      iconWidth: '327px',
      title: t('home.coming_soon.on_demand_staff'),
      subtitle: t('home.coming_soon.find_workers'),
    },
    {
      icon: mobileIcon,
      iconWidth: '250px',
      title: t('home.coming_soon.short_term_contracts'),
      subtitle: t('home.coming_soon.post_jobs'),
    },
    {
      icon: savingsIcon,
      iconWidth: '250px',
      title: t('home.coming_soon.incredible_savings'),
      subtitle: t('home.coming_soon.save_money'),
    },
  ]

  if (profileLoading) {
    return <LoadingSpinner />
  }

  return (
    <div className="GrStartPage">
      <DesktopHeader profileImgURL={profile?.image} hideLogo={false} hideQRScanner />
      <Separator.Vertical height={40} />
      <h1 className="GrStartPage__title">
        {t('home.coming_soon.hello')}, <span className="highlighted">{profile?.first_name}</span>
        👋
      </h1>
      <Carousel
        arrows
        prevArrow={<img src={leftArrowIcon} />}
        nextArrow={<img src={rightArrowIcon} />}
        swipe
        draggable
        autoplay
        autoplaySpeed={CAROUSEL_AUTOPLAY_DURATION}
      >
        {carouselSlides.map((slide, index) => (
          <div key={index} className="GrStartPage__slide">
            <img src={slide.icon} width={slide.iconWidth} />
            <span className="GrStartPage__slide__title">{slide.title}</span>
            <span className="GrStartPage__slide__subtitle">{slide.subtitle}</span>
          </div>
        ))}
      </Carousel>
      {!isCompanyDetailsLoading && !isCorporate && (
        <button onClick={() => redirect(routes.completeRegistration, {})} className="GrStartPage__actionButton">
          {t('profile_form.register_corporate')}
        </button>
      )}
    </div>
  )
})
