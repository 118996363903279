import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { TimeInput } from '../../../../components/form/time-input/time-input'
import { LeadForm } from '../../lead-form'
import './time.scss'

export const Time = ({ values, updateField }: LeadForm) => {
  const { t } = useTranslation()

  return (
    <div className="Time">
      <Separator.Vertical height={67} />
      <TimeInput
        label={t('lead_form.start_time')}
        value={values.time.startTime ?? ''}
        handleChange={(startTime) => {
          updateField({ ...values.time, startTime })
        }}
        className="Time__timeInput"
      />
      <Separator.Vertical height={22} />
      <TimeInput
        label={t('lead_form.end_time')}
        value={values.time.endTime ?? ''}
        handleChange={(endTime) => {
          updateField({ ...values.time, endTime })
        }}
        className="Time__timeInput"
      />
    </div>
  )
}
