import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { routes } from '../../../../routes'
import TextInput from '../../../text-input/text-input'
import { useRedirect } from '../../../../services/router'

export const SpIdForm = () => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <div className="QrScanner__manualInput">
      <div className="QrScanner__manualInput__header">
        <span className="QrScanner__manualInput__header__message">
          <span className="QrScanner__manualInput__header__message QrScanner__manualInput__header__message--highlight">
            {t('qr_scan.sp_id_form_title_1')}
          </span>{' '}
          {t('qr_scan.sp_id_form_title_2')}.
        </span>
      </div>
      <Formik
        initialValues={{ spId: '' }}
        onSubmit={({ spId }) => {
          redirect(routes.attendanceQR, { spId })
        }}
      >
        {formikProps => (
          <>
            <Form noValidate>
              <div className="QrScanner__manualInput__form">
                <TextInput
                  label={t('qr_scan.sp_id_label')}
                  formikKey="spId"
                  formikProps={formikProps}
                  className="QrScanner__manualInput__input"
                />
                <button type="submit" className="QrScanner__manualInput__submit">
                  {t('common.continue')}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  )
}
