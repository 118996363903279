import detailsImg from './details.svg'
import verticalDropdownImg from './vertical-dropdown.svg'
import costsImg from './costs.svg'
import breakdownImg from './breakdown.svg'
import arrowUpImg from './arrow-up.svg'
import exportImg from './export.svg'
import issueImg from './issue.svg'

export const images = {
  detailsImg,
  verticalDropdownImg,
  costsImg,
  breakdownImg,
  arrowUpImg,
  exportImg,
  issueImg,
}
