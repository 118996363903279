import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { FieldError } from '../../../../components/form/field-error/field-error'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { Input } from '../../../../components/form/input/input'
import { PasswordInput } from '../../../../components/password-input/password-input'
import { Button } from '../../../../components/button/button'
import { UIProps } from '../types'
import appLogoImg from '../../../../components/assets/app-logo.svg'
import './sign-up.scss'
import { MarketSelector } from '../../../../components/market-selector/market-selector'
import { PhoneInput } from '../../../../components/phone-input/phone-input'
import { getCurrentMarketConfig } from '../../../../services/market-service'

export const SignUpUI = (props: UIProps) => {
  const { t } = useTranslation()

  return (
    <div className="SignUp">
      <>
        <div className="SignUp__topContainer">
          <img src={appLogoImg} className="Login__logo" />
          <MarketSelector redirectPath="sign-up" />
        </div>
        <Separator.Vertical height={45} />
        <span className="SignUp__title">{t('sign_up.title')}</span>
        <Separator.Vertical height={9} />
        <span className="SignUp__subtitle">{t('sign_up.subtitle')}</span>
        <Separator.Vertical height={16} />
        <div className="SignUp__row">
          <FieldError errors={props.firstNameErrors}>
            <Input label={t('sign_up.first_name')} value={props.firstName} handleChange={props.handleFirstNameChange} />
          </FieldError>
          <Separator.Horizontal width={7} />
          <FieldError errors={props.lastNameErrors}>
            <Input label={t('sign_up.last_name')} value={props.lastName} handleChange={props.handleLastNameChange} />
          </FieldError>
        </div>
        <Separator.Vertical height={16} />
        <FieldError errors={props.companyNameErrors}>
          <Input
            label={t('sign_up.company_name_optional')}
            value={props.companyName}
            handleChange={props.handleCompanyNameChange}
          />
        </FieldError>
        <Separator.Vertical height={16} />
        <FieldError errors={props.phoneErrors}>
          <PhoneInput
            phone={props.phone}
            handlePhoneChange={props.handlePhoneNameChange}
            setSelectedCountry={props.setSelectedCountry}
          />
        </FieldError>
        <Separator.Vertical height={16} />
        <FieldError errors={props.emailErrors}>
          <Input label={t('sign_up.email')} value={props.email} handleChange={props.handleEmailNameChange} />
        </FieldError>
        <Separator.Vertical height={16} />
        <FieldError errors={props.passwordErrors}>
          <PasswordInput
            label={t('sign_up.password')}
            value={props.password}
            autoComplete="new-password"
            handleChange={props.handlePasswordChange}
            showHints
          />
        </FieldError>
        <Separator.Vertical height={16} />
        <FieldError errors={props.allow_marketing_emailsErrors}>
          <div className="SignUp__row">
            <input
              type="checkbox"
              onClick={props.handleAllowEmailsToggle}
              checked={props.allow_marketing_emails}
              className="SignUp__checkboxContainer__checkbox"
            />
            <Separator.Horizontal width={10} />
            <div className="SignUp__checkboxContainer__container">
              <span onClick={props.handleAllowEmailsToggle} className="SignUp__checkboxContainer__container__label">
                {t('sign_up.agree_to_emails')}
              </span>
            </div>
          </div>
        </FieldError>
        {getCurrentMarketConfig().registration.requireConsentAcceptance && (
          <Fragment>
            <Separator.Vertical height={16} />
            <div className="SignUp__checkboxContainer">
              <input
                type="checkbox"
                onClick={() => props.setIsConsentAccepted(!props.isConsentAccepted)}
                checked={props.isConsentAccepted}
                className="SignUp__checkboxContainer__checkbox"
              />
              <Separator.Horizontal width={10} />
              <div className="SignUp__checkboxContainer__container">
                <span className="SignUp__checkboxContainer__container__label">
                  Κάνοντας κλικ στο κουμπί εγγραφής, συναινείτε στη χρήση των προσωπικών σας δεδομένων από την εταιρεία
                  μας με σκοπό τη δημιουργία του λογαριασμού σας και την τοποθέτηση των παραγγελιών σας
                </span>
              </div>
            </div>
          </Fragment>
        )}
        <Separator.Vertical height={27} />
        <Button
          disabled={getCurrentMarketConfig().registration.requireConsentAcceptance && !props.isConsentAccepted}
          title={props.submitting ? t('sign_up.submitting') : t('sign_up.sign_up')}
          onClick={props.handleSubmitButtonPress}
          type="submit"
          className="SignUp__button"
        />
        <Separator.Vertical height={22} />
        <Button
          title={t('sign_up.back_to_sign_in')}
          onClick={props.handleLoginButtonPress}
          className="SignUp__backButton"
        />
        <Separator.Vertical height={40} />
      </>
    </div>
  )
}
