import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { jobDetailsPage } from '../pages/job-details'

export const jobDetailsOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [routes.jobDraftDetails.path],
  pages: [jobDetailsPage],
}
