import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Formik } from 'formik'

import { AccountSnapshot } from '../../../models/account'
import { trackingService, Conversion } from '../../../services/tracking-service'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { generateDeviceId } from '../../../utils/device-id-generator'
import { useFirebaseToken } from '../../../utils/use-firebase-token'
import { validation } from './validation'
import { UIProps } from './types'

export function createLoginContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return observer(({ onLogin }: { onLogin?: () => void }) => {
    const firebaseToken = useFirebaseToken()
    const store = useStore()

    const [loginResult, login] = useMutation(store.data.login, {
      onSuccess: (account: AccountSnapshot) => {
        trackingService.identifyUser(Number(account.user_id))
        trackingService.assignUserProperties(account.id, account.roles, account.email)
        onLogin?.()
      },
      filterError: [ApiErrorTypes.ClientError],
    })

    const remoteErrors = loginResult.error?.data.errors
    const submitting = loginResult.isLoading

    return (
      <Formik
        initialValues={{ email: '', password: '', remember_me: true }}
        onSubmit={values => {
          login({ ...values, device_id: generateDeviceId(), firebase_token: firebaseToken })
        }}
        validationSchema={validation}
      >
        {({ values, errors, setFieldValue, handleSubmit }) => (
          <UIComponent
            submitting={submitting}
            email={values.email}
            emailErrors={errors.email ?? remoteErrors?.email}
            password={values.password}
            passwordErrors={errors.password ?? remoteErrors?.password}
            handlEmailChange={email => {
              setFieldValue('email', email)
            }}
            handlPasswordChange={password => {
              setFieldValue('password', password)
            }}
            handleLoginButtonPress={() => {
              handleSubmit()
              trackingService.conversion(Conversion.SignInClicked)
            }}
            handleSignUpLinkPress={() => {
              trackingService.conversion(Conversion.SignUpClicked)
            }}
          />
        )}
      </Formik>
    )
  })
}
