import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { AccountProfileSnapshot } from '../../../../../models/account'
import { useStore, useMutation } from '../../../../../utils/mst-hooks'
import { Separator } from '../../../separator/separator'
import dropdownArrow from '../../assets/dropdown-arrow.svg'
import './company-name.scss'

export const CompanyName = observer(() => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const { account, switchProfile } = useStore().data

  const [switchProfileResult, requestSwitchProfile] = useMutation(switchProfile, {
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    onError: (err) => alert(err.data.message),
  })

  const { t } = useTranslation()

  if (!account) {
    return null
  }

  const [listProfiles, currentProfile] = account.profiles.reduce(
    (acc, item) => {
      if (item.id === account.id) {
        acc[1] = item
      } else {
        acc[0].push(item)
      }

      return acc
    },
    [[] as AccountProfileSnapshot[], account.profiles[0]],
  )

  return (
    <div className="CompanyName">
      <Separator.Vertical height={21} />
      <div
        onClick={() => {
          if (listProfiles.length < 1) return
          setDropdownVisible((prevState) => !prevState)
        }}
        className={`CompanyName__row ${listProfiles.length >= 1 ? 'clickable' : ''}`}
      >
        <div className="CompanyName__column">
          <span className="CompanyName__row__label">{t('sidebar.company')}</span>
          <span className="CompanyName__row__company-name">
            {switchProfileResult.isLoading ? 'loading...' : currentProfile.company_name}
          </span>
        </div>
        {listProfiles.length >= 1 && (
          <img src={dropdownArrow} alt="" className={`CompanyName__row__img ${dropdownVisible ? 'turned' : ''}`} />
        )}
      </div>
      <div className={`CompanyName__dropdown ${dropdownVisible ? '' : 'collapsed'}`}>
        {listProfiles.map((profile) => {
          return (
            <div
              onClick={() => {
                requestSwitchProfile(profile.id)
                setDropdownVisible(false)
              }}
              className="CompanyName__dropdown__item"
              key={profile.id}
            >
              <span className="CompanyName__dropdown__item__label">{profile.company_name}</span>
            </div>
          )
        })}
      </div>
      <Separator.Vertical height={11} />
    </div>
  )
})
