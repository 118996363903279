import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'

import { Shift } from '../order-summary-jobs-card/order-summary-jobs-card'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import Spacer from '../spacer/spacer'
import dragIcon from '../../assets/drag-icon.svg'
import './order-summary-shifts-modal.scss'

interface OrderSummaryShiftsModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  start_date: Moment
  end_date: Moment
  work_category_name: string
  shifts: Shift[]
}

const OrderSummaryShiftsModal = ({
  modalIsOpen,
  closeModal,
  start_date,
  end_date,
  shifts,
  work_category_name,
}: OrderSummaryShiftsModalProps) => {
  const { t } = useTranslation()

  const sortedShifts = shifts.slice().sort((a, b) => a.start_time - b.start_time)
  const countOfShifts = end_date.diff(start_date, 'd')

  return (
    <OgramModal modalVisible={modalIsOpen} className="OrderSummaryShiftsModal" onClose={closeModal}>
      <h2 className="OrderSummaryShiftsModal__modalTitle">{work_category_name} Schedule</h2>
      <h5 className="OrderSummaryShiftsModal__subTitle">
        {start_date.format('ddd, MMM DD')} - {end_date.format('ddd, MMM DD')}{' '}
        {t('order.order_summary_days_count', { count: countOfShifts + 1 })}
      </h5>
      <Spacer space={26} />
      {sortedShifts.map((shift, i) => (
        <div className="OrderSummaryShiftsModal__shifts" key={i}>
          <p className="OrderSummaryShiftsModal__day">{moment(shift.start_time * 1000).format('ddd,DD MMM')}</p>
          <p className="OrderSummaryShiftsModal__time">
            {moment(shift.start_time * 1000).format('hh:mm A')} - {moment(shift.end_time * 1000).format('hh:mm A')}
          </p>
        </div>
      ))}
      <Spacer space={30} />
      <img className="OrderSummaryShiftsModal__dragIcon" src={dragIcon} />
    </OgramModal>
  )
}

export default OrderSummaryShiftsModal
