import './payment-details.scss'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'

import { Header, Separator } from '../../../../components/app-layout'
import titleIcon from './assets/title-icon.svg'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { getFormattedDate, getPaymentDetailsStatusLabel, paymentMethodsData } from '../../utils'
import { PaymentMethod, PaymentStatus } from '../../../../models/payment'
import { UIProps } from '../types'
import { Invoice } from './components/invoice'
import { StickyPaymentButton } from '../../../../components/payment/sticky-payment-button/sticky-payment-button'
import { routes } from '../../../../routes'
import { StickyButton } from '../../../../components/sticky-button/sticky-button'
import { useRedirect } from '../../../../services/router'
import { WireTransferPendingAlert } from './components/wire-transfer-pending-alert'
import { WireTransferProcessingAlert } from './components/wire-transfer-processing-alert'
import { DropdownMenu } from '../../../../components/dropdown-menu/dropdown-menu'
import verticalDropdownImg from '../../../../components/assets/vertical-dropdown.svg'
import { MenuItem } from '../../../../components/dropdown-menu/components/menu-item/menu-item'
import downloadImg from '../../../../components/assets/download.svg'
import { downloadFileByUrl } from '../../../../services/download-file-by-url'
import { LeanPaymentAwaitingAuthorizationAlert } from './components/lean-payment-awaiting-authorization-alert'

export const PaymentDetails = ({
  paymentId,
  isLoading,
  currentPayment,
  onMainButtonClick,
  isWireTransferPending,
  isWireTransferProcessing,
  payNowEnabled,
  payNowButtonText,
  isLeanPaymentAwaitingAuthorization,
  resetPayment,
}: UIProps) => {
  const { t } = useTranslation()
  const redirect = useRedirect()

  const showPaymentMethod = currentPayment?.type_id !== null

  const contextMenuItems = useMemo(() => {
    if (!isWireTransferProcessing) {
      return []
    }

    return [
      <MenuItem
        key={1}
        title={t('payment.download_slip')}
        img={<img src={downloadImg} />}
        onClick={() => {
          if (currentPayment?.file) {
            downloadFileByUrl(currentPayment.file)
          }
        }}
      />,
    ]
  }, [isWireTransferProcessing])

  const headerContextMenu = !isWireTransferProcessing ? null : (
    <DropdownMenu outerComponent={<img src={verticalDropdownImg} />} items={contextMenuItems} />
  )

  return (
    <div className="PaymentDetails">
      <Header
        title={t('payment.payment_details_title', { paymentId })}
        showBack
        handleBackClick={() => redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })}
        rightComponent={headerContextMenu}
      />
      <Separator.Vertical height={35} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="PaymentDetails__container">
          {isWireTransferPending && <WireTransferPendingAlert />}
          {isWireTransferProcessing && <WireTransferProcessingAlert />}
          {isLeanPaymentAwaitingAuthorization && <LeanPaymentAwaitingAuthorizationAlert />}
          {isLeanPaymentAwaitingAuthorization && (
            <div className="PaymentDetails__cancelButtonContainer">
              <span className="PaymentDetails__cancelButton" onClick={resetPayment}>
                Cancel Initiated Payment
              </span>
            </div>
          )}
          <div className="PaymentDetails__title-container">
            <img src={titleIcon} />
            <span>{t('payment.details')}</span>
          </div>
          <div className="PaymentDetails__paymentItem">
            <div className="PaymentDetails__paymentItem__valueContainer">
              <span className="PaymentDetails__paymentItem__valueContainer__key">{t('payment.payment_number')}</span>
              <span className="PaymentDetails__paymentItem__valueContainer__value">
                {currentPayment?.payment_number}
              </span>
            </div>
            <div className="PaymentDetails__paymentItem__valueContainer">
              <span className="PaymentDetails__paymentItem__valueContainer__key">{t('payment.due_date')}</span>
              <span className="PaymentDetails__paymentItem__valueContainer__value">
                {getFormattedDate(String(currentPayment?.due_date))}
              </span>
            </div>
            <div className="PaymentDetails__paymentItem__valueContainer">
              <span className="PaymentDetails__paymentItem__valueContainer__key">{t('payment.amount')}</span>
              <span className="PaymentDetails__paymentItem__valueContainer__value">
                {currentPayment?.currency_code} {currentPayment?.amount}
              </span>
            </div>
            {showPaymentMethod && (
              <div className="PaymentDetails__paymentItem__valueContainer">
                <span className="PaymentDetails__paymentItem__valueContainer__key">{t('payment.payment_method')}</span>
                <span className="PaymentDetails__paymentItem__valueContainer__value">
                  {t(paymentMethodsData[String(currentPayment?.type_id) as PaymentMethod].title)}
                </span>
              </div>
            )}
            <div className="PaymentDetails__paymentItem__valueContainer">
              <span className="PaymentDetails__paymentItem__valueContainer__key">{t('payment.status')}</span>
              <span className="PaymentDetails__paymentItem__valueContainer__value">
                {t(getPaymentDetailsStatusLabel(String(currentPayment?.status)))}
              </span>
            </div>
          </div>
          <div className="PaymentDetails__title-container">
            <img src={titleIcon} />
            <span>{t('payment.invoices')}</span>
          </div>
          <div className="PaymentDetails__invoices">
            {currentPayment?.invoices.map((invoice) => <Invoice key={invoice.id} invoice={invoice} />)}
          </div>
          {payNowEnabled && (
            <StickyPaymentButton
              amount={`${currentPayment?.currency_code} ${currentPayment?.amount}`}
              action={onMainButtonClick}
              actionTitle={payNowButtonText}
            />
          )}
          {isWireTransferPending && (
            <StickyButton
              onClick={() => {
                redirect(routes.wireTransferUploadSlip, { paymentId: String(paymentId) })
              }}
              buttonTitle={t('payment.next')}
            />
          )}
        </div>
      )}
    </div>
  )
}
