import { FunctionComponent, useState } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useStore, useQuery, useDelayedQuery, useMutation } from '../../../utils/mst-hooks'
import { useFileService } from '../../../utils/use-file-service'
import { UIProps } from './types'
import { useIsPaymentsEnabled } from '../../../services/payment-service'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export function createInvoiceContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ id }: { id: string }) => {
      const { t } = useTranslation()

      const { getInvoice, getInvoiceDetails, downloadInvoice, reportIssue } = useStore().data

      const { isLoading: loadingInvoice } = useQuery(() => getInvoice(id))

      const { download: saveFile } = useFileService()

      const { enableQuery, query: downloadQuery } = useDelayedQuery(() => downloadInvoice(id), {
        onSuccess: (file) =>
          saveFile(file, `Invoice${invoice?.invoice_number ? ` ${invoice.invoice_number}` : ''}.pdf`),
      })

      const [isReportIssueFormVisible, setIsReportIssueFormVisible] = useState(false)
      const [reportIssueMutation, reportIssueApiCall] = useMutation(reportIssue, {
        filterError: [ApiErrorTypes.ClientError],
        onSuccess: (result) => {
          setIsReportIssueFormVisible(false)
          toast(t('invoice.issue_was_reported'))
        },
      })
      const reportIssueSubmit = ({ message }: { message: string }) => {
        reportIssueApiCall({
          id,
          message,
        })
      }
      const reportIssueErrors = reportIssueMutation.error?.data.errors
        ? Object.values(reportIssueMutation.error.data.errors).join(', ')
        : ''
      const invoice = getInvoiceDetails(id)

      const isPaymentsEnabled = useIsPaymentsEnabled()
      const hidePaymentFunctionality = !isPaymentsEnabled()

      return (
        <UIComponent
          id={id}
          loadingInvoice={loadingInvoice}
          downloadingInvoice={downloadQuery.isLoading}
          invoice={
            invoice
              ? {
                  currency: invoice.currency_code,
                  date: invoice.date,
                  dueDate: invoice.due_date,
                  items: invoice.items,
                  status: invoice.status,
                  subtotalAmount: invoice.sub_total_amount,
                  taxPercent: invoice.tax_percentage,
                  taxAmount: invoice.total_tax_amount,
                  totalAmount: invoice.total_amount,
                  transportationAmount: invoice.total_transportation_amount,
                  payment: invoice.payment,
                  invoiceNumber: invoice.invoice_number,
                }
              : null
          }
          handleDownload={enableQuery}
          isReportIssueFormVisible={isReportIssueFormVisible}
          setIsReportIssueFormVisible={setIsReportIssueFormVisible}
          handleReportIssue={reportIssueSubmit}
          reportIssueErrors={reportIssueErrors}
          isReportIssueSubmitting={reportIssueMutation.isLoading}
          hidePaymentFunctionality={hidePaymentFunctionality}
        />
      )
    }),
  )
}
