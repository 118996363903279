import { Separator } from '../../../../components/app-layout'
import { DropdownAvatar } from '../../../../components/app-layout/header/components/dropdown-avatar/dropdown-avatar'
import { NotificationsBar } from '../../../../components/app-layout/notifications-bar/notifications-bar'
import logoSmallImg from '../../../../components/assets/app-logo.svg'
import backImg from '../../../../components/assets/back.svg'
import './desktop-header.scss'
import { isMainScreen } from '../../../../services/router'
import { ScanQrButton } from '../../../../components/scan-qr-button/scan-qr-button'

type Props = {
  profileImgURL?: string
  title?: string
  subTitle?: string
  showBack?: boolean
  handleBackClick?: () => void
  rightComponent?: React.ReactNode
  hideLogo: boolean
  desktopContainerStyle?: object
  alwaysVisible?: boolean
  hideQRScanner?: boolean
}

export const DesktopHeader = ({
  profileImgURL,
  title,
  showBack,
  handleBackClick,
  subTitle,
  rightComponent,
  hideLogo,
  hideQRScanner,
}: Props) => {
  const navigateBack = () => window.history.back()

  return (
    <div className="MarketDesktopHeaderBox">
      <div className="MarketDesktopHeaderBox__container">
        <div className="MarketDesktopHeader">
          <Separator.Vertical height={24} />
          <div className="MarketDesktopHeader__container">
            {isMainScreen() ? (
              <>
                <div className="MarketDesktopHeader__main-left">
                  <img src={logoSmallImg} className={`MarketDesktopHeader__logo ${hideLogo ? 'hidden' : ''}`} />
                </div>
                <div className="MarketDesktopHeader__main-right">
                  {!hideQRScanner && <ScanQrButton style={{ marginRight: '50px' }} />}
                  <NotificationsBar />
                  <Separator.Horizontal width={23} />
                  <DropdownAvatar imageURL={profileImgURL ?? ''} />
                </div>
              </>
            ) : (
              <>
                {showBack ? (
                  <img
                    className="MarketDesktopHeader__back"
                    src={backImg}
                    alt="go back"
                    onClick={handleBackClick ?? navigateBack}
                  />
                ) : (
                  <div className="MarketDesktopHeader__main__separator" />
                )}
                {Boolean(title) && (
                  <div>
                    <h1 className="MarketDesktopHeader__title">{title}</h1>
                    {Boolean(subTitle) && <p className="MarketMobileHeader__subTitle">{subTitle}</p>}
                  </div>
                )}
                {rightComponent ? (
                  <div className="MarketDesktopHeader__main-right">{rightComponent}</div>
                ) : (
                  <div className="MarketDesktopHeader__main__separator" />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
