import { cast } from 'assertio';
export function filterMap(arr, fn) {
    const newArr = [];
    for (const el of arr) {
        const r = fn(el);
        if (r !== undefined) {
            newArr.push(r);
        }
    }
    return newArr;
}
export function objectKeys(obj) {
    return Object.keys(obj);
}
export function range(from, to, step = 1) {
    const arr = [];
    for (let i = from; i <= to; i += step) {
        arr.push(i);
    }
    if (from <= to && arr[arr.length - 1] !== to) {
        arr.push(to);
    }
    return arr;
}
export function resetTime(date) {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}
export function deepUpdate(obj, partial) {
    const newObj = { ...obj };
    for (const key in partial) {
        const k = key;
        const val = partial[key];
        newObj[k] = (isObject(val) && !(val instanceof Date) ? deepUpdate(cast(obj[k]), val) : val);
    }
    return newObj;
}
export function objectMap(obj, fn) {
    const newObj = {};
    for (const key in obj) {
        newObj[key] = fn(key, obj[key]);
    }
    return newObj;
}
export function pick(obj, keys) {
    const newObj = {};
    for (const key in obj) {
        if (keys.indexOf(key) > -1) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
export function omit(obj, keys) {
    const newObj = {};
    for (const key in obj) {
        if (keys.indexOf(key) === -1) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}
export function isObject(obj) {
    return typeof obj === 'object' && obj !== null;
}
export const snakeToCamel = (str) => str.replace(/([-_][a-z])/g, group => group
    .toUpperCase()
    .replace('-', '')
    .replace('_', ''));
export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
