import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useQuery, useStore } from '../../../../../../utils/mst-hooks'
import './calendar-location-filter.scss'
import { BusinessLocationSnapshot } from '../../../../../../models/location'
import { Multiselect } from '../../../../../../components/form/multiselect/multiselect'
import { TrackedEvent, trackingService } from '../../../../../../services/tracking-service'

function CalendarLocationFilterComponent({
  locationIds,
  setLocationIds,
}: {
  locationIds: number[]
  setLocationIds: (values: number[]) => void
}) {
  const { t } = useTranslation()

  const { locationsDataView, getLocations, getLocationByName } = useStore().data

  const { isLoading: locationsLoading } = useQuery(getLocations)

  const locationOptions =
    locationsDataView.length > 0
      ? locationsDataView.map(location => ({
          key: String(location.id),
          label: location.name,
          value: location.name,
        }))
      : []

  if (locationsLoading) {
    return null
  }

  return (
    <div className="CalendarLocationFilter">
      <Multiselect
        options={locationOptions}
        className="CalendarLocationFilter__select"
        label={t('all_jobs.filter_location')}
        onChange={values => {
          trackingService.track(TrackedEvent.TimesheetLocationFilterUsed)
          setLocationIds(values.map(locationName => (getLocationByName(locationName) as BusinessLocationSnapshot).id))
        }}
      />
    </div>
  )
}

export const CalendarLocationFilter = observer(CalendarLocationFilterComponent)
