import './sticky-button.scss'
import { StickyPanel } from '../sticky-panel/sticky-panel'

type InputProps = {
  onClick: () => void
  buttonTitle: string
}

export const StickyButton = ({ onClick, buttonTitle }: InputProps) => {
  return (
    <StickyPanel onClick={onClick} style={{ justifyContent: 'center' }}>
      <button className="StickyButton__button" onClick={onClick} style={{ background: '#7767E4' }}>
        {buttonTitle}
      </button>
    </StickyPanel>
  )
}
