import './qr-scanner.scss'
import { QrReader } from 'ogram-react-qr-reader'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import xIcon from './assets/x.svg'
import { UIStateContext } from '../ui-state-management/ui-state-management'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { ViewFinder } from './components/view-finder/view-finder'
import { CameraUnavailable } from './components/camera-permission-denied/camera-unavailable'
import { Instruction } from './components/instruction/instruction'
import { SpIdForm } from './components/sp-id-form/sp-id-form'

export const QrScanner = () => {
  const [hasCamera, setHasCamera] = useState(false)
  const [permissionState, setPermissionState] = useState<PermissionState>('prompt')
  const { showQRScanner, setShowQRScanner } = useContext(UIStateContext)

  const { t } = useTranslation()

  const isPermissionGranted = () => {
    return permissionState === 'granted'
  }

  const handleCameraPermission = async (withOnchange = true) => {
    // @ts-ignore
    const permissionResult = await navigator.permissions.query({ name: 'camera' })
    setPermissionState(permissionResult.state)
    if (withOnchange) {
      permissionResult.onchange = () => {
        setPermissionState(permissionResult.state)
      }
    }
  }

  let handleCameraPermissionInterval: NodeJS.Timeout | null = null

  useEffect(() => {
    if (!showQRScanner) {
      return
    }

    async function checkCameraAccess() {
      try {
        await handleCameraPermission()
        if (permissionState === 'prompt') {
          handleCameraPermissionInterval = setInterval(() => {
            handleCameraPermission(false)
          }, 1000)
        }
      } catch (error) {
        // means it Firefox or other browser where we can't get permissions, so for now will
        // render camera relying on previous method (checkForCamera)
        console.error('Error fetching camera permissions:', error)
        setPermissionState('granted')
      }
    }

    async function checkForCamera() {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()
        const videoInputs = devices.filter(device => device.kind === 'videoinput')

        const hasVideoInputs = videoInputs.length > 0
        setHasCamera(hasVideoInputs)

        if (hasVideoInputs) {
          checkCameraAccess()
        }
      } catch (error) {
        console.error('Error checking for camera:', error)
        setHasCamera(false)
      }
    }

    checkForCamera()

    return () => {
      if (handleCameraPermissionInterval !== null) {
        clearInterval(handleCameraPermissionInterval)
      }
    }
  }, [showQRScanner, permissionState])

  const redirect = useRedirect()

  const [isDetectorActive, setIsDetectorActive] = useState(false)

  if (!showQRScanner) {
    return null
  }

  return (
    <div className="QrScanner__container">
      <div className="QrScanner__content">
        <div className="QrScanner__closeContainer" onClick={() => setShowQRScanner(false)}>
          <img src={xIcon} />
        </div>
        <QrReader
          onResult={(result, error) => {
            if (result) {
              setIsDetectorActive(true)
              try {
                const url = new URL(result.getText())
                const params = new URLSearchParams(url.search)
                const spId = params.get('spId')

                if (!spId) {
                  toast.error(t('qr_scan.invalid_qr_code_provided'))
                  return
                }

                setShowQRScanner(false)
                redirect(routes.attendanceQR, { spId })
              } catch (e) {
                toast.error(t('qr_scan.invalid_qr_code_provided'))
              }
            } else {
              setIsDetectorActive(false)
            }
          }}
          constraints={{ facingMode: 'environment' }}
          containerStyle={{ display: isPermissionGranted() ? 'flex' : 'none', width: '100%', justifyContent: 'center' }}
          videoContainerStyle={{
            display: isPermissionGranted() ? 'unset' : 'none',
            width: '100%',
            paddingTop: 'unset',
            maxWidth: '650px',
            overflow: 'unset',
          }}
          videoStyle={{
            display: isPermissionGranted() ? 'unset' : 'none',
            width: '100%',
            height: 'unset',
            position: 'unset',
            maxWidth: '650px',
          }}
          ViewFinder={() => (isPermissionGranted() ? <ViewFinder isDetectorActive={isDetectorActive} /> : null)}
        />
        {!isPermissionGranted() && (
          <CameraUnavailable hasCamera={hasCamera} isCameraAccessDenied={permissionState === 'denied'} />
        )}
        <Instruction />
        <SpIdForm />
      </div>
      <div className="QrScanner__bottom"></div>
    </div>
  )
}
