import moment from 'moment'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../../../../../components/loading-spinner/loading-spinner'
import { PaymentStatus } from '../../../../../../models/payment'
import { UIProps } from '../types'
import completedImg from './assets/completed-img.svg'
import './card-payment-status.scss'

export const CardPaymentStatus = ({
  isCompleted,
  clientEmail,
  isLoading,
  isUpdatingStatus,
  paymentNumber,
  fulfilledAt,
  paymentMethodTitle,
  amount,
  status,
  statusId,
  nextAction,
  nextActionTitle,
}: UIProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <LoadingSpinner />
  }

  const fulfilledAtString = moment(fulfilledAt).format('MMM DD, YYYY | hh:mm:ss A')

  return (
    <div className="CardPaymentStatus">
      <div className="CardPaymentStatus__content">
        <img src={completedImg} />
        <span className="CardPaymentStatus__title">
          {isCompleted ? t('payment.payment_completed') : t('payment.payment_processing')}
        </span>
        <span className="CardPaymentStatus__subtitle">
          {isCompleted ? (
            <Fragment>
              {t('payment.was_successful')}
              <br />
              {t('payment.email_was_sent_to')}
              <span className="CardPaymentStatus__subtitle__email">{clientEmail}</span>
            </Fragment>
          ) : (
            <Fragment>{t('payment.can_go_to_payments_list_page')}</Fragment>
          )}
        </span>
        <div className="CardPaymentStatus__data">
          <div className="CardPaymentStatus__dataItem">
            <span className="CardPaymentStatus__dataItem__key">{t('payment.transaction_id')}</span>
            <span className="CardPaymentStatus__dataItem__value">{paymentNumber}</span>
          </div>
          {fulfilledAt && (
            <div className="CardPaymentStatus__dataItem">
              <span className="CardPaymentStatus__dataItem__key">{t('payment.date')}</span>
              <span className="CardPaymentStatus__dataItem__value">{fulfilledAtString}</span>
            </div>
          )}
          <div className="CardPaymentStatus__dataItem">
            <span className="CardPaymentStatus__dataItem__key">{t('payment.payment_method')}</span>
            <span className="CardPaymentStatus__dataItem__value">{paymentMethodTitle}</span>
          </div>
          <div className="CardPaymentStatus__dataItem">
            <span className="CardPaymentStatus__dataItem__key">{t('payment.amount')}</span>
            <span className="CardPaymentStatus__dataItem__value">{amount}</span>
          </div>
          <div className="CardPaymentStatus__dataItem">
            <span className="CardPaymentStatus__dataItem__key">{t('payment.status')}</span>
            <span
              className={`CardPaymentStatus__dataItem__value${
                !isUpdatingStatus && statusId === Number(PaymentStatus.Completed)
                  ? ' CardPaymentStatus__dataItem__value--green'
                  : ''
              }`}
            >
              {isUpdatingStatus ? t('payment.updating_status') : status}
            </span>
          </div>
        </div>
      </div>
      <button onClick={nextAction} className="CardPaymentStatus__goToPayments">
        {nextActionTitle ?? t('payment.go_to_payments_list_page')}
      </button>
    </div>
  )
}
