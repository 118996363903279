import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Schedule, ScheduleDate, ScheduleShiftSnapshot, ShiftStatus } from '../../../../../../../models/schedule/types'
import { ShiftTime } from './shift-time'
import { Status, StatusType } from './status'

import './shift-cell.scss'

function getShiftStatus(status: ShiftStatus): StatusType {
  switch (status) {
    case ShiftStatus.Completed:
      return StatusType.shiftCompleted
    case ShiftStatus.Started:
      return StatusType.shiftStarted
    case ShiftStatus.Upcoming:
      return StatusType.shiftUpcoming
  }
}

function getPercentFulfilled(shift: ScheduleShiftSnapshot) {
  return Math.round((shift.fulfilled_count * 100) / shift.required_quantity)
}

export function ShiftCellComponent({
  jobSchedule,
  shift,
}: {
  jobSchedule: Schedule
  shift: ScheduleShiftSnapshot | null
  dateShift: ScheduleDate | null
}) {
  const { t } = useTranslation()

  if (shift === null) return null
  return (
    <div className="ShiftCell">
      <ShiftTime
        shift={shift}
        startTimeSeconds={shift.start_time}
        endTimeSeconds={shift.end_time}
        jobSchedule={jobSchedule}
      />
      <Status
        status={getShiftStatus(shift.status)}
        title={t('all_jobs.percent_filled', {
          count: getPercentFulfilled(shift),
        })}
      />
    </div>
  )
}

export const ShiftCell = observer(ShiftCellComponent)
