import { observer } from 'mobx-react'

import { MobileHeader } from './components/mobile-header/mobile-header'
import { useStore } from '../../../utils/mst-hooks'
import { DesktopHeader } from './components/desktop-header/desktop-header'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { isCurrentMarketLaunched } from '../../../services/market-service'

interface HeaderProps {
  title?: string
  subTitle?: string
  handleBackClick?: () => void
  showBack?: boolean
  rightComponent?: React.ReactNode
  bottomComponent?: React.ReactNode
  desktopContainerStyle?: object
  mobileContainerStyle?: object
  hideLogo?: boolean
}

export const Header = observer(
  ({
    handleBackClick,
    title,
    subTitle,
    showBack,
    rightComponent,
    bottomComponent,
    desktopContainerStyle,
    mobileContainerStyle,
    hideLogo = false,
  }: HeaderProps) => {
    const store = useStore()
    const redirect = useRedirect()

    const onBackClick = handleBackClick
      ? handleBackClick
      : () => {
          const isFirstVisit = [1, 2].includes(window.history.length)
          if (isFirstVisit) {
            redirect(routes.index, {})
            return
          }

          window.history.back()
        }

    if (!isCurrentMarketLaunched()) {
      return null
    }

    return (
      <>
        <MobileHeader
          handleBackClick={onBackClick}
          title={title}
          subTitle={subTitle}
          profileImgURL={store.data.profile?.image}
          showBack={showBack}
          rightComponent={rightComponent}
          bottomComponent={bottomComponent}
          containerStyle={mobileContainerStyle}
          logged={store.data.logged}
        />
        <DesktopHeader
          handleBackClick={onBackClick}
          title={title}
          subTitle={subTitle}
          profileImgURL={store.data.profile?.image}
          showBack={showBack}
          rightComponent={rightComponent}
          bottomComponent={bottomComponent}
          containerStyle={desktopContainerStyle}
          hideLogo={hideLogo}
        />
      </>
    )
  },
)
