import './toggle.scss'

type ToggleProps = {
  active: boolean
  onToggle: () => void
}

export const Toggle = ({ active, onToggle }: ToggleProps) => {
  return (
    <div onClick={onToggle} className={`AttendanceCard__Toggle ${active ? 'AttendanceCard__Toggle--active' : ''}`}>
      <div className="AttendanceCard__Toggle__background" />
      <div className="AttendanceCard__Toggle__switch"></div>
    </div>
  )
}
