import { useTranslation } from 'react-i18next'

import LoadingSpinner from '../../../../../components/loading-spinner/loading-spinner'
import { Stepper } from '../../../../../components/stepper/stepper'
import { JobDraftDetailsFormComponent } from '../../../../../components/job-draft-details-form/job-draft-details-form-component'
import { UIProps } from '../types'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'

export const OrderDuplicateUI = ({
  isLoading,
  draftJobs,
  currentJobIndex,
  duplicatedOrderId,
  isLatestJob,
  onSubmit,
  getFormTitle,
  isFirstJob,
  handleBackPress,
}: UIProps) => {
  const { t } = useTranslation()

  const handleSubmit = () => {
    onSubmit((draftJobs as DraftJobInstance[])[currentJobIndex])
  }

  return isLoading || draftJobs === null ? (
    <LoadingSpinner />
  ) : (
    <JobDraftDetailsFormComponent
      formSubtitle={t('duplicate_order.duplicate_job_subtitle')}
      orderDraftTitle={t('duplicate_order.duplicate_order')}
      job={draftJobs[currentJobIndex]}
      orderId={String(duplicatedOrderId)}
      isExtension={false}
      onSubmit={handleSubmit}
      getFormTitle={getFormTitle}
      formActions={isSubmitting => {
        return (
          <Stepper
            stepsCount={draftJobs.length}
            currentStep={currentJobIndex}
            nextDisabled={false}
            backDisabled={isFirstJob}
            nextButtonLabel={!isLatestJob ? t('job_draft_details.next') : t('job_draft_details.create_duplicate')}
            shouldNextButtonSubmitForm={true}
            handleNextPress={handleSubmit}
            handleBackPress={handleBackPress}
            objectNameLabel="Job"
          />
        )
      }}
    />
  )
}
