import { types } from 'mobx-state-tree'

export enum ShiftStatus {
  Upcoming = 'Upcoming',
  Started = 'Started',
  Completed = 'Completed',
}

export const ScheduleShiftModel = types.model('ScheduleShift', {
  // Record<shift_id, model>
  id: types.identifierNumber,
  start_time: types.number,
  end_time: types.number,
  fulfilled_count: types.number,
  required_quantity: types.number,
  status: types.enumeration(Object.values(ShiftStatus)),
})
