import { useTranslation } from 'react-i18next'

import { ArrowLeft } from '../arrow-left'
import { ArrowRight } from '../arrow-right'

type InputProps = {
  currentPage: number
  setCurrentPage: (value: number) => void
  fetchPastWorkers: (page: number) => void
  pastSPsPagesCount: number
  isFetching: boolean
}

export const Pagination = ({
  currentPage,
  setCurrentPage,
  fetchPastWorkers,
  pastSPsPagesCount,
  isFetching,
}: InputProps) => {
  const { t } = useTranslation()

  return (
    <div className="WorkersList__filters__pagination desktop-only">
      <ArrowLeft
        isEnabled={currentPage > 1}
        onClick={() => {
          if (currentPage <= 1 || isFetching) {
            return
          }

          const newPage = currentPage - 1
          setCurrentPage(newPage)
          fetchPastWorkers(newPage)
        }}
      />
      <div className="WorkersList__filters__pagination__pages">
        <span className="WorkersList__filters__pagination__pageText">{t('pagination.page')}</span>
        <div className="WorkersList__filters__pagination__pageValue">{currentPage}</div>
        <span className="WorkersList__filters__pagination__pageText">
          {t('pagination.of')} {pastSPsPagesCount}
        </span>
      </div>
      <ArrowRight
        isEnabled={currentPage < pastSPsPagesCount}
        onClick={() => {
          if (currentPage >= pastSPsPagesCount || isFetching) {
            return
          }

          const newPage = currentPage + 1
          setCurrentPage(newPage)
          fetchPastWorkers(newPage)
        }}
      />
    </div>
  )
}
