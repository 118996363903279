import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { JobDraftFieldError } from '../error/error'
import './accreditation.scss'

export const JobDraftAccreditation = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  return (
    <div className="JobDraftAccreditation">
      <span className="Popup__title">{t('job_draft_details.accreditation_title')}</span>
      <span className="JobDraftAccreditation__description">{t('job_draft_details.accreditation_subtitle')}</span>
      <div className="JobDraftAccreditation__innerContainer">
        <Button
          title={t('job_draft_details.accreditation_yes')}
          onClick={() => {
            job.accreditation.set(true)
          }}
          className={`JobDraftAccreditation__innerContainer__button ${
            job.accreditation.value === true ? 'active' : ''
          } ${job.accreditation.hasError ? 'error' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.accreditation_no')}
          onClick={() => {
            job.accreditation.set(false)
          }}
          className={`JobDraftAccreditation__innerContainer__button ${
            job.accreditation.value === false ? 'active' : ''
          } ${job.accreditation.hasError ? 'error' : ''}`}
        />
        <JobDraftFieldError error={job.accreditation.error} />
      </div>
    </div>
  )
})
