import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/rating-requests'
import '../onboarding.scss'

export const ratingRequestsOnboarding: OnboardingContent = {
  title: t('rating_requests_onboarding.title'),
  paths: [routes.jobRatingRequests.path],
  pages: [
    {
      title: t('rating_requests_onboarding.pending_feedback_title'),
      content: [
        { image: { src: images.jobsPendingFeedback, className: 'Onboarding__bigImg' } },
        { description: t('rating_requests_onboarding.pending_feedback') },
      ],
    },
    {
      title: t('rating_requests_onboarding.rating_details_title'),
      content: [
        { image: { src: images.jobRatingDetailsImg, className: 'Onboarding__bigImg' } },
        { description: t('rating_requests_onboarding.rating_details') },
      ],
    },
  ],
}
