import { useContext } from 'react'

import { Link as RouterLink, routerContext, Route } from '../../../../../services/router'
import { Separator } from '../../../separator/separator'
import './link.scss'

export const Link = ({
  title,
  route,
  imageSource,
  params,
}: {
  title: string
  route: Route<Record<string, unknown>>
  imageSource: string
  params: Record<string, string>
}) => {
  const {
    url: { path },
  } = useContext(routerContext)
  return (
    <li className="LinkItem">
      <RouterLink route={route} params={params} className={`LinkItem__link ${path === route.path && 'active'}`}>
        <img src={imageSource} alt="" />
        <Separator.Horizontal width={16} />
        <span className={path === route.path ? 'active' : ''}>{title}</span>
      </RouterLink>
    </li>
  )
}
