import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Link } from '../../../services/router'
import { AttendanceManager } from '../../../models/attendance-manager'
import { Loading } from '../../../components/loading/loading'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import './attendance-manager-list.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const AttendanceManagerList = withErrorBoundary(
  observer(() => {
    const store = useStore()
    const { attendanceManagersList } = store.data
    const { isLoading } = useQuery(store.data.getAttendanceManagers)

    const { t } = useTranslation()

    if (isLoading) return <Loading />

    return (
      <div className="AttendanceManagerList">
        <h1>{t('attendance_manager_list.title')}</h1>
        <Link route={routes.addAttendanceManagers} params={{}}>
          {t('attendance_manager_list.add_manager')}
        </Link>
        {attendanceManagersList.map((manager) => (
          <AttendanceManagerRow key={manager.id} manager={manager} />
        ))}
      </div>
    )
  }),
)

const AttendanceManagerRow = withErrorBoundary(
  observer((props: { manager: AttendanceManager }) => {
    const { t } = useTranslation()

    const { manager } = props
    return (
      <div className="AttendanceManagerRow">
        <div className="AttendanceManagerRow__main">
          <div className="AttendanceManagerRow__name">
            {manager.first_name} {manager.last_name}
          </div>
          <div>{manager.email}</div>
        </div>
        <Link route={routes.editAttendanceManagers} params={{ id: String(manager.id) }}>
          {t('attendance_manager_list.edit')}
        </Link>
      </div>
    )
  }),
)
