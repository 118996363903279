import { Toggle } from '../../../../../../../components/toggle/toggle'
import './job-field.scss'

type JobFieldProps = {
  label: string
  selected: boolean
  onToggle: () => void
}

export const JobField = ({ label, selected, onToggle }: JobFieldProps) => {
  return (
    <div className="JobField">
      <span className="JobField__label">{label}</span>
      <Toggle active={selected} onToggle={onToggle} />
    </div>
  )
}
