import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const AmendModel = types.model('amend', {
  id: types.identifierNumber,
  name: types.string,
})

export const amendActions = (self: DataStore) => ({
  getAmendReasons: flow(function* () {
    const amendReasons = (yield self.request('get', 'client/order/adjustment-reasons/all')) as AmendSnapshot[]
    amendReasons.forEach((reason) => {
      self.amendReasons.set(String(reason.id), reason)
    })
  }),

  setOrderAmendReason: flow(function* ({ orderId, reasonId }: { orderId: string; reasonId: number }) {
    yield self.request('post', 'client/order/adjustment-reasons', {
      order_id: orderId,
      adjustment_reason_id: reasonId,
    })
  }),
})

export const amendViews = (self: DataStore) => ({
  get amendReasonList() {
    return Array.from(self.amendReasons.values())
  },

  getAmendReasonIdByName(name: string) {
    return Array.from(self.amendReasons.values()).find((item) => item.name === name)?.id
  },
})

export type Amend = SnapshotIn<typeof AmendModel>
export type AmendSnapshot = SnapshotOut<typeof AmendModel>
