import { useEffect, useState } from 'react'

import { AndroidBanner } from './android-banner/android-banner'
import { load, save } from '../../services/storage'
import { IosBanner } from './ios-banner/ios-banner'
import './common/banner-style.scss'
import { TrackedEvent, trackingService } from '../../services/tracking-service'

export const InstallAppBanner = () => {
  const [showBanner, setShowBanner] = useState<boolean>(!Boolean(load('installAppBannerWasShown')))

  useEffect(() => {
    trackingService.track(TrackedEvent.ViewedInstallProgressiveAppBanner)
  }, [])

  // @ts-ignore
  const isIos = /ipad|iphone|ipod/.test(navigator.userAgent.toLowerCase()) && !window.MSStream
  // @ts-ignore
  const isAndroid = /android/.test(navigator.userAgent.toLowerCase()) && !window.MSStream

  const closeBanner = () => {
    save('installAppBannerWasShown', true)
    setShowBanner(false)
  }

  if (!showBanner) {
    return null
  }

  return (
    <>
      {isAndroid && <AndroidBanner showBanner={showBanner} closeBanner={closeBanner} />}
      {isIos && <IosBanner showBanner={showBanner} closeBanner={closeBanner} />}
    </>
  )
}
