import { ApiError, ApiErrorTypes } from './api-types';
let tokenIsRefreshing = false;
async function checkRefresh() {
    return new Promise(resolve => {
        let retries = 0;
        const fn = () => setTimeout(() => {
            if (!tokenIsRefreshing) {
                resolve(retries);
            }
            else if (retries >= 5) {
                tokenIsRefreshing = false;
                resolve(retries);
            }
            else {
                retries++;
                fn();
            }
        }, 800);
        fn();
    });
}
export async function refreshExpiredToken(response) {
    if (response.data.code === 'token_expired') {
        if (response.config.url === this.config.refreshURL && response.status !== 200) {
            throw new ApiError(ApiErrorTypes.AuthError, {
                code: 'common',
                message: 'refresh token error',
            });
        }
        let headers;
        let authKey = await this.getAuthKey();
        if (authKey) {
            headers = { authorization: await this.getAuthKey() };
        }
        if (tokenIsRefreshing) {
            await checkRefresh();
            authKey = await this.getAuthKey();
            headers = authKey ? { authorization: authKey } : undefined;
            return this.api.request({
                ...response.config,
                headers,
            });
        }
        tokenIsRefreshing = true;
        const refreshResponse = await this.api.post(this.config.refreshURL, null, {
            headers,
        });
        if (refreshResponse.status === 200) {
            const authorizationKey = refreshResponse.headers.authorization;
            await this.setAuthKey(authorizationKey); // set token anyway so if we depend on cookies will remove current token from storage
            if (authorizationKey) {
                headers = { authorization: authorizationKey };
            }
            else {
                headers = undefined;
            }
            tokenIsRefreshing = false;
            return this.api.request({
                ...response.config,
                headers
            });
        }
        tokenIsRefreshing = false;
    }
    return response;
}
export async function checkTokenRefreshing(request) {
    if (tokenIsRefreshing && request.url !== this.config.refreshURL) {
        await checkRefresh();
    }
    return request;
}
