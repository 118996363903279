import moment, { Moment } from 'moment'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import notAttended from '../../assets/not-attended-icon.svg'
import { timeFormat } from '../../../../utils/datetime-utils'
import { AttendanceData } from '../../shift-details/shift-details'
import { useStore } from '../../../../utils/mst-hooks'
import { ShiftsModel } from '../../../../models/attendance-report-shift'
import './attendance-card.scss'
import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import { TimeInput } from '../../../../components/form/time-input/time-input'
import { Select } from '../../../../components/form/select/select'
import { DAY_OFF_REASON_ID } from '../../../../models/shift-cancel-reason'
import { Toggle } from './components/toggle/toggle'
export interface AttendanceShift {
  id: number
  hours: number
  client_checkin_time: string | null
  client_checkout_time: string | null
  status_id: number
  sp: {
    name: string
    image: string
  }
}

export interface Job {
  id: number
  start_time: string
  end_time: string
  time_to_auto_confirm: number
  pending_shifts: number
  job_id: number
  work_category_name: string
  client_location_name: string
}

interface AttendanceCardProps {
  job: Job
  attendanceShift: ShiftsModel
  onUpdate: (attendanceData: AttendanceData) => void
}

const AttendanceCard = ({ attendanceShift, job, onUpdate }: AttendanceCardProps) => {
  const [attendanceState, setAttendanceState] = useState(
    Boolean(attendanceShift.client_checkin_time) && Boolean(attendanceShift.client_checkout_time),
  )
  const isActiveShift = attendanceShift.status_id === 8
  const [shift, setShift] = useState(attendanceShift)
  const [selectedReason, setSelectedReason] = useState(DAY_OFF_REASON_ID)

  const [shiftCheckInTime, setShiftCheckInTime] = useState<string | null>(() => {
    const time = shift.client_checkin_time ?? job.start_time
    return time ? moment(time, 'YYYY-MM-DD hh:mm:ss').format('HH:mm') : null
  })
  const [shiftCheckOutTime, setShiftCheckOutTime] = useState<string | null>(() => {
    const time = shift.client_checkout_time ?? job.end_time
    return time ? moment(time, 'YYYY-MM-DD hh:mm:ss').format('HH:mm') : null
  })

  const [showTimeErrorMessage, setShowTimeErrorMessage] = useState<boolean>(false)
  const [durationWarningText, setDurationWarningText] = useState<string | null>(null)

  const { cancelReasons } = useStore().data

  useEffect(() => {
    const checkInTimeMoment = moment(shiftCheckInTime, 'HH:mm')
    const checkOutTimeMoment = moment(shiftCheckOutTime, 'HH:mm')

    if (checkInTimeMoment.diff(checkOutTimeMoment, 'h') > 0) {
      checkOutTimeMoment.add(1, 'day')
    }

    const diff = Number(checkOutTimeMoment.diff(checkInTimeMoment, 'h', true).toFixed(1))
    setShowTimeErrorMessage(diff > 12)
  }, [shiftCheckInTime, shiftCheckOutTime])

  const { t } = useTranslation()
  const redirect = useRedirect()

  const toggleActiveState = () => {
    setAttendanceState(false)
    onUpdate({
      id: attendanceShift.id,
      action: 'cancel',
      reason: cancelReasons.find(reason => reason.name.toLowerCase() === 'no show')?.id,
    })
  }

  function openSPProfile(spId: number, spName: string) {
    return redirect(routes.applicantDetails, {
      jobId: String(job.job_id),
      applicantId: String(spId),
      applicantName: spName,
    })
  }

  function updateAttendanceTimeWithWarning(checkInTime: Moment | string | null, checkOutTime: Moment | string | null) {
    const checkinTime = moment(checkInTime, 'HH:mm')
    const checkoutTime = moment(checkOutTime, 'HH:mm')
    const duration = moment.duration(checkoutTime.diff(checkinTime))
    let hoursDuration = duration.asHours()
    let minutesDuration = duration.asMinutes()
    // handle crossing am/pm and vice-versa:
    if (hoursDuration < 0) {
      hoursDuration = hoursDuration + 24
    }
    if (minutesDuration < 0) {
      minutesDuration = minutesDuration + 60 * 24
    }
    const jobDuration = Number(new Date(job.end_time)) - Number(new Date(job.start_time))
    const jobHoursDuration = jobDuration / (1000 * 60 * 60)
    const jobMinutesDuration = jobDuration / (1000 * 60)
    if (minutesDuration < jobMinutesDuration) {
      const hoursDiff = (Number(jobHoursDuration) - hoursDuration).toFixed(2)
      setDurationWarningText(
        t('shift_attendance_common.are_you_sure_og_worked_n_hours', {
          name: attendanceShift.sp.name,
          hours: hoursDuration,
          difference: hoursDiff,
        }),
      )
    }

    setDurationWarningText(null)

    updateAttendanceTime(checkInTime, checkOutTime)
  }

  function updateAttendanceTime(checkInTime: Moment | string | null, checkOutTime: Moment | string | null) {
    const shiftCheckInDate = moment(shift.client_checkin_time ? shift.client_checkin_time : job.start_time)
    const shiftCheckOutDate = moment(shift.client_checkin_time ? shift.client_checkin_time : job.end_time) // reseting the checkout date as check in date
    const checkInTimeMoment = moment(checkInTime, 'HH:mm')
    const checkOutTimeMoment = moment(checkOutTime, 'HH:mm')

    setAttendanceState(true)

    shiftCheckInDate.set({
      hour: checkInTimeMoment.get('hour'),
      minute: checkInTimeMoment.get('minute'),
    })
    shiftCheckOutDate.set({
      hour: checkOutTimeMoment.get('hour'),
      minute: checkOutTimeMoment.get('minute'),
    })

    if (shiftCheckOutDate.isBefore(shiftCheckInDate)) {
      shiftCheckOutDate.add(1, 'day')
    }

    onUpdate({
      check_in_time: shiftCheckInDate.format('YYYY-MM-DD HH:mm:ss'),
      check_out_time: shiftCheckOutDate.format('YYYY-MM-DD HH:mm:ss'),
      action: 'confirm',
      id: shift.id,
    })

    setShift(prevShift => ({
      ...prevShift,
      client_checkin_time: shiftCheckInDate.format('YYYY-MM-DD HH:mm'),
      client_checkout_time: shiftCheckOutDate.format('YYYY-MM-DD HH:mm'),
    }))
  }

  const calculateShiftHours = () => {
    const checkInMoment = moment(shift.client_checkin_time)
    const checkOutMoment = moment(shift.client_checkout_time)
    return `${checkOutMoment.diff(checkInMoment, 'h', true).toFixed(1)} h`
  }

  const submitAttendanceTime = (checkInTime: Moment | string | null, checkOutTime: Moment | string | null) => {
    updateAttendanceTimeWithWarning(checkInTime, checkOutTime)
  }

  const handleReasonChange = (value: string) => {
    setSelectedReason(Number(value))
    onUpdate({
      id: attendanceShift.id,
      action: 'cancel',
      reason: Number(value),
    })
  }

  const cancelReasonOptions = cancelReasons.map(cancelReason => ({
    key: String(cancelReason.id),
    label: cancelReason.name,
    value: String(cancelReason.id),
  }))

  return (
    <div className="AttendanceCard">
      <div className="AttendanceCard__container">
        <div className="AttendanceCard__data">
          <div
            onClick={() => openSPProfile(shift.sp.id, shift.sp.name)}
            className="AttendanceCard__avatar"
            style={{ backgroundImage: `url(${shift.sp.image})` }}
          ></div>
          <div className="AttendanceCard__basic">
            <h4 onClick={() => openSPProfile(shift.sp.id, shift.sp.name)}>{shift.sp.name}</h4>
            <button className="AttendanceCard__time">
              {attendanceState ? (
                <p>
                  {moment(shift.client_checkin_time).format(timeFormat)} -{' '}
                  {moment(shift.client_checkout_time).format(timeFormat)} ({calculateShiftHours()})
                </p>
              ) : (
                <p>
                  <img src={notAttended} />{' '}
                  {(cancelReasons.find(reason => reason.id === Number(selectedReason))?.name as string) ||
                    t('shift_attendance_common.day_off')}
                </p>
              )}
            </button>
          </div>
        </div>
        {isActiveShift && (
          <div className="AttendanceCard__attendance">
            <span
              className={`AttendanceCard__attendance__title ${
                attendanceState ? 'AttendanceCard__attendance__title--attended' : null
              }`}
            >
              {attendanceState ? t('shifts_attendance_details.attended') : t('shifts_attendance_details.not_attended')}
            </span>
            <Toggle
              active={attendanceState}
              onToggle={() => {
                if (attendanceState) {
                  toggleActiveState()
                  return
                }

                submitAttendanceTime(shiftCheckInTime, shiftCheckOutTime)
              }}
            />
          </div>
        )}
      </div>
      {isActiveShift && (
        <div className="AttendanceCard__actions">
          {attendanceState ? (
            <div className="AttendanceCard__timeForm__container">
              <div className="AttendanceCard__timeForm__inputs">
                <TimeInput
                  label={t('shift_attendance_common.check_in')}
                  value={shiftCheckInTime}
                  handleChange={time => {
                    setShiftCheckInTime(time)
                    submitAttendanceTime(time, shiftCheckOutTime)
                  }}
                  className="AttendanceCard__timeForm__input"
                />
                <TimeInput
                  label={t('shift_attendance_common.check_out')}
                  value={shiftCheckOutTime}
                  handleChange={time => {
                    setShiftCheckOutTime(time)
                    submitAttendanceTime(shiftCheckInTime, time)
                  }}
                  className="AttendanceCard__timeForm__input"
                />
              </div>
              <div className="AttendanceCard__timeForm__total">
                <span className="AttendanceCard__timeForm__total__title">
                  {t('shift_attendance_common.total_hours')}
                </span>
                <span className="AttendanceCard__timeForm__total__value">{calculateShiftHours()}</span>
              </div>
            </div>
          ) : (
            <div className="AttendanceCard__cancelForm__container">
              <Select
                value={String(selectedReason)}
                onChange={handleReasonChange}
                className="AttendanceCard__cancelForm__select"
                label={t('shift_attendance_common.select_reason')}
                options={cancelReasonOptions}
              />
            </div>
          )}
          {attendanceState && showTimeErrorMessage && (
            <p className="AttendanceCard__error">{t('shift_attendance_common.shift_duration_too_long')}</p>
          )}
          {attendanceState && durationWarningText && <p className="AttendanceCard__warning">{durationWarningText}</p>}
        </div>
      )}
    </div>
  )
}

export default AttendanceCard
