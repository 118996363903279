import { useTranslation } from 'react-i18next'

import { InfoIcon } from '../../../../../components/assets/components/info-icon'

export const WireTransferPendingAlert = () => {
  const { t } = useTranslation()

  return (
    <div className="PaymentDetails__alert">
      <span className="PaymentDetails__alert__title">{t('payment.please_upload_slip')}</span>
      <div className="PaymentDetails__alert__content">
        <div className="PaymentDetails__alert__content__icon">
          <InfoIcon color="#FCB35C" />
        </div>
        <div className="PaymentDetails__alert__content__texts">
          <span className="PaymentDetails__alert__content__title">{t('payment.bank_slip_info_in_alert')}</span>
          <span className="PaymentDetails__alert__content__subtitle">
            {t('payment.you_can_upload_slip_now_or_later')}
          </span>
        </div>
      </div>
    </div>
  )
}
