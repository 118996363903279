import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../services/router'
import LoadingSpinner from '../../components/loading-spinner/loading-spinner'
import { useQuery, useStore } from '../../utils/mst-hooks'
import { routes } from '../../routes'

export const ConfirmClientInvitationPage = observer(({ clientId }: { clientId: string }) => {
  const { confirmClientInvitation } = useStore().data

  const redirect = useRedirect()
  const { t } = useTranslation()

  const confirmInvitationResult = useQuery(() => confirmClientInvitation(clientId), {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: () => {
      redirect(routes.index, {})
      toast.success(t('user_management.invitation_confirmed'))
    },
    onError: (err) => {
      redirect(routes.index, {})
      toast.error(err.data.message)
    },
  })

  return confirmInvitationResult.isLoading ? <LoadingSpinner /> : null
})
