import { addMiddleware, Instance, types } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export enum PublicTransportAccessibility {
  accessible = 1,
  notAccessible = 2,
}

export const TransportAccessibilityModel = types
  .model('TransportAccessibility', {
    value: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(publicTransportAccessibility: PublicTransportAccessibility | null) {
      if (publicTransportAccessibility !== null) {
        checkValueIsInEnum(publicTransportAccessibility)
      }
      self.value = publicTransportAccessibility
      self.error = null
    },

    validate() {
      if (self.value === null) {
        self.error = t('job_draft_details.transport_accessibility_required')
        return false
      }
      checkValueIsInEnum(self.value)
      self.error = null
      return true
    },
  }))

function checkValueIsInEnum(value: number) {
  if (!Object.values(PublicTransportAccessibility).includes(value)) {
    throw new Error(`Unexpected id ${value} for transport accessibility`)
  }
}

export function createTransportMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.transport) {
      if (!targetInstance.fields.list.includes(DraftJobFields.transport)) {
        return abort(true)
      } else {
        return abort(targetInstance.transport.validate())
      }
    }
    return next(call)
  })
}

export type TransportAccessibilityInstance = Instance<typeof TransportAccessibilityModel>
