type FileService = {
  download: (file: BlobPart, name: string) => void
}

let fileService: FileService | undefined

export const setFileService = (service: FileService) => {
  fileService = service
}

export const useFileService = () => {
  if (!fileService) {
    throw new Error('File service has not been initialized!')
  }
  return {
    download: (file: BlobPart, name: string) => {
      fileService?.download(file, name)
    },
  }
}
