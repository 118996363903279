import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { toJS } from 'mobx'

import { DataStore } from './data-store/data-store'

const SkillModel = types.model('SkillModel', {
  id: types.identifierNumber,
  name: types.string,
  rating: types.number,
  work_category_name: types.string,
  icon: types.maybeNull(types.string),
  is_matched: types.boolean,
})

const CheeryPickingApplicantModel = types.model('CheeryPickingApplicant', {
  id: types.identifierNumber,
  name: types.string,
  image_url: types.maybeNull(types.string),
  rating: types.maybeNull(types.number),
  nationality: types.maybeNull(types.string),
  years_experience: types.number,
  seniority_level: types.model({
    name: types.string,
    id: types.number,
  }),
  past_jobs: types.array(
    types.model({
      id: types.number,
      designation_name: types.string,
      location_name: types.string,
      start_date: types.string,
      end_date: types.maybeNull(types.string),
    }),
  ),
  languages: types.array(types.string),
  age: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  completed_shifts_count: types.number,
  top_qualities: types.maybeNull(types.array(types.string)),
  is_favorite: 0 | 1,
  is_accepted: 0 | 1,
  is_chief: types.boolean,
  skills: types.array(SkillModel),
  skillMatch: types.number,
})

export const CherryPickingApplicantsModel = types.model('CherryPickingApplicants', {
  list: types.optional(types.map(CheeryPickingApplicantModel), {}),
  currentApplicant: types.maybeNull(CheeryPickingApplicantModel),
  paginator: types.maybeNull(
    types.model({
      current_page: types.number,
      next_page: types.maybeNull(types.number),
      per_page: types.number,
      last_page: types.number,
      total: types.number,
    }),
  ),
})

export const cherryPickingApplicantsActions = (self: DataStore) => ({
  getCherryPickingApplicantsApiCall: flow(function* ({
    jobId,
    page = 1,
    sortBy,
    sortDirection = 'DESC',
    shiftId,
  }: {
    jobId: number
    page?: number
    sortBy?: string
    sortDirection?: string
    shiftId?: number
  }) {
    const response = yield self.request('get', 'client/cherry-picking/applicants', undefined, {
      params: {
        job_id: jobId,
        shift_id: shiftId,
        sort_by: sortBy,
        sort_direction: sortDirection,
        page,
      },
    })
    if (page === 1) {
      self.cherryPickingApplicants.list.clear()
    }
    response.list.forEach((appplicant: CherryPickingApplicant) => {
      self.cherryPickingApplicants.list.set(String(appplicant.id), appplicant)
    })
    self.cherryPickingApplicants.paginator = response.paginator
  }),
  acceptApplicantApiCall: flow(function* ({
    job_id,
    sp_id,
    shift_ids,
  }: {
    job_id: number
    sp_id: string
    shift_ids?: number[]
  }) {
    const response = yield self.request('post', `client/cherry-picking/applicants/accept`, {
      job_id,
      sp_id,
      shift_ids,
    })
    const job = self.cherryPickingJobs.map.get(String(job_id))
    if (job) {
      job.shifts_fulfilled_count = response.shifts_fulfilled_count
      self.cherryPickingJobs.map.set(String(job.id), job)
    }
    const applicant = self.cherryPickingApplicants.list.get(sp_id)
    if (applicant) {
      applicant.is_accepted = 1
      self.cherryPickingApplicants.list.set(String(applicant.id), applicant)
    }
  }),
  rejectApplicantApiCall: flow(function* ({ job_id, sp_id }: { job_id: number; sp_id: string }) {
    yield self.request('post', `client/cherry-picking/applicants/reject`, {
      job_id,
      sp_id,
    })
    self.cherryPickingApplicants.list.delete(sp_id)
    if (self.cherryPickingApplicants.paginator) {
      self.cherryPickingApplicants.paginator.total = self.cherryPickingApplicants.paginator.total - 1
    }
  }),
  toggleApplicantAsFavouriteApiCall: flow(function* (sp_id: string) {
    const applicant = self.cherryPickingApplicants.list.get(sp_id)
    if (applicant) {
      if (applicant.is_favorite) {
        applicant.is_favorite = 0
        yield self.request('post', 'client/sp/un-save', { sp_id })
      } else {
        applicant.is_favorite = 1
        yield self.request('post', 'client/sp/save', { sp_id })
      }
      self.cherryPickingApplicants.list.set(String(applicant.id), applicant)
    }
  }),
  toggleCurrentApplicantAsFavouriteApiCall: flow(function* () {
    const applicant = self.cherryPickingApplicants.currentApplicant
    if (applicant) {
      if (applicant.is_favorite) {
        applicant.is_favorite = 0
        yield self.request('post', 'client/sp/un-save', { sp_id: applicant.id })
      } else {
        applicant.is_favorite = 1
        yield self.request('post', 'client/sp/save', { sp_id: applicant.id })
      }
    }
  }),
  getApplicant: flow(function* (jobId: number, spId: number) {
    const currentApplicant = (yield self.request(
      'get',
      `client/cherry-picking/jobs/${jobId}/applicants/${spId}`,
    )) as CherryPickingApplicant

    self.cherryPickingApplicants.currentApplicant = currentApplicant

    return currentApplicant
  }),
  setCurrentApplicant(applicantId: number) {
    const applicant = self.cherryPickingApplicants.list.get(String(applicantId))
    if (!applicant) {
      throw new Error(`Applicant with id ${applicantId} not found`)
    }
    self.cherryPickingApplicants.currentApplicant = toJS(applicant)
  },
})

export const cherryPickingApplicantsViews = (self: DataStore) => ({
  applicantsView() {
    return {
      list: Array.from(self.cherryPickingApplicants.list.values()),
      paginator: self.cherryPickingApplicants.paginator,
    }
  },

  getApplicantById(id: number) {
    return Array.from(self.cherryPickingApplicants.list.values()).find(item => item.id === id) as
      | CherryPickingApplicantSnapshot
      | undefined
  },

  get notAcceptedApplicantsList() {
    return Array.from(self.cherryPickingApplicants.list.values()).filter(applicant => applicant.is_accepted === 0)
  },

  get currentApplicant() {
    return self.cherryPickingApplicants.currentApplicant
  },

  get skillsGroupedByCategory() {
    const applicant = self.cherryPickingApplicants.currentApplicant
    if (!applicant) {
      return []
    }

    const groupedSkills = applicant.skills.reduce(
      (result: { [key: string]: { workCategoryName: string; skills: SkillSnapshot[] } }, skill: SkillSnapshot) => {
        const workCategoryName = skill.work_category_name
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!result[workCategoryName]) {
          result[workCategoryName] = {
            workCategoryName,
            skills: [],
          }
        }

        result[workCategoryName].skills.push(skill)

        return result
      },
      {},
    )

    return Object.values(groupedSkills)
  },
})

export type CherryPickingApplicant = typeof CheeryPickingApplicantModel.Type
export type CherryPickingApplicants = typeof CherryPickingApplicantsModel.Type
export type CherryPickingApplicantSnapshot = SnapshotOut<typeof CheeryPickingApplicantModel>
export type CherryPickingJobSnapshot = typeof CherryPickingApplicantsModel.SnapshotType
export type SkillSnapshot = SnapshotOut<typeof SkillModel>
