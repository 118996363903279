export var ApiErrorTypes;
(function (ApiErrorTypes) {
    ApiErrorTypes["AuthError"] = "AuthError";
    ApiErrorTypes["NotFound"] = "NotFound";
    ApiErrorTypes["ClientError"] = "ClientError";
    ApiErrorTypes["ServerError"] = "ServerError";
    ApiErrorTypes["ConnectionError"] = "ConnectionError";
    ApiErrorTypes["TooManyRequestsError"] = "TooManyRequestsError";
    ApiErrorTypes["PermissionError"] = "PermissionError";
})(ApiErrorTypes || (ApiErrorTypes = {}));
export class ApiError extends Error {
    constructor(type, data) {
        super(`ApiError: ${type}`);
        this.type = type;
        this.data = data;
    }
}
