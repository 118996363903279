import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import paymentIcon from '../assets/payment-icon.svg'
import {
  getPaymentStatusLabelForListItem,
  getPaymentItemStatusLabelStyles,
  getFormattedDate,
  paymentMethodsData,
} from '../../../utils'
import { PaymentExternalStatus, PaymentItemSnapshot, PaymentMethod, PaymentStatus } from '../../../../../models/payment'

export const PaymentItem = observer(
  ({
    paymentItem,
    onItemClick,
    onPayClick,
  }: {
    paymentItem: PaymentItemSnapshot
    onItemClick: (id: string) => void
    onPayClick: (id: string) => void
  }) => {
    const { t } = useTranslation()

    return (
      <div className="PaymentItem" key={paymentItem.id} onClick={() => onItemClick(String(paymentItem.id))}>
        <div className="PaymentItem__content">
          <div className="PaymentItem__content__left">
            <img src={paymentIcon} />
          </div>
          <div className="PaymentItem__content__right">
            <div className="PaymentItem__content__titles">
              <span className="PaymentItem__content__title">{paymentItem.payment_number}</span>
              <span className="PaymentItem__content__title">
                {paymentItem.currency_code} {paymentItem.amount}
              </span>
            </div>
            <div className="PaymentItem__content__details">
              <span className="PaymentItem__content__detailsItem">{getFormattedDate(paymentItem.due_date)}</span>
              <span className="PaymentItem__content__detailsItem">.</span>
              <span className="PaymentItem__content__detailsItem">
                {paymentItem.invoices_count} {t('payment.invoice')}
              </span>
              <span className="PaymentItem__content__detailsItem">.</span>
              <span className="PaymentItem__content__detailsItem" style={getPaymentItemStatusLabelStyles(paymentItem)}>
                {getPaymentStatusLabelForListItem(paymentItem, t)}
              </span>
            </div>
            {paymentItem.type_id !== null && (
              <div className="PaymentItem__content__payment-method-container">
                <span className="PaymentItem__content__payment-method">
                  {t(paymentMethodsData[String(paymentItem.type_id) as PaymentMethod].wideTitle)}
                </span>
              </div>
            )}
          </div>
        </div>
        {String(paymentItem.status) === PaymentStatus.Pending && (
          <div className="PaymentItem__actions">
            <button
              onClick={(event) => {
                event.stopPropagation()
                onPayClick(String(paymentItem.id))
              }}
              className="PaymentItem__actions__payButton"
            >
              {paymentItem.type_id === Number(PaymentMethod.Lean) &&
              paymentItem.external_status === PaymentExternalStatus.AWAITING_AUTHORIZATION
                ? t('payment.authorize')
                : t('payment.pay_now')}
            </button>
          </div>
        )}
      </div>
    )
  },
)
