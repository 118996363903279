import { Trans, useTranslation } from 'react-i18next'
import { Form } from 'formik'

import TextInput from '../../../../../components/text-input/text-input'
import { Separator } from '../../../../../components/app-layout'
import UploadDocument from '../../../../../components/upload-document/upload-document'
import { TERMS_AND_CONDITIONS_TYPE_PRIVACY_POLICY_CLIENT } from '../../../../../constants'
import { InputProps } from './types'
import { Select } from '../../../../../components/form/select/select'
import { FieldError } from '../../../../../components/form/field-error/field-error'
import {
  GR_CLIENT_INDUSTRY_BRC,
  GR_CLIENT_INDUSTRY_HOTELS,
  GR_CLIENT_INDUSTRY_RETAIL,
} from '../../../../../models/company-details'

const industryOptions = [
  {
    value: GR_CLIENT_INDUSTRY_RETAIL,
    key: GR_CLIENT_INDUSTRY_RETAIL,
    label: 'complete_registration.gr.industry_retail',
  },
  {
    value: GR_CLIENT_INDUSTRY_HOTELS,
    key: GR_CLIENT_INDUSTRY_HOTELS,
    label: 'complete_registration.gr.industry_hotels',
  },
  {
    value: GR_CLIENT_INDUSTRY_BRC,
    key: GR_CLIENT_INDUSTRY_BRC,
    label: 'complete_registration.gr.industry_brc',
  },
]

export const CorporateFormGr = ({
  formikProps,
  regionsData,
  maxFileSizeMB,
  showVATErrorMessage,
  onSelectVAT,
  errorMessages,
  toggleTermsAndConditions,
  termsAndConditionsAccepted,
  togglePrivacyPolicy,
  privacyPolicyAccepted,
  vat,
  apiHost,
}: InputProps) => {
  const { t } = useTranslation()

  return (
    <Form noValidate>
      <TextInput
        label={t('complete_registration.gr.company_name')}
        formikProps={formikProps}
        formikKey="company_name"
      />
      <Separator.Vertical height={10} />
      <FieldError errors={formikProps.errors.industry}>
        <Select
          label={t('complete_registration.gr.industry')}
          value={formikProps.values.industry}
          onChange={value => {
            formikProps.setFieldValue('industry', value)
          }}
          options={industryOptions.map(option => {
            return {
              ...option,
              label: t(option.label),
            }
          })}
        />
      </FieldError>
      <Separator.Vertical height={10} />
      <TextInput
        type="number"
        label={t('complete_registration.gr.registry_number')}
        formikProps={formikProps}
        formikKey="license_number"
      />
      <Separator.Vertical height={10} />
      <TextInput label={t('complete_registration.gr.address')} formikProps={formikProps} formikKey="address" />
      <Separator.Vertical height={10} />
      <TextInput label={t('complete_registration.gr.zip_code')} formikProps={formikProps} formikKey="po_box" />
      <Separator.Vertical height={10} />
      <TextInput
        label={t('complete_registration.gr.tax_jurisdiction')}
        formikProps={formikProps}
        formikKey="free_zone"
      />
      <Separator.Vertical height={10} />
      <TextInput label={t('complete_registration.region')} formikProps={formikProps} formikKey="region" />
      <Separator.Vertical height={40} />
      <h4 className="CompleteRegisteration__uploadInputLable">{t('complete_registration.vat_certificate')}</h4>
      <Separator.Vertical height={15} />
      <UploadDocument
        maxFileSize={maxFileSizeMB}
        showErrorRequiredMessage={showVATErrorMessage}
        name="vat"
        hasFile={Boolean(vat)}
        onSelectFile={(file: File | null) => {
          onSelectVAT(file)
          formikProps.setFieldValue('vat', file)
        }}
        placeholder={t('complete_registration.upload_vat')}
      />
      {errorMessages.map((error, i) => (
        <p key={i} className="CompleteRegisteration__errorMessage">
          {error}
        </p>
      ))}
      <Separator.Vertical height={15} />
      <div className="CompleteRegisteration__termsConditions">
        <input
          type="checkbox"
          onClick={togglePrivacyPolicy}
          checked={privacyPolicyAccepted}
          className="CompleteRegisteration__termsConditions__checkbox"
        />
        <Separator.Horizontal width={10} />
        <div className="CompleteRegisteration__termsConditions__container">
          <Trans i18nKey="complete_registration.i_agree_with_company_pp">
            <span className="CompleteRegisteration__termsConditions__container__label">I agree to the</span>
            <a
              href={`${apiHost}client/profile/sla?type=html&tc_type=${TERMS_AND_CONDITIONS_TYPE_PRIVACY_POLICY_CLIENT}`}
              target="_blank"
              rel="noreferrer"
              className="CompleteRegisteration__termsConditions__container__link"
            >
              Privacy Policy for Companies
            </a>
          </Trans>
        </div>
      </div>
      <Separator.Vertical height={20} />
      <div className="CompleteRegisteration__termsConditions">
        <input
          type="checkbox"
          onClick={toggleTermsAndConditions}
          checked={termsAndConditionsAccepted}
          disabled={termsAndConditionsAccepted}
          className="CompleteRegisteration__termsConditions__checkbox"
        />
        <Separator.Horizontal width={10} />
        <div className="CompleteRegisteration__termsConditions__container">
          <Trans i18nKey="complete_registration.i_agree_with_company_terms">
            <span className="CompleteRegisteration__termsConditions__container__label">I agree to the</span>
            <a
              href={`${apiHost}client/profile/sla?type=html`}
              target="_blank"
              rel="noreferrer"
              className="CompleteRegisteration__termsConditions__container__link"
            >
              Terms and Conditions
            </a>
          </Trans>
        </div>
      </div>
      <Separator.Vertical height={5} />
      <button
        disabled={!privacyPolicyAccepted || !termsAndConditionsAccepted}
        className="CompleteRegisteration__submitButton"
        type="submit"
      >
        {t('complete_registration.continue')}
      </button>
    </Form>
  )
}
