import { useState, useMemo } from 'react'
import moment from 'moment'
import { observer } from 'mobx-react'
import { Form, Formik, FormikErrors } from 'formik'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'

import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { RatingRequestDetails, RatingForm } from '../../../models/rating-request'
import { useStore, useQuery, useMutation } from '../../../utils/mst-hooks'
import { Separator } from '../../../components/app-layout/separator/separator'
import { FieldError } from '../../../components/form/field-error/field-error'
import { Button } from '../../../components/button/button'
import Rating from '../../../components/rating/rating'
import locationImg from '../../../components/assets/location-icon-grey.svg'
import calendarImg from '../../../components/assets/calender-icon-grey.svg'
import emptyStar from './assets/empty-star.svg'
import { RateCard, RateFields } from './components/rate-card/rate-card'
import { jobRatingFormValidation } from './validation'
import './job-rating.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { TrackedEvent, trackingService } from '../../../services/tracking-service'

const getFormattedDate = (dateString: string) => {
  return moment(dateString, 'YYYY-MM-DD').format('DD MMM')
}

type OgrammerRatesEror = {
  rating?: string
  criterias?: string
  comment?: string
}

export const JobRatingPage = withErrorBoundary(
  observer(({ id }: { id: number }) => {
    const [jobRating, setJobRating] = useState<RatingRequestDetails | null>(null)
    const [errors, setErrors] = useState<string[]>()
    const initialValues: RatingForm | null = useMemo(() => {
      if (!jobRating) return null
      const formValues: RatingForm = {
        job_id: id,
        job_rating: 0,
        ogrammerRates: [],
      }
      jobRating.sps.forEach(ogrammer => {
        formValues.ogrammerRates.push({
          sp_id: ogrammer.id,
          rating: 0,
          criterias: new Set(),
          comment: null,
        })
      })
      return formValues
    }, [jobRating, id])

    const { getRatingRequestDetails, getRatingCriteries, submitRating } = useStore().data
    const { isLoading: criteriesLoading } = useQuery(getRatingCriteries)
    const { isLoading: ratingDetailsLoading } = useQuery(() => getRatingRequestDetails(id), { onSuccess: setJobRating })
    const [submitRatingResult, submit] = useMutation(submitRating, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: () => {
        toast.success(t('job_rating.thank_you_for_submitting'))
        window.history.back()
      },
      onError: err => {
        if (err.data.errors) {
          setErrors([...Object.values(err.data.errors).flat()])
        }
      },
    })

    const { t } = useTranslation()

    return (
      <>
        {ratingDetailsLoading || criteriesLoading || initialValues === null ? (
          <LoadingSpinner />
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={jobRatingFormValidation}
            onSubmit={values => {
              if (submitRatingResult.isLoading) return
              submit({
                ...values,
                ogrammerRates: [
                  ...values.ogrammerRates.map(ogRate => {
                    const criterias = new Set(ogRate.criterias)
                    criterias.delete(-1)

                    trackingService.track(TrackedEvent.RatingSet, {
                      source: 'RatingPage',
                      isChange: false,
                    })

                    return { ...ogRate, criterias }
                  }),
                ],
              })
            }}
          >
            {formikProps => {
              const formDisabled = formikProps.values.job_rating === 0
              return (
                <Form className="JobRating">
                  <Separator.Vertical height={33} />
                  <FieldError errors={errors} className="JobRating__container">
                    <span className="JobRating__container__title">
                      {jobRating?.title}{' '}
                      <span className="JobRating__container__title__amount">({jobRating?.sp_max_quantity})</span>
                    </span>
                    <Separator.Vertical height={12} />
                    <div className="JobRating__container__row">
                      <img src={locationImg} alt="" />
                      <Separator.Horizontal width={11} />
                      <span className="JobRating__container__row__label">{jobRating?.client_address}</span>
                    </div>
                    <Separator.Vertical height={12} />
                    <div className="JobRating__container__row">
                      <img src={calendarImg} alt="" />
                      <Separator.Horizontal width={9} />
                      <span className="RatingCard__row__label">
                        {jobRating?.start_date === jobRating?.end_date
                          ? getFormattedDate(jobRating?.start_date as string)
                          : `${getFormattedDate(jobRating?.start_date as string)} - ${getFormattedDate(
                              jobRating?.end_date as string,
                            )}`}
                      </span>
                    </div>
                    <Separator.Vertical height={30} />
                    <span className="JobRating__container__subtitle">
                      {t('job_rating.how_was_experience_question')}
                    </span>
                    <Separator.Vertical height={6} />
                    <span className="JobRating__container__description">{t('job_rating.please_rate_team')}</span>
                    <Separator.Vertical height={18} />
                    <FieldError
                      errors={formikProps.errors.job_rating}
                      className="JobRating__container__overallRatingError"
                    >
                      <Rating
                        rating={formikProps.values.job_rating}
                        editable
                        onRatingChange={value => {
                          if (!formikProps.values.job_rating) {
                            formikProps.setFieldValue(
                              'ogrammerRates',
                              [
                                ...formikProps.values.ogrammerRates.map(prevRate => ({
                                  ...prevRate,
                                  rating: prevRate.rating ? prevRate.rating : value,
                                })),
                              ],
                              false,
                            )
                          }
                          formikProps.setFieldValue('job_rating', value, false)
                        }}
                        size={40}
                        emptyIcon={emptyStar}
                      />
                    </FieldError>
                    <Separator.Vertical height={38} />
                    <span className="JobRating__container__subtitle">{t('job_rating.tell_us_about_ogs')}</span>
                    <Separator.Vertical height={6} />
                    <span className="JobRating__container__description">{t('job_rating.tell_us_which_stood_out')}</span>
                    <Separator.Vertical height={8} />
                    {jobRating?.sps.map((ogrammer, index) => {
                      const handleFieldsChange = (fields: RateFields) => {
                        formikProps.setFormikState(prevState => {
                          return {
                            ...prevState,
                            values: {
                              ...prevState.values,
                              ogrammerRates: [
                                ...formikProps.values.ogrammerRates.map((ogrammerRate, ogrammerRateIndex) => {
                                  if (ogrammerRateIndex !== index) return ogrammerRate
                                  return {
                                    ...ogrammerRate,
                                    rating: fields.rating ?? ogrammerRate.rating,
                                    comment: fields.comment ?? ogrammerRate.comment,
                                    criterias: fields.criterias ?? ogrammerRate.criterias,
                                  }
                                }),
                              ],
                            },
                          }
                        })
                      }
                      const ogrammerErrors = formikProps.errors.ogrammerRates?.[index] as
                        | FormikErrors<OgrammerRatesEror>
                        | undefined
                      return (
                        <RateCard
                          key={ogrammer.id}
                          name={ogrammer.full_name}
                          imageURL={ogrammer.image_url}
                          initialRating={formikProps.values.job_rating}
                          rating={formikProps.values.ogrammerRates[index].rating}
                          comment={formikProps.values.ogrammerRates[index].comment ?? ''}
                          criterias={formikProps.values.ogrammerRates[index].criterias}
                          ratingError={ogrammerErrors?.rating}
                          criteriasError={ogrammerErrors?.criterias}
                          commentError={ogrammerErrors?.comment}
                          setFields={handleFieldsChange}
                        />
                      )
                    })}
                  </FieldError>
                  <Separator.Vertical height={29} />
                  <Button
                    title={t('job_rating.confirm')}
                    type="submit"
                    disabled={formDisabled}
                    className={`JobRating__confirmButton ${formDisabled ? 'disabled' : ''}`}
                  />
                  <Separator.Vertical height={29} />
                </Form>
              )
            }}
          </Formik>
        )}
      </>
    )
  }),
)
