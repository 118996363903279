import { useEffect } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

import { useDelayedQuery, useStore } from '../../../../../../../utils/mst-hooks'
import LoadingSpinner from '../../../../../../../components/loading-spinner/loading-spinner'
import { routes } from '../../../../../../../routes'
import { useRedirect } from '../../../../../../../services/router'
import { confirmSavedCardPayment } from '../../../../../../../services/stripe'
import { withErrorBoundary } from '../../../../../../../components/error/with-error-boundary'

export const PayWithSavedCardForm = withErrorBoundary(
  observer(
    ({
      paymentId,
      cardId,
      clientSecret,
      paymentOptions,
    }: {
      paymentId: number
      cardId: string
      clientSecret: string
      paymentOptions?: string
    }) => {
      const redirect = useRedirect()
      const stripe = useStripe()

      const { processPayment, resetPayment } = useStore().data
      const { enableQuery: resetPaymentQuery } = useDelayedQuery(() => resetPayment(paymentId), {
        onSuccess: (result) => {
          redirect(routes.paymentMethodsList, { paymentId: String(paymentId) })
        },
      })
      const { enableQuery: processPaymentQuery } = useDelayedQuery(() => processPayment(paymentId), {
        onSuccess: (result) => {
          confirmPayment()
        },
      })

      const confirmPayment = async () => {
        await confirmSavedCardPayment(
          stripe,
          clientSecret,
          cardId,
          paymentId,
          (errorMessage: string) => {
            resetPaymentQuery()
            toast.error(errorMessage)
          },
          () =>
            redirect(routes.cardPaymentStatus, {
              paymentId: String(paymentId),
              ...(paymentOptions ? { paymentOptions: paymentOptions } : {}),
            }),
          paymentOptions,
        )
      }

      useEffect(() => {
        if (Boolean(stripe)) {
          processPaymentQuery()
        }
      }, [stripe])

      return <LoadingSpinner />
    },
  ),
)
