import { flow, SnapshotOut, types } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'
import { TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT } from '../constants'

export enum ReferralStatus {
  ProfileCreated = 1,
  Activated = 2,
  BookedShifts = 3,
  ShiftsDone = 4,
}

export const AffiliateStatsModel = types.model('AffiliateStats', {
  currentMonthIncome: types.number,
  currency: types.string,
  referralsCount: types.number,
  invitationLink: types.string,
  marginSplit: types.maybeNull(types.number),
})

const AffiliateReferralModel = types.model('AffiliateReferral', {
  id: types.number,
  image: types.maybeNull(types.string),
  fullName: types.maybeNull(types.string),
  shiftsCompletedCount: types.number,
  shiftsTotalCount: types.number,
  designations: types.array(types.string),
  currency: types.string,
  payoutAmount: types.maybeNull(types.number),
  payoutDate: types.maybeNull(types.string),
  joinDate: types.string,
  status: types.number,
})

export const AffiliateReferralsModel = types.model('AffiliateReferralsModel', {
  list: types.array(AffiliateReferralModel),
  currentPage: types.number,
  totalPages: types.number,
})

export const AffiliateStatusModel = types.model('AffiliateStatus', {
  status: types.number,
  referralsCount: types.number,
})

export const AffiliateEarningsModel = types.model('AffiliateEarnings', {
  currency: types.string,
  totalAmount: types.number,
  periods: types.array(
    types.model({
      title: types.string,
      amount: types.number,
    }),
  ),
})

export const affiliateActions = (self: DataStore) => ({
  markClientAsAffiliate: flow(function* () {
    yield self.request('post', 'client/affiliate')
  }),
  getAffiliateStats: flow(function* () {
    self.affiliateStats = yield self.request('get', 'client/affiliate/stats')
  }),
  getAffiliateReferrals: flow(function* (page = 1) {
    self.affiliateReferrals.list.clear()

    const { list, paginator } = (yield self.request('get', `client/affiliate/referrals?page=${page}`)) as {
      list: AffiliateReferralSnapshot[]
      paginator?: Paginator
    }

    list.forEach((item) => {
      self.affiliateReferrals.list.push(item)
    })

    self.affiliateReferrals.totalPages = Number(paginator?.last_page)
    self.affiliateReferrals.currentPage = page
  }),
  getAffiliateStatuses: flow(function* () {
    self.affiliateReferralStatuses = yield self.request('get', 'client/affiliate/statuses')
  }),
  getAffiliateEarnings: flow(function* () {
    self.affiliateEarnings = yield self.request('get', 'client/affiliate/earnings')
  }),
  confirmAffiliateTermsAndConditions: flow(function* () {
    yield self.request(
      'post',
      `client/terms-and-conditions/accept?tc_type=${TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT}`,
    )
  }),
})

export const affiliateViews = (self: DataStore) => ({
  get affiliateReferralsList() {
    return self.affiliateReferrals.list
  },
  get affiliateReferralsPagesCount() {
    return self.affiliateReferrals.totalPages
  },
})

export type AffiliateStatsSnapshot = SnapshotOut<typeof AffiliateStatsModel>
export type AffiliateReferralSnapshot = SnapshotOut<typeof AffiliateReferralModel>
