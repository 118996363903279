import { types } from 'mobx-state-tree'

import { withRequest } from '../extenstions/request'
import { AttendanceManagerModel, attendanceManagerActions, attendanceManangerViews } from '../attendance-manager'
import { CompanyDetailsModel, companyDetailsActions } from '../company-details'
import { JobModel, jobActions, jobViews } from '../job'
import { BusinessLocationModel, locationActions, locationViews } from '../location'
import { ProfessionModel, professionActions, professionViews } from '../profession'
import { ProfileModel, profileActions } from '../profile'
import { authActions } from '../auth'
import { UniformModel, uniformActions, uniformViews } from '../uniform'
import { FilterDictionaryModel, filterDictionaryActions, filterDictionaryViews } from '../filter-dictionary'
import { RatingRequestModel, ratingActions, ratingViews } from '../rating-request'
import { InvoiceStoreModel, invoiceActions, invoiceViews } from '../invoice'
import { OrderStoreModel, orderStoreActions } from '../order/order-store'
import { basicOrderViews } from '../order/basic-order'
import { orderJobDetailsViews } from '../order/order-job-details'
import { orderSummaryViews } from '../order/order-summary'
import { listItemOrdersViews } from '../order/list-item-orders'
import { SPStoreModel, spActions, spViews } from '../sp'
import { InvitationModel, invitationActions, invitationViews } from '../invitation'
import { RegionModel, regionActions, regionViews } from '../region'
import { LanguageModel, languageActions, languageViews } from '../languages'
import { PaymentStoreModel, paymentActions, paymentViews } from '../payment'
import { NotificationListModel, notificationActions, notificationViews } from '../notification'
import { ShiftCancelReasonModel, shiftCancelReasonActions, shiftCancelReasonViews } from '../shift-cancel-reason'
import { ContactFiltersModel, contactFiltersActions } from '../contact-filters'
import { AccountModel, accountActions } from '../account'
import { ChatStoreModel, chatActions, chatViews } from '../chat'
import { leadFormActions } from '../lead-form'
import {
  attendanceReportJobActions,
  AttendanceReportJobModel,
  attendanceReportJobViews,
} from '../attendance-report-job'
import {
  attendanceReportShiftActions,
  AttendanceReportShiftModel,
  attendanceReportShiftViews,
} from '../attendance-report-shift'
import { CherryPickingJobList, cherryPickingJobsActions, cherryPickingJobsViews } from '../cherry-picking-jobs'
import {
  cherryPickingApplicantsActions,
  CherryPickingApplicantsModel,
  cherryPickingApplicantsViews,
} from '../cherry-picking-applicants'
import { StatisticsModel, statisticsActions } from '../statistics'
import { PointsOfContactsModel, pointsOfContactActions, pointsOfContactViews } from '../points-of-contact'
import { ApplicantExperienceModel, applicantExperienceActions, applicantExperienceViews } from '../applicant-experience'
import { RatingCriteriaModel, ratingCriteriaActions, ratingCriteriaViews } from '../criteria'
import { WorkRequirementModel, workRequirementsActions, workRequirementsViews } from '../work-requirement'
import { NationalityModel, nationalityActions, nationalityViews } from '../nationality'
import { JobDocumentsModel, jobDocumentsActions, jobDocumentsViews } from '../job-documents'
import { AmendModel, amendViews, amendActions } from '../amend-reasons'
import { SeniorityLevelModel, seniorityLevelsActions, seniorityLevelsViews } from '../seniority-level'
import { signUpWithOrderActions, SignUpWithOrderDataModel } from '../sign-up-with-order-data'
import { marketActions, marketViews, MarketModel } from '../market'
import { unconfirmedPointsOfContactActions, UnconfirmedPointsOfContactsModel } from '../unconfirmed-points-of-contact'
import { duplicatedOrderActions, DuplicatedOrderModel, duplicatedOrderViews } from '../duplicated-order'
import { accountStatementActions, AccountStatementModel, accountStatementViews } from '../account-statement'
import { UnseenUpdateModel, unseenUpdatesActions, unseenUpdatesViews } from '../unseen-updates'
import { ScheduleModel, scheduleActions, scheduleViews } from '../schedule'
import { commonViews } from '../common'
import { configActions, ConfigModel, configViews } from '../config'
import {
  affiliateActions,
  AffiliateEarningsModel,
  AffiliateReferralsModel,
  AffiliateStatsModel,
  AffiliateStatusModel,
  affiliateViews,
} from '../affiliate'
import { defaultInterviewStore, interviewActions, interviewViews } from '../interview'
import { qrAttendanceActions, QrAttendanceModel } from '../qr-attendance'
import { pastWorkersActions, PastWorkersModel, pastWorkersViews } from '../past-workers'
import { industryActions, IndustryModel, industryViews } from '../industry'
import { SPsToRateModel, SPsToRateActions, SPsToRateViews } from '../sp-to-rate'

export const BaseDataStoreModel = types
  .model('DataStore', {
    logged: types.optional(types.boolean, false),
    account: types.maybeNull(AccountModel),
    profile: types.maybeNull(ProfileModel),
    companyDetails: types.maybeNull(CompanyDetailsModel),
    attendanceManagers: types.map(AttendanceManagerModel),
    locations: types.map(BusinessLocationModel),
    ratingRequests: types.map(RatingRequestModel),
    ratingCriteries: types.map(RatingCriteriaModel),
    uniforms: types.map(UniformModel),
    industries: types.map(IndustryModel),
    languages: types.map(LanguageModel),
    nationalities: types.map(NationalityModel),
    professions: types.map(ProfessionModel),
    jobs: types.map(JobModel),
    invoices: types.optional(InvoiceStoreModel, {}),
    order: types.optional(OrderStoreModel, {}),
    sp: types.optional(SPStoreModel, {}),
    filterDictionary: types.maybeNull(FilterDictionaryModel),
    invitations: types.map(InvitationModel),
    regions: types.map(RegionModel),
    payments: types.optional(PaymentStoreModel, {
      paymentMethods: {},
    }),
    notification: types.optional(NotificationListModel, {}),
    shiftCancelReasons: types.map(ShiftCancelReasonModel),
    attendanceReportJob: types.map(AttendanceReportJobModel),
    attendanceReportShift: types.maybeNull(AttendanceReportShiftModel),
    contactFilters: types.maybeNull(ContactFiltersModel),
    cherryPickingJobs: types.optional(CherryPickingJobList, { map: {}, nextPage: null }),
    cherryPickingApplicants: types.optional(CherryPickingApplicantsModel, {}),
    chat: types.optional(ChatStoreModel, {}),
    statistics: types.maybeNull(StatisticsModel),
    pointsOfContact: types.maybeNull(types.array(PointsOfContactsModel)),
    applicantsExperience: types.map(ApplicantExperienceModel),
    workCategoryRequirements: types.map(types.array(WorkRequirementModel)),
    workCategorySeniorityLevels: types.map(types.array(SeniorityLevelModel)),
    job_documents: types.map(JobDocumentsModel),
    amendReasons: types.map(AmendModel),
    signUpWithOrderData: types.optional(SignUpWithOrderDataModel, {}),
    marketsStore: types.map(MarketModel),
    unconfirmedPointsOfContact: types.maybeNull(types.array(UnconfirmedPointsOfContactsModel)),
    duplicatedOrder: types.optional(DuplicatedOrderModel, {}),
    accountStatement: types.optional(AccountStatementModel, {}),
    unseenUpdates: types.map(UnseenUpdateModel),
    schedule: types.map(types.array(ScheduleModel)),
    config: types.maybeNull(ConfigModel),
    affiliateStats: types.maybeNull(AffiliateStatsModel),
    affiliateReferrals: types.optional(AffiliateReferralsModel, { list: [], currentPage: 1, totalPages: 1 }),
    affiliateReferralStatuses: types.array(AffiliateStatusModel),
    affiliateEarnings: types.maybeNull(AffiliateEarningsModel),
    interviewStore: defaultInterviewStore,
    qrAttendance: types.maybeNull(QrAttendanceModel),
    pastWorkers: types.optional(PastWorkersModel, { list: {}, currentPage: 1, totalPages: 1, total: 0 }),
    spsToRate: types.optional(SPsToRateModel, { list: {}, currentPage: 1, totalPages: 1, total: 0 }),
  })
  .extend(withRequest)

export const DataStoreModel = BaseDataStoreModel.actions(profileActions)
  .actions(accountActions)
  .actions(companyDetailsActions)
  .actions(attendanceManagerActions)
  .actions(ratingActions)
  .actions(uniformActions)
  .actions(jobActions)
  .actions(authActions)
  .actions(locationActions)
  .actions(orderStoreActions)
  .actions(professionActions)
  .actions(invoiceActions)
  .actions(spActions)
  .actions(filterDictionaryActions)
  .actions(invitationActions)
  .actions(regionActions)
  .actions(paymentActions)
  .actions(notificationActions)
  .actions(shiftCancelReasonActions)
  .actions(contactFiltersActions)
  .actions(chatActions)
  .actions(attendanceReportJobActions)
  .actions(attendanceReportShiftActions)
  .actions(cherryPickingJobsActions)
  .actions(cherryPickingApplicantsActions)
  .actions(statisticsActions)
  .actions(pointsOfContactActions)
  .actions(leadFormActions)
  .actions(applicantExperienceActions)
  .actions(ratingCriteriaActions)
  .actions(languageActions)
  .actions(workRequirementsActions)
  .actions(seniorityLevelsActions)
  .actions(nationalityActions)
  .actions(jobDocumentsActions)
  .actions(amendActions)
  .actions(signUpWithOrderActions)
  .actions(marketActions)
  .actions(unconfirmedPointsOfContactActions)
  .actions(duplicatedOrderActions)
  .actions(accountStatementActions)
  .actions(unseenUpdatesActions)
  .actions(configActions)
  .actions(scheduleActions)
  .actions(affiliateActions)
  .actions(interviewActions)
  .actions(qrAttendanceActions)
  .actions(pastWorkersActions)
  .actions(industryActions)
  .actions(SPsToRateActions)
  .views(ratingViews)
  .views(locationViews)
  .views(basicOrderViews)
  .views(orderJobDetailsViews)
  .views(orderSummaryViews)
  .views(professionViews)
  .views(invoiceViews)
  .views(uniformViews)
  .views(jobViews)
  .views(filterDictionaryViews)
  .views(spViews)
  .views(regionViews)
  .views(attendanceManangerViews)
  .views(paymentViews)
  .views(notificationViews)
  .views(shiftCancelReasonViews)
  .views(invitationViews)
  .views(chatViews)
  .views(attendanceReportJobViews)
  .views(attendanceReportShiftViews)
  .views(cherryPickingJobsViews)
  .views(cherryPickingApplicantsViews)
  .views(applicantExperienceViews)
  .views(pointsOfContactViews)
  .views(ratingCriteriaViews)
  .views(languageViews)
  .views(workRequirementsViews)
  .views(seniorityLevelsViews)
  .views(nationalityViews)
  .views(jobDocumentsViews)
  .views(amendViews)
  .views(listItemOrdersViews)
  .views(marketViews)
  .views(duplicatedOrderViews)
  .views(accountStatementViews)
  .views(unseenUpdatesViews)
  .views(commonViews)
  .views(configViews)
  .views(scheduleViews)
  .views(affiliateViews)
  .views(interviewViews)
  .views(pastWorkersViews)
  .views(industryViews)
  .views(SPsToRateViews)

export type DataStore = typeof BaseDataStoreModel.Type
export type DataStoreSnapshot = typeof BaseDataStoreModel.SnapshotType
