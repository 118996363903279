import { never, nonVoid } from 'assertio';
import axios from 'axios';
import { isObject } from '../utils/common';
import { Api } from './api';
import { refreshExpiredToken, checkTokenRefreshing } from './api-interceptors';
/**
 * Manages all requests to the API.
 */
export class ApiRest extends Api {
    constructor(config) {
        var _a, _b;
        super();
        const customHeaders = config.lang ? { 'x-lang-code': config.lang } : undefined;
        const axiosConfig = {
            baseURL: config.url,
            timeout: config.timeout,
            validateStatus: (status) => status >= 200 && status < 600,
            headers: {
                'content-type': 'application/json',
                ...customHeaders,
            },
        };
        if (config.withCredentials) {
            axiosConfig.withCredentials = true;
        }
        this.api = axios.create(axiosConfig);
        this.config = config;
        // Add interceptors
        this.addDefaultInterceptors();
        (_a = config.requestInterceptors) === null || _a === void 0 ? void 0 : _a.forEach((interceptor) => {
            this.api.interceptors.request.use(interceptor.bind(this));
        });
        (_b = config.responseInterceptors) === null || _b === void 0 ? void 0 : _b.forEach((interceptor) => {
            this.api.interceptors.response.use(interceptor.bind(this));
        });
    }
    addDefaultInterceptors() {
        this.api.interceptors.request.use(checkTokenRefreshing.bind(this));
        this.api.interceptors.response.use(refreshExpiredToken.bind(this));
    }
    async normalizeResponse(method, url, response) {
        var _a;
        const res = await response;
        const d = res.data;
        const status = d.status === 'fail' && res.status < 400 ? 400 : res.status;
        const data = d instanceof Blob ? d : d.status === 'fail' ? d : (_a = d.data) !== null && _a !== void 0 ? _a : null;
        const normResponse = { status: status, data: data, headers: res.headers };
        return normResponse;
    }
    normalizeErrors(errors) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        if (isObject(errors)) {
            if (Array.isArray(errors)) {
                return {
                    code: 'common',
                    // eslint-disable-next-line
                    message: errors[0].message,
                };
            }
            else {
                return errors;
            }
        }
        throw never('Unexpected error type');
    }
    /**
     * Send an API call
     */
    async request(axiosConfig) {
        var _a, _b;
        const authToken = await this.getAuthKey();
        const { authorization, ...headers } = (_a = axiosConfig.headers) !== null && _a !== void 0 ? _a : {};
        if (authToken) {
            headers.authorization = authToken;
        }
        return await this.normalizeResponse((_b = axiosConfig.method) !== null && _b !== void 0 ? _b : 'get', nonVoid(axiosConfig.url), this.api.request({ ...axiosConfig, headers }));
    }
}
