import { nonNull } from 'assertio'
import ReactDOM from 'react-dom'
import './modal.scss'

export const Modal = (props: { children: React.ReactNode; onClose: () => void }) => {
  return ReactDOM.createPortal(
    <div className="Modal">
      <div className="Modal__close" onClick={props.onClose}>
        X
      </div>
      {props.children}
    </div>,
    nonNull(document.getElementById('modals')),
  )
}
