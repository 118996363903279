import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/attendance-review'
import '../onboarding.scss'

export const attendanceReviewOnboarding: OnboardingContent = {
  title: t('attendance_review_onboarding.title'),
  paths: [routes.attendanceShiftsList.path],
  pages: [
    {
      title: t('attendance_review_onboarding.jobs_pending_review_title'),
      content: [
        { image: { src: images.jobsPendingReviewImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.jobs_pending_review') },
      ],
    },
    {
      title: t('attendance_review_onboarding.attendance_details_title'),
      content: [
        { image: { src: images.attendanceDetailsImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.attendance_details') },
      ],
    },
    {
      title: t('attendance_review_onboarding.attendance_status_title'),
      content: [
        { image: { src: images.attendanceStatusChangeImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.attendance_status') },
      ],
    },
    {
      title: t('attendance_review_onboarding.attendance_time_title'),
      content: [
        { image: { src: images.attendanceTimeChangeImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.attendance_time') },
      ],
    },
    {
      title: t('attendance_review_onboarding.not_attended_reason_title'),
      content: [
        { image: { src: images.notAttendedReasonChangeImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.not_attended_reason') },
      ],
    },
    {
      title: t('attendance_review_onboarding.confirmation_time_title'),
      content: [
        { image: { src: images.confirmationTimeImg, className: 'Onboarding__bigImg' } },
        { description: t('attendance_review_onboarding.confirmation_time') },
      ],
    },
  ],
}
