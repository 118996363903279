import { flow, types, SnapshotOut } from 'mobx-state-tree'
import moment from 'moment'

import { DataStore } from './data-store/data-store'

export enum InvoiceStatus {
  Unpaid = 2,
  Paid = 3,
}

export enum StatementItemType {
  Invoice = 'invoice',
}

const StatementItem = types.model('StatementItem', {
  type: types.string,
  id: types.identifierNumber,
  status: types.number,
  description: types.string,
  amount: types.number,
  currency_code: types.string,
  date: types.string,
  due_date: types.string,
})

export const AccountStatementModel = types.model('AccountStatement', {
  data: types.optional(
    types.model({
      start_balance: types.maybeNull(types.number),
      end_balance: types.maybeNull(types.number),
      items: types.map(StatementItem),
    }),
    {},
  ),
  filters: types.optional(
    types.model({
      date_from: types.maybeNull(types.string),
      date_to: types.maybeNull(types.string),
    }),
    {
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
    },
  ),
})

export const accountStatementActions = (self: DataStore) => ({
  loadAccountStatement: flow(function* () {
    const result = (yield self.request(
      'get',
      `client/account-statement?date_from=${self.accountStatement.filters.date_from}&date_to=${self.accountStatement.filters.date_to}`,
    )) as {
      start_balance: number | null
      end_balance: number | null
      items: StatementItemSnapshot[]
    }

    self.accountStatement.data.start_balance = result.start_balance
    self.accountStatement.data.end_balance = result.end_balance

    self.accountStatement.data.items.clear()

    result.items.forEach((item) => {
      self.accountStatement.data.items.set(String(item.id), item)
    })
  }),

  downloadAccountStatementFile: flow(function* () {
    return (yield self.request(
      'get',
      `client/account-statement/download?date_from=${self.accountStatement.filters.date_from}&date_to=${self.accountStatement.filters.date_to}`,
      undefined,
      {
        responseType: 'blob',
      },
    )) as BlobPart
  }),

  setDateFrom: function (dateFrom: string) {
    self.accountStatement.filters.date_from = dateFrom
  },

  setDateTo: function (dateTo: string) {
    self.accountStatement.filters.date_to = dateTo
  },
})

export const accountStatementViews = (self: DataStore) => ({
  get statementItems() {
    return Array.from(self.accountStatement.data.items.values()).filter((item) => item.type !== 'payment')
  },

  get startBalance() {
    return self.accountStatement.data.start_balance
  },

  get endBalance() {
    return self.accountStatement.data.end_balance
  },

  get dateFrom() {
    return self.accountStatement.filters.date_from
  },

  get dateTo() {
    return self.accountStatement.filters.date_to
  },
})

export type StatementItemSnapshot = SnapshotOut<typeof StatementItem>
