import shiftsAttendanceImg from './shifts_attendance_review.svg'
import applicantsReviewImg from './jobs_applicant_review.svg'
import ratingRequestsImg from './rating_requests.svg'
import ordersToConfirmImg from './orders_to_confirm.svg'
import pendingPaymentsImg from './pending_payments.svg'
import referralsImg from './referrals.svg'
import videoInterviewImg from './video_interview.png'

export const images = {
  shiftsAttendanceImg,
  applicantsReviewImg,
  ratingRequestsImg,
  ordersToConfirmImg,
  pendingPaymentsImg,
  referralsImg,
  videoInterviewImg,
}
