import './tabs.scss'

export type TabProps = {
  title: string
  onClick: (params?: unknown) => void
  isActive: boolean
  showUpdatesIndicator?: boolean
}

export type TabsProps = {
  tabs: TabProps[]
}

export const Tabs = ({ tabs }: TabsProps) => {
  return (
    <div className="tabs__container">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={`tabs__container__button${tab.isActive ? ' tabs__container__button--active' : ''}`}
          onClick={tab.onClick}
        >
          {tab.title}
          {tab.showUpdatesIndicator && <div className="tabs__container__updates-indicator"></div>}
        </button>
      ))}
    </div>
  )
}
