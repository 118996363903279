import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { SnapshotOut, types } from 'mobx-state-tree'

import { DateBoundariesModel } from './date-boundaries'
import { ScheduleTypeModel } from './schedule-type'
import { SameTimeScheduleModel } from './same-time-schedule'
import { DistinctTimeScheduleModel } from './distinct-time-schedule'
import { DraftJobFields } from '../fields'
import { ScheduleType } from './types'
import { formatDateToIso8601, formatYyyyMmDdToDdd, getRangeOfDates } from '../../../../../utils/datetime-utils'

// @ts-ignore
const moment = extendMoment(Moment)

export const sameTimingFields: DraftJobFields[] = [DraftJobFields.sameTimeSchedule, DraftJobFields.daysSelect]
export const distinctTimingFields: DraftJobFields[] = [DraftJobFields.daysSelect]

export const JobScheduleModel = types
  .model('JobSchedule', {
    dateBoundaries: types.optional(DateBoundariesModel, {}),
    scheduleType: types.optional(ScheduleTypeModel, {}),
    sameTimeSchedule: types.optional(SameTimeScheduleModel, {}),
    distinctTimeSchedule: types.optional(DistinctTimeScheduleModel, {}),
  })
  .views(self => ({
    get anyScheduleError() {
      return (
        self.scheduleType.error ??
        self.sameTimeSchedule.all_days_have_same_time_error ??
        self.sameTimeSchedule.same_start_time_error ??
        self.sameTimeSchedule.same_end_time_error ??
        self.distinctTimeSchedule.error
      )
    },
  }))
  .actions(self => ({
    preselectDate(date: string) {
      self.distinctTimeSchedule.setDateTime({
        date,
        start_time: self.sameTimeSchedule.same_start_time ?? null,
        end_time: self.sameTimeSchedule.same_end_time ?? null,
        selected: true,
      })
    },
  }))
  .actions(self => ({
    setSameStartTime(time: string) {
      self.sameTimeSchedule.setStartTime(time)
      self.distinctTimeSchedule.map.forEach((dateObj, key) => {
        if (dateObj.selected) {
          self.distinctTimeSchedule.setDateStartTime({ date: String(key), start_time: time })
        }
      })
    },

    setSameEndTime(time: string) {
      self.sameTimeSchedule.setEndTime(time)
      self.distinctTimeSchedule.map.forEach((dateObj, key) => {
        if (dateObj.selected) {
          self.distinctTimeSchedule.setDateEndTime({ date: String(key), end_time: time })
        }
      })
    },

    setRangeOfDays() {
      if (!self.dateBoundaries.start_date || !self.dateBoundaries.end_date) return
      self.distinctTimeSchedule.removeDates()

      const dateRanges = getRangeOfDates(
        new Date(self.dateBoundaries.start_date),
        new Date(self.dateBoundaries.end_date),
      )

      if (self.scheduleType.value === ScheduleType.daily) {
        dateRanges.forEach(date => {
          const formatted = formatDateToIso8601(date)
          self.distinctTimeSchedule.setDateStartTime({ date: formatted, start_time: null })
          self.distinctTimeSchedule.setDateEndTime({ date: formatted, end_time: null })
          self.preselectDate(formatted)
        })
      } else {
        self.distinctTimeSchedule.putEmptyTimesForWeekdays()
        dateRanges.forEach(date => {
          self.preselectDate(formatYyyyMmDdToDdd(formatDateToIso8601(date)))
        })
      }
    },
  }))
  .actions(self => ({
    setScheduleType(type: ScheduleType) {
      self.scheduleType.set(type)
      if (self.dateBoundaries.isValid) {
        self.setRangeOfDays()
      }
    },

    setEndDate(endDate: string | null) {
      self.dateBoundaries.setEndDate(endDate)
      if (self.dateBoundaries.start_date && self.scheduleType.value) {
        self.setRangeOfDays()
      }

      self.sameTimeSchedule.reset()
    },
  }))
  .actions(self => ({
    setStartDate(startDate: string) {
      self.dateBoundaries.setStartDate(startDate)
      if (self.dateBoundaries.open_ended) {
        self.dateBoundaries.setEndDate(
          moment(self.dateBoundaries.start_date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'),
        )
      }
      if (self.dateBoundaries.end_date && self.scheduleType.value) {
        self.setRangeOfDays()
      }

      self.sameTimeSchedule.reset()
    },

    setIfOpenEnded(isOpenEnded: boolean) {
      self.dateBoundaries.open_ended = isOpenEnded
      if (!isOpenEnded) {
        self.setEndDate(null)
        return
      }

      if (self.dateBoundaries.start_date) {
        self.setEndDate(moment(self.dateBoundaries.start_date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD'))
      }
    },
  }))

export type JobScheduleInstance = SnapshotOut<typeof JobScheduleModel>
