import { ApiError, ApiErrorTypes } from './api-types';
export class Api {
    async setAuthKey(authorizationKey) {
        this.authorizationKey = authorizationKey;
        return await Promise.resolve(true);
    }
    async getAuthKey() {
        return await Promise.resolve(this.authorizationKey);
    }
    async makeRequest(config) {
        let result;
        try {
            result = await this.request(config);
        }
        catch (err) {
            throw new ApiError(ApiErrorTypes.ConnectionError, err);
        }
        const { data, status, headers } = result;
        if (status >= 200 && status < 300) {
            if (headers['authorization'] !== undefined) {
                this.setAuthKey(headers['authorization']);
            }
            return data;
        }
        const error = data;
        if (status === 401) {
            throw new ApiError(ApiErrorTypes.AuthError, error);
        }
        if (status === 403) {
            throw new ApiError(ApiErrorTypes.PermissionError, error);
        }
        if (status === 404) {
            throw new ApiError(ApiErrorTypes.NotFound, error);
        }
        if (status === 429) {
            throw new ApiError(ApiErrorTypes.TooManyRequestsError, error);
        }
        if (status >= 400 && status < 500) {
            throw new ApiError(ApiErrorTypes.ClientError, error);
        }
        if (status >= 500) {
            throw new ApiError(ApiErrorTypes.ServerError, error);
        }
        throw new ApiError(ApiErrorTypes.ConnectionError, error);
    }
}
