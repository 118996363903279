import { useTranslation } from 'react-i18next'

import { Button } from '../../../../button/button'
import { SeniorityLevelSnapshot } from '../../../../../models/seniority-level'

type UIProps = {
  onClick: () => void
  isActive: boolean
  title: string
  seniorityLevel: SeniorityLevelSnapshot | null | undefined
  isError: boolean
}

const getYearsString = (seniorityLevel: SeniorityLevelSnapshot) => {
  if (seniorityLevel.minYears === seniorityLevel.maxYears) {
    return `${seniorityLevel.minYears}`
  }

  if (seniorityLevel.minYears && seniorityLevel.maxYears) {
    return `${seniorityLevel.minYears}-${seniorityLevel.maxYears}`
  }

  if (!seniorityLevel.maxYears) {
    return `${seniorityLevel.minYears}+`
  }

  return `0-${seniorityLevel.maxYears}`
}

export const ExperienceButton = ({ onClick, isActive, title, seniorityLevel, isError }: UIProps) => {
  const { t } = useTranslation()

  return (
    <Button
      onClick={onClick}
      className={`JobDraftExperience__innerContainer__button ${isActive ? 'active' : ''} ${isError ? 'error' : ''}`}
    >
      <div className="JobDraftExperience__innerContainer__button__content">
        <span className={`JobDraftExperience__innerContainer__button__label ${isError ? 'error' : ''}`}>{title}</span>
        {seniorityLevel && (seniorityLevel.minYears ?? seniorityLevel.maxYears) && (
          <span className="JobDraftExperience__innerContainer__button__content__description">
            {getYearsString(seniorityLevel)} {t('job_draft_details.experience_years')}
          </span>
        )}
      </div>
    </Button>
  )
}
