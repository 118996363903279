import { Genders } from '../../../models-ui/order/store/job-fields/gender'

export const getGenderName = (gender: Genders | null) => {
  switch (gender) {
    case Genders.male:
      return 'Male'
    case Genders.female:
      return 'Female'
    case Genders.other:
      return 'Other'
    case null:
      return 'No preference'
  }
}
