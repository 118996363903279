import {
  AddAttendanceManager as AddAttendanceManagerFormPage,
  EditAttendanceManager as EditAttendanceManagerPage,
} from './attendance-manager-form/attendance-manager-form'
import { AttendanceManagerList as AttendanceManagerListPage } from './attendance-manager-list/attendance-manager-list'
import { CompanyForm as CompanyFormPage } from './company-form/company-form'
import { InvoiceListPage } from './invoice-list/page'
import { InvoiceDetailsPage } from './invoice/page'
import { InvoiceOgrammer as InvoiceOgrammerPage } from './invoice-ogrammer/invoice-ogrammer'
import { PaymentMethodForm as PaymentMethodFormPage } from './payment-method-form/payment-method-form'
import { PaymentMethodList as PaymentMethodListPage } from './payment-method-list/payment-method-list'
import { Preferences as PreferencesPage } from './preferences/preferences'
import { ProfileForm as ProfileFormPage } from './profile-form/profile-form'

export const ProfileStack = {
  AddAttendanceManagerFormPage,
  EditAttendanceManagerPage,
  AttendanceManagerListPage,
  CompanyFormPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  InvoiceOgrammerPage,
  PaymentMethodFormPage,
  PaymentMethodListPage,
  PreferencesPage,
  ProfileFormPage,
}
