let runAssertFns = true;
export function setConfig(options) {
    runAssertFns = options.runAssertFns;
}
export function assert(value, msg = `Assertion failed`) {
    if (value !== true) {
        throw new Error(msg);
    }
}
export function assertType(value) { }
export function assertFn(value, msg = `Assertion failed`) {
    if (runAssertFns) {
        if (value() !== true) {
            throw new Error(msg);
        }
    }
}
export function ensureArray(value, msg = `Expected array but got ${value}`) {
    if (!Array.isArray(value)) {
        throw new Error(msg);
    }
}
export function ensureObject(value, msg = `Expected object but got ${value}`) {
    if (typeof value !== 'object' || value === null) {
        throw new Error(msg);
    }
}
export function ensureString(value, msg = `Expected string but got ${value}`) {
    if (typeof value !== 'string') {
        throw new Error(msg);
    }
}
export function ensureNumber(value, msg = `Expected number but got ${value}`) {
    if (typeof value !== 'number') {
        throw new Error(msg);
    }
}
export function ensureBoolean(value, msg = `Expected boolean but got ${value}`) {
    if (typeof value !== 'boolean') {
        throw new Error(msg);
    }
}
export function ensureNonVoid(value, msg = `Expected non undefined value`) {
    if (value === undefined) {
        throw new Error(msg);
    }
}
export function ensureNonNull(value, msg = `Expected non null value`) {
    if (value === null) {
        throw new Error(msg);
    }
}
export function ensureNonNullable(value, msg = `Expected non nullable value`) {
    if (value === undefined || value === null) {
        throw new Error(msg);
    }
}
export function nonVoid(value, msg = `Expected non undefined value`) {
    if (value === undefined) {
        throw new Error(msg);
    }
    return value;
}
export function nonNull(value, msg = `Expected non null value`) {
    if (value === null) {
        throw new Error(msg);
    }
    return value;
}
export function nonNullable(value, msg = `Expected non nullable value`) {
    if (value === null || value === undefined) {
        throw new Error(msg);
    }
    return value;
}
export function never(msg = `Never call`) {
    throw new Error(msg);
}
export function neverValue(value, msg = `Never possible value: ${JSON.stringify(value)}`) {
    throw new Error(msg);
}
export function castValue(value) { }
export function cast(value) {
    return value;
}
export function as(value) {
    return value;
}
export function maybe(val) {
    return val;
}
