import { useState, FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import moment from 'moment'

import { useQuery, useStore } from '../../../utils/mst-hooks'
import {
  ENABLED_PAYMENT_METHODS,
  LeanSetupExternalStatus,
  LeanSetupStatus,
  LeanStatus,
  PaymentMethod,
} from '../../../models/payment'
import { useRedirect } from '../../../services/router'
import { routes } from '../../../routes'
import { usePendingPaymentWithoutChosenMethodOrDenyAccess } from '../../../services/stripe'
import { UIProps } from './types'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { isLeanSetupInitiated, useConnectLean, useInitLeanPayment } from '../../../services/payment-service'

export function createPaymentMethodsContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ paymentId, paymentOptions }: { paymentId: number; paymentOptions?: string }) => {
      const redirect = useRedirect()
      const { t } = useTranslation()
      const {
        paymentMethods,
        getPaymentMethods,
        cards,
        setLeanSetupStatus,
        setLeanSetupCompleted,
        setLeanSetupStatusUpdatedAt,
      } = useStore().data

      const { isLoading: isCurrentPaymentLoading, currentPayment } =
        usePendingPaymentWithoutChosenMethodOrDenyAccess(paymentId)

      const getPaymentMethodsQuery = useQuery(() => getPaymentMethods(paymentId), {
        onSuccess: result => {
          const cardId = result.cards[0]?.id ?? null
          if (cardId) {
            chooseSavedCard(cardId)
          }
        },
        onError: err => {
          toast.error(err.data.message)
          redirect(routes.paymentDetails, { paymentId: String(paymentId) })
        },
        filterError: [ApiErrorTypes.ClientError],
      })

      const defaultPaymentMethod = PaymentMethod.Card
      const defaultCardId = paymentMethods?.cards[0]?.id ?? null

      const [chosenPaymentMethod, setChosenPaymentMethod] = useState<PaymentMethod | null>(
        !defaultCardId ? defaultPaymentMethod : null,
      )
      const [chosenSavedCard, setChosenSavedCard] = useState<string | null>(defaultCardId)

      const { initLeanPayment, isLoading: isLeanPayPreparing } = useInitLeanPayment()
      const { connectLean } = useConnectLean(responseObject => {
        if (responseObject.status === String(LeanStatus.SUCCESS)) {
          if (responseObject.secondary_status === String(LeanSetupExternalStatus.ACTIVE)) {
            setLeanSetupStatus(LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE)
            setLeanSetupStatusUpdatedAt(moment().format('YYYY-MM-DD'))
            initLeanPayment(paymentId, currentPayment?.external_id ?? null)

            toast.success(t('payment.successfully_connected_bank'))
          }

          if (responseObject.secondary_status === String(LeanSetupExternalStatus.PENDING_BENEFICIARY_COOL_OFF)) {
            setLeanSetupCompleted(1)
            setLeanSetupStatus(LeanSetupStatus.LEAN_SETUP_STATUS_AWAITING_BENEFICIARY_COOL_OFF)
            setLeanSetupStatusUpdatedAt(moment().format('YYYY-MM-DD'))

            toast.info(t('payment.lean.account_is_being_connected'))
          }
        }
      })

      const isSavedCardChosen = (cardId: string) => {
        return chosenSavedCard === cardId
      }

      const isPaymentMethodChosen = (paymentMethod: PaymentMethod) => {
        return chosenSavedCard === null && paymentMethod === chosenPaymentMethod
      }

      const choosePaymentMethod = (paymentMethod: PaymentMethod) => {
        setChosenSavedCard(null)
        setChosenPaymentMethod(paymentMethod)
      }

      const chooseSavedCard = (cardId: string) => {
        setChosenPaymentMethod(null)
        setChosenSavedCard(cardId)
      }

      const isAnyPaymentMethodChosen = () => {
        return chosenPaymentMethod !== null
      }

      const isAnySavedCardChosen = () => {
        return chosenSavedCard !== null
      }

      const methods =
        paymentMethods?.methods.filter(method => {
          const methodValue = String(method) as PaymentMethod
          return ENABLED_PAYMENT_METHODS.includes(methodValue)
        }) ?? []

      const onPaymentMethodContinue = () => {
        const paymentMethodParams = {
          paymentId: String(paymentId),
          ...(paymentOptions ? { paymentOptions: paymentOptions } : {}),
        }

        switch (chosenPaymentMethod) {
          case PaymentMethod.Card:
            redirect(routes.payWithCard, paymentMethodParams)
            break
          case PaymentMethod.Lean:
            if (
              paymentMethods?.config?.lean_setup_status ===
              Number(LeanSetupStatus.LEAN_SETUP_STATUS_AWAITING_BENEFICIARY_COOL_OFF)
            ) {
              toast.info(t('payment.lean.account_is_being_connected'))
              return
            }

            if (paymentMethods?.config?.lean_setup_status === Number(LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE)) {
              initLeanPayment(paymentId, currentPayment?.external_id ?? null)
              return
            }

            connectLean()
            break
          case PaymentMethod.Wire:
            redirect(routes.wireTransferDetails, { paymentId: String(paymentId) })
            break
          default:
            break
        }
      }

      const continueText =
        chosenPaymentMethod === PaymentMethod.Lean && !isLeanSetupInitiated(paymentMethods?.config?.lean_setup_status)
          ? t('payment.connect_lean_account')
          : t('payment.continue')

      return (
        <UIComponent
          isLoading={getPaymentMethodsQuery.isLoading || isCurrentPaymentLoading}
          cards={cards}
          methods={methods}
          chooseSavedCard={chooseSavedCard}
          isSavedCardChosen={isSavedCardChosen}
          choosePaymentMethod={choosePaymentMethod}
          isPaymentMethodChosen={isPaymentMethodChosen}
          isAnyPaymentMethodChosen={isAnyPaymentMethodChosen()}
          isAnySavedCardChosen={isAnySavedCardChosen()}
          onPaymentMethodContinue={onPaymentMethodContinue}
          isChosenPaymentMethodLoading={isLeanPayPreparing}
          onSavedCardContinue={() =>
            redirect(routes.payWithSavedCard, {
              paymentId: String(paymentId),
              cardId: String(chosenSavedCard),
              ...(paymentOptions ? { paymentOptions: paymentOptions } : {}),
            })
          }
          connectLean={connectLean}
          continueText={continueText}
          leanSetupCompleted={
            paymentMethods?.config?.lean_setup_status === Number(LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE)
          }
          leanSetupStatus={paymentMethods?.config?.lean_setup_status ?? null}
          leanStatusUpdatedAt={paymentMethods?.config?.lean_status_updated_at ?? null}
          amount={`${currentPayment?.currency_code} ${currentPayment?.amount}`}
        />
      )
    }),
  )
}
