import { getEnv, cast } from 'mobx-state-tree';
export const withRequest = (self) => {
    return {
        actions: {
            request: (method, url, data, options = {}) => {
                var _a;
                let sendData = data;
                if (((_a = options === null || options === void 0 ? void 0 : options.headers) === null || _a === void 0 ? void 0 : _a['Content-Type']) === 'multipart/form-data') {
                    const formData = new FormData();
                    for (const key in data) {
                        formData.append(key, cast(data[key]));
                    }
                    sendData = formData;
                }
                return getEnv(self).api.makeRequest({ method: method, url: url, data: sendData, ...options });
            },
            rawRequest: (method, url, data) => {
                return getEnv(self).rawApi.request({ method: method, url: url, data: data });
            },
        },
    };
};
