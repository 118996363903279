import { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../../../../utils/mst-hooks'
import { UserRole } from '../../../../../models/account'
import { Separator } from '../../../../../components/app-layout/separator/separator'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'
import avatarPlaceholderImg from './assets/avatar-placeholder.svg'
import './unconfirmed-user-card.scss'

type Props = {
  email: string
  roles: number[]
  locations?: number[]
}

export const UnconfirmedUserCard = withErrorBoundary(
  observer(({ email, roles, locations }: Props) => {
    const { getLocationNameById } = useStore().data

    const { t } = useTranslation()

    const getRoleByNumber = (role: UserRole) => {
      switch (role) {
        case UserRole.Admin:
          return t('user_management.role_admin')
        case UserRole.Owner:
          return t('user_management.role_owner')
        case UserRole.Manager:
          return t('user_management.role_manager')
        case UserRole.Finance:
          return t('user_management.role_finance')
      }
    }

    return (
      <div className={`UserCard ${locations ? 'increased' : ''}`}>
        <div className="UserCard__leftContainer">
          <img src={avatarPlaceholderImg} alt="" className={`UserCard__leftContainer__userPhoto`} />
          <Separator.Horizontal width={8} />
          <div className="UserCard__leftContainer__userInfo">
            <span className="UserCard__leftContainer__userInfo__name">{email}</span>
            <div className="UserCard__leftContainer__userInfo__locations">
              {locations?.map((locationId) => (
                <Fragment key={locationId}>
                  <span className="UserCard__leftContainer__userInfo__locations__location">
                    {getLocationNameById(String(locationId))}
                  </span>
                  <Separator.Vertical height={3} />
                </Fragment>
              ))}
            </div>
          </div>
          <Separator.Horizontal width={8} />
          {roles.map((role, index) => {
            const roleName = getRoleByNumber(role)
            return (
              <Fragment key={role}>
                <div className={`UserCard__leftContainer__roleContainer ${roleName}`}>
                  <span>{roleName}</span>
                </div>
                {index !== roles.length - 1 && <Separator.Horizontal width={8} />}
              </Fragment>
            )
          })}
        </div>
      </div>
    )
  }),
)
