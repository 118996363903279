import { ReferralStatus } from '../../models/affiliate'

type StatusSettings = {
  mainColorRGB: number[]
  title: string
}

const statusesSettings = {
  [ReferralStatus.ProfileCreated]: {
    mainColorRGB: [186, 190, 214],
    title: 'common_affiliate.status_profile_created',
  },
  [ReferralStatus.Activated]: {
    mainColorRGB: [119, 103, 228],
    title: 'common_affiliate.status_activated',
  },
  [ReferralStatus.BookedShifts]: {
    mainColorRGB: [112, 148, 247],
    title: 'common_affiliate.status_booked_shifts',
  },
  [ReferralStatus.ShiftsDone]: {
    mainColorRGB: [142, 205, 156],
    title: 'common_affiliate.status_shifts_done',
  },
}

export const getStatusSettings = (status: ReferralStatus): StatusSettings => {
  return statusesSettings[status]
}
