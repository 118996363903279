import * as Yup from 'yup'

import { t } from '../../../translations'

const GENERAL_RATE_REQUIRED = t('job_rating.provide_overall_rating')
const OGRAMMER_RATE_REQUIRED = t('job_rating.provide_og_rating')

export const jobRatingFormValidation = Yup.object().shape({
  job_rating: Yup.number().min(1, GENERAL_RATE_REQUIRED).max(5, GENERAL_RATE_REQUIRED).required(GENERAL_RATE_REQUIRED),
  ogrammerRates: Yup.array()
    .of(
      Yup.object().shape({
        comment: Yup.string().nullable(true),
        rating: Yup.number()
          .min(1, OGRAMMER_RATE_REQUIRED)
          .max(5, OGRAMMER_RATE_REQUIRED)
          .nullable(true)
          .required(OGRAMMER_RATE_REQUIRED),
      }),
    )
    .required(t('job_rating.provide_ogrammers_rating')),
})
