import { Fragment } from 'react'
import { observer } from 'mobx-react'

import { useStore } from '../../../../../../../utils/mst-hooks'
import { Column } from './column'
import { RowSeparator } from './row-separator'
import { ScheduleShiftSnapshot, ScheduleDate, Schedule } from '../../../../../../../models/schedule/types'
import { formatDateToIso8601 } from '../../../../../../../utils/datetime-utils'

type CellProps = {
  weekDates: Date[]
  firstWeekDate: Date
  jobSchedule: Schedule
  renderCell: (props: {
    jobSchedule: Schedule
    shift: ScheduleShiftSnapshot | null
    dateShift: ScheduleDate | null
  }) => React.ReactNode | React.ReactNode[]
}

function CellsComponent({ weekDates, jobSchedule, firstWeekDate, renderCell: renderContent }: CellProps) {
  const { getJobShift } = useStore().data
  return (
    <>
      {weekDates.map(date => {
        const formatedWeekDate = formatDateToIso8601(firstWeekDate)
        const dateShift = jobSchedule.getDateShift(date) ?? null
        const jobShift = dateShift?.shift_id
          ? getJobShift(jobSchedule.job_id, formatedWeekDate, dateShift.shift_id)
          : null
        return (
          <Fragment key={date.getDate()}>
            <RowSeparator />
            <Column>{renderContent({ jobSchedule, shift: jobShift, dateShift })}</Column>
          </Fragment>
        )
      })}
    </>
  )
}

export const Cells = observer(CellsComponent)
