import { useRef, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../../../components/app-layout'
import { InfoIcon } from '../../../../../../components/assets/components/info-icon'
import { StickyPanel } from '../../../../../../components/sticky-panel/sticky-panel'
import { UIProps } from '../types'
import { BankAccountDetails } from '../../components/bank-account-details/bank-account-details'
import './wire-transfer-upload-slip.scss'

export const WireTransferUploadSlip = ({
  paymentId,
  slipFile,
  setSlipFile,
  resetPaymentQuery,
  isPaymentProcessing,
  processPaymentRequest,
  onRemindLater,
}: UIProps) => {
  const { t } = useTranslation()

  const SlipFileInputRef = useRef<HTMLInputElement>(null)
  const onSetFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (!file) {
      return
    }

    setSlipFile(file)
  }

  return (
    <div className="WireTransferUploadSlip">
      <Header title={t('payment.payment_details_title', { paymentId })} showBack />
      <Separator.Vertical height={35} />
      <div className="WireTransferUploadSlip__container">
        <div className="WireTransferUploadSlip__alert">
          <span className="WireTransferUploadSlip__alert__title">{t('payment.please_upload_slip')}</span>
          <div className="WireTransferUploadSlip__alert__info">
            <InfoIcon color="#FCB35C" />
            <span className="WireTransferUploadSlip__alert__info__text">{t('payment.bank_slip_info_in_alert')}</span>
          </div>
        </div>
        <div className="WireTransferUploadSlip__uploadContainer">
          <span className="WireTransferUploadSlip__uploadContainer__title">{t('payment.payment_slip')}</span>
          <button
            onClick={() => SlipFileInputRef.current?.click()}
            className="WireTransferUploadSlip__uploadContainer__uploadButton"
          >
            {!slipFile ? t('payment.upload_payment_slip') : t('payment.document_selected')}
          </button>
          <input type="file" style={{ display: 'none' }} ref={SlipFileInputRef} onChange={onSetFile} />
          <div className="WireTransferUploadSlip__uploadContainer__info">
            <p className="WireTransferUploadSlip__uploadContainer__info__part">
              {t('payment.you_can_upload_slip_now_or_later')}
            </p>
            <p className="WireTransferUploadSlip__uploadContainer__info__part">
              {t('payment.you_can_select_another_payment_method')}
            </p>
          </div>
          <span onClick={resetPaymentQuery} className="WireTransferUploadSlip__uploadContainer__cancel">
            {t('payment.wire_transfer_cancel_button')}
          </span>
          <div className="WireTransferUploadSlip__bankContainer">
            <BankAccountDetails paymentId={paymentId} />
          </div>
        </div>
        <StickyPanel onClick={() => {}} style={{ justifyContent: 'center' }}>
          <button className="WireTransferUploadSlip__secondaryButton" onClick={onRemindLater}>
            {t('payment.wire_transfer_remind_later_button')}
          </button>
          <button
            disabled={!slipFile}
            className={`WireTransferUploadSlip__primaryButton${
              !slipFile ? ' WireTransferUploadSlip__primaryButton--disabled' : ''
            }`}
            onClick={() => {
              if (!slipFile) {
                return
              }
              processPaymentRequest()
            }}
          >
            {isPaymentProcessing
              ? t('payment.wire_transfer_slip_uploading')
              : t('payment.wire_transfer_i_have_sent_money')}
          </button>
        </StickyPanel>
      </div>
    </div>
  )
}
