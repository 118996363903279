import * as Yup from 'yup'
import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { FormikProps } from 'formik/dist/types'

import { Separator } from '../../../../../../components/app-layout'
import { FieldError } from '../../../../../../components/form/field-error/field-error'
import { Input } from '../../../../../../components/form/input/input'
import { ContactDataFormProps } from '../../../types'
import { rawCountries } from '../../../../../../components/phone-input/raw-countries'
import { PhoneInput } from '../../../../../../components/phone-input/phone-input'
import { default as getPhoneValidationRules } from '../../../../../../components/phone-input/validation'
import { Header } from '../../../../components/header/header'
import 'ogram-react-phone-input-2/lib/style.css'
import '../form.scss'

type UIProps = {
  phone: string
  email: string
  allowMarketingEmails: boolean
  handlePhoneChange: (value: string) => void
  handleEmailChange: (value: string) => void
  handleToggleEmailAgreement: () => void
  innerRef: React.Ref<FormikProps<ContactDataFormProps>>
  phoneErrors: string[] | string | undefined
  emailErrors: string[] | string | undefined
  allowMarketingEmailsErrors: string[] | string | undefined
}

export const ContactDataForm = ({
  phone,
  email,
  allowMarketingEmails,
  handlePhoneChange,
  handleEmailChange,
  handleToggleEmailAgreement,
  innerRef,
  phoneErrors,
  emailErrors,
  allowMarketingEmailsErrors,
}: UIProps) => {
  const { t } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null)

  return (
    <Formik
      innerRef={innerRef}
      initialValues={{
        phone,
        email,
        allow_marketing_emails: allowMarketingEmails,
      }}
      validationSchema={Yup.object().shape({
        phone: getPhoneValidationRules(
          t('sign_up.phone_required'),
          t('sign_up.phone_invalid'),
          rawCountries[selectedCountry as string],
        ),
        email: Yup.string().email(t('validation_messages.email_invalid')).required(t('sign_up.email_required')),
      })}
      enableReinitialize
      onSubmit={values => {}}
      validateOnChange={false}
    >
      {({ values, setFieldValue, errors }) => {
        return (
          <Fragment>
            <Header title={t('sign_up.create_new_order_title')} />
            <Separator.Vertical height={45} />
            <span className="SignUp__title">{t('sign_up.how_to_reach_title')}</span>
            <Separator.Vertical height={1} />
            <span className="SignUp__subtitle">{t('sign_up.contact_details_subtitle')}</span>
            <Separator.Vertical height={27} />
            <div className="SignUp__row">
              <FieldError errors={errors.email ?? emailErrors}>
                <Input
                  label={t('sign_up.email')}
                  value={values.email}
                  type="email"
                  handleChange={value => {
                    setFieldValue('email', value)
                    handleEmailChange(value)
                  }}
                />
              </FieldError>
            </div>
            <Separator.Vertical height={16} />
            <div className="SignUp__row">
              <FieldError errors={errors.phone ?? phoneErrors}>
                <PhoneInput
                  phone={phone}
                  handlePhoneChange={handlePhoneChange}
                  setSelectedCountry={setSelectedCountry}
                />
              </FieldError>
            </div>
            <Separator.Vertical height={16} />
            <div className="SignUp__row">
              <FieldError errors={errors.allow_marketing_emails ?? allowMarketingEmailsErrors} className="SignUp__row">
                <input
                  type="checkbox"
                  onClick={handleToggleEmailAgreement}
                  checked={allowMarketingEmails}
                  className="SignUp__checkboxContainer__checkbox"
                />
                <Separator.Horizontal width={10} />
                <div className="SignUp__checkboxContainer__labelContainer">
                  <span className="SignUp__checkboxContainer__labelContainer__label">
                    {t('sign_up.agree_to_emails')}
                  </span>
                </div>
              </FieldError>
            </div>
            <Separator.Vertical height={27} />
          </Fragment>
        )
      }}
    </Formik>
  )
}
