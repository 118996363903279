import { createContext } from 'react'
import { createMSTHooks, ApiErrorTypes } from 'ogram-react'

import { RootStore } from '../models/root-store'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const context = createContext<unknown>(null!)

export const { MSTProvider, RequestResult, useMutation, useQuery, useDelayedQuery, useSuspenseQuery, useStore } =
  createMSTHooks<RootStore, ApiErrorTypes>(context)
