import { Country } from './types'

export const rawCountries: { [key: string]: Country } = {
  'af': {
    'name': 'Afghanistan',
    'iso2': 'af',
    'dialCode': '93',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'al': {
    'name': 'Albania',
    'iso2': 'al',
    'dialCode': '355',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'dz': {
    'name': 'Algeria',
    'iso2': 'dz',
    'dialCode': '213',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ad': {
    'name': 'Andorra',
    'iso2': 'ad',
    'dialCode': '376',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ao': {
    'name': 'Angola',
    'iso2': 'ao',
    'dialCode': '244',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ag': {
    'name': 'Antigua and Barbuda',
    'iso2': 'ag',
    'dialCode': '1268',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ar': {
    'name': 'Argentina',
    'iso2': 'ar',
    'dialCode': '54',
    'mask': '(..) ........',
    'priority': 0,
    'regionCodes': [
      '11',
      '221',
      '223',
      '261',
      '264',
      '2652',
      '280',
      '2905',
      '291',
      '2920',
      '2966',
      '299',
      '341',
      '342',
      '343',
      '351',
      '376',
      '379',
      '381',
      '3833',
      '385',
      '387',
      '388',
    ],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'am': {
    'name': 'Armenia',
    'iso2': 'am',
    'dialCode': '374',
    'mask': '.. ......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'aw': {
    'name': 'Aruba',
    'iso2': 'aw',
    'dialCode': '297',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'au': {
    'name': 'Australia',
    'iso2': 'au',
    'dialCode': '61',
    'mask': '(..) .... ....',
    'priority': 0,
    'regionCodes': ['2', '3', '4', '7', '8', '02', '03', '04', '07', '08'],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'at': {
    'name': 'Austria',
    'iso2': 'at',
    'dialCode': '43',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'az': {
    'name': 'Azerbaijan',
    'iso2': 'az',
    'dialCode': '994',
    'mask': '(..) ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bs': {
    'name': 'Bahamas',
    'iso2': 'bs',
    'dialCode': '1242',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bh': {
    'name': 'Bahrain',
    'iso2': 'bh',
    'dialCode': '973',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bd': {
    'name': 'Bangladesh',
    'iso2': 'bd',
    'dialCode': '880',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bb': {
    'name': 'Barbados',
    'iso2': 'bb',
    'dialCode': '1246',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'by': {
    'name': 'Belarus',
    'iso2': 'by',
    'dialCode': '375',
    'mask': '(..) ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'be': {
    'name': 'Belgium',
    'iso2': 'be',
    'dialCode': '32',
    'mask': '... .. .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bz': {
    'name': 'Belize',
    'iso2': 'bz',
    'dialCode': '501',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bj': {
    'name': 'Benin',
    'iso2': 'bj',
    'dialCode': '229',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bt': {
    'name': 'Bhutan',
    'iso2': 'bt',
    'dialCode': '975',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bo': {
    'name': 'Bolivia',
    'iso2': 'bo',
    'dialCode': '591',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ba': {
    'name': 'Bosnia and Herzegovina',
    'iso2': 'ba',
    'dialCode': '387',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bw': {
    'name': 'Botswana',
    'iso2': 'bw',
    'dialCode': '267',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'br': {
    'name': 'Brazil',
    'iso2': 'br',
    'dialCode': '55',
    'mask': '(..) .........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'io': {
    'name': 'British Indian Ocean Territory',
    'iso2': 'io',
    'dialCode': '246',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bn': {
    'name': 'Brunei',
    'iso2': 'bn',
    'dialCode': '673',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bg': {
    'name': 'Bulgaria',
    'iso2': 'bg',
    'dialCode': '359',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bf': {
    'name': 'Burkina Faso',
    'iso2': 'bf',
    'dialCode': '226',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bi': {
    'name': 'Burundi',
    'iso2': 'bi',
    'dialCode': '257',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kh': {
    'name': 'Cambodia',
    'iso2': 'kh',
    'dialCode': '855',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cm': {
    'name': 'Cameroon',
    'iso2': 'cm',
    'dialCode': '237',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ca': {
    'name': 'Canada',
    'iso2': 'ca',
    'dialCode': '1',
    'mask': '(...) ...-....',
    'priority': 1,
    'regionCodes': [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905',
    ],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cv': {
    'name': 'Cape Verde',
    'iso2': 'cv',
    'dialCode': '238',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'bq': {
    'name': 'Caribbean Netherlands',
    'iso2': 'bq',
    'dialCode': '599',
    'mask': '',
    'priority': 1,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cf': {
    'name': 'Central African Republic',
    'iso2': 'cf',
    'dialCode': '236',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'td': {
    'name': 'Chad',
    'iso2': 'td',
    'dialCode': '235',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cl': {
    'name': 'Chile',
    'iso2': 'cl',
    'dialCode': '56',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cn': {
    'name': 'China',
    'iso2': 'cn',
    'dialCode': '86',
    'mask': '..-.........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'co': {
    'name': 'Colombia',
    'iso2': 'co',
    'dialCode': '57',
    'mask': '... ... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'km': {
    'name': 'Comoros',
    'iso2': 'km',
    'dialCode': '269',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cd': {
    'name': 'Congo',
    'iso2': 'cd',
    'dialCode': '243',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cg': {
    'name': 'Congo',
    'iso2': 'cg',
    'dialCode': '242',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cr': {
    'name': 'Costa Rica',
    'iso2': 'cr',
    'dialCode': '506',
    'mask': '....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ci': {
    'name': 'C\u00f4te d\u2019Ivoire',
    'iso2': 'ci',
    'dialCode': '225',
    'mask': '.. .. .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'hr': {
    'name': 'Croatia',
    'iso2': 'hr',
    'dialCode': '385',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cu': {
    'name': 'Cuba',
    'iso2': 'cu',
    'dialCode': '53',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cw': {
    'name': 'Cura\u00e7ao',
    'iso2': 'cw',
    'dialCode': '599',
    'mask': '',
    'priority': 0,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cy': {
    'name': 'Cyprus',
    'iso2': 'cy',
    'dialCode': '357',
    'mask': '.. ......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'cz': {
    'name': 'Czech Republic',
    'iso2': 'cz',
    'dialCode': '420',
    'mask': '... ... ...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'dk': {
    'name': 'Denmark',
    'iso2': 'dk',
    'dialCode': '45',
    'mask': '.. .. .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'dj': {
    'name': 'Djibouti',
    'iso2': 'dj',
    'dialCode': '253',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'dm': {
    'name': 'Dominica',
    'iso2': 'dm',
    'dialCode': '1767',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'do': {
    'name': 'Dominican Republic',
    'iso2': 'do',
    'dialCode': '1',
    'mask': '',
    'priority': 2,
    'regionCodes': ['809', '829', '849'],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ec': {
    'name': 'Ecuador',
    'iso2': 'ec',
    'dialCode': '593',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'eg': {
    'name': 'Egypt',
    'iso2': 'eg',
    'dialCode': '20',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sv': {
    'name': 'El Salvador',
    'iso2': 'sv',
    'dialCode': '503',
    'mask': '....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gq': {
    'name': 'Equatorial Guinea',
    'iso2': 'gq',
    'dialCode': '240',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'er': {
    'name': 'Eritrea',
    'iso2': 'er',
    'dialCode': '291',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ee': {
    'name': 'Estonia',
    'iso2': 'ee',
    'dialCode': '372',
    'mask': '.... ......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'et': {
    'name': 'Ethiopia',
    'iso2': 'et',
    'dialCode': '251',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'fj': {
    'name': 'Fiji',
    'iso2': 'fj',
    'dialCode': '679',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'fi': {
    'name': 'Finland',
    'iso2': 'fi',
    'dialCode': '358',
    'mask': '.. ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'fr': {
    'name': 'France',
    'iso2': 'fr',
    'dialCode': '33',
    'mask': '. .. .. .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gf': {
    'name': 'French Guiana',
    'iso2': 'gf',
    'dialCode': '594',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pf': {
    'name': 'French Polynesia',
    'iso2': 'pf',
    'dialCode': '689',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ga': {
    'name': 'Gabon',
    'iso2': 'ga',
    'dialCode': '241',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gm': {
    'name': 'Gambia',
    'iso2': 'gm',
    'dialCode': '220',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ge': {
    'name': 'Georgia',
    'iso2': 'ge',
    'dialCode': '995',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'de': {
    'name': 'Germany',
    'iso2': 'de',
    'dialCode': '49',
    'mask': '.... ........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gh': {
    'name': 'Ghana',
    'iso2': 'gh',
    'dialCode': '233',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gr': {
    'name': 'Greece',
    'iso2': 'gr',
    'dialCode': '30',
    'mask': '..........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': 10,
    'maxLocalPhonePartLength': 10,
  },
  'gd': {
    'name': 'Grenada',
    'iso2': 'gd',
    'dialCode': '1473',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gp': {
    'name': 'Guadeloupe',
    'iso2': 'gp',
    'dialCode': '590',
    'mask': '',
    'priority': 0,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gu': {
    'name': 'Guam',
    'iso2': 'gu',
    'dialCode': '1671',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gt': {
    'name': 'Guatemala',
    'iso2': 'gt',
    'dialCode': '502',
    'mask': '....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gn': {
    'name': 'Guinea',
    'iso2': 'gn',
    'dialCode': '224',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gw': {
    'name': 'Guinea-Bissau',
    'iso2': 'gw',
    'dialCode': '245',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'gy': {
    'name': 'Guyana',
    'iso2': 'gy',
    'dialCode': '592',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ht': {
    'name': 'Haiti',
    'iso2': 'ht',
    'dialCode': '509',
    'mask': '....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'hn': {
    'name': 'Honduras',
    'iso2': 'hn',
    'dialCode': '504',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'hk': {
    'name': 'Hong Kong',
    'iso2': 'hk',
    'dialCode': '852',
    'mask': '.... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'hu': {
    'name': 'Hungary',
    'iso2': 'hu',
    'dialCode': '36',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'is': {
    'name': 'Iceland',
    'iso2': 'is',
    'dialCode': '354',
    'mask': '... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'in': {
    'name': 'India',
    'iso2': 'in',
    'dialCode': '91',
    'mask': '.....-.....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'id': {
    'name': 'Indonesia',
    'iso2': 'id',
    'dialCode': '62',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ir': {
    'name': 'Iran',
    'iso2': 'ir',
    'dialCode': '98',
    'mask': '... ... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'iq': {
    'name': 'Iraq',
    'iso2': 'iq',
    'dialCode': '964',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ie': {
    'name': 'Ireland',
    'iso2': 'ie',
    'dialCode': '353',
    'mask': '.. .......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'il': {
    'name': 'Israel',
    'iso2': 'il',
    'dialCode': '972',
    'mask': '... ... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'it': {
    'name': 'Italy',
    'iso2': 'it',
    'dialCode': '39',
    'mask': '... .......',
    'priority': 0,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'jm': {
    'name': 'Jamaica',
    'iso2': 'jm',
    'dialCode': '1876',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'jp': {
    'name': 'Japan',
    'iso2': 'jp',
    'dialCode': '81',
    'mask': '.. .... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'jo': {
    'name': 'Jordan',
    'iso2': 'jo',
    'dialCode': '962',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kz': {
    'name': 'Kazakhstan',
    'iso2': 'kz',
    'dialCode': '7',
    'mask': '... ...-..-..',
    'priority': 1,
    'regionCodes': [
      '310',
      '311',
      '312',
      '313',
      '315',
      '318',
      '321',
      '324',
      '325',
      '326',
      '327',
      '336',
      '7172',
      '73622',
    ],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ke': {
    'name': 'Kenya',
    'iso2': 'ke',
    'dialCode': '254',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ki': {
    'name': 'Kiribati',
    'iso2': 'ki',
    'dialCode': '686',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'xk': {
    'name': 'Kosovo',
    'iso2': 'xk',
    'dialCode': '383',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kw': {
    'name': 'Kuwait',
    'iso2': 'kw',
    'dialCode': '965',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kg': {
    'name': 'Kyrgyzstan',
    'iso2': 'kg',
    'dialCode': '996',
    'mask': '... ... ...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'la': {
    'name': 'Laos',
    'iso2': 'la',
    'dialCode': '856',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lv': {
    'name': 'Latvia',
    'iso2': 'lv',
    'dialCode': '371',
    'mask': '.. ... ...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lb': {
    'name': 'Lebanon',
    'iso2': 'lb',
    'dialCode': '961',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ls': {
    'name': 'Lesotho',
    'iso2': 'ls',
    'dialCode': '266',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lr': {
    'name': 'Liberia',
    'iso2': 'lr',
    'dialCode': '231',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ly': {
    'name': 'Libya',
    'iso2': 'ly',
    'dialCode': '218',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'li': {
    'name': 'Liechtenstein',
    'iso2': 'li',
    'dialCode': '423',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lt': {
    'name': 'Lithuania',
    'iso2': 'lt',
    'dialCode': '370',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lu': {
    'name': 'Luxembourg',
    'iso2': 'lu',
    'dialCode': '352',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mo': {
    'name': 'Macau',
    'iso2': 'mo',
    'dialCode': '853',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mk': {
    'name': 'Macedonia',
    'iso2': 'mk',
    'dialCode': '389',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mg': {
    'name': 'Madagascar',
    'iso2': 'mg',
    'dialCode': '261',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mw': {
    'name': 'Malawi',
    'iso2': 'mw',
    'dialCode': '265',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'my': {
    'name': 'Malaysia',
    'iso2': 'my',
    'dialCode': '60',
    'mask': '..-....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mv': {
    'name': 'Maldives',
    'iso2': 'mv',
    'dialCode': '960',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ml': {
    'name': 'Mali',
    'iso2': 'ml',
    'dialCode': '223',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mt': {
    'name': 'Malta',
    'iso2': 'mt',
    'dialCode': '356',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mh': {
    'name': 'Marshall Islands',
    'iso2': 'mh',
    'dialCode': '692',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mq': {
    'name': 'Martinique',
    'iso2': 'mq',
    'dialCode': '596',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mr': {
    'name': 'Mauritania',
    'iso2': 'mr',
    'dialCode': '222',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mu': {
    'name': 'Mauritius',
    'iso2': 'mu',
    'dialCode': '230',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mx': {
    'name': 'Mexico',
    'iso2': 'mx',
    'dialCode': '52',
    'mask': '... ... ....',
    'priority': 0,
    'regionCodes': ['55', '81', '33', '656', '664', '998', '774', '229'],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'fm': {
    'name': 'Micronesia',
    'iso2': 'fm',
    'dialCode': '691',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'md': {
    'name': 'Moldova',
    'iso2': 'md',
    'dialCode': '373',
    'mask': '(..) ..-..-..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mc': {
    'name': 'Monaco',
    'iso2': 'mc',
    'dialCode': '377',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mn': {
    'name': 'Mongolia',
    'iso2': 'mn',
    'dialCode': '976',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'me': {
    'name': 'Montenegro',
    'iso2': 'me',
    'dialCode': '382',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ma': {
    'name': 'Morocco',
    'iso2': 'ma',
    'dialCode': '212',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mz': {
    'name': 'Mozambique',
    'iso2': 'mz',
    'dialCode': '258',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'mm': {
    'name': 'Myanmar',
    'iso2': 'mm',
    'dialCode': '95',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'na': {
    'name': 'Namibia',
    'iso2': 'na',
    'dialCode': '264',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'nr': {
    'name': 'Nauru',
    'iso2': 'nr',
    'dialCode': '674',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'np': {
    'name': 'Nepal',
    'iso2': 'np',
    'dialCode': '977',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'nl': {
    'name': 'Netherlands',
    'iso2': 'nl',
    'dialCode': '31',
    'mask': '.. ........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'nc': {
    'name': 'New Caledonia',
    'iso2': 'nc',
    'dialCode': '687',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'nz': {
    'name': 'New Zealand',
    'iso2': 'nz',
    'dialCode': '64',
    'mask': '...-...-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ni': {
    'name': 'Nicaragua',
    'iso2': 'ni',
    'dialCode': '505',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ne': {
    'name': 'Niger',
    'iso2': 'ne',
    'dialCode': '227',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ng': {
    'name': 'Nigeria',
    'iso2': 'ng',
    'dialCode': '234',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kp': {
    'name': 'North Korea',
    'iso2': 'kp',
    'dialCode': '850',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'no': {
    'name': 'Norway',
    'iso2': 'no',
    'dialCode': '47',
    'mask': '... .. ...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'om': {
    'name': 'Oman',
    'iso2': 'om',
    'dialCode': '968',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pk': {
    'name': 'Pakistan',
    'iso2': 'pk',
    'dialCode': '92',
    'mask': '...-.......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pw': {
    'name': 'Palau',
    'iso2': 'pw',
    'dialCode': '680',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ps': {
    'name': 'Palestine',
    'iso2': 'ps',
    'dialCode': '970',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pa': {
    'name': 'Panama',
    'iso2': 'pa',
    'dialCode': '507',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pg': {
    'name': 'Papua New Guinea',
    'iso2': 'pg',
    'dialCode': '675',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'py': {
    'name': 'Paraguay',
    'iso2': 'py',
    'dialCode': '595',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pe': {
    'name': 'Peru',
    'iso2': 'pe',
    'dialCode': '51',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ph': {
    'name': 'Philippines',
    'iso2': 'ph',
    'dialCode': '63',
    'mask': '.... .......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pl': {
    'name': 'Poland',
    'iso2': 'pl',
    'dialCode': '48',
    'mask': '...-...-...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pt': {
    'name': 'Portugal',
    'iso2': 'pt',
    'dialCode': '351',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'pr': {
    'name': 'Puerto Rico',
    'iso2': 'pr',
    'dialCode': '1',
    'mask': '',
    'priority': 3,
    'regionCodes': ['787', '939'],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'qa': {
    'name': 'Qatar',
    'iso2': 'qa',
    'dialCode': '974',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  're': {
    'name': 'R\u00e9union',
    'iso2': 're',
    'dialCode': '262',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ro': {
    'name': 'Romania',
    'iso2': 'ro',
    'dialCode': '40',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ru': {
    'name': 'Russia',
    'iso2': 'ru',
    'dialCode': '7',
    'mask': '(...) ...-..-..',
    'priority': 0,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'rw': {
    'name': 'Rwanda',
    'iso2': 'rw',
    'dialCode': '250',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kn': {
    'name': 'Saint Kitts and Nevis',
    'iso2': 'kn',
    'dialCode': '1869',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lc': {
    'name': 'Saint Lucia',
    'iso2': 'lc',
    'dialCode': '1758',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'vc': {
    'name': 'Saint Vincent and the Grenadines',
    'iso2': 'vc',
    'dialCode': '1784',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ws': {
    'name': 'Samoa',
    'iso2': 'ws',
    'dialCode': '685',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sm': {
    'name': 'San Marino',
    'iso2': 'sm',
    'dialCode': '378',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'st': {
    'name': 'S\u00e3o Tom\u00e9 and Pr\u00edncipe',
    'iso2': 'st',
    'dialCode': '239',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sa': {
    'name': 'Saudi Arabia',
    'iso2': 'sa',
    'dialCode': '966',
    'mask': '...........',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sn': {
    'name': 'Senegal',
    'iso2': 'sn',
    'dialCode': '221',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'rs': {
    'name': 'Serbia',
    'iso2': 'rs',
    'dialCode': '381',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sc': {
    'name': 'Seychelles',
    'iso2': 'sc',
    'dialCode': '248',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sl': {
    'name': 'Sierra Leone',
    'iso2': 'sl',
    'dialCode': '232',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sg': {
    'name': 'Singapore',
    'iso2': 'sg',
    'dialCode': '65',
    'mask': '....-....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sk': {
    'name': 'Slovakia',
    'iso2': 'sk',
    'dialCode': '421',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'si': {
    'name': 'Slovenia',
    'iso2': 'si',
    'dialCode': '386',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sb': {
    'name': 'Solomon Islands',
    'iso2': 'sb',
    'dialCode': '677',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'so': {
    'name': 'Somalia',
    'iso2': 'so',
    'dialCode': '252',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'za': {
    'name': 'South Africa',
    'iso2': 'za',
    'dialCode': '27',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'kr': {
    'name': 'South Korea',
    'iso2': 'kr',
    'dialCode': '82',
    'mask': '... .... ....',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ss': {
    'name': 'South Sudan',
    'iso2': 'ss',
    'dialCode': '211',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'es': {
    'name': 'Spain',
    'iso2': 'es',
    'dialCode': '34',
    'mask': '... ... ...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'lk': {
    'name': 'Sri Lanka',
    'iso2': 'lk',
    'dialCode': '94',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sd': {
    'name': 'Sudan',
    'iso2': 'sd',
    'dialCode': '249',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sr': {
    'name': 'Suriname',
    'iso2': 'sr',
    'dialCode': '597',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sz': {
    'name': 'Swaziland',
    'iso2': 'sz',
    'dialCode': '268',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'se': {
    'name': 'Sweden',
    'iso2': 'se',
    'dialCode': '46',
    'mask': '(...) ...-...',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ch': {
    'name': 'Switzerland',
    'iso2': 'ch',
    'dialCode': '41',
    'mask': '.. ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'sy': {
    'name': 'Syria',
    'iso2': 'sy',
    'dialCode': '963',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tw': {
    'name': 'Taiwan',
    'iso2': 'tw',
    'dialCode': '886',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tj': {
    'name': 'Tajikistan',
    'iso2': 'tj',
    'dialCode': '992',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tz': {
    'name': 'Tanzania',
    'iso2': 'tz',
    'dialCode': '255',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'th': {
    'name': 'Thailand',
    'iso2': 'th',
    'dialCode': '66',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tl': {
    'name': 'Timor-Leste',
    'iso2': 'tl',
    'dialCode': '670',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tg': {
    'name': 'Togo',
    'iso2': 'tg',
    'dialCode': '228',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'to': {
    'name': 'Tonga',
    'iso2': 'to',
    'dialCode': '676',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tt': {
    'name': 'Trinidad and Tobago',
    'iso2': 'tt',
    'dialCode': '1868',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tn': {
    'name': 'Tunisia',
    'iso2': 'tn',
    'dialCode': '216',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tr': {
    'name': 'Turkey',
    'iso2': 'tr',
    'dialCode': '90',
    'mask': '... ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tm': {
    'name': 'Turkmenistan',
    'iso2': 'tm',
    'dialCode': '993',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'tv': {
    'name': 'Tuvalu',
    'iso2': 'tv',
    'dialCode': '688',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ug': {
    'name': 'Uganda',
    'iso2': 'ug',
    'dialCode': '256',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ua': {
    'name': 'Ukraine',
    'iso2': 'ua',
    'dialCode': '380',
    'mask': '(..) ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ae': {
    'name': 'United Arab Emirates',
    'iso2': 'ae',
    'dialCode': '971',
    'mask': '(.) ...-....',
    'priority': 1,
    'regionCodes': ['1', '2', '3', '4', '6', '7', '9', '50', '52', '54', '55', '56', '58'],
    'minLocalPhonePartLength': 7,
    'maxLocalPhonePartLength': 7,
  },
  'gb': {
    'name': 'United Kingdom',
    'iso2': 'gb',
    'dialCode': '44',
    'mask': '.... ......',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'us': {
    'name': 'United States',
    'iso2': 'us',
    'dialCode': '1',
    'mask': '(...) ...-....',
    'priority': 0,
    'regionCodes': [
      '907',
      '205',
      '251',
      '256',
      '334',
      '479',
      '501',
      '870',
      '480',
      '520',
      '602',
      '623',
      '928',
      '209',
      '213',
      '310',
      '323',
      '408',
      '415',
      '510',
      '530',
      '559',
      '562',
      '619',
      '626',
      '650',
      '661',
      '707',
      '714',
      '760',
      '805',
      '818',
      '831',
      '858',
      '909',
      '916',
      '925',
      '949',
      '951',
      '303',
      '719',
      '970',
      '203',
      '860',
      '202',
      '302',
      '239',
      '305',
      '321',
      '352',
      '386',
      '407',
      '561',
      '727',
      '772',
      '813',
      '850',
      '863',
      '904',
      '941',
      '954',
      '229',
      '404',
      '478',
      '706',
      '770',
      '912',
      '808',
      '319',
      '515',
      '563',
      '641',
      '712',
      '208',
      '217',
      '309',
      '312',
      '618',
      '630',
      '708',
      '773',
      '815',
      '847',
      '219',
      '260',
      '317',
      '574',
      '765',
      '812',
      '316',
      '620',
      '785',
      '913',
      '270',
      '502',
      '606',
      '859',
      '225',
      '318',
      '337',
      '504',
      '985',
      '413',
      '508',
      '617',
      '781',
      '978',
      '301',
      '410',
      '207',
      '231',
      '248',
      '269',
      '313',
      '517',
      '586',
      '616',
      '734',
      '810',
      '906',
      '989',
      '218',
      '320',
      '507',
      '612',
      '651',
      '763',
      '952',
      '314',
      '417',
      '573',
      '636',
      '660',
      '816',
      '228',
      '601',
      '662',
      '406',
      '252',
      '336',
      '704',
      '828',
      '910',
      '919',
      '701',
      '308',
      '402',
      '603',
      '201',
      '609',
      '732',
      '856',
      '908',
      '973',
      '505',
      '575',
      '702',
      '775',
      '212',
      '315',
      '516',
      '518',
      '585',
      '607',
      '631',
      '716',
      '718',
      '845',
      '914',
      '216',
      '330',
      '419',
      '440',
      '513',
      '614',
      '740',
      '937',
      '405',
      '580',
      '918',
      '503',
      '541',
      '215',
      '412',
      '570',
      '610',
      '717',
      '724',
      '814',
      '401',
      '803',
      '843',
      '864',
      '605',
      '423',
      '615',
      '731',
      '865',
      '901',
      '931',
      '210',
      '214',
      '254',
      '281',
      '325',
      '361',
      '409',
      '432',
      '512',
      '713',
      '806',
      '817',
      '830',
      '903',
      '915',
      '936',
      '940',
      '956',
      '972',
      '979',
      '435',
      '801',
      '276',
      '434',
      '540',
      '703',
      '757',
      '804',
      '802',
      '206',
      '253',
      '360',
      '425',
      '509',
      '262',
      '414',
      '608',
      '715',
      '920',
      '304',
      '307',
    ],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'uy': {
    'name': 'Uruguay',
    'iso2': 'uy',
    'dialCode': '598',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'uz': {
    'name': 'Uzbekistan',
    'iso2': 'uz',
    'dialCode': '998',
    'mask': '.. ... .. ..',
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'vu': {
    'name': 'Vanuatu',
    'iso2': 'vu',
    'dialCode': '678',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'va': {
    'name': 'Vatican City',
    'iso2': 'va',
    'dialCode': '39',
    'mask': '.. .... ....',
    'priority': 1,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  've': {
    'name': 'Venezuela',
    'iso2': 've',
    'dialCode': '58',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'vn': {
    'name': 'Vietnam',
    'iso2': 'vn',
    'dialCode': '84',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'ye': {
    'name': 'Yemen',
    'iso2': 'ye',
    'dialCode': '967',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'zm': {
    'name': 'Zambia',
    'iso2': 'zm',
    'dialCode': '260',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
  'zw': {
    'name': 'Zimbabwe',
    'iso2': 'zw',
    'dialCode': '263',
    'mask': null,
    'priority': null,
    'regionCodes': [],
    'minLocalPhonePartLength': null,
    'maxLocalPhonePartLength': null,
  },
}
