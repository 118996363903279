import { Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { Link, useRedirect } from '../../../services/router'
import { useDelayedQuery, useQuery, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { Separator } from '../../../components/app-layout/separator/separator'
import { UserCard } from './components/user-card/user-card'
import newUserImg from '../../../components/assets/new-user.svg'
import './users-list.scss'
import { Tabs } from '../../../components/tabs/tabs'
import { UnconfirmedUserCard } from './components/unconfirmed-user-card/unconfirmed-user-card'
import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const UsersListPage = withErrorBoundary(
  observer(({ showOnlyUnconfirmed }: { showOnlyUnconfirmed: boolean }) => {
    const {
      pointsOfContact,
      getPointsOfContact,
      unconfirmedPointsOfContact,
      getUnconfirmedPointsOfContact,
      getLocations,
    } = useStore().data

    const { t } = useTranslation()
    const redirect = useRedirect()

    const tabs = [
      {
        title: t('user_management.users_tab_active_users'),
        onClick: () => redirect(routes.users, {}),
        isActive: !showOnlyUnconfirmed,
      },
      {
        title: t('user_management.users_tab_invitations'),
        onClick: () => redirect(routes.unconfirmedUsers, {}),
        isActive: showOnlyUnconfirmed,
      },
    ]

    useQuery(getLocations)

    const { enableQuery: getPointsOfContactRequest } = useDelayedQuery(() =>
      getPointsOfContact({ locationId: undefined }),
    )
    const { enableQuery: getUnconfirmedPointsOfContactRequest } = useDelayedQuery(() =>
      getUnconfirmedPointsOfContact({ locationId: undefined }),
    )

    useEffect(() => {
      if (showOnlyUnconfirmed) {
        getUnconfirmedPointsOfContactRequest()
      } else {
        getPointsOfContactRequest()
      }
    }, [showOnlyUnconfirmed])

    return (
      <div className="UsersList">
        <Header title={t('user_management.users')} />
        <Separator.Vertical height={31} />
        <div className="UsersList__container">
          <Tabs tabs={tabs} />
          <Separator.Vertical height={24} />
          {!showOnlyUnconfirmed &&
            pointsOfContact?.map((user, index) => (
              <Fragment key={user.id}>
                <UserCard
                  id={user.id}
                  name={`${user.first_name} ${user.last_name}`}
                  imageURL={user.image}
                  roles={user.roles}
                  locations={user.client_locations ?? undefined}
                />
                {index !== pointsOfContact.length - 1 && <Separator.Vertical height={18} />}
              </Fragment>
            ))}
          {showOnlyUnconfirmed &&
            unconfirmedPointsOfContact?.map((user, index) => (
              <Fragment key={user.email}>
                <UnconfirmedUserCard
                  email={user.email}
                  roles={user.roles}
                  locations={user.client_locations ?? undefined}
                />
                {index !== unconfirmedPointsOfContact.length - 1 && <Separator.Vertical height={18} />}
              </Fragment>
            ))}
          <Separator.Vertical height={39} />
          <Link route={routes.createUser} params={{}} className="UsersList__newUserButton">
            <img src={newUserImg} alt="" className="UsersList__newUserButton__img" />
            <Separator.Horizontal width={15} />
            <span className="UsersList__newUserButton__label">{t('user_management.add_user')}</span>
          </Link>
          <Separator.Vertical height={5} />
          <Link route={routes.inviteUser} params={{}} className="UsersList__inviteContainer">
            <span className="UsersList__inviteContainer__orLabel">{t('user_management.or')}</span>
            <span className="UsersList__inviteContainer__inviteLabel">{t('user_management.invite_existing_user')}</span>
          </Link>
        </div>
      </div>
    )
  }),
)
