import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/all-jobs'
import '../onboarding.scss'

export const allJobsOnboarding: OnboardingContent = {
  title: t('all_jobs_onboarding.title'),
  paths: [routes.allJobs.path],
  pages: [
    {
      title: t('all_jobs_onboarding.jobs_list_title'),
      content: [
        { image: { src: images.allJobsListImg, className: 'Onboarding__bigImg' } },
        { description: t('all_jobs_onboarding.jobs_list') },
      ],
    },
    {
      title: t('all_jobs_onboarding.job_details_title'),
      content: [
        { image: { src: images.jobSingleShiftTools, className: 'Onboarding__bigImg' } },
        { description: t('all_jobs_onboarding.job_details') },
      ],
    },
    {
      title: t('all_jobs_onboarding.change_schedule_title'),
      content: [
        { image: { src: images.jobAllShiftsToolsImg, className: 'Onboarding__bigImg' } },
        { description: t('all_jobs_onboarding.change_schedule') },
      ],
    },
    {
      title: t('all_jobs_onboarding.bulk_schedule_change_title'),
      content: [
        { image: { src: images.changeScheduleImg, className: 'Onboarding__bigImg' } },
        { description: t('all_jobs_onboarding.bulk_schedule_change') },
      ],
    },
    {
      title: t('all_jobs_onboarding.cancel_shift_title'),
      content: [
        { image: { src: images.cancelShiftImg, className: 'Onboarding__bigImg' } },
        { description: t('all_jobs_onboarding.cancel_shift') },
      ],
    },
  ],
}
