import { useState } from 'react'
import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { routes } from '../../../routes'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { generateDeviceId } from '../../../utils/device-id-generator'
import { Link } from '../../../services/router'
import { FieldError } from '../../../components/form/field-error/field-error'
import TextInput from '../../../components/text-input/text-input'
import { Separator } from '../../../components/app-layout/separator/separator'
import appLogoImg from '../../../components/assets/app-logo.svg'
import './verify-email.scss'

export const VerifyEmail = observer(({ email }: { email: string }) => {
  const store = useStore()
  const [codeSentAt, setCodeSentAt] = useState<number | null>(null)

  const [verifyResult, verify] = useMutation(store.data.verifyEmailByCode, {
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    onError: (err) => {
      if (err.type === ApiErrorTypes.NotFound) {
        alert('No such code for the user')
      } else if (err.type !== ApiErrorTypes.ClientError) {
        alert(err.data.message)
      }
    },
    onSuccess: () => {
      setCodeSentAt(Date.now())
    },
  })

  const [, resendCode] = useMutation(store.data.resendEmailVerification, {
    filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound, ApiErrorTypes.ServerError],
    onError: (err) => {
      alert(err.data.message)
    },
  })

  const errors = verifyResult.error?.data.errors

  const { t } = useTranslation()

  return (
    <div className="VerifyEmail">
      <img src={appLogoImg} className="VerifyEmail__logo" />
      <Separator.Vertical height={45} />
      <h1 className="VerifyEmail__title">{t('verify_email.title')}</h1>
      <Separator.Vertical height={5} />
      <h2 className="VerifyEmail__subtitle">{t('verify_email.description')}</h2>
      <Separator.Vertical height={53} />
      <p className="VerifyEmail__subtitle">{t('verify_email.we_sent_code_to_email', { email })}</p>
      <Separator.Vertical height={43} />
      <Formik
        initialValues={{ token: '', device_id: generateDeviceId(), firebase_token: '11' }}
        validationSchema={Yup.object().shape({
          token: Yup.string().required(t('verify_email.please_enter_code')),
        })}
        onSubmit={codeSentAt === null ? verify : () => resendCode({ email: email })}
      >
        {(formikProps) => (
          <Form noValidate>
            <FieldError errors={errors?.token}>
              <TextInput
                type="email"
                label={t('verify_email.verification_code')}
                formikProps={formikProps}
                formikKey="token"
              />
            </FieldError>
            <Separator.Vertical height={25} />
            <button type="submit" className="VerifyEmail__button">
              {t('verify_email.verify')}
            </button>
          </Form>
        )}
      </Formik>
      {codeSentAt !== null && (
        <>
          <Separator.Vertical height={25} />
          <p className="VerifyEmail__subtitle VerifyEmail__resend-description">
            {t('verify_email.did_not_receive_email_question')}
          </p>
        </>
      )}
      <Separator.Vertical height={26} />
      <p className="VerifyEmail__subtitle VerifyEmail__resend-description">
        {t('verify_email.not_your_email_question')}{' '}
        {/* replace the route with changeEmail when the screen is implemented */}
        <Link route={routes.login} params={{}} className="VerifyEmail__change-email-link">
          {t('verify_email.change_email')}
        </Link>
      </p>
      <Separator.Vertical height={25} />
    </div>
  )
})
