import { types, addMiddleware, getParent } from 'mobx-state-tree'

import { t } from '../../../../../translations'
import { isTimeLessThan24HoursFromNow, getCurrentDateIso8601 } from '../../../../../utils/datetime-utils'
import { DraftJobFields } from '../fields'
import { DraftJobInstance } from '../../draft-job'
import { JobScheduleInstance } from './'

export const SameTimeScheduleModel = types
  .model('JobDatesSameTimeSchedule', {
    all_days_have_same_time: types.maybeNull(types.boolean),
    all_days_have_same_time_error: types.maybeNull(types.string),
    same_start_time: types.maybeNull(types.string),
    same_start_time_error: types.maybeNull(types.string),
    same_end_time: types.maybeNull(types.string),
    same_end_time_error: types.maybeNull(types.string),
    is_less_than_24h: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get didSelectTimingType() {
      return self.all_days_have_same_time !== null
    },

    get allDaysHaveSameTime() {
      return self.all_days_have_same_time
    },

    get sameTimePromptHasErrors() {
      return self.all_days_have_same_time_error !== null
    },

    get startTimeHasErrors() {
      return self.same_start_time_error !== null
    },

    get endTimeHasErrors() {
      return self.same_end_time_error !== null
    },
  }))
  .actions(self => ({
    setStartTime(startTime: string | null) {
      self.same_start_time = startTime
      self.same_start_time_error = null

      const dateBoundaries = getParent<JobScheduleInstance>(self).dateBoundaries
      // check if submitted timing applies to tomorrow
      if (dateBoundaries.start_date === getCurrentDateIso8601()) {
        // check if start time is not earlier than 24 hours from now
        self.is_less_than_24h = isTimeLessThan24HoursFromNow(self.same_start_time as string)
      }
    },

    setEndTime(endTime: string | null) {
      self.same_end_time = endTime
      self.same_end_time_error = null
    },

    reset() {
      self.all_days_have_same_time = null
      self.all_days_have_same_time_error = null
      self.same_start_time = null
      self.same_start_time_error = null
      self.same_end_time = null
      self.same_end_time_error = null
    },
  }))
  .actions(self => ({
    setIsSameTiming(isSameTiming: boolean) {
      self.reset()
      self.all_days_have_same_time = isSameTiming
    },

    validateTypeSelection() {
      if (self.allDaysHaveSameTime === null) {
        self.all_days_have_same_time_error = t('job_draft_details.same_time_prompt_required')
        return false
      } else {
        self.all_days_have_same_time_error = null
        return true
      }
    },

    validateStartTime() {
      if (self.all_days_have_same_time && self.same_start_time === null) {
        self.same_start_time_error = t('job_draft_details.start_time_required')
        return false
      } else {
        self.same_start_time_error = null
        return true
      }
    },

    validateEndTime() {
      if (self.all_days_have_same_time && self.same_end_time === null) {
        self.same_end_time_error = t('job_draft_details.end_time_required')
        return false
      } else {
        self.same_end_time_error = null
        return true
      }
    },
  }))
  .actions(self => ({
    validateTimings() {
      const isStartTimeValid = self.validateStartTime()
      const isEndTimeValid = self.validateEndTime()
      return isStartTimeValid && isEndTimeValid
    },
  }))

export function createSameTimeScheduleMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.sameTimeSchedule) {
      return abort(targetInstance.schedule.sameTimeSchedule.validateTimings())
    } else if (call.type === 'action' && call.args[0] === DraftJobFields.sameOrDynamicTimingPrompt) {
      return abort(targetInstance.schedule.sameTimeSchedule.validateTypeSelection())
    } else if (call.type === 'action' && (call.name === 'setStartDate' || call.name === 'setEndDate')) {
      targetInstance.clearSameTimeFields()
    }
    return next(call)
  })
}
