import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'

import { useRedirect } from '../../../services/router'
import { BusinessLocation, BusinessLocationForm } from '../../../models/location'
import { Header, Separator } from '../../../components/app-layout'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { useStore, useMutation, useQuery } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { LocationForm } from '../location-form/location-form'
import './edit-location.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

type EditLocationPageProps = {
  id: string
}

const EditLocationPage = withErrorBoundary(
  observer((props: EditLocationPageProps) => {
    const store = useStore()
    const navigate = useRedirect()
    const navigateToLocationsList = () => navigate(routes.locations, {})
    const getLocationQuery = useQuery(() => store.data.getLocation(props.id))
    const [saveResult, save] = useMutation((data: BusinessLocationForm) => store.data.updateLocation(props.id, data), {
      onSuccess: navigateToLocationsList,
      filterError: [ApiErrorTypes.ClientError],
    })
    const [deleteLocationResult, deleteLocation] = useMutation(() => store.data.deleteLocation(props.id), {
      onSuccess: navigateToLocationsList,
    })
    const location: BusinessLocation = store.data.locations.get(props.id) as BusinessLocation

    const { t } = useTranslation()

    return (
      <div className="EditLocationPage">
        <Header title={t('edit_location.title')} showBack={true} />
        <Separator.Vertical height={16} />
        <div className="EditLocationPage__container">
          {getLocationQuery.isLoading || saveResult.isLoading || deleteLocationResult.isLoading ? (
            <LoadingSpinner />
          ) : (
            <LocationForm
              onDeleteLocation={() => {
                deleteLocation(props.id)
              }}
              onSubmit={save}
              initialValues={{ ...location }}
              showSubmit
              showLocationNameField
              showAddressDetailsField
              showImageField
              showCheckInRestrictionsBlock
            />
          )}
        </div>
      </div>
    )
  }),
)

export default EditLocationPage
