import { Instance, types, cast, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const DocumentsModel = types
  .model('Documents', {
    use_documents: types.optional(types.boolean, false),
    list: types.maybeNull(types.array(types.number)),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },

    has(id: number) {
      return Boolean(self.list?.includes(id))
    },
  }))
  .actions(self => ({
    clear() {
      self.list?.clear()
    },
  }))
  .actions(self => ({
    useDocuments(documentsRequired: boolean) {
      self.use_documents = documentsRequired
      if (!documentsRequired) {
        self.clear()
      }
      self.error = null
    },

    add(id: number) {
      if (self.list) {
        self.list.push(id)
      } else {
        self.list = cast([id])
      }
      self.error = null
    },

    remove(id: number) {
      self.list = cast(self.list?.filter(item => item !== id))
      self.error = null
    },

    set(documents: number[]) {
      self.list = cast(documents)
      self.error = null
    },

    validate() {
      if (self.use_documents && (self.list === null || self.list.length === 0)) {
        self.error = t('job_draft_details.documents_required')
        return false
      }
      self.error = null
      return true
    },
  }))

export function createDocumentsMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.documents) {
      return abort(targetInstance.documents.validate())
    }
    return next(call)
  })
}

export type DocumentsInstance = Instance<typeof DocumentsModel>
