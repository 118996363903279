import './image-preview.scss'
import closeIcon from '../assets/cross-icon-black.svg'

interface ImagePreviewProps {
  onDeletedSelectedImage: () => void
  imageUrl: File | null | undefined | string
}

const ImagePreview = ({ imageUrl, onDeletedSelectedImage }: ImagePreviewProps) => {
  return (
    <div className="ImagePreview">
      <button onClick={onDeletedSelectedImage} type="button" className="ImagePreview__close">
        <img src={closeIcon} />
      </button>
      <img className="ImagePreview__image" src={String(imageUrl)} />
    </div>
  )
}

export default ImagePreview
