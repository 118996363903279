import { useTranslation } from 'react-i18next'

import confirmed from '../../../components/assets/confirmed.svg'
import { Separator } from '../../../components/app-layout/separator/separator'
import { ButtonLink } from '../../../components/button-link/button-link'
import { routes } from '../../../routes'
import './order-confirmed.scss'
import { OrderState } from '../../../models/order/list-item-orders'

export const OrderConfirmedPage = () => {
  const { t } = useTranslation()

  return (
    <div className="OrderConfirmed">
      <Separator.Spacer minHeight={20} />
      <div className="OrderConfirmed__innerContainer">
        <Separator.Spacer />
        <img src={confirmed} className="OrderConfirmed__innerContainer__img" />
        <Separator.Spacer flex={0.2} />
        <h2 className="OrderConfirmed__innerContainer__title">{t('order_confirmed.title')}</h2>
        <p className="OrderConfirmed__innerContainer__description">{t('order_confirmed.description')}</p>
        <Separator.Spacer />
        <ButtonLink
          url={routes.orders}
          title={t('order_confirmed.done')}
          params={{ ordersState: OrderState.Unconfirmed }}
          className="OrderConfirmed__innerContainer__closeButton"
        />
        <Separator.Vertical height={40} />
      </div>
      <Separator.Spacer minHeight={20} />
    </div>
  )
}
