import { Trans, useTranslation } from 'react-i18next'
import { Form } from 'formik'

import TextInput from '../../../../../components/text-input/text-input'
import { Separator } from '../../../../../components/app-layout'
import SelectInput from '../../../../../components/select-input/select-input'
import UploadDocument from '../../../../../components/upload-document/upload-document'
import { TERMS_AND_CONDITIONS_TYPE_PRIVACY_POLICY_CLIENT } from '../../../../../constants'
import { InputProps } from './types'
import LocationAutoComplete from '../../../../../components/location-auto-complete/location-auto-complete'

export const CorporateFormDefault = ({
  formikProps,
  regionsData,
  maxFileSizeMB,
  showVATErrorMessage,
  onSelectVAT,
  errorMessages,
  togglePrivacyPolicy,
  privacyPolicyAccepted,
  vat,
  apiHost,
}: InputProps) => {
  const { t } = useTranslation()

  return (
    <Form noValidate>
      <TextInput label={t('complete_registration.company_name')} formikProps={formikProps} formikKey="company_name" />
      <Separator.Vertical height={10} />
      <TextInput
        label={t('complete_registration.trade_name_as_license')}
        formikProps={formikProps}
        formikKey="trade_name"
      />
      <Separator.Vertical height={10} />
      <TextInput
        type="number"
        label={t('complete_registration.trade_license_number')}
        formikProps={formikProps}
        formikKey="license_number"
      />
      <Separator.Vertical height={10} />
      <LocationAutoComplete
        type="text"
        label={t('complete_registration.legal_address')}
        autocompleteType="address"
        onPlaceSelected={p => {
          const address = p.formatted_address
          const city = p.address_components.find(component => component.types.includes('locality'))?.long_name

          formikProps.setFieldValue('address', address)
          if (Boolean(city)) {
            formikProps.setFieldValue('region', city)
          }
        }}
        formikProps={formikProps}
        formikKey="address"
      />
      <Separator.Vertical height={10} />
      <TextInput label={t('complete_registration.po_box')} formikProps={formikProps} formikKey="po_box" />
      <Separator.Vertical height={10} />
      <TextInput label={t('complete_registration.free_zone')} formikProps={formikProps} formikKey="free_zone" />
      <Separator.Vertical height={10} />
      <SelectInput
        options={[
          { name: t('complete_registration.yes'), id: 1 },
          { name: t('complete_registration.no'), id: 0 },
        ]}
        label={t('complete_registration.lpo_required')}
        formikKey="is_lpo_required"
        formikProps={formikProps}
      />
      <Separator.Vertical height={40} />
      <h4 className="CompleteRegisteration__uploadInputLable">{t('complete_registration.vat_certificate')}</h4>
      <Separator.Vertical height={15} />
      <UploadDocument
        maxFileSize={maxFileSizeMB}
        showErrorRequiredMessage={showVATErrorMessage}
        name="vat"
        hasFile={Boolean(vat)}
        onSelectFile={onSelectVAT}
        placeholder={t('complete_registration.upload_vat')}
      />
      {errorMessages.map((error, i) => (
        <p key={i} className="CompleteRegisteration__errorMessage">
          {error}
        </p>
      ))}
      <Separator.Vertical height={15} />
      <div className="CompleteRegisteration__termsConditions">
        <input
          type="checkbox"
          onClick={togglePrivacyPolicy}
          checked={privacyPolicyAccepted}
          className="CompleteRegisteration__termsConditions__checkbox"
        />
        <Separator.Horizontal width={10} />
        <div className="CompleteRegisteration__termsConditions__container">
          <Trans i18nKey="complete_registration.i_agree_with_terms">
            <span className="CompleteRegisteration__termsConditions__container__label">I agree to the</span>
            <a
              href={`${apiHost}client/profile/sla?type=html&tc_type=${TERMS_AND_CONDITIONS_TYPE_PRIVACY_POLICY_CLIENT}`}
              target="_blank"
              rel="noreferrer"
              className="CompleteRegisteration__termsConditions__container__link"
            >
              Privacy Policy
            </a>
          </Trans>
        </div>
      </div>
      <Separator.Vertical height={5} />
      <button disabled={!privacyPolicyAccepted} className="CompleteRegisteration__submitButton" type="submit">
        {t('complete_registration.continue')}
      </button>
    </Form>
  )
}
