import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Heart } from '../heart'
import { ListItemProps, pastWorkersStore } from '../../../../models-ui/past-workers'
import { Rating } from '../rating/rating'
import ApplicantDetails from '../../../applicants-review/applicant-details/applicant-details'
import { Popup } from '../../../../components/popup/popup'
import { NoteInput } from '../note-input/note-input'

export const CardItem = observer(({ sp, setSPNote, setSPLastJobRating, toggleIsFavorite }: ListItemProps) => {
  const { t } = useTranslation()

  const [isEditRatingMode, setIsEditRatingMode] = useState(sp.client_rating_for_last_job === null)
  const [isProfileShown, setIsProfileShown] = useState(false)

  const handleSetRating = (value: number) => {
    setSPLastJobRating({
      spId: sp.id,
      rating: value,
      locationId: pastWorkersStore.searchFilters.locationId,
      workCategoryId: pastWorkersStore.searchFilters.designationId,
    })
    setIsEditRatingMode(false)
  }

  return (
    <div className="WorkersList__item">
      <div className="WorkersList__item__header">
        <div className="WorkersList__item__header__left" onClick={() => setIsProfileShown(true)}>
          <img className="WorkersList__item__header__image" src={sp.image_url ?? undefined} />
          <div className="WorkersList__item__header__info">
            <span className="WorkersList__item__header__info__minor">{t('common.id_n', { id: sp.id })}</span>
            <span className="WorkersList__item__header__info__primary">{sp.name}</span>
            <span className="WorkersList__item__header__info__secondary">{sp.designations.join(', ')}</span>
          </div>
        </div>
        <div className="WorkersList__item__header__right">
          <div className="WorkersList__item__header__actionsContainer">
            <Heart isActive={sp.is_favorite} onClick={() => toggleIsFavorite({ spId: sp.id })} />
          </div>
        </div>
      </div>
      <div className="WorkersList__item__body" onClick={() => setIsProfileShown(true)}>
        <div className="WorkersList__item__body__row">
          <div className="WorkersList__item__body__info">
            <span className="WorkersList__item__body__info__minor">{t('staff.hours_worked')}</span>
            <span className="WorkersList__item__body__info__primary">
              {t('staff.n_hours', { hoursAmount: sp.hours_amount_worked_for_client })}
            </span>
            <span className="WorkersList__item__body__info__minor">
              {t('staff.n_shifts', { shiftsAmount: sp.shifts_amount_worked_for_client })}
            </span>
          </div>
          <div className="WorkersList__item__body__info">
            <span className="WorkersList__item__body__info__minor">{t('staff.last_job')}</span>
            <span className="WorkersList__item__body__info__primary">{sp.last_job_end_date}</span>
            <span className="WorkersList__item__body__info__minor">{t('common.job_n', { id: sp.last_job_id })}</span>
          </div>
        </div>
        <div className="WorkersList__item__body__row">
          <div className="WorkersList__item__body__info">
            <span className="WorkersList__item__body__info__minor">{t('staff.locations_worked')}</span>
            <span className="WorkersList__item__body__info__primary">{sp.locations[0]}</span>
            {sp.locations.length > 1 ? (
              <span className="WorkersList__item__body__info__minor">
                {t('staff.plus_n_locations', { locationsAmount: sp.locations.length - 1 })}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <Rating
        isEditRatingMode={isEditRatingMode}
        setIsEditRatingMode={setIsEditRatingMode}
        rating={Number(sp.client_rating_for_last_job)}
        handleSetRating={handleSetRating}
      />
      <div className="WorkersList__item__note">
        <NoteInput
          setSPNote={setSPNote}
          sp={sp}
          className="WorkersList__item__info__textarea"
          placeholder={t('staff.enter_notes_on_worker')}
        />
      </div>
      <Popup
        isActive={isProfileShown}
        content={
          <ApplicantDetails
            jobId={String(sp.last_job_id)}
            applicantId={String(sp.id)}
            applicantName={sp.name}
            showHeader={false}
            showControls={false}
          />
        }
        close={() => setIsProfileShown(false)}
      />
    </div>
  )
})
