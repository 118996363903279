export const Sort = ({ sortDirection }: { sortDirection: 'ASC' | 'DESC' | null }) => {
  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 4L4 1L1 4"
        stroke={sortDirection && sortDirection === 'ASC' ? '#6E6E87' : '#BABED6'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 12L4 15L7 12"
        stroke={sortDirection && sortDirection === 'DESC' ? '#6E6E87' : '#BABED6'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
