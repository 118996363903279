export function getArrayWithItemAtBeginning<T>(arr: T[], index: number) {
  if (index < 0 || index >= arr.length) {
    return arr // Return the original array if the index is out of range
  }

  // Create a copy of the given array using spread operator
  const copiedArr = [...arr]

  // Get the object at the given index
  const objectToMove = copiedArr.splice(index, 1)[0]

  // Add the object to the beginning of the copied array
  copiedArr.unshift(objectToMove)

  // Return the modified copy of the array
  return copiedArr
}

export function orderArrayBasedOnArray<T>(originalArr: T[], orderArr: T[]) {
  const orderMap = new Map()
  orderArr.forEach((element, index) => {
    orderMap.set(element, index)
  })

  originalArr.sort((a, b) => {
    const indexA = orderMap.get(a)
    const indexB = orderMap.get(b)

    if (indexA === undefined) return 1
    if (indexB === undefined) return -1

    return indexA - indexB
  })

  return originalArr
}

export function toCommaSeparatedString(arr: string[]) {
  return arr.join(', ')
}
