import { useTranslation } from 'react-i18next'
import { CountryData } from 'ogram-react-phone-input-2'
import { default as OgramPhoneInput } from 'ogram-react-phone-input-2'

import { config, masks, regionCodes } from './config'
import { getCurrentMarketConfig } from '../../services/market-service'
import './phone-input.scss'

type InputProps = {
  phone: string
  handlePhoneChange: (value: string) => void
  setSelectedCountry: (value: string) => void
}

export const PhoneInput = ({ phone, handlePhoneChange, setSelectedCountry }: InputProps) => {
  const { t } = useTranslation()

  const marketCode = getCurrentMarketConfig().iso2Code

  return (
    <OgramPhoneInput
      defaultMask={config.defaultMask}
      onMount={(value: string, data: CountryData) => {
        setSelectedCountry(data.countryCode)
      }}
      onCountryChange={(country: { iso2: string }) => {
        setSelectedCountry(country.iso2)
      }}
      alwaysShowPlaceholderOnEmptyValue
      containerClass="SignUp__phoneContainer"
      inputClass="SignUp__phone"
      buttonClass="SignUp__phoneDropdownButton"
      placeholder={t('sign_up.phone')}
      value={phone}
      onChange={handlePhoneChange}
      country={config.defaultCountry}
      countryCodeEditable={false}
      onlyCountries={[marketCode]}
      enableAreaCodes
      enableAreaCodeStretch
      hideAreaCodes
      masks={masks}
      areaCodes={regionCodes}
      disableDropdown={config.enabledCountries.length < 2}
    />
  )
}
