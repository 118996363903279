import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { StickyCallToAction } from '../sticky-call-to-action/sticky-call-to-action'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { withErrorBoundary } from '../error/with-error-boundary'
import { TrackedEvent, trackingService } from '../../services/tracking-service'

export const RatingRequestPanel = withErrorBoundary(
  ({ ratingRequests }: { ratingRequests: { id: number; order_id: number }[] }) => {
    const redirect = useRedirect()
    const { t } = useTranslation()

    useEffect(() => {
      trackingService.track(TrackedEvent.StickyPanelPendingRatingsShown)
    }, [])

    return (
      <section style={{ position: 'absolute', zIndex: 99 }}>
        <StickyCallToAction
          actionTitle={t('rating_request_panel.title')}
          actionSubtitle={t('order.order_number', { id: ratingRequests[0].order_id })}
          action={() => {
            trackingService.track(TrackedEvent.StickyPanelPendingRatingsClicked)
            redirect(routes.jobRatingDetails, { id: ratingRequests[0].id })
          }}
          buttonTitle={t('rating_request_panel.action')}
        />
      </section>
    )
  },
)
