import { types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../../translations'
import { DraftJobFields } from '../fields'
import { DraftJobInstance } from '../../draft-job'
import { isYyyyMmDdBeforeToday } from '../../../../../utils/datetime-utils'

/**
 * Specifies what start and end dates of the job are.
 * End date can be null if selected to have open ended job.
 */
export const DateBoundariesModel = types
  .model('JobDatesDateBoundaries', {
    open_ended: types.optional(types.boolean, false),
    start_date: types.maybeNull(types.string), // YYYY-MM-DD
    end_date: types.maybeNull(types.string), // YYYY-MM-DD
    start_date_error: types.maybeNull(types.string),
    end_date_or_open_ended_error: types.maybeNull(types.string),
  })
  .views(self => ({
    get startDateHasError() {
      return self.start_date_error !== null
    },

    get endDateOrOpenEndedHasError() {
      return self.end_date_or_open_ended_error !== null
    },

    get isValid() {
      return self.start_date && (self.end_date ?? self.open_ended)
    },
  }))
  .actions(self => ({
    setStartDate(startDate: string | null) {
      self.start_date = startDate
      self.start_date_error = null
    },

    setEndDate(endDate: string | null) {
      self.end_date = endDate
      self.end_date_or_open_ended_error = null
    },

    clearErors() {
      self.start_date_error = null
      self.end_date_or_open_ended_error = null
    },

    validateStartDate() {
      if (self.start_date && isYyyyMmDdBeforeToday(self.start_date)) {
        // check if date is at least today or later
        self.start_date_error = t('job_draft_details.start_date_must_be_at_least_today')
        return false
      } else if (self.start_date) {
        self.start_date_error = null
        return true
      } else {
        self.start_date_error = t('job_draft_details.start_date_required')
        return false
      }
    },

    validateEndDate() {
      if (self.end_date && isYyyyMmDdBeforeToday(self.end_date)) {
        self.end_date_or_open_ended_error = t('job_draft_details.end_date_must_be_at_least_today')
        return false
      } else if (self.start_date && self.end_date && new Date(self.end_date) < new Date(self.start_date)) {
        self.end_date = t('job_draft_details.end_date_cannot_be_before_start_date')
        return false
      } else if (!self.end_date && !self.open_ended) {
        self.end_date_or_open_ended_error = t('job_draft_details.end_date_required')
        return false
      } else {
        self.end_date_or_open_ended_error = null
        return true
      }
    },
  }))
  .actions(self => ({
    validate() {
      const isStartDateValid = self.validateStartDate()
      const isEndDateValid = self.validateEndDate()
      return isStartDateValid && isEndDateValid
    },
  }))

export function createDateBoundariesMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.dateBoundaries) {
      return abort(targetInstance.schedule.dateBoundaries.validate())
    }
    return next(call)
  })
}
