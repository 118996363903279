import './invite-referrals.scss'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { InviteReferralLink } from '../../../components/invite-referral-link/invite-referral-link'
import { ButtonPrimary } from '../../../../../components/button/button-primary/button-primary'
import gearImg from './assets/gear.svg'
import { SectionTitle } from '../../../components/section-title/section-title'
import { Separator } from '../../../../../components/app-layout'
import { AcceptTcLink } from '../../../../../components/affiliate/accept-tc-link/accept-tc-link'
import { TcModal } from '../../../../../components/affiliate/tc-modal/tc-modal'
import { useStore } from '../../../../../utils/mst-hooks'

export const InviteReferrals = observer(
  ({
    setShowInvitePopup,
    invitationLink,
  }: {
    setShowInvitePopup: (value: boolean) => void
    invitationLink: string
  }) => {
    const { t } = useTranslation()

    const { apiHost } = useStore().data

    const [showTcModal, setShowTcModal] = useState(false)

    return (
      <div className="InviteReferralsContainer">
        <div className="InviteReferralsContainer__card">
          <span className="InviteReferralsContainer__top__title">{t('common_affiliate.invite_your_staff')}</span>
          <span className="InviteReferralsContainer__top__subtitle">
            {t('common_affiliate.invite_staff_more_details')}
          </span>
          <InviteReferralLink link={invitationLink} />
          <ButtonPrimary
            text={t('common_affiliate.invite_now')}
            action={() => setShowInvitePopup(true)}
            additionalStyle={{ width: '100%', marginTop: '12px' }}
          />
        </div>
        <Separator.Vertical height={20} />
        <SectionTitle icon={gearImg} text={t('affiliate_instructions.how_it_works')} />
        <Separator.Vertical height={8} />
        <div className="InviteReferralsContainer__card InviteReferralsContainer__card--align-left">
          <div className="InviteReferralsContainer__instructionStep">
            <div className="InviteReferralsContainer__instructionStep__left">1</div>
            <div className="InviteReferralsContainer__instructionStep__right">
              <span className="InviteReferralsContainer__instructionStep__right__title">
                {t('affiliate_instructions.instruction_1_title')}
              </span>
              <span className="InviteReferralsContainer__instructionStep__right__subtitle">
                {t('affiliate_instructions.instruction_1_subtitle')}
              </span>
            </div>
          </div>
          <div className="InviteReferralsContainer__instructionStep">
            <div className="InviteReferralsContainer__instructionStep__left">2</div>
            <div className="InviteReferralsContainer__instructionStep__right">
              <span className="InviteReferralsContainer__instructionStep__right__title">
                {t('affiliate_instructions.instruction_2_title')}{' '}
                <span className="InviteReferralsContainer__instructionStep__right__title--highlighted">
                  {t('common.ogram')}
                </span>
              </span>
              <span className="InviteReferralsContainer__instructionStep__right__subtitle">
                {t('affiliate_instructions.instruction_2_subtitle')}
              </span>
            </div>
          </div>
          <div className="InviteReferralsContainer__instructionStep">
            <div className="InviteReferralsContainer__instructionStep__left">3</div>
            <div className="InviteReferralsContainer__instructionStep__right">
              <span className="InviteReferralsContainer__instructionStep__right__title">
                {t('affiliate_instructions.instruction_3_title')}
              </span>
              <span className="InviteReferralsContainer__instructionStep__right__subtitle">
                {t('affiliate_instructions.instruction_3_subtitle')}
              </span>
            </div>
          </div>
          <div className="InviteReferralsContainer__instructionStep">
            <div className="InviteReferralsContainer__instructionStep__left">4</div>
            <div className="InviteReferralsContainer__instructionStep__right">
              <span className="InviteReferralsContainer__instructionStep__right__title">
                {t('affiliate_instructions.instruction_4_title')}
              </span>
              <span className="InviteReferralsContainer__instructionStep__right__subtitle">
                {t('affiliate_instructions.instruction_4_subtitle')}
              </span>
            </div>
          </div>
          <div className="InviteReferralsContainer__acceptTc">
            <AcceptTcLink apiHost={apiHost} />
          </div>
        </div>
        <TcModal onContinue={() => setShowTcModal(false)} showModal={showTcModal} setShowModal={setShowTcModal} />
      </div>
    )
  },
)
