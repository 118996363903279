import 'react-calendar/dist/Calendar.css'
import moment from 'moment/moment'
import Calendar from 'react-calendar'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Select } from '../form/select/select'
import { OgramModal } from '../ogram-modal/ogram-modal'
import './range-calendar.scss'

const months = [
  'calendar.month_january',
  'calendar.month_february',
  'calendar.month_march',
  'calendar.month_april',
  'calendar.month_may',
  'calendar.month_june',
  'calendar.month_july',
  'calendar.month_august',
  'calendar.month_september',
  'calendar.month_october',
  'calendar.month_november',
  'calendar.month_december',
]
const years: { key: number; value: string }[] = []
for (let year = 2014; year <= Number(moment().format('YYYY')); year++) {
  years.push({
    key: year,
    value: String(year),
  })
}

type InputProps = {
  showCalendar: boolean
  setShowCalendar: (value: boolean) => void
  chosenDateRange: [Date, Date]
  setChosenDateRange: (value: [Date, Date]) => void
  onSubmit: () => void
}

export const RangeCalendar = ({
  showCalendar,
  setShowCalendar,
  chosenDateRange,
  setChosenDateRange,
  onSubmit,
}: InputProps) => {
  const { t } = useTranslation()

  const [chosenMonth, setChosenMonth] = useState<number>(Number(moment().format('MM')) - 1)
  const [chosenYear, setChosenYear] = useState<number>(Number(moment().format('YYYY')))
  const [chosenDate, setChosenDate] = useState<Date>(new Date())

  useEffect(() => {
    setChosenDate(new Date(chosenYear, chosenMonth))
  }, [chosenMonth, chosenYear])

  return (
    <OgramModal className="RangerCalendarModal" modalVisible={showCalendar} onClose={() => setShowCalendar(false)}>
      <div className="Calendar__topActions">
        <Select
          label={t('calendar.month')}
          className="Calendar__topActions__monthSelector"
          value={chosenMonth}
          onChange={value => {
            setChosenMonth(value)
          }}
          options={months.map((month, index) => {
            return {
              key: String(index),
              label: t(month),
              value: index,
            }
          })}
        />
        <Select
          label={t('calendar.year')}
          value={chosenYear}
          onChange={value => {
            setChosenYear(value)
          }}
          options={years.map(year => {
            return {
              key: String(year.key),
              label: year.value,
              value: Number(year.key),
            }
          })}
        />
      </div>
      <Calendar
        activeStartDate={chosenDate}
        onChange={(value: Date[]) => {
          setChosenDateRange([value[0], value[1]])
        }}
        showNavigation={false}
        showNeighboringMonth={false}
        value={chosenDateRange}
        selectRange
      />
      <div className="Calendar__bottomActions">
        <button className="Calendar__cancelButton" onClick={() => setShowCalendar(false)}>
          {t('calendar.cancel')}
        </button>
        <button className="Calendar__setButton" onClick={onSubmit}>
          {t('calendar.set')}
        </button>
      </div>
    </OgramModal>
  )
}
