import { useRef, useEffect, Fragment } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Image } from 'antd'

import { useStore } from '../../../../../utils/mst-hooks'
import Rating from '../../../../../components/rating/rating'
import { FieldError } from '../../../../../components/form/field-error/field-error'
import { Separator } from '../../../../../components/app-layout/separator/separator'
import { Quality } from '../quality/quality'
import './rate-card.scss'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

export type RateFields = { rating?: number; comment?: string | null; criterias?: Set<number> }

type Props = {
  name: string
  imageURL: string
  initialRating: number
  rating: number
  comment: string
  criterias: Set<number>
  setFields: (fields: RateFields) => void
  commentError?: string
  ratingError?: string
  criteriasError?: string
}

export const RateCard = withErrorBoundary(
  observer(
    ({
      name,
      imageURL,
      initialRating,
      rating,
      comment,
      criterias,
      setFields,
      ratingError,
      criteriasError,
      commentError,
    }: Props) => {
      const previousRating = useRef(initialRating)
      const initialRatingHasBeenSet = useRef(false)

      const { negativeCriteries, positiveCriteries } = useStore().data

      useEffect(() => {
        if (initialRating !== rating && initialRatingHasBeenSet.current === false) {
          setFields({ rating: initialRating })
          previousRating.current = initialRating
        }
        if (initialRating !== 0) {
          initialRatingHasBeenSet.current = true
        }
        // don't react to internal rating changes, only to outside ratings changes (once).
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [initialRating])

      const { t } = useTranslation()

      const criteriaOptions = [
        ...(rating > 3 ? positiveCriteries : negativeCriteries),
        {
          id: -1,
          name: 'Other',
          type: '',
        },
      ]
      const customCriteriaSelected = Boolean(criterias.has(-1))

      const handleCriteriaPress = (id: number) => {
        const newCriterias = new Set(criterias)
        if (criterias.has(id)) {
          newCriterias.delete(id)
        } else {
          newCriterias.add(id)
        }
        setFields({ criterias: newCriterias })
      }

      const handleRatingChange = (newRating: number) => {
        initialRatingHasBeenSet.current = true
        previousRating.current = rating

        // reset chosen qualities and comment if positive rate becomes negative or vice versa
        if ((newRating > 3 && rating < 4) || (newRating < 4 && rating > 3)) {
          setFields({ rating: newRating, criterias: new Set<number>(), comment: null })
        } else {
          setFields({ rating: newRating })
        }
      }

      const error = ratingError ?? criteriasError ?? commentError

      return (
        <FieldError errors={error} className="RateCard">
          <Separator.Vertical height={18} />
          <div className="RateCard__header">
            <div className="RateCard__header__leftContainer">
              <Image src={imageURL} alt="" className="RateCard__header__leftContainer__photo" />
              <Separator.Horizontal width={10} />
              <span className="RateCard__header__leftContainer__name">{name}</span>
            </div>
            <Rating rating={rating} onRatingChange={handleRatingChange} editable size={17} />
          </div>
          {rating !== 0 && (
            <>
              <Separator.Vertical height={14} />
              <div className="RateCard__qualitiesContainer">
                <Separator.Horizontal width={38} />
                {criteriaOptions.map((criteria, index) => (
                  <Fragment key={criteria.id}>
                    <Quality
                      id={criteria.id}
                      title={criteria.name}
                      selected={criterias.has(criteria.id)}
                      onPress={handleCriteriaPress}
                    />
                    {index !== criteriaOptions.length - 1 && <Separator.Horizontal width={6} />}
                  </Fragment>
                ))}
              </div>
              {customCriteriaSelected && (
                <>
                  <Separator.Vertical height={13} />
                  <textarea
                    value={comment}
                    onChange={ev => setFields({ comment: ev.target.value })}
                    placeholder={t('job_rating.comment')}
                    className="RateCard__textarea"
                  />
                </>
              )}
            </>
          )}
          <Separator.Vertical height={18} />
        </FieldError>
      )
    },
  ),
)
