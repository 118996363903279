import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { ScheduleType as ScheduleTypeEnum } from '../../../../../models-ui/order/store/job-fields/schedule/types'
import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { JobDraftFieldError } from '../error/error'
import './schedule-type.scss'

const ScheduleTypeComponent = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  return (
    <div className="ScheduleType">
      <span className="Popup__title">{t('job_draft_details.schedule_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.schedule_subtitle')}</span>
      <div className="ScheduleType__innerContainer">
        <Button
          title={t('job_draft_details.schedule_i_have_same_weekly')}
          onClick={() => {
            job.schedule.setScheduleType(ScheduleTypeEnum.weekly)
          }}
          className={`ScheduleType__innerContainer__button ${
            job.schedule.scheduleType.value === ScheduleTypeEnum.weekly ? 'active' : undefined
          } ${job.schedule.scheduleType.hasError ? 'error' : ''}`}
        >
          {t('common.yes')}
        </Button>
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.schedule_i_need_schedule_daily')}
          onClick={() => {
            job.schedule.setScheduleType(ScheduleTypeEnum.daily)
          }}
          className={`ScheduleType__innerContainer__button ${
            job.schedule.scheduleType.value === ScheduleTypeEnum.daily ? 'active' : undefined
          } ${job.schedule.scheduleType.hasError ? 'error' : ''}`}
        >
          {t('common.no')}
        </Button>
        {job.schedule.scheduleType.hasError && <JobDraftFieldError error={job.schedule.scheduleType.error} />}
      </div>
    </div>
  )
})
// @ts-ignore
ScheduleTypeComponent.displayName = 'ScheduleTypeComponent'

export const ScheduleType = ScheduleTypeComponent
