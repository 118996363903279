import { observer } from 'mobx-react'
import { Elements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../../components/app-layout'
import { PayWithSavedCardForm } from './components/pay-with-saved-card-form/pay-with-saved-card-form'
import LoadingSpinner from '../../../../../components/loading-spinner/loading-spinner'
import { createStripe, usePaymentIntent, usePendingPaymentOrDenyAccess } from '../../../../../services/stripe'
import { PaymentMethod } from '../../../../../models/payment'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

type InputProps = {
  paymentId: number
  cardId: string
  paymentOptions?: string
}

export const PayWithSavedCard = withErrorBoundary(
  observer(({ paymentId, cardId, paymentOptions }: InputProps) => {
    const { t } = useTranslation()
    const { isLoading } = usePendingPaymentOrDenyAccess(paymentId)
    const { stripeOptions } = usePaymentIntent(paymentId, PaymentMethod.Card)

    return (
      <div className="PayWithCardPage">
        <Header title={t('payment.card_payment')} />
        <Separator.Vertical height={35} />
        <div className="PayWithCardPage__container">
          {!stripeOptions || isLoading ? (
            <LoadingSpinner />
          ) : (
            <Elements stripe={createStripe()} options={stripeOptions}>
              <PayWithSavedCardForm
                paymentId={paymentId}
                cardId={cardId}
                clientSecret={stripeOptions.clientSecret}
                paymentOptions={paymentOptions}
              />
            </Elements>
          )}
        </div>
      </div>
    )
  }),
)
