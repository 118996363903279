import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import { images } from '../assets/home'
import './home.scss'
import '../onboarding.scss'

export const homeOnboarding: OnboardingContent = {
  title: t('home_onboarding.title'),
  paths: [routes.index.path],
  pages: [
    {
      title: null,
      content: [
        { image: { src: images.plantImg, className: 'HomeOnboarding__plantImg' } },
        { description: t('home_onboarding.intro_title') },
        { description: t('home_onboarding.intro_description') },
        { image: { src: images.smileImg, className: 'HomeOnboarding__smileImg' } },
        { description: t('home_onboarding.intro_footer') },
      ],
    },
    {
      title: t('home_onboarding.hire_title'),
      content: [
        { image: { src: images.homeHireImg, className: 'Onboarding__bigImg' } },
        { description: t('home_onboarding.hire') },
      ],
    },
    {
      title: t('home_onboarding.attendance_title'),
      content: [
        { image: { src: images.homeAttendanceReview, className: 'Onboarding__bigImg' } },
        { description: t('home_onboarding.attendance') },
      ],
    },
    {
      title: t('home_onboarding.orders_title'),
      content: [
        { image: { src: images.homeOrdersConfirmationImg, className: 'Onboarding__bigImg' } },
        { description: t('home_onboarding.orders') },
      ],
    },
    {
      title: t('home_onboarding.ratings_title'),
      content: [
        { image: { src: images.homeRatingRequstsImg, className: 'Onboarding__bigImg' } },
        { description: t('home_onboarding.ratings') },
      ],
    },
    {
      title: t('home_onboarding.applicants_review_title'),
      content: [
        { image: { src: images.homeApplicantReviewImg, className: 'Onboarding__bigImg' } },
        { description: t('home_onboarding.applicants_review') },
      ],
    },
    {
      title: null,
      content: [
        { image: { src: images.thumbUpImg, className: 'HomeOnboarding__thumbUpImg' } },
        { description: t('home_onboarding.outro') },
        { image: { src: images.arrowDownImg, className: 'HomeOnboarding__arrowDownImg' } },
      ],
    },
  ],
}
