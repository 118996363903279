import { useTranslation } from 'react-i18next'

import filledStarIcon from '../../assets/star-filled.svg'
import emptyStarIcon from '../../assets/star-empty.svg'

type InputProps = {
  isEditRatingMode: boolean
  setIsEditRatingMode: (value: boolean) => void
  rating: number
  handleSetRating: (value: number) => void
}

export const Rating = ({ isEditRatingMode, setIsEditRatingMode, rating, handleSetRating }: InputProps) => {
  const { t } = useTranslation()

  return (
    <div className="WorkersList__item__rating">
      {!isEditRatingMode ? (
        <>
          <div className="WorkersList__item__rating__stars">
            {Array.from(Array(rating)).map((_, i) => (
              <img key={i} src={filledStarIcon} />
            ))}
            {Array.from(Array(5 - rating)).map((_, i) => (
              <img key={i} src={emptyStarIcon} />
            ))}
          </div>
          <div className="WorkersList__item__rating__info">
            <span className="WorkersList__item__rating__info__value">
              <strong>{rating}</strong>/5
            </span>
            <span
              onClick={() => setIsEditRatingMode(true)}
              className="WorkersList__item__rating__info__highlight cursor-pointer"
            >
              {t('common.change')}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="WorkersList__item__rating__stars">
            <img onClick={() => handleSetRating(1)} src={emptyStarIcon} className="cursor-pointer" />
            <img onClick={() => handleSetRating(2)} src={emptyStarIcon} className="cursor-pointer" />
            <img onClick={() => handleSetRating(3)} src={emptyStarIcon} className="cursor-pointer" />
            <img onClick={() => handleSetRating(4)} src={emptyStarIcon} className="cursor-pointer" />
            <img onClick={() => handleSetRating(5)} src={emptyStarIcon} className="cursor-pointer" />
          </div>
          <div className="WorkersList__item__rating__info">
            <span className="WorkersList__item__rating__info__highlight cursor-pointer">
              {t('staff.how_was_the_worker')}
            </span>
            {rating > 0 && (
              <span
                onClick={() => setIsEditRatingMode(false)}
                className="WorkersList__item__rating__info__cancel cursor-pointer"
              >
                {t('common.cancel')}
              </span>
            )}
          </div>
        </>
      )}
    </div>
  )
}
