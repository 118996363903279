import { flow, types, SnapshotOut } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const UAE_MARKET_FALLBACK = {
  id: 1,
  code: 'uae',
  name: 'United Arab Emirates',
  icon: 'https://d9eoc87hwocgp.cloudfront.net/assets/markets/uae.png',
}

export const GR_MARKET_FALLBACK = {
  id: 2,
  code: 'gr',
  name: 'Greece',
  icon: 'https://d9eoc87hwocgp.cloudfront.net/assets/markets/gr.png',
}

export const SA_MARKET_FALLBACK = {
  id: 3,
  code: 'sa',
  name: 'KSA',
  icon: 'https://d9eoc87hwocgp.cloudfront.net/assets/markets/sa.png',
}

export const MarketModel = types.model('MarketModel', {
  id: types.identifierNumber,
  code: types.string,
  name: types.string,
  icon: types.string,
  api_endpoint: types.string,
})

export const marketActions = (self: DataStore) => ({
  getMarkets: flow(function* () {
    const markets = (yield self.request('get', 'common/markets')) as MarketSnapshot[]
    markets.forEach(market => {
      self.marketsStore.set(String(market.id), market)
    })
  }),
  setMarket(id: string, market: MarketSnapshot) {
    self.marketsStore.set(id, market)
  },
})

export const marketViews = (self: DataStore) => ({
  get marketList() {
    return Array.from(self.marketsStore.values())
  },
  getMarketByCode(code: string) {
    return Array.from(self.marketsStore.values()).find(market => market.code === code)
  },
})

export type MarketSnapshot = SnapshotOut<typeof MarketModel>
