import { types, SnapshotIn, SnapshotOut, Instance, flow } from 'mobx-state-tree'

import { DataStore } from '../data-store/data-store'
import { OrderJobDetailsModel } from './order-job-details'
import { OrderSummaryModel } from './order-summary'

export const BasicOrderModel = types.model('Order', {
  id: types.identifierNumber,
  name: types.string,
  is_draft: types.boolean,
  is_pending_client_approval: types.maybeNull(types.number),
  is_pending_rates_review: types.maybeNull(types.number),
  jobs: types.map(OrderJobDetailsModel),
  summary: types.maybeNull(OrderSummaryModel),
})

export type BasicOrder = SnapshotIn<typeof BasicOrderModel>
export type BasicOrderSnapshot = SnapshotOut<typeof BasicOrderModel>
export type BasicOrderInstance = Instance<typeof BasicOrderModel>

export const basicOrderActions = (self: DataStore) => ({
  getBasicOrder: flow(function* (orderId: number) {
    const order = (yield self.request('post', 'client/order/basic/get', { order_id: orderId })) as BasicOrderSnapshot
    self.order.draft.set(String(order.id), { ...order, is_draft: Boolean(order.is_draft) })
  }),

  createOrder: flow(function* (lpoNumber?: string) {
    const order = (yield self.request(
      'post',
      'client/order/basic/post',
      lpoNumber ? { lpo_number: lpoNumber } : undefined,
    )) as {
      id: number
      name: string
      is_draft: number
      is_pending_client_approval: number
      is_pending_rates_review: number
    }
    self.order.draft.set(String(order.id), { ...order, is_draft: Boolean(order.is_draft) })
    return order.id
  }),

  submitOrder: flow(function* (orderId: string) {
    yield self.request('post', `client/order/${orderId}/submit`)
  }),
})

export const basicOrderViews = (self: DataStore) => ({
  get basicOrders() {
    return Array.from(self.order.draft.values())
  },

  getBasicOrderJobs(orderId: string) {
    const order = self.order.draft.get(orderId)
    return order?.jobs ? Array.from(order.jobs.values()) : []
  },
})
