import { ApiErrorTypes } from 'ogram-react'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'

import { useStore, useMutation } from '../../../../../../utils/mst-hooks'
import { QuestionSnapshot } from '../../../../../../models/interview'
import { OgramModal } from '../../../../../../components/ogram-modal/ogram-modal'
import { ButtonPrimary } from '../../../../../../components/button/button-primary/button-primary'
import { Input } from '../../../../../form/input/input'
import './question-create.scss'

type QuestionCreateProps = {
  visible: boolean
  onClose: () => void
  onCreate: (question: QuestionSnapshot) => void
}

function QuestionCreateComponent({ visible, onCreate, onClose }: QuestionCreateProps) {
  const { t } = useTranslation()

  const { addQuestion } = useStore().data

  const [addQuestionMutation, requestCreateMutation] = useMutation(addQuestion, {
    filterError: [ApiErrorTypes.ClientError],
    onSuccess: question => {
      toast.success(t('job_draft_details.interview_question_created'))
      onCreate(question)
    },
    onError: err => {
      toast.error(err.data.errors?.description?.[0] ?? err.data.message)
    },
  })

  const [description, setDescription] = useState('')

  return (
    <OgramModal modalVisible={visible} onClose={onClose}>
      <div className="QuestionCreate">
        <h2 className="QuestionCreate__title">{t('job_draft_details.interview_question_form_title')}</h2>
        <Input
          className="QuestionCreate__input"
          label={t('job_draft_details.interview_question_form_placeholder')}
          value={description}
          handleChange={setDescription}
        />
        <ButtonPrimary
          text={t('job_draft_details.interview_question_form_submit')}
          disabled={description.length === 0 || addQuestionMutation.isLoading}
          action={() => {
            requestCreateMutation({ description })
            setDescription('')
          }}
        />
      </div>
    </OgramModal>
  )
}

export const QuestionCreate = observer(QuestionCreateComponent)
