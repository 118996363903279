import { types, cast, addMiddleware, getParent } from 'mobx-state-tree'

import { t } from '../../../../../translations'
import {
  isTimeLessThan24HoursFromNow,
  getNextDaySameTime,
  formatYyyyMmDdToDdd,
  daysOfWeek,
  isYyyyMmDdValid,
  isDddValid,
  formatDateToIso8601,
  formatDateToDdd,
} from '../../../../../utils/datetime-utils'
import { DraftJobFields } from '../fields'
import { DraftJobInstance } from '../../draft-job'
import { SingleDateInstance, SingleDateModel } from './single-date'
import { JobScheduleInstance } from './'

export const DistinctTimeScheduleModel = types
  .model('JobDatesDistinctTimeSchedule', {
    /**
     * keeps Record<date, SingleDateModel>, where date is in one of two formats:
     * 'ddd' ('Mon', 'Tue', etc.) or 'YYYY-MM-DD'
     */
    map: types.map(SingleDateModel),
    /**
     * overall indicator that there is an error related to distinct schedule
     * can be a general message or a duplicate error message applied to particular date
     */
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get jobDates() {
      return Array.from(self.map.entries())
    },

    get jobDatesKeys() {
      return Array.from(self.map.keys()).filter(key => self.map.get(key)?.selected)
    },

    get jobDatesValues() {
      return Array.from(self.map.values()).filter(value => value.selected)
    },

    get filledJobDatesCount() {
      return Array.from(self.map.values()).filter(item => item.selected).length
    },

    get isLessThan24Hours() {
      return Array.from(self.map.values()).filter(item => item.is_less_than_24h).length > 0
    },
  }))
  .actions(self => ({
    setDateTime(dateTime: { date: string; start_time: string | null; end_time: string | null; selected: boolean }) {
      const { date, ...dateObj } = dateTime
      self.map.set(date, dateObj)
    },

    setDateStartTime({ date, start_time }: { date: string; start_time: string | null }) {
      const existingDate = self.map.get(date)
      self.map.set(date, {
        start_time,
        end_time: existingDate?.end_time ?? null,
        selected: existingDate?.selected,
        start_time_error: null,
      })
      if (start_time) {
        const dateInstance = self.map.get(date) as SingleDateInstance
        const parent = getParent(self)
        const dateBoundaries = (parent as JobScheduleInstance).dateBoundaries
        const nextDaySameTime = getNextDaySameTime(new Date())
        const tomorrowYyyyMmDd = formatDateToIso8601(nextDaySameTime)
        const tomorrowDdd = formatDateToDdd(nextDaySameTime)
        // check if submitted timing applies to tomorrow
        if (dateBoundaries.start_date === tomorrowYyyyMmDd && (date === tomorrowYyyyMmDd || date === tomorrowDdd)) {
          dateInstance.is_less_than_24h = isTimeLessThan24HoursFromNow(start_time)
        }
        return true
      }
      self.error = null
    },

    setDateEndTime({ date, end_time }: { date: string; end_time: string | null }) {
      const existingDate = self.map.get(date)
      self.map.set(date, {
        start_time: existingDate?.start_time ?? null,
        end_time,
        selected: existingDate?.selected,
        end_time_error: null,
      })
      self.error = null
    },

    setDateTimes({
      dates,
    }: {
      dates: Record<
        string,
        {
          start_time: string
          end_time: string
          selected: boolean
        }
      >
    }) {
      self.map = cast(dates)
    },

    selectDate({ date }: { date: string }) {
      const existingDate = self.map.get(date)
      self.map.set(date, {
        start_time: existingDate?.start_time,
        end_time: existingDate?.end_time,
        selected: true,
        start_time_error: null,
        end_time_error: null,
      })
    },

    clearDate(date: string) {
      self.map.set(date, { start_time: null, end_time: null, start_time_error: null, end_time_error: null })
    },

    removeDates() {
      self.map.clear()
    },

    validateSelections() {
      const parent = getParent(self)
      const dateBoundaries = (parent as JobScheduleInstance).dateBoundaries
      if (self.map.size === 0 || self.filledJobDatesCount === 0) {
        self.error = t('job_draft_details.select_one_date_at_least')
        return false
      }

      const scheduleType = (parent as JobScheduleInstance).scheduleType
      const startDateKey =
        scheduleType.value === 'weekly'
          ? formatYyyyMmDdToDdd(dateBoundaries.start_date as string)
          : (dateBoundaries.start_date as string)
      const endDateKey =
        scheduleType.value === 'weekly'
          ? formatYyyyMmDdToDdd(dateBoundaries.end_date as string)
          : (dateBoundaries.end_date as string)

      if (!self.map.get(startDateKey)?.selected) {
        self.error = t('job_draft_details.must_include_day_of_start_date')
        return false
      } else if (!dateBoundaries.open_ended && !self.map.get(endDateKey)?.selected) {
        self.error = t('job_draft_details.must_include_day_of_end_date')
        return false
      }
      return true
    },
  }))
  .actions(self => ({
    putEmptyTimesForWeekdays() {
      self.removeDates()
      daysOfWeek.forEach((date: string) => {
        self.setDateStartTime({ date, start_time: null })
        self.setDateEndTime({ date, end_time: null })
      })
    },

    validate() {
      if (!self.validateSelections()) {
        return false
      }
      let hasAtLeastOneDateError = false
      for (const [date, singleDateInstance] of self.map.entries()) {
        checkDateFormat(date)
        if (!singleDateInstance.validate()) {
          hasAtLeastOneDateError = true
        }
      }
      if (hasAtLeastOneDateError) {
        self.error = t('job_draft_details.fill_all_selected_dates')
        return false
      }
      self.error = null
      return true
    },
  }))

/**
 * Validates that date has valid format
 * @param date 'YYYY-MM-DD' or 'ddd' ('Mon', 'Tue', 'Wed', etc) string
 */
function checkDateFormat(date: string) {
  if (!isYyyyMmDdValid(date) && !isDddValid(date)) {
    throw new Error(`Invalid format of date: ${date}, expected one of: YYYY-MM-DD or ddd`)
  }
}

export function createDistinchTimeScheduleMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (
      call.type === 'action' &&
      call.args[0] === DraftJobFields.daysSelect &&
      !targetInstance.schedule.sameTimeSchedule.allDaysHaveSameTime
    ) {
      return abort(targetInstance.schedule.distinctTimeSchedule.validate())
    } else if (
      call.type === 'action' &&
      call.args[0] === DraftJobFields.daysSelect &&
      targetInstance.schedule.sameTimeSchedule.allDaysHaveSameTime
    ) {
      return abort(targetInstance.schedule.distinctTimeSchedule.validateSelections())
    } else if (call.type === 'action' && (call.name === 'setStartDate' || call.name === 'setEndDate')) {
      targetInstance.clearDistinctTimeFields()
    }
    return next(call)
  })
}
