import { useTranslation } from 'react-i18next'

import arrowRightImg from '../../assets/arrow-right.svg'
import './accept-tc-link.scss'
import { TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT } from '../../../constants'

export const AcceptTcLink = ({ apiHost }: { apiHost: string }) => {
  const { t } = useTranslation()

  return (
    <div
      className="AcceptTcLink"
      onClick={() => {
        window.open(
          `${apiHost}client/profile/sla?type=html&tc_type=${TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT}`,
          '_blank',
        )
      }}
    >
      <span className="AcceptTcLink__text">{t('become_affiliate.accept_tc')}</span>
      <img src={arrowRightImg} />
    </div>
  )
}
