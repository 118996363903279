import { types, flow } from 'mobx-state-tree'
import { AxiosRequestConfig } from 'axios'
import { GeoCodeResponse, GooglePlacesResponse } from 'ogram-react'

import { DataStore } from './data-store/data-store'
import { config } from '../config/env'

export const BusinessLocationModel = types.model('Location', {
  id: types.identifierNumber,
  name: types.string,
  address: types.string,
  address_details: types.string,
  lat: types.maybeNull(types.number),
  lng: types.maybeNull(types.number),
  region: types.string,
  image: types.maybeNull(types.string),
  delete_image: types.maybe(types.number),
  is_georestriction_large: types.maybeNull(types.boolean),
  is_georestriction_disabled: types.maybeNull(types.boolean),
  public_transport_accessibility: types.maybeNull(types.number),
})

export const locationActions = (self: DataStore) => ({
  getLocations: flow(function* () {
    const locations = (yield self.request('get', 'client/locations')) as BusinessLocation[]
    self.locations.clear()
    locations.map(location => {
      self.locations.set(String(location.id), location)
    })
  }),

  getLocation: flow(function* (id: string) {
    const location = (yield self.request('get', `client/locations/${id}`)) as BusinessLocation
    self.locations.set(String(location.id), location)
  }),

  addLocation: flow(function* (location: BusinessLocationForm, optConfig?: AxiosRequestConfig) {
    const createdLocation = yield self.request(
      'post',
      'client/locations',
      {
        ...location,
        is_georestriction_large: Number(location.is_georestriction_large),
        is_georestriction_disabled: Number(location.is_georestriction_disabled),
      },
      {
        headers: { 'Content-Type': 'multipart/form-data' },
        ...optConfig,
      },
    )
    self.locations.set(String(createdLocation.id), createdLocation)

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return createdLocation
  }),

  updateLocation: flow(function* (id: string, location: BusinessLocationForm, optConfig?: AxiosRequestConfig) {
    const updatedLocation = yield self.request(
      'put',
      `client/locations/${id}`,
      {
        ...location,
        is_georestriction_large: Number(location.is_georestriction_large),
        is_georestriction_disabled: Number(location.is_georestriction_disabled),
      },
      {
        ...optConfig,
      },
    )
    self.locations.set(String(updatedLocation.id), updatedLocation)
  }),

  deleteLocation: flow(function* (id: string) {
    self.locations.delete(id)
    yield self.request('delete', `client/locations/${id}`)
  }),

  getGeoCode: flow(function* (address: string): Generator<Promise<unknown>, GeoCodeResponse> {
    return (yield self.rawRequest(
      'get',
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${
        config.GOOGLE_API_KEY
      }`,
    )) as GeoCodeResponse
  }),

  getPlaces: flow(function* (address: string): Generator<Promise<unknown>, GooglePlacesResponse> {
    return (yield self.rawRequest(
      'get',
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(address)}&key=${
        config.GOOGLE_API_KEY
      }&language=en`,
    )) as GooglePlacesResponse
  }),
})

export const locationViews = (self: DataStore) => ({
  getLocationIdByName(name: string) {
    return Array.from(self.locations.values()).find(location => location.name === name)?.id
  },
  getLocationNameById(id: string) {
    return Array.from(self.locations.values()).find(location => location.id === Number(id))?.name
  },
  getLocationByName(name: string) {
    return Array.from(self.locations.values()).find(location => location.name === name)
  },
  get locationsDataView() {
    return Array.from(self.locations.values())
  },
})

export type BusinessLocation = typeof BusinessLocationModel.Type
export type BusinessLocationSnapshot = typeof BusinessLocationModel.SnapshotType

export type BusinessLocationForm = Omit<BusinessLocation, 'image' | 'id'> & {
  id?: number
  image: File | null | undefined | string
}
