import { useEffect } from 'react'

import { useStore, useMutation } from './mst-hooks'
import { LANGUAGE_EN, marketService } from '../services/market-service'

export const useLanguageUpdate = () => {
  const { logged, updateUserLanguage } = useStore().data

  useEffect(() => {
    if (logged) {
      callUpdateLanguage(marketService.getCurrentLanguage() ?? LANGUAGE_EN)
    }
  }, [logged])

  const [_, callUpdateLanguage] = useMutation(updateUserLanguage)
}
