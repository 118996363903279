import { t } from '../../../translations'
import { routes } from '../../../routes'
import { OnboardingContent } from '../../page-onboarding/page-onboarding'
import '../onboarding.scss'
import { selectDesignationPage } from './pages/select-designation'
import { jobDetailsPage } from './pages/job-details'
import { jobDurationPage } from './pages/job-duration'
import { jobRequirementsPage } from './pages/job-requirements'
import { shiftsBreakdownPage } from './pages/shifts-breakdown'
import { duplicateJobPage } from './pages/duplicate-job'
import { sendForReviewPage } from './pages/send-for-review'

export const ordersCreationOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [
    routes.setJobCategory.path,
    routes.jobDraftDetails.path,
    routes.orderDraft.path,
    routes.orderDraftSummary.path,
    routes.orderConfirmation.path,
  ],
  pages: [
    selectDesignationPage,
    jobDetailsPage,
    jobDurationPage,
    jobRequirementsPage,
    shiftsBreakdownPage,
    duplicateJobPage,
    sendForReviewPage,
  ],
}
