import { observer } from 'mobx-react'

import angleDown from './assets/angle-down.svg'
import { MarketSnapshot } from '../../models/market'
import { Separator } from '../app-layout'
import { DropdownMenu } from '../dropdown-menu/dropdown-menu'
import { useQuery, useStore } from '../../utils/mst-hooks'
import './market-selector.scss'
import { ENABLED_MARKET_CODES, marketService } from '../../services/market-service'
import { withErrorBoundary } from '../error/with-error-boundary'

export const MarketSelector = withErrorBoundary(
  observer(({ redirectPath }: { redirectPath: string }) => {
    const store = useStore()
    useQuery(store.data.getMarkets)

    if (ENABLED_MARKET_CODES.length < 2) {
      return <div></div>
    }

    const marketList = store.data.marketList
    const currentMarketCode = marketService.getCurrentMarketCode()
    const selectedMarket = !currentMarketCode ? marketList[0] ?? null : store.data.getMarketByCode(currentMarketCode)

    return (
      <DropdownMenu
        outerComponent={
          <div className="MarketSelector">
            <img src={selectedMarket?.icon} className="MarketSelector__countryImg" />
            <div className="MarketSelector__codeContainer">
              <span className="MarketSelector__countryCode">{selectedMarket?.code}</span>
              <img src={angleDown} className="MarketSelector__countryArrowDown" />
            </div>
          </div>
        }
        items={marketList.map((market: MarketSnapshot) => {
          return (
            <div
              key={market.id}
              onClick={() => {
                window.location.href = `/${market.code}/${redirectPath}${window.location.search}`
              }}
              className={`MarketSelector__item${
                market.id === selectedMarket?.id ? ' MarketSelector__item--active' : ''
              }`}
            >
              <img src={market.icon} />
              <Separator.Horizontal width={16} />
              <span className="MarketSelector__item__title">{market.name}</span>
            </div>
          )
        })}
      />
    )
  }),
)
