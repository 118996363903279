import './collapsible.scss'

export function Collapsible({
  collapsed,
  children,
  maxHeight,
}: {
  collapsed: boolean
  children: React.ReactNode
  maxHeight: number
}) {
  return (
    <div style={{ maxHeight }} className={`Collapsible${collapsed ? '__collapsed' : ''}`}>
      {children}
    </div>
  )
}
