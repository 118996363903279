import { Separator } from '../../../app-layout/separator/separator'
import './menu-item.scss'

type Props = {
  img: React.ReactNode
  title: string
  onClick: (event: unknown) => void
}

export const MenuItem = ({ img, title, onClick }: Props) => {
  return (
    <div onClick={onClick} className="SelectItem">
      {img}
      <Separator.Horizontal width={16} />
      <span className="SelectItem__title">{title}</span>
    </div>
  )
}
