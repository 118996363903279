import { useTranslation } from 'react-i18next'

import { AffiliateReferralSnapshot } from '../../../../../../models/affiliate'

export const Table = ({ affiliateReferral }: { affiliateReferral: AffiliateReferralSnapshot }) => {
  const { t } = useTranslation()

  const designations = affiliateReferral.designations.join(', ')

  return (
    <table className="AffiliateReferralListItem__bottom__table">
      <tbody>
        <tr className="AffiliateReferralListItem__bottom__table__headers">
          {designations && <td>{t('common_affiliate.designation')}</td>}
          {affiliateReferral.payoutAmount && <td>{t('common_affiliate.payout')}</td>}
          {affiliateReferral.payoutDate ? (
            <td>{t('common_affiliate.due_date')}</td>
          ) : (
            <td>{t('common_affiliate.join_date')}</td>
          )}
        </tr>
        <tr className="AffiliateReferralListItem__bottom__table__content">
          {designations && <td>{designations}</td>}
          {affiliateReferral.payoutAmount && (
            <td>
              {affiliateReferral.currency} {affiliateReferral.payoutAmount}
            </td>
          )}
          {affiliateReferral.payoutDate ? (
            <td>{affiliateReferral.payoutDate}</td>
          ) : (
            <td>{affiliateReferral.joinDate}</td>
          )}
        </tr>
      </tbody>
    </table>
  )
}
