import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { useBottomListListener } from '../../../../utils/use-bottom-list-listener'
import { InvoiceCard } from './components/invoice-card/invoice-card'
import { UIProps } from '../types'
import { Header } from '../../../../components/app-layout'
import './invoice-list.scss'
import { FiltersPopup } from '../../../../components/filters/filters-popup/filters-popup'
import { FiltersButton } from '../../../../components/filters/filters-button/filters-button'
import { EmptyList } from '../../../../components/empty-list/empty-list'

export const InvoiceList = ({ loading, invoiceList, loadNextPage, filters }: UIProps) => {
  const handleScroll = useBottomListListener(loadNextPage)
  const { t } = useTranslation()

  return (
    <div className="InvoiceList" onScroll={handleScroll}>
      <Header
        showBack
        title={t('sidebar.invoices')}
        rightComponent={
          <FiltersButton
            setShowFilters={filters.setIsFiltersPopupOpen}
            activeFiltersCount={filters.activeFiltersCount}
            className="OrderConfirmationList__filtersButton"
          />
        }
      />
      <Separator.Vertical height={25} />
      {invoiceList.map(invoice => {
        return (
          <Fragment key={invoice.id}>
            <InvoiceCard
              id={invoice.id}
              amount={invoice.total_amount}
              currency={invoice.currency_code}
              date={invoice.date}
              dueDate={invoice.due_date}
              status={invoice.status}
              invoiceNumber={invoice.invoice_number}
              orderIds={invoice.order_ids}
            />
            <Separator.Vertical height={24} />
          </Fragment>
        )
      })}
      {loading && <LoadingSpinner />}
      {!loading && invoiceList.length === 0 && <EmptyList />}
      <FiltersPopup
        isOpen={filters.isFiltersPopupOpen}
        close={() => filters.setIsFiltersPopupOpen(false)}
        reset={filters.reset}
        apply={filters.apply}
        fields={['pointOfContactId', 'designationId', 'locationId', 'startDate', 'endDate', 'orderId', 'isOverdue']}
        pointOfContactId={filters.pointOfContactId}
        setPointOfContactId={filters.setPointOfContactId}
        locationId={filters.locationId}
        setLocationId={filters.setLocationId}
        designationId={filters.designationId}
        setDesignationId={filters.setDesignationId}
        startDate={filters.dateFrom}
        setStartDate={filters.setDateFrom}
        startDateLabel={t('common.date_from')}
        endDate={filters.dateTo}
        setEndDate={filters.setDateTo}
        endDateLabel={t('common.date_to')}
        orderId={filters.orderId}
        setOrderId={filters.setOrderId}
        isOverdue={filters.isOverdue}
        setIsOverdue={filters.setIsOverdue}
      />
    </div>
  )
}
