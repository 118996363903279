import { Trans, useTranslation } from 'react-i18next'

import './accept-terms.scss'
import { TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT } from '../../../constants'

type InputProps = {
  isTermsAndConditionsAccepted: boolean
  requestConfirmTerms: (params: unknown) => void
  apiHost: string | undefined
}

export const AcceptTerms = ({ isTermsAndConditionsAccepted, requestConfirmTerms, apiHost }: InputProps) => {
  const { t } = useTranslation()

  return (
    <div className="AcceptTerms">
      <input
        checked={isTermsAndConditionsAccepted}
        type="checkbox"
        disabled={isTermsAndConditionsAccepted}
        onChange={requestConfirmTerms}
      />
      <p>
        <Trans key="common.i_accept_terms">
          {t('common.i_accept')}{' '}
          <a
            href={`${apiHost}client/profile/sla?type=html&tc_type=${TERMS_AND_CONDITIONS_TYPE_AFFILIATE_CLIENT}`}
            target="_blank"
            rel="noreferrer"
          >
            {t('common.terms_of_service')}
          </a>
        </Trans>
      </p>
    </div>
  )
}
