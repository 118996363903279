import { types, flow, destroy } from 'mobx-state-tree'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

export interface TwilioToken {
  identity: number
  token: string
}

const ChannelModel = types.model({
  sid: types.string,
  friendly_name: types.string,
  last_message: types.maybe(types.string),
  last_message_index: types.maybe(types.number),
  last_message_time: types.maybe(types.Date),
  created_at: types.maybe(types.Date),
  updated_at: types.maybe(types.Date),
  order_id: types.maybe(types.number),
  created_by: types.string,
  unread_count: types.optional(types.number, 0),
  images: types.optional(types.array(types.string), []),
})

export type Channel = typeof ChannelModel.Type
export type ChannelSnapshot = typeof ChannelModel.SnapshotType

const ContactJobModel = types.model({
  id: types.number,
  title: types.string,
  orderId: types.number,
  workCategoryId: types.number,
  locationId: types.number,
  startDate: types.string,
  endDate: types.string,
})

export type ContactJob = typeof ContactJobModel.Type
export type ContactJobSnapshot = typeof ContactJobModel.SnapshotType

const ContactModel = types.model({
  identity: types.number,
  type: types.string,
  firstName: types.string,
  lastName: types.string,
  imageUrl: types.maybeNull(types.string),
  jobs: types.optional(types.array(ContactJobModel), []),
})

export type Contact = typeof ContactModel.Type
export type ContactSnapshot = typeof ContactModel.SnapshotType

const ChatContactsFilterModel = types.model('ChatContactsFilter', {
  order_id: types.maybeNull(types.number),
  location_id: types.maybeNull(types.number),
  work_category_id: types.maybeNull(types.number),
})

export type ChatContactsFilter = typeof ChatContactsFilterModel.Type
export type ChatContactsFilterSnapshot = typeof ChatContactsFilterModel.SnapshotType

export const ChatStoreModel = types.model('ChatStore', {
  channels: types.map(ChannelModel),
  contacts: types.map(ContactModel),
  contactsNextPage: types.optional(types.maybeNull(types.number), 1),
  filter: types.optional(ChatContactsFilterModel, {}),
})

export const chatActions = (self: DataStore) => ({
  getToken: flow(function* () {
    return (yield self.request('post', 'common/chat/twilio/token')) as TwilioToken
  }),

  setChannels(channels: ChannelSnapshot[]) {
    self.chat.channels.clear()
    channels.forEach((channel: ChannelSnapshot) => {
      self.chat.channels.set(channel.sid, channel)
    })
  },

  addChannel(channel: ChannelSnapshot) {
    self.chat.channels.set(channel.sid, channel)
  },

  addChannels(channels: ChannelSnapshot[]) {
    channels.forEach((channel: ChannelSnapshot) => {
      self.chat.channels.set(channel.sid, channel)
    })
  },

  updateChannel: flow(function* (channel: ChannelSnapshot) {
    const foundChannel = self.chat.channels.get(channel.sid)
    self.chat.channels.set(channel.sid, { ...foundChannel, ...channel } as ChannelSnapshot)
  }),

  updateChannelLastMessage(channelId: string, last_message: string, last_message_time: Date) {
    const channel = self.chat.channels.get(channelId) as ChannelSnapshot
    self.chat.channels.set(channelId, { ...channel, last_message, last_message_time: new Date(last_message_time) })
  },

  removeChannel(channelId: string) {
    self.chat.channels.delete(channelId)
  },

  getChatContacts: flow(function* (firstPage?: boolean) {
    if (!firstPage && !self.chat.contactsNextPage) return

    const url = new URL('common/chat/contacts')
    url.searchParams.append('page', String(firstPage ? 1 : self.chat.contactsNextPage))

    const filter = self.chat.filter

    if (filter.order_id) {
      url.searchParams.append('order_id', String(filter.order_id))
    }
    if (filter.location_id) {
      url.searchParams.append('location_id', String(filter.location_id))
    }
    if (filter.work_category_id) {
      url.searchParams.append('work_category_id', String(filter.work_category_id))
    }

    const { list: contacts = [], paginator } = (yield self.request('get', url.toString())) as {
      list: ContactSnapshot[]
      paginator?: Paginator
    }

    if (firstPage) {
      self.chat.contacts.clear()
    }

    contacts.forEach((contact: ContactSnapshot) => {
      self.chat.contacts.set(String(contact.identity), contact)
    })

    self.chat.contactsNextPage = paginator?.next_page ?? null
  }),

  createChatChannel: flow(function* (params: ChannelFormValues) {
    const chatChannel = (yield self.request('post', 'common/chat/channels', params)) as ChannelSnapshot
    const createdChannel = { ...chatChannel, created_by: 'system' }
    self.chat.channels.set(String(chatChannel.sid), createdChannel)
    return chatChannel
  }),

  getChatMembers: flow(function* (channelId: string) {
    const chatMembers = (yield self.request('get', `common/chat/channels/${channelId}/members`)) as ContactSnapshot[]
    return chatMembers
  }),

  setChatContactsFilter(filter: ChatContactsFilterSnapshot) {
    self.chat.filter = ChatContactsFilterModel.create(filter)
  },

  resetChatContactsFilter() {
    destroy(self.chat.filter)
  },
})

export const chatViews = (self: DataStore) => ({
  get channels() {
    const channels = Array.from(self.chat.channels.values())
    return channels.sort(
      (channelA, channelB) => Number(channelB.last_message_time) - Number(channelA.last_message_time),
    )
  },
  get chatContacts() {
    const chatContacts = Array.from(self.chat.contacts.values())
    return chatContacts.reduce(
      (orderGroups: ContactOrder[], contact: ContactSnapshot) =>
        contact.jobs.reduce((orders, job: ContactJobSnapshot) => {
          const foundOrder = orders.find((order) => order.orderId === job.orderId)
          if (foundOrder) {
            const foundWorkCategory = foundOrder.workCategories.find(
              (workCategory) => workCategory.workCategoryId === job.workCategoryId,
            )
            const foundSP = foundWorkCategory?.sps.find((sp) => sp.id === contact.identity)
            if (foundWorkCategory && foundSP) {
              return orders
            }

            const sp = createNewSP(contact)
            if (foundWorkCategory) {
              foundWorkCategory.sps = foundWorkCategory.sps.concat(sp)
              return orders.map((order) =>
                order.orderId === foundOrder.orderId
                  ? ({
                      ...order,
                      workCategories: order.workCategories.map((w) =>
                        w.workCategoryId === foundWorkCategory.workCategoryId ? foundWorkCategory : w,
                      ),
                    } as ContactOrder)
                  : order,
              )
            }

            const newWorkCategory = createNewWorkCategory(job, sp)
            return orders.map((order) =>
              order.orderId === foundOrder.orderId
                ? { ...order, workCategories: order.workCategories.concat(newWorkCategory) }
                : order,
            )
          }

          const sp = createNewSP(contact)
          const newWorkCategory = createNewWorkCategory(job, sp)
          const newOrder: ContactOrder = { orderId: job.orderId, title: job.title, workCategories: [newWorkCategory] }
          return orders.concat(newOrder)
        }, orderGroups),
      [],
    )
  },

  get chatContactsFilterCount() {
    let count = 0
    count += self.chat.filter.order_id ? 1 : 0
    count += self.chat.filter.location_id ? 1 : 0
    count += self.chat.filter.work_category_id ? 1 : 0
    return count
  },

  get unreadCounts() {
    let count = 0
    self.chat.channels.forEach((channel: Channel) => {
      count += channel.unread_count
    })
    return count
  },
})

function createNewSP(contact: ContactSnapshot) {
  return {
    id: contact.identity,
    name: `${contact.firstName} ${contact.lastName}`,
    imageUrl: contact.imageUrl,
  } as ContactSP
}

function createNewWorkCategory(job: ContactJobSnapshot, sp: ContactSP) {
  return {
    workCategoryId: job.workCategoryId,
    title: job.title,
    sps: [sp],
  } as ContactWorkCategory
}

export interface ChannelFormValues {
  order_id: number
  friendly_name: string
  identities: number[]
}

export interface ContactOrder {
  orderId: number
  title: string
  workCategories: ContactWorkCategory[]
}

export interface ContactWorkCategory {
  workCategoryId: number
  title: string
  sps: ContactSP[]
}

export interface ContactSP {
  id: number
  name: string
  imageUrl: string | null
}
