import { types, flow, destroy, cast } from 'mobx-state-tree'
import { isObservableArray } from 'mobx'
import { Paginator } from 'ogram-react'

import { DataStore } from './data-store/data-store'

const SPFilterModel = types.model('SPFilter', {
  name: types.maybe(types.string),
  nationality_ids: types.optional(types.array(types.number), []),
  languages_ids: types.optional(types.array(types.number), []),
  gender: types.maybe(types.number),
  min_age: types.maybe(types.number),
  max_age: types.maybe(types.number),
  work_category_id: types.maybe(types.number),
  order_id: types.maybe(types.number),
  min_experience: types.maybe(types.number),
  max_experience: types.maybe(types.number),
  min_hourly_rate: types.maybe(types.number),
  max_hourly_rate: types.maybe(types.number),
  min_height: types.maybe(types.number),
  max_height: types.maybe(types.number),
})

export type SPFilter = typeof SPFilterModel.Type
export type SPFiltersSnapshot = typeof SPFilterModel.SnapshotType

const LanguageModel = types.model('Language', {
  id: types.optional(types.number, 0),
  name: types.string,
})

const ExperienceModel = types.model('Experience', {
  name: types.string,
  years_experience: types.maybeNull(types.number),
  hourly_rate: types.maybeNull(types.number),
  work_locations: types.optional(
    types.array(
      types.model('WorkLocation', {
        name: types.string,
        lat: types.maybeNull(types.number),
        lng: types.maybeNull(types.number),
      }),
    ),
    [],
  ),
})

export type Experience = typeof ExperienceModel.Type
export type ExperienceSnapshot = typeof ExperienceModel.SnapshotType

const RatingModel = types.model('Rating', {
  id: types.number,
  rating: types.number,
  comment: types.string,
  criteria: types.optional(
    types.array(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    [],
  ),
  work_category: types.model('WorkCategory', {
    id: types.number,
    name: types.string,
    description: types.string,
    image: types.string,
  }),
})

export type Rating = typeof RatingModel.Type
export type RatingSnapshot = typeof RatingModel.SnapshotType

const JobModel = types.model('Job', {
  id: types.number,
  title: types.string,
  order_id: types.number,
  start_date: types.string,
  end_date: types.string,
  work_category: types.model({
    id: types.number,
    name: types.string,
  }),
})

export type Job = typeof JobModel.Type
export type JobSnapshot = typeof JobModel.SnapshotType

export const SPDetailsModel = types.model('SPDetails', {
  id: types.number,
  first_name: types.string,
  last_name: types.string,
  full_name: types.maybe(types.string),
  image_url: types.maybe(types.string),
  email: types.maybe(types.string),
  phone: types.maybeNull(types.string),
  rating: types.optional(types.number, 0),
  gender: types.optional(types.string, 'None'),
  is_saved: types.boolean,
  jobs_count: types.number,
  nationality: types.maybe(
    types.model('Nationality', {
      id: types.maybeNull(types.number),
      name: types.maybeNull(types.string),
    }),
  ),
  languages: types.optional(types.array(LanguageModel), []),
  experiences: types.optional(types.array(ExperienceModel), []),
  rating_list: types.optional(types.array(RatingModel), []),
  job: types.optional(types.array(JobModel), []),
  age: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  resume: types.maybeNull(types.string),
})

export type SPDetails = typeof SPDetailsModel.Type
export type SPDetailsSnapshot = typeof SPDetailsModel.SnapshotType

export const SPModel = types.model('SP', {
  id: types.number,
  first_name: types.string,
  last_name: types.string,
  image_url: types.maybe(types.string),
  email: types.maybe(types.string),
  rating: types.optional(types.number, 0),
  is_saved: types.boolean,
  nationality: types.maybe(
    types.model('Nationality', {
      id: types.number,
      name: types.string,
    }),
  ),
  languages: types.optional(types.array(LanguageModel), []),
  job: types.optional(types.array(JobModel), []),
  experiences: types.array(
    types.model({
      name: types.string,
    }),
  ),
})

export type SP = typeof SPModel.Type
export type SPSnapshot = typeof SPModel.SnapshotType

export const SPStoreModel = types.model('SPStore', {
  SPs: types.map(SPModel),
  staff: types.optional(types.array(types.number), []),
  saved: types.optional(types.array(types.number), []),
  search: types.optional(types.array(types.number), []),
  details: types.maybe(SPDetailsModel),
  filter: types.optional(
    types.model({
      staff: types.optional(SPFilterModel, {}),
      search: types.optional(SPFilterModel, {}),
      saved: types.optional(SPFilterModel, {}),
    }),
    {},
  ),
  nextPage: types.optional(
    types.model({
      staff: types.optional(types.maybeNull(types.number), 1),
      saved: types.optional(types.maybeNull(types.number), 1),
      search: types.optional(types.maybeNull(types.number), 1),
    }),
    {},
  ),
})

export const spActions = (self: DataStore) => ({
  setSPFilter(spType: SPTypes, filter: SPFiltersSnapshot) {
    self.sp.filter[spType] = SPFilterModel.create(filter)
  },

  resetSPFilter(spType: SPTypes) {
    destroy(self.sp.filter[spType])
  },

  fetchSPs: flow(function* (type: SPTypes, firstPage?: boolean) {
    if (!firstPage && !self.sp.nextPage[type]) return

    const { list: SPs = [], paginator } = (yield self.request(
      'post',
      `client/sp/${type}?page=${firstPage ? 1 : self.sp.nextPage[type]}`,
      self.sp.filter[type],
    )) as { list: SPSnapshot[]; paginator?: Paginator }

    if (firstPage) {
      self.sp[type].forEach((spId) => {
        self.sp.SPs.delete(String(spId))
      })
      self.sp[type].clear()
    }

    SPs.forEach((sp: SPSnapshot) => {
      self.sp.SPs.set(String(sp.id), sp)
      self.sp[type].push(sp.id)
    })

    self.sp.nextPage[type] = paginator?.next_page ?? null
  }),

  saveSP: flow(function* (id: number) {
    const sp = self.sp.SPs.get(String(id))
    if (sp) {
      sp.is_saved = true
    }
    self.sp.saved.push(id)
    yield self.request('post', 'client/sp/save', { sp_id: id })
  }),

  unSaveSP: flow(function* (id: number) {
    const sp = self.sp.SPs.get(String(id))
    if (sp) {
      sp.is_saved = false
    }
    self.sp.saved = cast(self.sp.saved.filter((spId) => spId !== id))
    yield self.request('post', 'client/sp/un-save', { sp_id: id })
  }),

  getSPDetails: flow(function* (id: number) {
    const details = (yield self.request('post', 'client/sp/details', { sp_id: id })) as SPDetailsSnapshot
    self.sp.details = SPDetailsModel.create(details)
  }),

  updateSPDetailsSaved: flow(function* () {
    if (self.sp.details) {
      self.sp.details.is_saved = !self.sp.details.is_saved
    }
  }),
})

export const spViews = (self: DataStore) => ({
  getSPs(type: SPTypes) {
    return self.sp[type].map((spId) => self.sp.SPs.get(String(spId))) as SPSnapshot[]
  },

  getSPFilters(type: SPTypes) {
    return self.sp.filter[type] as SPFiltersSnapshot
  },

  getUsedFilters(type: SPTypes) {
    const filter = self.sp.filter[type]
    let count = 0
    let field: keyof SPFiltersSnapshot
    for (field in filter) {
      const fieldValue = filter[field]
      if (isObservableArray(fieldValue)) {
        count += fieldValue.length > 0 ? 1 : 0
      } else {
        count += fieldValue ? 1 : 0
      }
    }

    // otherwise it adds up 2 instead of 1 for these properties
    if (filter.min_experience && filter.max_experience) {
      count -= 1
    }
    if (filter.min_hourly_rate && filter.max_hourly_rate) {
      count -= 1
    }
    if (filter.min_height && filter.max_height) {
      count -= 1
    }
    if (filter.min_age && filter.max_age) {
      count -= 1
    }

    return count
  },
})

export enum SPTypes {
  STAFF = 'staff',
  SAVED = 'saved',
  SEACH = 'search',
}
