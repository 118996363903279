import { useEffect, FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { PaymentMethod, PaymentStatus } from '../../../../../models/payment'
import { useDelayedQuery, useQuery, useStore } from '../../../../../utils/mst-hooks'
import { getPaymentDetailsStatusLabel, paymentMethodsData } from '../../../utils'
import { trackingService, TrackedEvent } from '../../../../../services/tracking-service'
import { UIProps } from './types'
import { PaymentOptions } from '../../../types'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

export function createCardPaymentStatusContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ paymentId, paymentOptions }: { paymentId: number; paymentOptions?: string }) => {
      const redirect = useRedirect()
      const { t } = useTranslation()

      const { getPayment, currentPayment } = useStore().data
      const getPaymentQuery = useQuery(() => getPayment(paymentId))
      const {
        query: { isLoading: isRepeatedLoading },
        enableQuery: getPaymentRepeatedQuery,
      } = useDelayedQuery(() => getPayment(paymentId))

      useEffect(() => {
        trackingService.track(TrackedEvent.ViewedPaymentCompleted)
      }, [])

      useEffect(() => {
        const getPaymentTimeout = setTimeout(() => {
          getPaymentRepeatedQuery()
        }, 3000)

        return () => clearTimeout(getPaymentTimeout)
      }, [])

      const paymentOptionsObject = paymentOptions ? (JSON.parse(paymentOptions) as PaymentOptions) : null
      const nextAction = () => {
        if (!paymentOptionsObject?.redirectToRouteAfterSuccess) {
          redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })
          return
        }

        redirect(
          routes[paymentOptionsObject.redirectToRouteAfterSuccess as 'orderConfirmation' | 'orderRequestSuccess'],
          paymentOptionsObject.redirectToRouteAfterSuccessParams as { orderId: string },
        )
        return
      }

      return (
        <UIComponent
          paymentId={paymentId}
          isCompleted={String(currentPayment?.status) === PaymentStatus.Completed}
          clientEmail={currentPayment?.client?.email}
          isLoading={getPaymentQuery.isLoading}
          isUpdatingStatus={isRepeatedLoading}
          paymentNumber={currentPayment?.payment_number}
          fulfilledAt={currentPayment?.fulfilled_at}
          paymentMethodTitle={
            currentPayment
              ? t(paymentMethodsData[String(currentPayment.type_id ?? PaymentMethod.Card) as PaymentMethod].title)
              : ''
          }
          amount={`${currentPayment?.currency_code} ${currentPayment?.amount}`}
          status={t(getPaymentDetailsStatusLabel(String(currentPayment?.status)))}
          statusId={Number(currentPayment?.status)}
          nextAction={nextAction}
          nextActionTitle={paymentOptionsObject?.redirectToRouteAfterSuccessTitle ?? null}
        />
      )
    }),
  )
}
