import React from 'react'

// utility to create error boundary as functional components
export function createBoundary<
  T extends {
    children: React.ReactElement
  },
>(
  Component: React.ComponentType<{ error: Error | null; resetError: () => void; children: React.ReactElement }>,
  config?: { didCatch?: (error: Error, errorInfo: React.ErrorInfo) => void },
) {
  return class Boundary extends React.Component<T, { error: Error | null }> {
    state: { error: Error | null } = { error: null }
    static getDerivedStateFromError(error: Error) {
      return { error: error }
    }
    componentDidCatch = config?.didCatch
    reset = () => this.setState({ error: null })
    render() {
      return (
        <Component error={this.state.error} resetError={this.reset}>
          {this.props.children}
        </Component>
      )
    }
  }
}
