import { t } from '../../../translations'
import { routes } from '../../../routes'
import { images } from '../assets/invoices'
import '../onboarding.scss'

export const invoicesOnboarding = {
  title: t('invoices_onboarding.title'),
  paths: [routes.invoices.path],
  pages: [
    {
      title: t('invoices_onboarding.invoice_list_title'),
      content: [
        { image: { src: images.invoicesListImg, className: 'Onboarding__bigImg' } },
        { description: t('invoices_onboarding.invoice_list') },
      ],
    },
    {
      title: t('invoices_onboarding.invoice_breakdown_title'),
      content: [
        { image: { src: images.invoiceBreakdownImg, className: 'Onboarding__bigImg' } },
        { description: t('invoices_onboarding.invoice_breakdown') },
      ],
    },
    {
      title: t('invoices_onboarding.shifts_breakdown_title'),
      content: [
        { image: { src: images.invoiceShiftsBreakdown, className: 'Onboarding__bigImg' } },
        { description: t('invoices_onboarding.shifts_breakdown') },
      ],
    },
    {
      title: t('invoices_onboarding.export_invoice_title'),
      content: [
        { image: { src: images.exportInvoiceImg, className: 'Onboarding__bigImg' } },
        { description: t('invoices_onboarding.export_invoice') },
      ],
    },
  ],
}
