import { useTranslation, Trans } from 'react-i18next'

import { Modal } from '../../../../../components/modal/modal'
import beaconImg from '../../../../../components/assets/beacon.svg'
import { ButtonPrimary } from '../../../../../components/button/button-primary/button-primary'
import './chief-popup.scss'

type ChiefPopupProps = {
  imgSrc: string
  name: string
  onClose: () => void
}

export const ChiefPopup = ({ imgSrc, name, onClose }: ChiefPopupProps) => {
  const { t } = useTranslation()

  return (
    <Modal onClose={onClose}>
      <div className="ChiefPopup">
        <span className="ChiefPopup__title">{t('job_cherry_picking.chief_title')}</span>
        <span className="ChiefPopup__subtitle">{t('job_cherry_picking.chief_subtitle')}</span>
        <div className="ChiefPopup__imageContainer">
          <img src={imgSrc} className="ChiefPopup__imageContainer__avatar" />
          <img src={beaconImg} className="ChiefPopup__imageContainer__beacon" />
        </div>
        <span className="ChiefPopup__name">{name}</span>
        <span className="ChiefPopup__chiefLabel">{t('job_cherry_picking.chief_og')}</span>
        <span className="ChiefPopup__instructionsTitle">{t('job_cherry_picking.what_is_chief')}</span>
        <span className="ChiefPopup__instructionsDescription">
          <Trans key="job_cherry_picking.chief_definition">
            A Chief OG is a team leader, carefully selected by us to help manage larger Ogram teams. For example, by
            resolving disputes and coordinating team members - at no extra cost to you.
            <br />
            And all this is{' '}
            <span className="ChiefPopup__instructionsDescription__highlight">completely free for you.</span>
          </Trans>
        </span>
        <ButtonPrimary text={t('job_cherry_picking.chief_button_label')} action={onClose} />
      </div>
    </Modal>
  )
}
