import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { selectDesignationPage } from '../pages/select-designation'

export const selectDesignationOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [routes.setJobCategory.path],
  pages: [selectDesignationPage],
}
