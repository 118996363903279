import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { t } from '../../../../translations'
import { BoardingStatus } from '../../../../models/job'
import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export const BoardingModel = types
  .model('Boarding', {
    use_boarding: types.optional(types.boolean, false),
    value: types.maybeNull(types.number),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(boardingStatus: BoardingStatus) {
      checkValueIsInEnum(boardingStatus)
      self.value = boardingStatus
      self.error = null
    },

    useBoarding(boardingOffered: boolean) {
      self.use_boarding = boardingOffered
      if (!boardingOffered) {
        self.value = null
      }
      self.error = null
    },

    validate() {
      if (self.value !== null) {
        checkValueIsInEnum(self.value)
      }

      if (self.use_boarding && self.value === null) {
        self.error = t('job_draft_details.boarding_required')
        return false
      }
      self.error = null
      return true
    },
  }))

function checkValueIsInEnum(value: BoardingStatus) {
  if (!Object.values(BoardingStatus).includes(value)) {
    throw new Error(`Unexpected id ${value} for boarding`)
  }
}

export function createBoardingMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.boarding) {
      return abort(targetInstance.boarding.validate())
    }
    return next(call)
  })
}

export type BoardingInstance = Instance<typeof BoardingModel>
