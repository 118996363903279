import { useEffect, useState, RefObject } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { FormikProps } from 'formik'

import { useStore, useQuery } from '../../../../../utils/mst-hooks'
import { BusinessLocationForm, BusinessLocationSnapshot } from '../../../../../models/location'
import { Separator } from '../../../../app-layout/separator/separator'
import { LoadingSpinner } from '../../../../loading-spinner/loading-spinner'
import { Select } from '../../../../form/select/select'
import { OgramModal } from '../../../../ogram-modal/ogram-modal'
import { Button } from '../../../../button/button'
import AddLocation from '../../../../../pages/locations/add-location/add-location'
import crossImg from '../../../../assets/cross-icon-black.svg'
import locationImg from './assets/location.svg'
import { JobDraftFieldError } from '../error/error'
import './location.scss'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'

type InputProps = {
  locationFormRef?: RefObject<FormikProps<BusinessLocationForm>> | null
  onLocationsLoaded?: (hasLocations: boolean) => void
  onLocationFormSubmit?: () => void
  job: DraftJobInstance
}

export const Location = observer(({ locationFormRef, onLocationsLoaded, onLocationFormSubmit, job }: InputProps) => {
  const { locationsDataView, getLocations, getLocationByName } = useStore().data

  const [regionsModalVisible, setRegionsModalVisible] = useState(false)

  const { isLoading: locationsLoading } = useQuery(getLocations)

  useEffect(() => {
    if (!job.location.value && locationsDataView.length > 0) {
      const { id, name, public_transport_accessibility } = locationsDataView[0]
      job.location.set({ id, name })
      job.manager.set(null)

      if (public_transport_accessibility !== null) {
        job.fields.removePublicTransportAccessibilityField()
      } else {
        job.fields.addPublicTransportAccessibilityField()
      }
    }

    if (onLocationsLoaded) {
      onLocationsLoaded(Boolean(locationsDataView.length))
    }
  }, [locationsDataView, locationsDataView.length])

  const locationOptions =
    locationsDataView.length > 0
      ? locationsDataView.map(location => ({
          key: String(location.id),
          label: location.name,
          value: location.name,
        }))
      : null

  const { t } = useTranslation()

  return (
    <div className="JobDraftLocation">
      <span className="Popup__title">{t('job_draft_details.location_title')}</span>
      <span className="Popup__subtitle JobDraftLocation__Popup__subtitle">
        {t('job_draft_details.location_subtitle')}
      </span>
      {locationsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {locationOptions === null ? (
            <AddLocation
              formRef={locationFormRef}
              onSubmit={onLocationFormSubmit}
              showSubmit={false}
              showLocationNameField={false}
              showAddressDetailsField={false}
              showImageField={false}
              showCheckInRestrictionsBlock={false}
              onLocationSelected={job.location.clearError}
            >
              {!locationFormRef?.current?.errors && job.location.error && (
                <JobDraftFieldError error={job.location.error} style={{ position: 'absolute' }} />
              )}
            </AddLocation>
          ) : (
            <>
              <Select
                label={t('job_draft_details.location')}
                value={job.location.value?.name}
                onChange={value => {
                  const { id, name, public_transport_accessibility } = getLocationByName(
                    value,
                  ) as BusinessLocationSnapshot
                  job.location.set({ id, name })
                  job.manager.set(null)

                  if (public_transport_accessibility !== null) {
                    job.fields.removePublicTransportAccessibilityField()
                  } else {
                    job.fields.addPublicTransportAccessibilityField()
                  }
                }}
                options={locationOptions}
                className="JobDraftLocation__locationSelect"
              />
              {job.location.error && <JobDraftFieldError error={job.location.error} />}
              <Separator.Vertical height={35} />
              <Button
                onClick={() => {
                  setRegionsModalVisible(true)
                }}
                className="JobDraftLocation__addLocationButton"
              >
                <img src={locationImg} className="JobDraftLocation__addLocationButton__img" />
                <span className="JobDraftLocation__addLocationButton__title">
                  {t('job_draft_details.location_add_new')}
                </span>
              </Button>
              <OgramModal
                modalVisible={regionsModalVisible}
                onClose={() => {
                  setRegionsModalVisible(false)
                }}
              >
                <div className="JobDraftLocation__modalHeader">
                  <div />
                  <p className="JobDraftLocation__modalHeader__modalTitle">{t('job_draft_details.location_add')}</p>
                  <img
                    src={crossImg}
                    onClick={() => {
                      setRegionsModalVisible(false)
                    }}
                    className="JobDraftLocation__modalHeader__modalCloseImg"
                  />
                </div>
                <AddLocation
                  onSubmit={() => {
                    setRegionsModalVisible(false)
                  }}
                  showSubmit
                  showLocationNameField
                  showAddressDetailsField
                  showImageField
                  showCheckInRestrictionsBlock
                />
              </OgramModal>
            </>
          )}
        </>
      )}
    </div>
  )
})
