import { Link, Route } from '../../services/router'
import './button-link.scss'

export function ButtonLink<Params extends object>({
  url,
  title,
  params,
  children,
  className,
}: {
  url: Route<Params>
  title?: string
  children?: React.ReactNode
  params?: Params
  className?: string
}) {
  return (
    <Link route={url} params={params ?? ({} as Params)} className="ButtonLink">
      <div className={`ButtonLink__container ${className ?? ''}`}>
        {children ?? <span className="ButtonLink__label">{title}</span>}
      </div>
    </Link>
  )
}
