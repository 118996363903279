import { ApiErrorTypes } from 'ogram-react'
import { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import arrowRightIcon from '../../../components/assets/arrow-right.svg'
import locationIcon from '../../../components/assets/location-icon-grey.svg'
import calendarIcon from '../../../components/assets/calender-icon-grey.svg'
import { TimeInput } from '../../../components/form/time-input/time-input'
import './qr-attendance.scss'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { routes } from '../../../routes'
import { useRedirect } from '../../../services/router'
import { UIStateContext } from '../../../components/ui-state-management/ui-state-management'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { formatDateToIso8601, utcOffsetSecs } from '../../../utils/datetime-utils'
import { SuccessModal } from './components/success-modal/success-modal'
import { StickyPanel } from '../../../components/sticky-panel/sticky-panel'

export const QrAttendance = withErrorBoundary(
  observer(({ spId }: { spId: number }) => {
    const { t } = useTranslation()

    const { qrAttendance, getCheckinEligibility, checkInOgrammerWithQR, checkOutOgrammerWithQR } = useStore().data

    const [value, setValue] = useState<string | null>(null)
    const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)

    const { isLoading: isQrAttendanceLoading } = useQuery(() => getCheckinEligibility({ spId }), {
      onSuccess: res => {
        const currentUnixInOriginLocale = Date.now() / 1000 + res.timezone_offset + utcOffsetSecs
        setValue(moment.unix(currentUnixInOriginLocale).format('HH:mm'))
      },
      onError: () => {
        toast.error(t('qr_scan.sp_cant_be_checked_in_or_out'))
        redirect(routes.allJobs, { date: formatDateToIso8601(new Date()) })
      },
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
    })
    const redirect = useRedirect()

    const { setShowQRScanner } = useContext(UIStateContext)

    useEffect(() => {
      setShowQRScanner(false)
    }, [])

    const [checkInResult, checkIn] = useMutation(
      (params: { id: number; time: string }) => checkInOgrammerWithQR(params),
      {
        onSuccess: () => {
          setIsSuccessModalVisible(true)
        },
        onError: err => {
          toast.error(err.data.message)
        },
        filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
      },
    )

    const [checkOutResult, checkOut] = useMutation(
      (params: { id: number; time: string }) => checkOutOgrammerWithQR(params),
      {
        onSuccess: () => {
          setIsSuccessModalVisible(true)
        },
        onError: err => {
          toast.error(err.data.message)
        },
        filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound],
      },
    )

    if (!qrAttendance) {
      return null
    }

    const actionText = qrAttendance.is_checkin
      ? t('shift_attendance_common.check_in')
      : t('shift_attendance_common.check_out')
    const isLoading = checkInResult.isLoading || checkOutResult.isLoading

    return (
      <div className="QrAttendance">
        <Header
          title={`${t('qr_scan.qr')} ${actionText}`}
          handleBackClick={() => redirect(routes.index, {})}
          showBack
        />
        <Separator.Vertical height={35} />
        {isQrAttendanceLoading || value === null ? (
          <LoadingSpinner />
        ) : (
          <div className="QrAttendance__container">
            <div className="QrAttendance__content">
              <div className="QrAttendance__profile">
                <img className="QrAttendance__profile__image" src={String(qrAttendance.sp_image)} />
                <span className="QrAttendance__profile__name">{qrAttendance.sp_full_name}</span>
                <span className="QrAttendance__profile__id">{t('common.id_n', { id: qrAttendance.sp_id })}</span>
              </div>
              <Separator.Vertical height={20} />
              <div className="QrAttendance__order">
                <div className="QrAttendance__order__info">
                  <span className="QrAttendance__order__info__number">
                    {t('order.order_number', { id: qrAttendance.order_id })}
                  </span>
                  <span className="QrAttendance__order__info__designation">
                    {qrAttendance.work_category_name} ({qrAttendance.job_amount})
                  </span>
                </div>
                <img src={arrowRightIcon} />
              </div>
              <Separator.Vertical height={12} />
              <div className="QrAttendance__stat-item">
                <img src={locationIcon} />
                <span>{qrAttendance.location_name}</span>
              </div>
              <Separator.Vertical height={12} />
              <div className="QrAttendance__stat-item">
                <img src={calendarIcon} />
                <span>
                  {moment
                    .unix(qrAttendance.shift_start_time + qrAttendance.timezone_offset + utcOffsetSecs)
                    .format('dddd, DD MMM')}
                </span>
              </div>
              <Separator.Vertical height={24} />
            </div>
            <Separator.Vertical height={200} />
            <StickyPanel contentStyle={{ flexDirection: 'column', position: 'relative' }}>
              <TimeInput
                label={actionText}
                value={value}
                handleChange={time => {
                  setValue(time)
                }}
                className="QrAttendance__timePicker"
                useDropdownWithInput
                required
              />
              <button
                className="QrAttendance__buttonSetDefault"
                onClick={() => {
                  const originalTime = qrAttendance.is_checkin
                    ? qrAttendance.shift_start_time + qrAttendance.timezone_offset + utcOffsetSecs
                    : qrAttendance.shift_end_time + qrAttendance.timezone_offset + utcOffsetSecs

                  const originalTimeValue = moment.unix(originalTime).format('HH:mm')

                  setValue(originalTimeValue)
                }}
              >
                {t('qr_scan.set_default_time')}
              </button>
              <Separator.Vertical height={8} />
              <button
                onClick={() => {
                  const params = { id: qrAttendance.sp_active_shift_id, time: value }
                  const action = qrAttendance.is_checkin ? checkIn : checkOut

                  action(params)
                }}
                className={`QrAttendance__action ${qrAttendance.is_checkin ? '' : ' QrAttendance__action--red'}`}
              >
                {isLoading ? t('common.loading') : actionText}
              </button>
            </StickyPanel>
          </div>
        )}
        <SuccessModal
          isSuccessModalVisible={isSuccessModalVisible}
          isCheckin={qrAttendance.is_checkin}
          setShowQRScanner={setShowQRScanner}
        />
      </div>
    )
  }),
)
