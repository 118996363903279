import { useState, createContext } from 'react'

type UIStateType = {
  isRtl: boolean
  showHelpButton: boolean
  setShowHelpButton: (value: boolean) => void
  showPendingPaymentsPanel: boolean
  setShowPendingPaymentsPanel: (value: boolean) => void
  showQRScanner: boolean
  setShowQRScanner: (value: boolean) => void
}

const initialUIState: UIStateType = {
  isRtl: false,
  showHelpButton: true,
  setShowHelpButton: (value: boolean) => {},
  showPendingPaymentsPanel: true,
  setShowPendingPaymentsPanel: (value: boolean) => {},
  showQRScanner: false,
  setShowQRScanner: (value: boolean) => {},
}

export const UIStateContext = createContext<UIStateType>(initialUIState)

export const UiStateManagement = ({ isRtl, children }: { isRtl: boolean; children: React.ReactNode }) => {
  const [showHelpButton, setShowHelpButton] = useState<boolean>(true)
  const [showPendingPaymentsPanel, setShowPendingPaymentsPanel] = useState<boolean>(true)
  const [showQRScanner, setShowQRScanner] = useState<boolean>(false)

  return (
    <UIStateContext.Provider
      value={{
        isRtl,
        showHelpButton,
        setShowHelpButton,
        showPendingPaymentsPanel,
        setShowPendingPaymentsPanel,
        showQRScanner,
        setShowQRScanner,
      }}
    >
      {children}
    </UIStateContext.Provider>
  )
}
