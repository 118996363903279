import attendanceDetailsImg from './attendance-details.png'
import attendanceStatusChangeImg from './attendance-status-change.png'
import attendanceTimeChangeImg from './attendance-time-change.png'
import confirmationTimeImg from './confirmation-time.png'
import jobsPendingReviewImg from './jobs-pending-review.png'
import notAttendedReasonChangeImg from './not-attended-reason-change.png'

export const images = {
  attendanceDetailsImg,
  attendanceStatusChangeImg,
  attendanceTimeChangeImg,
  confirmationTimeImg,
  jobsPendingReviewImg,
  notAttendedReasonChangeImg,
}
