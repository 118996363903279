import { useState, FunctionComponent } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'
import { toast } from 'react-toastify'

import { UIProps } from './types'
import { DraftJobInstance } from '../../../../models-ui/order/store/draft-job'
import { useMutation, useQuery, useStore } from '../../../../utils/mst-hooks'
import { OrderJobDetailsModel, OrderJobDetailsSnapshot } from '../../../../models/order/order-job-details'
import { createDraftJobFromDetailsInstance } from '../../order-draft/utils/create-draft-job-data-from-details-instance'
import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import { getFormForJobForm } from '../../../../models/job'
import { DuplicatedOrder } from '../../../../models/duplicated-order'
import { jobFormFromDraftJobInstance } from '../../../../models-ui/order/utils/job-form-from-draft-job-instance'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'

export function createOrderDuplicateContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ duplicatedOrderId }: { duplicatedOrderId: number }) => {
      const [draftJobs, setDraftJobs] = useState<DraftJobInstance[] | null>(null)

      const { getDuplicatedOrderJobs, duplicateOrder, createInterview, duplicatedOrderJobIds } = useStore().data
      const getJobsQuery = useQuery(() => getDuplicatedOrderJobs(duplicatedOrderId), {
        onSuccess: result => {
          setDraftJobs(
            result
              .filter((job: OrderJobDetailsSnapshot) => duplicatedOrderJobIds.includes(job.id))
              .map((job: OrderJobDetailsSnapshot): DraftJobInstance => {
                const jobDetails = OrderJobDetailsModel.create(job)
                return createDraftJobFromDetailsInstance(jobDetails)
              }),
          )
        },
      })

      const [currentJobIndex, setCurrentJobIndex] = useState(0)

      const { t } = useTranslation()
      const redirect = useRedirect()

      const [, requestCreateInterview] = useMutation(createInterview, {
        filterError: Object.values(ApiErrorTypes), // don't interupt order duplication process
        onError: () => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Create Interview API Error (Order Duplication)')
        },
      })

      const [, duplicateOrderRequest] = useMutation(duplicateOrder, {
        filterError: [ApiErrorTypes.ClientError],
        onSuccess: (result: DuplicatedOrder) => {
          if (draftJobs === null) {
            throw new Error('Unexpected null instead of DraftJobInstance[]')
          }
          draftJobs.forEach((job, index) => {
            if (job.manualSelection.use_interview && job.manualSelection.interview_questions?.length) {
              requestCreateInterview({
                jobId: result.job_ids[index],
                workCategoryId: job.category.id as number,
                questions: job.manualSelection.interview_questions,
              })
            }
          })
          redirect(routes.orderDraft, { orderId: String(result.id), title: t('duplicate_order.duplicate_order') })
        },
        onError: err => {
          const errorMessage = Object.values({ ...err.data.errors }).join(';')
          toast.error(errorMessage)
          setCurrentJobIndex(0)
        },
      })

      const isLatestJob = draftJobs ? currentJobIndex === draftJobs.length - 1 : true
      const isFirstJob = currentJobIndex === 0

      return (
        <UIComponent
          isLoading={getJobsQuery.isLoading}
          draftJobs={draftJobs}
          currentJobIndex={currentJobIndex}
          duplicatedOrderId={duplicatedOrderId}
          isLatestJob={isLatestJob}
          onSubmit={draftJob => {
            const isValid = draftJob.validateForm()
            if (!isValid) return

            if (!isLatestJob) {
              setCurrentJobIndex(currentJobIndex + 1)
              return
            }
            const jobsFormData = (draftJobs as DraftJobInstance[]).map(job =>
              getFormForJobForm(jobFormFromDraftJobInstance(job)),
            )
            duplicateOrderRequest(jobsFormData)
          }}
          getFormTitle={professionName => {
            return `Duplicate ${t('job_draft_details.title', { category: professionName })}`
          }}
          isFirstJob={isFirstJob}
          handleBackPress={() => setCurrentJobIndex(currentJobIndex - 1)}
        />
      )
    }),
  )
}
