import { useMemo } from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'

import { useRedirect } from '../../../services/router'
import { UserRole } from '../../../models/account'
import { useQuery, useStore } from '../../../utils/mst-hooks'
import { Separator } from '..'
import { OrderButton } from './components/order-button/order-button'
import { Submenu } from './components/submenu/submenu'
import { CompanyName } from './components/company-name/company-name'
import { routes } from '../../../routes'
import { ProtectedRoute } from '../../protected-route/protected-route'
import { DraftJobsStoreProvider, draftJobsStore } from '../../../models-ui/order/store'
import { getCurrentMarketConfig } from '../../../services/market-service'
import { images } from './assets'
import appLogo from '../../assets/app-logo.svg'
import appLogoSmall from '../../assets/logo-small.png'
import closeSidebarImg from './assets/close-sidebar.svg'
import closeSidebarDarkImg from './assets/close-sidebar-dark.svg'
import sidebarToggleImg from './assets/sidebar-toggle.svg'
import './sidebar.scss'
import { OrderState } from '../../../models/order/list-item-orders'
import { PaymentStatus } from '../../../models/payment'
import { withErrorBoundary } from '../../error/with-error-boundary'
import { CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS } from '../../../constants'
import { ScanQrButton } from '../../scan-qr-button/scan-qr-button'
import { formatDateToIso8601 } from '../../../utils/datetime-utils'

type Props = {
  active: boolean
  setActive: (active: boolean) => void
}

const defaultUserMenu = [
  {
    title: 'sidebar.register_corporate',
    route: routes.completeRegistration,
    imgSource: images.registerCorporateImg,
    allowedRoles: [UserRole.Admin, UserRole.Owner],
  },
  {
    title: 'sidebar.manage_locations',
    route: routes.locations,
    imgSource: images.manageLocationsImg,
    allowedRoles: [UserRole.Admin, UserRole.Owner],
  },
  {
    title: 'sidebar.users',
    route: routes.users,
    imgSource: images.usersImg,
    allowedRoles: [UserRole.Admin, UserRole.Owner],
  },
  {
    title: 'sidebar.knowledge_center',
    route: routes.knowledgeCenter,
    imgSource: images.registerCorporateImg,
    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance],
  },
]

export const Sidebar = withErrorBoundary(
  observer(({ active, setActive }: Props) => {
    const redirect = useRedirect()

    const { companyDetails, getСompanyDetails } = useStore().data

    useQuery(getСompanyDetails)

    const isCorporate = Boolean(companyDetails?.is_corporate)
    const isCorporateDetailsIncomplete =
      isCorporate && CLIENT_STATUSES_ALLOWED_TO_CHANGE_CORPORATE_DETAILS.includes(Number(companyDetails?.status_id))
    const showRegisterCorporateMenuItem = !isCorporate || isCorporateDetailsIncomplete

    const accountMenuItems = useMemo(() => {
      return defaultUserMenu.filter(
        menuItem => showRegisterCorporateMenuItem || menuItem.route !== routes.completeRegistration,
      )
    }, [showRegisterCorporateMenuItem])

    const hideInvoices = useMemo(() => !getCurrentMarketConfig().finance.showInvoices, [])

    const toggle = () => setActive(!active)

    return (
      <>
        <img onClick={toggle} src={sidebarToggleImg} className="toggleImg" />
        <div className={`Sidebar ${active ? 'mobile-active' : 'desktop-collapsed'}`}>
          <div className="desktop">
            <div className="Sidebar__desktop-header">
              {active ? (
                <>
                  <img
                    onClick={() => {
                      redirect(routes.index, {})
                    }}
                    src={appLogo}
                    alt="app logo"
                    className="Sidebar__desktop-header__app-logo"
                  />
                  <img
                    onClick={toggle}
                    src={closeSidebarImg}
                    alt="menu button"
                    className="Sidebar__header__close-sidebar-img"
                  />
                </>
              ) : (
                <>
                  <img src={appLogoSmall} alt="" className="Sidebar__desktop-header__app-logo-small" />
                  <img
                    onClick={toggle}
                    src={closeSidebarDarkImg}
                    alt="menu button"
                    className="Sidebar__header__app-logo"
                  />
                </>
              )}
            </div>
          </div>
          <div className="Sidebar__mobile-container">
            <div className="Sidebar__mobile-inner-container mobile">
              <div className="Sidebar__mobile-inner-container__header">
                <img src={appLogo} alt="app logo" className="Sidebar__mobile-app-logo" />
                <img onClick={toggle} src={closeSidebarImg} alt="" className="MobileSidebar__close-sidebar-img" />
              </div>
            </div>
            <CompanyName />
            <Separator.Vertical height={15} />
            <ProtectedRoute
              allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance]}
              showDefaultFallbackUI={false}
            >
              <DraftJobsStoreProvider value={draftJobsStore}>
                <div className="Sidebar__inner-container">
                  <OrderButton />
                  <ScanQrButton
                    style={{ width: '100%', marginTop: '10px', padding: '10px 0' }}
                    textStyle={{ fontSize: '16px', fontWeight: '400' }}
                  />
                </div>
              </DraftJobsStoreProvider>
            </ProtectedRoute>
            <Separator.Vertical height={15} />
            <Submenu
              items={[
                {
                  title: 'sidebar.home',
                  route: routes.index,
                  imgSource: images.homeImg,
                  allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Finance, UserRole.Manager],
                },
              ]}
            />
            <div className="mobile">
              <Separator.Vertical height={9} />
            </div>
            <div>
              <Submenu
                sectionTitle="sidebar.orders_uppercase"
                items={[
                  {
                    title: 'sidebar.all_jobs',
                    route: routes.allJobs,
                    params: {
                      date: formatDateToIso8601(new Date()),
                    },
                    imgSource: images.allJobsImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager],
                  },
                  {
                    title: 'staff.past_workers',
                    route: routes.pastWorkers,
                    imgSource: images.applicantsReviewImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager],
                  },
                  {
                    title: 'sidebar.attendance_review',
                    route: routes.attendanceShiftsList,
                    imgSource: images.attendanceReviewImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance],
                  },
                  {
                    title: 'sidebar.orders',
                    route: routes.orders,
                    params: {
                      ordersState: OrderState.Unconfirmed,
                    },
                    imgSource: images.ordersImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager, UserRole.Finance],
                  },
                  {
                    title: 'sidebar.applicants_review',
                    route: routes.applicantsReview,
                    imgSource: images.applicantsReviewImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager],
                  },
                  {
                    title: 'sidebar.rating_requests',
                    route: routes.jobRatingRequests,
                    imgSource: images.starImg,
                    allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Manager],
                  },
                ]}
              />
            </div>
            <ProtectedRoute
              allowedRoles={[UserRole.Admin, UserRole.Owner, UserRole.Finance]}
              showDefaultFallbackUI={false}
            >
              <div>
                <Submenu
                  sectionTitle="sidebar.billing"
                  items={[
                    ...(hideInvoices
                      ? []
                      : [
                          {
                            title: 'sidebar.invoices',
                            route: routes.invoices,
                            imgSource: images.invoiceImg,
                            allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Finance],
                          },
                        ]),
                    ...[
                      {
                        title: 'sidebar.payments',
                        route: routes.paymentList,
                        params: {
                          paymentStatus: String(PaymentStatus.Pending),
                        },
                        imgSource: images.paymentImg,
                        allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Finance],
                      },
                      {
                        title: 'sidebar.payment_methods',
                        route: routes.savedPaymentMethods,
                        imgSource: images.invoiceImg,
                        allowedRoles: [UserRole.Admin, UserRole.Owner, UserRole.Finance],
                      },
                    ],
                  ]}
                />
              </div>
            </ProtectedRoute>
            <Submenu sectionTitle="sidebar.account" items={accountMenuItems} />
          </div>
        </div>
        <div onClick={toggle} className="Sidebar__overlay-foreground" />
      </>
    )
  }),
  { [ApiErrorTypes.ServerError]: 'sidebar.error_failed_to_load_sidebar' },
)
