import { Instance, types, addMiddleware } from 'mobx-state-tree'

import { DraftJobFields } from './fields'
import { DraftJobInstance } from '../draft-job'

export enum Genders {
  other = '0',
  male = '1',
  female = '2',
}

export const GenderModel = types
  .model('Gender', {
    use_gender: types.optional(types.boolean, false),
    value: types.maybeNull(types.enumeration<Genders>('Genders', Object.values(Genders))),
    error: types.maybeNull(types.string),
  })
  .views(self => ({
    get hasError() {
      return self.error !== null
    },
  }))
  .actions(self => ({
    set(gender: Genders | null) {
      if (gender !== null) {
        checkValueIsInEnum(gender)
      }
      self.value = gender
      self.error = null
    },

    useGender(genderRequired: boolean) {
      self.use_gender = genderRequired
      if (!genderRequired) {
        self.value = null
      }
      self.error = null
    },

    validate() {
      if (self.use_gender && self.value !== null) {
        checkValueIsInEnum(self.value)
      }
      self.error = null
      return true
    },
  }))

function checkValueIsInEnum(value: Genders) {
  if (!Object.values(Genders).includes(value)) {
    throw new Error(`Unexpected id ${value} for gender`)
  }
}

export function createGenderMiddleware(targetInstance: DraftJobInstance) {
  addMiddleware(targetInstance, (call, next, abort) => {
    if (call.type === 'action' && call.args[0] === DraftJobFields.gender) {
      return abort(targetInstance.gender.validate())
    }
    return next(call)
  })
}

export type GenderInstance = Instance<typeof GenderModel>
