import { CSSProperties } from 'react'
import './error.scss'

export function JobDraftFieldError({ error, style }: { error: string | null; style?: CSSProperties }) {
  if (!error) return null
  return (
    <div className="JobDraftFieldError" style={style}>
      {error}
    </div>
  )
}
