import { cast, flow, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'
import { OrderJobDetailsSnapshot } from './order/order-job-details'

export const DuplicatedOrderModel = types.model('DuplicatedOrderJobIds', {
  jobIds: types.array(types.number),
})

export const duplicatedOrderActions = (self: DataStore) => ({
  getDuplicatedOrderJobs: flow(function* (duplicatedOrderId: number) {
    return (yield self.request('post', `client/order/jobs/get`, {
      order_id: duplicatedOrderId,
    })) as OrderJobDetailsSnapshot[]
  }),

  duplicateOrder: flow(function* (jobs: Record<string, unknown>[]) {
    return (yield self.request('post', 'client/order/jobs/bulk/post', { jobs })) as DuplicatedOrder
  }),

  addDuplicatedJobId: function (jobId: number) {
    self.duplicatedOrder.jobIds.push(jobId)
  },

  removeDuplicatedJobId: function (jobId: number) {
    self.duplicatedOrder.jobIds = cast(self.duplicatedOrder.jobIds.filter(addedJobId => jobId !== addedJobId))
  },
})

export const duplicatedOrderViews = (self: DataStore) => ({
  get duplicatedOrderJobIds() {
    return self.duplicatedOrder.jobIds
  },
})

export type DuplicatedOrder = {
  id: number
  name: string
  is_draft: number
  job_ids: number[]
}
