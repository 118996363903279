import { getEnv } from 'mobx-state-tree'
import { ApiRest } from 'ogram-react'

import { DataStore } from './data-store/data-store'
import { Enviroment } from './environment/environment'

export const commonViews = (self: DataStore) => ({
  get apiHost() {
    return (getEnv<Enviroment>(self).api as ApiRest).api.defaults.baseURL as string
  },
})
