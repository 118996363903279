import { OnboardingContent } from '../../../page-onboarding/page-onboarding'
import { t } from '../../../../translations'
import { routes } from '../../../../routes'
import { shiftsBreakdownPage } from '../pages/shifts-breakdown'
import { duplicateJobPage } from '../pages/duplicate-job'
import { sendForReviewPage } from '../pages/send-for-review'

export const orderDraftOnboarding: OnboardingContent = {
  title: t('order_creation_onboarding.title'),
  paths: [routes.orderDraft.path],
  pages: [shiftsBreakdownPage, duplicateJobPage, sendForReviewPage],
}
