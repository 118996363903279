import * as Yup from 'yup'

import { Country } from './types'

const getValidationRules = (phoneRequiredText: string, phoneInvalidText: string, country: Country) => {
  return Yup.string()
    .required(phoneRequiredText)
    .test('isValidPhone', phoneInvalidText, (value) => {
      const regExpStart =
        country.regionCodes.length > 0
          ? `^${country.dialCode}(${country.regionCodes.join('|')})`
          : `^${country.dialCode}`
      const regExpEnd =
        Boolean(country.minLocalPhonePartLength) && Boolean(country.maxLocalPhonePartLength)
          ? `\\d{${country.minLocalPhonePartLength},${country.maxLocalPhonePartLength}}`
          : `\\d+`

      const regExp = new RegExp(`${regExpStart}${regExpEnd}`)
      return regExp.test(value as string)
    })
}

export default getValidationRules
