import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import {
  LeanSecondaryStatus,
  LeanSetupStatus,
  LeanStatus,
  PaymentExternalStatus,
  PaymentMethod,
  PaymentStatus,
} from '../models/payment'
import { routes } from '../routes'
import { useRedirect } from './router'
import { useMutation, useStore } from '../utils/mst-hooks'
import { leanService } from './lean-service'
import { TrackedEvent, trackingService } from './tracking-service'

export const useOnPayHandler = () => {
  const redirect = useRedirect()
  const { initLeanPayment } = useInitLeanPayment()
  const { authorizeLeanPayment } = useAuthorizeLeanPayment()

  return ({
    id,
    type_id,
    external_status,
    external_id,
  }: {
    id: number
    type_id: number | null
    external_status: number | null
    external_id: string | null
  }) => {
    const routeParams = { paymentId: String(id) }

    if (type_id === Number(PaymentMethod.Wire)) {
      redirect(routes.wireTransferUploadSlip, routeParams)
      return
    }

    if (type_id === Number(PaymentMethod.Lean)) {
      if (external_status === PaymentExternalStatus.AWAITING_AUTHORIZATION) {
        authorizeLeanPayment(id, external_id)
        return
      }

      initLeanPayment(id, external_id)
      return
    }

    if (type_id === Number(PaymentMethod.Card)) {
      redirect(routes.payWithCard, routeParams)
      return
    }

    redirect(routes.paymentMethodsList, routeParams)
  }
}

export const useIsPaymentsEnabled = () => {
  return () => {
    return true
  }
}

export const useIsLeanPaymentsEnabled = () => {
  return () => {
    return false
  }
}

export const useConnectLean = (
  onConnect?: (responseObject: { status: string | null; secondary_status: string | null }) => void,
) => {
  const redirect = useRedirect()
  const { t } = useTranslation()
  const { initLeanClient } = useStore().data

  const onConnectSuccess = onConnect
    ? onConnect
    : (responseObject: { status: string | null }) => {
        if (responseObject.status === String(LeanStatus.SUCCESS)) {
          redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })

          toast.success(t('payment.successfully_connected_bank'))
        }
      }

  const [initLeanClientResult, initLeanClientRequest] = useMutation(initLeanClient, {
    onSuccess: result => leanService.connect(result.client_lean_id, result.user_lean_id, onConnectSuccess),
  })

  const isLoading = initLeanClientResult.isLoading
  const connectLean = () => {
    initLeanClientRequest({})
  }

  return { connectLean, isLoading }
}

export const useInitLeanPayment = () => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  const {
    initLeanClientForPayment,
    initLeanPaymentIntent,
    resetPayment,
    setPaymentExternalStatus,
    setPaymentExternalId,
    setPaymentStatus,
  } = useStore().data

  const [resetPaymentResult, resetPaymentRequest] = useMutation((paymentId: number) => resetPayment(paymentId))

  const [initLeanPaymentIntentResult, initLeanPaymentIntentRequest] = useMutation(
    (params: { endUserId: string; paymentId: number }) => initLeanPaymentIntent(params.paymentId, params.endUserId),
    {
      onSuccess: result => {
        const onPaySuccess = (response: { status: string | null; secondary_status: string | null }) => {
          if ([String(LeanStatus.CANCELLED), String(LeanStatus.ERROR)].includes(String(response.status))) {
            resetPaymentRequest(result.paymentId)
          }

          if (response.status === String(LeanStatus.SUCCESS)) {
            if (response.secondary_status === String(LeanSecondaryStatus.AWAITING_AUTHORIZATION)) {
              setPaymentExternalStatus(result.paymentId, PaymentExternalStatus.AWAITING_AUTHORIZATION)

              redirect(routes.paymentDetails, { paymentId: String(result.paymentId) })
              toast.success(t('payment.payment_has_been_queued'))
            }

            if (response.secondary_status === String(LeanSecondaryStatus.ACCEPTED_BY_BANK)) {
              trackingService.track(String(TrackedEvent.LeanPaymentPaid), { paymentId: result.paymentId })

              setPaymentStatus(result.paymentId, Number(PaymentStatus.Completed))
              setPaymentExternalStatus(result.paymentId, null)

              redirect(routes.cardPaymentStatus, { paymentId: String(result.paymentId) })
              toast.success(t('payment.successfully_paid'))
            }
          }
        }

        setPaymentExternalId(result.paymentId, result.id)

        trackingService.track(String(TrackedEvent.LeanPaymentMethodChosen), { paymentId: result.paymentId })

        leanService.pay(result.id, result.endUserId, onPaySuccess)
      },
    },
  )

  const [initLeanClientResult, initLeanClientRequest] = useMutation(
    (params: { paymentId: number; externalId: string | null }) =>
      initLeanClientForPayment(params.paymentId, params.externalId),
    {
      onSuccess: result => {
        initLeanPaymentIntentRequest({
          endUserId: result.user_lean_id,
          paymentId: result.paymentId,
        })
      },
    },
  )

  const isLoading = initLeanClientResult.isLoading || initLeanPaymentIntentResult.isLoading
  const isResettingPayment = resetPaymentResult.isLoading

  const initLeanPayment = (paymentId: number, externalId: string | null) => {
    initLeanClientRequest({ paymentId, externalId })
  }

  return { initLeanPayment, isLoading, isResettingPayment }
}

export const useAuthorizeLeanPayment = () => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  const { initLeanClientForPayment, setPaymentStatus, setPaymentExternalStatus } = useStore().data

  const [initLeanClientResult, initLeanClientRequest] = useMutation(
    (params: { paymentId: number; externalId: string | null }) =>
      initLeanClientForPayment(params.paymentId, params.externalId),
    {
      onSuccess: result => {
        const onAuthorizeSuccess = (response: { status: string | null; secondary_status: string | null }) => {
          if (response.status === String(LeanStatus.SUCCESS)) {
            if (response.secondary_status === String(LeanSecondaryStatus.ACCEPTED_BY_BANK)) {
              trackingService.track(String(TrackedEvent.LeanPaymentPaid), { paymentId: result.paymentId })

              setPaymentStatus(result.paymentId, Number(PaymentStatus.Completed))
              setPaymentExternalStatus(result.paymentId, PaymentExternalStatus.AWAITING_AUTHORIZATION)

              toast.success(t('payment.successfully_paid'))
            }

            redirect(routes.cardPaymentStatus, { paymentId: String(result.paymentId) })
          }
        }

        leanService.authorize(result.externalId, result.client_lean_id, result.user_lean_id, onAuthorizeSuccess)
      },
    },
  )

  const isLoading = initLeanClientResult.isLoading

  const authorizeLeanPayment = (paymentId: number, externalId: string | null) => {
    trackingService.track(String(TrackedEvent.LeanPaymentAuthorizationInitiated), { paymentId })
    initLeanClientRequest({ paymentId, externalId })
  }

  return { authorizeLeanPayment, isLoading }
}

export const isLeanSetupInitiated = (leanSetupStatus: number | undefined | null) => {
  return [
    LeanSetupStatus.LEAN_SETUP_STATUS_AWAITING_BENEFICIARY_COOL_OFF,
    LeanSetupStatus.LEAN_SETUP_STATUS_ACTIVE,
  ].includes(Number(leanSetupStatus))
}
