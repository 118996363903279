import { useTranslation } from 'react-i18next'

import { PaymentAlert } from './payment-alert'

export const WireTransferProcessingAlert = () => {
  const { t } = useTranslation()

  return (
    <PaymentAlert
      firstTitle={t('payment.wire_transfer_slip_under_review')}
      secondTitle={t('payment.wire_transfer_out_team_is_checking_slip')}
    />
  )
}
