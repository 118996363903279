import { SpShiftStatus } from '../types'
import { t } from '../../../translations'

export function getShiftStatusTitle(statusId: SpShiftStatus) {
  switch (statusId) {
    case SpShiftStatus.accepted:
      return t('all_jobs.accepted')
    case SpShiftStatus.canceled:
      return t('all_jobs.canceled')
    case SpShiftStatus.completed:
      return t('all_jobs.completed')
    case SpShiftStatus.irrelevant:
      return t('all_jobs.irrelevant')
    case SpShiftStatus.pendingConfirmation:
      return t('all_jobs.pending')
    case SpShiftStatus.started:
      return t('all_jobs.started')
  }
}
