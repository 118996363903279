import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { FormikProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useRedirect } from '../../../services/router'
import { BusinessLocationForm, BusinessLocationSnapshot } from '../../../models/location'
import { Header, Separator } from '../../../components/app-layout'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { useMutation, useStore, useQuery } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { LocationForm } from '../location-form/location-form'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import './add-location.scss'
import { PublicTransportAccessibility } from '../../../models-ui/order/store/job-fields/transport-accessibility'

interface AddLocationPageProps extends React.PropsWithChildren {
  onSubmit?: (location: BusinessLocationSnapshot) => void
  onLocationSelected?: () => void
  formRef?: React.RefObject<FormikProps<BusinessLocationForm>> | null
  showSubmit?: boolean
  showLocationNameField?: boolean
  showAddressDetailsField?: boolean
  showImageField?: boolean
  showHeader?: boolean
  showCheckInRestrictionsBlock?: boolean
}

const AddLocationPage = withErrorBoundary(
  observer((props: AddLocationPageProps) => {
    const store = useStore()
    const navigate = useRedirect()
    const navigateToLocationsList = () => {
      navigate(routes.locations, {})
    }

    const { t } = useTranslation()

    const { isLoading: regionsLoading } = useQuery(store.data.getRegions)

    const [saveResult, save] = useMutation((data: BusinessLocationForm) => store.data.addLocation(data), {
      onSuccess: result => {
        if (props.onSubmit) {
          props.onSubmit(result)
        } else {
          navigateToLocationsList()
        }
      },
      onError: err => {
        toast.error(err.data.message)
      },
      filterError: [ApiErrorTypes.ClientError],
    })

    return (
      <div className="AddLocationPage">
        {props.showHeader && <Header title={t('add_location.title')} showBack={true} />}
        <Separator.Vertical height={28} className="AddLocationPage__topSeparator" />
        <div className="AddLocationPage__container">
          {regionsLoading || saveResult.isLoading ? (
            <LoadingSpinner />
          ) : (
            <LocationForm
              onSubmit={save}
              onDeleteLocation={navigateToLocationsList}
              initialValues={{
                name: '',
                address: '',
                address_details: '',
                lat: null,
                lng: null,
                delete_image: 0,
                image: '',
                region: '',
                is_georestriction_large: false,
                is_georestriction_disabled: false,
                public_transport_accessibility: Number(PublicTransportAccessibility.accessible),
              }}
              showDelete={false}
              showSubmit={props.showSubmit}
              formRef={props.formRef}
              showLocationNameField={props.showLocationNameField}
              showAddressDetailsField={props.showAddressDetailsField}
              showImageField={props.showImageField}
              showCheckInRestrictionsBlock={props.showCheckInRestrictionsBlock}
              onLocationSelected={props.onLocationSelected}
            />
          )}
          {props.children}
        </div>
      </div>
    )
  }),
)

export default AddLocationPage
