export const titles = [
  {
    title: 'What Staff do you need?',
    subtitle: 'Find vetted staff for your every need',
  },
  {
    title: 'Where do you need the staff?',
    subtitle: 'Select the location or the venue for where you need the staff',
  },
  {
    title: 'When is the staff needed?',
    subtitle: 'Select the date when you require staff',
  },
  {
    title: 'How many days per week?',
    subtitle: 'Select which days you want the staff to work',
  },
  {
    title: 'When is the staff needed?',
    subtitle: 'Select the timing for the staff',
  },
  {
    title: 'What will they do?',
    subtitle: 'Add the job description for the staff (What is expected from the staff)',
  },
  {
    title: 'How experienced do you need the staff to be?',
    subtitle: 'Select the type of experience you want the staff to have',
  },
  {
    title: 'Do you have a language preference?',
    subtitle: 'What languages would you like the staff to speak?',
  },
  {
    title: 'Do you have a gender preference?',
    subtitle: "Select the staff's preferred gender",
  },
]
