import { useTranslation } from 'react-i18next'

import Spacer from '../../../../order/components/spacer/spacer'
import success from '../../assets/success.svg'
import { OgramModal } from '../../../../../components/ogram-modal/ogram-modal'
import './cherry-picking-success-modal.scss'

interface CherryPickingSuccessModalProps {
  modalIsOpen: boolean
  closeModal: () => void
  onConfirm: () => void
}

const CherryPickingSuccessModal = ({ modalIsOpen, closeModal, onConfirm }: CherryPickingSuccessModalProps) => {
  const { t } = useTranslation()

  return (
    <OgramModal modalVisible={modalIsOpen} className="CherryPickingSuccessModal" onClose={closeModal}>
      <img src={success} />
      <h2 className="CherryPickingSuccessModal__modalTitle">{t('job_cherry_picking.congratulation_exclamation')}</h2>
      <p className="CherryPickingSuccessModal__modalText">{t('job_cherry_picking.you_have_assigned_staff')}</p>
      <Spacer space={35} />
      <button className="CherryPickingSuccessModal__done" onClick={onConfirm}>
        {t('job_cherry_picking.done')}
      </button>
    </OgramModal>
  )
}

export default CherryPickingSuccessModal
