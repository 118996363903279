import { types, flow } from 'mobx-state-tree'

import { BusinessLocationModel } from './location'
import { DataStore } from './data-store/data-store'

export const AttendanceManagerModel = types.model('AttendanceManager', {
  id: types.identifierNumber,
  first_name: types.optional(types.string, ''),
  last_name: types.optional(types.string, ''),
  email: types.string,
  job_role: types.optional(types.string, ''),
  status: types.optional(types.string, ''),
  phone: types.optional(types.string, ''),
  locations: types.array(BusinessLocationModel),
})

export const attendanceManagerActions = (self: DataStore) => {
  const actions = {
    getAttendanceManagers: flow(function* () {
      const managers = (yield self.request('get', 'client/attendance_managers')) as AttendanceManager[]
      self.attendanceManagers.clear()
      managers.map((manager) => {
        self.attendanceManagers.set(String(manager.id), manager)
      })
    }),

    getAttendanceManager: flow(function* (id: string) {
      const manager = (yield self.request('get', `client/attendance_managers/${id}`)) as AttendanceManager
      self.attendanceManagers.set(String(manager.id), manager)
    }),

    addAttendanceManager: flow(function* (manager: AddUpdateAttendanceManager) {
      yield self.request('post', 'client/attendance_managers', manager)
      // TODO: check if added manager is returned as response then add it instead of `getAttendanceManagers` api call
      actions.getAttendanceManagers()
    }),

    updateAttendanceManager: flow(function* (id: string, manager: AddUpdateAttendanceManager) {
      yield self.request('put', `client/attendance_managers/${id}`, manager)
      actions.getAttendanceManagers()
    }),

    deleteAttendanceManager: flow(function* (id: string) {
      yield self.request('delete', `client/attendance_managers/${id}`)
      self.attendanceManagers.delete(String(id))
    }),
  }

  return actions
}

export const attendanceManangerViews = (self: DataStore) => ({
  get attendanceManagersList() {
    return Array.from(self.attendanceManagers.values())
  },
})

export type AttendanceManager = typeof AttendanceManagerModel.Type
export type AttendanceManagerSnapshot = typeof AttendanceManagerModel.SnapshotType
export type AddUpdateAttendanceManager = {
  first_name: string
  last_name: string
  email: string
  job_role: string
  phone: string
  locations: string[]
  password?: string
}
