import { useTranslation } from 'react-i18next'

import { PricingModelSnapshot } from '../../../../../models/order/order-job-details'

type InputProps = {
  pricing: PricingModelSnapshot
  currency: string
}

const formatMultiplierValue = (value: number | null, valueType: string) => {
  if (valueType === 'number') {
    return value
  }

  if (valueType === 'difference') {
    return value === null || value <= 0 ? `${value}` : `+${value}`
  }

  return `${value}%`
}

export const Pricing = ({ pricing, currency }: InputProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="JobCard__separator" />
      <div className="JobCard__pricingTable">
        <table className="JobCard__pricingTable__content">
          <tr className="JobCard__pricingTable__header">
            <td className="JobCard__pricingTable__header__value">{t('order_draft.pricing.type')}</td>
            <td className="JobCard__pricingTable__header__value">{t('order_draft.pricing.hours')}</td>
            <td className="JobCard__pricingTable__header__value">{t('order_draft.pricing.hourly_rate')}</td>
          </tr>
          {pricing.details
            .filter((item) => item.valueType !== 'percentage')
            .map((item, key) => (
              <tr key={key} className="JobCard__pricingTable__row">
                <td className="JobCard__pricingTable__row__type">{item.type}</td>
                <td className="JobCard__pricingTable__row__value">{item.hours}</td>
                <td className="JobCard__pricingTable__row__value">
                  {formatMultiplierValue(item.value, item.valueType)}
                </td>
              </tr>
            ))}
        </table>
      </div>
      <div className="JobCard__separator" />
      {pricing.details
        .filter((item) => item.valueType === 'percentage')
        .map((item) => (
          <>
            <div className="JobCard__row">
              <span className="JobCard__row__label JobCard__row__label--highlighted">{item.type}</span>
              <span className="JobCard__row__value">{formatMultiplierValue(item.value, item.valueType)}</span>
            </div>
            <div className="JobCard__separator" />
          </>
        ))}
      <div className="JobCard__pricingInfo">
        <table className="JobCard__pricingInfo__table">
          <tr className="JobCard__pricingInfo__table__row">
            <td className="JobCard__pricingInfo__table__title">{t('order_draft.pricing.estimated_total')}</td>
            <td className="JobCard__pricingInfo__table__prevTotal">
              {Number(pricing.total) > Number(pricing.final_total) ? `${pricing.total} ${currency}` : null}
            </td>
            <td className="JobCard__pricingInfo__table__total">{`${pricing.final_total} ${currency}`}</td>
          </tr>
        </table>
        <span className="JobCard__pricingInfo__note">{t('order.final_amount_will_be_based')}</span>
      </div>
    </>
  )
}
