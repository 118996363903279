import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { Genders } from '../../../../../models-ui/order/store/job-fields/gender'
import './gender.scss'
import { JobDraftFieldError } from '../error/error'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'

export const JobDraftGender = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  const gender = job.gender.value

  return (
    <div className="JobDraftGender">
      <span className="Popup__title">{t('job_draft_details.gender_title')}</span>
      <span className="Popup__subtitle">{t('job_draft_details.gender_subtitle')}</span>
      <div className="JobDraftGender__innerContainer">
        <Button
          title={t('job_draft_details.gender_male')}
          onClick={() => {
            job.gender.set(Genders.male)
          }}
          className={`JobDraftGender__innerContainer__button ${gender === Genders.male ? 'active' : ''} ${
            job.gender.hasError ? 'error' : ''
          }`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.gender_female')}
          onClick={() => {
            job.gender.set(Genders.female)
          }}
          className={`JobDraftGender__innerContainer__button ${gender === Genders.female ? 'active' : ''} ${
            job.gender.hasError ? 'error' : ''
          }`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={t('job_draft_details.gender_no_preference')}
          onClick={() => {
            job.gender.set(null)
          }}
          className={`JobDraftGender__innerContainer__button ${gender === null ? 'active' : ''} ${
            job.gender.hasError ? 'error' : ''
          }`}
        />
        <JobDraftFieldError error={job.gender.error} />
      </div>
    </div>
  )
})
