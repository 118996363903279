import { useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../../../components/app-layout/separator/separator'
import { Ogrammer } from '../ogrammer/ogrammer'
import arrowDownImg from './assets/arrow-down.svg'
import './section.scss'

type SectionProps = {
  invoiceItemId: string
  currency: string
  title: string
  totalShifts: number
  totalHours: number
  totalOG: number
  ogrammers: {
    id: number
    full_name: string
    image_url: string
    shifts_count: number | null
    hours: number | null
    earnings: number | null
  }[]
  location: string
}

export const Section = ({
  invoiceItemId,
  currency,
  title,
  totalShifts,
  totalHours,
  totalOG,
  ogrammers,
  location,
}: SectionProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const { t } = useTranslation()

  const toggleOgrammersVisibility = () => {
    setCollapsed((prevState) => !prevState)
  }

  return (
    <Fragment>
      <Separator.Vertical height={14} />
      <div className="Section">
        <div className="Section__header" onClick={toggleOgrammersVisibility}>
          <span className="Section__header__title">
            {title} ({location})
          </span>
          <img src={arrowDownImg} className={`Section__header__arrowImg ${collapsed ? 'turned' : ''}`} />
        </div>
        <Separator.Vertical height={17} />
        <div className="Section__statsContainer">
          <div className="Section__statsContainer__stat">
            <span className="Section__statsContainer__stat__label">{t('invoice.total_shifts')}</span>
            <span className="Section__statsContainer__stat__value">{totalShifts}</span>
          </div>
          <div className="Section__statsContainer__stat">
            <span className="Section__statsContainer__stat__label">{t('invoice.total_hours')}</span>
            <span className="Section__statsContainer__stat__value">{totalHours}</span>
          </div>
          <div className="Section__statsContainer__stat">
            <span className="Section__statsContainer__stat__label">{t('invoice.total_ogs')}</span>
            <span className="Section__statsContainer__stat__value">{totalOG}</span>
          </div>
        </div>
        <div
          className={`Section__ogrammers ${collapsed ? 'collapsed' : ''}`}
          style={{ maxHeight: `${ogrammers.length * 120}px` }}
        >
          {ogrammers.map((ogrammer) => (
            <Ogrammer
              key={ogrammer.id}
              ogrammerId={ogrammer.id}
              invoiceItemId={invoiceItemId}
              name={ogrammer.full_name}
              amount={ogrammer.earnings}
              currency={currency}
              hours={ogrammer.hours}
              imageURL={ogrammer.image_url}
              shifts={ogrammer.shifts_count}
            />
          ))}
        </div>
      </div>
      <Separator.Vertical height={30} />
    </Fragment>
  )
}
