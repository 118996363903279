export const InfoIcon = ({ color }: { color: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9997 4C7.58197 4 4 7.58197 4 11.9997C4 16.4174 7.58197 20 11.9997 20C16.4174 20 20 16.4174 20 11.9997C20 7.58197 16.4174 4 11.9997 4ZM13.665 16.3984C13.2532 16.5609 12.9255 16.6842 12.6796 16.7695C12.4345 16.8549 12.1493 16.8975 11.8249 16.8975C11.3265 16.8975 10.9384 16.7756 10.6621 16.5325C10.3858 16.2894 10.2483 15.9812 10.2483 15.6067C10.2483 15.4611 10.2585 15.3121 10.2788 15.1604C10.2998 15.0087 10.333 14.838 10.3783 14.6463L10.8937 12.8259C10.9391 12.6512 10.9784 12.4852 11.0095 12.3308C11.0407 12.1751 11.0556 12.0322 11.0556 11.9021C11.0556 11.6705 11.0075 11.508 10.912 11.4166C10.8152 11.3251 10.633 11.2804 10.3614 11.2804C10.2287 11.2804 10.0919 11.3001 9.95166 11.3414C9.81283 11.384 9.69228 11.4226 9.5934 11.4606L9.72952 10.8998C10.0668 10.7623 10.3898 10.6445 10.698 10.547C11.0061 10.4481 11.2974 10.3993 11.5716 10.3993C12.0667 10.3993 12.4487 10.5199 12.7175 10.7583C12.9851 10.9973 13.1198 11.3082 13.1198 11.6902C13.1198 11.7694 13.1103 11.9089 13.0921 12.108C13.0738 12.3078 13.0392 12.49 12.9891 12.6573L12.4764 14.4723C12.4345 14.6179 12.3972 14.7845 12.3633 14.9708C12.3302 15.157 12.3139 15.2992 12.3139 15.3947C12.3139 15.6358 12.3674 15.8004 12.4758 15.8877C12.5828 15.9751 12.7704 16.0191 13.0359 16.0191C13.1611 16.0191 13.3013 15.9968 13.4598 15.9534C13.6169 15.9101 13.7307 15.8715 13.8025 15.8383L13.665 16.3984ZM13.5743 9.03128C13.3352 9.25342 13.0474 9.36449 12.7108 9.36449C12.3749 9.36449 12.085 9.25342 11.8439 9.03128C11.6041 8.80914 11.4829 8.53892 11.4829 8.22332C11.4829 7.9084 11.6048 7.6375 11.8439 7.41333C12.085 7.18849 12.3749 7.07674 12.7108 7.07674C13.0474 7.07674 13.3359 7.18849 13.5743 7.41333C13.8133 7.6375 13.9332 7.9084 13.9332 8.22332C13.9332 8.5396 13.8133 8.80914 13.5743 9.03128Z"
        fill={color}
      />
    </svg>
  )
}
