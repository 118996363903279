import exportInvoiceImg from './export-invoice.png'
import invoiceBreakdownImg from './invoice-breakdown.png'
import invoiceShiftsBreakdown from './invoice-shifts-breakdown.png'
import invoicesListImg from './invoices-list.png'

export const images = {
  exportInvoiceImg,
  invoiceBreakdownImg,
  invoiceShiftsBreakdown,
  invoicesListImg,
}
