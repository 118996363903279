import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../components/app-layout/separator/separator'
import { LeadForm } from '../../lead-form'
import './description.scss'

export const Description = ({ values, updateField }: LeadForm) => {
  const { t } = useTranslation()

  return (
    <div className="Description">
      <Separator.Vertical height={53} />
      <textarea
        value={values.description ?? ''}
        onChange={(ev) => updateField(ev.target.value)}
        placeholder={t('lead_form.describe_job_details')}
        className="Description__textarea"
      />
    </div>
  )
}
