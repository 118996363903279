import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Header } from '../../../../../components/app-layout'
import { Button } from '../../../../../components/button/button'
import LoadingSpinner from '../../../../../components/loading-spinner/loading-spinner'
import { JobCard } from './components/job-card'
import { UIProps } from '../types'
import './choose-jobs.scss'

export const ChooseJobsUI = ({
  isLoading,
  jobs,
  isJobChosen,
  removeDuplicatedJobId,
  addDuplicatedJobId,
  chosenJobsLength,
  onSubmit,
}: UIProps) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Fragment>
          <div className="ChooseJobs__container">
            <Header title={t('duplicate_order.duplicate_order')} />
            <div className="ChooseJobs__titlesContainer">
              <span className="ChooseJobs__titlesContainer__mainTitle">
                {t('duplicate_order.choose_jobs_main_title')}
              </span>
              <span className="ChooseJobs__titlesContainer__secondaryTitle">
                {t('duplicate_order.choose_jobs_secondary_title')}
              </span>
            </div>
            {jobs.map((job) => (
              <JobCard
                key={job.id}
                jobId={job.id}
                isJobChosen={isJobChosen(job.id)}
                removeDuplicatedJobId={() => removeDuplicatedJobId(job.id)}
                addDuplicatedJobId={() => addDuplicatedJobId(job.id)}
                categoryName={job.category_name}
                quantity={job.quantity}
                clientLocationName={job.client_location_name}
              />
            ))}
            <Button
              disabled={chosenJobsLength === 0}
              title={t('duplicate_order.choose_jobs_confirm')}
              type="button"
              className="ChooseJobs__confirmButton"
              onClick={onSubmit}
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
