import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../components/button/button'
import arrowLeftImg from '../../../../../components/assets/arrow-left.svg'
import './form-stepper.scss'

type FormStepperProps = {
  stepIndex: number
  stepsLength: number
  showAccepted: boolean
  showAccept: boolean
  showNext: boolean
  showNextApplicantButton: boolean
  onBack: () => void
  onNext: () => void
  onNextApplicant: () => void
  onAccept: () => void
  onShowResult: () => void
}

export function FormStepper({
  stepIndex,
  stepsLength,
  showAccepted,
  showAccept,
  showNext,
  showNextApplicantButton,
  onBack,
  onNext,
  onNextApplicant,
  onAccept,
  onShowResult,
}: FormStepperProps) {
  const { t } = useTranslation()
  const backDisabled = !showAccept && stepIndex === 0
  return (
    <div className="FormStepper">
      <Button
        onClick={onBack}
        className={`FormStepper__backButton ${backDisabled ? 'FormStepper__backButton_disabled' : ''}`}
        disabled={backDisabled}
      >
        <img src={arrowLeftImg} className="FormStepper__backImg" />
      </Button>
      {showNext && (
        <Button onClick={stepIndex === stepsLength - 1 ? onShowResult : onNext} className="FormStepper__nextButton">
          <div className="FormStepper__nextLabel">{t('interview.next_question')}</div>
        </Button>
      )}
      {showAccept && (
        <Button onClick={onAccept} className="FormStepper__acceptButton">
          <div className="FormStepper__acceptLabel">{t('interview.accept')}</div>
        </Button>
      )}
      {showAccepted && <div className="FormStepper__assigned">{t('interview.assigned')}</div>}
      {showNextApplicantButton && (
        <Button onClick={onNextApplicant} className="FormStepper__nextApplicantButton">
          <div className="FormStepper__nextApplicantLabel">{t('interview.next_applicant')}</div>
        </Button>
      )}
    </div>
  )
}
