import { getEnv, IStateTreeNode, cast } from 'mobx-state-tree'
import { AxiosRequestConfig } from 'axios'

import { Enviroment } from '../environment/environment'
import { marketService } from '../../services/market-service'

export const withRequest = (self: IStateTreeNode) => {
  return {
    actions: {
      request: <T>(
        method: 'get' | 'post' | 'put' | 'delete',
        url: string,
        data?: object | null,
        options: AxiosRequestConfig = {},
      ) => {
        let sendData = data
        if (options.headers?.['Content-Type'] === 'multipart/form-data') {
          const formData = new FormData()
          for (const key in data) {
            formData.append(key, cast(data[key as never]))
          }
          sendData = formData
        }

        if (!options.headers?.['x-lang-code']) {
          options.headers = {
            ...options.headers,
            ['x-lang-code']: marketService.getCurrentLanguage(),
          }
        }

        return getEnv<Enviroment>(self).api.makeRequest<T>({ method: method, url: url, data: sendData, ...options })
      },
      rawRequest: <T>(method: 'get' | 'post' | 'put' | 'delete', url: string, data?: object | null) => {
        return getEnv<Enviroment>(self).rawApi.request<T>({ method: method, url: url, data: data })
      },
    },
  }
}
