import './field-error.scss'

export type FormFieldError = string | string[] | null | undefined

type FormFieldProps = {
  children: React.ReactNode
  errors: FormFieldError
  className?: string
}

export const FieldError = ({ children, errors, className = '' }: FormFieldProps) => {
  const getError = () => {
    if (!errors) return null

    let errorMessage = ''

    if (typeof errors === 'string') {
      errorMessage = errors
    }

    if (Array.isArray(errors)) {
      errorMessage = errors[0]
    }

    return errorMessage
  }

  const error = getError()

  return (
    <div className={`FieldError ${className}`}>
      {children}
      {Boolean(error) && <span className="FieldError__label">{error}</span>}
    </div>
  )
}
