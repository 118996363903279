import { AxiosRequestConfig } from 'axios'
import { ApiRest } from 'ogram-react'

import { store } from '../../app'

let isTokenSet = false

export async function secretTokenInterceptor(this: ApiRest, request: AxiosRequestConfig) {
  const url = window.location.search
  const urlParams = new URLSearchParams(url)
  const token = urlParams.get('token')
  if (!isTokenSet && token && request.url !== 'client/token') {
    try {
      await store.data.logInByToken({ token })
    } catch {}
  }
  isTokenSet = true

  return request
}
