import { observer } from 'mobx-react'
import { Elements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../../../components/app-layout'
import { PayWithCardForm } from './components/pay-with-card-form/pay-with-card-form'
import LoadingSpinner from '../../../../../components/loading-spinner/loading-spinner'
import { createStripe, usePaymentIntent, usePendingPaymentOrDenyAccess } from '../../../../../services/stripe'
import { PaymentMethod } from '../../../../../models/payment'
import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

type InputProps = {
  paymentId: number
  paymentOptions?: string
}

export const PayWithCard = withErrorBoundary(
  observer(({ paymentId, paymentOptions }: InputProps) => {
    const { t } = useTranslation()
    const { isLoading } = usePendingPaymentOrDenyAccess(paymentId)
    const { paymentIntent, stripeOptions } = usePaymentIntent(paymentId, PaymentMethod.Card)
    const redirect = useRedirect()

    return (
      <div className="PayWithCardPage">
        <Header
          title={t('payment.card_payment')}
          handleBackClick={() => redirect(routes.paymentDetails, { paymentId: String(paymentId) })}
          showBack
        />
        <Separator.Vertical height={35} />
        <div className="PayWithCardPage__container">
          {!stripeOptions || !paymentIntent || isLoading ? (
            <LoadingSpinner />
          ) : (
            <Elements stripe={createStripe()} options={stripeOptions}>
              <PayWithCardForm paymentId={paymentId} paymentIntent={paymentIntent} paymentOptions={paymentOptions} />
            </Elements>
          )}
        </div>
      </div>
    )
  }),
)
