import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import { Header } from '../../../components/app-layout'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'
import { CalendarWrapper } from './components/calendar/calendar-wrapper/calendar-wrapper'
import { ScanQrButton } from '../../../components/scan-qr-button/scan-qr-button'
import { ActionAlert, Theme } from '../../../components/action-alert/action-alert'
import { load, save } from '../../../services/storage'
import { Separator } from '../../../components/app-layout/separator/separator'
import './all-jobs.scss'

export const AllJobsPage = withErrorBoundary(
  observer((props: { date: string }) => {
    const { t } = useTranslation()

    useEffect(() => {
      save('extensionAnnouncementWasShown', true)
    }, [])

    return (
      <div className="AllJobs">
        <Header title={t('all_jobs.title')} rightComponent={<ScanQrButton />} />
        <Separator.Vertical height={30} />
        {!Boolean(load('extensionAnnouncementWasShown')) && (
          <ActionAlert
            theme={Theme.Orange}
            title={t('all_jobs.extension_announcement')}
            textStyle={{ width: 'unset' }}
          />
        )}
        <Separator.Vertical height={10} />
        <CalendarWrapper initialDate={new Date(Date.parse(props.date))} />
      </div>
    )
  }),
)
