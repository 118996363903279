import { useTranslation } from 'react-i18next'

import { Link } from '../../../../../../services/router'
import { routes } from '../../../../../../routes'
import { Separator } from '../../../../../../components/app-layout/separator/separator'
import arrowRightImg from './assets/arrow-right.svg'
import './ogrammer.scss'

type Props = {
  invoiceItemId: string
  ogrammerId: number
  name: string
  imageURL: string
  shifts: number | null
  hours: number | null
  amount: number | null
  currency: string
}

export const Ogrammer = ({ invoiceItemId, ogrammerId, name, imageURL, shifts, hours, amount, currency }: Props) => {
  const { t } = useTranslation()

  return (
    <Link
      route={routes.invoiceOgrammer}
      params={{ invoiceItemId, ogrammerId: String(ogrammerId), name }}
      className="Ogrammer"
    >
      <img src={imageURL} className="Ogrammer__photo" />
      <Separator.Horizontal width={16} />
      <div className="Ogrammer__main">
        <span className="Ogrammer__main__name">{name}</span>
        <Separator.Vertical height={13} />
        <div className="Ogrammer__main__stats">
          {shifts !== null && (
            <div className="Ogrammer__main__stats__item">
              <span className="Ogrammer__main__stats__item__label">{t('invoice.shifts')}</span>
              <span className="Ogrammer__main__stats__item__value">{shifts}</span>
            </div>
          )}
          {hours !== null && (
            <div className="Ogrammer__main__stats__item">
              <span className="Ogrammer__main__stats__item__label">{t('invoice.hours')}</span>
              <span className="Ogrammer__main__stats__item__value">{hours}</span>
            </div>
          )}
          {amount !== null && (
            <div className="Ogrammer__main__stats__item">
              <span className="Ogrammer__main__stats__item__label">{t('invoice.amount')}</span>
              <span className="Ogrammer__main__stats__item__value">
                {amount} {currency}
              </span>
            </div>
          )}
        </div>
      </div>
      <Separator.Spacer flex={0.3} />
      <img src={arrowRightImg} className="Ogrammer__arrowImg" />
    </Link>
  )
}
