import { createContext, useContext } from 'react'
import { Instance, onSnapshot } from 'mobx-state-tree'

import { load, save } from '../../../services/storage'
import { DraftJobsModel, DraftJobsSnapshot } from './draft-jobs'
import { ExtensionStep, OrderExtensionModel } from './order-extension'

export let draftJobsStore = DraftJobsModel.create()
export const DRAFT_JOBS_STORE_KEY = 'draftJobs'

const savedStorageData = load(DRAFT_JOBS_STORE_KEY) as string
if (savedStorageData) {
  const json: DraftJobsSnapshot = JSON.parse(savedStorageData)
  if (DraftJobsModel.is(json)) {
    draftJobsStore = DraftJobsModel.create(json)
  }
}

export type DraftJobsStoreInstance = Instance<typeof DraftJobsModel>
const DraftJobsStoreContext = createContext<DraftJobsStoreInstance | null>(null)

export const DraftJobsStoreProvider = DraftJobsStoreContext.Provider

export function useDraftJobsStore() {
  const store = useContext(DraftJobsStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}

export const orderExtensionStore = OrderExtensionModel.create({
  useSameExtensionDateForAllJobs: true,
  extensionStep: Number(ExtensionStep.ExtensionTypeSelection),
})

onSnapshot(draftJobsStore, (snapshot: DraftJobsSnapshot) => {
  save(DRAFT_JOBS_STORE_KEY, JSON.stringify(snapshot))
})
