import './header.scss'

type HeaderProps = {
  title: string
  subtitle: string
}

export const Header = ({ title, subtitle }: HeaderProps) => (
  <div className="Header">
    <span className="Header__title">{title}</span>
    <span className="Header__subtitle">{subtitle}</span>
  </div>
)
