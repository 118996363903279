/* eslint-disable @typescript-eslint/no-empty-interface */
import { types, Instance } from 'mobx-state-tree'

import { t } from '../../../../../translations'

export const SingleDateModel = types
  .model('JobDatesSingleDate', {
    start_time: types.maybeNull(types.string),
    start_time_error: types.maybeNull(types.string),
    end_time: types.maybeNull(types.string),
    end_time_error: types.maybeNull(types.string),
    selected: types.optional(types.boolean, false),
    is_less_than_24h: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    validate() {
      if (self.selected && (!self.start_time || !self.end_time)) {
        if (!self.start_time) {
          self.start_time_error = t('job_draft_details.start_time_required')
        }
        if (!self.end_time) {
          self.end_time_error = t('job_draft_details.end_time_required')
        }
        return false
      }

      self.start_time_error = null
      self.end_time_error = null
      return true
    },
  }))

export interface SingleDateInstance extends Instance<typeof SingleDateModel> {}
