import { InputConfig } from './types'
import { rawCountries } from './raw-countries'
import { ENABLED_MARKET_CODES_ISO2, marketService } from '../../services/market-service'

const currentMarketCode = marketService.getCurrentMarketCode()

const EMIRATES_CODE = 'ae'

const enabledCodes: string[] = Object.values(ENABLED_MARKET_CODES_ISO2)

export const config: InputConfig = {
  enabledCountries: enabledCodes,
  defaultCountry: currentMarketCode ? ENABLED_MARKET_CODES_ISO2[currentMarketCode] : EMIRATES_CODE,
  defaultMask: '... ... ... ... ..',
}

export const masks: { [key: string]: string } = {}
config.enabledCountries.forEach((enabledCountryCode) => {
  masks[enabledCountryCode] = rawCountries[enabledCountryCode].mask ?? config.defaultMask
})

export const regionCodes: { [key: string]: string[] } = {}
config.enabledCountries.forEach((enabledCountryCode) => {
  regionCodes[enabledCountryCode] = rawCountries[enabledCountryCode].regionCodes
})
