import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import { Button } from '../../../../components/button/button'
import { Separator } from '../../../../components/app-layout/separator/separator'
import calendarImg from './assets/calendar.svg'
import './empty-order-list.scss'

export const EmptyOrderList = observer(() => {
  const redirect = useRedirect()

  const { t } = useTranslation()

  return (
    <div className="EmptyOrderList">
      <Separator.Spacer flex={0.5} />
      <img src={calendarImg} className="EmptyOrderList__calendarImg" />
      <Separator.Vertical height={21} />
      <span className="EmptyOrderList__title">{t('orders_list.empty_list_title')}</span>
      <Separator.Vertical height={6} />
      <span className="EmptyOrderList__description">{t('orders_list.empty_list_description')}</span>
      <Separator.Vertical height={47} />
      <Button
        title={t('orders_list.create_order')}
        onClick={() => {
          redirect(routes.setJobCategory, {})
        }}
        className="EmptyOrderList__createOrderButton"
      />
      <Separator.Spacer />
    </div>
  )
})
