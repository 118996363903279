import { useState, useEffect } from 'react'
import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js'

import { useQuery, useStore } from '../utils/mst-hooks'
import { PaymentIntent, PaymentMethod, PaymentStatus } from '../models/payment'
import { routes } from '../routes'
import { useRedirect } from './router'
import { getCurrentMarketConfig, marketService } from './market-service'

export type StripeOptions = {
  clientSecret: string
}

const getStripePublishableKey = () => {
  return getCurrentMarketConfig().stripePublishedKey
}

export function createStripe() {
  return loadStripe(getStripePublishableKey(), {
    // @ts-ignore
    locale: marketService.getCurrentLanguage(),
  })
}

export function usePendingPaymentWithoutChosenMethodOrDenyAccess(paymentId: number) {
  const redirect = useRedirect()
  const { getPayment, currentPayment } = useStore().data

  const getPaymentQuery = useQuery(() => getPayment(paymentId))
  useEffect(() => {
    if (
      currentPayment &&
      (String(currentPayment.status) !== PaymentStatus.Pending || currentPayment.type_id !== null)
    ) {
      redirect(routes.paymentDetails, { paymentId: String(paymentId) })
    }
  }, [currentPayment])

  return {
    isLoading: getPaymentQuery.isLoading,
    currentPayment,
  }
}

export function usePendingPaymentOrDenyAccess(paymentId: number) {
  const redirect = useRedirect()
  const { getPayment, currentPayment } = useStore().data

  const getPaymentQuery = useQuery(() => getPayment(paymentId))
  useEffect(() => {
    if (currentPayment && String(currentPayment.status) !== PaymentStatus.Pending) {
      redirect(routes.paymentDetails, { paymentId: String(paymentId) })
    }
  }, [currentPayment])

  return {
    isLoading: getPaymentQuery.isLoading,
    currentPayment,
  }
}

export function usePaymentIntent(paymentId: number, paymentMethod: PaymentMethod) {
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | null>(null)
  const [stripeOptions, setStripeOptions] = useState<StripeOptions | null>(null)
  const { initPaymentIntent } = useStore().data

  useQuery(() => initPaymentIntent(paymentId, paymentMethod), {
    onSuccess: result => {
      setPaymentIntent(result)

      if (paymentMethod === PaymentMethod.Card) {
        setStripeOptions({
          clientSecret: result.client_secret,
        })
      }
    },
  })

  return {
    paymentIntent,
    stripeOptions,
  }
}

export async function confirmNewCardPayment(
  stripe: Stripe | null | undefined,
  elements: StripeElements,
  paymentId: number,
  shouldSaveCard: boolean,
  processingHandler: (isProcessing: boolean) => void,
  errorHandler: (errorMessage: string) => void,
  paymentOptions?: string,
) {
  let returnUrl = `${window.location.origin}/payments/${paymentId}/status`
  if (Boolean(paymentOptions)) {
    returnUrl = `${returnUrl}?paymentOptions=${paymentOptions}`
  }

  const result = await stripe?.confirmPayment({
    elements,
    confirmParams: {
      return_url: returnUrl,
      ...(shouldSaveCard ? { save_payment_method: true } : {}),
    },
  })

  processingHandler(false)

  if (result?.error) {
    errorHandler(String(result.error.message))
  }
}

export async function confirmSavedCardPayment(
  stripe: Stripe | null | undefined,
  clientSecret: string,
  cardId: string,
  paymentId: number,
  errorHandler: (errorMessage: string) => void,
  successHandler: () => void,
  paymentOptions?: string,
) {
  let returnUrl = `${window.location.origin}/payments/${paymentId}/status`
  if (Boolean(paymentOptions)) {
    returnUrl = `${returnUrl}?paymentOptions=${paymentOptions}`
  }

  const result = await stripe?.confirmCardPayment(clientSecret, {
    payment_method: cardId,
    return_url: returnUrl,
  })

  if (result?.error) {
    errorHandler(String(result.error.message))
  } else {
    successHandler()
  }
}
