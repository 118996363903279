import { useState } from 'react'
import Modal from 'react-modal'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { useStore, useQuery, useMutation } from '../../../utils/mst-hooks'
import { NotificationItem } from './components/notification-item/notification-item'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { Separator } from '..'
import bellImg from './assets/notification-bell.svg'
import unreadIndicatorImg from './assets/unread-indicator.svg'
import crossImg from './assets/cross.svg'
import './notifications-bar.scss'
import { withErrorBoundary } from '../../error/with-error-boundary'

export const NotificationsBar = withErrorBoundary(
  observer(() => {
    const [active, setActive] = useState(false)

    const {
      notification,
      notifications,
      fetchNotifications,
      readNotification,
      getUnreadNotificationsCount,
      isMoreNotifications,
    } = useStore().data
    const notificationQuery = useQuery(() => fetchNotifications({ page: 1 }))
    useQuery(getUnreadNotificationsCount, {
      filterError: [
        ApiErrorTypes.ClientError,
        ApiErrorTypes.NotFound,
        ApiErrorTypes.ConnectionError,
        ApiErrorTypes.ServerError,
      ],
    })
    const [, requestReadNotification] = useMutation(readNotification, {
      filterError: [ApiErrorTypes.ClientError, ApiErrorTypes.NotFound, ApiErrorTypes.ConnectionError],
    })
    const [loadMoreNotificationsResult, loadMoreNotifications] = useMutation(fetchNotifications)

    const { t } = useTranslation()

    const toggleVisibility = () => {
      setActive((prevState) => !prevState)
    }

    return (
      <>
        <div onClick={toggleVisibility} className="NotificationsBar__bell-container">
          <img src={bellImg} />
          {notification.unreadCount > 0 && (
            <img src={unreadIndicatorImg} className="NotificationsBar__bell-container__bell-indicator" />
          )}
        </div>
        <Modal
          isOpen={active}
          onRequestClose={() => {
            setActive(false)
          }}
          closeTimeoutMS={400}
          className="NotificationsBar"
        >
          <div className="NotificationsBar__header">
            <span className="NotificationsBar__header__title">
              {t('notifications.title')}{' '}
              <span className="NotificationsBar__header__title__amount">({notification.unreadCount})</span>
            </span>
            <img src={crossImg} onClick={toggleVisibility} className="NotificationsBar__header__close-img" />
          </div>
          <Separator.Vertical height={25} />
          {!notificationQuery.isLoading && (
            <>
              {notifications.map((notificationItem) => (
                <NotificationItem
                  key={notificationItem.id}
                  title={notificationItem.subject}
                  description={notificationItem.message}
                  url={notificationItem.url}
                  secondsSinceNotification={notificationItem.date}
                  onClick={() => {
                    requestReadNotification(notificationItem.id)
                  }}
                />
              ))}
              <Separator.Vertical height={28} />
              {loadMoreNotificationsResult.isLoading ? (
                <LoadingSpinner />
              ) : notifications.length > 0 && isMoreNotifications ? (
                <div className="NotificationsBar__loadMoreButton" onClick={() => loadMoreNotifications({})}>
                  <span className="NotificationsBar__loadMoreButton__label">{t('notifications.load_more')}</span>
                </div>
              ) : (
                <span className="NotificationsBar__noNotifications">
                  {notifications.length > 0
                    ? t('notifications.no_more_notifications')
                    : t('notifications.no_notifications_yet')}
                </span>
              )}
            </>
          )}
        </Modal>
      </>
    )
  }),
  { [ApiErrorTypes.ServerError]: 'notifications.error_failed_to_load_notifications' },
)
