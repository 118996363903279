import { useTranslation } from 'react-i18next'

import { IntervalModelSnapshot } from '../../../../../models/order/order-job-details'

type InputProps = {
  startDate: string
  endDate: string
  daysCount: number
  intervals: [string, IntervalModelSnapshot][]
  showSchedulePopup: () => void
}

export const ExtendedJobFields = ({ startDate, endDate, daysCount, intervals, showSchedulePopup }: InputProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="JobCard__separator" />
      <div className="JobCard__row">
        <span className="JobCard__row__label">{t('order_draft.start_date')}</span>
        <span className="JobCard__row__value">{startDate}</span>
      </div>
      <div className="JobCard__separator" />
      <div className="JobCard__row">
        <span className="JobCard__row__label">
          {t('order_draft.end_date')} <span className="JobCard__row__label__note">[{t('order_draft.updated')}]</span>
        </span>
        <span className="JobCard__row__value">
          {endDate}{' '}
          <span className="JobCard__row__value__additional">+{t('order_draft.days_amount', { count: daysCount })}</span>
        </span>
      </div>
      {intervals.slice(0, 3).map(([_, interval], key) => (
        <div className="JobCard__row" key={key}>
          <span className="JobCard__row__label">
            {interval.day_of_month} {interval.month}{' '}
            <span className="JobCard__row__value__additional">{interval.day}</span>
          </span>
          <span className="JobCard__row__value">
            {interval.start_time} - {interval.end_time}{' '}
            <span className="JobCard__row__value__additional">
              {t('order_draft.n_hours', { hours: interval.duration })}
            </span>
          </span>
        </div>
      ))}
      <button
        className="JobCard__bottomButton"
        onClick={(event) => {
          event.stopPropagation()
          showSchedulePopup()
        }}
      >
        {t('order_extension.full_schedule')}
      </button>
    </>
  )
}
