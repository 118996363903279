import { BoardingStatus } from '../../../../../models/job'
import { t } from '../../../../../translations'

export function getBoardingLabel(status: BoardingStatus) {
  switch (status) {
    case BoardingStatus.noBoarding:
      return t('job_draft_details.no_boarding')
    case BoardingStatus.halfBoarding:
      return t('job_draft_details.half_boarding')
    case BoardingStatus.fullBoarding:
      return t('job_draft_details.full_boarding')
  }
}
