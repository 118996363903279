import { values } from 'mobx'
import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

const WorkCategoryModel = types.model({
  id: types.number,
  name: types.string,
  min_rate: types.number,
  max_rate: types.number,
  min_years: types.number,
  max_years: types.number,
})

export type WorkCategory = typeof WorkCategoryModel.Type
export type WorkCategorySnapshot = typeof WorkCategoryModel.SnapshotType

export const FilterDictionaryModel = types.model('FilterDictionaryModel', {
  languages: types.array(types.model({ id: types.number, name: types.string })),
  nationalities: types.array(types.model({ id: types.number, name: types.string })),
  certificates: types.array(types.model({ id: types.number, display_name: types.string })),
  work_categories: types.array(WorkCategoryModel),
  gender: types.array(types.model({ id: types.number, name: types.string })),
  age: types.model({ min: types.number, max: types.number }),
  height: types.model({ min: types.number, max: types.number }),
})

export const filterDictionaryActions = (self: DataStore) => ({
  getFiltersDictionary: flow(function* () {
    self.filterDictionary = yield self.request('post', 'common/filter/lists')
  }),
})

export const filterDictionaryViews = (self: DataStore) => ({
  getNationalityIdByTitle(title: string) {
    if (!self.filterDictionary) return []
    return values(self.filterDictionary.nationalities).find((nationality) => nationality.name === title)?.id
  },
})

export type FilterDictionary = typeof FilterDictionaryModel.Type
export type FilterDictionarySnapshot = typeof FilterDictionaryModel.SnapshotType

export const genderMap = {
  'Male': 1,
  'Female': 2,
  'Mixed': 3,
}

export enum Genders {
  Male = 'Male',
  Female = 'Female',
  Mixed = 'Mixed',
}
