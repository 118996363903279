import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useQuery, useStore } from '../../../../../../utils/mst-hooks'
import './calendar-designation-filter.scss'
import { Multiselect } from '../../../../../../components/form/multiselect/multiselect'
import { TrackedEvent, trackingService } from '../../../../../../services/tracking-service'

function CalendarDesignationFilterComponent({
  designationIds,
  setDesignationIds,
}: {
  designationIds: number[]
  setDesignationIds: (values: number[]) => void
}) {
  const { t } = useTranslation()

  const { professionList, getProfessions } = useStore().data

  const { isLoading } = useQuery(getProfessions)

  const dataOptions =
    professionList.length > 0
      ? professionList.map(item => ({
          key: String(item.id),
          label: item.name,
          value: item.id,
        }))
      : []

  if (isLoading) {
    return null
  }

  return (
    <div className="CalendarLocationFilter">
      <Multiselect
        options={dataOptions}
        className="CalendarLocationFilter__select"
        label={t('all_jobs.filter_designation')}
        onChange={values => {
          trackingService.track(TrackedEvent.TimesheetDesignationFilterUsed)
          setDesignationIds(values.map(value => Number(value)))
        }}
      />
    </div>
  )
}

export const CalendarDesignationFilter = observer(CalendarDesignationFilterComponent)
