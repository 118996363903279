import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Input, InputProps } from '../form/input/input'
import openedEyeImg from './assets/opened-eye.png'
import closedEyeImg from './assets/closed-eye.png'
import checkmarkImg from './assets/checkmark.svg'
import './password-input.scss'

export const PasswordInput = ({
  showHints = false,
  hidePasswordByDefault = false,
  ...props
}: InputProps & { hidePasswordByDefault?: boolean; showHints?: boolean }) => {
  const [passwordVisible, setPasswordVisible] = useState(!hidePasswordByDefault)

  const { t } = useTranslation()

  const passwordValue = (props.value ?? '') as string
  const has6Chars = passwordValue.length >= 6
  const hasLowercase = passwordValue.toUpperCase() !== passwordValue
  const hasUppercase = passwordValue.toLowerCase() !== passwordValue
  const hasNumber = /\d/.test(passwordValue)

  return (
    <div className="PasswordInput">
      <Input
        type={passwordVisible ? 'text' : 'password'}
        image={
          <img
            onClick={() => {
              setPasswordVisible((prev) => !prev)
            }}
            src={passwordVisible ? closedEyeImg : openedEyeImg}
            className="PasswordInput__img"
          />
        }
        {...props}
      />
      {showHints && (
        <div className="PasswordInput__hints">
          <div className={`PasswordInput__hints__item ${has6Chars ? 'active' : ''}`}>
            <img src={checkmarkImg} className={`PasswordInput__hints__item__checkmark ${has6Chars ? 'visible' : ''}`} />
            <span className={`PasswordInput__hints__item__label ${has6Chars ? 'active' : ''}`}>
              {t('password.minimumChars')}
            </span>
          </div>
          <div className={`PasswordInput__hints__item ${hasLowercase ? 'active' : ''}`}>
            <img
              src={checkmarkImg}
              className={`PasswordInput__hints__item__checkmark ${hasLowercase ? 'visible' : ''}`}
            />
            <span className={`PasswordInput__hints__item__label ${hasLowercase ? 'active' : ''}`}>
              {t('password.oneLowercase')}
            </span>
          </div>
          <div className={`PasswordInput__hints__item ${hasNumber ? 'active' : ''}`}>
            <img src={checkmarkImg} className={`PasswordInput__hints__item__checkmark ${hasNumber ? 'visible' : ''}`} />
            <span className={`PasswordInput__hints__item__label ${hasNumber ? 'active' : ''}`}>
              {t('password.oneNumber')}
            </span>
          </div>
          <div className={`PasswordInput__hints__item ${hasUppercase ? 'active' : ''}`}>
            <img
              src={checkmarkImg}
              className={`PasswordInput__hints__item__checkmark ${hasUppercase ? 'visible' : ''}`}
            />
            <span className={`PasswordInput__hints__item__label ${hasUppercase ? 'active' : ''}`}>
              {t('password.oneUppercase')}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}
