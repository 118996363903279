import './loading-spinner.scss'

export const LoadingSpinner = () => (
  <div className="loader-container">
    <div className="loadingio-spinner-eclipse-zqbi37icss9">
      <div className="ldio-28vulqui936">
        <div></div>
      </div>
    </div>
  </div>
)

export default LoadingSpinner
