import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DropdownMenu } from '../../../../../../../components/dropdown-menu/dropdown-menu'
import { MenuItem } from './shift-tools/components/menu-item/menu-item'
import { routes } from '../../../../../../../routes'
import { useRedirect } from '../../../../../../../services/router'
import { useStore } from '../../../../../../../utils/mst-hooks'
import clockImg from './shift-tools/assets/clock.svg'
import dropdownHorizontalImg from '../../../../../../../components/assets/context.svg'
import './confirmation-shift-tools.scss'

function ConfirmationShiftToolsComponent({ shiftId }: { shiftId: number }) {
  const { t } = useTranslation()

  const { scheduleModificationsAmount } = useStore().data

  const redirect = useRedirect()

  return (
    <>
      <div className="ConfirmationShiftTools">
        <DropdownMenu
          outerComponent={
            <div className="ConfirmationShiftTools__dropdownContainer">
              <img src={dropdownHorizontalImg} alt="ogrammer tools" />
            </div>
          }
          items={[
            <MenuItem
              key="manage"
              img={clockImg}
              title={t('all_jobs.manage_shift')}
              onClick={() => {
                if (scheduleModificationsAmount === 0 || confirm(t('all_jobs.unsaved_changes'))) {
                  redirect(routes.attendanceShiftDetails, { id: shiftId })
                }
              }}
              disabled={false}
            />,
          ]}
        />
      </div>
    </>
  )
}

export const ConfirmationShiftTools = observer(ConfirmationShiftToolsComponent)
