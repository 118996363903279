import { flow, SnapshotOut, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const QrAttendanceModel = types.model('QrAttendanceModel', {
  sp_active_shift_id: types.identifierNumber,
  shift_id: types.number,
  is_checkin: types.boolean,
  sp_id: types.number,
  sp_full_name: types.string,
  sp_image: types.maybeNull(types.string),
  order_id: types.number,
  job_amount: types.number,
  work_category_name: types.string,
  location_name: types.string,
  shift_start_time: types.number,
  shift_end_time: types.number,
  timezone_offset: types.number,
})

export const qrAttendanceActions = (self: DataStore) => ({
  getCheckinEligibility: flow(function* ({ spId }: { spId: number }) {
    self.qrAttendance = (yield self.request(
      'post',
      `client/attendance/shifts/checkin-eligibility?sp_id=${spId}`,
    )) as QrAttendanceSnapshot
    return self.qrAttendance
  }),
})

export type QrAttendanceSnapshot = SnapshotOut<typeof QrAttendanceModel>
