import './field.scss'

export type FieldProps = {
  label?: string
  required?: boolean
  errors?: string[]
  description?: React.ReactNode
  rightPos?: boolean
}
export function Field(props: FieldProps & { children: React.ReactNode }) {
  const children = (
    <>
      {props.children}
      {props.rightPos && props.label}
      <div className="Field__description">{props.description}</div>
      {props.errors && props.errors.length > 0 && (
        <div className="Field__errors">
          {props.errors.map((err, i) => (
            <div key={i} className="Field__error">
              {err}
            </div>
          ))}
        </div>
      )}
    </>
  )
  return <div className="Field">{props.label ? <label>{children}</label> : children}</div>
}
