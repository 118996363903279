import { t } from '../../../../translations'
import { images } from '../../assets/order-creation'

export const duplicateJobPage = {
  title: t('order_creation_onboarding.duplicate_job_title'),
  content: [
    { image: { src: images.duplicateJobImg, className: 'Onboarding__bigImg' } },
    { description: t('order_creation_onboarding.duplicate_job') },
  ],
}
