import './button.scss'

type Props = {
  title?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
  className?: string
  disabled?: boolean
  children?: React.ReactNode
  style?: object
}

export const Button = ({ title, type, onClick, className, disabled, children, style }: Props) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`Button ${className ?? ''} ${disabled ? 'disabled' : ''}`}
      style={style}
    >
      {children ?? title}
    </button>
  )
}
