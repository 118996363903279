import searchImg from './search.svg'
import './search-input.scss'

type SearchInputProps = {
  value: string
  handleValueChange: (value: string) => void
  placeholder?: string
  className?: string
}

export const SearchInput = ({ value, handleValueChange, placeholder = 'Search...', className }: SearchInputProps) => (
  <div className={`SearchInput ${className ?? ''}`}>
    <input
      className="SearchInput__input"
      value={value}
      onChange={(ev) => handleValueChange(ev.target.value)}
      placeholder={placeholder}
    />
    <img src={searchImg} className="SearchInput__searchImg" />
  </div>
)
