import { useTranslation } from 'react-i18next'

import { AffiliateReferralSnapshot, ReferralStatus } from '../../../../../../models/affiliate'

export const ShiftsLabel = ({ affiliateReferral }: { affiliateReferral: AffiliateReferralSnapshot }) => {
  const { t } = useTranslation()

  const status = affiliateReferral.status as ReferralStatus

  if ([ReferralStatus.ProfileCreated, ReferralStatus.Activated].includes(status)) {
    return null
  }

  if (affiliateReferral.shiftsTotalCount > affiliateReferral.shiftsCompletedCount) {
    return (
      <span className="AffiliateReferralListItem__top__left__userInfo__shifts">
        {affiliateReferral.shiftsCompletedCount}/{affiliateReferral.shiftsTotalCount} {t('common.shifts')}
      </span>
    )
  }

  return (
    <span className="AffiliateReferralListItem__top__left__userInfo__shifts">
      {affiliateReferral.shiftsTotalCount} {t('common.shifts')}
    </span>
  )
}
