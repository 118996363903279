import * as Yup from 'yup'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { useRedirect } from '../../../services/router'
import { TextInput } from '../../../components/text-input/text-input'
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner'
import { FieldError } from '../../../components/form/field-error/field-error'
import { Separator } from '../../../components/app-layout/separator/separator'
import { useMutation, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import { generateDeviceId } from '../../../utils/device-id-generator'
import { useFirebaseToken } from '../../../utils/use-firebase-token'
import appLogoImg from '../../../components/assets/app-logo.svg'
import './reset-password.scss'

export const ResetPassword = observer(() => {
  const firebaseToken = useFirebaseToken()

  const store = useStore()
  const redirect = useRedirect()
  const urlParams = new URLSearchParams(window.location.search)
  const changePasswordToken = String(urlParams.get('changePasswordToken'))
  const [resetResult, reset] = useMutation(store.data.resetPassword, {
    onSuccess: () => {
      redirect(routes.index, {})
    },
    onError: (err) => {
      if (err.data.message) {
        alert(err.data.message)
      }
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  const errors = resetResult.error?.data.errors

  const { t } = useTranslation()

  return (
    <div className="ResetPassword">
      <img src={appLogoImg} className="ResetPassword__logo" />
      <Separator.Vertical height={45} />
      <h1 className="ResetPassword__title">{t('reset_password.title')}</h1>
      <Separator.Vertical height={5} />
      <h2 className="ResetPassword__subtitle">{t('reset_password.description')}</h2>
      <Separator.Vertical height={53} />
      {resetResult.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Formik
          initialValues={{ password: '', confirm_password: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(t('reset_password.please_enter_password')),
            confirm_password: Yup.string().required(t('reset_password.please_confirm_password')),
          })}
          onSubmit={({ password, confirm_password }) => {
            if (password === confirm_password) {
              reset({
                password,
                token: changePasswordToken,
                device_id: generateDeviceId(),
                firebase_token: firebaseToken,
              })
            }
          }}
        >
          {(formikProps) => (
            <Form noValidate>
              <FieldError errors={errors?.password}>
                <TextInput
                  type="password"
                  label={t('reset_password.password')}
                  formikProps={formikProps}
                  formikKey="password"
                />
              </FieldError>
              <Separator.Vertical height={15} />
              <TextInput
                type="password"
                label={t('reset_password.confirm_password')}
                formikProps={formikProps}
                formikKey="confirm_password"
              />
              {formikProps.touched.password &&
                formikProps.values.confirm_password &&
                (formikProps.values.password === formikProps.values.confirm_password ? (
                  <p className="ResetPassword__matchedText">{t('reset_password.password_mathched')}</p>
                ) : (
                  <p className="ResetPassword__notMatchedText">{t('reset_password.password_not_matched')}</p>
                ))}
              <Separator.Vertical height={25} />
              <button type="submit" className="ResetPassword__button">
                {t('reset_password.submit')}
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
})
