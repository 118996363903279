import { useTranslation } from 'react-i18next'

import nextImg from './assets/next.svg'
import backImg from './assets/previous.svg'
import checkmarkImg from './assets/checkmark.svg'
import './page-stepper.scss'

type PageStepperProps = {
  step: number
  stepsCount: number
  toNextStep: () => void
  toPreviousStep: () => void
  close: () => void
}

export const PageStepper = ({ step, stepsCount, toNextStep, toPreviousStep, close }: PageStepperProps) => {
  if (stepsCount === 1) {
    return (
      <div className="PageStepper justifyEnd">
        <FinishButton onClick={close} />
      </div>
    )
  }

  if (step === 0) {
    return (
      <div className="PageStepper justifyEnd">
        <NextButton onClick={toNextStep} />
      </div>
    )
  }

  if (step === stepsCount - 1) {
    return (
      <div className="PageStepper justifySpaceBetween">
        <BackButton onClick={toPreviousStep} />
        <FinishButton onClick={close} />
      </div>
    )
  }

  return (
    <div className="PageStepper justifySpaceBetween">
      <BackButton onClick={toPreviousStep} />
      <NextButton onClick={toNextStep} />
    </div>
  )
}

const NextButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} className="PageStepper__row">
      <span className="PageStepper__row__label">{t('page_stepper.next')}</span>
      <img src={nextImg} className="PageStepper__row__nextImg" />
    </div>
  )
}

const BackButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} className="PageStepper__row">
      <img src={backImg} className="PageStepper__row__backImg" />
      <span className="PageStepper__row__label">{t('page_stepper.previous')}</span>
    </div>
  )
}

const FinishButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()

  return (
    <div onClick={onClick} className="PageStepper__row">
      <span className="PageStepper__row__label">{t('page_stepper.finish')}</span>
      <img src={checkmarkImg} className="PageStepper__row__checkmarkImg" />
    </div>
  )
}
