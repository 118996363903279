import { types } from 'mobx-state-tree'

const OrdersList = types
  .model('OrdersList', {
    searchFilters: types.model({
      locationId: types.maybeNull(types.number),
      designationId: types.maybeNull(types.number),
      startDate: types.maybeNull(types.string),
      endDate: types.maybeNull(types.string),
    }),
  })
  .actions(self => ({
    setLocationId(locationId: number | null) {
      self.searchFilters.locationId = locationId
    },
    setDesignationId(designationId: number | null) {
      self.searchFilters.designationId = designationId
    },
    setStartDate(startDate: string | null) {
      self.searchFilters.startDate = startDate
    },
    setEndDate(endDate: string | null) {
      self.searchFilters.endDate = endDate
    },
    reset() {
      self.searchFilters.locationId = null
      self.searchFilters.designationId = null
      self.searchFilters.startDate = null
      self.searchFilters.endDate = null
    },
  }))
  .views(self => ({
    get activeFiltersCount() {
      return [
        self.searchFilters.locationId,
        self.searchFilters.designationId,
        self.searchFilters.startDate,
        self.searchFilters.endDate,
      ].filter(value => Boolean(value)).length
    },
  }))

export const ordersListStore = OrdersList.create({
  searchFilters: {
    locationId: null,
    designationId: null,
    startDate: null,
    endDate: null,
  },
})
