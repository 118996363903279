export enum EnvironmentType {
  localDevelopment = 'local development',
  development = 'development',
  production = 'production',
}

export const config: Config = isProductionEnvironment()
  ? (require('./env.prod') as typeof import('./env.prod')).config
  : (require('./env.dev') as typeof import('./env.dev')).config

export function isProductionEnvironment() {
  return process.env.NODE_ENV === EnvironmentType.production
}

export function isLocalDevelopmentEnvironment() {
  return process.env.NODE_ENV === EnvironmentType.localDevelopment
}

export type Config = {
  API_URL: string
  API_GR_URL: string
  API_SA_URL: string
  USE_REAL_API: boolean
  GOOGLE_API_KEY: string
  GOOGLE_AUTH_CLIENT_ID: string
  IOS_CLIENT_ID?: string
  FB_AUTH_CLIENT_ID: string
  SHOW_MESSAGES_TAB: boolean
  STRIPE_PUBLISHABLE_KEY_UAE: string
  STRIPE_PUBLISHABLE_KEY_GR: string
  LEAN_APP_TOKEN: string
  LEAN_PAYMENT_DESTINATION_ID: string
  FIREBASE_WEB_CONFIG: {
    apiKey: string
    vapidKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
}
