import { useTranslation } from 'react-i18next'

import shiftConfirmed from '../../../../components/assets/confirmed.svg'
import './shift-confirmed.scss'

interface ShiftConfirmedProps {
  closeConfirmation: () => void
}

const ShiftConfirmed = ({ closeConfirmation }: ShiftConfirmedProps) => {
  const { t } = useTranslation()

  return (
    <div className="ShiftConfirmed">
      <div className="ShiftConfirmed__container">
        <img src={shiftConfirmed} />
        <h2 className="ShiftConfirmed__modalTitle">{t('shift_attendance_common.shift_confirmed')}</h2>
        <p>{t('shift_attendance_common.thanks_for_confirming')}</p>
        <button onClick={closeConfirmation} className="ShiftConfirmed__closeButton">
          {t('shift_attendance_common.done')}
        </button>
      </div>
    </div>
  )
}

export default ShiftConfirmed
