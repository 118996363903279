import { types } from 'mobx-state-tree'

export const ScheduleActiveShiftModel = types.model('ScheduleActiveShift', {
  shift_id: types.number,
  active_shift_id: types.number,
  start_time: types.maybeNull(types.number),
  end_time: types.maybeNull(types.number),
  client_checkin_time: types.maybeNull(types.number),
  client_checkout_time: types.maybeNull(types.number),
  is_available_for_confirmation: types.boolean,
  status: types.model('ScheduleActiveShiftStatus', {
    id: types.number,
    title: types.string,
  }),
})
