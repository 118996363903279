import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'

import { DropdownMenu } from '../../../../components/dropdown-menu/dropdown-menu'
import { Header } from '../../../../components/app-layout/header/header'
import { Separator } from '../../../../components/app-layout/separator/separator'
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner'
import { images } from './assets'
import { Section } from './components/section/section'
import { MenuItem } from './components/menu-item/menu-item'
import { UIProps } from '../types'
import './invoice.scss'
import { OgramModal } from '../../../../components/ogram-modal/ogram-modal'
import { ReportIssueForm } from './components/report-issue-form/report-issue-form'
import 'react-toastify/dist/ReactToastify.css'
import { Payment } from './components/payment/payment'
import { getInvoiceItemStatusLabel } from '../../../../utils/invoice'
import { routes } from '../../../../routes'
import { Link } from '../../../../services/router'

const TOAST_BACKGROUND_COLOR = '#7f72d9'
const TOAST_AUTO_CLOSE = 3000

export const Invoice = ({
  id,
  loadingInvoice,
  downloadingInvoice,
  invoice,
  handleDownload,
  isReportIssueFormVisible,
  setIsReportIssueFormVisible,
  handleReportIssue,
  reportIssueErrors,
  isReportIssueSubmitting,
  hidePaymentFunctionality,
}: UIProps) => {
  const { t } = useTranslation()

  const orderIds = (invoice?.items ?? []).map(invoiceItem => invoiceItem.order_id)

  return (
    <div className="Invoice">
      <ToastContainer autoClose={TOAST_AUTO_CLOSE} progressStyle={{ background: TOAST_BACKGROUND_COLOR }} />
      <OgramModal
        modalVisible={isReportIssueFormVisible}
        onClose={() => setIsReportIssueFormVisible(false)}
        contentStyle={{ overflow: 'hidden' }}
      >
        <ReportIssueForm
          onReportIssueSubmit={handleReportIssue}
          setReportIssueModalVisible={setIsReportIssueFormVisible}
          errors={reportIssueErrors}
          isSubmitting={isReportIssueSubmitting}
        />
      </OgramModal>
      <Header
        showBack
        title={t('invoice.invoice_#', { index: invoice?.invoiceNumber ?? `#INV-${id}` })}
        rightComponent={
          <DropdownMenu
            outerComponent={<img src={images.verticalDropdownImg} className="Invoice__dropdownImg" />}
            items={[
              <MenuItem
                key={0}
                title={downloadingInvoice ? t('invoice.exporting') : t('invoice.export_pdf')}
                img={<img src={images.exportImg} />}
                onClick={handleDownload}
              />,
              <MenuItem
                key={1}
                title={t('invoice.report_issue')}
                img={<img src={images.issueImg} />}
                onClick={() => setIsReportIssueFormVisible(true)}
              />,
            ]}
          />
        }
      />
      <Separator.Vertical height={27} />
      {loadingInvoice ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="Invoice__sectionHeader">
            <img src={images.detailsImg} className="Invoice__sectionHeader__detailsImg " />
            <Separator.Horizontal width={8} />
            <span className="Invoice__sectionHeader__title">{t('invoice.details')}</span>
          </div>
          <Separator.Vertical height={14} />
          <div className="Invoice__cardContainer">
            <div className="Invoice__cardContainer__row">
              <span className="Invoice__cardContainer__row__label">{t('invoice.invoice_number')}</span>
              <span className="Invoice__cardContainer__row__value">{invoice?.invoiceNumber ?? `#INV-${id}`}</span>
            </div>
            {orderIds.length > 0 && (
              <div className="Invoice__cardContainer__row">
                <span className="Invoice__cardContainer__row__label">{t('orders_list.title')}</span>
                <span className="Invoice__cardContainer__row__value">
                  {orderIds.map(orderId => (
                    <>
                      <Link
                        route={routes.orderConfirmation}
                        params={{ orderId: String(orderId) }}
                        className="Invoice__orderId"
                      >
                        {orderId}
                      </Link>
                      <span className="Invoice__orderId__separator">,</span>
                    </>
                  ))}
                </span>
              </div>
            )}
            {invoice?.date && (
              <div className="Invoice__cardContainer__row">
                <span className="Invoice__cardContainer__row__label">{t('invoice.date')}</span>
                <span className="Invoice__cardContainer__row__value">
                  {moment(invoice.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
                </span>
              </div>
            )}
            {invoice?.dueDate && (
              <div className="Invoice__cardContainer__row">
                <span className="Invoice__cardContainer__row__label">{t('invoice.due_date')}</span>
                <span className="Invoice__cardContainer__row__value">
                  {moment(invoice.dueDate, 'YYYY-MM-DD').format('DD MMM YYYY')}
                </span>
              </div>
            )}
            <div className="Invoice__cardContainer__row last">
              <span className="Invoice__cardContainer__row__label">{t('invoice.status')}</span>
              <span className="Invoice__cardContainer__row__value">
                {invoice && getInvoiceItemStatusLabel({ status: invoice.status, due_date: invoice.dueDate }, t)}
              </span>
            </div>
          </div>
          <Separator.Vertical height={14} />
          <div className="Invoice__sectionHeader">
            <img src={images.costsImg} className="Invoice__sectionHeader__costsImg" />
            <Separator.Horizontal width={8} />
            <span className="Invoice__sectionHeader__title">{t('invoice.costs')}</span>
          </div>
          <Separator.Vertical height={14} />
          <div className="Invoice__cardContainer">
            <div className="Invoice__cardContainer__row">
              <span className="Invoice__cardContainer__row__label">{t('invoice.subtotal')}</span>
              <span className="Invoice__cardContainer__row__value">
                {invoice?.subtotalAmount} {invoice?.currency}
              </span>
            </div>
            <div className="Invoice__cardContainer__row">
              <span className="Invoice__cardContainer__row__label">{t('invoice.transportation')}</span>
              <span className="Invoice__cardContainer__row__value">
                {invoice?.transportationAmount} {invoice?.currency}
              </span>
            </div>
            <div className="Invoice__cardContainer__row">
              <span className="Invoice__cardContainer__row__label">
                {t('invoice.vat_percent', { count: invoice?.taxPercent ?? 0 })}
              </span>
              <span className="Invoice__cardContainer__row__value">
                {invoice?.taxAmount} {invoice?.currency}
              </span>
            </div>
            <div className="Invoice__cardContainer__row last">
              <span className="Invoice__cardContainer__row__label">{t('invoice.total')}</span>
              <span className="Invoice__cardContainer__row__value highlighted">
                {invoice?.totalAmount} {invoice?.currency}
              </span>
            </div>
          </div>
          <Separator.Vertical height={14} />
          {!hidePaymentFunctionality && invoice?.payment && <Payment payment={invoice.payment} />}
          <div className="Invoice__sectionHeader">
            <img src={images.breakdownImg} className="Invoice__sectionHeader__breakdownImg" />
            <Separator.Horizontal width={8} />
            <span className="Invoice__sectionHeader__title">{t('invoice.breakdown')}</span>
          </div>
          {invoice?.items.map(item => (
            <Section
              key={item.id}
              invoiceItemId={String(item.id)}
              currency={invoice.currency}
              title={item.title ?? ''}
              totalHours={item.total_hours}
              totalOG={item.total_sps}
              totalShifts={item.total_shifts}
              ogrammers={item.sps}
              location={item.location}
            />
          ))}
          <Separator.Vertical height={40} />
          {/* Uncomment when there is an API */}
          {/* <Button
            title={t('invoice.pay_now')}
            onClick={() => { }}
            className="Invoice__payButton"
          />
          <Separator.Vertical height={40} /> */}
        </>
      )}
    </div>
  )
}
