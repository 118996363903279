import moment from 'moment'

import { Separator } from '../../../../../components/app-layout/separator/separator'
import locationImg from '../../../../../components/assets/location-icon-grey.svg'
import calendarImg from '../../../../../components/assets/calender-icon-grey.svg'
import './rating-card.scss'

type Props = {
  title: string
  amount: number
  location: string
  startDateString: string
  endDateString: string
}

const getFormattedDate = (dateString: string) => {
  return moment(dateString, 'YYYY-MM-DD').format('DD MMM')
}

export const RatingCard = ({ title, amount, location, startDateString, endDateString }: Props) => (
  <div className="RatingCard">
    <span className="RatingCard__title">
      {title} <span className="RatingCard__title__amount">({amount})</span>
    </span>
    <Separator.Vertical height={12} />
    <div className="RatingCard__row">
      <img src={locationImg} alt="" />
      <Separator.Horizontal width={11} />
      <span className="RatingCard__row__label">{location}</span>
    </div>
    <Separator.Vertical height={12} />
    <div className="RatingCard__row">
      <img src={calendarImg} alt="" />
      <Separator.Horizontal width={9} />
      <span className="RatingCard__row__label">
        {startDateString === endDateString
          ? getFormattedDate(startDateString)
          : `${getFormattedDate(startDateString)} - ${getFormattedDate(endDateString)}`}
      </span>
    </div>
  </div>
)
