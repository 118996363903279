import { useTranslation } from 'react-i18next'
import { nonVoid } from 'assertio'
import { default as ReactSelect } from 'react-select'

import { FormField } from '../form'
import './select.scss'

type SelectProps<T> = {
  label?: string
  required?: boolean
  field?: FormField<T>
  value?: T
  onChange?: (val: T) => void
  options: { label: string; value: T; key: string | null }[]
  className?: string
  placeholderClassName?: string
}

export function Select<T>(props: SelectProps<T>) {
  const { t } = useTranslation()
  const handleChange = (value: T) => {
    props.field?.setData(value)
    props.onChange?.(value)
  }

  const controlDefaultClassNames = `CustomSelect__control${props.className ? ` ${props.className}` : ''}`
  const options = props.options.map(val => {
    return { value: val.value, label: val.label }
  })
  const item = options.find(val => val.value === (props.value ?? props.field?.value))

  return (
    <>
      <ReactSelect
        defaultValue={item}
        onChange={selectedOption => {
          handleChange(nonVoid(props.options.find(val => val.value === selectedOption?.value)).value)
        }}
        required={props.required}
        options={options}
        placeholder={props.label ?? t('common.select')}
        classNames={{
          control: state =>
            !state.isFocused ? controlDefaultClassNames : `${controlDefaultClassNames} CustomSelect__control--focused`,
          menu: state => 'CustomSelect__menu',
          placeholder: state => props.placeholderClassName ?? 'CustomSelect__placeholder',
          singleValue: state => 'CustomSelect__singleValue',
        }}
        className="CustomSelect"
      />
    </>
  )
}
