import { useEffect } from 'react'
import { observer } from 'mobx-react'

import { useStore } from '../../utils/mst-hooks'
import { routes } from '../../routes'
import { useRedirect } from '../../services/router'

export const withNonAuthenticated = <T extends Record<string, unknown>>(Component: React.ComponentType<T>) => {
  const ComponentWithHomeRouting = observer((props: T) => {
    const { logged } = useStore().data

    const redirect = useRedirect()

    useEffect(() => {
      if (logged) {
        redirect(routes.index, {})
      }
    }, [])

    if (logged) {
      return null
    }

    return <Component {...props} />
  })

  return ComponentWithHomeRouting
}
