import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'
import { ApiError, ApiErrorTypes } from 'ogram-react'

import { isProductionEnvironment } from '../config/env'

if (isProductionEnvironment()) {
  Sentry.init({
    dsn: 'https://51665d1b23424de488ae50aaa2b4dde6@o418998.ingest.sentry.io/4504117096349696',
    integrations: [
      new BrowserTracing({ tracingOrigins: ['*'] }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: 1.0,
    normalizeDepth: 8,
    ignoreErrors: [
      'Error: ApiError: AuthError',
      'Error: ApiError: ConnectionError',
      'Error: ApiError: ClientError',
      'Error: ApiError: NotFound',
    ],
  })
}

export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
  const initializedClient = Sentry.getCurrentHub().getClient()
  if (!initializedClient) return
  Sentry.addBreadcrumb(breadcrumb)
}

/**
 * If error is of ApiError instance, loggs only errors we are interested in.
 * Otherwise loggs every error it's given
 * @param error
 * @param errorInfo
 * @returns void
 */
export function handleReactErrorCatch(error: Error, errorInfo: React.ErrorInfo) {
  if (error instanceof ApiError) {
    const loggedErrors = [ApiErrorTypes.ServerError, ApiErrorTypes.TooManyRequestsError, ApiErrorTypes.PermissionError]
    if (loggedErrors.includes(error.type)) {
      console.error(error)
    }
    return
  }

  console.error(error, errorInfo)
}
