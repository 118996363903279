import { Separator } from '../../../app-layout/separator/separator'
import './menu-item.scss'

type Props = {
  img?: React.ReactNode | null
  title: string
  onClick: (ev: React.MouseEvent<HTMLDivElement>) => void
  additionalTitleStyle?: object
}

export const MenuItem = ({ img, title, onClick, additionalTitleStyle }: Props) => {
  return (
    <div onClick={onClick} className="MenuItem">
      {img}
      <Separator.Horizontal width={16} />
      <span className="MenuItem__title" style={additionalTitleStyle}>
        {title}
      </span>
    </div>
  )
}
