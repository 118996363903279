import { createContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DraftJobInstance } from '../../../../models-ui/order/store/draft-job'
import { useDraftJobsStore } from '../../../../models-ui/order/store'
import { JobDraftDetailsFormComponent } from '../../../../components/job-draft-details-form/job-draft-details-form-component'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'
import { DraftJobFields } from '../../../../models-ui/order/store/job-fields/fields'
import './job-draft-details.scss'
import { useMutation, useStore } from '../../../../utils/mst-hooks'
import { OrderJobDetailsModel, OrderJobDetailsSnapshot } from '../../../../models/order/order-job-details'
import { createDraftJobFromDetailsInstance } from '../../order-draft/utils/create-draft-job-data-from-details-instance'

type JobDraftDetailsProps = {
  orderId?: string
  draftJobId: string
  activePopupField?: DraftJobFields | null
  stepNumber?: string
  isExtension: boolean
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DraftJobContext = createContext<DraftJobInstance>(null!)

export const JobDraftDetailPage = withErrorBoundary(
  observer(({ orderId, draftJobId, activePopupField, stepNumber, isExtension }: JobDraftDetailsProps) => {
    const { t } = useTranslation()
    const { getDraftJob } = useDraftJobsStore()
    const { getLastJob } = useStore().data

    const job = getDraftJob(draftJobId)

    const [isPrefilled, setIsPrefilled] = useState(false)

    const [, getLastJobQuery] = useMutation(
      ({ locationId, workCategoryId }: { locationId: number; workCategoryId: number }) =>
        getLastJob(locationId, workCategoryId),
      {
        onSuccess: result => {
          const lastJob =
            result.map((item: OrderJobDetailsSnapshot): DraftJobInstance => {
              const jobDetails = OrderJobDetailsModel.create(item)
              return createDraftJobFromDetailsInstance(jobDetails)
            })[0] ?? null

          if (lastJob) {
            job.fillBasedOnTemplateJob(lastJob)
            setIsPrefilled(true)
          }
        },
      },
    )

    useEffect(() => {
      const locationId = job.location.value?.id
      const workCategoryId = job.category.id

      if (locationId && workCategoryId) {
        getLastJobQuery({ locationId, workCategoryId })
      }
    }, [job.location.value?.id, job.category.id])

    return (
      <DraftJobContext.Provider value={job}>
        <JobDraftDetailsFormComponent
          isExtension={isExtension}
          orderId={orderId}
          orderDraftTitle={t('order_draft.title')}
          formSubtitle={t('job_draft_details.fill_job_details')}
          activePopupField={activePopupField}
          job={job}
          getFormTitle={professionName => {
            return t('job_draft_details.title', { category: professionName })
          }}
          showStepIndicator
          stepNumber={Number(stepNumber)}
          isPrefilled={isPrefilled}
        />
      </DraftJobContext.Provider>
    )
  }),
)
