import { DropdownMenu } from '../../../../../components/dropdown-menu/dropdown-menu'
import verticalDropdownImg from '../../../../../components/assets/vertical-dropdown.svg'
import { Header as LayoutHeader } from '../../../../../components/app-layout/header/header'
import { MenuItem } from '../../../../../components/dropdown-menu/components/menu-item/menu-item'
import downloadImg from '../../../../../components/assets/download.svg'
import uploadImg from '../../../../../components/assets/upload.svg'
import duplicateImg from '../../../../../components/assets/duplicate.svg'
import { t } from '../../../../../translations'
import { ProtectedRoute } from '../../../../../components/protected-route/protected-route'
import { UserRole } from '../../../../../models/account'

type InputProps = {
  orderId: string
  isQuoteDownloading: boolean
  downloadQuote: () => void
  isPendingLPOUpload: boolean
  isLPOUploading: boolean
  uploadLPO: () => void
  downloadLPO: () => void
  isLPODownloading: boolean
  lpoWasUploaded: boolean
  duplicateOrder: () => void
  showQuoteDownload: boolean
}

export const Header = ({
  orderId,
  isQuoteDownloading,
  downloadQuote,
  isPendingLPOUpload,
  isLPOUploading,
  uploadLPO,
  downloadLPO,
  isLPODownloading,
  lpoWasUploaded,
  duplicateOrder,
  showQuoteDownload,
}: InputProps) => {
  const menuItems = [
    <MenuItem
      key={0}
      title={t('order.menu_duplicate_order')}
      img={<img src={duplicateImg} />}
      onClick={duplicateOrder}
    />,
  ]

  if (showQuoteDownload) {
    menuItems.push(
      <MenuItem
        key={1}
        title={isQuoteDownloading ? t('order.quotation_download_in_progress_text') : t('order.menu_download_quote')}
        img={<img src={downloadImg} />}
        onClick={downloadQuote}
      />,
    )
  }

  if (Boolean(isPendingLPOUpload)) {
    menuItems.push(
      <MenuItem
        key={2}
        title={isLPOUploading ? t('order.lpo_upload_in_progress_text') : t('order.menu_upload_lpo')}
        img={<img src={uploadImg} />}
        onClick={uploadLPO}
      />,
    )
  }

  if (Boolean(lpoWasUploaded)) {
    menuItems.push(
      <MenuItem
        key={3}
        title={isLPODownloading ? t('order.lpo_download_in_progress_text') : t('order.lpo_download_title')}
        img={<img src={uploadImg} />}
        onClick={downloadLPO}
      />,
    )
  }

  return (
    <LayoutHeader
      showBack
      title={t('order.order_number', { id: orderId })}
      rightComponent={
        <ProtectedRoute allowedRoles={[UserRole.Owner, UserRole.Admin, UserRole.Finance]} showDefaultFallbackUI={false}>
          <DropdownMenu outerComponent={<img src={verticalDropdownImg} />} items={menuItems} />
        </ProtectedRoute>
      }
    />
  )
}
