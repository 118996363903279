import './payment-methods.scss'
import { useTranslation } from 'react-i18next'

import { PaymentMethod, PaymentStatus } from '../../../../models/payment'
import { Header, Separator } from '../../../../components/app-layout'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { StickyPanel } from '../../../../components/sticky-panel/sticky-panel'
import { paymentMethodsData } from '../../utils'
import { UIProps } from '../types'
import { PaymentMethod as PaymentMethodComponent } from './components/payment-method'
import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'

export const PaymentMethods = ({
  isLoading,
  cards,
  methods,
  chooseSavedCard,
  isSavedCardChosen,
  choosePaymentMethod,
  isPaymentMethodChosen,
  isAnyPaymentMethodChosen,
  isAnySavedCardChosen,
  onPaymentMethodContinue,
  onSavedCardContinue,
  amount,
  isChosenPaymentMethodLoading,
  connectLean,
  continueText,
  leanSetupCompleted,
  leanSetupStatus,
  leanStatusUpdatedAt,
}: UIProps) => {
  const { t } = useTranslation()
  const redirect = useRedirect()

  return (
    <div className="PaymentMethods">
      <Header
        title={t('payment.payment_methods')}
        showBack
        handleBackClick={() => redirect(routes.paymentList, { paymentStatus: String(PaymentStatus.Pending) })}
      />
      <Separator.Vertical height={35} />
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="PaymentMethods__container">
          <span className="PaymentMethods__title">{t('payment.select_payment_method')}</span>
          <div className="PaymentMethods__methodsList">
            {cards.map((card) => (
              <PaymentMethodComponent
                paymentMethod={PaymentMethod.Card}
                isSavedCard
                key={card.id}
                chooseMethod={() => chooseSavedCard(card.id)}
                title={`•••• •••• •••• •••• ${card.card_number_last4}`}
                cardBrand={card.brand}
                paymentMethodProps={{ leanSetupCompleted, leanSetupStatus, leanStatusUpdatedAt }}
                isMethodChosen={isSavedCardChosen(card.id)}
              />
            ))}
            {methods.map((paymentMethod) => {
              const paymentMethodValue = String(paymentMethod) as PaymentMethod

              return (
                <PaymentMethodComponent
                  paymentMethod={paymentMethodValue}
                  isSavedCard={false}
                  key={paymentMethod}
                  chooseMethod={() => choosePaymentMethod(paymentMethodValue)}
                  title={t(paymentMethodsData[paymentMethodValue].description)}
                  connectLean={connectLean}
                  paymentMethodProps={{ leanSetupCompleted, leanSetupStatus, leanStatusUpdatedAt }}
                  isMethodChosen={isPaymentMethodChosen(paymentMethodValue)}
                />
              )
            })}
          </div>
          {isAnyPaymentMethodChosen && (
            <StickyPanel style={{ justifyContent: 'center' }}>
              <button
                className="PayButton__button"
                disabled={isChosenPaymentMethodLoading}
                onClick={onPaymentMethodContinue}
              >
                {isChosenPaymentMethodLoading ? t('payment.loading') : continueText}
              </button>
            </StickyPanel>
          )}
          {isAnySavedCardChosen && (
            <StickyPanel onClick={onSavedCardContinue} style={{ justifyContent: 'center' }}>
              <button className="PayButton__button" onClick={onSavedCardContinue} style={{ background: '#7767E4' }}>
                {t('payment.pay')} <strong>{amount}</strong>
              </button>
            </StickyPanel>
          )}
        </div>
      )}
    </div>
  )
}
