import { flow, types } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const UnconfirmedPointsOfContactsModel = types.model('UnconfirmedPointsOfContacts', {
  email: types.string,
  roles: types.array(types.number),
  client_locations: types.maybeNull(types.array(types.number)),
})

export const unconfirmedPointsOfContactActions = (self: DataStore) => ({
  getUnconfirmedPointsOfContact: flow(function* ({ locationId }: { locationId?: number }) {
    self.unconfirmedPointsOfContact = yield self.request(
      'get',
      `client/pocs/unconfirmed${locationId !== undefined ? `?location_id=${locationId}` : ''}`,
    )
    return self.unconfirmedPointsOfContact
  }),
})
