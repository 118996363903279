import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useDelayedQuery, useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { PaymentExternalStatus, PaymentMethod, PaymentStatus } from '../../../models/payment'
import { UIProps } from './types'
import { useOnPayHandler } from '../../../services/payment-service'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export function createPaymentDetailsContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ paymentId, hideBack }: { paymentId: number; hideBack?: number }) => {
      const { t } = useTranslation()
      const { getPayment, currentPayment, resetPayment } = useStore().data

      const getPaymentQuery = useQuery(() => getPayment(paymentId))
      const { enableQuery: getPaymentDelayedQuery } = useDelayedQuery(() => getPayment(paymentId))
      const [, resetPaymentRequest] = useMutation(() => resetPayment(paymentId), {
        onSuccess: (result) => {
          getPaymentDelayedQuery()
          toast.success('Payment was cancelled')
        },
      })

      const onPayClick = useOnPayHandler()

      const isPending = String(currentPayment?.status) === PaymentStatus.Pending
      const isProcessing = String(currentPayment?.status) === PaymentStatus.Processing

      const isWireTransfer = currentPayment?.type_id === Number(PaymentMethod.Wire)
      const isLean = currentPayment?.type_id === Number(PaymentMethod.Lean)

      const payNowButtonText =
        currentPayment?.type_id === Number(PaymentMethod.Lean) &&
        currentPayment.external_status === PaymentExternalStatus.AWAITING_AUTHORIZATION
          ? t('payment.authorize')
          : t('payment.pay_now')

      if (!currentPayment) {
        return null
      }

      return (
        <UIComponent
          paymentId={paymentId}
          hideBack={hideBack}
          isLoading={getPaymentQuery.isLoading}
          currentPayment={currentPayment}
          onMainButtonClick={() => onPayClick(currentPayment)}
          isWireTransferPending={isPending && isWireTransfer}
          isWireTransferProcessing={isProcessing && isWireTransfer}
          isLeanPaymentAwaitingAuthorization={
            isPending && isLean && currentPayment.external_status === PaymentExternalStatus.AWAITING_AUTHORIZATION
          }
          payNowEnabled={isPending && !isWireTransfer}
          payNowButtonText={payNowButtonText}
          resetPayment={resetPaymentRequest}
        />
      )
    }),
  )
}
