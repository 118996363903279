import './quality.scss'

type Props = {
  id: number
  title: string
  selected: boolean
  onPress: (id: number) => void
}

export const Quality = ({ id, title, selected, onPress }: Props) => {
  return (
    <div onClick={() => onPress(id)} className={`Quality ${selected ? 'selected' : ''}`}>
      <span className="Quality__label">{title}</span>
    </div>
  )
}
