import { OgramModal } from '../../ogram-modal/ogram-modal'
import ogramIcon from './assets/ogram-icon.svg'
import burgerIcon from './assets/burger.svg'
import phoneIcon from './assets/phone.svg'
import closeIcon from '../assets/close.svg'

export const IosBanner = ({ showBanner, closeBanner }: { showBanner: boolean; closeBanner: () => void }) => {
  return (
    <OgramModal
      overlayStyle={{ overflow: 'hidden' }}
      contentStyle={{ overflow: 'hidden' }}
      modalVisible={showBanner}
      onClose={() => closeBanner()}
    >
      <div className="InstallAppBanner">
        <div className="InstallAppBanner__top">
          <img src={closeIcon} onClick={() => closeBanner()} />
        </div>
        <div className="InstallAppBanner__content">
          <img className="InstallAppBanner__content__logo" src={ogramIcon} />
          <span className="InstallAppBanner__content__description">
            Add <span className="InstallAppBanner__content__description__ogram">Ogram</span> app to your screen for easy
            access, and better experience
          </span>
          <div className="InstallAppBanner__content__panelIos">
            <span className="InstallAppBanner__content__panelIos__tap">Tap</span>
            <img className="InstallAppBanner__content__panelIos__burger" src={burgerIcon} />
            <span className="InstallAppBanner__content__panelIos__then">then</span>
            <img className="InstallAppBanner__content__panelIos__phone" src={phoneIcon} />
            <span className="InstallAppBanner__content__panelIos__add">Add to Home Screen</span>
          </div>
        </div>
      </div>
    </OgramModal>
  )
}
