import './referrals-list.scss'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../../components/app-layout'
import staffImg from '../../../components/assets/staff.svg'
import { SectionTitle } from '../components/section-title/section-title'
import { ReferralListItem } from '../components/referral-list-item/referral-list-item'
import { AffiliateReferralSnapshot, ReferralStatus } from '../../../models/affiliate'
import { getStatusSettings } from '../helper'
import { Pagination } from '../../../components/pagination/pagination'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { TcAcceptedChecker } from '../../../components/affiliate/tc-accepted-checker/tc-accepted-checker'

export const ReferralsList = observer(() => {
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1)

  const {
    getAffiliateStats,
    affiliateStats,
    getAffiliateReferrals,
    affiliateReferralsList,
    affiliateReferralsPagesCount,
    getAffiliateStatuses,
    affiliateReferralStatuses,
  } = useStore().data

  useQuery(getAffiliateStats)
  useQuery(getAffiliateStatuses)

  const [getAffiliateReferralsResult, getAffiliateReferralsRequest] = useMutation(({ page }: { page: number }) =>
    getAffiliateReferrals(page),
  )

  useEffect(() => {
    getAffiliateReferralsRequest({ page: currentPage })
  }, [])

  return (
    <div className="ReferralsListPage">
      <TcAcceptedChecker />
      <Header title={t('common_affiliate.referrals_list')} showBack />
      <Separator.Vertical height={28} />
      <div className="ReferralsListPage__container">
        <SectionTitle
          icon={staffImg}
          text={t('common_affiliate.my_referrals')}
          statText={`${affiliateStats?.referralsCount ?? 0} ${t('common.ogs')}`}
        />
        <Separator.Vertical height={20} />
        <div className="ReferralsListPage__card">
          <div className="ReferralsListPage__statuses">
            {affiliateReferralStatuses.map((status: { status: number; referralsCount: number }, index) => {
              const statusSettings = getStatusSettings(status.status as ReferralStatus)

              return (
                <div key={index} className="ReferralsListPage__statuses__item">
                  <div className="ReferralsListPage__statuses__item__left">
                    <div
                      className="ReferralsListPage__statuses__item__left__circle"
                      style={{ background: `rgb(${statusSettings.mainColorRGB.join(', ')})` }}
                    ></div>
                    <span
                      className="ReferralsListPage__statuses__item__left__title"
                      style={{ color: `rgb(${statusSettings.mainColorRGB.join(', ')})` }}
                    >
                      {t(statusSettings.title)}
                    </span>
                  </div>
                  <div className="ReferralsListPage__statuses__item__right">
                    <span className="ReferralsListPage__statuses__item__right__title">{status.referralsCount}</span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <Separator.Vertical height={20} />
        <div className="ReferralsListPage__card">
          <div className="ReferralsListPage__referralList">
            {getAffiliateReferralsResult.isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                {affiliateReferralsList.map((affiliateReferral: AffiliateReferralSnapshot) => (
                  <ReferralListItem key={affiliateReferral.id} affiliateReferral={affiliateReferral} />
                ))}
              </>
            )}
          </div>
          <Separator.Vertical height={20} />
          <Pagination
            handleNext={() => {
              const newPage = currentPage + 1
              setCurrentPage(newPage)
              getAffiliateReferralsRequest({ page: newPage })
            }}
            handlePrev={() => {
              const newPage = currentPage - 1
              setCurrentPage(newPage)
              getAffiliateReferralsRequest({ page: newPage })
            }}
            currentPage={currentPage}
            totalPages={affiliateReferralsPagesCount}
          />
        </div>
        <Separator.Vertical height={50} />
      </div>
    </div>
  )
})
