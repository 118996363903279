import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Separator } from '../../../../app-layout/separator/separator'
import { Button } from '../../../../button/button'
import { DraftJobInstance } from '../../../../../models-ui/order/store/draft-job'
import { BoardingStatus } from '../../../../../models/job'
import { getBoardingLabel } from './get-boarding-label'
import { JobDraftFieldError } from '../error/error'
import './boarding.scss'

export const JobDraftBoarding = observer(({ job }: { job: DraftJobInstance }) => {
  const { t } = useTranslation()

  return (
    <div className="JobDraftBoarding">
      <span className="Popup__title">{t('job_draft_details.accommodation_title')}</span>
      <div className="JobDraftBoarding__innerContainer">
        <Button
          title={getBoardingLabel(BoardingStatus.noBoarding)}
          onClick={() => {
            job.boarding.set(BoardingStatus.noBoarding)
          }}
          className={`JobDraftBoarding__innerContainer__button ${
            job.boarding.value === BoardingStatus.noBoarding ? 'active' : ''
          } ${job.boarding.hasError ? 'error' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={getBoardingLabel(BoardingStatus.halfBoarding)}
          onClick={() => {
            job.boarding.set(BoardingStatus.halfBoarding)
          }}
          className={`JobDraftBoarding__innerContainer__button ${
            job.boarding.value === BoardingStatus.halfBoarding ? 'active' : ''
          } ${job.boarding.hasError ? 'error' : ''}`}
        />
        <Separator.Vertical height={10} />
        <Button
          title={getBoardingLabel(BoardingStatus.fullBoarding)}
          onClick={() => {
            job.boarding.set(BoardingStatus.fullBoarding)
          }}
          className={`JobDraftBoarding__innerContainer__button ${
            job.boarding.value === BoardingStatus.fullBoarding ? 'active' : ''
          } ${job.boarding.hasError ? 'error' : ''}`}
        />
        <JobDraftFieldError error={job.boarding.error} />
      </div>
    </div>
  )
})
