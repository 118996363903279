import { useState, useEffect } from 'react'

import { getFirebaseToken } from './firebase-utils'

export const useFirebaseToken = () => {
  const [firebaseToken, setFirebaseToken] = useState('0')

  useEffect(() => {
    getFirebaseToken()
      .then(setFirebaseToken)
      .catch(() => setFirebaseToken('0'))
  }, [])

  return firebaseToken
}
