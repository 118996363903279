import { useTranslation } from 'react-i18next'
import { useContext } from 'react'

import scannerIcon from '../assets/scanner.svg'
import { UIStateContext } from '../ui-state-management/ui-state-management'
import './scan-qr-button.scss'

export const ScanQrButton = ({ style, textStyle }: { style?: object; textStyle?: object }) => {
  const { t } = useTranslation()

  const { setShowQRScanner } = useContext(UIStateContext)

  return (
    <button className="ScanQRButton" onClick={() => setShowQRScanner(true)} style={style}>
      <img src={scannerIcon} />
      <span style={textStyle}>{t('qr_scan.scan_qr')}</span>
    </button>
  )
}
