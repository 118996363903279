import { homeOnboarding } from './home/home'
import { allJobsOnboarding } from './all-jobs/all-jobs'
import { attendanceReviewOnboarding } from './attendance-review/attendance-review'
import { ordersConfirmationOnboarding } from './orders-confirmation/orders-confirmation'
import { applicantsReviewOnboarding } from './applicants-review/applicants-review'
import { locationsOnboarding } from './locations/locations'
import { usersOnboarding } from './users/users'
import { ratingRequestsOnboarding } from './rating-requests/rating-requests'
import { ordersCreationOnboarding } from './order-creation/order-creation'
import { jobDetailsOnboarding } from './order-creation/onboardings/job-details-onboarding'
import { orderDraftOnboarding } from './order-creation/onboardings/order-draft-onboarding'
import { selectDesignationOnboarding } from './order-creation/onboardings/select-designation-onboarding'
import { invoicesOnboarding } from './invoices/invoices'

export const onboardingsForKnowledgeCenter = [
  homeOnboarding,
  allJobsOnboarding,
  attendanceReviewOnboarding,
  ordersConfirmationOnboarding,
  applicantsReviewOnboarding,
  locationsOnboarding,
  usersOnboarding,
  ratingRequestsOnboarding,
  ordersCreationOnboarding,
  invoicesOnboarding,
]

export const onboardingsForPages = [
  homeOnboarding,
  allJobsOnboarding,
  attendanceReviewOnboarding,
  ordersConfirmationOnboarding,
  applicantsReviewOnboarding,
  locationsOnboarding,
  usersOnboarding,
  ratingRequestsOnboarding,
  selectDesignationOnboarding,
  jobDetailsOnboarding,
  orderDraftOnboarding,
  invoicesOnboarding,
]
