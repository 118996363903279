import { ApiConfig } from 'ogram-react'

import { config } from '../../config/env'

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: config.API_URL,
  timeout: 40000,
  lang: 'en-us',
  refreshURL: 'client/auth/refresh',
}
