import { useState } from 'react'
import { observer } from 'mobx-react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { useDelayedQuery, useMutation, useStore } from '../../../../../utils/mst-hooks'
import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { UIProps } from './types'
import { withErrorBoundary } from '../../../../../components/error/with-error-boundary'

export function createWireTransferUploadSlipContainer(UIComponent: React.FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ paymentId }: { paymentId: number }) => {
      const { processPayment, resetPayment } = useStore().data

      const [slipFile, setSlipFile] = useState<File | null>(null)

      const redirect = useRedirect()
      const { t } = useTranslation()

      const { enableQuery: resetPaymentQuery } = useDelayedQuery(() => resetPayment(paymentId), {
        onSuccess: (result) => {
          toast.success(t('payment.wire_transfer_has_been_canceled'))
          redirect(routes.paymentMethodsList, { paymentId: String(paymentId) })
        },
      })

      const [processPaymentResult, processPaymentRequest] = useMutation(
        ({ file }: { file: File }) => processPayment(paymentId, file),
        {
          onSuccess: (result) => {
            toast.success(t('payment.wire_transfer_slip_was_uploaded'))
            redirect(routes.paymentDetails, { paymentId: String(paymentId), hideBack: 1 })
          },
          onError: (err) => {
            const errorMessage = err.data.message
            const errors = err.data.errors?.slip ?? []
            const errorText = errors.length > 0 ? errors.join(';') : errorMessage

            toast.error(errorText)
          },
          filterError: [ApiErrorTypes.ClientError],
        },
      )

      return (
        <UIComponent
          paymentId={paymentId}
          slipFile={slipFile}
          setSlipFile={setSlipFile}
          resetPaymentQuery={resetPaymentQuery}
          isPaymentProcessing={processPaymentResult.isLoading}
          processPaymentRequest={() => {
            if (slipFile) {
              processPaymentRequest({ file: slipFile })
            }
          }}
          onRemindLater={() => redirect(routes.paymentDetails, { paymentId: String(paymentId), hideBack: 1 })}
        />
      )
    }),
  )
}
