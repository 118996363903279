import { useTranslation } from 'react-i18next'

import scannerIcon from '../../../assets/scanner.svg'
import userCardIcon from '../../assets/user_card.svg'

export const Instruction = () => {
  const { t } = useTranslation()

  return (
    <div className="QrScanner__info">
      <span className="QrScanner__info__title">{t('qr_scan.instruction_title')}</span>
      <div className="QrScanner__info__blocks">
        <div className="QrScanner__info__block">
          <div className="QrScanner__info__block__top">
            <img src={scannerIcon} />
            <span className="QrScanner__info__block__top__title">{t('qr_scan.step_1')}</span>
          </div>
          <span className="QrScanner__info__block__bottom">{t('qr_scan.step_1_text')}</span>
        </div>
        <div className="QrScanner__info__block">
          <div className="QrScanner__info__block__top">
            <img src={userCardIcon} />
            <span className="QrScanner__info__block__top__title">{t('qr_scan.step_2')}</span>
          </div>
          <span className="QrScanner__info__block__bottom">{t('qr_scan.step_2_text')}</span>
        </div>
      </div>
    </div>
  )
}
