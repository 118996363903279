import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useRedirect } from '../../../../../services/router'
import { routes } from '../../../../../routes'
import { Separator } from '../../../separator/separator'
import { Button } from '../../../../button/button'
import newOrderImg from './assets/new-order.svg'
import './order-button.scss'

export const OrderButton = observer(() => {
  const redirect = useRedirect()
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => {
        redirect(routes.setJobCategory, {})
      }}
      className="OrderButton"
    >
      <img src={newOrderImg} alt="" />
      <Separator.Horizontal width={15} />
      <span className="OrderButton__title">{t('sidebar.create_order')}</span>
    </Button>
  )
})
