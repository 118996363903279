import { Fragment } from 'react'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

import { Toggle } from '../../../../../../../../components/toggle/toggle'
import { StickyPanel } from '../../../../../../../../components/sticky-panel/sticky-panel'
import { UIProps } from '../types'
import securityImg from './assets/security.svg'
import './pay-with-card-form.scss'

export const PayWithCardForm = ({
  setIsPaymentFormComplete,
  setIsPaymentFormLoaded,
  setShouldSaveCard,
  shouldSaveCard,
  isPaymentFormLoaded,
  formHandler,
  payButtonText,
  resetPayment,
}: UIProps) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="StripeForm">
        <div className="StripeForm__form">
          <PaymentElement
            options={{
              wallets: {
                applePay: 'never',
                googlePay: 'never',
              },
            }}
            onChange={(event) => {
              setIsPaymentFormComplete(event.complete)
            }}
            onReady={(event) => {
              setIsPaymentFormLoaded(true)
            }}
          />
          {isPaymentFormLoaded && (
            <div className="StripeForm__saveCardToggle" onClick={() => setShouldSaveCard(!shouldSaveCard)}>
              <span className="StripeForm__saveCardToggle__label">{t('payment.save_card_for_future')}</span>
              <Toggle active={shouldSaveCard} onToggle={() => setShouldSaveCard(!shouldSaveCard)} />
            </div>
          )}
          {isPaymentFormLoaded && (
            <div className="StripeForm__securityMessage">
              <img src={securityImg} />
              <span className="StripeForm__securityMessage__text">{t('payment.all_transactions_are_secured')}</span>
            </div>
          )}
        </div>
        {isPaymentFormLoaded && (
          <StickyPanel style={{ justifyContent: 'center' }}>
            <button className="PayButton__button" onClick={formHandler}>
              {payButtonText}
            </button>
          </StickyPanel>
        )}
      </div>
      <div className="StripeForm__additionalContent">
        <span onClick={resetPayment} className="StripeForm__cancelButton">
          {t('payment.card_payment_cancel_button')}
        </span>
      </div>
    </Fragment>
  )
}
