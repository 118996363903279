import { createRoot } from 'react-dom/client'
import 'antd/dist/antd.css'
import 'moment/locale/el'
import 'moment/locale/ar'
import { Helmet } from 'react-helmet'
import { useState, useEffect, Suspense } from 'react'
import moment from 'moment'
import Modal from 'react-modal'
import i18next from 'i18next'
import { ApiError } from 'ogram-react'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

import { Router } from './services/router'
import './services/sentry'
import { MSTProvider } from './utils/mst-hooks'
import { fileService } from './services/file-service'
import { setFileService } from './utils/use-file-service'
import { AppLayout } from './components/app-layout/app-layout'
import { ErrorBoundary } from './components/error/error'
import { config, isLocalDevelopmentEnvironment } from './config/env'
import './translations'
import './index.scss'
import { setupRootStore } from './models/utils/setup-root-store'
import { UiStateManagement } from './components/ui-state-management/ui-state-management'
import { isCurrentMarketLaunched, LANGUAGE_EN, marketService } from './services/market-service'
import { TrackedEvent, trackingService } from './services/tracking-service'
import { load, save } from './services/storage'
import { API_VERSION_URL } from './constants'
import { ComingSoonLayout } from './components/app-layout/coming-soon-layout'

if (Modal.defaultStyles.overlay) {
  Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0, 0.2)'
  Modal.defaultStyles.overlay.height = '100%'
  Modal.defaultStyles.overlay.overflow = 'auto'
  Modal.defaultStyles.overlay.paddingTop = '50px'
}

setFileService({ download: fileService.downloadFile })

const firebaseApp = initializeApp(config.FIREBASE_WEB_CONFIG)
getAnalytics(firebaseApp)

export const store = setupRootStore()

export function App() {
  const [layoutDirection, setLayoutDirection] = useState('ltr')

  useEffect(() => {
    const language = marketService.getCurrentLanguage()
    moment.locale(language ?? LANGUAGE_EN)
    setLayoutDirection(i18next.dir(i18next.language))
  }, [])

  useEffect(() => {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      const appInstallationWasTracked = Boolean(load('appInstallationWasTracked'))
      if (!appInstallationWasTracked) {
        trackingService.track(TrackedEvent.AppInstalledAsProgressive)
        save('appInstallationWasTracked', true)
      }
    }
  }, [])

  return (
    <>
      <Helmet bodyAttributes={{ dir: layoutDirection }}>
        <script>
          {`
(function(w,d,s,l,i){
  w[l] = w[l] || [];
  w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
  var f=d.getElementsByTagName(s)[0], j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
  j.async=true;
  j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
  f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TH7CQ4HN');`}
        </script>
      </Helmet>
      <MSTProvider value={store}>
        <Router>
          <Suspense fallback="Loading">
            <MSTProvider value={store}>
              <ErrorBoundary>
                <UiStateManagement isRtl={layoutDirection === 'rtl'}>
                  {isCurrentMarketLaunched() ? <AppLayout /> : <ComingSoonLayout />}
                </UiStateManagement>
              </ErrorBoundary>
            </MSTProvider>
          </Suspense>
        </Router>
      </MSTProvider>
    </>
  )
}

const root = createRoot(window.document.getElementById('root') as Element)
root.render(<App />)
Modal.setAppElement('#root')

// to prevent noisy uncaught errors
window.addEventListener('error', event => {
  if (event.error instanceof ApiError) {
    event.preventDefault()
  }
})

if ('serviceWorker' in navigator && !isLocalDevelopmentEnvironment()) {
  const s = navigator.serviceWorker // Parcel workaround
  s.register(new URL('sw.js', import.meta.url), { type: 'module' })
    .then(registration => {
      return registration.update().then(updateResult => {
        fetch(config.API_URL + API_VERSION_URL, {
          method: 'POST',
        })
      })
    })
    .catch(error => {
      console.error(`Registration failed with ${error}`)
    })
}
