import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const AttendanceReportJobModel = types.model('Shift', {
  id: types.number,
  start_time: types.string,
  end_time: types.string,
  time_to_auto_confirm: types.number,
  pending_shifts: types.number,
  job_id: types.number,
  work_category_name: types.string,
  client_location_name: types.string,
  order_id: types.number,
})

export const attendanceReportJobActions = (self: DataStore) => ({
  fetchAttendanceReportJob: flow(function* () {
    const shifts = (yield self.request('get', 'client/attendance/shifts')).list as AttendanceReportSnapshot[]
    self.attendanceReportJob.clear()
    shifts.forEach((shift: AttendanceReport) => {
      self.attendanceReportJob.set(String(shift.id), shift)
    })
  }),
})

export const attendanceReportJobViews = (self: DataStore) => ({
  attendanceReportJobView() {
    return Array.from(self.attendanceReportJob.values())
  },
})

export type AttendanceReport = typeof AttendanceReportJobModel.Type
export type AttendanceReportSnapshot = typeof AttendanceReportJobModel.SnapshotType
