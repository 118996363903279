import { useState, FunctionComponent } from 'react'
import { observer } from 'mobx-react'

import { UIProps } from './types'
import { OrderJobDetailsSnapshot } from '../../../../models/order/order-job-details'
import { useQuery, useStore } from '../../../../utils/mst-hooks'
import { useRedirect } from '../../../../services/router'
import { routes } from '../../../../routes'
import { withErrorBoundary } from '../../../../components/error/with-error-boundary'

export function createChooseJobContainer(UIComponent: FunctionComponent<UIProps>) {
  return withErrorBoundary(
    observer(({ duplicatedOrderId }: { duplicatedOrderId: number }) => {
      const [jobs, setJobs] = useState<OrderJobDetailsSnapshot[]>([])
      const { getDuplicatedOrderJobs, removeDuplicatedJobId, addDuplicatedJobId, duplicatedOrderJobIds } =
        useStore().data
      const getJobsQuery = useQuery(() => getDuplicatedOrderJobs(duplicatedOrderId), {
        onSuccess: (result) => {
          setJobs(result)
        },
      })

      const redirect = useRedirect()

      const isJobChosen = (jobId: number) => {
        return duplicatedOrderJobIds.includes(jobId)
      }

      return (
        <UIComponent
          isLoading={getJobsQuery.isLoading}
          jobs={jobs}
          isJobChosen={isJobChosen}
          removeDuplicatedJobId={(jobId: number) => removeDuplicatedJobId(jobId)}
          addDuplicatedJobId={(jobId: number) => addDuplicatedJobId(jobId)}
          chosenJobsLength={duplicatedOrderJobIds.length}
          onSubmit={() => redirect(routes.orderDuplicate, { orderId: String(duplicatedOrderId) })}
        />
      )
    }),
  )
}
