import { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { ApiErrorTypes } from 'ogram-react'

import { useRedirect } from '../../../services/router'
import { AddUpdateAttendanceManager } from '../../../models/attendance-manager'
import { Form } from '../../../components/form/form'
import { InputField } from '../../../components/form/input/input'
import { Submit } from '../../../components/form/submit/submit'
import { Loading } from '../../../components/loading/loading'
import { useMutation, useQuery, useStore } from '../../../utils/mst-hooks'
import { routes } from '../../../routes'
import './attendance-manager-form.scss'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

export const AttendanceManagerForm = withErrorBoundary(
  observer((props: { title: string; id: string | null; manager: AddUpdateAttendanceManager }) => {
    const store = useStore()
    const redirect = useRedirect()
    const [data, setData] = useState(props.manager)
    const [saveResult, save] = useMutation(
      () => (props.id ? store.data.updateAttendanceManager(props.id, data) : store.data.addAttendanceManager(data)),
      {
        onSuccess: () => redirect(routes.attendanceManagers, {}),
        filterError: [ApiErrorTypes.ClientError],
      },
    )

    const { t } = useTranslation()

    return (
      <div className="AttendanceManagerForm">
        <h1>{props.title}</h1>
        <Form
          data={data}
          setData={setData}
          error={saveResult.error?.data}
          onSubmit={save}
          isLoading={saveResult.isLoading}
        >
          {(fields) => (
            <>
              {/* {saveResult.error && <div>Have Errors</div>} */}
              <InputField label={t('attendance_manager_form.first_name')} field={fields.first_name} required />
              <InputField label={t('attendance_manager_form.last_name')} field={fields.last_name} required />
              <InputField label={t('attendance_manager_form.phone')} type="phone" field={fields.phone} required />
              <InputField label={t('attendance_manager_form.email')} type="email" field={fields.email} required />
              <InputField label={t('attendance_manager_form.job_role')} field={fields.job_role} required />
              <Submit>{t('attendance_manager_form.save')}</Submit>
            </>
          )}
        </Form>
      </div>
    )
  }),
)

export const EditAttendanceManager = withErrorBoundary(
  observer((props: { id: string }) => {
    const store = useStore()
    const {} = useQuery(() => store.data.getAttendanceManager(props.id))
    const { t } = useTranslation()

    const manager = store.data.attendanceManagers.get(props.id)
    if (!manager) return <Loading />
    return (
      <AttendanceManagerForm
        id={props.id}
        manager={manager as unknown as AddUpdateAttendanceManager}
        title={t('attendance_manager_form.edit_attendance_manager')}
      />
    )
  }),
)

export function AddAttendanceManager() {
  const { t } = useTranslation()

  return (
    <AttendanceManagerForm
      id={null}
      title={t('attendance_manager_form.add_attendance_manager')}
      manager={{ email: '', first_name: '', job_role: '', last_name: '', phone: '', locations: [] }}
    />
  )
}
