import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { Button } from '../../../button/button'
import { orderExtensionStore } from '../../../../models-ui/order/store'
import { Separator } from '../../../app-layout'

export const ExtensionType = observer(() => {
  const { t } = useTranslation()

  return (
    <>
      <span className="Popup__title">{t('order_extension.how_to_extend')}</span>
      <span className="Popup__subtitle">{t('order_extension.you_can_extend_order')}</span>
      <div className="OrderExtension__popup__innerContainer">
        <Button
          onClick={() => {
            orderExtensionStore.setUseSameExtensionDateForAllJobs(true)
          }}
          className={`OrderExtension__popup__innerContainer__button ${
            orderExtensionStore.useSameExtensionDateForAllJobs ? 'active' : ''
          }`}
        >
          <div className="OrderExtension__popup__innerContainer__button__content">
            <span>{t('order_extension.same_extension_date')}</span>
          </div>
        </Button>
        <Separator.Vertical height={10} />
        <Button
          onClick={() => orderExtensionStore.setUseSameExtensionDateForAllJobs(false)}
          className={`OrderExtension__popup__innerContainer__button ${
            !orderExtensionStore.useSameExtensionDateForAllJobs ? 'active' : ''
          }`}
        >
          <div className="OrderExtension__popup__innerContainer__button__content">
            <span>{t('order_extension.different_extension_dates')}</span>
          </div>
        </Button>
      </div>
    </>
  )
})
