import { INITIAL_VERSION, VERSION_CACHE_KEY, VERSION_CACHE_NAME } from '../constants'
import { config } from '../config/env'

export function getVersionCacheKey() {
  return config.API_URL + VERSION_CACHE_KEY
}

export async function getCurrentAppVersion() {
  const cache = await caches.open(VERSION_CACHE_NAME)
  const version = await cache.match(getVersionCacheKey())

  return (await version?.text()) ?? INITIAL_VERSION
}
