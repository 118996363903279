import { useState, useRef } from 'react'

import dateInputImg from './assets/date-input-icon.svg'
import Calendar from '../calendar/calendar'
import { useOutsideClickHandler } from '../../utils/use-outside-click-handler'
import './date-input.scss'
import { formatDateToIso8601 } from '../../utils/datetime-utils'

type DateInputProps = {
  label: string
  handleChange: (val: string) => void
  hideBorder?: boolean
  hideValue?: boolean
  calendarContainerStyle?: object
} & React.InputHTMLAttributes<HTMLInputElement>

export function DateInput(props: DateInputProps) {
  const [showCalendar, setShowCalendar] = useState(false)
  const calendarRef = useRef(null)

  useOutsideClickHandler(calendarRef, () => setShowCalendar(false))

  const setCalendarValue = (value: Date | null) => {
    const formattedValue = value !== null ? formatDateToIso8601(value) : ''
    props.handleChange(formattedValue)

    setShowCalendar(false)
  }

  return (
    <>
      <div className={`DateInput ${props.className ? props.className : ''}`}>
        {showCalendar && (
          <Calendar
            containerStyle={props.calendarContainerStyle}
            value={new Date(String(props.value))}
            setValue={setCalendarValue}
            minDate={props.min ? new Date(props.min) : undefined}
            maxDate={props.max ? new Date(props.max) : undefined}
            containerClassName="DateInput__Calendar"
            ref={calendarRef}
          />
        )}
        <div
          style={props.hideBorder ? { border: 'none' } : {}}
          onClick={() => {
            setShowCalendar(true)
          }}
          className={`DateInput__labelContainer ${!props.value || props.hideValue ? 'fullContainer' : ''}`}
        >
          <span className="DateInput__labelContainer__label" style={props.hideValue ? { color: '#323246' } : {}}>
            {props.label}
          </span>
        </div>
        {!props.hideValue && (
          <input
            style={props.hideBorder ? { border: 'none' } : {}}
            className="DateInput__input"
            type="text"
            value={props.value}
            onClick={() => setShowCalendar(true)}
            onFocus={e => props.onFocus?.(e)}
            onBlur={e => props.onBlur?.(e)}
            readOnly
          />
        )}
        <div className="DateInput__icon-container" onClick={() => setShowCalendar(true)}>
          <img src={dateInputImg} alt="" className="DateInput__icon-container__icon" />
        </div>
      </div>
    </>
  )
}
