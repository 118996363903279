import { useTranslation } from 'react-i18next'

import './too-many-attempts.scss'

interface TooManyAttemptsProps {
  resetError: () => void
}

export const TooManyAttempts = ({ resetError }: TooManyAttemptsProps) => {
  const { t } = useTranslation()

  return (
    <div className="TooManyAttempts">
      <div className="TooManyAttempts__container">
        <h2 className="TooManyAttempts__modalTitle">{t('too_many_attempts.title')}</h2>
        <p>{t('too_many_attempts.description')}</p>
        <button
          onClick={() => {
            resetError()
            window.history.back()
          }}
          className="TooManyAttempts__backButton"
        >
          {t('too_many_attempts.ok')}
        </button>
      </div>
    </div>
  )
}
