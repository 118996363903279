import { useState, useRef, Fragment, RefObject } from 'react'
import * as Yup from 'yup'
import { Formik, Form, FormikProps } from 'formik'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { BusinessLocationForm } from '../../../models/location'
import { Separator } from '../../../components/app-layout'
import TextInput from '../../../components/text-input/text-input'
import GoogleMaps from '../../../components/google-maps/google-maps'
import LocationAutoComplete from '../../../components/location-auto-complete/location-auto-complete'
import './location-form.scss'
import { CheckInRestrictions } from './components/check-in-restrictions/check-in-restrictions'
import { AdditionalDetails } from './components/additional-details/additional-details'
import { withErrorBoundary } from '../../../components/error/with-error-boundary'

type LocationFormProps = {
  initialValues: BusinessLocationForm
  onSubmit: (values: BusinessLocationForm) => void
  onDeleteLocation: () => void
  onLocationSelected?: () => void
  showDelete?: boolean
  formRef?: RefObject<FormikProps<BusinessLocationForm>> | null
  showSubmit?: boolean
  showLocationNameField?: boolean
  showAddressDetailsField?: boolean
  showImageField?: boolean
  showCheckInRestrictionsBlock?: boolean
}

export const LocationForm = withErrorBoundary(
  observer(
    ({
      initialValues,
      showDelete,
      onSubmit,
      onDeleteLocation,
      onLocationSelected,
      formRef,
      showSubmit,
      showLocationNameField,
      showAddressDetailsField,
      showImageField,
      showCheckInRestrictionsBlock,
    }: LocationFormProps) => {
      const [selectedImage, setSelectedImage] = useState<File | null>(null)
      const [deleteImage, setDeleteImage] = useState(0)

      const { t } = useTranslation()

      if (!formRef) {
        /* eslint-disable no-param-reassign */
        formRef = useRef<FormikProps<BusinessLocationForm>>(null)
        /* eslint-enable no-param-reassign */
      }

      const onMarkerChange = (position: { lat: number; lng: number }) => {
        formRef?.current?.setFieldValue('lat', position.lat)
        formRef?.current?.setFieldValue('lng', position.lng)
      }

      const onPlaceSelected = (place: {
        geometry: { location: { lat: () => number; lng: () => number } }
        name: string
        formatted_address: string
      }) => {
        onMarkerChange({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
        formRef?.current?.setFieldValue('address', `${place.name} - ${place.formatted_address}`)
        onLocationSelected?.()
      }

      const onDeleteImage = () => {
        setDeleteImage(1)
      }

      const onSelectImage = (file: File | null) => {
        setSelectedImage(file)
        setDeleteImage(0)
      }

      return (
        <div className="LocationForm">
          <Formik
            innerRef={formRef}
            validationSchema={Yup.object().shape({
              name: Yup.string().required(t('location_list.location_required')),
              address: Yup.string().required(t('location_list.address_required')),
            })}
            initialValues={initialValues}
            onSubmit={values => {
              const apiValues = { ...values, delete_image: deleteImage }
              if (selectedImage) {
                apiValues.image = selectedImage
                onSubmit(apiValues)
              } else {
                delete apiValues.image
                onSubmit(apiValues)
              }
            }}
          >
            {formikProps => (
              <Form autoComplete="off" noValidate>
                <LocationAutoComplete
                  type="text"
                  label={t('location_list.location_address')}
                  onPlaceSelected={p => {
                    onPlaceSelected(p)
                    formikProps.setFieldValue('name', p.name)
                  }}
                  getValue={value => {
                    const matches = value.trim().match(/^[\w\s]+/i)
                    if (matches) {
                      formikProps.setFieldValue('name', matches[0].trim())
                    }
                  }}
                  formikProps={formikProps}
                  formikKey="address"
                />
                <Separator.Vertical height={16} />
                <div className="LocationForm__map">
                  <GoogleMaps
                    markerLat={formikProps.values.lat}
                    markerLng={formikProps.values.lng}
                    onMarkerChange={onMarkerChange}
                  />
                </div>
                <Separator.Vertical height={16} />
                {showLocationNameField && (
                  <Fragment>
                    <TextInput label={t('location_list.location_name')} formikProps={formikProps} formikKey="name" />
                    <Separator.Vertical height={16} />
                  </Fragment>
                )}
                <CheckInRestrictions
                  isVisible={Boolean(showCheckInRestrictionsBlock)}
                  isGeorestrictionLarge={Boolean(formikProps.values.is_georestriction_large)}
                  isGeorestrictionDisabled={Boolean(formikProps.values.is_georestriction_disabled)}
                  setIsGeorestrictionLarge={(value: boolean) => {
                    formikProps.setFieldValue('is_georestriction_large', value)
                  }}
                  setIsGeorestrictionDisabled={(value: boolean) => {
                    formikProps.setFieldValue('is_georestriction_disabled', value)
                  }}
                />
                <AdditionalDetails
                  showAddressDetailsField={Boolean(showAddressDetailsField)}
                  showImageField={Boolean(showImageField)}
                  onDeleteImage={onDeleteImage}
                  imageUrl={initialValues.image}
                  onSelectImage={onSelectImage}
                  formikProps={formikProps}
                />
                <div className="LocationForm__buttonsContainer">
                  {showSubmit && (
                    <button type="submit" className="LocationForm__save">
                      {t('location_list.save')}
                    </button>
                  )}
                  {showDelete && (
                    <>
                      <Separator.Horizontal width={10} />
                      <button onClick={onDeleteLocation} type="button" className="LocationForm__delete">
                        {t('location_list.delete')}
                      </button>
                    </>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )
    },
  ),
)
